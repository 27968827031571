import React, { useCallback } from "react";
import "../../../App.css";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import showSnackbar from "../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import GetFilterFields from "./IllnessReportFilters";
import EmergencyPlanModal from "./IllnessReportModal";
import moment from "moment";
import { getDepartments } from "./GetOptions";

export default function IllnessReportTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "illnessReports");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [dialog, setDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterDepartment: null,
    filterCompany: null,
    filterRegion: null,
    filterEndDate: null,
    filterStartDate: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: language.tableColumns.companyUser, field: "companyName" },
      { title: language.tableColumns.departmentUser, field: "departmentName" },
      { title: "Bölgesi", field: "regionName" },
      { title: language.tableColumns.name, field: "name" },
      { title: language.tableColumns.surname, field: "surname" },
      {
        title: "Rapor Başlangıç Tarihi",
        field: "startDate",
        render: (rowData) =>
          moment(rowData.startDate).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Rapor Bitiş Tarihi ",
        field: "endDate",
        render: (rowData) => moment(rowData.endDate).format("DD-MM-YYYY HH:mm"),
      },
      { title: "Raporlu Gün Sayısı", field: "numberOfDays" },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    data && setSelectedReport(data);
    setModalLoading(true);
    setEditModal(true);
    // let opts = { ...options };
    // data && data.company
    //   ? (opts.departments = await getDepartments(data.company))
    //   : (opts.departments = await getDepartments(user.companyId));
    // setOptions(opts);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedReport(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedReport(null);
    setDialog(false);
  };

  const deleteReport = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/reports/received-for-illness/" + selectedReport?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterDepartment: null,
      filterCompany: null,
      filterRegion: null,
      filterEndDate: null,
      filterStartDate: null,
    });
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={selectedReport && deleteReport}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <EmergencyPlanModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldReportProps={selectedReport}
        setOldReportProps={setSelectedReport}
        options={options}
        setOptions={setOptions}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters />
      <Table
        tableName={"Hastalık Sebebiyle Alınan Raporlar"}
        authName={"illnessReports"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        filters={filterProps}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() => authorization.delete}
        isEditable={(rowData) => authorization?.edit}
      />
    </>
  );
}
