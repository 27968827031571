import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl, Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Request from "../../../helpers/Request";
import { modalStyles } from '../../../assets/styles/tableContainer';
import showSnackbar from "../../../components/Utils/showSnackbar";
import { TextArea } from '../../../components/Fields/TextFields';

const useStyles = makeStyles((theme) => (modalStyles(theme)));

export default function DecisionModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  rowData,
  reload,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [props, setProps] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [textError, setTextError] = React.useState(null);
  const [customNoError, setCustomNoError] = React.useState(null);

  const init = useCallback(async () => {
    setProps({
      isgCommitteeId: rowData?.id,
      customNo: null,
      text: null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setProps({
      text: null,
    });
  };

  const submitNewDecision = async () => {
    if(!props.customNo || props.customNo === "") {
      setCustomNoError("Bu alan boş bırakılamaz.");
    }
    else if(!props.text || props.text === "") {
      setTextError("Bu alan boş bırakılamaz.");
    } else {
      setLoading(true);
      const resp = await Request("post", "/api/isgCommittee/decision", props);
      console.log(resp);
      showSnackbar(
        resp,
        setSeverity,
        setSnackbarMessage,
        setSnackbar,
        language.etc.added,
        tableRef,
        reload
      );
      handleCloseModal();
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <FormControl
              noValidate
              autoComplete="off"
              className={classes.formControl}
            >
              <TextArea
                required
                error={customNoError}
                label={"Karar No"}
                value={props.customNo}
                onChangeFunc={(value) => {
                  if(value.target.value.length > 100) {
                    setCustomNoError("Bu alan 100 karakterden uzun olamaz.");
                  } else {
                    customNoError && setCustomNoError(null);
                    setProps({ ...props, customNo: value.target.value })
                  }
                }}
              />
              <TextArea
                required
                error={textError}
                label={"Karar"}
                value={props.text}
                onChangeFunc={(value) => 
                  setProps({ ...props, text: value.target.value })
                }
              />
              <div style={{ marginTop: 10 }}>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={submitNewDecision}
                    className={classes.submit}
                  >
                    {language.form.submit}
                  </Button>
                )}
              </div>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
}