import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    var params = new URLSearchParams();
    for (let mod of filterProps.moduleIds) {
      params.append("moduleIds", mod.id);
    }
    for (let func of filterProps.moduleFunctionIds) {
      params.append("moduleFunctionIds", func.id);
    }
    for (let role of filterProps.roleIds) {
      params.append("roleIds", role.id);
    }
    params.append("page", query.page);
    params.append("page-size", query.pageSize);
    params.append("sort-by", "id");
    params.append("sort-direction", "DESC");
    // if (query.orderBy !== undefined) {
    //   if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
    //   else if (query.orderBy.field === "name") { params.sort = "name," + query.orderDirection; }
    // }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/authority", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(
        language.etc.fetchingError + resp?.data?.messageResponse
      );
      setSnackbar(true);
    } else {
      let otherData = resp.data?.content;
      let data = otherData?.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          moduleName: data[i].module?.name,
          moduleId: data[i].module?.id,
          functionName: data[i].moduleFunction?.name,
          functionId: data[i].moduleFunction?.id,
          roleName: data[i].role?.name,
          roleId: data[i].role?.id,
          createOpNote: data[i].createOpNote,
          createOpWeb: data[i].createOpWeb,
          createOpMobile: data[i].createOpMobile,
          deleteOpMobile: data[i].deleteOpMobile,
          deleteOpNote: data[i].deleteOpNote,
          deleteOpWeb: data[i].deleteOpWeb,
          updateOpMobile: data[i].updateOpMobile,
          updateOpNote: data[i].updateOpNote,
          updateOpWeb: data[i].updateOpWeb,
          readOpNote: data[i].readOpNote,
          readOpWeb: data[i].readOpWeb,
          readOpMobile: data[i].readOpMobile,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
