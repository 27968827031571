import React from "react";
import UserTable from './components/UserTable';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from '../../assets/styles/tableContainer';
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import SessionHelper from "../../helpers/SessionHelper";
import UnauthorizedPage from "../UnauthorizedPage";

export default function UserPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "usersTable");
  // const [authorization, setAuthorization] = React.useState({});
  // const init = useCallback(async () => {
  //   setAuthorization(await getAuthorizationForPage(roles, "USERS_TABLE"));
  // }, [roles]);
  // React.useEffect(() => {
  //   init();
  // }, [init]);
  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <UserTable /> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}
