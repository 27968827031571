import React, {useCallback} from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import { getDepartments } from "./GetOptions";
import { taskStatuses } from "../../../assets/constants";
import jsonMap from "../../../components/Utils/jsonMap";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
  companyDisabled,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const statuses = {...taskStatuses, DONE: "Onay Bekliyor"};

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [departments, setDepartments] = React.useState([]);
  const [towns, setTowns] = React.useState(options?.towns);

  const init = useCallback(async () => {
    tableFilters.company && setDepartments(await getDepartments(tableFilters.company?.id));
    tableFilters.region && setTowns(tableFilters.region?.towns);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters.company, tableFilters.region]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />

      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Termin Başlangıç Tarihi"
                value={tableFilters.startDate ? moment(tableFilters.startDate) : null}
                shrink={tableFilters.startDate}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    startDate: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Termin Bitiş Tarihi"
                value={tableFilters.endDate ? moment(tableFilters.endDate) : null}
                shrink={tableFilters.endDate}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    endDate: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.unsuitUserName !== null}
                label={"Uygunsuzluk Giderici Kişi"}
                value={tableFilters.unsuitUserName}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    unsuitUserName: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.inspectUserName !== null}
                label={"Denetim Yapan Kişi"}
                value={tableFilters.inspectUserName}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    inspectUserName: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={companyDisabled}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  setTableFilters({...tableFilters, company: value, department: null});
                  /* let newOpts = options;
                  newOpts.departments = value?.id ? await getDepartments(value?.id) : [];
                  setOptions(newOpts);
                  setTableFilters({ ...tableFilters, company: value });
                  if(!value) {
                    setTableFilters({ ...tableFilters, company: value, department: value });
                  } */
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.regions?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.region}
                onChange={(event, value) => {
                  setTableFilters({...tableFilters, region: value, town: null});
                  // setTowns(value ? value?.towns : options?.towns);
                  // setTableFilters({...tableFilters, region: value});
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Bölge" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={towns?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.town}
                onChange={(event, value) => setTableFilters({ ...tableFilters, town: value })}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="İlçe" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Durumu"
                value={tableFilters.status}
                shrink={tableFilters.status !== ''}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    status: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, statuses)}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
