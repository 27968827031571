import Request from '../../helpers/Request';
import LanguageHelper from '../../helpers/LanguageHelper';
import moment from 'moment';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.id,
      department: filterProps.department?.id,
      endTime: filterProps.endTime,
      startTime: filterProps.startTime,
      name: filterProps.name,
      surname: filterProps.surname,
      eligible: filterProps.eligible,
      high: filterProps.high,
      night: filterProps.night,
      shift: filterProps.shift,
      dangerous: filterProps.dangerous,
      veryDangerous: filterProps.veryDangerous,
      screen: filterProps.screen,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") { params.sort = "date," + query.orderDirection; }
      else if (query.orderBy.field === "level") { params.sort = "level," + query.orderDirection; }
      else if (query.orderBy.field === "message") { params.sort = "message," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/users/personnel-files/health-reports", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          id: content[i]?.id,
          tcNo: content[i]?.tcNo,
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          title: content[i]?.title,
          name: content[i]?.name,
          surname: content[i]?.surname,
          startDateOfWork: content[i]?.startDateOfWork ? moment(content[i]?.startDateOfWork).format("DD-MM-YYYY") : null,
          examinationTime: content[i]?.props?.examinationTime ? moment(content[i]?.props?.examinationTime).format("DD-MM-YYYY") : null,
          jobToWork: content[i]?.props?.jobToWork,
          eligibleToWork: content[i]?.props?.eligibleToWork !== undefined ? content[i].props?.eligibleToWork: false,
          workAtNight: content[i]?.props?.workAtNight !== undefined ? content[i].props?.workAtNight : false,
          workAtHeight: content[i]?.props?.workAtHeight !== undefined ? content[i]?.props?.workAtHeight : false,
          workInShift: content[i]?.props?.workInShift !== undefined ? content[i]?.props?.workInShift : false,
          workInDangerousWorkplace: content[i]?.props?.workInDangerousWorkplace !== undefined ? content[i]?.props?.workInDangerousWorkplace : false,
          workInVeryDangerousWorkplace: content[i]?.props?.workInVeryDangerousWorkplace !== undefined ? content[i]?.props?.workInVeryDangerousWorkplace : false,
          workWithScreenedVehicle: content[i]?.props?.workWithScreenedVehicle !== undefined ? content[i]?.props?.workWithScreenedVehicle : false,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      })
    }
  })
  return data;
}