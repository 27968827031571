import Request from '../../../../helpers/Request';
import moment from 'moment';
import LanguageHelper from '../../../../helpers/LanguageHelper';

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "accidentDate,desc",

      //FILTERS
      user: filterProps.user,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "companyName") { params.sort = "responsibleCompany.name," + query.orderDirection; }
      else if (query.orderBy.field === "accidentDate") { params.sort = "accidentDate," + query.orderDirection; }
      else if (query.orderBy.field === "accidentId") { params.sort = "accidentId," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "summary") { params.sort = "summary," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/industrial-accidents/isg-card/"+params.user+"/accidents", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          accidentId: data[i].accidentId,
          companyName: data[i].responsibleCompany?.name,
          accidentDate: data[i].accidentDate ? (new moment(data[i].accidentDate)).format("DD-MM-YYYY") : "Tarih Girilmemiş",
          totalLostTime: data[i].totalLostTime,
          summary: data[i].summary,
          departmentName: data[i].department?.name,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}
