import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Grid, TextField } from "@material-ui/core";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import IconTooltipButton from "../../../../components/Buttons/IconTooltipButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import Request from "../../../../helpers/Request";

const useStyles = makeStyles((theme) => ({
  alignLeft: {
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: "20px",
  },
  title: {
    fontSize: "22px",
  },
  subtitle: {
    fontSize: "16px",
  },
  towns: {
    fontSize: "14px",
  },
  addButton: {
    marginLeft: "100px",
    marginTop: "5px",
  },
  submit: {
    marginTop: "10px",
  },
  content: {
    backgroundColor: "#2D3446",
    color: "white",
    textAlign: "center",
    alignItems: "center",
  },
  noRegionTownsArea: {
    margin: "0 auto",
    overflow: "auto",
    maxHeight: 400,
  },
}));

export default function NoRegionTownsArea({
  setTownModal,
  setDeleteTownId,
  setOpenDialog,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  towns,
  setTowns,
  regions,
  setRegions
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");

  const [autocompleteValue, setAutocompleteValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleOpenTownModal = () => {
    setTownModal(true);
  };

  const handleOpenTownDialog = (id) => {
    setDeleteTownId(id);
    setOpenDialog(true);
  };

  const addTownToRegion = async (townId, regionId) => {
    setLoading(true);
    const resp = await Request(
      "put",
      "/api/cities-towns/update-town/" + townId + "/" + regionId
    );
    console.log(resp);
    if(resp?.status !== 200) { 
      setSeverity("error");
      setSnackbarMessage(resp?.data?.message);
      setSnackbar(true);
    } else {
      let tempRegions = [...regions];
      let tempTowns = [...towns];
      for(let i in tempTowns) {
        if(tempTowns[i]?.id === townId) {
          for(let j in tempRegions) {
            if(tempRegions[j]?.id === regionId) {
              tempRegions[j]?.towns.push(tempTowns[i]);
            }
          }
          tempTowns.splice(i, 1);
        }
      }
      setTowns(tempTowns);
      setRegions(tempRegions);
    }
    setLoading(false);
  };

  const PrintNoRegionTowns = () => {
    return towns.map((data, index) => {
      return (
        <div key={index}>
          <Grid container>
            <Grid item xs={3} className={classes.alignLeft} />
            <Grid item xs={1} className={classes.alignLeft}>
              <IconTooltipButton
                title={language.tableDetails.removeTown}
                onClick={() => handleOpenTownDialog(data?.id)}
              >
                <DeleteIcon /> 
              </IconTooltipButton>
            </Grid>
            <Grid item xs={2} className={classes.alignLeft}>
              <p className={classes.towns}>{data?.id}</p>
            </Grid>
            <Grid item xs={3} className={classes.alignLeft}>
              <p className={classes.towns}>{data?.name}</p>
            </Grid>
            <Grid item xs={2} className={classes.alignLeft}>
              {loading ?
              <CircularProgress color="secondary"/>
              :
              <Autocomplete
                id="combo-box"
                options={regions?.sort(function(a, b) {
                    return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                clearOnBlur={true}
                disableClearable={true}
                inputValue={autocompleteValue}
                onInputChange={(event, value, reason) => {
                  if(reason === "reset") {
                    setAutocompleteValue("");
                  } else if(reason === "input") {
                    setAutocompleteValue(value);
                  }
                }}
                onChange={(event, value, reason) => {
                  if(value?.id && value !== "") {
                    if(parseInt(value?.id)) {
                      addTownToRegion(data?.id, parseInt(value?.id));
                    }
                  }
                }}
                renderInput={(params) => <TextField {...params} required label="Bölgeye Ekle" className={classes.textFieldFields}/>}
              />}
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  return (
    <>
      <div className={classes.noRegionTownsArea}>
        <h2>Bölgesi Olmayan İlçeler</h2>
        <Grid container>
          <Grid item xs={4} className={classes.alignLeft}></Grid>
          <Grid item xs={2} className={classes.alignLeft}>
            <p className={classes.title}>İlçe No</p>
          </Grid>
          <Grid item xs={6} className={classes.alignLeft}>
            <p className={classes.title}>İlçe</p>
            <div className={classes.addButton}>
              <IconTooltipButton
                title={language.tableDetails.newTown}
                onClick={handleOpenTownModal}
              >
                <AddIcon />
              </IconTooltipButton>
            </div>
          </Grid>
        </Grid>
        {towns && <PrintNoRegionTowns />}
      </div>
    </>
  );
}
