import React, { useCallback } from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import WorkingTypeModal from "./WorkingTypeModal";

export default function WorkingTypeTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "ibys");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [dialog, setDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedWorkingType, setSelectedWorkingType] = React.useState(null);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: language.ibysPages.name, field: "name" },
      { title: language.ibysPages.description, field: "description" },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedWorkingType(data || {});
    setModalLoading(true);
    setEditModal(true);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedWorkingType(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedWorkingType(null);
    setDialog(false);
  };

  const removeWorkingType = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/calisma-sekli/" + selectedWorkingType?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <React.Fragment>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={selectedWorkingType && removeWorkingType}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <WorkingTypeModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldWorkingTypeProps={selectedWorkingType}
        setOldWorkingTypeProps={setSelectedWorkingType}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <Table
        noPaging
        tableName={language.ibysPages.workingType}
        authName={"ibys"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() => authorization.delete}
        isEditHidden={() => true}
      />
    </React.Fragment>
  );
}
