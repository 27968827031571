import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./HealthReportFilters";
import LanguageHelper from "../../helpers/LanguageHelper";
import GetOptions from "./GetOptions";
import { suitability, trueFalse } from "../../assets/constants";

export default function HealthReportTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [options, setOptions] = React.useState({});

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: null,
    department: null,
    startTime: null,
    endTime: null,
    name: null,
    surname: null,
    eligible: null,
    high: null,
    night: null,
    shift: null,
    dangerous: null,
    veryDangerous: null,
    screen: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.healthReport.id, field: "id" },
      { title: language.healthReport.name, field: "name" },
      { title: language.healthReport.surname, field: "surname" },
      { title: language.healthReport.company, field: "companyName" },
      { title: language.healthReport.title, field: "title" },
      { title: language.healthReport.department, field: "departmentName" },
      { title: language.healthReport.tcNo, field: "tcNo" },
      {
        title: language.healthReport.workStartDate,
        field: "startDateOfWork",
      },
      {
        title: language.healthReport.examinationTime,
        field: "examinationTime",
      },
      {
        title: language.healthReport.job,
        field: "jobToWork",
      },
      {
        title: language.healthReport.eligibleToWork,
        field: "eligibleToWork",
        lookup: suitability,
      },
      {
        title: language.healthReport.workAtNight,
        field: "workAtNight",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workAtHeight,
        field: "workAtHeight",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workInShift,
        field: "workInShift",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workInDangerousWorkplace,
        field: "workInDangerousWorkplace",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workInVeryDangerousWorkplace,
        field: "workInVeryDangerousWorkplace",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workWithScreenedVehicle,
        field: "workWithScreenedVehicle",
        lookup: trueFalse,
      },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: null,
      department: null,
      startTime: null,
      endTime: null,
      name: null,
      surname: null,
      eligible: null,
      high: null,
      night: null,
      shift: null,
      dangerous: null,
      veryDangerous: null,
      screen: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noRowActions
        tableName={language.sidebar.healthStatusReport}
        authName="healthStatusReport"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
