import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import SessionHelper from "../../helpers/SessionHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import TechnicalTrainingReportTable from "./TechnicalTrainingReportTable";

function TechnicalTrainingReportPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(
    roles,
    "technicalTrainingReport"
  );

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? (
          <TechnicalTrainingReportTable />
        ) : (
          <UnauthorizedPage />
        )}
      </Grid>
    </Container>
  );
}

export default TechnicalTrainingReportPage;
