import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { List, ListItem, ListItemText } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from "@material-ui/icons/Delete";
import jsonMap from "../../../components/Utils/jsonMap";
import { TextSelector } from "../../../components/Fields/TextFields";
import { GDZ_EYAŞ } from "../../../assets/constants";

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: 180,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5,
  },
  roles: {
    marginTop: -5,
    color: "#B8B8B8",
    marginLeft: "0",
  },
  textFieldRoles: {
    marginTop: "-30px",
    marginBottom: "40px",
    width: "50%",
  },
}));

export default function RoleList({
  newUserProps,
  setNewUserProps,
  options,
  roleError,
  setRoleError,
  errors,
  setErrors,
  companyType,
  setChangeMadeToProps,
  companies,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const isEyas = (companyId) =>{
    const company = companies.find(company => company.id === companyId)
    return company?.name === GDZ_EYAŞ.companyName
  }

  return (
    <div style={{ textAlign: "left" }}>
      <h5 className={classes.roles}>Rolleri *</h5>
      <List className={classes.list}>
        {newUserProps.roles.map((data, index) => (
          <ListItem key={index}>
            <IconTooltipButton
              title={language.form.deleteQuestion}
              onClick={() => {
                let roles = newUserProps.roles;
                roles.splice(index, 1);
                setNewUserProps({ ...newUserProps, roles: roles });
                setChangeMadeToProps && setChangeMadeToProps(true);
              }}
            >
              <DeleteIcon />
            </IconTooltipButton>
            <ListItemText
              secondary={options.roles[data.name ? data.name : data]}
            />
          </ListItem>
        ))}
      </List>
      <div style={{ textAlign: "center" }}>
        <TextSelector
          shrink={false}
          required
          error={roleError ? roleError : errors?.roleError}
          label={"Rol Ekle"}
          value={null}
          onChangeFunc={(value) => {
            (roleError || errors) &&
              (setRoleError
                ? setRoleError(null)
                : setErrors({ ...errors, companyError: null }));
            let roles = newUserProps.roles;
            // A user can have a single role.
            // Exception: EDC users can have a second role as "Yaşam Güvenliği Komitesi Üyesi"
            if (
              roles.length === 0 ||
              roles[0] in options.edcRoles ||
              (roles[0] in options.ygkMember && roles.length === 1)
            ) {
              roles.push(value.target.value);
              setNewUserProps({ ...newUserProps, roles: roles });
              setChangeMadeToProps && setChangeMadeToProps(true);
            }
          }}
          style={classes.textFieldRoles}
          options={
            // If edc role is selected, then user can only choose ygk member role.
            // Else company type - role matching. (E.g. EDC type - EDC roles)
            newUserProps.roles[0] in options.edcRoles
              ? jsonMap("ygkMember", options)
              : isEyas(newUserProps.companyId)
              ? jsonMap("eyasRoles", options)
              : companyType === "EDC"
              ? jsonMap("edcRoles", options)
              : companyType === "CONTRACTORISG"
              ? jsonMap("contractorIsgRoles", options)
              : companyType === "CONTRACTOROPR" &&
                newUserProps.companyName !== "İNOVEN"
              ? jsonMap("contractorOprRoles", options)
              : companyType === "CONTRACTOROPR" &&
                newUserProps.companyName === "İNOVEN"
              ? jsonMap("inovenRole", options)
              : jsonMap("contractorOsgbRoles", options)
          }
        />
      </div>
    </div>
  );
}
