import Request from "../../helpers/Request";
import LanguageHelper from "../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.id,
      department: filterProps.department?.id,
      name: filterProps.name,
      surname: filterProps.surname,
      edStartTime: filterProps.edStartTime,
      edEndTime: filterProps.endTime,
      edStatus: filterProps.edStatus,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") {
        params.sort = "date," + query.orderDirection;
      } else if (query.orderBy.field === "level") {
        params.sort = "level," + query.orderDirection;
      } else if (query.orderBy.field === "message") {
        params.sort = "message," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/users/personnel-files/technical-education-reports",
      null,
      params
    );
    console.log(resp);

    const trainingSource = (trainingSourceArray) => {
      let source = "";
      for (let index = 0; index < trainingSourceArray.length; index++) {
        const element = trainingSourceArray[index];
        const keys = Object.keys(trainingSourceArray[index]);
        const fname = element[keys[1]];
        const lname = element[keys[2]];
        const shortCompanyName = element[keys[3]];
        if (index !== 0) {
          const oneLine = fname + " " + lname + "/" + shortCompanyName;
          console.log(oneLine);
          source = source.concat(" - ",oneLine);
        } else {
          const oneLine = fname + " " + lname + "/" + shortCompanyName;
          console.log(oneLine);
          source = source.concat(oneLine);
        }
      }
      console.log(source);
      return source;
    };

    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          id: content[i]?.id,
          tcNo: content[i]?.tcNo,
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          title: content[i]?.title,
          name: content[i]?.name,
          surname: content[i]?.surname,
          startDateOfWork: content[i]?.startDateOfWork
            ? moment(content[i]?.startDateOfWork).format("DD-MM-YYYY")
            : null,
          educationStatus: content[i]?.educationStatus,
          technicalEducationStatus: content[i]?.props?.technicalEducationStatus
            ? language.status.exist
            : language.status.absent,
          trainingName: content[i]?.props?.technicalEducationStatus
            ? content[i].props.trainingName
            : language.status.absent,
          trainingDate: content[i].props?.technicalEducationStatus
            ? moment(content[i]?.props.trainingDate).format("DD-MM-YYYY")
            : language.status.absent,
          trainingSource:
            content[i].props.from === "TRAINING_RECORD"
              ? trainingSource(content[i].props.trainingSource)
              : content[i].props.trainingSource,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      });
    }
  });
  return data;
}
