import Request from "../../helpers/Request";
import LanguageHelper from "../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      regionId: filterProps.region?.id,
      companyFilter: filterProps.company?.id,
      departmentFilter: filterProps.department?.id,
      emailFilter: filterProps.email,
      platform: filterProps.platform,
      readTimeStart: filterProps.readTimeStart,
      readTimeEnd: filterProps.readTimeEnd,
      approved: filterProps.approved,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") {
        params.sort = "date," + query.orderDirection;
      } else if (query.orderBy.field === "level") {
        params.sort = "level," + query.orderDirection;
      } else if (query.orderBy.field === "message") {
        params.sort = "message," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/users/tos-logs", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          regionName: content[i]?.regionName,
          name: content[i]?.fname,
          surname: content[i]?.lname,
          email: content[i]?.email,
          consentStatus: content[i]?.tosLog ? "Onaylandı" : "Onaylanmadı",
          platform: content[i]?.tosLog?.platform,
          readTime: content[i]?.tosLog?.readTime
            ? moment(content[i]?.tosLog?.readTime).format("DD-MM-YYYY HH:mm")
            : language.status.absent,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      });
    }
  });
  return data;
}
