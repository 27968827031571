import { makeStyles } from "@material-ui/core/styles";
import { TextArea, TextSelector } from "../../../../components/Fields/TextFields";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import jsonMap from "../../../../components/Utils/jsonMap";
import { getUsers, getTowns } from '../../components/GetOptions';
 import React from "react";
import FieldList from '../../components/FieldsList';
import BelongingDepartmentList from '../../components/BelongingDepartmentList';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "40%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  textField: {
    marginBottom: "3vw",
    whiteSpace: 'pre-wrap',
    textAlign: "left",
  },
  dateField: {
    marginBottom: "3vw",
    marginTop: 0	
  },
}));

export default function DepartmentDataForm({setChangeMade, departmentProps, setDepartmentProps, data, errors, setErrors, options, setOptions}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const getFromId = (options, id) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  return(
    <FormControl required autoComplete="off" className={classes.formControl}> 
      <TextArea //NAME
        required
        error={errors.nameError}
        label={"Adı"}
        value={departmentProps.name}
        onChangeFunc={value => {
          if(value.target.value.length > 60) {
            setErrors({...errors, nameError: "Adı 60 karakterden uzun olamaz."});
          } else {
            errors.nameError && setErrors({...errors, nameError: null});
            setDepartmentProps({...departmentProps, name: value.target.value});
            setChangeMade && setChangeMade(true);
          }
        }}
        style={classes.textField}
      />
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        defaultValue={departmentProps ? getFromId(options.companies, departmentProps?.companyId) : null}
        onChange={async (event, value) => {
          errors.companyError && setErrors({...errors, companyError: null});
          let newOpts = options;
          newOpts.unsuitUser = value?.id ? await getUsers(value?.id) : [];
          setOptions(newOpts);
          setDepartmentProps({...departmentProps, companyId: value?.id});
          setChangeMade && setChangeMade(true);
        }} 
        renderInput={(params) => <TextField {...params} error={errors.companyError} required label="Şirket" className={classes.textField}/>}
      />
      <TextArea //SGK
        label={"SGK Sicil No"}
        error={errors.sgkError}
        required
        value={departmentProps.sgkno}
        onChangeFunc={value => {
          let val = value.target.value;
          val = val.replace(/\s/g, "").replace(/\D/g,'');
          if(val.length !== 26) {
            setErrors({...errors, sgkError: "SGK Numarası 26 haneli olup sadece sayılardan oluşmalıdır.\nÖrnek: 11234010112345670010199000000"});
          } else {
            errors.sgkError && setErrors({...errors, sgkError: null});
          }
          setDepartmentProps({...departmentProps, sgkno: val});
          setChangeMade && setChangeMade(true);
        }}
        style={classes.textField}
      />
      <BelongingDepartmentList 
        oldDepartmentProps={data}
        newDepartmentProps={departmentProps} 
        setNewDepartmentProps={setDepartmentProps}
        options={options}
        collator={collator}
        setChangeMade={setChangeMade}
      />
      <TextSelector //CITY
        label={"Şehir"}
        error={errors.cityError}
        value={departmentProps.cityId}
        required
        onChangeFunc={async value => {
          errors.cityError && setErrors({...errors, cityError: null});
          let newOpts = options;
          newOpts.towns = await getTowns(value.target.value);
          setOptions(newOpts);
          setDepartmentProps({...departmentProps, cityId: value.target.value});
          setChangeMade && setChangeMade(true);
        }}        
        style={classes.textField}
        options={jsonMap("cities", options)}
      />
      <Autocomplete //TOWN
        id="combo-box"
        options={options.towns?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        disabled={!departmentProps.cityId}
        defaultValue={departmentProps ? getFromId(options.towns, departmentProps.townId) : null}
        onChange={(event, value) => {
          errors.townError && setErrors({...errors, townError: null});
          setDepartmentProps({...departmentProps, townId: value?.id});
          setChangeMade && setChangeMade(true);
        }}     
        renderInput={(params) => <TextField {...params} error={errors.townError} required label="İlçe" className={classes.textField}/>}
      />
      <FieldList
        newDepartmentProps={departmentProps} 
        setNewDepartmentProps={setDepartmentProps} 
        options={options}
        setChangeMade={setChangeMade}
      />
      <TextSelector //RISK CLASS
        label={"Tehlike Sınıfı"}
        required
        error={errors.riskError}
        value={departmentProps.riskClass}
        onChangeFunc={value => {
          setDepartmentProps({...departmentProps, riskClass: value.target.value})
          setChangeMade && setChangeMade(true);
        }}
        style={classes.textField}
        options={jsonMap("riskClasses", options)}
      />
      <TextSelector //FIELD OF ACTIVITY
        label={"Faaliyet Tipi"}
        required
        value={departmentProps.fieldOfActivity}
        onChangeFunc={value => {
          setDepartmentProps({...departmentProps, fieldOfActivity: value.target.value})
          setChangeMade && setChangeMade(true);
        }}
        style={classes.textField}
        options={jsonMap("fieldOfActivities", options)}
      />
    </FormControl>
  )
};