import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function VideoModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  videoProps,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestVideoProps, setRequestVideoProps] = React.useState({});
  const isNewVideo = videoProps && Object.keys(videoProps).length === 0;
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setRequestVideoProps({
      name: videoProps ? videoProps.name : null,
    });
    if (!isNewVideo && videoProps) {
      setFiles([...videoProps.videoFile]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestVideoProps({
      name: null,
    });
    setFiles([]);
  };

  const submitNewVideo = async () => {
    setLoading(true);
    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("file", file);
      }
    }
    const videoResponse = await Request(
      "post",
      "/api/vm/file-controller/upload",
      formData
    );
   
    const filename=videoResponse.data.address;
    requestVideoProps.filename = filename;
    // formData.append(
    //   "videoProps",
    //   new Blob([JSON.stringify(requestVideoProps)], {
    //     type: "application/json",
    //   })
    // );
    console.log(requestVideoProps);
   
    const resp = await Request("post", "/api/vm/video/create", null,requestVideoProps); // TODO Change endpoint
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateVideo = async () => {
    setLoading(true);
    let props = { ...requestVideoProps };
    props.id = videoProps.id;

    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("file", file);
      }
    }
    const videoResponse = await Request(
      "post",
      "/api/vm/file-controller/upload",
      formData
    );
   
    const filename=videoResponse.data.address;
    props.filename = filename;
    
    const resp = await Request(
      "put",
      "/api/vm/video/update/" + props.id,
      null,
      props
    ); 
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestVideoProps={requestVideoProps}
              videoProps={videoProps}
              setRequestVideoProps={setRequestVideoProps}
              files={files}
              setFiles={setFiles}
              submitNewVideo={submitNewVideo}
              updateVideo={updateVideo}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
