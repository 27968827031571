import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import moment from "moment";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { TextArea } from "../../../components/Fields/TextFields";
import {
  Button,
  CircularProgress,
  FormControl,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  buttonContainer: {
    textAlign: "center",
    padding: 10,
  },
}));

export default function ActionModal({
  modalLoading,
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  nearMissProps,
  opts,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [actionProps, setActionProps] = React.useState({});
  const [options, setOptions] = React.useState({
    users: [],
  });
  const [loading, setLoading] = React.useState(false);
  const [descriptionError, setDescriptionError] = React.useState(null);

  const init = useCallback(async () => {
    setActionProps({
      nearMissId: nearMissProps?.id,
      assignedTo: null,
      note: null,
      terminationDate: moment()
        .set("hour", 23)
        .set("minute", 59)
        .toISOString(true), //nearMissProps ? nearMissProps.terminationDate : moment().toISOString(true),
    });
    setOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearMissProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    descriptionError && setDescriptionError(null);
    setTimeout(() => {
      setActionProps({
        nearMissId: null,
        assignedTo: null,
        note: null,
        terminationDate: moment()
          .set("hour", 23)
          .set("minute", 59)
          .toISOString(true),
      });
    }, 300);
  };

  /**
   * Request to post a new inspection task with the data given.
   */
  const submitAction = async () => {
    if (!descriptionError) {
      setLoading(true);
      const resp = await Request("post", "/api/nearMiss/action", actionProps);
      console.log(resp);
      showSnackbar(
        resp,
        setSeverity,
        setSnackbarMessage,
        setSnackbar,
        language.etc.added,
        tableRef
      );
      handleCloseModal();
      setLoading(false);
    }
  };

  const getUserFromId = (id) => {
    for (let i = 0; i < options.users.length; i++) {
      if (options.users[i].id === id) {
        return options.users[i];
      }
    }
    return null;
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <FormControl
          noValidate
          autoComplete="off"
          className={classes.formControl}
        >
          <Autocomplete //PERSONNEL
            id="combo-box"
            options={options.users?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              actionProps.assignedTo
                ? getUserFromId(actionProps.assignedTo)
                : null
            }
            onChange={async (event, value) => {
              setActionProps({ ...actionProps, assignedTo: value?.id });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Personel"
                className={classes.textField}
              />
            )}
          />
          <CustomDatePicker //TERMINATION DATE
            style={classes.textField}
            label="Termin Tarihi"
            value={
              actionProps.terminationDate
                ? moment(actionProps.terminationDate)
                : null
            }
            shrink={actionProps.terminationDate}
            onChangeFunc={(value) =>
              setActionProps({
                ...actionProps,
                terminationDate: moment(value)
                  .set("hour", 23)
                  .set("minute", 59)
                  .toISOString(true),
              })
            }
          />
          <TextArea //DESCRIPTION
            label={"Açıklama"}
            error={descriptionError}
            value={actionProps.note}
            shrink={actionProps.note}
            style={classes.textField}
            onChangeFunc={(value) => {
              if (value.target.value.length > 1000) {
                setDescriptionError("Açıklama 1000 karakterden uzun olamaz.");
              } else {
                descriptionError && setDescriptionError(null);
                setActionProps({ ...actionProps, note: value.target.value });
              }
            }}
          />
          <div className={classes.buttonContainer}>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => submitAction()}
                className={classes.submit}
              >
                {"OLUŞTUR"}
              </Button>
            )}
          </div>
        </FormControl>
      </TableEntryModal>
    </>
  );
}
