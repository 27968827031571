import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from './FetchData';
import NewUserModal from './NewUserModal';
import GetOptions, { getDepartments } from './GetOptions';
import { List, ListItem, ListItemText } from "@material-ui/core";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import Table from "../../../components/Table/Table";
import GetFilterFields from './UserFilters';
import DeleteModal from "./DeleteModal";
import TransferModal from "./TransferModal";
import SessionHelper from "../../../helpers/SessionHelper";
import MultipleUserModal from "./MultipleUserModal";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  root: {
    maxHeight: 200,
    overflow: "auto",
  },
  addButton: {
    marginLeft: "12px"
  },
  editButton: {
    marginLeft: "12px"
  },
  list: {
    height: 100,
    overflow: "auto"
  },
  towns: {
    width: 250,
    height: 100,
    overflow: "auto"
  },
}));

export default function UserTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [oldUser, setOldUser] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [multipleAddModal, setMultipleAddModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [transferModal, setTransferModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [companyType, setCompanyType] = React.useState(null);
  const [idToDelete, setIdToDelete] = React.useState(null);
  
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    active: null,
    company: null,
    department: null,
    isg: null,
    edu: null,
    gender: null,
    marital: null,
    enabled: null,
    manager: null,
    role: null,
    isAppUser: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id", editable: "never" },
      { title: "Adı", field: "firstName" },
      { title: "Soyadı", field: "lastName" },
      { title: "Şirket Adı", field: "companyName" },
      { title: "Rolü", field: "roles", render: rowData => 
        <>
          <List className={classes.list}>
            {
              rowData?.roles.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={options.roles[`${data}`]}/>
                </ListItem>
              ))
            }
          </List>
        </>
      },
      { title: "Ünvanı", field: "title" },
      { title: "Email Adresi", field: "email" },
      { title: "Uygulama Kullanıcısı", field: "isAppUser", lookup: options.isg, emptyValue: "Hayır" },
      { title: "Aktiflik", field: "enabled", lookup: options.active, emptyValue: "Aktif Değil" },
      { title: "Yöneticisi", field: "managerName" },
      { title: "Bölümü", field: "departmentName" },
      { title: "Uygunsuzluk Giderdiği Bölümler ve Bölgeler", field: "unsuitTowns", render: rowData => 
      <>
        <List className={classes.towns}>
          {
            rowData?.unsuitTowns.map((data, index) => (
              <ListItem key={index}>
                <ListItemText secondary={data}/>
              </ListItem>
            ))
          }
        </List>
      </>
      },
      { title: "Sorumluluk Bölgeleri", field: "responsibilityRegions", render: rowData => 
      <>
        <List className={classes.towns}>
          {
            rowData?.responsibilityRegions.map((data, index) => (
              <ListItem key={index}>
                <ListItemText secondary={data?.name}/>
              </ListItem>
            ))
          }
        </List>
      </>
      },
      { title: "SGK Numarası", field: "userSGKNo" },
      { title: "TC Numarası", field: "tcNo" },
      { title: "Doğum Tarihi", field: "birthDate" },
      { title: "Telefon Numarası", field: "phoneNo" },
      { title: "Günlük Çalışma Saati", field: "dailyHourOfWork" },
      { title: "Eğitim Durumu", field: "educationStatus" },
      { title: "Evlilik Durumu", field: "maritalStatus" },
      { title: "Cinsiyeti", field: "gender" },
      { title: "İşe Başlama Tarihi", field: "startDateOfWork" },
      { title: "İSG Eğitimi Aldı Mı", field: "didGetISGTraining", lookup: options.isg },
      { title: "İşten Çıkış Tarihi", field: "deletedAt" },
    ]);
    setBackdropLoading(false);
  }, [classes]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    let newOpts = {...options};
    specialContractor && (newOpts.departments = await getDepartments(user?.companyId));
    setOptions(newOpts);
    setModal(true);
    setModalLoading(false);
  }

  const handleMultipleAddModal = () => {
    setModalLoading(true);
    setMultipleAddModal(true);
    setModalLoading(false);
  }

  const handleOpenDeleteModal = async (data) => {
    setModalLoading(true);
    setDeleteModal(true);
    setIdToDelete(data?.id);
    setCompanyType(data?.companyType);
    setModalLoading(false);
  }

  const handleOpenTransferModal = async (data) => {
    setModalLoading(true);
    setTransferModal(true);
    setOldUser(data);
    setModalLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      active: null,
      company: null,
      department: null,
      isg: null,
      edu: null,
      gender: null,
      marital: null,
      enabled: null,
      manager: null,
      role: null,
      isAppUser: null,
    });
  }

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <NewUserModal
        modal={modal} 
        setModal={setModal} 
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
        tableRef={tableRef} 
        oldUserProps={oldUser}
        opts={options}
        modalLoading={modalLoading}
      />
      <MultipleUserModal
        modal={multipleAddModal}
        setModal={setMultipleAddModal}
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
        tableRef={tableRef}
        modalLoading={modalLoading}
      />
      <DeleteModal
        modal={deleteModal} 
        setModal={setDeleteModal} 
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
        tableRef={tableRef}
        companyType={companyType}
        id={idToDelete}
        setId={setIdToDelete}
      />
      <TransferModal
        modal={transferModal} 
        modalLoading={modalLoading}
        userToDelete={oldUser}
        setModal={setTransferModal} 
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
      />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters/>
      <Table
        tableName={language.sidebar.users}
        authName="usersTable"
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenModal={handleOpenModal}
        handleMultipleAddModal={handleMultipleAddModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        handleOpenTransferModal={handleOpenTransferModal}
        transferTooltip={rowData => !rowData.tasks ? "Devredilecek Görev Yok" : "Görevleri Devret"}
        deleteTooltip={rowData => !rowData.isActive ? "Kullanıcı Silinmiş" : (rowData.tasks ? "Önce Görevleri Devredin" : "Sil")}
        filters={filterProps}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={(user, rowData) => rowData.isActive && !rowData.tasks}
        isEditHidden={true}
        isTransferable={(user, rowData) => rowData.tasks}
        rowStyle={rowData => ({backgroundColor: !rowData.isActive && "#804f4f"})}
        detailsWindow={rowData => window.open("/user/" + rowData.id)}
        guideLink={"/api/guides/user-guide/file"}
        guideName={"kullanıcılar_kılavuz"}
      />
    </>
  )
}