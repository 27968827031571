import React from "react";
import { Collapse, Grid, makeStyles, Paper } from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { TableFiltersButtons } from "../../../components/Table/TableFilters";
import { TableFiltersOpenButton } from "../../../components/Table/TableFilters";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import LanguageHelper from "../../../helpers/LanguageHelper";
import jsonMap from "../../../components/Utils/jsonMap";
import { succession, platform } from "../../../assets/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [backdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.userId}
                label={language.history.userId}
                value={tableFilters.userId}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    userId: parseInt(value.target.value),
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.history.signInStatus}
                value={tableFilters.isSuccessful}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    isSuccessful: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, succession)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={"Platform"}
                value={tableFilters.platform}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    platform: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, platform)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.history.createdAtStart}
                value={
                  tableFilters.createdAtStart
                    ? moment(tableFilters.createdAtStart)
                    : null
                }
                shrink={tableFilters.createdAtStart}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    createdAtStart: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true)
                      .slice(0, -10),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.history.createdAtEnd}
                value={
                  tableFilters.createdAtEnd
                    ? moment(tableFilters.createdAtEnd)
                    : null
                }
                shrink={tableFilters.createdAtEnd}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    createdAtEnd: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true)
                      .slice(0, -10),
                  })
                }
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
