import SessionHelper from "./SessionHelper";

let authorizationLookup = {};
const user = SessionHelper.getUser();

// const resp = Request("get", "/api/auth/get-authorization-list");
// resp.then((val) => {
//   console.log(val);
// })

// PREVIOUS AUTHS FOR DASHBOARD

// authorizationLookup.dashboard = {
//   "ROLE_SİSTEM_ADMİNİ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: true
//   },
//   "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: true
//   },
//   "ROLE_ISG_UZMANI": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: true
//   },
//   "ROLE_YONETSEL_BIRIM_YONETICISI": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_YONETSEL_BIRIM_PERSONELI": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: false,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   },
//   "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_OPERASYONEL_BİRİM_PERSONELİ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: false,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   },
//   "ROLE_YUKLENICI_YÖNETİCİ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_YUKLENICI_PERSONEL": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: false,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   },
//   "ROLE_YUKLENICI_ISG_UZMANI": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_YUKLENICI_OSGB_ISG_UZMANI": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_OSGB_ISG_UZMANI": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_BÖLGE_MÜDÜRÜ": {
//     personalGoals: true,
//     personalTasks: true,
//     personalUnsuitTasks: true,
//     departmentTasks: true,
//     companyTasks: true,
//     departmentUnsuitTasks: true,
//     companyUnsuitTasks: true,
//     departmentTasksPerUser: true,
//     companyTasksPerUser: true,
//     departmentUnsuitTasksPerUser: true,
//     companyUnsuitTasksPerUser: true,
//     tasksPerDepartment: true,
//     tasksPerDate: false
//   },
//   "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ": {
//     personalGoals: false,
//     personalTasks: false,
//     personalUnsuitTasks: false,
//     departmentTasks: false,
//     companyTasks: false,
//     departmentUnsuitTasks: false,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: false,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: false,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   },  
//   "ROLE_OSGB_SAĞLIK_PERSONELİ": {
//     personalGoals: false,
//     personalTasks: false,
//     personalUnsuitTasks: false,
//     departmentTasks: false,
//     companyTasks: false,
//     departmentUnsuitTasks: false,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: false,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: false,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   },    
//   "ROLE_SAĞLIK_PERSONELİ": {
//     personalGoals: false,
//     personalTasks: false,
//     personalUnsuitTasks: false,
//     departmentTasks: false,
//     companyTasks: false,
//     departmentUnsuitTasks: false,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: false,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: false,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   },    
//   "ROLE_INNOVEN_ISG_UZMANI": {
//     personalGoals: false,
//     personalTasks: false,
//     personalUnsuitTasks: false,
//     departmentTasks: false,
//     companyTasks: false,
//     departmentUnsuitTasks: false,
//     companyUnsuitTasks: false,
//     departmentTasksPerUser: false,
//     companyTasksPerUser: false,
//     departmentUnsuitTasksPerUser: false,
//     companyUnsuitTasksPerUser: false,
//     tasksPerDepartment: false,
//     tasksPerDate: false
//   }              
// };
authorizationLookup.dashboard = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: true},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: true},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: true},
  
};
authorizationLookup.yasamUsersTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, goals: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, goals: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, goals: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, goals: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, goals: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, goals: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, goals: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, goals: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, goals: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, goals: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, goals: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, goals: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: false, delete: false, goals: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, goals: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, goals: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, goals: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, goals: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, goals: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, goals: false},
    "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, goals: false},
    "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, goals: false},
};
authorizationLookup.usersTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true, multipleCreate: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true, multipleCreate: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true, multipleCreate: true},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: false, delete: true, edit: false, multipleCreate: false},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true, multipleCreate: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true, multipleCreate: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, create: true, delete: true, edit: true, multipleCreate: true},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false, multipleCreate: false},
    "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false, multipleCreate: false},
};
authorizationLookup.companiesTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, create: true, delete: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, create: true, delete: false},
    "ROLE_ISG_UZMANI":                          {view: true, edit: true, create: true, delete: false},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, edit: false, create: false, delete: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, create: false, delete: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, edit: false, create: false, delete: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, create: false, delete: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, edit: false, create: false, delete: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, edit: false, create: false, delete: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, edit: false, create: false, delete: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, edit: false, create: false, delete: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: false, edit: false, create: false, delete: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, edit: false, create: false, delete: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, edit: false, create: false, delete: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, create: true, delete: true, edit: true},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.citiesTownsTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false},
    "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false},
    "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false},
};
authorizationLookup.departmentsTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
    "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, create: true, delete: true, edit: true},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.formTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
    "ROLE_ISG_UZMANI":                          {view: false},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
    "ROLE_KALITE_KONTROL_YONETICISI":           {view: false},
    "ROLE_KALITE_KONTROL_PERSONELI":            {view: false},
};
authorizationLookup.formTemplateTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true},
    "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false},
    "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false},
    "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false},
};
/* authorizationLookup.taskTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: false, delete: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: false, delete: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: false, edit: false, delete: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: false, edit: false, delete: true},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: false, edit: false, delete: true},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: false, edit: false, delete: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: false, edit: false, delete: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: false, edit: false, delete: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: false, edit: false, delete: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: false, edit: false, delete: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: false, edit: false, delete: true},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, edit: false, delete: true},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false}
}; */
authorizationLookup.tasksTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: false, edit: false, delete: true, assign: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false, assign: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: false, edit: false, delete: true, assign: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false, assign: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false, assign: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false, assign: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, edit: true, delete: true, assign: true},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, edit: false, delete: true, assign: true},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false, assign: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false, assign: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false, assign: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, create: false, delete: false, edit: false, assign: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: true, create: false, delete: false, edit: false, assign: false},
    "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false, assign: false},
    "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false, assign: false},
};
const yukleniciPersonelDenetimGorevleri = () => {
  if(user?.nonconformityUser) {
    return {view: true, create: true, edit: true, delete: true, goals: false};
  } else {
    if(user?.appUser) {
      return {view: true, create: true, edit: true, delete: true, goals: false};
    } else {
      return {view: true, create: false, edit: false, delete: false, goals: false};
    }
  }
}
authorizationLookup.personalTaskTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: false, edit: true, delete: false, goals: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: true, delete: true, goals: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, edit: true, delete: true, goals: false},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: false, edit: false, delete: true, goals: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false, goals: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: false, edit: false, delete: true, goals: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false, goals: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, edit: true, delete: true, goals: false},
    "ROLE_YUKLENICI_PERSONEL":                  yukleniciPersonelDenetimGorevleri(),
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, edit: true, delete: true, goals: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, edit: true, delete: true, goals: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, edit: true, delete: true, goals: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, edit: true, delete: true, goals: true},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: false, edit: false, delete: true, goals: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false, goals: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false, goals: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false, goals: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false, goals: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false, goals: false},
    "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false, goals: false},
    "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false, goals: false},
};
authorizationLookup.nearMissTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, delete: true, create: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, delete: true, create: true},
  "ROLE_ISG_UZMANI":                          {view: true, edit: true, delete: true, create: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, edit: false, delete: false, create: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, delete: false, create: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, edit: false, delete: false, create: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, edit: false, delete: false, create: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, edit: true, delete: true, create: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, edit: false, delete: false, create: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, edit: false, delete: false, create: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: true, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.healthMonitoring = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, delete: true, create: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, delete: true, create: true},
  "ROLE_ISG_UZMANI":                          {view: false, edit: false, delete: false, create: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, edit: false, delete: false, create: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, delete: false, create: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, edit: false, delete: false, create: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, edit: false, delete: false, create: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, edit: false, delete: false, create: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, edit: false, delete: false, create: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, edit: false, delete: false, create: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, edit: false, delete: false, create: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, edit: true, delete: true, create: true},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, edit: true, delete: true, create: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, edit: true, delete: true, create: true},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, edit: false, delete: false, create: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.unsuitTaskTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, delete: true, approve: false},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, delete: true, approve: true},
    "ROLE_ISG_UZMANI":                          {view: true, edit: true, delete: true, approve: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, edit: true, delete: false, approve: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, delete: false, approve: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, edit: true, delete: false, approve: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, delete: false, approve: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, edit: true, delete: false, approve: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: true, edit: true, delete: false, approve: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, edit: true, delete: false, approve: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, edit: false, delete: false, approve: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, edit: true, delete: true, approve: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, edit: true, delete: false, approve: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, edit: true, delete: false, approve: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, edit: false, delete: false, approve: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, edit: false, delete: false, approve: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, edit: false, delete: false, approve: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: true, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.actionTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, delete: true, approve: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, delete: true, approve: true},
  "ROLE_ISG_UZMANI":                          {view: true, edit: true, delete: true, approve: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, edit: false, delete: false, approve: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, delete: false, approve: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, edit: true, delete: false, approve: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, edit: false, delete: false, approve: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, edit: true, delete: true, approve: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, edit: false, delete: false, approve: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, edit: false, delete: false, approve: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, edit: false, delete: false, approve: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, edit: false, delete: false, approve: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, edit: false, delete: false, approve: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, edit: false, delete: false, approve: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: true, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.workAccidentTable = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.epdkWorkAccidentTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: false, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
};
authorizationLookup.workAccidentActionTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, delete: true, approve: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, delete: true, approve: true},
  "ROLE_ISG_UZMANI":                          {view: true, edit: true, delete: true, approve: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, edit: false, delete: false, approve: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, delete: false, approve: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, edit: true, delete: false, approve: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, edit: false, delete: false, approve: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, edit: true, delete: true, approve: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, edit: false, delete: false, approve: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, edit: false, delete: false, approve: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, approve: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, approve: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, approve: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, approve: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, approve: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, approve: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, approve: false, delete: false, edit: false},
};
authorizationLookup.edvarsAccidentTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
};
authorizationLookup.comitteeDepartmentGoalCharts = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, export: true, exportAll: true},
  "ROLE_ISG_UZMANI":                          {view: true, export: true, exportAll: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, export: true, exportAll: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, export: false, exportAll: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},    
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},    
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},    
}
authorizationLookup.contractorFieldInspection = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, export: true, exportAll: true},
  "ROLE_ISG_UZMANI":                          {view: true, export: true, exportAll: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, export: true, exportAll: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
}
authorizationLookup.contractorPerformance = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, export: true, exportAll: true},
  "ROLE_ISG_UZMANI":                          {view: true, export: true, exportAll: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, export: true, exportAll: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
}
authorizationLookup.ygkReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, export: true, exportAll: true},
  "ROLE_ISG_UZMANI":                          {view: true, export: true, exportAll: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, export: true, exportAll: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
}
authorizationLookup.trainingRecords = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, delete: false, create: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, delete: true, create: true, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.trainingCatalogues = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, delete: true, create: true, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.jobRiskAnalysis = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, delete: false, create: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.boards = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.boardActions = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.riskAnalysis = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, delete: false, create: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.riskActions = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, delete: true, create: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.emergencyPlans = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, delete: false, create: false, edit: false},//
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, delete: false, create: false, edit: false},//
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, delete: false, create: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.drills = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, delete: true, create: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, delete: true, create: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, delete: false, create: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, delete: false, create: false, edit: false},//
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, delete: false, create: false, edit: false},//
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, delete: true, create: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, delete: false, create: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, delete: false, create: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, delete: false, create: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, delete: false, create: false, edit: false},
}
authorizationLookup.drillActionTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, edit: true, delete: true, approve: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, edit: true, delete: true, approve: true},
  "ROLE_ISG_UZMANI":                          {view: true, edit: true, delete: true, approve: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, edit: false, delete: false, approve: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, edit: false, delete: false, approve: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, edit: false, delete: false, approve: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, edit: false, delete: false, approve: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, edit: false, delete: false, approve: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, edit: true, delete: true, approve: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, edit: false, delete: false, approve: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, edit: false, delete: false, approve: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, edit: false, delete: false, approve: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, edit: false, delete: false, approve: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, edit: false, delete: false, approve: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, edit: false, delete: false, approve: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, edit: false, delete: false, approve: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, edit: false, delete: false, approve: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, edit: false, delete: false, approve: false},
}
authorizationLookup.kkd = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: true, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: true, delete: true},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.kkdTask = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: true, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: true, delete: true},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.kkdDebt = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: true, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: true, delete: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, edit: true, delete: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.missingKKD = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: true, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: true, delete: true},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.crews = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: true, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: true, delete: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, edit: true, delete: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.kkdReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, export: true, exportAll: true},
  "ROLE_ISG_UZMANI":                          {view: true, export: true, exportAll: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, export: true, exportAll: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, export: true, exportAll: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, export: false, exportAll: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, export: false, exportAll: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
}
authorizationLookup.heavyEquipmentTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: false, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: false, delete: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, edit: false, delete: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, edit: false, delete: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, edit: false, delete: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.vehicleTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, edit: false, delete: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, edit: false, delete: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, edit: false, delete: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, edit: false, delete: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, edit: false, delete: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, edit: false, delete: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, edit: false, delete: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, edit: false, delete: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, edit: false, delete: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, edit: false, delete: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, edit: false, delete: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, delete: false, create: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, delete: false, create: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, delete: false, create: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, delete: false, create: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.logs = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false},
  "ROLE_ISG_UZMANI":                          {view: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
}
authorizationLookup.headcounts = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.oditAnalysis = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, export: true, exportAll: true},
  "ROLE_ISG_UZMANI":                          {view: true, export: true, exportAll: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, export: false, exportAll: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
}

authorizationLookup.wasteMeasurementRecords = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.wasteMeasurementCatalogues = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.wasteDischargeRecords = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.wasteDischargeCatalogues = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.loginStatus = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
}
authorizationLookup.contractorWorkAccidents = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
}
authorizationLookup.contractorAudits = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
}
authorizationLookup.contractorNonconformities = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
}
authorizationLookup.contractorOverall = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
}
authorizationLookup.crewNumbers = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: false, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.contractorITAReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
};
authorizationLookup.contractorToolbox = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
};
authorizationLookup.trainingReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
};
authorizationLookup.certificateReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
};
authorizationLookup.isgTrainingReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
};
authorizationLookup.healthStatusReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
};
authorizationLookup.personnelDressSize = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
};
authorizationLookup.technicalTrainingReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
};

authorizationLookup.firstAidCertificateReport = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false},
};

authorizationLookup.consumptionManagementRecords={
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
};

authorizationLookup.consumptionManagementCatalogues={
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
};

authorizationLookup.signInHistory= {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, export: false, exportAll: false},
  "ROLE_ISG_UZMANI":                          {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, export: false, exportAll: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, export: false, exportAll: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
}

authorizationLookup.activationHistory = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, export: false, exportAll: false},
  "ROLE_ISG_UZMANI":                          {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, export: false, exportAll: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, export: false, exportAll: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
}

authorizationLookup.resetHistory = {
"ROLE_SİSTEM_ADMİNİ":                       {view: true, export: true, exportAll: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, export: false, exportAll: false},
"ROLE_ISG_UZMANI":                          {view: false, export: false, exportAll: false},
"ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, export: false, exportAll: false},
"ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, export: false, exportAll: false},
"ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, export: false, exportAll: false},
"ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, export: false, exportAll: false},
"ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, export: false, exportAll: false},
"ROLE_YUKLENICI_PERSONEL":                  {view: false, export: false, exportAll: false},
"ROLE_YUKLENICI_ISG_UZMANI":                {view: false, export: false, exportAll: false},
"ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, export: false, exportAll: false},
"ROLE_OSGB_ISG_UZMANI":                     {view: false, export: false, exportAll: false},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, export: false, exportAll: false},
"ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, export: false, exportAll: false},
"ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, export: false, exportAll: false},
"ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, export: false, exportAll: false},
"ROLE_SAĞLIK_PERSONELİ":                    {view: false, export: false, exportAll: false},
"ROLE_INNOVEN_ISG_UZMANI":                  {view: false, export: false, exportAll: false},
"ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, export: false, exportAll: false},
"ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, export: false, exportAll: false},
"ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, export: false, exportAll: false},
}

authorizationLookup.notices = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  }

authorizationLookup.accidentDefinition = {
    "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
    "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
    "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
    "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
    "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
    "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
    "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
    "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
    "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
    "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: true, edit: true},
    "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
    "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
    "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
    "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
    "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
    "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
    "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
    "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
    "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.accidentType = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.accidentPlace = {
"ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
"ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
"ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
"ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
"ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
"ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: true, edit: true},
"ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
"ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
"ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
"ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
"ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
"ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
"ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
"ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
"ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.injuryType = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.bodyPart = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.lostLimb = {
"ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
"ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
"ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
"ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
"ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
"ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true,  delete: true, edit: true},
"ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
"ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
"ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
"ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
"ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
"ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
"ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
"ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
"ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.section = {
"ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
"ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
"ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
"ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
"ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
"ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: true, edit: true},
"ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: true, edit: true},
"ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
"ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: true, edit: true},
"ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
"ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: true, edit: true},
"ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
"ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
"ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
"ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
"ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
"ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.tosLogs = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true},
  "ROLE_ISG_UZMANI":                          {view: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false}, 
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false}, 
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false}, 
}

authorizationLookup.illnessReports = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: true, edit: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  }
authorizationLookup.moduleTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
}
authorizationLookup.authTable = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_INNOVEN_ISG_UZMANI":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":                 {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":                 {view: false, create: false, delete: false, edit: false},
}

// FROM DEMO

authorizationLookup.auditTypes = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.visitorEquipments = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.visitorDocs = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.visitorCompany = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: false, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: false, edit: true},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: false, edit: true},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: false, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: false, edit: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: false, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: false, edit: true},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: true, delete: false, edit: true},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: true, delete: false, edit: true},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}
  
authorizationLookup.visitorVideos = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.visitors = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: false, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: false, edit: true},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: false, edit: true},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: false, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: false, edit: true},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: false, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: false, edit: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: false, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: false, edit: true},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: false, edit: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: false, edit: true},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: true},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: true},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}
  
authorizationLookup.visitTypes = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.visits = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: true, create: true, delete: true, edit: true},
  "ROLE_ISG_UZMANI":                          {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: true, create: true, delete: true, edit: true},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: true, create: true, delete: true, edit: true},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_PERSONEL":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: true, create: true, delete: false, edit: true},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: true, create: true, delete: false, edit: true},
  "ROLE_OSGB_ISG_UZMANI":                     {view: true, create: true, delete: false, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: true, create: true, delete: false, edit: true},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: true, create: true, delete: true, edit: true},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: true, create: true, delete: false, edit: true},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: true, create: true, delete: false, edit: true},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: true, create: true, delete: true, edit: true},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: true, create: true, delete: false, edit: true},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: true, delete: false, edit: true},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: true, delete: false, edit: true},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.ibys = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.qualityControls = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: true, create: true, delete: true, edit: true},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: true, create: true, delete: true, edit: true},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: true, create: true, delete: true, edit: true},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}

authorizationLookup.currentVisitors = {
  "ROLE_SİSTEM_ADMİNİ":                       {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ": {view: false, create: false, delete: false, edit: false},
  "ROLE_ISG_UZMANI":                          {view: true, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_YONETSEL_BIRIM_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ":        {view: false, create: false, delete: false, edit: false},
  "ROLE_OPERASYONEL_BİRİM_PERSONELİ":         {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_YÖNETİCİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_PERSONEL":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_ISG_UZMANI":                {view: false, create: false, delete: false, edit: false},
  "ROLE_YUKLENICI_OSGB_ISG_UZMANI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_ISG_UZMANI":                     {view: false, create: false, delete: false, edit: false},
  "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ":      {view: false, create: false, delete: false, edit: false},
  "ROLE_BÖLGE_MÜDÜRÜ":                        {view: false, create: false, delete: false, edit: false},
  "ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ":     {view: false, create: false, delete: false, edit: false},
  "ROLE_OSGB_SAĞLIK_PERSONELİ":               {view: false, create: false, delete: false, edit: false},
  "ROLE_SAĞLIK_PERSONELİ":                    {view: false, create: false, delete: false, edit: false},
  "ROLE_GÜVENLİK_GÖREVLİSİ":                  {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_YONETICISI":           {view: false, create: false, delete: false, edit: false},
  "ROLE_KALITE_KONTROL_PERSONELI":            {view: false, create: false, delete: false, edit: false},
  "ROLE_GDZ_EYAŞ_İSG_UZMANI":                 {view: false, create: false, delete: false, edit: false},
}
    

export function getLookup() {
  return authorizationLookup;
}

export function getViewAuthorizationForAll(roles) {
  // authorizationLookup.resp = await Request("get", "/api/auth/get-authorization-list");
  // console.log(resp);
  // authorizationLookup.array = resp.data;
  // let authorization = {};
  // for(let i = 0; i < array.length; i++) {
  //   if(array[i]?.page === page) {
  //     authorizationLookup.authList = array[i]?.authorizationList;
  //     for(let j = 0; j < authList.length; j++) {
  //       if(authList[j] === "READ") {authorization.view = true}
  //       else if(authList[j] === "CREATE") {authorization.create = true}
  //       else if(authList[j] === "DELETE") {authorization.delete = true}
  //       else if(authList[j] === "GOALS") {authorization.goals = true}
  //       else if(authList[j] === "ASSIGN") {authorization.assign = true}
  //       else if(authList[j] === "EDIT") {authorization.edit = true}
  //       else if(authList[j] === "APPROVE") {authorization.approve = true}
  //       else if(authList[j] === "EXPORT") {authorization.export = true}
  //       else if(authList[j] === "EXPORT_ALL") {authorization.exportAll = true}
  //     }
  //   }
  // }

  let authorization = {};
  for (let i = 0; i < roles.length; i++) {
    for (let page in authorizationLookup) {
      if (page !== "dashboard") {
        authorization[page] = authorization[page]
          ? true
          : authorizationLookup[page][roles[i]].view;
      }
    }
  }
  return authorization;
}

export function getAuthorizationForPage(roles, page) {
  // const resp = await Request("get", "/api/auth/get-authorization-list");
  // console.log(resp);
  // const array = resp.data;
  // let authorization = {};

  // for(let i = 0; i < array.length; i++) {
  //   if(array[i]?.page === page) {
  //     const authList = array[i]?.authorizationList;
  //     for(let j = 0; j < authList.length; j++) {
  //       if(authList[j] === "READ") {authorization.view = true}
  //       else if(authList[j] === "CREATE") {authorization.create = true}
  //       else if(authList[j] === "DELETE") {authorization.delete = true}
  //       else if(authList[j] === "GOALS") {authorization.goals = true}
  //       else if(authList[j] === "ASSIGN") {authorization.assign = true}
  //       else if(authList[j] === "EDIT") {authorization.edit = true}
  //       else if(authList[j] === "APPROVE") {authorization.approve = true}
  //       else if(authList[j] === "EXPORT") {authorization.export = true}
  //       else if(authList[j] === "EXPORT_ALL") {authorization.exportAll = true}
  //     }
  //   }
  // }

  let authorization = {};
  for (let i = 0; i < roles.length; i++) {
    for (let prop in authorizationLookup[page][roles[i]]) {
      authorization[prop] = authorization[prop]
        ? true
        : authorizationLookup[page][roles[i]][prop];
    }
  }
  return authorization;
}