import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import OtherFiles from './OtherFiles';

const useStyles = makeStyles((theme) => ({
  details: {
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2D3446',
  },
  dividerStyle: {
    margin: 20
  }
}));

/**
 * Details panel component, renders the files if exists and has a dropzone
 * to upload files.
 * @param {rowData: obj, tableRef: obj} props to use in the details panel 
 */
export default function EPDKDetails({ rowData, tableRef, setSeverity, setSnackbarMessage, setSnackbar }) {
  const classes = useStyles();

  return (
    <div className={classes.details}>
      <OtherFiles 
        rowData={rowData}
        tableRef={tableRef}  
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
      />
    </div>
  )
}