import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2D3446',
  },
  alignLeft: {
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: "20px"
  },
  title: {
    fontSize: "18px"
  },
  props: {
    fontSize: "14px"
  },
}));

export default function Details({ rowData }) {
  const classes = useStyles();

  const PrintDepartments = () => {
    return (
      rowData.departments.map((data, index) => {
        return (
          <div key={index}>
            <Grid container>
              <Grid item xs={1} className={classes.alignLeft}><p className={classes.props}>{data["id"]}</p></Grid>
              <Grid item xs={5} className={classes.alignLeft}><p className={classes.props}>{data["name"]}</p></Grid>
              <Grid item xs={2} className={classes.alignLeft}><p className={classes.props}>{data["city"] && data["city"]["name"]}</p></Grid>
              <Grid item xs={2} className={classes.alignLeft}><p className={classes.props}>{data["town"] && data["town"]["name"]}</p></Grid>
              <Grid item xs={2} className={classes.alignLeft}><p className={classes.props}>{data["fieldOfActivity"] ? data["fieldOfActivity"] : "Yok"}</p></Grid>
            </Grid>
          </div>
        )
      })
    );
  }

  return (
    <>
      <div className={classes.details}>
        {rowData?.departments?.length > 0 ?
          <>
            <Grid container>
              <Grid item xs={1} className={classes.alignLeft}><p className={classes.title}>No</p></Grid>
              <Grid item xs={5} className={classes.alignLeft}><p className={classes.title}>Bölüm</p></Grid>
              <Grid item xs={2} className={classes.alignLeft}><p className={classes.title}>Şehir</p></Grid>
              <Grid item xs={2} className={classes.alignLeft}><p className={classes.title}>İlçe</p></Grid>
              <Grid item xs={2} className={classes.alignLeft}><p className={classes.title}>Faaliyet Tipi</p></Grid>
            </Grid>
            <PrintDepartments/>
          </>
          : <p style={{fontSize: 16, color: "red"}}>Şirketin Bölümü Yok</p>}
      </div>
    </>
  );
}