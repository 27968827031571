import React from 'react';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { MobileStepper, Button } from '@material-ui/core';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { makeStyles, createTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: "rgb(200, 200, 200)",
    flexGrow: 1,
    margin: "0 auto"
  }
}));

/**
 * A dotted stepper with back and next buttons to step through the questions of the template.
 * @param {formTemplate: obj, activeStep: number, handleNext: func, handleBack: func} param
 * props of the pagestepper
 */
export default function PageStepper({ formTemplate, activeStep, handleNext, handleBack }) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      type: 'dark',
    },
  });
  return (
    <MobileStepper
      variant="dots"
      steps={formTemplate === null ? 1 : formTemplate.questions.length}
      position="static"
      activeStep={activeStep}
      className={classes.root}
      nextButton={
        <Button size="small" onClick={handleNext} disabled={activeStep === (formTemplate === null ? 1 : formTemplate.questions.length - 1) ||
          (formTemplate.questions.length === 0)}>
          {language.form.next}
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
          {language.form.back}
        </Button>
      }
    />
  )
}