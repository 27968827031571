import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import SessionHelper from "../../../../../helpers/SessionHelper";
import MaterialTable from "@material-table/core";
import LanguageHelper from "../../../../../helpers/LanguageHelper";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import TrainingModal from "./TrainingModal";
import Request from "../../../../../helpers/Request";
import showSnackbar from '../../../../../components/Utils/showSnackbar';
import CustomFileDropzone from '../../../../../components/Files/CustomFileDropzone';

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  divider: {
    marginTop: 20,
    marginBottom: 10
  },
  participants: {
    width: 250,
    height: 100,
    overflow: "auto"
  },
  dropzone: {
    width: "350px",
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Transfer: forwardRef((props, ref) => <SyncAltIcon {...props} ref={ref} />)
};

export default function OtherTrainings({
  userProps,
  options,
  error,
  setError,
  setSnackbar,
  setSnackbarMessage,
  setSeverity
}) {
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const id = user?.company?.id;
  const columns = [
    { field: 'id', title: 'No' },
    { field: 'topics', title: 'Adı' },
    { field: 'date', title: 'Tarih' },
    { field: 'time', title: 'Zaman' },
    { field: 'place', title: 'Eğitim Yeri' },
    { field: 'instructor', title: 'Eğitmen' },
    { field: 'files', title: 'Dosyalar', render: rowData => <RenderDropzone files={rowData.files} />}
  ];

  const [update, setUpdate] = React.useState(false);
  const [data, setData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [trainingModal, setTrainingModal] = React.useState(null);

  const getTrainings = useCallback(async () => {
    const resp = await Request("get", "/api/training/other/" + userProps?.id);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }
    return resp.data;
  }, [language, setSeverity, setSnackbarMessage, setSnackbar, userProps]);

  const init = useCallback(async () => {
    setLoading(true);
    const data = formatFiles(await getTrainings());
    setData(data);
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update]);
  React.useEffect(() => {
    init();
  }, [init]);
  
  const RenderDropzone = useCallback(({files}) => {
    return(
      <div className={classes.dropzone}>
        <CustomFileDropzone
          files={files}
          setFiles={(value) => {console.log(value)}}
          fileLimit={30}
          onlyShowTitleWhenEmpty
          disabled
          dropzoneText={"Dosyalar"}
        />
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProps]);

  const handleOpenModal = async (data) => {
    setTrainingModal(data);
  }

  const deleteTraining = async (data) => {
    const resp = await Request("delete", "/api/training/other/" + data?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, null, updateTable);
  }

  const updateTable = () => {setUpdate(!update)}

  const formatFiles = (trainings) => {
    for(let training of trainings) {
      let trainingFiles = [];
      for(let file of training.files) {
        const name = file.name ? file.name : file?.fileName;
        trainingFiles.push(new File([name], name, { type: "text/plain" }));
      }
      training.files = trainingFiles;
    }
    return trainings;
  }

  return ( 
    <>
      <TrainingModal
        userId={userProps?.id}
        trainingProps={trainingModal}
        setTrainingModal={setTrainingModal}
        options={options}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        update={updateTable}
      />
      <div className={classes.container}>
        <MaterialTable
          title="Diğer Eğitimler"
          localization={language.tableLocalization}
          icons={tableIcons}
          columns={columns}
          isLoading={loading}
          data={data ? [...data] : []}
          options={{
            minBodyHeight: window.innerHeight > 900 ? "57vh" : "47vh",
            maxBodyHeight: window.innerHeight > 900 ? "57vh" : "47vh",
            emptyRowsWhenPaging: false,
          }}
          editable={{
            onRowDelete: ((oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteTraining(oldData);
                  resolve();
                }, 500);
              })),
          }}
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: language.tableLocalization.body.editTooltip,
              position: "row",
              onClick: (event, rowData) => handleOpenModal(rowData),
            },
            {
              icon: tableIcons.Add,
              tooltip: language.tableLocalization.body.addTooltip,
              isFreeAction: true,
              onClick: (event, rowData) => handleOpenModal(rowData),
            }
          ]}
        />
      </div>
    </>
  );
}