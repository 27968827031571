import React, { forwardRef, useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import RiskModal from "./RiskModal";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import { getUsers } from "./GetOptions";
import ActionModal from "../actions/ActionModal";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import AddBox from "@material-ui/icons/AddBox";
import { List, ListItem, ListItemText } from "@material-ui/core";
import moment from "moment";

const textStyle = {
  maxHeight: 100,
  width: 200,
  overflowY: "scroll",
  alignItems: "center",
};

export default function RiskTable({ rowData, options, setOptions }) {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const roles = SessionHelper.getUser()?.roles;
  const authorization = getAuthorizationForPage(roles, "riskAnalysis");
  const user = SessionHelper.getUser();
  const id = user?.company?.id;
  const isAdmin = user?.roles.includes("ROLE_SİSTEM_ADMİNİ");

  const [createActionModal, setCreateActionModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedRisk, setSelectedRisk] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [update, setUpdate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(false);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    let cols = [
      { title: "No", field: "id", width: "60px" },
      { title: "Risk No", field: "no", width: "60px" },
      {
        title: "Faaliyet",
        field: "activity",
        render: (rowData) => <div style={textStyle}>{rowData.activity}</div>,
      },
      {
        title: "Fırsatlar",
        field: "opportunities",
        render: (rowData) => (
          <div style={textStyle}>{rowData.opportunities}</div>
        ),
      },
      {
        title: "Tehlike",
        field: "danger",
        render: (rowData) => <div style={textStyle}>{rowData.danger}</div>,
      },
      {
        title: "Risk",
        field: "risk",
        render: (rowData) => <div style={textStyle}>{rowData.risk}</div>,
      },
      {
        title: "Etkilenen Kişiler",
        field: "affectedEntities",
        render: (rowData) =>
          rowData.affectedEntities ? (
            <div style={textStyle}>{rowData.affectedEntities}</div>
          ) : (
            <>
              <List
                style={{
                  width: 250,
                  height: 100,
                  overflow: "auto",
                }}
              >
                {rowData?.affectedUsers.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={data?.fName + " " + data?.lName} />
                  </ListItem>
                ))}
              </List>
            </>
          ),
      },
      {
        title: "Mevcut Durum",
        field: "currentState",
        render: (rowData) => (
          <div style={textStyle}>{rowData.currentState}</div>
        ),
      },
      { title: "Risk Olasılık", field: "riskProbability" },
      { title: "Risk Şiddet", field: "riskVolume" },
      { title: "Risk Skoru", field: "riskScore" },
      {
        title: "Risk Önem Derecesi",
        field: "priority",
        render: (rowData) => <div style={textStyle}>{rowData.priority}</div>,
      },
      {
        title: "Tedbirler",
        field: "precautions",
        render: (rowData) => <div style={textStyle}>{rowData.precautions}</div>,
      },
      {
        title: "Başlangıç Tarihi",
        field: "startDate",
        render: (rowData) =>
          rowData.startDate
            ? moment(rowData.startDate).format("DD-MM-YYYY")
            : "Yok",
      },
      {
        title: "Bitiş Tarihi",
        field: "endDate",
        render: (rowData) =>
          rowData.endDate
            ? moment(rowData.endDate).format("DD-MM-YYYY")
            : "Yok",
      },
      {
        title: "Gerçekleşme Tarihi",
        field: "completionDate",
        render: (rowData) =>
          rowData.completionDate
            ? moment(rowData.completionDate).format("DD-MM-YYYY")
            : "Yok",
      },
      {
        title: "Sorumlu Kişi",
        field: "responsibleEntities",
        render: (rowData) =>
          rowData.responsibleEntities ? (
            <div style={textStyle}>{rowData.responsibleEntities}</div>
          ) : (
            rowData.responsibleUser &&
            rowData.responsibleUser.fName + " " + rowData.responsibleUser.lName
          ),
      },
      {
        title: "Düzeltici Faaliyetler",
        field: "correctiveActions",
        render: (rowData) => (
          <div style={textStyle}>{rowData.correctiveActions}</div>
        ),
      },
      {
        title: "Düzeltici Faaliyet Olasılık",
        field: "correctiveActionProbability",
      },
      { title: "Düzeltici Faaliyet Şiddet", field: "correctiveActionVolume" },
      {
        title: "Düzeltici Faaliyet Risk Skoru",
        field: "correctiveActionRiskScore",
      },
      {
        title: "Düzeltici Faaliyet Önem Derecesi",
        field: "correctiveActionPriority",
        render: (rowData) => (
          <div style={textStyle}>{rowData.correctiveActionPriority}</div>
        ),
      },
    ];
    setColumns(cols);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, update]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedRisk(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const removeRisk = async () => {
    setLoading(true);
    const resp = await Request(
      "delete",
      "/api/riskAnalysis/risk/" + idToDelete
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      null,
      reload
    );
    handleCloseDeleteDialog();
    setLoading(false);
  };

  const actionModal = async (data) => {
    setModalLoading(true);
    setCreateActionModal(true);
    const users = await getUsers(data?.id);
    setOptions({ users: users });
    setSelectedRisk(data);
    setCreateActionModal(true);
    setModalLoading(false);
  };

  const reload = (data) => {
    data && (rowData.risks = data);
    setUpdate(!update);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeRisk}
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
        customTitle={
          "Riske bağlı bütün aksiyonlar da silinecek, devam etmek istediğine emin misin?"
        }
      />
      <ActionModal
        modalLoading={modalLoading}
        modal={createActionModal}
        setModal={setCreateActionModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        options={options}
        riskProps={selectedRisk}
      />
      <RiskModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        rowData={rowData}
        reload={reload}
        options={options}
        selectedRisk={selectedRisk}
      />
      <Table
        tableName={"Riskler"}
        authName="riskAnalysis"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        isEditHidden={true}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        data={rowData?.risks}
        isDeletable={(rowData) => isAdmin || rowData?.companyId === id}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        reloadFunction={reload}
        extraAction={{
          auth: authorization.create || isAdmin || rowData?.companyId === id,
          icon: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          tooltip: "Aksiyon Yarat",
          position: "row",
          onClick: (rowData) => actionModal(rowData),
        }}
      />
    </>
  );
}
