import Request from "../../../helpers/Request";

export const getCompanies = async () => {
  const resp = await Request("get", "/api/vm/drop-down/companies");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let comp = {};
    comp["name"] = content[i].name;
    comp["id"] = content[i].id;
    arr.push(comp);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions() {
  const companies = await getCompanies();
  const options = {
    companies,
  };
  return options;
}
