import React from "react";
import moment from "moment";
import "moment/locale/tr";
import Request from "../../helpers/Request";
import FileSaver from "file-saver";
import TableBackdrop from "../../components/Table/TableBackdrop";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import LanguageHelper from "../../helpers/LanguageHelper";
import SharedSettings from "./SharedSettings";
import SessionHelper from "../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import UnauthorizedPage from "../UnauthorizedPage";

/**
 * A dashboard component that renders charts depending on the role of the user.
 */
export default function YGKReport() {
  moment.locale("tr");
  const user = SessionHelper.getUser();
  const userRegion = user?.department?.town?.belongingRegion?.id;
  const language = LanguageHelper.getLanguage();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "ygkReport");

  const [isYgk, setYgk] = React.useState(false);
  const [edc, setEdc] = React.useState(user.company?.id);
  const [edcs, setEdcs] = React.useState([]);
  const [region, setRegion] = React.useState(
    user.roles.includes("ROLE_BÖLGE_MÜDÜRÜ") ? userRegion : -1
  );
  const [regions, setRegions] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment().add(-1, "month").set("hour", 0).set("minute", 0).toISOString(true)
  );
  const [endDate, setEndDate] = React.useState(
    moment().set("hour", 23).set("minute", 59).toISOString(true)
  );
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  const getReport = async () => {
    setBackdropLoading(true);
    const param = {
      "end-date": endDate,
      "start-date": startDate,
      "only-ygk-member": isYgk,
    };
    const resp = await Request(
      "get",
      "/api/report/" + edc + "/" + region + "/base64/ygk-report",
      null,
      param
    );
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
      FileSaver.saveAs(
        source,
        "YGK Denetim Raporu_" +
          moment(startDate).format("DD-MM-YYYY") +
          "_" +
          moment(endDate).format("DD-MM-YYYY") +
          ".xlsx"
      );
    }
    setBackdropLoading(false);
    return;
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      {authorization?.view ? (
        <>
          <h2>Yaşam Güvenliği Komitesi Denetim Raporları</h2>
          <SharedSettings
            getReport={getReport}
            setBackdropLoading={setBackdropLoading}
            edc={edc}
            setEdc={setEdc}
            region={region}
            setRegion={setRegion}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            edcs={edcs}
            setEdcs={setEdcs}
            regions={regions}
            setRegions={setRegions}
            isYgk={isYgk}
            setYgk={setYgk}
          />
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
}
