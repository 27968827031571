import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./FirstAidFilters";
import LanguageHelper from "../../helpers/LanguageHelper";
import GetOptions from "./GetOptions";
import { reportStatus } from "../../assets/constants";

export default function FirstAidTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [options, setOptions] = React.useState({});

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: null,
    department: null,
    educationStatus: null,
    reportStatus: null,
    name: null,
    surname: null,
    startTime: null,
    endTime: null,
    dateTakenStart: null,
    dateTakenEnd: null,
    validityStart: null,
    validityEnd: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.certificateReport.id, field: "id" },
      { title: language.certificateReport.name, field: "name" },
      { title: language.certificateReport.surname, field: "surname" },
      { title: language.certificateReport.title, field: "title" },
      { title: language.certificateReport.company, field: "companyName" },
      { title: language.certificateReport.department, field: "departmentName" },
      { title: language.certificateReport.tcNo, field: "tcNo" },
      {
        title: language.certificateReport.educationStatus,
        field: "educationStatus",
      },
      {
        title: language.firstAid.reportStatus,
        field: "reportStatus",
        lookup: reportStatus
      },
      {
        title: language.certificateReport.workStartDate,
        field: "startDateOfWork",
      },
      {
        title: language.firstAid.certificateTakenDate,
        field: "firstAidTakenDate",
      },
      {
        title: language.firstAid.certificateValidityDate,
        field: "firstAidValidityDate",
      },
      {
        title: language.firstAid.certificateValidityTime,
        field: "firstAidPeriodOfValidity",
      },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: null,
      department: null,
      educationStatus: null,
      reportStatus: null,
      name: null,
      surname: null,
      startTime: null,
      endTime: null,
      dateTakenStart: null,
      dateTakenEnd: null,
      validityStart: null,
      validityEnd: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noRowActions
        tableName={language.sidebar.firstAidCertificateReport}
        authName="firstAidCertificateReport"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
