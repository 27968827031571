import React, { useCallback } from "react";
import ModalForm from "./ModalForm";
import TableEntryModal from "../../../../components/Modal/TableEntryModal";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function MedicalExamModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldExamProps,
  setOldExamProps,
  options,
  setOptions,
  modalLoading,
}) {
  const defaultProps = {
    userId: null,
    examinationTime: null,
    examinationType: null,
    examinationFile: null,
    department: null,
    files: [],
    // IBYS props
    aracKullanimi: null,
    calisanMeslegi: [],
    calismaPozisyonu: null,
    calismaZamani: null,
    idrarTetkikiYapildiMi: null,
    isitmeTestiYapildiMi: null,
    kanTetkikiYapildiMi: null,
    rontgenYapildiMi: null,
    solunumFonkTestiYapildiMi: null,
    raporGecerlilikTarihi: null,
    raporTuru: null,
    sonucVeKanaat: null,
    // Calisma Ortamlari
    biyolojikEtkenlerSinifi: [],
    elektrikSinifi: [],
    fizikiOrtamSinifi: [],
    gurultuSinifi: [],
    havaSinifi: [],
    kimyasalMaddeSinifi: [],
    tozYonetmeligiSinifi: [],
    // Is ekipmanlari
    isEkipmanlari: [],
  };

  const [examProps, setExamProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const language = LanguageHelper.getLanguage();

  const init = useCallback(async () => {
    if (oldExamProps) {
      // create new array
      const havaSinifi = options.havaSinifi.filter((x) =>
        // find object with same id
        oldExamProps.havaSinifi.find((a) => a == x.id)
      );
      const tozYonetmeligiSinifi = options.tozYonetmeligiSinifi.filter((x) =>
        oldExamProps.tozYonetmeligiSinifi.find((a) => a == x.id)
      );
      const kimyasalMaddeSinifi = options.kimyasalMaddeSinifi.filter((x) =>
        oldExamProps.kimyasalMaddeSinifi.find((a) => a == x.id)
      );
      const gurultuSinifi = options.gurultuSinifi.filter((x) =>
        oldExamProps.gurultuSinifi.find((a) => a == x.id)
      );
      const fizikiOrtamSinifi = options.fizikiOrtamSinifi.filter((x) =>
        oldExamProps.fizikiOrtamSinifi.find((a) => a == x.id)
      );
      const elektrikSinifi = options.elektrikSinifi.filter((x) =>
        oldExamProps.elektrikSinifi.find((a) => a == x.id)
      );
      const biyolojikEtkenlerSinifi = options.biyolojikEtkenlerSinifi.filter(
        (x) => oldExamProps.biyolojikEtkenlerSinifi.find((a) => a == x.id)
      );
      setExamProps({
        userId: oldExamProps.personnel || null,
        examinationTime: oldExamProps.examDate || null,
        examinationFile: oldExamProps.examinationFile || null,
        examinationType: oldExamProps.examType || null,
        department: oldExamProps.department || null,
        files: oldExamProps.files || [],
        aracKullanimi: oldExamProps.aracKullanimi || null,
        calisanMeslegi: oldExamProps.calisanMeslegi || [],
        calismaPozisyonu: oldExamProps.calismaPozisyonu || null,
        calismaZamani: oldExamProps.calismaZamani || null,
        idrarTetkikiYapildiMi: oldExamProps.idrarTetkikiYapildiMi,
        isitmeTestiYapildiMi: oldExamProps.isitmeTestiYapildiMi,
        kanTetkikiYapildiMi: oldExamProps.kanTetkikiYapildiMi,
        rontgenYapildiMi: oldExamProps.rontgenYapildiMi,
        solunumFonkTestiYapildiMi: oldExamProps.solunumFonkTestiYapildiMi,
        raporGecerlilikTarihi: oldExamProps.raporGecerlikTarihi || null,
        raporTuru: oldExamProps.raporTuru || null,
        sonucVeKanaat: oldExamProps.sonucVeKanaat || null,
        isEkipmanlari: oldExamProps.isEkipmanlari || [],
        havaSinifi: havaSinifi || [],
        tozYonetmeligiSinifi: tozYonetmeligiSinifi || [],
        kimyasalMaddeSinifi: kimyasalMaddeSinifi || [],
        gurultuSinifi: gurultuSinifi || [],
        fizikiOrtamSinifi: fizikiOrtamSinifi || [],
        elektrikSinifi: elektrikSinifi || [],
        biyolojikEtkenlerSinifi: biyolojikEtkenlerSinifi || [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldExamProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setExamProps(defaultProps);
      setOldExamProps(null);
    }, 300);
  };

  return (
    <React.Fragment>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          examProps={examProps}
          setExamProps={setExamProps}
          label={oldExamProps ? language.modal.update : language.modal.submit}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
          handleCloseModal={handleCloseModal}
          oldExamProps={oldExamProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </React.Fragment>
  );
}
