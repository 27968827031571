import React, { useCallback } from "react";
import Request from "../../../helpers/Request";
import {
  Backdrop,
  CircularProgress,
  Fade,
  // Button,
  makeStyles,
  Modal,
  // TableContainer,
} from "@material-ui/core";
// import Carousel from 'react-material-ui-carousel'
import UnsuitTasksTransfer from "./UnsuitTasksTransfer";
// import TasksTransfer from "./TasksTransfer";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
    width: "1200px",
    height: "800px",
  },
  itemContainer: {
    overflow: "auto",
    maxHeight: 800,
  },
}));

export default function TransferModal({
  modal,
  modalLoading,
  userToDelete,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
}) {
  const classes = useStyles();
  // const items = [{ table: "inspections" }, { table: "unsuitInspections" }];

  //const [inspections, setInspections] = React.useState([]);
  const [unsuitInspections, setUnsuitInspections] = React.useState([]);
  const [updated, setUpdated] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  const init = useCallback(async () => {
    if (userToDelete) {
      setLoading(true);
      const data = await getInspections();
      //setInspections(data?.inspectionResponseList);
      setUnsuitInspections(data?.nonConformityResponseList);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal, updated]);
  React.useEffect(() => {
    init();
  }, [init, modal]);

  const getInspections = async () => {
    const resp = await Request(
      "get",
      "/api/tasks/" + userToDelete?.id + "/waiting-tasks"
    );
    console.log(resp);
    return resp?.data;
  };

  const handleCloseModal = () => {
    setModal(false);
    //setInspections([]);
    setUnsuitInspections([]);
  };

  // const Item = (props) => {
  //   const isUnsuit = props?.item?.table === "unsuitInspections";
  //   return (
  //     <TableContainer className={classes.itemContainer}>
  //       {isUnsuit ? (
  //         <UnsuitTasksTransfer
  //           data={unsuitInspections}
  //           setSnackbarMessage={setSnackbarMessage}
  //           setSnackbar={setSnackbar}
  //           setSeverity={setSeverity}
  //           updated={updated}
  //           setUpdated={setUpdated}
  //           loading={loading}
  //         />
  //       ) : (
  //         {
  //           /* <TasksTransfer
  //           data={inspections}
  //           setSnackbarMessage={setSnackbarMessage}
  //           setSnackbar={setSnackbar}
  //           setSeverity={setSeverity}
  //           updated={updated}
  //           setUpdated={setUpdated}
  //           loading={loading}
  //         /> */
  //         }
  //       )}
  //     </TableContainer>
  //   );
  // };

  // const NavButton = ({ title }) => {
  //   return <Button>{title}</Button>;
  // };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            {modalLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <UnsuitTasksTransfer
                data={unsuitInspections}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbar={setSnackbar}
                setSeverity={setSeverity}
                updated={updated}
                setUpdated={setUpdated}
                loading={loading}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

/* <Carousel
    // next={ (next, active) => console.log(`we left ${active}, and are now at ${next}`)}
    // prev={ (prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
    NextIcon={<NavButton title="Uygunsuzluk Giderme Görevleri"/>}
    PrevIcon={<NavButton title="İSG Denetim Görevleri"/>}
    cycleNavigation={false}
    indicators={false}
    autoPlay={false}
    navButtonsAlwaysVisible
    navButtonsProps={{  
      style: {
        backgroundColor: 'cornflowerblue',
        borderRadius: 0
      }
    }} 
    navButtonsWrapperProps={{
      style: {
        position: "relative",
        float: "right",
        bottom: '0',
        top: 'unset',
      }
    }} 
    fullHeightHover={false}
    animation="slide"
  >
    {
      items.map( (item, i) => <Item key={i} item={item} /> )
    }    
  </Carousel> */
