import React, { useCallback } from "react";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from "../../../../helpers/Request";
import moment from 'moment';
import TableEntryModal from "../../../../components/Modal/TableEntryModal";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import { CustomDatePicker } from '../../../../components/Selectors/DatePickers';
import { TextArea } from '../../../../components/Fields/TextFields';
import { Button, CircularProgress, FormControl, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  buttonContainer: {
    textAlign: "center",
    padding: 10
  }
}));

export default function ActionModal({modalLoading, modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, tableRef, drillProps, opts}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const defaultProps = {
    emergencyPractiseId: drillProps?.id,
    assignedTo: null,
    terminationDate: null,
    message: null
  }
  
  const [actionProps, setActionProps] = React.useState({});
  const [options, setOptions] = React.useState({
    users: []
  });
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setActionProps(defaultProps);
    setOptions(opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drillProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);
  
  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setActionProps(defaultProps);
    }, 300);
  }

  const submitAction = async () => {
    setLoading(true);
    const resp = await Request("post", '/api/emergency/practise-action/', actionProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  const getUserFromId = (id) => {
    for (let i = 0; i < options.users.length; i++) {
      if (options.users[i].id === id) {
        return options.users[i];
      }
    }
    return null;
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}>
        <FormControl noValidate autoComplete="off" className={classes.formControl}>
          <Autocomplete //PERSONNEL
            id="combo-box"
            options={options.users?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={actionProps.assignedTo ? getUserFromId(actionProps.assignedTo) : null}
            onChange={async (event, value) => {
              setActionProps({...actionProps, assignedTo: value?.id});
            }}
            renderInput={(params) => <TextField {...params} required label="Personel" className={classes.textField}/>}
          />
          <CustomDatePicker //TERMINATION DATE
            style={classes.textField}
            label="Termin Tarihi" 
            value={actionProps.terminationDate ? moment(actionProps.terminationDate) : null} 
            shrink={actionProps.terminationDate} 
            onChangeFunc={value => setActionProps({...actionProps, terminationDate: moment(value).set("hour", 23).set("minute", 59).toISOString(true)})}
          />
          <TextArea //DESCRIPTION
            label={"Açıklama"}
            value={actionProps.message}
            shrink={actionProps.message && actionProps.message !== ""}
            style={classes.textField}
            onChangeFunc={(value) => setActionProps({...actionProps, message: value.target.value})}
          />
          <div className={classes.buttonContainer}>
            {loading ?
            <CircularProgress color="secondary"/>
            :
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => submitAction()}
              className={classes.submit}
            >
              {"OLUŞTUR"}
            </Button>}
          </div>
        </FormControl>
      </TableEntryModal>
    </>
  );
}