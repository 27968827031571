import React, { useCallback } from "react";
import ModalForm from './ModalForm';
import TableEntryModal from "../../../../components/Modal/TableEntryModal";

export default function DrillModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldDrillProps,
  setOldDrillProps,
  options,
  setOptions,
  modalLoading
}) {
  const defaultProps = {
    companyId: null,
    departmentId: null,
    plannedDate: null,
    completionDate: null,
    practiseFile: null,
    files: []
  }

  const [drillProps, setDrillProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldDrillProps) {
      setDrillProps({
        companyId: oldDrillProps.company || null,
        departmentId: oldDrillProps.department || null,
        plannedDate: oldDrillProps.plannedDate || null,
        completionDate: oldDrillProps.completionDate || null,
        practiseFile: oldDrillProps.practiseFile || null,
        files: oldDrillProps.files || []
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldDrillProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setDrillProps(defaultProps);
      setOldDrillProps(null);
    }, 300);
  };

  return (
    <>
      <TableEntryModal modal={modal} handleCloseModal={handleCloseModal} modalLoading={modalLoading}>
        <ModalForm
          drillProps={drillProps}
          setDrillProps={setDrillProps}
          label={oldDrillProps ? "GÜNCELLE" : "OLUŞTUR"}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
          handleCloseModal={handleCloseModal}
          oldDrillProps={oldDrillProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </>
  );
}