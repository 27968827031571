import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { Button, Grid } from '@material-ui/core';
import Request from '../../../helpers/Request';
import Dropzone from './Dropzone';

const useStyles = makeStyles((theme) => ({
  dzContainer: {
    maxHeight: 400,
    overflow: "auto",
    border: '1px solid #474D5C',
  },
  successMessage: {
    color: "blue"
  },
  errorMessage: {
    color: "red"
  }
}));

/**
 * The section of the details panel that renders the form file dropzones.
 * @param {rowData: obj, tableRef: obj} params table parameters 
 */
export default function FormFilesSection({ rowData, casualty, tableRef, forms }) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [texts, setTexts] = React.useState([]);
  const [formFiles, setFormFiles] = React.useState(new FormData());
  const [formDatas, setFormDatas] = React.useState([]);
  const [formFilesToDelete, setFormFilesToDelete] = React.useState([]);
  const [uploadedFormCount, setUploadedFormCount] = React.useState(0);
  const [changeMade, setChangeMade] = React.useState(0);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  /**
   * Initializes the texts array, pushes the indexes and titles of the dropzones to the array,
   * and populates the formDatas array with the already existing files to then render inside the dropzones.
   */
  const init = useCallback(async () => {
    let texts = [];
    texts.push([1, "SGK Bildirim Raporu (3 İş Günü)"]);
    texts.push([2, "İsg Olay-Kaza Paylaşım Formu (Frm.480) (İş Kazası Sonrası 24 Saat İçerisinde)"]);
    texts.push([3, "İş Kazası Değerlendirme Formu (Frm.356)"]);
    texts.push([4, "İsg Olay-Kaza Araştırma Formu (Frm.501)"]);
    texts.push([5, "Görev Tanımı Tutanağı"]);
    texts.push([6, "İş Görev Emri Tutanağı"]);
    texts.push([7, "İş Öncesinde Gerçekleştirilmesi Gereken R5 Formu (Tüm Ekip)"]);
    texts.push([8, "Yürütülen Faaliyet Çalışma İznine Tabi İse Çalışma İzin Formu (Çalışma Kapsamındaki Tüm Çalışma İş İzinleri"]);
    texts.push([9, "Yürütülen Faaliyete Yönelik Çalışma Yöntem, Teknik, Talimat"]);
    texts.push([10, "Olayın Nasıl Olduğuna Dair Kazazedenin Beyanı (El Yazısı)"]);
    texts.push([11, "Varsa Olayın Nasıl Olduğuna Dair Şahit Beyanı (El Yazısı)"]);
    texts.push([12, "Yaptığı İşin Niteliğine Bağlı Ekip Şefi/Amiri Tarafından Kazayı Geçiren Personelle Verilmiş İş Başlama Eğitimi"]);
    texts.push([13, "Daha Önce Katılmış Olduğu İsg Eğitim Formu Ve Sertifikaları(Sertifikalar, İşveren İş Güvenliği Uzmanı, İşyeri Hekimi İmzalı olmalıdır."]);
    texts.push([14, "İşe Giriş/Periyodik Kontrol Raporu, İşe Giriş Bilgilendirme Formu"]);
    texts.push([15, "Kaza İle İlgili Hastane Raporu"]);
    texts.push([16, "Varsa İş Göremezlik Raporu"]);
    texts.push([17, "Varsa Kolluk Kuvvetlerince Tutulan Tutanak"]);
    texts.push([18, "Mesleki Eğitim/Yeterlilik Belgesi (Meslek Lisesi Diploma, Ekat/Meb Onaylı Mesleki Eğitim Sertifikaları Vb.)"]);
    texts.push([19, "Kişisel Koruyucu Donanım Zimmet Tutanağı"]);
    texts.push([20, "Vardiya Çizelgesi Veya Çalışma Saatlerini Belirtir Yazı"]);
    texts.push([21, "Kazazedenin Kimlik Kartı Fotokopisi(Ön Yüz)"]);
    texts.push([22, "İş Makinesi İle İlgili Kazalarda İlgili İş Makinesi Kullanıcı Bilgileri, Operatörlük Belgesi, Makine Periyodik Kontrol Ve Bakım Raporları"]);
    texts.push([23, "İşe Dönüş Eğitimi (İş Başı Öncesinde İşyeri Hekimince Hazırlanacak Muayene Ve İş Güvenliği Uzmanınca Verilecek Kazanın Analizi Ve Korunma Yolları İle İlgili Eğitim Katılım Formu Olmalıdır) (Frm.359)"]);
    texts.push([24, "Kaza Sonrası Risk Analizi Raporu"]);
    texts.push([25, "Kaza Sonrası Kurul Tutanağı"]);
    for (let prop in forms) {
      if (prop !== "id" && prop !== "active" && !prop.includes("form") && prop !== "createdAt" && prop !== "deletedAt") {
        formDatas.push([prop, forms[prop]?.fileKey, forms[prop]?.id, forms[prop]?.size]); //ID YERİNE SİZE OLACAK
      }
    }
    setFormDatas(formDatas);
    setTexts(texts);
  }, [rowData]);
  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Sends the newly uploaded file(s), deletes the existing file(s), or does both.
   * Renders a message/error depending on the response.
   */
  const sendOrDeleteFormFiles = async () => {
    let resp = null;
    if (formFilesToDelete.length > 0) {
      let ids = "?files=" + formFilesToDelete[0];
      for (let i = 1; i < formFilesToDelete.length; i++) {
        ids += "&files=" + formFilesToDelete[i];
      }
      resp = await Request("delete", "/api/industrial-accidents/casualties/" + rowData?.casualtyIds[casualty] + "/attachments" + ids);
      console.log(resp);
    }
    if (uploadedFormCount > 0) {
      resp = await Request("post", "/api/industrial-accidents/casualties/" + rowData?.casualtyIds[casualty] + "/upload-form-attachments", formFiles);
      console.log(resp);
    }
    if (resp?.status === 200) {
      setMessage("Değişiklikler başarılı bir şekilde kaydedildi.");
      tableRef.current && tableRef.current.onQueryChange();
      setChangeMade(0);
    } else {
      setError("Değişiklikleri kaydederken bir hata oluştu, lütfen sayfayı yenileyip tekrar deneyiniz.");
    }
  }

  /**
   * Uses the custom dropzone component to render multiple dropzones.
   * Gets the data of these dropzones from the texts and formDatas arrays. 
   */
  const MultipleDropzones = () => {
    return (
      texts.map((data, index) => {
        return (
          <Grid item xs={window.innerWidth < 1400 ? 6 : 4}>
            <Dropzone
              active={rowData.active}
              index={index}
              texts={texts}
              formDatas={formDatas}
              formFiles={formFiles}
              setTexts={setTexts}
              setFormFiles={setFormFiles}
              setChangeMade={setChangeMade}
              changeMade={changeMade}
              formFilesToDelete={formFilesToDelete}
              setFormFilesToDelete={setFormFilesToDelete}
              uploadedFormCount={uploadedFormCount}
              setUploadedFormCount={setUploadedFormCount} />
          </Grid>
        )
      })
    )
  }
  
  return (
    <>
      <Grid container direction="row" alignItems="center" className={classes.dzContainer}>
        {MultipleDropzones()}
      </Grid>
      {changeMade > 0 && <Button variant="contained" fullWidth color="primary" onClick={sendOrDeleteFormFiles}>{language.tableDetails.saveChanges}</Button>}
      <h5 className={classes.successMessage}>{message}</h5>
      <h5 className={classes.errorMessage}>{error}</h5>
    </>
  )
}