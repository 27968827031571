import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import InjuryTypeModal from "./InjuryTypeModal";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import { active } from "../../../assets/constants";

export default function InjuryTypeTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedInjuryType, setSelectedInjuryType] =
    React.useState("");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: language.workAccidentSelections.injuryTypeName,
        field: "name",
      },
      {
        title: language.workAccidentSelections.isActive,
        field: "active",
        lookup: active,
      },
      { title: language.workAccidentSelections.createdAt, field: "createdAt" },
      { title: language.workAccidentSelections.deletedAt, field: "deletedAt" },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = (data) => {
    setSelectedInjuryType(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedInjuryType(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedInjuryType(null);
    setDialog(false);
  };

  const removeInjuryType = async () => {
    setRequestLoading(true);
    let params = {
      id: selectedInjuryType?.id,
      "selection-type": "InjuryType",
    };
    const resp = await Request(
      "delete",
      "/api/selections/delete/" + selectedInjuryType?.id,
      null,
      params
    );
  
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeInjuryType}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <InjuryTypeModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        injuryTypeProps={selectedInjuryType}
      />
      <Table
        tableName={language.workAccidentSelections.injuryType}
        authName="injuryType"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(user, rowData) => rowData.active}
        isEditable={() => true}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
