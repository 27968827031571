import React, { useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageHelper from "../../helpers/LanguageHelper";
import logo from "../../assets/images/pincident.png";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Request from "../../helpers/Request";
import Copyright from "../../components/Footer/Copyright";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Settings from "../../components/Buttons/Settings";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 20,
    width: 300,
    height: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: 396,
  },
  password: { width: 396 },
  passwor: { width: 396, marginLeft: theme.spacing(5) },
  passwordIcon: {
    margin: theme.spacing(4, 0, 2, 2),
  },
}));

export default function AccountActivated() {
  let location = useLocation();
  const darkTheme = createTheme({ palette: { type: "dark" } });
  const lightTheme = createTheme({ palette: { type: "light" } });
  const token = location.pathname.substring(12);
  const navigate = useNavigate();
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const regexRules =
    /(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\d)(?=.*[!@#$%&*'(),\-+<=>:;?{}^._])[A-Za-z\d!@#$%&*'(),\-+<=>:;?{}^._]{8,32}$/;
  const passwordRules = `- Şifreniz Türkçe karakter içermemelidir\n- Şifreniz en az 8, en çok 32 karakterden oluşmalıdır.\n- Şifreniz en az 1 küçük ve 1 büyük harf içermelidir.\n- Şifreniz en az 1 rakam içermelidir.\n- Şifreniz !@#$%&*'(),-+<=>:;?{}^._ sembollerinden en az 1'ini içermelidir.`;

  const [newPassword, setNewPassword] = useState("");
  const [newPasswordCheck, setNewPasswordCheck] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [severity, setSeverity] = useState("info");
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode")
  );
  const [, setLanguage] = React.useState(language);
  const [error, setError] = React.useState(null);
  const [strongPassword, setStrongPassword] = useState(false);

  async function handleActivate(password) {
    const resp = await Request("post", "/api/auth/confirm/" + token, {
      newPassword: password,
    });
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.status === 429) {
        setSeverity("error");
        setMessage(
          "Çok fazla istekte bulundunuz. Lütfen daha sonra tekrar deneyiniz."
        );
        setSnackbar(true);
      } else {
        if (resp.data && resp.data.message) {
          setSeverity("error");
          setMessage(resp.data.message);
          setSnackbar(true);
        } else {
          setSeverity("error");
          setMessage(language.login.unexpectedError);
          setSnackbar(true);
        }
      }
    } else {
      if (resp.data && resp.data.message) {
        setMessage(resp.data?.message);
      } else if (resp.message) {
        setMessage(resp.message);
      } else {
        setMessage(language.login.activated);
      }
      setShowMessage(true);
      setTimeout(() => {
        navigate("/signin");
      }, 3000);
    }
  }

  const checkPassword = (newPassword) => {
    if (!regexRules.test(newPassword)) {
      setError("Şifreniz belirtilen kurallara uymamaktadır.");
      setStrongPassword(false);
      setNewPassword(newPassword);
    } else {
      setNewPassword(newPassword);
      setStrongPassword(true);
      setError(null);
    }
  };

  const checkCredentials = () => {
    if (newPassword === "") {
      setMessage(language.login.providePassword);
      setSnackbar(true);
    } else if (!strongPassword) {
      setMessage(error);
      setSnackbar(true);
    } else {
      // eslint-disable-next-line no-useless-escape
      if (newPasswordCheck === newPassword) {
        handleActivate(newPassword);
      } else {
        setMessage(language.login.passwordMatch);
        setSnackbar(true);
      }
    }
  };

  return (
    <Container maxWidth={false}>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={message}
        severity={severity}
      />
      <Settings
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        setLanguage={setLanguage}
      />
      <Container component="main" maxWidth="sm">
        <ThemeProvider theme={darkMode === "true" ? darkTheme : lightTheme}>
          <CssBaseline />
          <div className={classes.paper}>
            <img className={classes.avatar} alt={logo} src={logo} />
            <Typography component="h1" variant="h5">
              {language.login.activate}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                id="password_field"
                snackbar={snackbar}
                variant="outlined"
                margin="normal"
                error={error}
                helperText={error}
                required
                // fullWidth
                className={classes.passwor}
                label={language.login.password}
                type="password"
                autoFocus
                onChange={(newPassword) =>
                  checkPassword(newPassword.target.value)
                }
              />
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line", fontSize: 12 }}>
                    {passwordRules}
                  </div>
                }
              >
                <HelpIcon
                  className={classes.passwordIcon}
                  color="primary"
                  fontSize="medium"
                />
              </Tooltip>
              <TextField
                id="password_confirm_field"
                snackbar={snackbar}
                variant="outlined"
                margin="normal"
                required
                // fullWidth
                className={classes.password}
                label={language.login.passwordConfirm}
                type="password"
                autoFocus
                onChange={(newPasswordCheck) =>
                  setNewPasswordCheck(newPasswordCheck.target.value)
                }
              />
              {newPassword !== newPasswordCheck && newPasswordCheck !== "" && (
                <Typography style={{ color: "red" }}>
                  {language.login.passwordMatch}
                </Typography>
              )}
              {showMessage && (
                <Typography style={{ color: "blue" }}>{message}</Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => checkCredentials()}
              >
                {language.login.activate}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => {
                      navigate("/signin");
                    }}
                  >
                    {language.login.haveAccount}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright
              string={"DELTA Smart Technologies"}
              date={new Date().getFullYear()}
              url={"https://deltasmart.tech/en/home/"}
            />
          </Box>
        </ThemeProvider>
      </Container>
    </Container>
  );
}
