import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./TosLogsFilters";
import LanguageHelper from "../../helpers/LanguageHelper";
import GetOptions from "./GetOptions";

export default function TosLogsTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [options, setOptions] = React.useState([]);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: null,
    department: null,
    email: null,
    platform: null,
    readTimeStart: null,
    readTimeEnd: null,
    approved: null,
    region: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.companyUser, field: "companyName" },
      { title: language.tableColumns.departmentUser, field: "departmentName" },
      { title: "Bölgesi", field: "regionName" },
      { title: language.tableColumns.name, field: "name" },
      { title: language.tableColumns.surname, field: "surname" },
      { title: language.tableColumns.email, field: "email" },
      { title: "Onay Durumu", field: "consentStatus" },
      { title: "Onay Tarihi", field: "readTime" },
      { title: "Onay Aracı", field: "platform" },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: null,
      department: null,
      email: null,
      platform: null,
      readTimeStart: null,
      readTimeEnd: null,
      approved: null,
      region: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noRowActions
        tableName={language.sidebar.tosStatus}
        authName="tosLogs"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
