import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./HeadcountsFilters";
import GetOptions, { getDepartments } from './GetOptions';
import DeleteDialog from '../../components/Dialog/DeleteDialog';
import HeadcountsModal from "./HeadcountsModal";
import showSnackbar from '../../components/Utils/showSnackbar';
import LanguageHelper from "../../helpers/LanguageHelper";
import SessionHelper from "../../helpers/SessionHelper";
import { getAuthorizationForPage } from '../../helpers/AuthorizationHelper';
import Request from "../../helpers/Request";

export default function HeadcountsTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "headcounts");

  const [oldHeadcounts, setOldHeadcounts] = React.useState({});
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [idsToDelete, setIdsToDelete] = React.useState([]);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: null,
    department: null,
    year: null,
    month: null
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Şirket", field: "companyName" },
      { title: "Bölüm", field: "departmentName" },
      { title: "SGK Sicil No", field: "id" },
      { title: "Yıl", field: "year" },
      { title: "Ay", field: "monthTr" },
      { title: "Mevcut Personel Sayısı", field: "existingPersonal" },
      { title: "İşe Giren Personel Sayısı", field: "beginPersonal" },
      { title: "İşten Çıkan Personel Sayısı", field: "quitPersonal" },
      { title: "Toplam Personel Sayısı", field: "totalPersonal" }
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModal(true);
    setModalLoading(true);
    setOldHeadcounts(data);
    let newOpts = options;
    newOpts.departments = data ? await getDepartments(data.companyId) : null;
    setOptions(newOpts);
    setModalLoading(false);
  }

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  }

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  }

  const removeHeadcounts = async () => {
    setLoading(true);
    const resp = await Request("delete", '/api/monthly-user/' + idToDelete);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setLoading(false);
  }

  const removeMultipleHeadcounts = async () => {
    setLoading(true);
    const param = {
      ids: idsToDelete.join()
    }
    const resp = await Request("delete", '/api/monthly-user/', null, param);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: null,
      department: null,
      year: null,
      month: null
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeMultipleHeadcounts : (idToDelete && removeHeadcounts)} 
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
      />
      <HeadcountsModal
        modal={modal} 
        setModal={setModal} 
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
        tableRef={tableRef} 
        oldHeadcounts={oldHeadcounts}
        modalLoading={modalLoading}
        opts={options}
      />
      <RenderFilters />
      <Table
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        tableName={"Bölüm Personel Sayıları"}
        authName="headcounts"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSelectionIds={setIdsToDelete}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={() => authorization.delete}
      />
    </>
  );
}