import React from "react";
import Request from "../../../helpers/Request";
import { Backdrop, Button, CircularProgress, Fade, FormControl, makeStyles, Modal } from "@material-ui/core";
import moment from 'moment';
import LanguageHelper from "../../../helpers/LanguageHelper";
import { MonthPicker } from "../../../components/Selectors/DatePickers";
import { TextArea } from '../../../components/Fields/TextFields';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px"
  },
  formControl: {
    margin: 20,
    width: "30vw",
    maxWidth: 400,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw"
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3)
  },
}));

export default function GoalForm({modal, handleCloseModal, row, options, update, refresh, setRefresh, goalProps, setGoalProps, setSeverity, setSnackbar, setSnackbarMessage}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  const updateGoals = async () => {
    setLoading(true);
    const resp = await Request("put", "/api/inspection-goals/" + row[0], goalProps);
    console.log(resp);
    if(resp.status !== 200) {
      if(resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      setRefresh(!refresh);
      setSeverity("success");
      setSnackbarMessage(language.etc.updated);
      setSnackbar(true);
    }
    handleCloseModal();
    setLoading(false);
  }

  const submitGoals = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/inspection-goals", goalProps);
    console.log(resp);
    if(resp.status !== 200) {
      if(resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      setRefresh(!refresh);
      setSeverity("success");
      setSnackbarMessage(language.etc.added);
      setSnackbar(true);
    }
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={classes.paper}>
            <FormControl noValidate autoComplete="off" className={classes.formControl}>
              <> {/* GOALS */}
                <TextArea
                  required
                  label={"Davranış Odaklı Denetim Hedefi"}
                  value={goalProps.inspectionGoalsInMonths.BEHAVIORDRIVENAUDITING}
                  style={classes.textField}
                  onChangeFunc={value => {
                    const val = value.target.value.replace(/\s/g, "").replace(/\D/g, "");
                    setGoalProps({...goalProps, 
                      inspectionGoalsInMonths: {...goalProps.inspectionGoalsInMonths, 
                        BEHAVIORDRIVENAUDITING: val}
                  })}}
                />
                <TextArea
                  required
                  label={"Briefing Hedefi"}
                  value={goalProps.inspectionGoalsInMonths.BRIEFING}
                  style={classes.textField}
                  onChangeFunc={value => {
                    const val = value.target.value.replace(/\s/g, "").replace(/\D/g, "");
                    setGoalProps({...goalProps, 
                      inspectionGoalsInMonths: {...goalProps.inspectionGoalsInMonths, 
                        BRIEFING: val}
                  })}}
                />
                <TextArea
                  required
                  label={"Aylık Planlı Emniyet Kontrolleri Hedefi"}
                  value={goalProps.inspectionGoalsInMonths.MONTLYPLANNEDSAFETYCHECK}
                  style={classes.textField}
                  onChangeFunc={value => {
                    const val = value.target.value.replace(/\s/g, "").replace(/\D/g, "");
                    setGoalProps({...goalProps, 
                      inspectionGoalsInMonths: {...goalProps.inspectionGoalsInMonths, 
                        MONTLYPLANNEDSAFETYCHECK: val}
                  })}}
                />
                <TextArea
                  required
                  label={"İSG ve Çevre Uygunsuzluk Denetimi Hedefi"}
                  value={goalProps.inspectionGoalsInMonths.NEARMISSREPORT}
                  style={classes.textField}
                  onChangeFunc={value => {
                    const val = value.target.value.replace(/\s/g, "").replace(/\D/g, "");
                    setGoalProps({...goalProps, 
                      inspectionGoalsInMonths: {...goalProps.inspectionGoalsInMonths, 
                        NEARMISSREPORT: val}
                  })}}
                />
              </>
              {/* VALID FROM */}
              <MonthPicker
                style={classes.dateField}
                label={"Geçerlilik Başlangıç"}
                value={goalProps.validFrom ? moment(goalProps.validFrom) : null} 
                shrink={goalProps.validFrom} 
                onChangeFunc={value => setGoalProps({...goalProps, validFrom: moment(value).date(1).set("hours", 0).set("minutes", 0).toISOString(true)})}
              />
              {/* VALID UNTIL */}
              <MonthPicker
                style={classes.dateField}
                label={"Geçerlilik Bitiş"}
                value={goalProps.validUntil ? moment(goalProps.validUntil) : null} 
                shrink={goalProps.validUntil} 
                onChangeFunc={value => setGoalProps({...goalProps, validUntil: (new moment(value)).date(1).set("hours", 23).set("minutes", 59).add("months", 1).add("days", -1).toISOString(true)})}
              />
              <div style={{textAlign: "center"}}>
                {loading ?
                <CircularProgress color="secondary"/>
                :
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => update ? updateGoals() : submitGoals()}
                  className={classes.submit}>
                  {update ? "GÜNCELLE" : "OLUŞTUR"}
                </Button>}
              </div>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
}