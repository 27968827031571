import React, { forwardRef, useCallback } from "react";
import "../../../App.css";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import AddBox from "@material-ui/icons/AddBox";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions, { getUsers } from './GetOptions';
import showSnackbar from "../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import ActionModal from './ActionModal';
import GetFilterFields from './NearMissFilters';
import Details from './Details';
import { List, ListItem, ListItemText } from "@material-ui/core";

export default function NearMissTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "nearMissTable");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({
    users: []
  });
  const [dialog, setDialog] = React.useState(false);
  const [createActionModal, setCreateActionModal] = React.useState(false);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedNearMiss, setSelectedNearMiss] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    reportedByFilter: null,
    incidentUserFilter: null
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id", editable: "never" },
      { title: "Görev No", field: "customId", editable: "never" },
      { title: "Bildiren Kişi", field: "notifyingUser", editable: "never"},
      { title: "Personeller", field: "personnel", editable: "never", render: rowData => 
      <>
        <List className='table-cell-list'>
          {rowData.personnel && rowData.personnel.length > 0 ?
            <>
              {rowData?.personnel.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.fName + " " + data?.lName}/>
                </ListItem>
              ))}
            </>
            : 
            <ListItem key={"empty"}>
              <ListItemText secondary={"Yok"}/>
            </ListItem>}
        </List>
        
      </>
      },
      { title: "Olay Tipi", field: "incidentType", editable: "never" },
      { title: "Olay Tarihi", field: "incidentDateFormatted", editable: "never" },
      { title: "Bildirim Tarihi", field: "notificationDateFormatted", editable: "never" },
      { title: "Şirket", field: "company", editable: "never" },
      { title: "Bölüm", field: "department", editable: "never" },
      { title: "İlçe", field: "townName", editable: "never" },
      {
        title: "Açıklama",
        field: "description",
        editable: "never", 
        render: rowData => 
          <div className='table-cell-list' >
            {rowData.description}
          </div>
      }
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenDeleteDialog = (data) => {
    setSelectedNearMiss(data);
    setDialog(true);
  }

  const actionModal = async (data) => {
    setModalLoading(true);
    setCreateActionModal(true);
    const users = await getUsers();
    setOptions({users: users});
    setSelectedNearMiss(data);
    setCreateActionModal(true);
    setModalLoading(false);
  }

  const handleCloseDeleteDialog = () => {
    setSelectedNearMiss(null);
    setDialog(false);
  }

  const removeNearMiss = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", '/api/nearMiss/' + selectedNearMiss?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const removeNearMisses = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join()
    }
    const resp = await Request("delete", '/api/nearMiss/', null, param);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      reportedByFilter: null,
      incidentUserFilter: null
    });
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeNearMisses : (selectedNearMiss && removeNearMiss)}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      {/* <NearMissModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldNearMissProps={selectedNearMiss}
      /> */}
      <ActionModal
        modalLoading={modalLoading}
        modal={createActionModal}
        setModal={setCreateActionModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        opts={options}
        nearMissProps={selectedNearMiss}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters/>
      <Table
        tableName={"Ramak Kala"}
        authName={"nearMissTable"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        extraAction={{
          auth: authorization.create,
          icon: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          tooltip: "Aksiyon Yarat",
          position: "row",
          onClick: (rowData) => actionModal(rowData)
        }}
        filters={filterProps}
        createTooltip="Aksiyon Yarat"
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ")
        }
        isDeleteHidden={() => !authorization.delete}
        detailsPanel={(rowData) => <Details rowData={rowData}/>}
      />
    </>
  );
}
