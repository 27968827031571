import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      "page-size": query.pageSize,
      active: false,
      "selection-type": "AccidentDefinition",
    };
    // if (query.orderBy !== undefined) {
    //   if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
    //   else if (query.orderBy.field === "name") { params.sort = "name," + query.orderDirection; }
    // }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/selections/get-all", null, params);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(
        language.etc.fetchingError + resp?.data?.messageResponse
      );
      setSnackbar(true);
    } else {
      let otherData = resp.data?.content;
      let data = otherData?.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          name: language.title === "Türkçe" ? data[i].nameTr : data[i].nameEng,
          active: data[i].active,
          createdAt: data[i].createdAt
            ? moment(data[i].createdAt).format("DD-MM-YYYY HH:mm:ss")
            : "-",
          deletedAt: data[i].deletedAt
            ? moment(data[i].deletedAt).format("DD-MM-YYYY HH:mm:ss")
            : "-",
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
