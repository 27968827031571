import React, { useCallback } from "react";
import SessionHelper from "../../helpers/SessionHelper";
import LanguageHelper from "../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import Request from "../../helpers/Request";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import showSnackbar from "../../components/Utils/showSnackbar";
import DeleteDialog from "../../components/Dialog/DeleteDialog";
import NoticeModal from "./NoticeModal";

export default function NoticeTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "notices");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [dialog, setDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedNotice, setSelectedNotice] = React.useState(null);

  const init = useCallback(async () => {
    setColumns([
      { title: "No", field: "id" },
      { title: "Tipi", field: "type" },
      { title: "Text", field: "text" },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    data && setSelectedNotice(data);
    setModalLoading(true);
    setEditModal(true);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedNotice(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedNotice(null);
    setDialog(false);
  };

  const removeNotice = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", "/api/notice/" + selectedNotice?.id);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={selectedNotice && removeNotice}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <NoticeModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldNoticeProps={selectedNotice}
        setOldNoticeProps={setSelectedNotice}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <Table
        noPaging
        tableName={language.history.notices}
        authName={"notices"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() => authorization.delete}
      />
    </>
  );
}
