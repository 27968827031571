import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Grid, List, ListItem, ListItemText, TextField } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  activityFieldList: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  fields: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  textFieldFields: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%"
  },
}));

const FieldList = ({newDepartmentProps, setNewDepartmentProps, options, setChangeMade}) => {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const getTownFromId = (id) => {
    for(let i = 0; i < options.allTowns.length; i++) {
      if(options.allTowns[i].id === id) {
        return options.allTowns[i];
      }
    }
    return null;
  }

  const filterOptions = createFilterOptions({
    stringify: ({ name, cityName }) => `${name} ${cityName}`
  });

  const getPersonnelFromId = (id) => {
    for(let i = 0; i < options.unsuitUser.length; i++) {
      if(options.unsuitUser[i].id === id) {
        return options.unsuitUser[i];
      }
    }
    return null;
  }

  const getUnsuitUserIdFromTownId = (id) => {
    const temp = newDepartmentProps.nonconformityUserRequest;
    for(let index in temp) {
      if(temp[index].townId === id) {
        return temp[index].userId;
      }
    }
    return null;
  }

  const RenderFieldsBox = useCallback(() => {
    return (
      <>
        {newDepartmentProps.fieldsOfActivity.length > 0 
          ?
          <List className={classes.activityFieldList}>
            {newDepartmentProps.fieldsOfActivity.map((data, index) => (
              <ListItem key={index}>
                <Grid container>

                  {/* RENDERS THE TOWNS AND THE DELETE BUTTON */}
                  <Grid item xs={6}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                      {newDepartmentProps.fieldsOfActivity.length > 1 && 
                      <IconTooltipButton title={language.form.deleteQuestion} onClick={() => {
                        let fields = [...newDepartmentProps.fieldsOfActivity];
                        fields.splice(index, 1);
                        let unsuits = [...newDepartmentProps.nonconformityUserRequest];
                        for(let i in unsuits) {
                          if(unsuits[i].townId === data) {
                            unsuits.splice(i, 1);
                            break;
                          }
                        }
                        setNewDepartmentProps({...newDepartmentProps, fieldsOfActivity: fields, nonconformityUserRequest: unsuits});
                        setChangeMade && setChangeMade(true);
                      }}>
                        <DeleteIcon />
                      </IconTooltipButton>}
                      <ListItemText style={{marginTop: 15}} secondary={data.name ? data.name : (options.allTowns ? getTownFromId(data)?.name : null)}/>
                    </div>
                  </Grid>

                  {/* RENDERS THE UNSUIT PERSONNEL SELECTION FIELD FOR EACH TOWN */}
                  <Grid item xs={6}>
                    <Autocomplete //UNSUIT
                      id="combo-box"
                      options={options.unsuitUser?.sort(function(a, b) {
                        return collator.compare(a.name, b.name)
                      })}
                      getOptionLabel={(option) => option.name}
                      fullWidth={true}
                      defaultValue={newDepartmentProps ? getPersonnelFromId(getUnsuitUserIdFromTownId(data)) : null}
                      onChange={(event, value) => {
                        let requestArray = [...newDepartmentProps.nonconformityUserRequest];
                        let found = false;
                        for(let index in requestArray) {
                          if(requestArray[index].townId === data) {
                            requestArray[index].userId = value?.id;
                            found = true;
                          }
                        }
                        if(!found) {
                          requestArray.push({townId: data, userId: value?.id});
                        }
                        setNewDepartmentProps({...newDepartmentProps, nonconformityUserRequest: requestArray});
                        setChangeMade && setChangeMade(true);
                      }} 
                      renderInput={(params) => <TextField {...params} label="Uygunsuzluk Giderici Personel" className={classes.textField}/>}
                    />
                  </Grid>

                </Grid>
              </ListItem>
            ))}
          </List>
          :
          <div className={classes.activityFieldList} style={{textAlign: "center"}}>
            <p style={{color: "red", fontSize: 14, marginLeft: "auto"}}>Ekli ilçe yok.</p>
          </div>}
      </>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.unsuitUser, options.allTowns, newDepartmentProps.nonconformityUserRequest, newDepartmentProps.fieldsOfActivity]);

  return (
    <div style={{textAlign: "left"}}>
      <Grid item xs={6}><h5 className={classes.fields}>Faaliyet Bölgesi *</h5></Grid>
      {/* <Grid item xs={6}><h5 className={classes.fields}>Uygunsuzluk Giderici Personel</h5></Grid> */}
      <RenderFieldsBox/>

      <div style={{textAlign: "center"}}>
        <Autocomplete //TOWN
          id="combo-box"
          options={options.allTowns?.sort(function(a, b) {
              return collator.compare(a.name, b.name);
            })?.sort(function(a, b) {
              return collator.compare(a.cityName, b.cityName);
          })}
          getOptionLabel={(option) => option.name}
          filterOptions={filterOptions}
          fullWidth={true}
          multiple={false}
          groupBy={(option) => option.cityName}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if(reason === "reset") {
              setAutocompleteValue("");
            } else if(reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value, reason) => {
            if(value?.id && value !== "") {
              let fields = [...newDepartmentProps.fieldsOfActivity];
              if(!fields.includes(parseInt(value?.id))) {
                fields.push(parseInt(value?.id));
                setNewDepartmentProps({...newDepartmentProps, fieldsOfActivity: fields});
                setChangeMade && setChangeMade(true);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} required label="İlçe Ekle" className={classes.textFieldFields}/>}
        />
      </div>
    </div>
  );
}

export default FieldList;