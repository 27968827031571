import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import { Autocomplete } from "@material-ui/lab";
import { getUsers, getDepartments } from './GetOptions';
import CustomFileDropzone from '../../../components/Files/CustomFileDropzone';
import jsonMap from "../../../components/Utils/jsonMap";
import DriversList from './DriversList';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: "-0.5vw",
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  brand: null,
  companyId: null,
  departmentId: null,
  fuelType: null,
  gearType: null,
  model: null,
  plate: null,
  year: null,
/*   files: [] */
  driverIds: [],
};

export default function VehicleModal({
  modal,
  setModal,
  options,
  setOptions,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState({
    ...propsDefault,
    companyId: user?.companyId,
    departmentId: user?.department?.id,
  });
  const [registrationDocument, setRegistrationDocument] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const init = useCallback(async () => {
    setModalLoading(true);
    setProps(propsDefault);
    setModalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const submitNewVehicle = async () => {
    setLoading(true);
    let formData = new FormData();
    if(registrationDocument && Array.isArray(registrationDocument)) {
      for (let doc of registrationDocument) {
        formData.append("registrationDocumentFile", doc);
      }
    }
    if (props.driverObjects) {
      let drivers = [];
      for (let prt of props.driverObjects) {
        drivers.push(prt.id);
      }
      props.driverIds = drivers;
      delete props.driverObjects;
    };
    formData.append(
      "request",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request(
      "post",
      "/api/vehicles/passenger-vehicles",
      formData
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModal(false);
    setProps(propsDefault);
  };

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <TableEntryModal
      modal={modal}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
      <Autocomplete //COMPANY
          id="combo-box"
          options={options.companies?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={
            props.companyId
              ? getFromId(props.companyId, options.companies)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, companyId: null });
              setOptions({
                ...options,
                departments: [],
                users: [],
              });
            } else {
              setProps({ ...props, companyId: value?.id });
              const [depts, users] = await Promise.all([
                getDepartments(value?.id),
                getUsers(value?.id),
              ]);
              setOptions({
                ...options,
                users: users,
                departments: depts
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Şirket"
              className={classes.textField}
            />
          )}
        />
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!props.companyId}
          value={
            props.departmentId
              ? getFromId(props.departmentId, options.departments)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, departmentId: null });
            } else {
              setProps({ ...props, departmentId: value?.id });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
        <TextArea
          label={"Marka"}
          required
          value={props?.brand}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, brand: value.target.value });
          }}
        />
        <TextArea
          label={"Model"}
          required
          value={props?.model}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, model: value.target.value });
          }}
        />
        <TextArea
          label={"Yılı"}
          required
          value={props?.year}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, year: value.target.value });
          }}
        />
        <TextSelector
          required
          label={"Yakıt Tipi"}
          value={props?.fuelType}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              fuelType: value.target.value,
            })
          }
          style={classes.textField}
          options={jsonMap("fuelTypes", options)}
        />
        <TextSelector
          required
          label={"Vites Tipi"}
          value={props?.gearType}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              gearType: value.target.value,
            })
          }
          style={classes.textField}
          options={jsonMap("gearTypes", options)}
        />
        <TextArea
          label={"Plaka"}
          required
          value={props?.plate}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, plate: value.target.value });
          }}
        />
        {options.users && (
          <DriversList
            props={props}
            setProps={setProps}
            options={options}
          />
        )}
        <CustomFileDropzone
          files={registrationDocument}
          setFiles={(value) => {
            setRegistrationDocument(value);
          }}
          fileLimit={1}
          dropzoneText={"Ruhsat yüklemek için sürükleyin ya da buraya tıklayın."}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => submitNewVehicle()}
              className={classes.submit}
            >
              {"OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
