import { Button, FormControl, makeStyles } from '@material-ui/core';
import React from 'react';
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from '../../../../helpers/Request';
import moment from 'moment';
import { CustomDatePicker } from '../../../../components/Selectors/DatePickers';
import showSnackbar from '../../../../components/Utils/showSnackbar';
import { TextArea, TextSelector } from '../../../../components/Fields/TextFields';

const useStyles = makeStyles((theme) => ({
  formControl: {
    overflow: "auto",
    width: "70vw",
    maxWidth: 600,
    maxHeight: "55vw",
  },
  CustomDatePicker: {
    width: "100%"
  },
  unsuitReportButton: {
    cursor: "pointer", 
    marginTop: 30
  },
  unsuitReportLabel: {
    cursor: "pointer"
  },
  createdMessage: {
    color: "#63cbf7", 
    fontSize: 15
  }
}));

export default function ModalForm({data, user, setUser, values, setValues, handleCloseModal, users, setSnackbar, setSnackbarMessage, setSeverity}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [note, setNote] = React.useState("");
  const [descriptionError, setDescriptionError] = React.useState(null);

  const sendUnsuitability = async () => {
    let body = {
      departmentId: data.department?.id,
      inspectionId: data.id,
      userId: user,
      deadline: (new moment(values.date)).toISOString(true),
      managerText: note,
      note: null
    };
    const resp = await Request("post", '/api/unsuitInspections/save-unsuitabilityCorrectionsInspection', body);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.tableDetails.reportCreated);
    handleCloseModal();
  };

  const handleDateChange = (date) => {
    setValues({ id: values.id, date: date });
  };

  return(
    <FormControl required className={classes.formControl}>
      <TextSelector
        label={language.tableDetails.personnel}
        value={user}
        onChangeFunc={value => setUser(value.target.value)}
        style={classes.textField}
        options={users}
      />
      <CustomDatePicker
        style={classes.CustomDatePicker}
        label={language.tableColumns.terminationDate}
        value={values.date} 
        shrink={true} 
        onChangeFunc={value => handleDateChange(value)}
        keyboardButtonProps={{'aria-label': 'change date'}}
      />
      <TextArea 
        error={descriptionError}
        label={"Açıklama"}
        value={note}
        style={classes.textField}
        onChangeFunc={value => {
          if(value.target.value.length > 5000) {
            setDescriptionError("Açıklama 5000 karakterden uzun olamaz.");
          } else {
            descriptionError && setDescriptionError(null);
            setNote(value.target.value);
          }
        }}
      />
      <Button 
        variant="contained" 
        color="primary"
        className={classes.unsuitReportButton} 
        onClick={() => sendUnsuitability()}
      >
        <label className={classes.unsuitReportLabel}>
          UYGUNSUZLUK GİDERME GÖREVİ OLUŞTUR
        </label>
      </Button>
    </FormControl>
  )
}