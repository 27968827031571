import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 20,
    width: 300,
    height: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonContainer: {
    marginTop: 10,
    textAlign: "center",
    paddingBottom: 20,
  },
}));

export default function Timer({ handleSendAgain, loading }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [minutes, setMinutes] = useState(3);
  const [seconds, setSeconds] = useState(0);

  // useInterval hook
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    });

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }

      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }, [delay]);
  }

  useInterval(() => {
    if (seconds === 0) {
      if (minutes !== 0) {
        setSeconds(59);
        setMinutes(minutes - 1);
      } else {
        navigate("/signin");
      }
    } else {
      setSeconds(seconds - 1);
    }
  }, 1000);

  const timerMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const timerSeconds = seconds < 10 ? `0${seconds}` : seconds;

  return (
    <div className="pomodoro">
      <div className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button onClick={handleSendAgain}>Tekrar Kod Gönder</Button>
        )}
      </div>
      <div className="timer">
        {timerMinutes}:{timerSeconds}
      </div>
    </div>
  );
}
