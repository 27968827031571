import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "name") {
        params.sort = "name," + query.orderDirection;
      } else if (query.orderBy.field === "nameShort") {
        params.sort = "nameShort," + query.orderDirection;
      } else if (query.orderBy.field === "companySGKNos") {
        params.sort = "companySGKNos," + query.orderDirection;
      } else if (query.orderBy.field === "city") {
        params.sort = "city.name," + query.orderDirection;
      } else if (query.orderBy.field === "eCompanyType") {
        params.sort = "eCompanyType," + query.orderDirection;
      } else if (query.orderBy.field === "taxOffice") {
        params.sort = "taxOffice," + query.orderDirection;
      } else if (query.orderBy.field === "taxIdentificationNumber") {
        params.sort = "taxIdentificationNumber," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/companies", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          active: data[i]?.isActive,
          nameShort: data[i]?.nameShort,
          cityId: data[i].city_town?.id,
          name: data[i].name,
          id: data[i].id,
          companySGKNos: data[i].companySGKNos,
          departments: data[i].departments,
          city: data[i].city_town?.name,
          eCompanyType: data[i].eCompanyType,
          taxOffice: data[i].taxOffice ? data[i].taxOffice : "Yok",
          taxIdentificationNumber: data[i].taxIdentificationNumber
            ? data[i].taxIdentificationNumber
            : "Yok",
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
