import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl, Input, InputLabel, Select } from "@material-ui/core";
import { modalFormStyles } from '../../../assets/styles/tableContainer';
import { jsonMapForTagItemAlt } from '../../../components/Utils/jsonMap';

const useStyles = makeStyles((theme) => (modalFormStyles(theme)));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function TaskModalForm({
  task,
  addKKDKindsToTask,
  options,
  loading,
}) {
  const classes = useStyles();

  const [kkdKinds, setKKDKinds] = React.useState([]);
  const [kindsAvailable, setKindsAvailable] = React.useState({});

  const init = useCallback(async () => {
    let temp = {...options.kkdKinds};
    for (let kindId of task.kkdKindIds) {
      for (let tempKindId in options.kkdKinds) {
        if (kindId === parseInt(tempKindId)) {
          delete temp[kindId];
        }
      }
    }
    setKindsAvailable(temp);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);


  const mapSelections = (selected) => {
    let temp = [...selected];
    for (let index in temp) {
      temp[index] = kindsAvailable[temp[index]];
    }
    return temp;
  };
  
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <InputLabel id="mutiple-checkbox-label">
        {"KKD Tipi"}
      </InputLabel>
      <Select
        labelId="mutiple-checkbox-label"
        id="mutiple-checkbox"
        required
        multiple
        fullWidth={true}
        value={kkdKinds}
        onChange={(value) => {
          setKKDKinds(value.target.value);
        }}
        input={<Input />}
        renderValue={(selected) => mapSelections(selected).join(", ")}
        MenuProps={MenuProps}
        className={classes.textField}
      >
        {jsonMapForTagItemAlt(kindsAvailable, kkdKinds)}
      </Select>
      <div style={{paddingBottom: 20, textAlign: "center"}}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => addKKDKindsToTask(kkdKinds)}
          className={classes.submit}
        >
          {"EKLE"}
        </Button>}
      </div>
    </FormControl>
  );
}