import React from 'react';
import AccidentActionTable from './AccidentActionTable';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { tableContainerStyles } from '../../../assets/styles/tableContainer';
import UnauthorizedPage from '../../UnauthorizedPage';
import SessionHelper from '../../../helpers/SessionHelper';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';

export default function AccidentActionsPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "workAccidentActionTable");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <AccidentActionTable/> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}