import React, { useCallback, useRef } from "react";
import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import RecordModal from "./RecordModal";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetFilterFields from "./RecordFilters";
import Table from "../../../components/Table/Table";
import FetchRecordData from "./FetchRecordData";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { GetOptions } from "../GetOptions";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

const columns = [
  { field: "id", title: "No" },
  { field: "companyName", title: "Şirket" },
  { field: "catalogueId", title: "Katalog No" },
  { field: "catalogueName", title: "Katalog" },
  { field: "january", title: "Ocak" },
  { field: "february", title: "Şubat" },
  { field: "march", title: "Mart" },
  { field: "april", title: "Nisan" },
  { field: "may", title: "Mayıs" },
  { field: "june", title: "Haziran" },
  { field: "july", title: "Temmuz" },
  { field: "august", title: "Ağustos" },
  { field: "september", title: "Eylül" },
  { field: "october", title: "Ekim" },
  { field: "november", title: "Kasım" },
  { field: "december", title: "Aralık" },
  { field: "sum", title: "Toplam" },
  { field: "average", title: "Ortalama" },
];

const othercolumns = [
  { field: "id", title: "No" },
  { field: "companyName", title: "Şirket" },
  { field: "departmentName", title: "Bölüm" },
  { field: "catalogueId", title: "Katalog No" },
  { field: "catalogueName", title: "Katalog" },
  { field: "january", title: "Ocak" },
  { field: "february", title: "Şubat" },
  { field: "march", title: "Mart" },
  { field: "april", title: "Nisan" },
  { field: "may", title: "Mayıs" },
  { field: "june", title: "Haziran" },
  { field: "july", title: "Temmuz" },
  { field: "august", title: "Ağustos" },
  { field: "september", title: "Eylül" },
  { field: "october", title: "Ekim" },
  { field: "november", title: "Kasım" },
  { field: "december", title: "Aralık" },
  { field: "sum", title: "Toplam" },
  { field: "average", title: "Ortalama" },
];

export default function Records() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(
    roles,
    "wasteMeasurementRecords"
  );

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [recordModal, setRecordModal] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [catalogues, setCatalogues] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [modalLoading, setModalLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    departmentFilter: null,
    wasteTypeFilter: null,
    wasteUnitFilter: null,
    yearFilter: null,
  });

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    setRecordModal(data || {});
    setOptions(await GetOptions(false, data?.companyId, data?.cityId));
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedRecord(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedRecord(null);
    setDialog(false);
  };

  const deleteRecord = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/waste-measurement/delete/" + selectedRecord?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      departmentFilter: null,
      wasteTypeFilter: null,
      wasteUnitFilter: null,
      yearFilter: null,
    });
    tableRef.current && tableRef.current.onQueryChange();
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteRecord}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <RecordModal
        modalLoading={modalLoading}
        setModalLoading={setModalLoading}
        recordProps={recordModal}
        setRecordModal={setRecordModal}
        catalogues={catalogues}
        setCatalogues={setCatalogues}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <RenderFilters />
      <Table
        tableRef={tableRef}
        authName="wasteMeasurementRecords"
        tableName="Atık Ölçüm Kayıtları"
        columns={filterProps.department ? othercolumns : columns}
        fetchData={FetchRecordData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        disableCreate
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => authorization?.delete}
        isEditable={(rowData) => authorization?.edit && filterProps.department}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
      />
    </>
  );
}
