import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  title: {
    float: "left",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  error: {
    color: "red",
    fontSize: "2vw",
  },
}));

export default function TypeDetails({ rowData }) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [visitType, setVisitType] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const getVisitType = useCallback(async (id) => {
    const resp = await Request("get", "/api/vm/visit-type/get/" + id);
    console.log(resp);
    return resp.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(async () => {
    const temp = await getVisitType(rowData.id);
    setVisitType(temp);
    console.log(temp);
    temp.length > 0 && setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [getVisitType, rowData]);
  React.useEffect(() => {
    init();
  }, [init]);

  const PrintMaterials = () => {
    return (
      <>
        <TableRow>
          <TableCell align="left">
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {visitType?.equipments.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.name} />
                </ListItem>
              ))}
            </List>
          </TableCell>
          <TableCell align="left">
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {visitType?.documents.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.name} />
                </ListItem>
              ))}
            </List>
          </TableCell>
          <TableCell align="left">
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {visitType?.videos.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.name} />
                </ListItem>
              ))}
            </List>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : visitType.id ? (
        <div className={classes.details}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <p className={classes.title}>
                      {language.visitTypes.equipments}
                    </p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>
                      {language.visitTypes.documents}
                    </p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>
                      {language.visitTypes.videos}
                    </p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <PrintMaterials />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <label className={classes.error}>{language.etc.error}</label>
      )}
    </React.Fragment>
  );
}
