import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { getUsers } from "./GetOptions";
import { Autocomplete } from "@material-ui/lab";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  id: null,
  companyId: SessionHelper.getUser()?.companyId,
  date: moment().toISOString(true),
  jobNumber: null,
  productTypeId: null,
  shiftStartLotNo: null,
  shift: null,
  userId: null,
};

export default function QualityControlModal({
  qualityControlProps,
  setQualityControlProps,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const isQualityPersonnel = user.roles.includes(
    "ROLE_KALITE_KONTROL_PERSONELI"
  );
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const isNewControl =
    qualityControlProps && Object.keys(qualityControlProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({
    companyId: null,
    date: null,
    jobNumber: null,
    productTypeId: null,
    shiftStartLotNo: null,
    shift: null,
    userId: null,
  });

  const init = useCallback(async () => {
    setModalLoading(true);
    if (!isNewControl && qualityControlProps) {
      setProps({ ...qualityControlProps });
    }
    setModalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, qualityControlProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = language.errors.blankField;
    let errors = {
      companyId: null,
      date: null,
      jobNumber: null,
      productTypeId: null,
      shiftStartLotNo: null,
      shift: null,
      userId: null,
    };
    if (isNewControl && !props.companyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if (!props.date) {
      errors.date = errorMessage;
      error = true;
    }
    if (!props.jobNumber) {
      errors.jobNumber = errorMessage;
      error = true;
    }
    if (isNewControl && !props.productTypeId) {
      errors.productTypeId = errorMessage;
      error = true;
    }
    if (!props.shiftStartLotNo) {
      errors.shiftStartLotNo = errorMessage;
      error = true;
    }
    if (!props.shift) {
      errors.shift = errorMessage;
      error = true;
    }
    if (isNewControl && !isQualityPersonnel && !props.userId) {
      errors.userId = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      isNewControl ? submitNewControl() : updateControl();
    }
  };

  const submitNewControl = async () => {
    setLoading(true);
    let postProps = { ...props };
    if (isQualityPersonnel) {
      postProps = { ...postProps, userId: user.id };
    }
    const resp = await Request("post", "/api/quality-controls", postProps);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateControl = async () => {
    setLoading(true);
    const resp = await Request(
      "patch",
      "/api/quality-controls/" + props.id,
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setProps(propsDefault);
    setQualityControlProps(null);
    setErrors({
      companyId: null,
      date: null,
      jobNumber: null,
      productTypeId: null,
      shiftStartLotNo: null,
      shift: null,
      userId: null,
    });
  };

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <TableEntryModal
      modal={qualityControlProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        {isNewControl && (
          <Autocomplete //COMPANY
            id="combo-box"
            options={options.companies?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.companyId
                ? getFromId(props.companyId, options.companies)
                : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, companyId: null });
              } else {
                setProps({ ...props, companyId: value?.id });
                errors.companyId && setErrors({ ...errors, companyId: null });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.companyId}
                helperText={errors.companyId}
                label={language.qualityControls.company}
                className={classes.textField}
              />
            )}
          />
        )}
        {isNewControl && !isQualityPersonnel && (
          <Autocomplete // USER
            id="combo-box"
            options={options.users?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={props.userId ? getFromId(props.userId, options.users) : null}
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, userId: null });
              } else {
                setProps({ ...props, userId: value?.id });
                errors.userId && setErrors({ ...errors, userId: null });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.userId}
                helperText={errors.userId}
                label={language.qualityControls.assignedUser}
                className={classes.textField}
              />
            )}
          />
        )}
        {isNewControl && (
          <Autocomplete //PRODUCT TYPE
            id="combo-box"
            options={options.productTypes?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.productTypeId
                ? getFromId(props.productTypeId, options.productTypes)
                : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, productTypeId: null });
              } else {
                setProps({ ...props, productTypeId: value?.id });
                errors.productTypeId &&
                  setErrors({ ...errors, productTypeId: null });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.productTypeId}
                helperText={errors.productTypeId}
                label={language.qualityControls.productType}
                className={classes.textField}
              />
            )}
          />
        )}
        <CustomDatePicker //DATE
          style={classes.dateField}
          label={language.qualityControls.date}
          error={errors.date}
          value={props?.date}
          shrink={true}
          onChangeFunc={(value) =>
            setProps({ ...props, date: moment(value).toISOString(true) })
          }
        />
        <TextArea
          shrink={props.jobNumber}
          label={language.qualityControls.jobNumber}
          required
          error={errors.jobNumber}
          value={props.jobNumber}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              jobNumber: value.target.value,
            });
            errors.jobNumber && setErrors({ ...errors, jobNumber: null });
          }}
        />
        <TextArea
          shrink={props.shiftStartLotNo}
          label={language.qualityControls.shiftStartLotNo}
          required
          error={errors.shiftStartLotNo}
          value={props.shiftStartLotNo}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              shiftStartLotNo: value.target.value,
            });
            errors.shiftStartLotNo &&
              setErrors({ ...errors, shiftStartLotNo: null });
          }}
        />
        <TextArea
          shrink={props.shift}
          label={language.qualityControls.shift}
          required
          error={errors.shift}
          value={props.shift}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              shift: value.target.value,
            });
            errors.shift && setErrors({ ...errors, shift: null });
          }}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewControl ? language.modal.update : language.modal.submit}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
