import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
  Grid,
} from "@material-ui/core";
import { TextArea } from "../../../components/Fields/TextFields";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import moment from "moment";
import { getKKDs, getKKDKinds } from "./GetOptions";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  buttonContainer: {
    textAlign: "center",
  },
}));

export default function ModalForm({
  requestKKDDebtProps,
  setRequestKKDDebtProps,
  onButtonClick,
  options,
  errors,
  setErrors,
  loading,
  setKKDKinds,
  kkdKinds,
  crew,
  setCrew,
  isNewKKDDebt,
  crewFiles,
  setCrewFiles,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [kkds, setKKDs] = React.useState([]);
  const [kkdKind, setKKDKind] = React.useState(null);
  // const [crew, setCrew] = React.useState(false);

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const clearProps = () => {
    setRequestKKDDebtProps({
      crewId: null,
      userId: null,
      kkdId: null,
      kkdSerialNo: null,
      givenDate: null,
      productionDate: null,
    });
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        {isNewKKDDebt && (
          <Grid item xs={6} className={classes.buttonContainer}>
            <Button
              variant="outlined"
              id="task_switch_to_personnel_button"
              color="secondary"
              disabled={!crew}
              onClick={() => {
                setCrew(false);
                clearProps();
              }}
            >
              KİŞİSEL ZİMMET YARAT
            </Button>
          </Grid>
        )}
        {isNewKKDDebt && (
          <Grid item xs={6} className={classes.buttonContainer}>
            <Button
              variant="outlined"
              id="task_switch_to_crew_button"
              color="secondary"
              disabled={crew}
              onClick={() => {
                setCrew(true);
                clearProps();
              }}
            >
              EKİPSEL ZİMMET YARAT
            </Button>
          </Grid>
        )}
      </Grid>
      {!crew && isNewKKDDebt && (
        <Autocomplete //PERSONNEL
          id="combo-box"
          options={options.users?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={
            requestKKDDebtProps.userId
              ? getFromId(requestKKDDebtProps.userId, options.users)
              : null
          }
          onChange={async (event, value) => {
            setRequestKKDDebtProps({
              ...requestKKDDebtProps,
              userId: value?.id,
            });
            setKKDKinds(await getKKDKinds(value?.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Personel"
              className={classes.textField}
            />
          )}
        />
      )}
      {crew && isNewKKDDebt && (
        <Autocomplete //CREW
          id="combo-box"
          options={options.crews?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={
            requestKKDDebtProps.crewId
              ? getFromId(requestKKDDebtProps.crewId, options.crews)
              : null
          }
          onChange={async (event, value) => {
            setRequestKKDDebtProps({
              ...requestKKDDebtProps,
              crewId: value?.id,
            });
            setKKDKinds(await getKKDKinds(null, value?.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Ekip"
              className={classes.textField}
            />
          )}
        />
      )}
      {isNewKKDDebt && (
        <Autocomplete //KKD KIND
          id="combo-box"
          options={kkdKinds?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!requestKKDDebtProps.userId && !requestKKDDebtProps.crewId}
          value={kkdKind}
          onChange={async (event, value) => {
            setKKDKind(value);
            setKKDs(await getKKDs(value?.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="KKD Tipi"
              className={classes.textField}
            />
          )}
        />
      )}
      {isNewKKDDebt && (
        <Autocomplete //KKD
          id="combo-box"
          options={kkds?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={
            !kkdKind ||
            (!requestKKDDebtProps.userId && !requestKKDDebtProps.crewId)
          }
          value={
            requestKKDDebtProps.kkdId
              ? getFromId(requestKKDDebtProps.kkdId, kkds)
              : null
          }
          onChange={async (event, value) => {
            setRequestKKDDebtProps({
              ...requestKKDDebtProps,
              kkdId: value?.id,
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="KKD"
              className={classes.textField}
            />
          )}
        />
      )}
      {isNewKKDDebt && (
        <TextArea
          required
          label={"KKD Seri No"}
          value={requestKKDDebtProps.kkdSerialNo}
          onChangeFunc={(value) =>
            setRequestKKDDebtProps({
              ...requestKKDDebtProps,
              kkdSerialNo: value.target.value,
            })
          }
          style={classes.textField}
        />
      )}
      {isNewKKDDebt && (
        <CustomDatePicker
          required
          error={errors.givenDateError}
          style={classes.textField}
          label="Veriliş Tarihi"
          value={
            requestKKDDebtProps.givenDate
              ? moment(requestKKDDebtProps.givenDate)
              : null
          }
          shrink={requestKKDDebtProps.givenDate}
          onChangeFunc={(value) => {
            setRequestKKDDebtProps({
              ...requestKKDDebtProps,
              givenDate: moment(value)
                .set("hour", 0)
                .set("minute", 0)
                .toISOString(true),
            });
            errors.givenDateError &&
              setErrors({ ...errors, givenDateError: null });
          }}
        />
      )}
      {isNewKKDDebt && (
        <CustomDatePicker
          style={classes.textField}
          label="Üretim Tarihi"
          value={
            requestKKDDebtProps.productionDate
              ? moment(requestKKDDebtProps.productionDate)
              : null
          }
          shrink={requestKKDDebtProps.productionDate}
          onChangeFunc={(value) =>
            setRequestKKDDebtProps({
              ...requestKKDDebtProps,
              productionDate: moment(value)
                .set("hour", 0)
                .set("minute", 0)
                .toISOString(true),
            })
          }
        />
      )}
      {crew && (
        <CustomFileDropzone
          files={crewFiles}
          setFiles={(value) => {
            setCrewFiles(value);
          }}
          fileLimit={200}
          dropzoneText={
            "Zimmet dosyası yüklemek için sürükleyin ya da buraya tıklayın."
          }
        />
      )}
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onButtonClick()}
            className={classes.submit}
          >
            {language.form.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
