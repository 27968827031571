import Request from '../../../helpers/Request';
import moment from 'moment';
import LanguageHelper from '../../../helpers/LanguageHelper';

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @param {func} setData a function to set the newly received data
 * An important note about setData: data state itself is never used directly, only the setData function
 * is used to update the page when new data is received.
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "accidentId,desc" 
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "accidentId") { params.sort = "accident.id," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "accident.edcOrContractorName," + query.orderDirection; }
      //else if (query.orderBy.field === "edcName") { params.sort = "accident.edcName," + query.orderDirection; }
      //else if (query.orderBy.field === "nomOfPersonnelWorkedLastYear") { params.sort = "accident.tMinusOneYearPersonnel," + query.orderDirection; }
      else if (query.orderBy.field === "companySgkNo") { params.sort = "accident.companySgkNo," + query.orderDirection; }
      else if (query.orderBy.field === "personnelName") { params.sort = "fName,lName," + query.orderDirection; }
      else if (query.orderBy.field === "personnelSGKNo") { params.sort = "fName," + query.orderDirection; }
      else if (query.orderBy.field === "accidentDateFormatted") { params.sort = "accident.accidentDate," + query.orderDirection; }
      else if (query.orderBy.field === "incapacitationStartFormatted") { params.sort = "accident.incapacitationStart," + query.orderDirection; }
      else if (query.orderBy.field === "incapacitationEndFormatted") { params.sort = "accident.incapacitationEnd," + query.orderDirection; }
      else if (query.orderBy.field === "accidentType") { params.sort = "accident.accidentTypeEPDK," + query.orderDirection; }
      else if (query.orderBy.field === "lostLimb") { params.sort = "lostLimb," + query.orderDirection; }
      else if (query.orderBy.field === "lostDays") { params.sort = "lostTimeInDays," + query.orderDirection; }
      else if (query.orderBy.field === "accidentCause") { params.sort = "accident.accidentReasonEPDK," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/epdk/single-row", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          sn: data[i].sn,
          fileKeys: data[i].fileKey,
          industrialAccidentForms: data[i].industrialAccidentForms,
          accidentId: data[i].accidentId,
          customAccidentId: data[i].customAccidentId,
          casualtyId: data[i].casualtyId,
          accidentGroupId: data[i].accidentGroupId,
          lostLimb: data[i].lostLimb,
          lostDays: data[i].lostTimeInDays,
          accidentType: data[i].accidentTypeEPDK?.nameTr,
          accidentTypeId: data[i].accidentTypeEPDK?.id,
          companySgkNo: data[i].companySgkNo,
          didGetISGTraining: data[i].didGetISGTraining,
          numOfCasualties: data[i].numOfCasualties,
          //nomOfPersonnelWorkedLastYear: data[i].tminusOneYearPersonnel,
          edcName: data[i].edcName,
          personnelName: (data[i].fname && data[i].lname) && data[i].fname + " " + data[i].lname,
          personnelFName: data[i].fname,
          personnelLName: data[i].lname,
          personnelSGKNo: data[i].personnelSgkNo,
          companyName: data[i].edcOrContractorName,
          accidentDate: data[i].accidentDate ? data[i].accidentDate : null,
          accidentDateFormatted: data[i].accidentDate ? (new moment(data[i].accidentDate)).format("DD/MM/YYYY") : "Tarih Girilmemiş",
          incapacitationStart: data[i].incapacitationStart ? data[i].incapacitationStart : null,
          incapacitationStartFormatted: data[i].incapacitationStart ? (new moment(data[i].incapacitationStart)).format("DD/MM/YYYY") : "Tarih Girilmemiş",
          incapacitationEnd: data[i].incapacitationEnd ? data[i].incapacitationEnd : null,
          incapacitationEndFormatted: data[i].incapacitationEnd ? (new moment(data[i].incapacitationEnd)).format("DD/MM/YYYY") : "Tarih Girilmemiş",
          accidentCause: data[i].accidentReasonEPDK?.nameTr,
          accidentCauseId: data[i].accidentReasonEPDK?.id,
          year: data[i].year
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}
