import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress } from "@material-ui/core";
import Request from "../../../helpers/Request";
import WorkAccidentForm from "./WorkAccidentForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import Group from "./Group";
import showSnackbar from "../../../components/Utils/showSnackbar";
import moment from "moment";
import TableEntryModal from "../../../components/Modal/TableEntryModal";

const useStyles = makeStyles((theme) => modalStyles(theme));

/**
 * Modal component used in the accident table for adding/editing work accidents.
 * @param {modal: boolean, setModal: func, setSnackbarMessage: func, setSnackbar: func, tableRef: obj, oldAccidentProps: obj, opts: obj[]} props of the accident modal
 */
export default function NewAccidentModal({
  modal,
  setModal,
  modalLoading,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldAccidentProps,
  opts,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [newAccidentProps, setNewAccidentProps] = React.useState({});
  const [accidentPersonnels, setAccidentPersonnels] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({
    edcCompanyId: null,
    companyId: null,
    companySGKSicilNo: null,
    departmentId: null,
    accidentTypeId: null,
    accidentTypeEPDKId: null,
    accidentDefinitionId: null,
    accidentPlaceId: null,
  });

  const init = useCallback(async () => {
    setNewAccidentProps({
      edcCompanyId: oldAccidentProps ? oldAccidentProps.edcCompanyId : null,
      companyId: oldAccidentProps
        ? oldAccidentProps.responsibleCompanyId
        : null,
      accidentTypeId: oldAccidentProps ? oldAccidentProps.accidentType : null,
      accidentTypeEPDKId: oldAccidentProps
        ? oldAccidentProps.accidentTypeEPDK
        : null,
      accidentDate: oldAccidentProps
        ? moment(oldAccidentProps.accidentDate)
            .set("hour", 18)
            .set("minute", 0)
            .toISOString(true)
        : moment().set("hour", 18).set("minute", 0).toISOString(true),
      companySGKSicilNo: oldAccidentProps
        ? oldAccidentProps.companySGKSicilNo
        : null,
      cityId: oldAccidentProps ? oldAccidentProps.city?.id : null,
      townId: oldAccidentProps ? oldAccidentProps.town?.id : null,
      departmentId: oldAccidentProps ? oldAccidentProps.departmentId : null,
      visibleCauses: oldAccidentProps ? oldAccidentProps.visibleCauses : null,
      summary: oldAccidentProps ? oldAccidentProps.summary : null,
      instantAction: oldAccidentProps ? oldAccidentProps.instantAction : null,
      accidentDefinitionId: oldAccidentProps
        ? oldAccidentProps.accidentDefinition
        : null,
      severity: oldAccidentProps ? oldAccidentProps.severity : null,
      accidentPlaceId: oldAccidentProps ? oldAccidentProps.accidentPlace : null,
    });
    setOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldAccidentProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Closes the modal and flushes the states.
   */
  const handleCloseModal = () => {
    setModal(false);
    setAccidentPersonnels([]);
    setNewAccidentProps({
      edcCompanyId: null,
      companyId: null,
      accidentTypeId: null,
      accidentTypeEPDKId: null,
      accidentDate: moment().set("hour", 18).set("minute", 0).toISOString(true),
      companySGKSicilNo: null,
      cityId: null,
      townId: null,
      departmentId: null,
      visibleCauses: null,
      summary: null,
      instantAction: null,
      accidentDefinitionId: null,
      accidentPlaceId: null,
      severity: null,
    });
    let opts = options;
    opts.users = {};
    opts.sgkNos = {};
    opts.departments = [];
    setOptions(opts);
    setErrors({
      edcCompanyId: null,
      companyId: null,
      companySGKSicilNo: null,
      departmentId: null,
      accidentTypeId: null,
      accidentTypeEPDKId: null,
      accidentDefinitionId: null,
      accidentPlaceId: null,
    });
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      edcCompanyId: null,
      companyId: null,
      companySGKSicilNo: null,
      departmentId: null,
      accidentTypeId: null,
      accidentTypeEPDKId: null,
      accidentDefinitionId: null,
      accidentPlaceId: null,
    };
    if (!newAccidentProps.edcCompanyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if (!newAccidentProps.companyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if (!newAccidentProps.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    if (!newAccidentProps.companySGKSicilNo) {
      errors.companySGKSicilNo = errorMessage;
      error = true;
    }
    if (!newAccidentProps.accidentTypeId) {
      errors.accidentTypeId = errorMessage;
      error = true;
    }
    if (!newAccidentProps.accidentTypeEPDKId) {
      errors.accidentTypeEPDKId = errorMessage;
      error = true;
    }
    if (!newAccidentProps.accidentDefinitionId) {
      errors.accidentDefinitionId = errorMessage;
      error = true;
    }
    if (!newAccidentProps.accidentPlaceId) {
      errors.accidentPlaceId = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      oldAccidentProps ? updateAccident() : submitNewAccident();
    }
  };

  /**
   * Request to post a new work accident with the data given.
   */
  const submitNewAccident = async () => {
    setLoading(true);
    newAccidentProps.casualties = accidentPersonnels;
    console.log(newAccidentProps);
    const resp = await Request(
      "post",
      "/api/industrial-accidents/",
      newAccidentProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  /**
   * Request to replace the old data with the new data.
   */
  const updateAccident = async () => {
    setLoading(true);
    newAccidentProps.casualties = accidentPersonnels;
    const resp = await Request(
      "put",
      "/api/industrial-accidents/" + oldAccidentProps.id,
      newAccidentProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <WorkAccidentForm
          updateAccident={updateAccident}
          submitNewAccident={submitNewAccident}
          newAccidentProps={newAccidentProps}
          setNewAccidentProps={setNewAccidentProps}
          options={options}
          setOptions={setOptions}
          oldAccidentProps={oldAccidentProps}
          errors={errors}
        />
        {newAccidentProps.companyId && (
          <>
            <p style={{ color: "#C7C7C7" }}>Kazazedeler</p>
            <Group
              options={options}
              oldAccidentProps={oldAccidentProps}
              accidents={accidentPersonnels}
              setAccidents={setAccidentPersonnels}
            />
          </>
        )}
        <div style={{ textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {oldAccidentProps ? "Kazayı Güncelle" : "Kaza Oluştur"}
            </Button>
          )}
        </div>
      </TableEntryModal>
    </>
  );
}
