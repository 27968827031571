import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { modalStyles } from '../../../assets/styles/tableContainer';

const useStyles = makeStyles((theme) => (modalStyles(theme)));

/**
 * A modal with open and close properties, children of the modal are given as props.
 * @param {open: boolean, onClose: func, children: obj} param props of the form modal
 */
export default function FormModal({ open, onClose, children }) {
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className={classes.paper}>
          {children}
        </div>
      </Fade>
    </Modal>
  )
}
