import Request from "../../../../helpers/Request";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "inspection.completionDate,desc",

      status: "DONE",
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "customId") {
        params.sort = "inspection.customId," + query.orderDirection;
      }
      if (query.orderBy.field === "completionDate") {
        params.sort = "inspection.completionDate," + query.orderDirection;
      }
      if (query.orderBy.field === "inspectionType") {
        params.sort = "inspection.inspectionType," + query.orderDirection;
      }
      if (query.orderBy.field === "company") {
        params.sort = "inspection.company.name," + query.orderDirection;
      }
      if (query.orderBy.field === "department") {
        params.sort = "inspection.department.name," + query.orderDirection;
      }
      if (query.orderBy.field === "personalUnsuitability") {
        params.sort = "inspection.personalUnsuitability," + query.orderDirection;
      }
      if (query.orderBy.field === "text") {
        params.sort = "inspection.text," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/inspections/isg-card/" + filterProps.user,
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          customId: data[i].customId,
          completionDate: data[i].completionDate,
          inspectionType: data[i].inspectionType,
          company: data[i].company?.name,
          department: data[i].department?.name,
          personalUnsuitability: data[i].personalUnsuitability,
          unsuitability: data[i].unsuitability,
          text: data[i].text,
          questions: data[i].questions,
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
