import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import CustomFileDropzone from "../../../../../components/Files/CustomFileDropzone";
import { CustomDatePicker } from "../../../../../components/Selectors/DatePickers";
import AltTable from '../../../../../components/Table/AltTable';
import { TextArea } from '../../../../../components/Fields/TextFields';
import IconTooltipButton from '../../../../../components/Buttons/IconTooltipButton';
import DoneIcon from '@material-ui/icons/Done';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    overflowY: "auto",
    maxHeight: "700px",
    margin: "0 auto",
    textAlign: "left",
    padding: 10,
    marginBottom: 10
  },
  formControl: {
    width: "100%",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  dropzone: {
    height: "100%",
    width: "18vw"
  }
}));

export default function HealthReport({files, setFiles, fileProps, setFileProps, errors, setErrors, setChangeMadeToFiles}) {
  const classes = useStyles();
  const fileExists = files.healthReport.length > 0;

  const titles = ["Alınan Tarih", "Açıklama", "Doktor Adı", "Dosya"];
  const [rows, setRows] = React.useState([]);

  const init = useCallback(async () => {
    let rows = [];
    rows.push([
      <RenderTakenDate key={"empty_hr_taken_date"} />,
      <RenderDescription key={"empty_description"} />,
      <RenderDoctorName key={"empty_doctor"} />,
      <RenderDropzone key={"empty_hr_dropzone"} />,
    ]);
    if(fileExists) { 
      for(let i = files.healthReport.length - 1; i >= 0; i--) {
        rows.push([
          <RenderTakenDate index={i} key={files.healthReport[i].name + "_hr_taken_date"} />,
          <RenderDescription index={i} key={files.healthReport[i].name + "_description"} />,
          <RenderDoctorName index={i} key={files.healthReport[i].name + "_doctor"} />,
          <RenderDropzone index={i} key={files.healthReport[i].name + "_hr_dropzone"} />,
        ]);
      }
    }
    await setRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.healthReportRequestList, errors]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderDescription = ({index}) => {
    const fileExists = index !== undefined && files.healthReport[index];
  
    const [saveButton, setSaveButton] = React.useState(false);
    const [value, setValue] = React.useState(fileProps?.healthReportRequestList[index]?.healthReportDescription);
  
    const onChange = () => {
      let temp = fileProps?.healthReportRequestList;
      !temp[index] && (temp[index] = {});
      temp[index].healthReportDescription = value;
      setFileProps({...fileProps, healthReportRequestList: temp});
      setChangeMadeToFiles(true);
    }
    return(
      <div style={{display: "flex", flexDirection: "row"}}>
        <TextArea
          required
          label={"Açıklama"}
          error={index !== undefined && errors.healthReportErrors[index] && errors.healthReportErrors[index].healthReportDescription}
          value={value}
          disabled={!fileExists}
          shrink={value || saveButton}
          style={classes.textField}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onChange();
              ev.preventDefault();
            }
          }}
          onChangeFunc={(event) => {
            setValue(event.target.value);
            !saveButton && setSaveButton(true);
          }}
          onBlurFunc={(event) => {onChange();}}
        />
        <div style={{marginTop: 20}}>
        {saveButton &&
          <IconTooltipButton title={"Değişikliği Kaydet"} onClick={onChange}>
            <DoneIcon />
          </IconTooltipButton>
        }
        </div>
      </div>
    )
  }

  const RenderDoctorName = ({index}) => {
    const fileExists = index !== undefined && files.healthReport[index];
  
    const [saveButton, setSaveButton] = React.useState(false);
    const [value, setValue] = React.useState(fileProps?.healthReportRequestList[index]?.doctorsName);
  
    const onChange = () => {
      let temp = fileProps?.healthReportRequestList;
      !temp[index] && (temp[index] = {});
      temp[index].doctorsName = value;
      setFileProps({...fileProps, healthReportRequestList: temp});
      setChangeMadeToFiles(true);
    }
  
    return(
      <div style={{display: "flex", flexDirection: "row"}}>
        <TextArea
          required
          label={"Doktor Adı"}
          error={index !== undefined && errors.healthReportErrors[index] && errors.healthReportErrors[index].doctorsName}
          value={value}
          disabled={!fileExists}
          shrink={value || saveButton}
          style={classes.textField}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onChange();
              ev.preventDefault();
            }
          }}
          onChangeFunc={(event) => {
            setValue(event.target.value);
            !saveButton && setSaveButton(true);
          }}
          onBlurFunc={(event) => {onChange();}}
        />
        <div style={{marginTop: 20}}>
        {saveButton &&
          <IconTooltipButton title={"Değişikliği Kaydet"} onClick={onChange}>
            <DoneIcon />
          </IconTooltipButton>
        }
        </div>
      </div>
    )
  }

  const RenderDropzone = useCallback(({index}) => {
    return(
      <div className={classes.dropzone}>
        <CustomFileDropzone
          files={index !== undefined ? [files.healthReport[index]] : []}
          setFiles={async (value) => {
            // If file added to dropzone, create the errors for it
            if(value[0] && !errors.healthReportErrors[files.healthReport.length]) {
              let temp = [...errors.healthReportErrors];
              temp[files.healthReport.length] = {
                healthReportDateTaken: null,
                doctorsName: null,
                healthReportDescription: null
              }
              await setErrors({...errors, healthReportErrors: temp});
            } 

            // If file added to dropzone, create the file props for it
            if(value[0] && !fileProps.healthReportRequestList[files.healthReport.length]) {
              let temp = [...fileProps.healthReportRequestList];
              temp[files.healthReport.length] = {
                healthReportDateTaken: null,
                doctorsName: null,
                healthReportDescription: null
              }
              await setFileProps({...fileProps, healthReportRequestList: temp});
            }

            // If file added to dropzone, push the new value to the file array
            // If file deleted from dropzone, remove the file from that index
            let temp = [...files.healthReport];
            value[0] ? temp.push(value[0]) : temp.splice(index, 1);
            await setFiles({...files, healthReport: temp});

            // If file deleted from dropzone, also remove the file props and errors from that index
            if(!value[0]) {
              let temp = fileProps?.healthReportRequestList;
              temp.splice(index, 1);
              await setFileProps({...fileProps, healthReportRequestList: temp});
              temp = errors.healthReportErrors;
              temp.splice(index, 1);
              await setErrors({...errors, healthReportErrors: temp});
            }
            setChangeMadeToFiles(true);
          }}
          index={index}
          fileLimit={1}
          onlyShowTitleWhenEmpty
          onChange={clearProps}
          dropzoneText={"Sağlık raporunu yüklemek için sürükleyin ya da buraya tıklayın."}
        />
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const RenderTakenDate = ({index}) => {
    let val = index !== undefined ? fileProps?.healthReportRequestList[index]?.healthReportDateTaken : null;
    val === undefined && (val = null);
    const fileExists = index !== undefined && files.healthReport[index];
    return(
      <CustomDatePicker
        required
        label={"Alınan Tarih"}
        error={index !== undefined && errors.healthReportErrors[index] && errors.healthReportErrors[index].healthReportDateTaken}
        value={val}
        disabled={!fileExists}
        shrink={val}
        onChangeFunc={value => {
          let temp = fileProps?.healthReportRequestList;
          !temp[index] && (temp[index] = {});
          temp[index].healthReportDateTaken = value;
          setFileProps({...fileProps, healthReportRequestList: temp});
          setChangeMadeToFiles(true);
        }}
        style={classes.textField}
      />
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  const clearProps = () => {
    setFileProps({
      ...fileProps,
      healthReportDateTaken: null,
      doctorsName: null,
      healthReportDescription: null,
    });
    setErrors({
      ...errors,
      healthReportDateTaken: null,
      healthReportDescription: null,
      doctorsName: null,
    });
  }

  const openFile = () => {
    window.open("https://isgapi.gdzelektrik.com.tr:8443/open-file/ie_giri_muayenesi_bilgilendirme_formu.xlsx-2021-08-09-ml9wzqt.xlsx");
  }

  return(
    <div>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4}/>
        <Grid item xs={4}><h2>Sağlık Raporu</h2></Grid>
        <Grid item xs={4} style={{textAlign: "left"}}>
          <IconTooltipButton title="Sağlık raporu şablonunu indir." label="ŞABLON" onClick={openFile} >
            <MenuBookIcon />
          </IconTooltipButton>
        </Grid>
      </Grid>
      <div className={classes.root}>
        <AltTable
          titles={titles}
          rows={rows}
        />
      </div>
    </div>
  )
}