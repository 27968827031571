import React, { useCallback } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
  Outlet
} from "react-router-dom";
import SessionHelper from "./helpers/SessionHelper";
import LogsPage from "./pages/logs/LogsPage";
import HeadcountsPage from "./pages/headcounts/HeadcountsPage";
import TasksPage from "./pages/auditTasks/taskspage/TasksPage";
import UnsuitPage from "./pages/unsuitpage/UnsuitPage";
import NearMissPage from "./pages/nearmisspage/NearMissPage";
import ActionPage from "./pages/actionPage/ActionPage";
import ActionDetailsPage from "./pages/actiondetailspage/ActionDetailsPage";
import FormPage from "./pages/formpage/FormPage";
import UserDetails from "./pages/users/userdetails/UserDetailsPage";
import DepartmentDetails from "./pages/departments/departmentdetails/DepartmentDetailsPage";
import UserPage from "./pages/users/UserPage";
import YasamUserPage from "./pages/yasamusers/YasamUserPage";
import Navbar from "./components/Navbar/navbar";
import Dashboard from "./pages/Dashboard/Dashboard";
import SignIn from "./pages/SignIn/SignIn";
import SmsVerification from "./pages/SmsVerification/SmsVerification";
import SignUp from "./pages/SignUp/SignUp";
import Activation from "./pages/AccountActivated/AccountActivated";
import Forgot from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import { LandingPage } from "./pages/landingPage";
import CompanyPage from "./pages/companies/CompanyPage";
import DepartmentPage from "./pages/departments/DepartmentPage";
import EPDKWorkAccidentPage from "./pages/workaccidentpage/EPDKWorkAccidentPage";
import EDVARSWorkAccidentPage from "./pages/edvarsAccidentPage/EDVARSWorkAccidentPage";
import MainWorkAccidentPage from "./pages/mainWorkAccidentPage/MainWorkAccidentPage";
import AccidentActionsPage from "./pages/mainWorkAccidentPage/actions/AccidentActionsPage";
import AccidentActionDetailsPage from "./pages/mainWorkAccidentPage/actiondetails/AccidentActionDetailsPage";
import CitiesTownsPage from "./pages/cities-towns/CitiesTownsPage";
import TaskDetailsPage from "./pages/auditTasks/taskdetailspage/TaskDetailsPage";
import UnsuitDetailsPage from "./pages/unsuitdetailspage/UnsuitDetailsPage";
import GoalsPage from "./pages/yasamusers/components/GoalsPage";
import GoalCharts from "./pages/reports/GoalCharts";
import ContractorFieldInspection from "./pages/reports/ContractorFieldInspection";
import ContractorPerformance from "./pages/reports/ContractorPerformance";
import YGKReport from "./pages/reports/YGKReport";
import TrainingsPage from "./pages/trainings/TrainingsPage";
import KKDPage from "./pages/kkdPages/KKDPage";
import KKDTaskPage from "./pages/kkdPages/KKDTaskPage";
import KKDDebtPage from "./pages/kkdPages/KKDDebtPage";
import MissingKKDPage from "./pages/kkdPages/MissingKKDPage";
import CrewPage from "./pages/kkdPages/CrewPage";
import CrewMissingKKDPage from "./pages/kkdPages/CrewMissingKKDPage";
import KKDReport from "./pages/reports/KKDReport";
import BoardsPage from "./pages/isgboards/boards/BoardsPage";
import MedicalExamPage from "./pages/healthmonitoring/medicalexam/MedicalExamPage";
import BoardActionsPage from "./pages/isgboards/actions/BoardActionsPage";
import HeavyEquipmentPage from "./pages/heavyequipmentsvehicles/heavyequipments/HeavyEquipmentPage";
import HeavyEquipmentDetailsPage from "./pages/heavyequipmentsvehicles/heavyequipmentdetails/HeavyEquipmentDetailsPage";
import VehiclePage from "./pages/heavyequipmentsvehicles/vehicles/VehiclePage";
import VehicleDetailsPage from "./pages/heavyequipmentsvehicles/vehicledetails/VehicleDetailsPage";
import AnalysisPage from "./pages/riskanalysis/analysis/AnalysisPage";
import RiskActionsPage from "./pages/riskanalysis/actions/RiskActionsPage";
import EmergencyPlansPage from "./pages/emergency/emergencyPlans/EmergencyPlansPage";
import DrillsPage from "./pages/emergency/drills/DrillsPage";
import DrillActionsPage from "./pages/emergency/drills/actions/DrillActionsPage";
import DrillActionDetailsPage from "./pages/emergency/drills/actiondetails/DrillActionDetailsPage";
import JobRiskAnalysisPage from "./pages/riskanalysis/jobriskanalysis/JobRiskAnalysisPage";
import LanguageHelper from "./helpers/LanguageHelper";
import { getViewAuthorizationForAll } from "./helpers/AuthorizationHelper";
import PersonnelNumberPage from "./pages/workaccidentpersonnelnumber/PersonnelNumberPage";
import EDVARSPersonnelNumberPage from "./pages/edvarsPersonnelNumberPage/EDVARSPersonnelNumberPage";
import OditUnsuitAnalysis from "./pages/reports/ODİTUnsuitAnalysis";
import WasteMeasurementsPage from "./pages/wastemeasurement/WasteMeasurementsPage";
import WasteDischargesPage from "./pages/wastedischarge/WasteDischargesPage";
import LoginStatusPage from "./pages/loginStatus/LoginStatusPage";
import ContractorAccidentsPage from "./pages/contractorAccidents/ContractorAccidentsPage";
import ContractorAuditsPage from "./pages/contractorAudits/ContractorAuditsPage";
import CrewNumbersPage from "./pages/riskanalysis/crewnumbers/CrewNumbersPage";
import ContractorNonconformitiesPage from "./pages/contractorNonconformities/ContractorNonconformitiesPage";
import ContractorOverallPage from "./pages/contractorOverall/ContractorOverallPage";
import ContractorITAReportPage from "./pages/contractorITAReport/ContractorITAReportPage";
import ContractorToolboxReportPage from "./pages/contractorToolboxReport/ContractorToolboxReportPage";
import TrainingReportPage from "./pages/trainingReport/TrainingReportPage";
import CertificateReportPage from "./pages/certificateReport/CertificateReportPage";
import TechnicalTrainingReportPage from "./pages/technicalTrainingReport/TechnicalTrainingReportPage";
import PersonnelDressSizePage from "./pages/personnelDressSize/PersonnelDressSizePage";
import FirstAidPage from "./pages/personnelFirstAid/FirstAidPage";
import IsgTrainingReportPage from "./pages/isgTrainingReport/IsgTrainingReportPage";
import HealthReportPage from "./pages/healthStatusReport/HealthReportPage";
import ConsumptionManagementPage from "./pages/consumptionManagement/ConsumptionManagementPage";
import SignInHistoryPage from "./pages/History/SignIn/SignInHistoryPage";
import ActivationHistoryPage from "./pages/History/Activation/ActivationHistoryPage";
import ResetHistoryPage from "./pages/History/Reset/ResetHistoryPage";
import NoticesPage from "./pages/notices/NoticesPage";
import WorkAccidentsSelectionsPage from "./pages/WorkAccidentsSelections/WorkAccidentsSelectionsPage";
import TosLogsPage from "./pages/tosLogs/TosLogsPage";
import IllnessReportPage from "./pages/healthmonitoring/illnessreports/IllnessReportPage";
import RoleAuthsPage from "./pages/roleauthmatrix/RoleAuthsPage";

//FROM DEMO
import ISGAuditTypesPage from "./pages/ISGAuditTypes/ISGAuditTypesPage";
import FormTemplatePage from "./pages/formtemplatepage/FormTemplatePage";
import VisitorManagementPage from "./pages/visitormanagement/VisitorManagementPage";
import WorkingAreasPage from "./pages/ibys/workingArea/WorkingAreasPage";
import WorkingTypesPage from "./pages/ibys/workingType/WorkingTypesPage";
import WorkEquipmentsPage from "./pages/ibys/workEquipments/WorkEquipmentsPage";
import QualityControlPage from "./pages/quality/controls/QualityControlPage";

const auth = [
  {
    path: "/SignIn",
    component: SignIn,
    exact: false,
  },
  {
    path: "/signup",
    component: SignUp,
    exact: false,
  },
  {
    path: "/forgot",
    component: Forgot,
    exact: false,
  },
  {
    path: "/reset/:token",
    component: ResetPassword,
    exact: false,
  },
  {
    path: "/activation/:token",
    component: Activation,
    exact: false,
  },
  {
    path: "/verification",
    component: SmsVerification,
    exact: false,
  },
];

const publicRoutes = [
  {
    path: `/personnel-headcounts`,
    component: HeadcountsPage,
  },
  {
    path: `/logs`,
    component: LogsPage,
  },
  {
    path: `/job-risk-analysis`,
    component: JobRiskAnalysisPage,
  },
  {
    path: `/emergency-plans`,
    component: EmergencyPlansPage,
  },
  {
    path: `/drills`,
    component: DrillsPage,
  },
  {
    path: `/drills-actions`,
    component: DrillActionsPage,
  },
  {
    path: `/drills-action/:id`,
    component: DrillActionDetailsPage,
  },
  {
    path: `/boards`,
    component: BoardsPage,
  },
  {
    path: `/board-actions`,
    component: BoardActionsPage,
  },
  {
    path: `/risk-analysis`,
    component: AnalysisPage,
  },
  {
    path: `/risk-actions`,
    component: RiskActionsPage,
  },
  {
    path: `/medical-exam`,
    component: MedicalExamPage,
  },
  {
    path: `/task/:id`,
    component: TaskDetailsPage,
  },
  {
    path: `/unsuittask/:id`,
    component: UnsuitDetailsPage,
  },
  {
    path: "/dashboard",
    component: Dashboard,
  },
  {
    path: "/isgtasks",
    component: TasksPage,
  },
  {
    path: "/unsuit-tasks",
    component: UnsuitPage,
  },
  {
    path: `/near-miss`,
    component: NearMissPage,
  },
  {
    path: "/actions",
    component: ActionPage,
  },
  {
    path: "/action/:id",
    component: ActionDetailsPage,
  },
  {
    path: "/users",
    component: UserPage,
  },
  {
    path: "/user/:id",
    component: UserDetails,
  },
  {
    path: "/yasamusers",
    component: YasamUserPage,
  },
  {
    path: "/forms",
    component: FormPage,
  },
  {
    path: "/formtemplates",
    component: FormTemplatePage,
  },
  {
    path: "/companies",
    component: CompanyPage,
  },
  {
    path: "/departments",
    component: DepartmentPage,
  },
  {
    path: "/department/:id",
    component: DepartmentDetails,
  },
  {
    path: "/workaccidents-epdk",
    component: EPDKWorkAccidentPage,
  },
  {
    path: "/workaccidents-epdk-headcount",
    component: PersonnelNumberPage,
  },
  {
    path: "/workaccidents-headcount-edvars",
    component: EDVARSPersonnelNumberPage,
  },
  {
    path: "/workaccidents-edvars",
    component: EDVARSWorkAccidentPage,
  },
  {
    path: "/workaccidents-main",
    component: MainWorkAccidentPage,
  },
  {
    path: "/workaccidents-actions",
    component: AccidentActionsPage,
  },
  {
    path: "/workaccidents-action/:id",
    component: AccidentActionDetailsPage,
  },
  {
    path: "/workaccidents-elder",
    component: Dashboard,
  },
  {
    path: "/workaccidents-dir",
    component: Dashboard,
  },
  {
    path: "/workaccidents-tedas",
    component: Dashboard,
  },
  {
    path: "/cities-towns",
    component: CitiesTownsPage,
  },
  {
    path: "/goals/:id",
    component: GoalsPage,
  },
  {
    path: "/goalcharts",
    component: GoalCharts,
  },
  {
    path: "/contractor-field-inspection",
    component: ContractorFieldInspection,
  },
  {
    path: "/contractor-performance",
    component: ContractorPerformance,
  },
  {
    path: "/ygk-report",
    component: YGKReport,
  },
  {
    path: "/kkd-report",
    component: KKDReport,
  },
  {
    path: "/training-records",
    component: TrainingsPage,
  },
  {
    path: "/training-catalogues",
    component: TrainingsPage,
  },
  {
    path: "/kkd",
    component: KKDPage,
  },
  {
    path: "/kkd-task",
    component: KKDTaskPage,
  },
  {
    path: "/kkd-debt",
    component: KKDDebtPage,
  },
  {
    path: "/crews",
    component: CrewPage,
  },
  {
    path: "/crew-missing-kkd",
    component: CrewMissingKKDPage,
  },
  {
    path: "/missing-kkd",
    component: MissingKKDPage,
  },
  {
    path: "/heavy-equipments",
    component: HeavyEquipmentPage,
  },
  {
    path: "/heavy-equipment/:id",
    component: HeavyEquipmentDetailsPage,
  },
  {
    path: "/vehicles",
    component: VehiclePage,
  },
  {
    path: "/vehicle/:id",
    component: VehicleDetailsPage,
  },
  {
    path: "/odit-analysis",
    component: OditUnsuitAnalysis,
  },
  {
    path: "/waste-measurement-catalogues",
    component: WasteMeasurementsPage,
  },
  {
    path: "/waste-measurement-records",
    component: WasteMeasurementsPage,
  },
  {
    path: "/waste-discharge-catalogues",
    component: WasteDischargesPage,
  },
  {
    path: "/waste-discharge-records",
    component: WasteDischargesPage,
  },
  {
    path: "/login-status",
    component: LoginStatusPage,
  },
  {
    path: "/accidents",
    component: ContractorAccidentsPage,
  },
  {
    path: "/audits",
    component: ContractorAuditsPage,
  },
  {
    path: "/nonconformities",
    component: ContractorNonconformitiesPage,
  },
  {
    path: "/overall",
    component: ContractorOverallPage,
  },
  {
    path: "/crew-numbers",
    component: CrewNumbersPage,
  },
  {
    path: "/itaReport",
    component: ContractorITAReportPage,
  },
  {
    path: "/toolboxReport",
    component: ContractorToolboxReportPage,
  },
  {
    path: "/training-report",
    component: TrainingReportPage,
  },
  {
    path: "/certificate-report",
    component: CertificateReportPage,
  },
  {
    path: "/technical-training-report",
    component: TechnicalTrainingReportPage,
  },
  {
    path: "/personnel-dress-size-report",
    component: PersonnelDressSizePage,
  },
  {
    path: "/first-aid-certificate-report",
    component: FirstAidPage,
  },
  {
    path: "/isg-training-report",
    component: IsgTrainingReportPage,
  },
  {
    path: "/health-status-report",
    component: HealthReportPage,
  },
  {
    path: "/consumption-management-records",
    component: ConsumptionManagementPage,
  },
  {
    path: "/consumption-management-catalogues",
    component: ConsumptionManagementPage,
  },
  {
    path: "/sign-in-history",
    component: SignInHistoryPage,
  },
  {
    path: "/activation-history",
    component: ActivationHistoryPage,
  },
  {
    path: "/reset-history",
    component: ResetHistoryPage,
  },
  {
    path: "/notices",
    component: NoticesPage,
  },
  {
    path: "/accidentDefinition",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/accidentType",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/accidentPlace",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/injuryType",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/bodyPart",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/lostLimb",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/section",
    component: WorkAccidentsSelectionsPage,
  },
  {
    path: "/tos-logs",
    component: TosLogsPage,
  },
  {
    path: "/illness-reports",
    component: IllnessReportPage,
  },
  {
    path: "/modules",
    component: RoleAuthsPage,
  },
  {
    path: "/role-auths",
    component: RoleAuthsPage,
  },
  {
    path: "/visitor-equipments",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-docs",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-company",
    component: VisitorManagementPage,
  },
  {
    path: "/visitors",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-videos",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-types",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-visits",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-current",
    component: VisitorManagementPage,
  },
  {
    path: "/visitor-page/approve/:visitId/:visitorId",
    component: VisitorManagementPage,
    exact: true,
  },
  {
    path: "/audit-types",
    component: ISGAuditTypesPage,
  },
  {
    path: "/working-areas",
    component: WorkingAreasPage,
  },
  {
    path: "/working-types",
    component: WorkingTypesPage,
  },
  {
    path: "/work-equipments",
    component: WorkEquipmentsPage,
  },
  {
    path: "/quality-controls",
    component: QualityControlPage,
  },
];

function PrivateRoute({ children, ...rest }) {
  //const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const isLoggedIn = SessionHelper.getIsLoggedIn();
  return isLoggedIn ? <Outlet /> : <Navigate to="/signin" />;
}

export default function AppRoutes() {
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();

  const [drawerList, setDrawerList] = React.useState([]);
  const [update, setUpdate] = React.useState(false);

  const populateDrawerList = useCallback(() => {
    if (user) {
      const roles = user.roles;
      const isAppUser = user.appUser;
      const authorization = getViewAuthorizationForAll(roles);
      let drawerList = [
        // DASHBOARD
        { label: language.sidebar.dashboard, Path: "/dashboard" },
        // LOGS
        authorization.logs && { label: "Kayıtlar", Path: "/logs" },

        //HISTORY

        (authorization.signInHistory ||
          authorization.activationHistory ||
          authorization.resetHistory) && {
          label: language.sidebar.history,
          values: [
            authorization.signInHistory && {
              label: language.history.signIn,
              Path: "/sign-in-history",
            },
            authorization.activationHistory && {
              label: language.history.activation,
              Path: "/activation-history",
            },
            authorization.resetHistory && {
              label: language.history.reset,
              Path: "/reset-history",
            },
            authorization.notices && {
              label: language.history.notices,
              Path: "/notices",
            },
          ],
        },
        // YAŞAM GÜVENLİĞİ KOMİTESİ
        (authorization.yasamUsersTable ||
          authorization.comitteeDepartmentGoalCharts) && {
          label: "Yaşam Güvenliği Komitesi",
          values: [
            authorization.yasamUsersTable && {
              label: "Üyeler",
              Path: "/yasamusers",
            },
            authorization.comitteeDepartmentGoalCharts && {
              label: "Hedeflerin Grafikleri",
              Path: "/goalcharts",
            },
          ],
        },

        // İSG DENETİMLERİ
        (authorization.tasksTable ||
          authorization.unsuitTaskTable ||
          authorization.contractorFieldInspection ||
          authorization.contractorPerformance ||
          authorization.oditAnalysis ||
          authorization.ygkReport ||
          authorization.kkdReport) && {
          label: "İSG Denetimleri",
          values: [
            authorization.tasksTable && {
              label: "İSG Denetim Görevleri",
              Path: "/isgtasks",
            },
            authorization.unsuitTaskTable && {
              label: "Uygunsuzluk Giderme Görevleri",
              Path: "/unsuit-tasks",
            },
            authorization.auditTypes && {
              label: language.sidebar.isgInspection,
              Path: "/audit-types",
            },
            authorization.formTemplateTable && {
              label: language.sidebar.formtemplates,
              Path: "/formtemplates",
            },
            (authorization.contractorFieldInspection ||
              authorization.contractorPerformance ||
              authorization.ygkReport ||
              authorization.oditAnalysis ||
              authorization.kkdReport) && {
              label: language.sidebar.reports,
              values: [
                authorization.contractorFieldInspection && {
                  label: "Yüklenici Saha Denetimleri",
                  Path: "/contractor-field-inspection",
                },
                authorization.contractorPerformance && {
                  label: "Yüklenici Performans Takip Raporları",
                  Path: "/contractor-performance",
                },
                authorization.ygkReport && {
                  label: "Yaşam Güvenliği Komitesi Denetim Raporları",
                  Path: "/ygk-report",
                },
                authorization.kkdReport && {
                  label: "Kişisel Koruyucu Donanım Raporları",
                  Path: "/kkd-report",
                },
                authorization.oditAnalysis && {
                  label: "ODİT Denetim Analiz Raporları",
                  Path: "/odit-analysis",
                },
              ],
            },
          ],
        },
        // İŞ KAZALARI
        (authorization.workAccidentTable ||
          authorization.workAccidentActionTable) && {
          label: "İş Kazaları",
          values: [
            authorization.workAccidentTable && {
              label: language.sidebar.workaccidents_main,
              Path: "/workaccidents-main",
            },
            authorization.epdkWorkAccidentTable && {
              label: language.sidebar.workaccidents_epdk,
              Path: "/workaccidents-epdk",
            },
            authorization.epdkWorkAccidentTable && {
              label: "EPDK Çalışan Sayısı Bildirim Tablosu",
              Path: "/workaccidents-epdk-headcount",
            },
            authorization.epdkWorkAccidentTable && {
              label: "Çalışan Sayısı EDVARS",
              Path: "/workaccidents-headcount-edvars",
            },
            authorization.edvarsAccidentTable && {
              label: "İş Kazaları EDVARS",
              Path: "/workaccidents-edvars",
            },
            // { label: language.sidebar.workaccidents_elder, Path: "/workaccidents-elder" },
            // { label: language.sidebar.workaccidents_dir, Path: "/workaccidents-dir" },
            // { label: language.sidebar.workaccidents_tedas, Path: "/workaccidents-tedas" }
            { label: "Aksiyonlar", Path: "/workaccidents-actions" },
            authorization.headcounts && {
              label: "Bölüm Personel Sayıları",
              Path: "/personnel-headcounts",
            },
            authorization.accidentDefinition && {
              label: language.workAccidentSelections.workAccidentsSelections,
              values: [
                {
                  label: language.workAccidentSelections.accidentDefinition,
                  Path: "/accidentDefinition",
                },
                {
                  label: language.workAccidentSelections.accidentType,
                  Path: "/accidentType",
                },
                {
                  label: language.workAccidentSelections.accidentPlace,
                  Path: "/accidentPlace",
                },
                {
                  label: language.workAccidentSelections.injuryType,
                  Path: "/injuryType",
                },
                {
                  label: language.workAccidentSelections.bodyPart,
                  Path: "/bodyPart",
                },
                {
                  label: language.workAccidentSelections.lostLimb,
                  Path: "/lostLimb",
                },
                {
                  label: language.workAccidentSelections.section,
                  Path: "/section",
                },
              ],
            },
          ],
        },
        // RAMAK KALA
        (authorization.nearMissTable || authorization.actionTable) && {
          label: "Ramak Kala",
          values: [
            authorization.nearMissTable && {
              label: "Bildirimler",
              Path: "/near-miss",
            },
            authorization.actionTable && {
              label: "Aksiyonlar",
              Path: "/actions",
            },
          ],
        },
        // EĞİTİMLER
        (authorization.trainingRecords || authorization.trainingCatalogues) && {
          label: "Eğitimler",
          values: [
            authorization.trainingRecords && {
              label: "Eğitim Kayıtları",
              Path: "/training-records",
            },
            authorization.trainingCatalogues && {
              label: "Eğitim Katalogları",
              Path: "/training-catalogues",
            },
          ],
        },
        // KİŞİSEL KORUYUCU DONANIMLAR
        (authorization.kkd ||
          authorization.kkdTask ||
          authorization.kkdDebt ||
          authorization.missingKKD ||
          authorization.crews ||
          authorization.kkdReport) && {
          label: "Kişisel Koruyucu Donanımlar",
          values: [
            authorization.kkd && {
              label: "Kişisel Koruyucu Donanımlar",
              Path: "/kkd",
            },
            authorization.kkdTask && {
              label: "KKD Görevleri",
              Path: "/kkd-task",
            },
            authorization.kkdDebt && { label: "Zimmetler", Path: "/kkd-debt" },
            authorization.missingKKD && {
              label: "Eksik Kişisel KKD",
              Path: "/missing-kkd",
            },
            authorization.missingKKD && {
              label: "Eksik Ekipsel KKD",
              Path: "/crew-missing-kkd",
            },
            authorization.crews && { label: "Ekipler", Path: "/crews" },
            authorization.kkdReport && {
              label: "Kişisel Koruyucu Donanım Raporları",
              Path: "/kkd-report",
            },
          ],
        },
        // İŞ MAKİNELERİ
        (authorization.heavyEquipmentTable || authorization.vehicleTable) && {
          label: "İş Makineleri ve Binek Araçlar",
          values: [
            authorization.heavyEquipmentTable && {
              label: "İş Makineleri",
              Path: "/heavy-equipments",
            },
            authorization.vehicleTable && {
              label: "Binek Araçlar",
              Path: "/vehicles",
            },
          ],
        },
        // İSG KURULLARI
        (authorization.boards || authorization.boardActions) && {
          label: "İSG Kurulları",
          values: [
            authorization.boards && { label: "İSG Kurulları", Path: "/boards" },
            authorization.boardActions && {
              label: "Aksiyonlar",
              Path: "/board-actions",
            },
          ],
        },
        // RİSK ANALİZİ
        (authorization.riskAnalysis ||
          authorization.riskActions ||
          authorization.jobRiskAnalysis ||
          authorization.crewNumbers) && {
          label: "Risk Analizi",
          values: [
            authorization.riskAnalysis && {
              label: "Risk Analizi",
              Path: "/risk-analysis",
            },
            authorization.riskActions && {
              label: "Aksiyonlar",
              Path: "/risk-actions",
            },
            authorization.jobRiskAnalysis && {
              label: "İş Tehlike Analizi",
              Path: "/job-risk-analysis",
            },
            authorization.crewNumbers && {
              label: "Ekip Sayıları",
              Path: "/crew-numbers",
            },
          ],
        },
        // ACİL DURUM
        (authorization.emergencyPlans ||
          authorization.drills ||
          authorization.drillActionTable) && {
          label: "Acil Durum",
          values: [
            authorization.emergencyPlans && {
              label: "Acil Durum Planları",
              Path: "/emergency-plans",
            },
            authorization.drills && { label: "Tatbikatlar", Path: "/drills" },
            authorization.drillActionTable && {
              label: "Aksiyonlar",
              Path: "/drills-actions",
            },
          ],
        },
        // SAĞLIK GÖZETİMİ
        (authorization.healthMonitoring || authorization.illnessReports) && {
          label: "Sağlık Gözetimi",
          values: [
            authorization.healthMonitoring && {
              label: "Muayeneler",
              Path: "/medical-exam",
            },
            authorization.illnessReports && {
              label: "Hastalık Sebebiyle Alınan Raporlar",
              Path: "/illness-reports",
            },
          ],
        },
        // ZIYARETCI YONETIMI
        (authorization.visitorEquipments ||
          authorization.visitorDocs ||
          authorization.visitorCompany ||
          authorization.visitors ||
          authorization.visitorVideos ||
          authorization.visitTypes ||
          authorization.visits ||
          authorization.currentVisitors) && {
          label: language.sidebar.visitorManagement,
          values: [
            authorization.visitorEquipments && {
              label: language.sidebar.visitorEquipments,
              Path: "/visitor-equipments",
            },
            authorization.visitorDocs && {
              label: language.sidebar.visitorDocs,
              Path: "/visitor-docs",
            },
            authorization.visitorVideos && {
              label: language.sidebar.visitorVideos,
              Path: "/visitor-videos",
            },
            authorization.visitorCompany &&
              isAppUser && {
                label: language.sidebar.visitorCompany,
                Path: "/visitor-company",
              },
            authorization.visitors &&
              isAppUser && {
                label: language.sidebar.visitors,
                Path: "/visitors",
              },
            authorization.visitTypes && {
              label: language.sidebar.visitTypes,
              Path: "/visitor-types",
            },
            authorization.visits &&
              isAppUser && {
                label: language.sidebar.visits,
                Path: "/visitor-visits",
              },
            authorization.currentVisitors &&
              isAppUser && {
                label: language.sidebar.visitorsAtFacility,
                Path: "/visitor-current",
              },
          ],
        },
        // IBYS MANAGEMENT
        authorization.ibys && {
          label: language.sidebar.ibysManagement,
          values: [
            { label: language.sidebar.workingAreas, Path: "/working-areas" },
            { label: language.sidebar.workingTypes, Path: "/working-types" },
            {
              label: language.sidebar.workEquipments,
              Path: "/work-equipments",
            },
          ],
        },
        // QUALITY
        authorization.qualityControls && {
          label: language.sidebar.quality,
          values: [
            { label: language.sidebar.controls, Path: "/quality-controls" },
          ],
        },
        // ATIK YÖNETİMİ
        (authorization.wasteMeasurementCatalogues ||
          authorization.wasteMeasurementRecords ||
          authorization.wasteDischargeRecords ||
          authorization.wasteDischargeCatalogues) && {
          label: "Atık Yönetimi",
          values: [
            authorization.wasteMeasurementRecords && {
              label: "Atık Ölçüm Kayıtları",
              Path: "/waste-measurement-records",
            },
            authorization.wasteMeasurementCatalogues && {
              label: "Atık Ölçüm Katalogları",
              Path: "/waste-measurement-catalogues",
            },
            authorization.wasteDischargeRecords && {
              label: "Atık Bertarafı Kayıtları",
              Path: "/waste-discharge-records",
            },
            authorization.wasteDischargeCatalogues && {
              label: "Atık Bertarafı Katalogları",
              Path: "/waste-discharge-catalogues",
            },
          ],
        },
        // CONSUMPTION MANAGEMENT - TÜKETİM YÖNETİMİ
        (authorization.consumptionManagementRecords ||
          authorization.consumptionManagementCatalogues) && {
          label: language.sidebar.consumptionManagement,
          values: [
            {
              label: language.consumptionManagement.records,
              Path: "/consumption-management-records",
            },
            {
              label: language.consumptionManagement.catalogues,
              Path: "/consumption-management-catalogues",
            },
          ],
        },

        // YÖNETİM / MANAGEMENT
        (authorization.loginStatus || authorization.tosLogs) && {
          label: language.sidebar.management,
          values: [
            authorization.loginStatus && {
              label: language.sidebar.loginStatus,
              Path: "/login-status",
            },
            authorization.tosLogs && {
              label: language.sidebar.tosStatus,
              Path: "/tos-logs",
            },
          ],
        },
        // YÜKLENİCİ PERFORMANS / SUB-CONTRACTOR PERFORMANCE
        (authorization.contractorWorkAccidents ||
          authorization.contractorAudits ||
          authorization.contractorNonconformities ||
          authorization.contractorOverall ||
          authorization.contractorITAReport ||
          authorization.contractorToolbox) && {
          label: language.sidebar.contractorPerformance,
          values: [
            authorization.contractorWorkAccidents && {
              label: language.sidebar.accidents,
              Path: "/accidents",
            },
            authorization.contractorAudits && {
              label: language.sidebar.contractorAudits,
              Path: "/audits",
            },
            authorization.contractorNonconformities && {
              label: language.sidebar.contractorNonconformities,
              Path: "/nonconformities",
            },
            authorization.contractorOverall && {
              label: language.sidebar.contractorOverall,
              Path: "/overall",
            },
            authorization.contractorITAReport && {
              label: language.sidebar.contractorITA,
              Path: "/itaReport",
            },
            authorization.contractorToolbox && {
              label: language.sidebar.contractorToolbox,
              Path: "/toolboxReport",
            },
          ],
        },
        // PERSONEL / PERSONNEL
        (authorization.trainingReport ||
          authorization.certificateReport ||
          authorization.technicalTrainingReport ||
          authorization.personnelDressSize ||
          authorization.firstAidCertificateReport ||
          authorization.isgTrainingReport ||
          authorization.healthStatusReport) && {
          label: language.sidebar.personnel,
          values: [
            authorization.trainingReport && {
              label: language.sidebar.trainingReport,
              Path: "/training-report",
            },
            authorization.certificateReport && {
              label: language.sidebar.certificateReport,
              Path: "/certificate-report",
            },
            authorization.technicalTrainingReport && {
              label: language.sidebar.technicalTrainingReport,
              Path: "/technical-training-report",
            },
            authorization.personnelDressSize && {
              label: language.sidebar.personnelDressSizeReport,
              Path: "/personnel-dress-size-report",
            },
            authorization.firstAidCertificateReport && {
              label: language.sidebar.firstAidCertificateReport,
              Path: "/first-aid-certificate-report",
            },
            authorization.isgTrainingReport && {
              label: language.sidebar.isgTrainingReport,
              Path: "/isg-training-report",
            },
            authorization.healthStatusReport && {
              label: language.sidebar.healthStatusReport,
              Path: "/health-status-report",
            },
          ],
        },
        // ROL-YETKİLER / ROLE-AUTHS
        (authorization.authTable || authorization.moduleTable) && {
          label: language.sidebar.roleAuth,
          values: [
            authorization.moduleTable && {
              label: language.sidebar.modules,
              Path: "/modules",
            },
            authorization.authTable && {
              label: language.sidebar.auths,
              Path: "/role-auths",
            },
          ],
        },
        // KULLANICILAR
        authorization.usersTable && {
          label: language.sidebar.users,
          Path: "/users",
        },
        // ŞİRKETLER
        authorization.companiesTable && {
          label: language.sidebar.companies,
          Path: "/companies",
        },
        // BÖLÜMLER
        authorization.departmentsTable && {
          label: language.sidebar.departments,
          Path: "/departments",
        },
        // İLLER VE İLÇELER
        authorization.citiesTownsTable && {
          label: language.sidebar.citiesTowns,
          Path: "/cities-towns",
        },

        // authorization.formTemplateTable && { label: language.sidebar.formtemplates, Path: "/formtemplates" },
        // authorization.unsuitTaskTable && { label: language.sidebar.nonCompianceTasks, Path: "/unsuitreports" }
      ];
      setDrawerList(drawerList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, user, update]);

  const init = useCallback(() => {
    populateDrawerList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populateDrawerList, update]);
  React.useEffect(() => {
    init();
  }, [init, user]);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" exact element={<LandingPage />}></Route>
          {auth.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              element={
                <route.component update={update} setUpdate={setUpdate} />
              }
            ></Route>
          ))}
          <Route path="/" element={<PrivateRoute />}>
            {publicRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={
                  <Navbar
                    component={<route.component />}
                    drawerList={drawerList}
                  />
                }
              ></Route>
            ))}
          </Route>
        </Routes>
      </Router>
    </>
  );
}
