import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl, TextField } from "@material-ui/core";
import moment from 'moment';
import { CustomDatePicker } from '../../../../components/Selectors/DatePickers';
import CustomFileDropzone from '../../../../components/Files/CustomFileDropzone';
import showSnackbar from '../../../../components/Utils/showSnackbar';
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from '../../../../helpers/Request';
import { getDepartments } from './GetOptions';

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20
  }
}));

export default function ModalForm({
  drillProps,
  setDrillProps,
  label,
  loading,
  setLoading,
  options,
  setOptions,
  handleCloseModal,
  oldDrillProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();

  const [practiseFile, setPractiseFile] = React.useState(drillProps.practiseFile)
  const [files, setFiles] = React.useState(drillProps.files);
  const [errors, setErrors] = React.useState({
    companyId: null,
    departmentId: null,
    plannedDate: null,
    completionDate: null
  });

  const getFromId = (options, id) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  /**
   * Request to replace the old data with the new data.
   */
  const updateDrill = async () => {
    setLoading(true);
    let formData = new FormData();
    if(files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    formData.append("practiseFile", practiseFile);
    drillProps.practiseId = oldDrillProps.id;
    delete drillProps.files;
    delete drillProps.practiseFile;
    formData.append("request", new Blob([JSON.stringify(drillProps)], { type: "application/json" }));
    const resp = await Request("patch", "/api/emergency/practise", formData);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  const submitNewDrill = async () => {
    setLoading(true);
    let formData = new FormData();
    if(files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    formData.append("practiseFile", practiseFile);
    delete drillProps.files;
    delete drillProps.practiseFile;
    formData.append("request", new Blob([JSON.stringify(drillProps)], { type: "application/json" }));
    const resp = await Request("post", "/api/emergency/practise", formData);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.created, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      companyId: null,
      departmentId: null,
      plannedDate: null,
      completionDate: null
    };

    if(!drillProps.companyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if(!drillProps.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    if(!drillProps.plannedDate) {
      errors.plannedDate = errorMessage;
      error = true;
    }
    if(!drillProps.completionDate) {
      errors.completionDate = errorMessage;
      error = true;
    }
    setErrors(errors);

    if(!error) {
      oldDrillProps ? updateDrill() : submitNewDrill();
    }
  }
  
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={drillProps.companyId ? getFromId(options.companies, drillProps.companyId) : null}
        onChange={async (event, value) => {
          setDrillProps({...drillProps, companyId: value?.id});
          let opts = {...options};
          opts.departments = await getDepartments(value?.id);
          setOptions(opts);
          errors.companyId && setErrors({...errors, companyId: null});
        }} 
        renderInput={(params) => 
          <TextField 
            {...params} 
            required 
            error={errors.department}
            helperText={errors.department}
            label="Şirket" 
            className={classes.textField}
          />
        }
      />
      <Autocomplete //DEPARTMENT
        id="combo-box"
        options={options.departments?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={drillProps.departmentId ? getFromId(options.departments, drillProps.departmentId) : null}
        onChange={async (event, value) => {
          setDrillProps({...drillProps, departmentId: value?.id});
          errors.departmentId && setErrors({...errors, departmentId: null});
        }} 
        renderInput={(params) => 
          <TextField 
            {...params} 
            required 
            error={errors.department}
            helperText={errors.department}
            label="Bölüm" 
            className={classes.textField}
          />
        }
      />
      <CustomDatePicker
        style={classes.dateField}
        label="Planlanma Tarihi" 
        value={drillProps.plannedDate ? drillProps.plannedDate.replaceAll("Z", "") : null} 
        shrink={drillProps.plannedDate} 
        onChangeFunc={value => {
          setDrillProps({...drillProps, plannedDate: moment(value).toISOString(true)});
          errors.plannedDate && setErrors({...errors, plannedDate: null});
        }}
      />
      <CustomDatePicker
        style={classes.dateField}
        label="Tamamlanma Tarihi" 
        value={drillProps.completionDate ? drillProps.completionDate.replaceAll("Z", "") : null} 
        shrink={drillProps.completionDate} 
        onChangeFunc={value => {
          setDrillProps({...drillProps, completionDate: moment(value).toISOString(true)});
          errors.completionDate && setErrors({...errors, completionDate: null});
        }}
      />
      <CustomFileDropzone
        files={practiseFile ? [practiseFile] : []}
        setFiles={(value) => {
          if(value.length > 0) {
            setPractiseFile(value[0]);
          } else {
            setPractiseFile(null);
          }
        }}
        fileLimit={1}
        dropzoneText={"Tatbikatı yüklemek için sürükleyin ya da buraya tıklayın."}
      />
      <CustomFileDropzone
        files={files}
        setFiles={(value) => {
          setFiles(value);
        }}
        fileLimit={200}
        dropzoneText={"Diğer dosyaları yüklemek için sürükleyin ya da buraya tıklayın."}
      />
      <div className={classes.buttonContainer}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          className={classes.submit}
        >
          {label}
        </Button>}
      </div>
    </FormControl>
  );
}