import React, { useCallback } from "react";
import AltTable from "../../../components/Table/AltTable";
import { useLocation } from "react-router-dom";
import Request from "../../../helpers/Request";
import {
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import moment from "moment";
import GetOptions from "./GetOptions";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import GoalForm from "./GoalForm";
import { MonthPicker } from "../../../components/Selectors/DatePickers";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { Bar, LabelList } from "recharts";
import clsx from "clsx";
import BarChart from "../../../components/Chart/BarChart";
import { inspectionTypes } from "../../../assets/constants";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";

const useStyles = makeStyles((theme) => ({
  details: {
    margin: "0 auto",
    maxWidth: "90%",
    padding: 10,
    textAlign: "center",
    marginBottom: 20,
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  fixedHeight: {
    maxHeight: fixedHeight,
    padding: 10,
    marginBottom: 10,
  },
  footerComponent: {
    display: "flex",
    flexDirection: "column",
  },
  dateFooter: {
    marginTop: 30,
    marginLeft: 0,
    marginRight: "auto",
  },
}));

const COLORS = ["#0088FE", "#FFBB28"];
const fixedHeight = 500;

export default function GoalsPage({ idVar, goalsArg }) {
  moment.locale("tr");
  const user = SessionHelper.getUser();
  const userRoles = user.roles;
  const classes = useStyles(idVar);
  const location = useLocation();
  const id = idVar ? idVar : location.pathname.substring(7);
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const auth = getAuthorizationForPage(userRoles, "yasamUsersTable");

  const [name, setName] = React.useState("Yok");
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [update, setUpdate] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const [options, setOptions] = React.useState(null);
  const [titles, setTitles] = React.useState(null);
  const [row, setRow] = React.useState(null);
  const [startDate, setStartDate] = React.useState(
    moment().set("date", 1).set("hour", 0).set("minute", 0).toISOString(true)
  );
  const [endDate, setEndDate] = React.useState(
    moment()
      .set("date", 1)
      .add(1, "month")
      .set("hour", 23)
      .set("minute", 59)
      .add(-1, "days")
      .toISOString(true)
  );
  const [goals, setGoals] = React.useState(null);
  const [goalStatistics, setGoalStatistics] = React.useState(null);
  const [goalProps, setGoalProps] = React.useState({
    inspectionGoalsInMonths: {
      BEHAVIORDRIVENAUDITING: null,
      BRIEFING: null,
      MONTLYPLANNEDSAFETYCHECK: null,
      NEARMISSREPORT: null,
    },
    userId: id,
    validFrom: null,
    validUntil: null,
  });

  const getGoals = useCallback(async () => {
    const params = {
      endDate: endDate,
      startDate: startDate,
    };
    const resp = await Request(
      "get",
      "/api/inspection-goals/user/" + id,
      null,
      params
    );
    console.log(resp);
    return resp.status === 200 ? resp.data : {};
  }, [id, endDate, startDate]);

  const getGoalStatistics = useCallback(async () => {
    const params = {
      endDate: endDate,
      startDate: startDate,
    };
    const resp = await Request(
      "get",
      "/api/inspection-goals/statistics/user/" + id,
      null,
      params
    );
    console.log(resp);
    return resp.data;
  }, [id, endDate, startDate]);

  const init = useCallback(async () => {
    const goals = goalsArg ? goalsArg : await getGoals();
    const goalStatistics = goalsArg ? goalsArg : await getGoalStatistics();
    setGoals(goals);
    setGoalStatistics(goalStatistics.statistics);
    setName(goalStatistics.user.fName + " " + goalStatistics.user.lName);
    !options && setOptions(await GetOptions(true));
    initTable(goals);
    initProps(goals);
    goals.validFrom && setUpdate(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes, options, startDate, endDate, refresh]);
  React.useEffect(() => {
    init();
  }, [init]);

  const initTable = (goals) => {
    let titles = [];
    let row = [];
    titles.push("No");
    titles.push("Tamamlanan Denetim Görevleri");
    titles.push("Denetim Görevi Hedefleri");
    titles.push("Geçerlilik Başlangıç");
    titles.push("Geçerlilik Bitiş");
    goals.id && auth.goals && titles.push("");
    setTitles(titles);
    row.push(goals.id);
    row.push(
      <List className={classes.list}>
        {formatCompletedInspections(goals).map((data, index) => {
          return (
            <ListItem>
              <ListItemText secondary={data} />
            </ListItem>
          );
        })}
      </List>
    );
    row.push(
      <List className={classes.list}>
        {formatInspectionGoalTimeFrames(goals).map((data, index) => {
          return (
            <ListItem>
              <ListItemText secondary={data} />
            </ListItem>
          );
        })}
      </List>
    );
    row.push(
      goals.validFrom
        ? moment(goals.validFrom).format("DD-MM-YYYY")
        : "Belirtilmemiş"
    );
    row.push(
      goals.validUntil
        ? moment(goals.validUntil).format("DD-MM-YYYY")
        : "Belirtilmemiş"
    );
    goals.id &&
      auth.goals &&
      row.push(
        <Button variant="outlined" onClick={() => setModal(true)}>
          Güncelle
        </Button>
      );
    setRow(row);
  };

  const initProps = (goals) => {
    formatInspectionGoalTimeFrames(goals);
    setGoalProps({
      ...goalProps,
      validFrom: goals.validFrom,
      validUntil: goals.validUntil,
    });
  };

  const formatCompletedInspections = (goals) => {
    let arr = [];
    for (let prop in goals.completedInspections) {
      if (options && options.inspectionTypes[`${prop}`]) {
        goals.completedInspections[`${prop}`] !== null &&
          arr.push(
            options.inspectionTypes[`${prop}`] +
              ": " +
              goals.completedInspections[`${prop}`]
          );
      }
    }
    return arr.length > 0 ? arr : ["Yok"];
  };

  const formatInspectionGoalTimeFrames = (goals) => {
    let arr = [];
    let temp = { ...goalProps };
    let update = false;
    for (let prop in goals.isBiWeekly) {
      if (options && options.inspectionTypes[`${prop}`]) {
        if (goals.isWeekly[`${prop}`]) {
          update = true;
          temp.inspectionGoalsInMonths[`${prop}`] = 4;
          arr.push(options.inspectionTypes[`${prop}`] + ": Haftada Bir");
        } else if (goals.isBiWeekly[`${prop}`]) {
          update = true;
          temp.inspectionGoalsInMonths[`${prop}`] = 2;
          arr.push(options.inspectionTypes[`${prop}`] + ": İki Haftada Bir");
        } else if (goals.isMonthly[`${prop}`]) {
          update = true;
          temp.inspectionGoalsInMonths[`${prop}`] = 1;
          arr.push(options.inspectionTypes[`${prop}`] + ": Ayda Bir");
        } else {
          update = true;
          temp.inspectionGoalsInMonths[`${prop}`] =
            goals.inspectionGoals[`${prop}`];
          arr.push(
            options.inspectionTypes[`${prop}`] +
              ": " +
              temp.inspectionGoalsInMonths[`${prop}`]
          );
        }
      }
    }
    setUpdate(update);
    setGoalProps(temp);
    return arr.length > 0 ? arr : ["Yok"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleCloseModal = () => {
    setModal(false);
    !update &&
      setTimeout(() => {
        initProps(goals);
      }, 200);
  };

  const formatDataForChart = (data) => {
    let chartData = [];
    for (let p in data) {
      let name = "";
      let row = {};
      for (let prop in data[p]) {
        if (data[p][prop] !== null) {
          if (prop === "goalInMonths") {
            row["Hedef"] = data[p][prop];
          } else if (prop === "completedInspections") {
            row["Gerçekleşen"] = data[p][prop];
          } else if (prop === "type") {
            name = inspectionTypes[`${data[p][prop]}`];
          }
        }
      }
      row.name = name;
      chartData.push(row);
    }
    return chartData;
  };

  const DateFooter = useCallback(() => {
    return (
      <div className={classes.footerComponent}>
        <label className={classes.dateFooter}>
          {moment(startDate).format("MMM yyyy")}
        </label>
      </div>
    );
  }, [classes, startDate]);

  const RenderSettings = () => {
    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={4}>
          <Paper className={classes.paper} style={{ height: 100 }}>
            <MonthPicker
              style={classes.textField}
              label="Geçerlilik Tarihi"
              value={moment(startDate)}
              shrink={startDate}
              onChangeFunc={(value) => {
                setStartDate(
                  moment(value)
                    .set("date", 1)
                    .set("hour", 0)
                    .set("minute", 0)
                    .toISOString(true)
                );
                setEndDate(
                  moment(value)
                    .add(1, "month")
                    .set("hour", 23)
                    .set("minute", 59)
                    .add(-1, "days")
                    .toISOString(true)
                );
              }}
            />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper
            className={classes.paper}
            style={{ height: 100, justifyContent: "center" }}
          >
            <p style={{ fontSize: 18 }}>{name}</p>
          </Paper>
        </Grid>
        {auth.goals && (
          <Grid item xs={1}>
            <Paper
              className={classes.paper}
              style={{ height: 100, width: 100, float: "right" }}
            >
              <div style={{ margin: "auto", marginLeft: "25%" }}>
                <IconTooltipButton
                  title="Kullanıcı İçin Hedef Yarat"
                  onClick={() => {
                    setUpdate(false);
                    setModal(true);
                    setGoalProps({
                      inspectionGoalsInMonths: {
                        BEHAVIORDRIVENAUDITING: null,
                        BRIEFING: null,
                        MONTLYPLANNEDSAFETYCHECK: null,
                        NEARMISSREPORT: null,
                      },
                      userId: id,
                      validFrom: null,
                      validUntil: null,
                    });
                  }}
                >
                  <AddBoxIcon fontSize="large" />
                </IconTooltipButton>
              </div>
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, offset } = props;

    const xOffset = width / 2 + offset - 1;
    const yOffset = height / 2 + 5;

    return (
      <text x={x + xOffset} y={y + yOffset} fill={"black"} textAnchor="end">
        {value === 0 ? "" : value}
      </text>
    );
  };

  const RenderChart = useCallback(() => {
    return (
      <Paper className={fixedHeightPaper}>
        <BarChart
          dataSet={formatDataForChart(goalStatistics)}
          title={"Yaşam Güvenliği Komitesi Gerçekleşmeleri"}
          FooterComponent={(data) => DateFooter(data)}
        >
          <Bar dataKey="Hedef" fill={COLORS[0]}>
            <LabelList
              dataKey="Gerçekleşen"
              position="center"
              content={renderCustomizedLabel}
              style={{ fill: "white" }}
            />
          </Bar>
          <Bar dataKey="Gerçekleşen" fill={COLORS[1]}>
            <LabelList
              dataKey="Gerçekleşen"
              position="center"
              content={renderCustomizedLabel}
              style={{ fill: "white" }}
            />
          </Bar>
        </BarChart>
      </Paper>
    );
  }, [goalStatistics, DateFooter, fixedHeightPaper]);

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      {name ? (
        <div
          className={classes.details}
          style={{ backgroundColor: idVar ? "none" : "#2D3446" }}
        >
          {/* SETTINGS */}
          <RenderSettings />

          {/* DATA TABLE */}
          {titles && row ? (
            <AltTable titles={titles} rows={[row]} />
          ) : (
            <CircularProgress color="secondary" />
          )}

          {/* CHART */}
          <RenderChart />

          {/* GOALS FORM MODAL */}
          {!idVar && auth.goals && row && (
            <GoalForm
              modal={modal}
              handleCloseModal={handleCloseModal}
              row={row}
              options={options}
              update={update}
              refresh={refresh}
              setRefresh={setRefresh}
              goalProps={goalProps}
              setGoalProps={setGoalProps}
              setSeverity={setSeverity}
              setSnackbar={setSnackbar}
              setSnackbarMessage={setSnackbarMessage}
            />
          )}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: window.innerHeight - 200,
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
}
