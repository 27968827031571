import React, { useCallback } from "react";
import Request from "../../../helpers/Request";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { TextField, FormControl, Button } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Details from "./Details";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    maxWidth: "90%",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px",
  },
  addNewFormButton: {
    padding: "0px 11px",
  },
  form: {
    width: "70vw",
    maxWidth: 600,
  },
  submit: {
    marginTop: "10px",
  },
}));

/**
 * A table component that lists the form templates. These form templates can be fully modified or deleted. New form templates can also be created.
 */
export default function FormTemplateTable() {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const tableRef = React.createRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [templateProps, setTemplateProps] = React.useState({
    title: "",
    content: "",
  });
  const [columns, setColumns] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.formId, field: "id" },
      { title: language.tableColumns.templateTitle, field: "title" },
    ]);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Opens the form template creation modal.
   */
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  /**
   * Closes the form template creation modal.
   */
  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  /**
   * Sends a post request to the API to add the newly created form template.
   */
  const addEntry = useCallback(async () => {
    const request = {
      title: templateProps.title,
      content: templateProps.content,
      questionRequests: null,
    };
    const resp = await Request("post", "/api/form-templates/", request);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleClose();
  }, [templateProps, handleClose, language, tableRef]);

  /**
   * Sends a delete request to the API for the removal of the form template.
   * @param {data: obj} param the data to remove
   */
  const removeEntry = useCallback(
    async (data) => {
      const resp = await Request(
        "delete",
        "/api/form-templates/" + data.id,
        null
      );
      console.log(resp);
      showSnackbar(
        resp,
        setSeverity,
        setSnackbarMessage,
        setSnackbar,
        language.etc.deleted,
        tableRef
      );
      return resp;
    },
    [language, tableRef]
  );

  // const updateEntry = useCallback(
  //   async (data) => {
  //     const formTemplate = await Request(
  //       "get",
  //       "/api/form-templates/" + data.id,
  //       null
  //     );
  //     if (formTemplate.status === 200) {
  //       const resp = await Request(
  //         "put",
  //         "/api/form-templates",
  //         formTemplate.data,
  //         null,
  //         { "Content-Type": "application/json" }
  //       );
  //       console.log(resp);
  //       showSnackbar(
  //         resp,
  //         setSeverity,
  //         setSnackbarMessage,
  //         setSnackbar,
  //         language.etc.updated,
  //         tableRef
  //       );
  //     }
  //     return formTemplate;
  //   },
  //   [language, tableRef]
  // );

  return (
    <React.Fragment>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={language.sidebar.formtemplates}
        authName="formTemplateTable"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpen}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        deleteEntry={removeEntry}
        isDeletable={(user, rowData) => !rowData.isDefault}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => (
          <Details rowData={rowData} tableRef={tableRef}></Details>
        )}
      />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <FormControl noValidate autoComplete="off" className={classes.form}>
              <TextField
                required
                label={language.form.title}
                onChange={(value) =>
                  setTemplateProps({
                    ...templateProps,
                    title: value.target.value,
                  })
                }
                fullWidth={true}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                required
                label={language.form.content}
                onChange={(value) =>
                  setTemplateProps({
                    ...templateProps,
                    content: value.target.value,
                  })
                }
                fullWidth={true}
                style={{ marginBottom: "10px" }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={addEntry}
                className={classes.submit}
              >
                {language.form.submit}
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
