import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import moment from "moment";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import jsonMap from "../../../components/Utils/jsonMap";
//import { getRiskLevels } from "./GetOptions";
// import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
}));

const defaultProps = {
  activity: null, //"string",
  affectedEntities: null, //string
  affectedUsers: [], //array //göndermene gerek yok
  completionDate: null, //"2021-12-24T08:34:09.894Z",
  // correctiveActionPriority: null, //0, //göndermene gerek yok
  correctiveActionPriorityStr: null, //enum
  correctiveActionProbability: null, //0,
  correctiveActionRiskScore: null, //0,
  correctiveActionVolume: null, //0,
  correctiveActions: null, //"string",
  currentState: null, //"string",
  danger: null, //"string",
  endDate: null, //"2021-12-24T08:34:09.894Z",
  no: null, //"string",
  opportunities: null, //"string",
  precautions: null, //"string",
  // priority: null, //0, //göndermene gerek yok
  priorityStr: null, //enum
  responsibleEntities: null, //string,
  // responsibleUserId: null, //0 //göndermene gerek yok
  risk: null, //"string",
  riskAnalysisId: null, //0,
  riskProbability: null, //0,
  riskScore: null, //0,
  riskVolume: null, //0,
  startDate: null, //"2021-12-24T08:34:09.894Z",
};

export default function RiskModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  rowData,
  reload,
  options,
  selectedRisk,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [props, setProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (selectedRisk) {
      setProps({ ...selectedRisk, riskAnalysisId: rowData.id });
    } else {
      setProps({ ...props, riskAnalysisId: rowData.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, modal, selectedRisk]);
  React.useEffect(() => {
    init();
  }, [init]);
  const handleCloseModal = () => {
    setModal(false);
    setProps(defaultProps);
  };

  const submitNewRisk = async () => {
    console.log(props);
    setLoading(true);
    const resp = await Request("post", "/api/riskAnalysis/risk", props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef,
      reload
    );
    handleCloseModal();
    setLoading(false);
  };

  // const updateRisk = async () => {
  //   setLoading(true);
  //   const resp = await Request("patch", "/api/riskAnalysis/risk", props);
  //   console.log(resp);
  //   showSnackbar(
  //     resp,
  //     setSeverity,
  //     setSnackbarMessage,
  //     setSnackbar,
  //     language.etc.updated,
  //     tableRef,
  //     reload
  //   );
  //   handleCloseModal();
  //   setLoading(false);
  // };

  // const getUserFromId = (id) => {
  //   for (let i = 0; i < options.users.length; i++) {
  //     if (options.users[i].id === id) {
  //       return options.users[i];
  //     }
  //   }
  //   return null;
  // };

  return (
    <>
      <TableEntryModal modal={modal} handleCloseModal={handleCloseModal}>
        <FormControl
          noValidate
          autoComplete="off"
          className={classes.formControl}
        >
          <TextArea
            label={"Risk No"}
            value={props.no}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, no: value.target.value })
            }
          />
          <TextArea
            label={"Faaliyet"}
            value={props.activity}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, activity: value.target.value })
            }
          />
          <TextArea
            label={"Fırsatlar"}
            value={props.opportunities}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, opportunities: value.target.value })
            }
          />
          <TextArea
            label={"Tehlike"}
            value={props.danger}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, danger: value.target.value })
            }
          />
          <TextArea
            label={"Risk"}
            value={props.risk}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, risk: value.target.value })
            }
          />
          <TextArea
            label={"Etkilenen Kişiler"}
            value={props.affectedEntities}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, affectedEntities: value.target.value })
            }
          />
          <TextArea
            label={"Mevcut Durum"}
            value={props.currentState}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, currentState: value.target.value })
            }
          />
          <TextArea
            label={"Risk Olasılık"}
            value={props.riskProbability}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, riskProbability: val });
            }}
          />
          <TextArea
            label={"Risk Şiddet"}
            value={props.riskVolume}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, riskVolume: val });
            }}
          />
          <TextArea
            label={"Risk Skoru"}
            value={props.riskScore}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, riskScore: val });
            }}
          />
          <TextSelector //RISK LEVELS
            label={"Risk Önem Derecesi"}
            value={props?.priorityStr}
            onChangeFunc={(value) =>
              setProps({
                ...props,
                priorityStr: value.target.value,
              })
            }
            style={classes.textField}
            options={jsonMap("riskLevels", options)} // TODO
          />
          {/*<TextArea
            label={"Risk Önem Derecesi"}
            value={props.priority}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, priority: val });
            }}
          /> */}
          <TextArea
            label={"Tedbirler"}
            value={props.precautions}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, precautions: value.target.value })
            }
          />
          <CustomDatePicker
            style={classes.dateField}
            label={"Başlangıç Tarihi"}
            value={props?.startDate}
            shrink={props?.startDate}
            onChangeFunc={(value) => {
              setProps({
                ...props,
                startDate: moment(value).toISOString(true),
              });
            }}
          />
          <CustomDatePicker
            style={classes.dateField}
            label={"Bitiş Tarihi"}
            value={props?.endDate}
            shrink={props?.endDate}
            onChangeFunc={(value) => {
              setProps({ ...props, endDate: moment(value).toISOString(true) });
            }}
          />
          <CustomDatePicker
            style={classes.dateField}
            label={"Gerçekleşme Tarihi"}
            value={props?.completionDate}
            shrink={props?.completionDate}
            onChangeFunc={(value) => {
              setProps({
                ...props,
                completionDate: moment(value).toISOString(true),
              });
            }}
          />
          <TextArea
            label={"Sorumlu Kişi"}
            value={props.responsibleEntities}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, responsibleEntities: value.target.value })
            }
          />
          {/*
          <Autocomplete
            options={options.users?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={props.responsibleUserId ? getUserFromId(props.responsibleUserId) : null}
            onChange={async (event, value) => {
              setProps({...props, responsibleUserId: value?.id});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Sorumlu Kişi"
                className={classes.textField}
              />
            )}
          />
          */}
          <TextArea
            label={"Düzeltici Faaliyetler"}
            value={props.correctiveActions}
            style={classes.textField}
            onChangeFunc={(value) =>
              setProps({ ...props, correctiveActions: value.target.value })
            }
          />
          <TextArea
            label={"Düzeltici Faaliyet Olasılık"}
            value={props.correctiveActionProbability}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, correctiveActionProbability: val });
            }}
          />
          <TextArea
            label={"Düzeltici Faaliyet Şiddet"}
            value={props.correctiveActionVolume}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, correctiveActionVolume: val });
            }}
          />
          <TextArea
            label={"Düzeltici Faaliyet Risk Skoru"}
            value={props.correctiveActionRiskScore}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, correctiveActionRiskScore: val });
            }}
          />
          <TextSelector //RISK LEVELS
            label={"Düzeltici Faaliyet Önem Derecesi"}
            value={props?.correctiveActionPriorityStr}
            onChangeFunc={(value) =>
              setProps({
                ...props,
                correctiveActionPriorityStr: value.target.value,
              })
            }
            style={classes.textField}
            options={jsonMap("riskLevels", options)} // TODO
          />
          {/*<TextArea
            label={"Düzeltici Faaliyet Önem Derecesi"}
            value={props.correctiveActionPriority}
            style={classes.textField}
            onChangeFunc={(value) => {
              let val = value.target.value;
              val = val.replace(/[^0-9.]/g, "");
              setProps({ ...props, correctiveActionPriority: val });
            }}
          />*/}
          <div style={{ paddingBottom: 20, textAlign: "center" }}>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={submitNewRisk}
                className={classes.submit}
              >
                {language.form.submit}
              </Button>
            )}
          </div>
        </FormControl>
      </TableEntryModal>
    </>
  );
}
