import { makeStyles } from "@material-ui/core/styles";
import { Divider, FormControl } from "@material-ui/core";
import React, { useCallback } from "react";
import OtherTrainings from "./components/OtherTrainings";
import MainTrainings from './components/MainTrainings';

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  divider: {
    marginTop: 20,
    marginBottom: 30
  }
}));

export default function Trainings({
  userProps,
  options,
  error,
  setError,
  setSnackbar,
  setSnackbarMessage,
  setSeverity
}) {
  const classes = useStyles();

  const RenderMainTrainings = useCallback(() => {
    return(
      <MainTrainings 
        userProps={userProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
      />
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const RenderOtherTrainings = useCallback(() => {
    return(
      <OtherTrainings
        userProps={userProps}
        options={options}
        error={error}
        setError={setError}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
      />
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <FormControl
        required
        autoComplete="off"
        className={classes.formControl}
      >
        <RenderMainTrainings />
        <Divider className={classes.divider} />
        <h2>Diğer Eğitimler</h2>
        <RenderOtherTrainings />
      </FormControl>
    </>
  );
}