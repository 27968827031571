import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function SectionModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  functionProps,
  module,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestFunctionProps, setRequestFunctionProps] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setRequestFunctionProps({
      name: functionProps ? functionProps.name : null,
      moduleId: module.moduleId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [functionProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestFunctionProps({
      name: null,
    });
  };

  const submitNewFunction = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/authority/module-function",
      requestFunctionProps
    );

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateFunction = async () => {
    setLoading(true);
    let props = { ...requestFunctionProps };
    props.id = functionProps.id;
    const resp = await Request("put", "/api/authority/module-function/", props);
    console.log(requestFunctionProps);

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestFunctionProps={requestFunctionProps}
              functionProps={functionProps}
              setRequestFunctionProps={setRequestFunctionProps}
              submitNewFunction={submitNewFunction}
              updateFunction={updateFunction}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
