import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { TextSelector } from "../../../../../components/Fields/TextFields";
import jsonMap from "../../../../../components/Utils/jsonMap";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    width: "40%",
    maxHeight: "700px",
    margin: "0 auto",
    textAlign: "center",
    marginBottom: 10,
    maxWidth: 600,
  },
  textField: {
    marginBottom: 20,
  },
}));

export default function Measurements({
  userProps,
  setUserProps,
  setChangeMade,
  options,
}) {
  const classes = useStyles();

  return (
    <div>
      <h2>Ölçüler</h2>
      <div className={classes.root}>
        <Paper style={{ padding: 20 }}>
          <TextSelector
            label="Ayakkabı Numarası"
            value={userProps.shoeSize ? userProps.shoeSize : ""}
            shrink={userProps.shoeSize !== null}
            onChangeFunc={(value) => {
              setUserProps({ ...userProps, shoeSize: value.target.value });
              setChangeMade && setChangeMade(true);
            }}
            style={classes.textField}
            options={jsonMap("shoeSizes", options)}
          />
          <TextSelector
            label="Ceket/Mont Bedeni"
            value={userProps.jacketSize ? userProps.jacketSize : ""}
            shrink={userProps.jacketSize !== null}
            onChangeFunc={(value) => {
              setUserProps({ ...userProps, jacketSize: value.target.value });
              setChangeMade && setChangeMade(true);
            }}
            style={classes.textField}
            options={jsonMap("sizes", options)}
          />
          <TextSelector
            label="Pantolon Bedeni"
            value={userProps.trouserSize ? userProps.trouserSize : ""}
            shrink={userProps.trouserSize !== null}
            onChangeFunc={(value) => {
              setUserProps({ ...userProps, trouserSize: value.target.value });
              setChangeMade && setChangeMade(true);
            }}
            style={classes.textField}
            options={jsonMap("sizes", options)}
          />
          <TextSelector
            label="Yelek Bedeni"
            value={userProps.vestSize ? userProps.vestSize : ""}
            shrink={userProps.vestSize !== null}
            onChangeFunc={(value) => {
              setUserProps({ ...userProps, vestSize: value.target.value });
              setChangeMade && setChangeMade(true);
            }}
            style={classes.textField}
            options={jsonMap("sizes", options)}
          />
          <TextSelector
            label="Tişört Bedeni"
            value={userProps.tshirtSize ? userProps.tshirtSize : ""}
            shrink={userProps.tshirtSize !== null}
            onChangeFunc={(value) => {
              setUserProps({ ...userProps, tshirtSize: value.target.value });
              setChangeMade && setChangeMade(true);
            }}
            style={classes.textField}
            options={jsonMap("sizes", options)}
          />
        </Paper>
      </div>
    </div>
  );
}
