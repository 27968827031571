import React, { useState,useRef } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageHelper from "../../helpers/LanguageHelper";
import logo from "../../assets/images/pincident.png";
import SessionHelper from "../../helpers/SessionHelper";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Request from "../../helpers/Request";
import Copyright from "../../components/Footer/Copyright";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Settings from "../../components/Buttons/Settings";
import { CircularProgress } from "@material-ui/core";
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 20,
    width: 300,
    height: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonContainer: {
    marginTop: 10,
    textAlign: "center",
    paddingBottom: 20,
  },
  reCaptcha: {
    marginLeft: theme.spacing(6),
    marginTop: theme.spacing(1),
  },
}));

export default function SignIn({ update, setUpdate }) {
  const darkTheme = createTheme({ palette: { type: "dark" } });
  const lightTheme = createTheme({ palette: { type: "light" } });
  const reRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const regexRules =
    /(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\d)(?=.*[!@#$%&*'(),\-+<=>:;?{}^._])[A-Za-z\d!@#$%&*'(),\-+<=>:;?{}^._]{8,32}$/;

  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [severity, setSeverity] = useState("info");
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode")
  );
  // eslint-disable-next-line no-unused-vars
  const [, setLanguage] = React.useState(language);
  const [loading, setLoading] = React.useState(false);

  async function handleLogin(username, password) {
    setLoading(true);
    const resp = await Request("post", "/api/auth/signin", {
      username: username,
      password: password,
    });
    console.log(resp);
    if (resp?.status === 200) {
      let data = resp.data.content;
      if (data.isLoggedIn === true) {
        SessionHelper.setUser(data.jwt);
        setUpdate(!update);
        // const token = reRef.current.getValue();
        // const captchaResp = await Request(
        //   "post",
        //   "/api/validate-captcha",
        //   null,
        //   {
        //     key: token,
        //   }
        // );
        // console.log(captchaResp);
        // reRef.current.reset();
        // if (captchaResp?.status === 200) {
          if (!regexRules.test(password)) {
            location?.state
              ? navigate(location?.state?.from?.pathname)
              : navigate("/dashboard", { error: true });
          } else {
            location?.state
              ? navigate(location?.state?.from?.pathname)
              : navigate("/dashboard");
          }
        // } else {
        //   SessionHelper.deleteUser();
        //   setSeverity("error");
        //   setSnackbarMessage("Captcha Doğrulama Hatası");
        //   setSnackbar(true);
        // }
      } else {
        navigate("/verification", {
          state: {
            password: password,
            key: data.key,
            message: resp.data.messageResponse.message,
          },
        });
      }
    } else {
      if (resp?.status === 429) {
        setSeverity("error");
        setSnackbarMessage(
          "Çok fazla istekte bulundunuz. Lütfen daha sonra tekrar deneyiniz."
        );
        setSnackbar(true);
      } else {
        if (resp?.data && resp?.data.messageResponse) {
          setSeverity("error");
          setSnackbarMessage(resp.data.messageResponse.message);
          setSnackbar(true);
        } else {
          setSeverity("error");
          setSnackbarMessage(language.login.unexpectedError);
          setSnackbar(true);
        }
      }
    }
    setLoading(false);
  }

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (username === "") {
        setSnackbarMessage(language.login.provideEmail);
        setSnackbar(true);
      } else if (password === "") {
        setSnackbarMessage(language.login.providePassword);
        setSnackbar(true);
      } else {
        let re =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
        if (re.test(username)) {
          handleLogin(username, password);
        } else {
          setSnackbar(true);
          setSnackbarMessage(language.login.weirdEmail);
        }
      }
    }
  };

  return (
    <Container maxWidth={false}>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
        disableHide={true}
      />
      <Settings
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        setLanguage={setLanguage}
        isLoginPage
      />
      <Container component="main" maxWidth="xs">
        <ThemeProvider theme={darkMode === "true" ? darkTheme : lightTheme}>
          <CssBaseline />
          <div onKeyDown={_handleKeyDown} className={classes.paper}>
            <img className={classes.avatar} alt={logo} src={logo} />
            <Typography component="h1" variant="h5">
              {language.login.title}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                error={severity === "error"}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={language.login.email}
                autoFocus
                onChange={(username) =>
                  setUsername(username.target.value.trim())
                }
              />
              <TextField
                error={severity === "error"}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={language.login.password}
                type="password"
                onChange={(password) => setPassword(password.target.value)}
              />
              {/* <ReCAPTCHA
                className={classes.reCaptcha}
                sitekey="6Lc6ej8hAAAAAERG0jHreJ97zkVQpUH1cmpgbgiS"
                ref={reRef}
              /> */}
              <div className={classes.buttonContainer}>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      if (username === "") {
                        setSnackbarMessage(language.login.provideEmail);
                        setSnackbar(true);
                      } else if (password === "") {
                        setSnackbarMessage(language.login.providePassword);
                        setSnackbar(true);
                      } else {
                        let re =
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
                        if (re.test(username)) {
                          handleLogin(username, password);
                        } else {
                          setSnackbar(true);
                          setSnackbarMessage(language.login.weirdEmail);
                        }
                      }
                    }}
                  >
                    {language.login.signin}
                  </Button>
                )}
              </div>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => {
                      navigate("/forgot");
                    }}
                  >
                    {language.login.forgot}
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    {language.login.activate}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright
              string={"DELTA Smart Technologies"}
              date={new Date().getFullYear()}
              url={"https://deltasmart.tech/en/home/"}
            />
          </Box>
        </ThemeProvider>
      </Container>
    </Container>
  );
}
