import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters 
 */
 export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return(
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />

      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.filterUsers?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.reportedByFilter}
                onChange={(event, value) => {
                  setTableFilters({...tableFilters, reportedByFilter: value});
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Bildiren Kişi" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.filterUsers?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.incidentUserFilter}
                onChange={(event, value) => {
                  setTableFilters({...tableFilters, incidentUserFilter: value});
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Personel" className={classes.textField}/>}
              />
            </Grid>
          </Grid> 
  
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  )
}