import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import showSnackbar from "../../../components/Utils/showSnackbar";
import SessionHelper from "../../../helpers/SessionHelper";

/**
 * Modal component used in the user table for adding/editing users.
 * @param {modal: boolean, setModal: func, setSnackbarMessage: func, setSnackbar: func, tableRef: obj, oldUserProps: obj, opts: obj[], modalLoading: boolean} props of the user modal
 */
export default function NewUserModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldUserProps,
  opts,
  modalLoading,
}) {
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const language = LanguageHelper.getLanguage();

  const [file, setFile] = React.useState(new FormData());
  const [length, setLength] = React.useState(0);
  const [newUserProps, setNewUserProps] = React.useState({});
  const [options, setOptions] = React.useState(opts);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    let regions = [];
    if (oldUserProps) {
      for (let prop in oldUserProps.responsibilityRegions) {
        regions.push(oldUserProps.responsibilityRegions[prop].id);
      }
    }

    setNewUserProps({
      enabled: null,
      isAppUser: null,
      educationStatusId: null,
      email: null,
      fName: null,
      lName: null,
      active: null,
      roles: specialContractor ? ["ROLE_YUKLENICI_PERSONEL"] : [],
      companyId: specialContractor ? user.company?.id : null,
      companyName: specialContractor ? user.company?.nameShort : null,
      departmentId: null,
      id: null,
      tcNo: null,
      phoneNo: "05",
      startDateOfWork: null,
    });
    setOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOptions, opts, oldUserProps, modal]);
  React.useEffect(() => {
    init();
  }, [init, modal]);

  /**
   * Closes the modal and flushes the states.
   */
  const handleCloseModal = () => {
    setModal(false);
    setNewUserProps({
      enabled: null,
      isAppUser: null,
      educationStatusId: null,
      email: null,
      fName: null,
      lName: null,
      active: null,
      roles: [],
      companyId: null,
      companyName: null,
      departmentId: null,
      id: null,
      tcNo: null,
      phoneNo: "05",
      startDateOfWork: null,
    });
    setFile(new FormData());
    setLength(0);
    let newOpts = opts;
    newOpts.departments = [];
    setOptions(newOpts);
  };
  /**
   * Request to submit a new user with the data given.
   */
  const submitNewUser = async () => {
    setLoading(true);
    // Delete all whitespaces from both ends of all string type properties of newUser object.
    Object.keys(newUserProps).forEach(
      (k) =>
        (newUserProps[k] =
          typeof newUserProps[k] == "string"
            ? newUserProps[k].trim()
            : newUserProps[k])
    );
    file.append(
      "newUser",
      new Blob([JSON.stringify(newUserProps)], { type: "application/json" })
    );
    const resp = await Request("post", "/api/users", file);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          closeUpperModal={handleCloseModal}
          file={file}
          setFile={setFile}
          length={length}
          setLength={setLength}
          newUserProps={newUserProps}
          setNewUserProps={setNewUserProps}
          options={options}
          submitNewUser={submitNewUser}
          setOptions={setOptions}
          oldUserProps={oldUserProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          loading={loading}
        />
      </TableEntryModal>
    </>
  );
}
