import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { modalFormStyles } from "../../../../assets/styles/tableContainer";
import { MultiDatePicker } from "../../../../components/Selectors/DatePickers";
import { TextArea } from "../../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function MultipleDateSelection({
  newTaskProps,
  setNewTaskProps,
}) {
  const classes = useStyles();

  const [error, setError] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [daysGiven, setDaysGiven] = React.useState(0);

  const init = useCallback(async () => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const isoToDate = (dates) => {
    let startDates = [];
    for (let date of dates) {
      startDates.push(new Date(date));
    }
    return startDates;
  };

  const onChange = (dates, days) => {
    let startDates = [];
    let endDates = [];
    for (let date of dates) {
      let temp = new Date(date);
      startDates.push(
        moment(temp).set("hour", 0).set("minute", 0).toISOString(true)
      );
      endDates.push(
        moment(temp)
          .set("hour", 23)
          .set("minute", 59)
          .add(days ? days : daysGiven, "days")
          .toISOString(true)
      );
    }
    setNewTaskProps({
      ...newTaskProps,
      startTimes: startDates,
      deadlines: endDates,
    });
    setOpen(false);
  };

  return (
    <div style={{ textAlign: "center", margin: "0 auto" }}>
      <MultiDatePicker
        open={open}
        id="task_multi_date_picker"
        minDate={new Date()}
        dates={isoToDate(newTaskProps.startTimes)}
        setOpen={setOpen}
        onChangeFunc={onChange}
        required
        label={"Başlangıç Tarihleri"}
      />
      <div
        style={{ width: "210px", margin: "0 auto", marginTop: "20px" }} //DAYS GIVEN
      >
        <TextArea
          label={"Tamamlanma Süresi (+Gün)"}
          id="task_days_given_field"
          value={daysGiven}
          error={error}
          number
          shrink={!daysGiven}
          style={classes.textField}
          onChangeFunc={async (value) => {
            const val = value.target.value
              .replace(/\s/g, "")
              .replace(/\D/g, "");
            if (parseInt(val) > 7) {
              setError(
                "Görevler'in tamamlanma süresi en fazla 7 gün olabilir."
              );
            } else if (parseInt(val) < 0) {
              setError("Görevler'in tamamlanma süresi en az 0 gün olabilir.");
            } else {
              error && setError(null);
              setDaysGiven(val ? val : 0);
              onChange(isoToDate(newTaskProps.startTimes), val);
            }
          }}
        />
      </div>
      <p style={{ marginTop: "-15px", color: "red", fontSize: 12 }}>
        Not: 0 seçili ise Termin Tarihi, Başlangıç Tarihi ile aynı olacaktır.
      </p>
    </div>
  );
}
