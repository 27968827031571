import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import { active } from "../../../assets/constants";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestLostLimbProps,
  sectionProps,
  setRequestSectionProps,
  submitNewSection,
  updateSection,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      {sectionProps && (
        <TextSelector
          label="Aktiflik Durumu"
          shrink={!sectionProps.active}
          value={
            requestLostLimbProps.active
              ? requestLostLimbProps.active
              : sectionProps.active
          }
          onChangeFunc={(value) =>
            setRequestSectionProps({
              ...requestLostLimbProps,
              active: value.target.value,
              "selection-type": "Section",
            })
          }
          style={classes.textField}
          options={jsonMap(false, active)}
        />
      )}
      <TextArea
        required
        label={language.workAccidentSelections.accidentDefinition} //will be changed
        value={requestLostLimbProps.name}
        onChangeFunc={(value) =>
          setRequestSectionProps({
            ...requestLostLimbProps,
            name: value.target.value,
            "selection-type": "Section",
          })
        }
        style={classes.textField}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              sectionProps
                ? updateSection()
                : submitNewSection()
            }
            className={classes.submit}
          >
            {sectionProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
