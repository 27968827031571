import React from "react";
import EquipmentTable from "./equipments/EquipmentTable";
import DocsTable from "./docs/DocsTable";
import CompanyTable from "./visitorCompany/CompanyTable";
import VisitorsTable from "./visitors/VisitorsTable";
import VideosTable from "./videos/VideosTable";
import VisitTypesTable from "./visitTypes/VisitTypesTable";
import VisitsTable from "./visits/VisitsTable";
import VisitDetailPage from "./visitDetail/VisitDetailPage";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import SessionHelper from "../../helpers/SessionHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import { useLocation } from "react-router-dom";
import CurrentVisitorsTable from "./visitorsAtFacility/CurrentVisitorsTable";

export default function VisitorManagementPage() {
  const roles = SessionHelper.getUser().roles;
  const isAppUser = SessionHelper.getUser().appUser;
  const location = useLocation();
  const page = location.pathname.substring(9);
  const equipmentsAuth = getAuthorizationForPage(roles, "visitorEquipments");
  const docsAuth = getAuthorizationForPage(roles, "visitorDocs");
  const companyAuth = getAuthorizationForPage(roles, "visitorCompany");
  const visitorsAuth = getAuthorizationForPage(roles, "visitors");
  const visitorVideosAuth = getAuthorizationForPage(roles, "visitorVideos");
  const visitorTypesAuth = getAuthorizationForPage(roles, "visitTypes");
  const visitsAuth = getAuthorizationForPage(roles, "visits");
  const currentVisitorsAuth = getAuthorizationForPage(roles, "currentVisitors");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {(page === "equipments" &&
          (equipmentsAuth?.view ? <EquipmentTable /> : <UnauthorizedPage />)) ||
          (page === "docs" &&
            (docsAuth?.view ? <DocsTable /> : <UnauthorizedPage />)) ||
          (page === "company" &&
            (companyAuth?.view && isAppUser ? (
              <CompanyTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "" &&
            (visitorsAuth?.view && isAppUser ? (
              <VisitorsTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "videos" &&
            (visitorVideosAuth?.view ? (
              <VideosTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "types" &&
            (visitorTypesAuth?.view ? (
              <VisitTypesTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "visits" &&
            (visitsAuth?.view && isAppUser ? (
              <VisitsTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "current" &&
            (currentVisitorsAuth?.view && isAppUser ? (
              <CurrentVisitorsTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page.substring(0, 4) === "page" &&
            (visitsAuth?.view ? <VisitDetailPage /> : <UnauthorizedPage />))}
      </Grid>
    </Container>
  );
}
