import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl, Grid, Input, InputLabel, Select, TextField } from "@material-ui/core";
import { getCompaniesAndDepartments, getUserRoles } from './GetOptions';
import jsonMap, { jsonMapForTagItem } from "../../../../components/Utils/jsonMap";
import { Autocomplete } from "@material-ui/lab";
import { TextSelector } from "../../../../components/Fields/TextFields";
import MultipleDateSelection from "./MultipleDateSelection";
import { CustomDatePicker } from '../../../../components/Selectors/DatePickers';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: 'pre-wrap'
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  buttonContainer: {
    textAlign: "center"
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function NewTaskModal({
  oldTaskProps,
  options,
  setOptions,
  newTaskProps,
  setNewTaskProps,
  updateTask,
  submitNewTask,
  loading
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [multi, setMulti] = React.useState(false);
  const [inspectionTypeError, setInspectionTypeError] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [companies, setCompanies] = React.useState({});
  const [departments, setDepartments] = React.useState([]);
  const [fields, setFields] = React.useState({});
  const [isYGKMember, setIsYGKMember] = React.useState(false);

  const init = useCallback(async () => {
    const compsAndDepts = options.compsAndDepts;
    let companies = {};
    for (let comp in compsAndDepts) {
      companies[comp] = comp;
    }
    setCompanies(companies);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, newTaskProps.userID]);
  React.useEffect(() => {
    init();
  }, [init]);

  const setDepartmentOptions = (company) => {
    const compsAndDepts = options.compsAndDepts;

    let tempArray = [];
    for (let comp in compsAndDepts) {
      if (comp === company) {
        const depts = compsAndDepts[comp];
        for (let i = 0; i < depts.length; i++) {
          let temp = {};
          if (depts[i].id) {
            const fields = {};
            temp["name"] = depts[i].name;
            temp["id"] = depts[i].id;
            for (let j = 0; j < depts[i].fieldOfActivities.length; j++) {
              fields[depts[i].fieldOfActivities[j].id] =
                depts[i].fieldOfActivities[j].name;
            }
            temp["fields"] = fields;
            tempArray.push(temp);
          }
        }
      }
    }
    setDepartments(tempArray);
  };

  const getUserFromId = (id) => {
    for (let i = 0; i < options.users.length; i++) {
      if (options.users[i].id === id) {
        return options.users[i];
      }
    }
    return null;
  };

  const getDepartmentFromId = (id) => {
    for (let i = 0; i < departments.length; i++) {
      if (departments[i].id === id) {
        return departments[i];
      }
    }
    return null;
  };

  const getSupervisorFromId = (id) => {
    for (let i = 0; i < options.supervisors.length; i++) {
      if (options.supervisors[i].id === id) {
        return options.supervisors[i];
      }
    }
    return null;
  };

  const getIsYGKMember = () => {
    for (let i = 0; i < options.userRole.length; i++) {
      if (options.userRole[i].name === "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ") {
        return true;
      }
    }
    return false;
  }

  const mapSelections = (selected) => {
    let temp = [...selected];
    for (let index in temp) {
      temp[index] = options.inspectionTypes[temp[index]];
    }
    return temp;
  };

  const clearProps = () => {
    setNewTaskProps({
      userID: null,
      departmentID: null,
      startTime: moment().toISOString(true),
      startTimes: [],
      deadline: moment().set("hour", 23).set("minute", 59).toISOString(true),
      deadlines: [],
      eInspectionTypes: [],
      eStatus: null,
      cityTownId: null,
    });
    setIsYGKMember(false);
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        style={{ marginBottom: 15 }}
      >
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            variant="outlined"
            id="task_switch_to_single_button"
            color="secondary"
            disabled={!multi}
            onClick={() => {
              setMulti(false);
              clearProps();
            }}
          >
            TEK DENETİM GÖREVİ YARAT
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.buttonContainer}>
          <Button
            variant="outlined"
            id="task_switch_to_multi_button"
            color="secondary"
            disabled={multi}
            onClick={() => {
              setMulti(true);
              clearProps();
            }}
          >
            ÇOKLU DENETİM GÖREVİ YARAT
          </Button>
        </Grid>
      </Grid>
      <Autocomplete //PERSONNEL
        id="task_personnel_dropdown_autocomplete"
        options={options.users?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={newTaskProps.userID ? getUserFromId(newTaskProps.userID) : null}
        onChange={async (event, value) => {
          let newOpts = options;
          [newOpts.compsAndDepts, newOpts.userRole] = await Promise.all([
            getCompaniesAndDepartments(value?.id), getUserRoles(value?.id)
          ]);
          setIsYGKMember(getIsYGKMember(newOpts.userRole));
          setOptions(newOpts);
          setNewTaskProps({
            ...newTaskProps,
            userID: value?.id,
            departmentID: null,
          });
          setCompany(null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="task_personnel_dropdown_field"
            required
            label="Personel"
            className={classes.textField}
          />
        )}
      />
      {isYGKMember && (
        <Autocomplete //SUPERVISOR
          id="task_supervisor_dropdown_autocomplete"
          options={options.supervisors?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={newTaskProps.supervisorId ? getSupervisorFromId(newTaskProps.supervisorId) : null}
          onChange={async (event, value) => {
            setNewTaskProps({
              ...newTaskProps,
              supervisorId: value?.id,
            });
            setCompany(null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              id="task_supervisor_dropdown_field"
              required
              label="Gözetmen"
              className={classes.textField}
            />
          )}
        />
      )}
      {!oldTaskProps && (
        <FormControl>
          {!multi && (
            <InputLabel id="mutiple-checkbox-label">
              {"Denetleme Tipi"}
            </InputLabel>
          )}
          {multi && (
            <TextSelector
              label="Denetleme Tipi"
              id="task_single_inspection_type_dropdown"
              value={newTaskProps.eInspectionTypes[0]}
              shrink={newTaskProps.eInspectionTypes[0]}
              onChangeFunc={(value) => {
                let temp = [value.target.value];
                setNewTaskProps({ ...newTaskProps, eInspectionTypes: temp });
              }}
              style={classes.textField}
              options={jsonMap("inspectionTypes", options)}
            />
          )}
          {!multi && (
            <Select
              labelId="mutiple-checkbox-label"
              id="task_multi_inspection_type_dropdown"
              required
              multiple
              fullWidth={true}
              error={inspectionTypeError}
              helperText={inspectionTypeError}
              value={newTaskProps.eInspectionTypes}
              onChange={(value) => {
                setInspectionTypeError(null);
                setNewTaskProps({
                  ...newTaskProps,
                  eInspectionTypes: value.target.value,
                });
              }}
              input={<Input />}
              renderValue={(selected) => mapSelections(selected).join(", ")}
              MenuProps={MenuProps}
              className={classes.textField}
            >
              {jsonMapForTagItem(
                "inspectionTypes",
                options,
                newTaskProps.eInspectionTypes
              )}
            </Select>
          )}
        </FormControl>
      )}
      {multi && (
        <MultipleDateSelection
          newTaskProps={newTaskProps}
          setNewTaskProps={setNewTaskProps}
        />
      )}
      {!multi && (
        <>
          <CustomDatePicker //START DATE
            style={classes.textField}
            label="Başlangıç Tarihi"
            id="task_start_date_picker"
            value={moment(newTaskProps.startTime)}
            shrink={newTaskProps.startTime}
            onChangeFunc={(value) =>
              setNewTaskProps({
                ...newTaskProps,
                startTime: moment(value)
                  .set("hour", 0)
                  .set("minute", 0)
                  .toISOString(true),
              })
            }
          />
          <CustomDatePicker //TERMINATION DATE
            style={classes.textField}
            label="Termin Tarihi"
            id="task_termination_date_picker"
            value={moment(newTaskProps.deadline)}
            shrink={newTaskProps.deadline}
            onChangeFunc={(value) =>
              setNewTaskProps({
                ...newTaskProps,
                deadline: moment(value)
                  .set("hour", 23)
                  .set("minute", 59)
                  .toISOString(true),
              })
            }
          />
        </>
      )}
      <TextSelector //COMPANY
        required
        id="task_company_dropdown"
        label={"Şirket"}
        disabled={!newTaskProps.userID}
        value={company}
        onChangeFunc={(value) => {
          setCompany(value.target.value);
          setDepartmentOptions(value.target.value);
        }}
        options={jsonMap(false, companies)}
        style={classes.textField}
      />
      <Autocomplete //DEPARTMENT
        id="task_department_dropdown_autocomplete"
        options={departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        disabled={!company}
        fullWidth={true}
        value={
          newTaskProps.departmentID
            ? getDepartmentFromId(newTaskProps.departmentID)
            : null
        }
        onChange={async (event, value) => {
          setNewTaskProps({ ...newTaskProps, departmentID: value?.id });
          setFields(value?.fields);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            id="task_department_dropdown_field"
            label="Bölüm"
            className={classes.textField}
          />
        )}
      />
      <TextSelector //FIELD OF ACTIVITY
        required
        id="task_town_dropdown"
        label={"İlçe"}
        disabled={!newTaskProps.departmentID}
        value={newTaskProps.cityTownId}
        onChangeFunc={(value) =>
          setNewTaskProps({ ...newTaskProps, cityTownId: value.target.value })
        }
        options={jsonMap(false, fields)}
        style={classes.textField}
      />
      {newTaskProps.eStatus && (
        <TextSelector //STATUS
          required
          id="task_status_dropdown"
          label={"Durumu"}
          value={newTaskProps.eStatus}
          onChangeFunc={(value) =>
            setNewTaskProps({ ...newTaskProps, eStatus: value.target.value })
          }
          options={jsonMap("taskStatuses", options)}
          style={classes.textField}
        />
      )}
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          id="task_submit_button"
          variant="contained"
          color="primary"
          onClick={() => {
            if (newTaskProps.eInspectionTypes?.length < 1) {
              setInspectionTypeError("En az 1 denetleme tipi seçmelisiniz.");
            } else {
              oldTaskProps ? updateTask() : submitNewTask();
            }
          }}
          className={classes.submit}
        >
          {language.form.submit}
        </Button>}
      </div>
    </FormControl>
  );
}
