import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetOptions from "./GetOptions";
import KKDModal from './KKDModal';

export default function KKDTable({task}) {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedKKD, setSelectedKKD] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [data, setData] = React.useState([])
  const [update, setUpdate] = React.useState(false);

  const getKKDKindsOfTask = useCallback(async () => {
    const resp = await Request("get", "/api/kkd-kinds/by-task/" + task.taskId, null);
    console.log(resp)
    if(resp.status !== 200 && resp.status !== 404) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } 
    return resp.data ? resp.data : [];
  }, [language, task])

  const init = useCallback(async () => {
    setBackdropLoading(true);
    if(task && task.taskId) {
      const data = await getKKDKindsOfTask();
      let ids = [];
      for(let i = 0; i < data.length; i++) {
        ids.push(data[i]?.id);
      }
      task.kkdKindIds = ids;
      setData(data);
    }
    const options = await GetOptions();
    setOptions(options);
    let cols = [
      { title: "No", field: "id" },
      { title: "KKD Tipi", field: task ? "name" : "kkdKind" },
    ];
    !task && cols.push({ title: "KKD Adı", field: "kkdName" });
    !task && cols.push({ title: "Markası", field: "brand" });
    !task && cols.push({ title: "Modeli", field: "model" });
    !task && cols.push({ title: "Cinsi", field: "type", lookup: options.types });
    !task && cols.push({ title: "Özelliği", field: "characteristic" });
    !task && cols.push({ title: "Raf Ömrü (Ay)", field: "shelfLife" });
    !task && cols.push({ title: "Kullanım Ömrü (Ay)", field: "lifetime" });
    !task && cols.push({ title: "Şirket", field: "companyName" })
    setColumns(cols);
    setBackdropLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getKKDKindsOfTask, task, update]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedKKD(data);
    setModal(true);
  }

  const removeKKD = async (data) => {
    const resp = await Request("delete", '/api/kkds/' + data.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
  }

  const removeKKDKindFromTask = async (data) => {
    let props = {...task};
    let temp = props.kkdKindIds;
    const index = temp.indexOf(data.id);
    if (index > -1) {
      temp.splice(index, 1);
    }
    props.kkdKindIds = temp;
    const resp = await Request("patch", '/api/kkd-tasks', props);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, null, reload);
  }

  const reload = () => {setUpdate(!update)}

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <KKDModal
        modal={modal} 
        setModal={setModal} 
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
        tableRef={tableRef} 
        opts={options}
        kkdProps={selectedKKD}
        task={task}
        reload={reload}
      />
      <Table
        tableName={(task && task.taskName) ? "KKD: " + task.taskName : "Kişisel Koruyucu Donanımlar"}
        authName="kkd"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        isEditHidden={true}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={!task?.taskId && FetchData}
        data={task && task.taskId && data}
        deleteEntry={task && task.taskId ? removeKKDKindFromTask : removeKKD}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        reloadFunction={reload}
        //rowStyle={rowData => ({backgroundColor: !rowData.active && "#804f4f"})}
      />
    </>
  );
}