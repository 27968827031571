import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PieChart from "../../../components/Chart/PieChart";
import BarChart from "../../../components/Chart/BarChart";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import { Bar, LabelList } from "recharts";
import { monthsForNumber } from "../../../assets/constants";
import {
  getPersonnelCountByPeriodicExaminations,
  getPersonnelCountByIllnessReports,
  getPersonnelCountByWorkEntryExaminations,
} from "./GetOptions";
import { Footer } from "./Settings";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red"];
const fixedHeight = 650;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  section: {
    height: window.innerHeight > 900 ? "75vh" : "70vh",
  },
}));

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, offset } = props;

  const xOffset = width / 2 + offset - 1;
  const yOffset = height / 2 + 5;

  return (
    <text x={x + xOffset} y={y + yOffset} fill={"black"} textAnchor="end">
      {value === 0 ? "" : value}
    </text>
  );
};

export function IllnessReportBarChart({ title, data, Footer, filterProps }) {
  return (
    <BarChart
      dataSet={data}
      title={title}
      FooterComponent={() => Footer("bar", filterProps)}
    >
      <Bar
        isAnimationActive={false}
        dataKey="Hastalık Raporu Alan Personel Sayısı"
        fill={COLORS[0]}
      >
        <LabelList
          dataKey="Hastalık Raporu Alan Personel Sayısı"
          position="center"
          content={renderCustomizedLabel}
          style={{ fill: "white" }}
        />
      </Bar>
    </BarChart>
  );
}

export default function HealthCharts({ setBackdropLoading, filterProps }) {
  const user = SessionHelper.getUser();
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "dashboard");
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [
    personnelCountByPeriodicExaminations,
    setPersonnelCountByPeriodicExaminations,
  ] = React.useState(null);
  const [personnelCountByIllnessReports, setPersonnelCountByIllnessReports] =
    React.useState(null);
  const [
    personnelCountByWorkEntryExaminations,
    setPersonnelCountByWorkEntryExaminations,
  ] = React.useState(null);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const [
      personnelCountByPeriodicExaminationsData,
      personnelCountByIllnessReportsData,
      personnelCountByWorkEntryExaminationsData,
    ] = await Promise.all([
      getPersonnelCountByPeriodicExaminations(filterProps),
      getPersonnelCountByIllnessReports(filterProps),
      getPersonnelCountByWorkEntryExaminations(filterProps),
    ]);
    setPersonnelCountByWorkEntryExaminations(
      formatDataForPieCharts(personnelCountByWorkEntryExaminationsData)
    );
    setPersonnelCountByPeriodicExaminations(
      formatDataForPieCharts(personnelCountByPeriodicExaminationsData)
    );
    setPersonnelCountByIllnessReports(
      formatDataForBarCharts(
        personnelCountByIllnessReportsData,
        "Hastalık Raporu Alan Personel Sayısı"
      )
    );
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    filterProps.startDate,
    filterProps.endDate,
    filterProps.companyIds,
    filterProps.regionIds,
    filterProps.townIds,
    filterProps.departmentIds,
    filterProps.departmentTypes,
  ]);
  React.useEffect(() => {
    init();
  }, [init]);

  const formatDataForBarCharts = (data, propName) => {
    let chartData = [];
    for (let p in data) {
      const row = {
        name: data[p].value,
        [propName]: data[p].count ?? 0,
      };
      chartData.push(row);
    }
    return chartData;
  };

  const formatDataForPieCharts = (data) => {
    let chartData = [];
    let totalCount = 0;
    for (let p in data) {
      totalCount += data[p].value ?? 0;
    }
    for (let p in data) {
      const row = {
        name: data[p].count,
        value: data[p].value ?? 0,
        percent: ((data[p].value / totalCount) * 100).toFixed(2),
      };
      chartData.push(row);
    }
    return chartData;
  };

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <IllnessReportBarChart
              data={personnelCountByIllnessReports}
              title={"Hastalık Raporu Alan Personel Sayıları"}
              Footer={Footer}
              filterProps={filterProps}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={personnelCountByPeriodicExaminations}
              title={"Periyodik Muayene Durumuna Göre Personel Sayıları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={personnelCountByWorkEntryExaminations}
              title={"İşe Giriş Muayene Durumuna Göre Personel Sayıları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
