import React, { useCallback, forwardRef } from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import { taskStatuses } from "../../../assets/constants";
import GetFilterFields from "./ActionFilters";
import showSnackbar from "../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import StatusColumn from "./StatusColumn";
import ForwardingModal from "./ForwardingModal";
import { Send } from "@material-ui/icons";

/**
 * A table component that lists the unsuitability correction reports.
 * Files of these reports can be found in their respective details panel.
 */
export default function ActionTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "actionTable");

  let statuses = { ...taskStatuses };
  statuses.DONE = "Onay Bekliyor";

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState([]);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [selectedAction, setSelectedAction] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterTerminationStart: null,
    filterTerminationEnd: null,
    filterApprovedStart: null,
    filterApprovedEnd: null,
    department: null,
    company: null,
    assignedBy: null,
    assignedTo: null,
    status: null,
    personal: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id", editable: "never" },
      { title: "Ramak Kala Görev No", field: "customId", editable: "never" },
      { title: "Olay Tipi", field: "nearMissType", editable: "never" },
      { title: "Olay Tarihi", field: "incidentDate", editable: "never" },
      { title: "İlçe", field: "town", editable: "never" },
      { title: "Şirket", field: "companyName", editable: "never" },
      { title: "Atayan Kişi", field: "assignedBy", editable: "never" },
      { title: "Atanan Kişi", field: "assignedTo", editable: "never" },
      {
        title: "Alınacak Aksiyonlar",
        field: "note",
        editable: "never",
        render: (rowData) => (
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "100px",
              width: 200,
              alignItems: "center",
            }}
          >
            {rowData.note}
          </div>
        ),
      },
      {
        title: "Alınan Akisyonlar",
        field: "messages",
        editable: "never",
        render: (rowData) => (
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "100px",
              width: 200,
              alignItems: "center",
            }}
          >
            {rowData.messages}
          </div>
        ),
      },
      { title: "Termin Tarihi", field: "terminationDate", editable: "never" },
      { title: "Onaylanma Tarihi", field: "approvedDate", editable: "never" },
      {
        title: language.tableColumns.status,
        field: "status",
        lookup: taskStatuses,
        render: (rowData) => {
          return (
            <StatusColumn
              rowData={rowData}
              roles={roles}
              jsonMap={jsonMap}
              statuses={statuses}
              setSeverity={setSeverity}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbar={setSnackbar}
              tableRef={tableRef}
            />
          );
        },
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const jsonMap = (json) => {
    return (
      json &&
      Object.keys(json).map((data) => (
        <MenuItem key={data} value={data}>
          {json[data]}
        </MenuItem>
      ))
    );
  };

  const handleOpenModal = (data) => {
    setSelectedAction(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const removeAction = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", "/api/nearMiss/action/" + idToDelete);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const removeActions = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join(),
    };
    const resp = await Request("delete", "/api/nearMiss/action/", null, param);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterTerminationStart: null,
      filterTerminationEnd: null,
      filterApprovedStart: null,
      filterApprovedEnd: null,
      department: null,
      company: null,
      assignedBy: null,
      assignedTo: null,
      status: null,
    });
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={
          idsToDelete.length > 0 ? removeActions : idToDelete && removeAction
        }
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters />
      <ForwardingModal
        modal={modal}
        setModal={setModal}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        forwardProps={selectedAction}
        loading={loading}
        setLoading={setLoading}
        tableRef={tableRef}
      />
      <Table
        tableName={"Aksiyonlar"}
        authName={"actionTable"}
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ")
        }
        isDeleteHidden={() => !authorization.delete}
        headerComponents={
          <FormControlLabel
            control={
              <Checkbox
                checked={filterProps.personal}
                onChange={() => {
                  setFilterProps({
                    ...filterProps,
                    personal: !filterProps.personal,
                  });
                  tableRef.current && tableRef.current.onQueryChange();
                }}
                name="futureTasks"
              />
            }
            label="Kendi Aksiyonlarımı Göster"
          />
        }
        detailsWindow={(rowData) => window.open("/action/" + rowData.id)}
        extraAction={{
          auth: roles.includes("ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ"),
          icon: forwardRef((props, ref) => <Send {...props} ref={ref} />),
          tooltip: "Aksiyonu Yönlendir",
          position: "row",
          onClick: (rowData) => {
            handleOpenModal(rowData);
          },
        }}
      />
    </>
  );
}
