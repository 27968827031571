import Request from "../../../helpers/Request";

export const getTypes = async () => {
  const resp = await Request("get", "/api/ibys/calisma-ortami/types");
  const data = resp.data;
  return data;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [types] = await Promise.all([getTypes()]);
  const options = { types };
  return options;
}
