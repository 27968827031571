import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import GetOptions from "./GetOptions";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import PersonnelNumberModal from "./PersonnelNumberModal";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";

/**
 * A table component that lists the EPDK work accidents. Read-only.
 */
export default function PersonnelNumberTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [oldAccident, setOldAccident] = React.useState(null);

  const init = useCallback(async () => {
    const options = await GetOptions();
    setColumns([
      { title: "S/N", field: "id" },
      { title: "Yıl", field: "year" },
      { title: "EDAŞ", field: "edcName" },
      {
        title: "Elektrik Dağıtım Şirketi / Yüklenici Şirket",
        field: "companyName",
      },
      { title: "Şirket SGK Sicil No", field: "companySgkNo" },
      { title: "T-1 Yılı İçinde Çalışmış Toplam Personel Sayısı", field: "t1" },
    ]);
    setOptions(options);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModal(true);
    setOldAccident(data);
  };

  const deleteEntry = async (oldData) => {
    const resp = await Request("delete", "/api/epdk/user/" + oldData.id);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <PersonnelNumberModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldAccidentProps={oldAccident}
        opts={options}
      />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={"EPDK Çalışan Sayısı Bildirimleri"}
        authName="workAccidentTable"
        columns={columns}
        tableRef={tableRef}
        deleteEntry={deleteEntry}
        handleOpenModal={handleOpenModal}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
