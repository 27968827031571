import Request from '../../../../helpers/Request';
import moment from 'moment';
import LanguageHelper from '../../../../helpers/LanguageHelper';
import SessionHelper from '../../../../helpers/SessionHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      userId: filterProps.personal ? SessionHelper.getUser().id : undefined,
      sort: "id,desc",
      getFutureTasks: type,

      //FILTERS
      filterCreationStart: filterProps.creationStartDate,
      filterCreationEnd: filterProps.creationEndDate,
      filterCompletionStart: filterProps.completionStartDate,
      filterCompletionEnd: filterProps.completionEndDate,
      filterCompanyName: filterProps.company?.name,
      filterDepartmentId: filterProps.department?.id,
      filterRegionId: filterProps.region?.id,
      filterTownId: filterProps.town?.id,
      filterInspectionUserName: filterProps.userName,
      filterStatus: filterProps.status,
      filterRole: filterProps.role
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "fullName") { params.sort = "user.fName,user.lName," + query.orderDirection; }
      else if (query.orderBy.field === "eStatus") { params.sort = "eStatus," + query.orderDirection; }
      else if (query.orderBy.field === "inspectionType") { params.sort = "inspectionType," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "completionDate") { params.sort = "completionDate," + query.orderDirection; }
      else if (query.orderBy.field === "terminationTime") { params.sort = "terminationTime," + query.orderDirection; }
      else if (query.orderBy.field === "unsuitability") { params.sort = "unsuitability," + query.orderDirection; }
      else if (query.orderBy.field === "customId") { params.sort = "customId," + query.orderDirection; }
      else if (query.orderBy.field === "givenByName") { params.sort = "givenBy.fName,givenBy.lName," + query.orderDirection; }
      else if (query.orderBy.field === "createdAt") { params.sort = "createdAt," + query.orderDirection; }
      else if (query.orderBy.field === "cityTownName") { params.sort = "cityTown.name," + query.orderDirection; }
      else if (query.orderBy.field === "text") { params.sort = "text," + query.orderDirection; }
      else if (query.orderBy.field === "score") { params.sort = "score," + query.orderDirection; }
      else if (query.orderBy.field === "unsuitability") { params.sort = "unsuitability," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/inspections", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {    
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        let createdAt = "-";
        let comp_date = "-";
        let term_date = "-";
        let start_date = "-";

        let unsuit = "";
        if (data[i].unsuitability) { 
          if(data[i].personalUnsuitability) {unsuit = "Personelde Uygunsuzluk Var"}
          else if(data[i].departmentUnsuitability) {unsuit = "Bölümde Uygunsuzluk Var"}
          if(data[i].departmentUnsuitability && data[i].personalUnsuitability) {unsuit = "Personelde ve Bölümde Uygunsuzluk Var"}
        }
        else if(data[i].eStatus !== "DONE") { unsuit = "-" }
        else { unsuit = "Yok" }

        let deptUnsuit = "";
        if (data[i].departmentUnsuitability) { deptUnsuit = "Var" }
        else if(data[i].eStatus !== "DONE") { deptUnsuit = "-" }
        else { deptUnsuit = "Yok" }

        if (data[i].createdAt) { createdAt = (new moment(data[i].createdAt)).format('DD-MM-YYYY HH:mm:ss'); }
        if (data[i].completionDate) { comp_date = (new moment(data[i].completionDate)).format('DD-MM-YYYY HH:mm:ss'); }
        if (data[i].terminationTime) { term_date = (new moment(data[i].terminationTime)).format('DD-MM-YYYY'); }
        if (data[i].startTime) { start_date = (new moment(data[i].startTime)).format('DD-MM-YYYY'); }
        const edit = {
          createdAt: createdAt,
          startTime: start_date,
          score: (data[i].score !== undefined && data[i].score !== null) ? data[i].score : null,
          text: data[i].text,
          location: data[i].location,
          cityTownName: data[i].cityTown?.name,
          cityTownId: data[i].cityTown?.id,
          givenBy: data[i].givenBy,
          givenById: data[i].givenBy ? data[i].givenBy?.id : null,
          givenByName: data[i].givenBy ? data[i].givenBy?.fName + " " + data[i].givenBy?.lName : null,
          nonconformityUsers: data[i].nonconformityUsers,
          departmentUnsuitability: deptUnsuit,
          term: data[i].terminationTime,
          completionDate: comp_date,
          fileKeys: data[i].fileKeys,
          company: data[i].company.id,
          companyName: data[i].company.active ? data[i].company.name : data[i].company.name + " (Silinmiş)",
          department: data[i].department.id,
          departmentName: data[i].department.active ? data[i].department.name : data[i].department.name + " (Silinmiş)",
          nonConformityAssignedAuto: data[i].nonconformityAssignedAuto,
          fullName: data[i].user.fName + " " + data[i].user.lName,
          id: data[i].id,
          customId: data[i].customId,
          user: data[i].user.id,
          inspectionType: data[i].inspectionType,
          unsuitability: unsuit,
          terminationTime: term_date,
          eStatus: data[i].eStatus,
          forms: data[i].forms,
          supervisor: data[i].supervisor,
          supervisorName: data[i].supervisor ? data[i].supervisor?.fName + " " + data[i].supervisor?.lName : null,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}
