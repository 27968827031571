import { makeStyles } from "@material-ui/core/styles";
import { CircularProgress, Divider, FormControl } from "@material-ui/core";
import React, { useCallback } from "react";
import { hasItems } from "../../../../components/Utils/JsonUtils";
import Request from "../../../../helpers/Request";
import Diploma from "./components/Diploma";
import EKAT from "./components/EKAT";
import HealthReport from "./components/HealthReport";
import OperatorCertificates from "./components/OperatorCertificates";
import DriversLicense from "./components/DriversLicense";
import SGKIn from "./components/SGKIn";
import SGKOut from "./components/SGKOut";
import ISGEducationCertificate from "./components/ISGEducationCertificate";
import KKDDebt from "./components/KKDDebt";
import FirstAid from "./components/FirstAid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
}));

export default function UserFiles({
  requestMade,
  setRequestMade,
  userProps,
  files,
  setFiles,
  fileProps,
  setFileProps,
  errors,
  setErrors,
  options,
  setChangeMadeToFiles,
}) {
  const classes = useStyles();

  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setLoading(true);
    if (!requestMade && !hasItems(files)) {
      const [data, props] = await getFilesOfUser();
      setFiles(data);
      setFileProps(props);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProps, files, fileProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFilesOfUser = async () => {
    const resp = await Request("get", "/api/users/" + userProps.id + "/files");
    console.log(resp);
    let files = {};
    let props = {};
    if (resp.data) {
      for (let key of Object.keys(resp.data)) {
        if (key !== "user" && key !== "userFileAttributes") {
          files[key] = [];
          for (let index in resp.data[key]) {
            const data = resp.data[key][index];
            const fileKey = data.fileKey
              ? data.fileKey.replace(
                  "https://isgapi.gdzelektrik.com.tr:8443/open-file/",
                  ""
                )
              : data.file
              ? data.file.fileKey.replace(
                  "https://isgapi.gdzelektrik.com.tr:8443/open-file/",
                  ""
                )
              : "undefined";
            files[key].push(
              new File([fileKey], fileKey, { type: "text/plain" })
            );
          }
        }
        if (key === "userFileAttributes" && resp.data[key]) {
          const attributes = resp.data[key];
          props.userId = attributes.userId;
          props.ekatRequestList = attributes.ekatResponses;
          props.firstAidCertificateRequestList = attributes.firstAidCertificateResponses;
          props.healthReportRequestList = attributes.healthReportResponses;
          props.driversLicenceRequestsList =
            attributes.driversLicenceResponseList;
          props.operatorCertificateRequestList =
            attributes.operatorCertificateResponseList;
          props.isgGeneralEducationCertificateRequestList =
            attributes.isgGeneralEducationCertificateResponses;
        }
      }
    }
    setRequestMade(true);
    return [files, props];
  };

  const RenderSGKIn = useCallback(() => {
    return (
      <SGKIn
        files={files}
        setFiles={setFiles}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const RenderSGKOut = useCallback(() => {
    return (
      <SGKOut
        files={files}
        setFiles={setFiles}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const RenderKKDDebt = useCallback(() => {
    return (
      <KKDDebt
        files={files}
        setFiles={setFiles}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const RenderDiploma = useCallback(() => {
    return (
      <Diploma
        files={files}
        setFiles={setFiles}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const RenderEKAT = useCallback(() => {
    return (
      <EKAT
        files={files}
        setFiles={setFiles}
        fileProps={fileProps}
        setFileProps={setFileProps}
        errors={errors}
        setErrors={setErrors}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.ekatRequestList, errors]);

  const RenderFirstAid = useCallback(() => {
    return (
      <FirstAid
        files={files}
        setFiles={setFiles}
        fileProps={fileProps}
        setFileProps={setFileProps}
        errors={errors}
        setErrors={setErrors}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.firstAidCertificateRequestList, errors]);

  const RenderISGEducationCertificate = useCallback(() => {
    return (
      <ISGEducationCertificate
        files={files}
        setFiles={setFiles}
        fileProps={fileProps}
        setFileProps={setFileProps}
        errors={errors}
        setErrors={setErrors}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.isgGeneralEducationCertificateRequestList, errors]);

  const RenderHealthReport = useCallback(() => {
    return (
      <HealthReport
        files={files}
        setFiles={setFiles}
        fileProps={fileProps}
        setFileProps={setFileProps}
        errors={errors}
        setErrors={setErrors}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.healthReportRequestList, errors]);

  const RenderOperatorCertificates = useCallback(() => {
    return (
      <OperatorCertificates
        files={files}
        setFiles={setFiles}
        fileProps={fileProps}
        setFileProps={setFileProps}
        errors={errors}
        setErrors={setErrors}
        options={options}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.operatorCertificateRequestList, errors]);

  const RenderDriversLicense = useCallback(() => {
    return (
      <DriversLicense
        files={files}
        setFiles={setFiles}
        fileProps={fileProps}
        setFileProps={setFileProps}
        errors={errors}
        setErrors={setErrors}
        options={options}
        setChangeMadeToFiles={setChangeMadeToFiles}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.driversLicenceRequestsList, errors]);

  return (
    <>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <FormControl
          required
          autoComplete="off"
          className={classes.formControl}
        >
          <RenderSGKIn />
          <Divider className={classes.divider} />
          <RenderSGKOut />
          <Divider className={classes.divider} />
          <RenderDiploma />
          <Divider className={classes.divider} />
          <RenderKKDDebt />
          <Divider className={classes.divider} />
          <RenderEKAT />
          <Divider className={classes.divider} />
          <RenderFirstAid />
          <Divider className={classes.divider} />
          <RenderISGEducationCertificate />
          <Divider className={classes.divider} />
          <RenderHealthReport />
          <Divider className={classes.divider} />
          <RenderOperatorCertificates />
          <Divider className={classes.divider} />
          <RenderDriversLicense />
        </FormControl>
      )}
    </>
  );
}
