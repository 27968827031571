import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function ModuleModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  moduleProps,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestModuleProps, setRequestModuleProps] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setRequestModuleProps({
      name: moduleProps ? moduleProps.name : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestModuleProps({
      name: null,
    });
  };

  const submitNewModule = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/authority/module",
      requestModuleProps
    );

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateModule = async () => {
    setLoading(true);
    let props = { ...requestModuleProps };
    props.id = moduleProps.id;
    const resp = await Request("put", "/api/authority/module", props);

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestModuleProps={requestModuleProps}
              moduleProps={moduleProps}
              setRequestModuleProps={setRequestModuleProps}
              submitNewModule={submitNewModule}
              updateModule={updateModule}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
