import React, { forwardRef, useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import DecisionModal from "./DecisionModal";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import ActionModal from "../actions/ActionModal";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import AddBox from "@material-ui/icons/AddBox";

export default function Decisions({
  rowData,
  options,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const roles = SessionHelper.getUser()?.roles;
  const authorization = getAuthorizationForPage(roles, "boards");
  const user = SessionHelper.getUser();
  const id = user?.company?.id;
  const isAdmin = user?.roles.includes("ROLE_SİSTEM_ADMİNİ");

  const [createActionModal, setCreateActionModal] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [selectedDecision, setSelectedDecision] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [update, setUpdate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(false);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    let cols = [
      { title: "No", field: "id", width: "60px" },
      { title: "Karar No", field: "customNo" },
      { title: "Karar", field: "text" },
    ];
    setColumns(cols);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, update]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedDecision(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const removeDecision = async () => {
    setLoading(true);
    const resp = await Request(
      "delete",
      "/api/isgCommittee/decision/" + idToDelete
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      null,
      reload
    );
    handleCloseDeleteDialog();
    setLoading(false);
  };

  const actionModal = async (data) => {
    setModalLoading(true);
    setCreateActionModal(true);
    setSelectedDecision(data);
    setCreateActionModal(true);
    setModalLoading(false);
  };

  const reload = (data) => {
    data && (rowData.decisions = data);
    setUpdate(!update);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <DeleteDialog
        remove={removeDecision}
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
        customTitle={
          "Karara bağlı bütün aksiyonlar da silinecek, devam etmek istediğine emin misin?"
        }
      />
      <ActionModal
        modalLoading={modalLoading}
        modal={createActionModal}
        setModal={setCreateActionModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        options={options}
        decisionProps={selectedDecision}
      />
      <DecisionModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        kkdProps={selectedDecision}
        rowData={rowData}
        reload={reload}
      />
      <Table
        tableName={"Kararlar"}
        authName="boards"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        isEditHidden={true}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        data={rowData?.decisions}
        isDeletable={(rowData) => isAdmin || rowData?.companyId === id}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        reloadFunction={reload}
        extraAction={{
          auth: authorization.create || isAdmin || rowData?.companyId === id,
          icon: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          tooltip: "Aksiyon Yarat",
          position: "row",
          onClick: (rowData) => actionModal(rowData),
        }}
      />
    </>
  );
}
