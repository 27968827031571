import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      isActive:
        filterProps.active === "false"
          ? true
          : filterProps.active === "true"
          ? false
          : null,
      companyFilter: filterProps.company?.id,
      departmentFilter: filterProps.department?.id,
      didGetISGTrainingFilter: filterProps.isg,
      educationStatusFilter: filterProps.edu,
      genderFilter: filterProps.gender,
      maritalStatusFilter: filterProps.marital,
      enabledFilter: filterProps.enabled,
      managerFilter: filterProps.manager,
      roleFilter: filterProps.role,
      isAppUser: filterProps.isAppUser,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "firstName") {
        params.sort = "fName," + query.orderDirection;
      } else if (query.orderBy.field === "lastName") {
        params.sort = "lName," + query.orderDirection;
      } else if (query.orderBy.field === "departmentName") {
        params.sort = "department.name," + query.orderDirection;
      } else if (query.orderBy.field === "company") {
        params.sort = "company.name," + query.orderDirection;
      } else if (query.orderBy.field === "title") {
        params.sort = "title," + query.orderDirection;
      } else if (query.orderBy.field === "email") {
        params.sort = "email," + query.orderDirection;
      } else if (query.orderBy.field === "enabled") {
        params.sort = "enabled," + query.orderDirection;
      } else if (query.orderBy.field === "managerName") {
        params.sort = "manager.fName,manager.lName," + query.orderDirection;
      } else if (query.orderBy.field === "userSGKNo") {
        params.sort = "userSGKNo," + query.orderDirection;
      } else if (query.orderBy.field === "tcNo") {
        params.sort = "tcNo," + query.orderDirection;
      } else if (query.orderBy.field === "birthDate") {
        params.sort = "birthDate," + query.orderDirection;
      } else if (query.orderBy.field === "phoneNo") {
        params.sort = "phoneNo," + query.orderDirection;
      } else if (query.orderBy.field === "dailyHourOfWork") {
        params.sort = "dailyHourofWork," + query.orderDirection;
      } else if (query.orderBy.field === "maritalStatus") {
        params.sort = "maritalStatus.nameTr," + query.orderDirection;
      } else if (query.orderBy.field === "gender") {
        params.sort = "gender.nameTr," + query.orderDirection;
      } else if (query.orderBy.field === "startDateOfWork") {
        params.sort = "startDateOfWork," + query.orderDirection;
      } else if (query.orderBy.field === "didGetISGTraining") {
        params.sort = "didGetISGTraining," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/users", null, params);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          tasks: data[i]?.haveAnyTasks,
          deletedAt: data[i].deletedAt
            ? moment(data[i].deletedAt).format("DD-MM-YYYY")
            : null,
          responsibilityRegions: data[i].responsibilityRegions
            ? data[i].responsibilityRegions
            : [],
          isActive: data[i].active ? data[i].active : null,
          isAppUser: data[i].isAppUser ? data[i].isAppUser : null,
          id: data[i].id ? data[i].id : null,
          firstName: data[i].fName ? data[i].fName : null,
          lastName: data[i].lName ? data[i].lName : null,
          department: data[i].department ? data[i].department?.id : null,
          departmentName: data[i].department ? data[i].department?.name : null,
          company: data[i].company ? data[i].company?.id : null,
          companyType: data[i].company ? data[i].company?.eCompanyType : null,
          companyName: data[i].company ? data[i].company?.name : null,
          companyCityId: data[i].company ? data[i].company?.city?.id : null,
          title: data[i].title ? data[i].title : null,
          email: data[i].email ? data[i].email : null,
          enabled: data[i].enabled ? data[i].enabled : null,
          roles: data[i].roles ? formatRoles(data[i].roles) : null,
          manager: data[i].manager ? data[i].manager?.id : null,
          managerName: data[i].manager
            ? data[i].manager?.fName + " " + data[i].manager?.lName
            : null,
          userSGKNo: data[i].userSGKNo ? data[i].userSGKNo : null,
          unsuitTowns: formatUnsuitTowns(data[i].nonconformityTowns),

          didGetISGTraining: data[i].didGetISGTraining
            ? data[i].didGetISGTraining
            : null,
          tcNo: data[i].tcNo ? data[i].tcNo : null,
          birthDate: data[i].birthDate
            ? new moment(data[i].birthDate).format("DD-MM-YYYY")
            : null,
          phoneNo: data[i].phoneNo ? data[i].phoneNo : null,
          dailyHourOfWork: data[i].dailyHourofWork
            ? data[i].dailyHourofWork
            : null,
          educationStatus: data[i].educationStatus
            ? data[i].educationStatus?.nameTr
            : null,
          maritalStatus: data[i].maritalStatus
            ? data[i].maritalStatus?.nameTr
            : null,
          gender: data[i].gender ? data[i].gender?.nameTr : null,
          startDateOfWork: data[i].startDateOfWork
            ? new moment(data[i].startDateOfWork).format("DD-MM-YYYY")
            : null,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}

const formatRoles = (roles) => {
  let newArray = [];
  for (let i = 0; i < roles.length; i++) {
    newArray.push(roles[i].name);
  }
  return newArray;
};

const formatUnsuitTowns = (unsuitTowns) => {
  let formattedArray = [];
  for (let index in unsuitTowns) {
    formattedArray.push(
      unsuitTowns[index]?.town?.name +
        " (" +
        unsuitTowns[index]?.department?.name +
        ")"
    );
  }
  return formattedArray.length === 0 ? null : formattedArray;
};
