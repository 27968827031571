import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Grid, List, ListItem, ListItemText, TextField } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import '../../../index.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";
import { ceil } from "lodash";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  fields: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  textFieldFields: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%"
  },
  required: {
    marginTop: -5,
    color: "#b24d50",
    fontSize: 13
  },
}));

export default function EmergencyTeams({newDepartmentProps, setNewDepartmentProps, options, setChangeMade}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");
  let ffRequiredAmount = null;
  let srRequiredAmount = null;
  let faRequiredAmount = null;
  if(newDepartmentProps.riskClass === "AZ_TEHLİKELİ") {
    ffRequiredAmount = ceil(newDepartmentProps.employeeCount / 50);
    srRequiredAmount = ceil(newDepartmentProps.employeeCount / 50);
    faRequiredAmount = ceil(newDepartmentProps.employeeCount / 20);
  } else if(newDepartmentProps.riskClass === "TEHLİKELİ") {
    ffRequiredAmount = ceil(newDepartmentProps.employeeCount / 40);
    srRequiredAmount = ceil(newDepartmentProps.employeeCount / 40);
    faRequiredAmount = ceil(newDepartmentProps.employeeCount / 15);
  } else if(newDepartmentProps.riskClass === "ÇOK_TEHLİKELİ") {
    ffRequiredAmount = ceil(newDepartmentProps.employeeCount / 30);
    srRequiredAmount = ceil(newDepartmentProps.employeeCount / 30);
    faRequiredAmount = ceil(newDepartmentProps.employeeCount / 10);
  }

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const getFromId = (options, id) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  const RenderUsersBox = ({teamType, emptyLabel}) => {
    const users = newDepartmentProps.emergencyTeams[teamType];
    return (
      <Grid item xs={12}>
        {users && users.length > 0 
        ?
        <List className={classes.list}>
          {users.map((data, i) => (
            <ListItem key={i}>
              <Grid container>
                <Grid item xs={6}>
                  <div style={{display: "flex", flexDirection: "row"}}>
                    <IconTooltipButton title={language.form.deleteQuestion} onClick={() => {
                      users.splice(i, 1);
                      let temp = {...newDepartmentProps.emergencyTeams};
                      temp[teamType] = users;
                      setNewDepartmentProps({...newDepartmentProps, emergencyTeams: temp});
                      setChangeMade && setChangeMade(true);
                    }}>
                      <DeleteIcon />
                    </IconTooltipButton>
                    <ListItemText style={{marginTop: 15}} secondary={data.name ? data.name : (options.deptUsers ? getFromId(options.deptUsers, data)?.name : null)}/>
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        :
        <div className={classes.list} style={{textAlign: "center"}}>
          <p style={{color: "red", fontSize: 14, marginLeft: "auto"}}>{emptyLabel}</p>
        </div>}
      </Grid>
    )
  }

  const RenderAutocomplete = ({teamType, label}) => {
    return(
      <div style={{textAlign: "center"}}>
        <Autocomplete
          id="combo-box"
          options={options.deptUsers?.sort(function(a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if(reason === "reset") {
              setAutocompleteValue("");
            } else if(reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value, reason) => {
            if(value?.id && value !== "") {
              const users = newDepartmentProps.emergencyTeams[teamType];
              if(!users.includes(parseInt(value?.id))) {
                users.push(parseInt(value?.id));
                const temp = {...newDepartmentProps.emergencyTeams};
                temp[teamType] = users;
                setNewDepartmentProps({...newDepartmentProps, emergencyTeams: temp});
                setChangeMade && setChangeMade(true);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} required label={label} className={classes.textFieldFields}/>}
        />
      </div>
    )
  }

  const RenderCrisisManagement = () => {
    return (
      <>
        <Grid item xs={6}><p className={classes.fields}>Kriz Yönetimi Ekibi</p></Grid>
        <RenderUsersBox teamType="crisisManagement" emptyLabel="Kriz Yönetimi Personeli yok."/>
        <RenderAutocomplete teamType="crisisManagement" label="Kriz Yönetimi Personeli Ekle"/>
      </>
    )
  }

  const RenderFireFighting = () => {
    return (
      <>
        <Grid item xs={6}><p className={classes.fields}>Yangınla Mücadele Ekibi</p></Grid>
        {ffRequiredAmount && <Grid item xs={12}><p className={classes.required}>Bu bölümde en az {ffRequiredAmount} yangınla mücadele personelinin bulunması zorunludur.</p></Grid>}
        <RenderUsersBox teamType="fireFighting" emptyLabel="Yangınla Mücadele Personeli yok."/>
        <RenderAutocomplete teamType="fireFighting" label="Yangınla Mücadele Personeli Ekle"/>
      </>
    )
  }

  const RenderSearchAndRescue = () => {
    return (
      <>
        <Grid item xs={6}><p className={classes.fields}>Arama Kurtarma Ekibi</p></Grid>
        {srRequiredAmount && <Grid item xs={12}><p className={classes.required}>Bu bölümde en az {srRequiredAmount} arama kurtarma personelinin bulunması zorunludur.</p></Grid>}
        <RenderUsersBox teamType="searchAndRescue" emptyLabel="Arama Kurtarma Personeli yok."/>
        <RenderAutocomplete teamType="searchAndRescue" label="Arama Kurtarma Personeli Ekle"/>
      </>
    )
  }

  const RenderFirstAid = () => {
    return (
      <>
        <Grid item xs={6}><p className={classes.fields}>İlkyardım Ekibi</p></Grid>
        {faRequiredAmount && <Grid item xs={12}><p className={classes.required}>Bu bölümde en az {faRequiredAmount} ilkyardım personelinin bulunması zorunludur.</p></Grid>}
        <RenderUsersBox teamType="firstAid" emptyLabel="İlkyardım Personeli yok."/>
        <RenderAutocomplete teamType="firstAid" label="İlkyardım Personeli Ekle"/>
      </>
    )
  }

  const RenderProtection = () => {
    return (
      <>
        <Grid item xs={6}><p className={classes.fields}>Koruma Ekibi</p></Grid>
        <RenderUsersBox teamType="protection" emptyLabel="Koruma Personeli yok."/>
        <RenderAutocomplete teamType="protection" label="Koruma Personeli Ekle"/>
      </>
    )
  }

  const RenderTechnical = () => {
    return (
      <>
        <Grid item xs={6}><p className={classes.fields}>Teknik Ekip</p></Grid>
        <RenderUsersBox teamType="technical" emptyLabel="Teknik Personel yok."/>
        <RenderAutocomplete teamType="technical" label="Teknik Personel Ekle"/>
      </>
    )
  }

  return (
    <div style={{textAlign: "left"}}>
      <RenderCrisisManagement/>
      <RenderFireFighting/>
      <RenderSearchAndRescue/>
      <RenderFirstAid/>
      <RenderProtection/>
      <RenderTechnical/>
    </div>
  );
}