import Request from "../../../helpers/Request";
import { taskStatuses } from "../../../assets/constants";
import SessionHelper from "../../../helpers/SessionHelper";

export const getUsers = async (departmentId) => {
  const resp = await Request(
    "get",
    "/api/isgCommittee/" + departmentId + "/committee",
    null
  );
  const data = resp?.data?.content;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/training/record/company-list");
  return resp.data.map((record) => ({
    name: record.name,
    id: record.id,
  }));
};

export const getDepartments = async (companyId) => {
  const { data } = await Request(
    "get",
    "/api/training/record/department-list/" + companyId
  );
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions() {
  const user = SessionHelper.getUser();
  const statuses = { ...taskStatuses };
  delete statuses.REJECTED;
  const [companies, departments, users] = await Promise.all([
    getCompanies(),
    getDepartments(user?.companyId),
    getUsers(user?.department?.id),
  ]);
  const options = {
    statuses,
    companies,
    departments,
    users,
  };
  return options;
}
