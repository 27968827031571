import React, { useCallback } from "react";
import {
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { makeStyles } from "@material-ui/core/styles";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import AuthModal from "./RoleAuthModal";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import { getFunctions } from "./GetOptions";
import GetOptions from "./GetOptions";
import GetFilterFields from "./RoleAuthFilters";

const useStyles = makeStyles((theme) => ({
  divider: {
    height: 1,
    width: 200,
    backgroundColor: "black",
    marginTop: 10,
    marginBottom: 10,
  },
  footerComponent: {
    // display: "flex",
    // flexDirection: "column",
    whiteSpace: "pre-line",
  },
  root: {
    color: "black",
  },
  label: {
    "&.Mui-disabled": {
      color: "black",
    },
  },
  note: {
    overflowY: "scroll",
    height: "100px",
    maxHeight: "100px",
    width: 200,
    display: "flex",
    justifyContent: "center",
  },
  mobileWeb: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function RoleAuthTable() {
  const classes = useStyles();
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const userRoles = user.roles;
  const authorization = getAuthorizationForPage(userRoles, "authTable");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedAuth, setSelectedAuth] = React.useState("");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    moduleIds: [],
    moduleFunctionIds: [],
    roleIds: [],
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: "Modül",
        field: "moduleName",
      },
      {
        title: "Fonksiyon",
        field: "functionName",
      },
      {
        title: "Rol",
        field: "roleName",
      },
      {
        title: "Görüntüleme Yetkisi",
        field: "readOpNote",
        render: (rowData) => {
          return (
            <>
              <div className={classes.note}>{rowData.readOpNote}</div>
              <Divider className={classes.divider} />
              <FormGroup
                className={classes.mobileWeb}
                aria-label="position"
                row
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.readOpWeb}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Web"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.readOpMobile}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Mobile"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
              </FormGroup>
            </>
          );
        },
      },
      {
        title: "Oluşturma Yetkisi",
        field: "createOpNote",
        render: (rowData) => {
          return (
            <>
              <div className={classes.note}>{rowData.createOpNote}</div>
              <Divider className={classes.divider} />
              <FormGroup
                className={classes.mobileWeb}
                aria-label="position"
                row
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.createOpWeb}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Web"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.createOpMobile}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Mobile"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
              </FormGroup>
            </>
          );
        },
      },
      {
        title: "Değiştirme Yetkisi",
        field: "updateOpNote",
        render: (rowData) => {
          return (
            <>
              <div className={classes.note}>{rowData.updateOpNote}</div>
              <Divider className={classes.divider} />
              <FormGroup
                className={classes.mobileWeb}
                aria-label="position"
                row
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.updateOpWeb}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Web"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.updateOpMobile}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Mobile"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
              </FormGroup>
            </>
          );
        },
      },
      {
        title: "Silme Yetkisi",
        field: "deleteOpNote",
        render: (rowData) => {
          return (
            <>
              <div className={classes.note}>{rowData.deleteOpNote}</div>
              <Divider className={classes.divider} />
              <FormGroup
                className={classes.mobileWeb}
                aria-label="position"
                row
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.deleteOpWeb}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Web"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      checked={rowData.deleteOpMobile}
                      icon={<CheckBoxOutlineBlankIcon color="primary" />}
                      checkedIcon={<CheckBoxIcon color="primary" />}
                    />
                  }
                  label="Mobile"
                  labelPlacement="top"
                  classes={{
                    root: classes.root,
                    label: classes.label,
                  }}
                />
              </FormGroup>
            </>
          );
        },
      },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedAuth(data);
    let opts = { ...options };
    data && data.moduleId
      ? (opts.functions = await getFunctions(data.moduleId))
      : (opts.functions = []);
    setOptions(opts);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedAuth(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedAuth(null);
    setDialog(false);
  };

  const removeAuth = async () => {
    setRequestLoading(true);
    let params = {
      "authority-id": selectedAuth?.id,
    };
    const resp = await Request(
      "delete",
      "/api/authority/authority/",
      null,
      params
    );

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          setOptions={setOptions}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      moduleIds: [],
      moduleFunctionIds: [],
      roleIds: [],
    });
  };

  if (
    options.modules &&
    options.rolesId &&
    Array.isArray(options.modules) &&
    Array.isArray(options.rolesId)
  ) {
    return (
      <>
        <TableBackdrop backdropLoading={backdropLoading} />
        <CustomSnackbar
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
          severity={severity}
        />
        <DeleteDialog
          remove={removeAuth}
          open={dialog}
          loading={requestLoading}
          close={handleCloseDeleteDialog}
        />
        <RenderFilters />
        <AuthModal
          modal={modal}
          setModal={setModal}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          setSeverity={setSeverity}
          tableRef={tableRef}
          authProps={selectedAuth}
          options={options}
          setOptions={setOptions}
        />
        <Table
          tableName={language.sidebar.auths}
          authName="authTable"
          noRowActions={!authorization.edit}
          columns={columns}
          tableRef={tableRef}
          handleOpenModal={handleOpenModal}
          handleOpenDeleteModal={handleOpenDeleteDialog}
          isEditable={() => authorization.edit}
          filters={filterProps}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          fetchData={FetchData}
          numOfEntries={numOfEntries}
          setNumOfEntries={setNumOfEntries}
        />
      </>
    );
  } else {
    return <TableBackdrop backdropLoading={backdropLoading} />;
  }
}
