import moment from "moment";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    var params = new URLSearchParams();
    for (let department of filterProps.departmentIds) {
      params.append("departmentIds", department.id);
    }
    for (let region of filterProps.regionIds) {
      params.append("regionIds", region.id);
    }
    for (let month of filterProps.months) {
      params.append("months", new moment(month).format("YYYY-MM-DD"));
    }
    params.append("isForGraph", false);

    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      }
      if (query.orderBy.field === "customId") {
        params.sort = "customId," + query.orderDirection;
      }
      if (query.orderBy.field === "notifyingUser") {
        params.sort = "user.fName,user.lName" + query.orderDirection;
      }
      if (query.orderBy.field === "incidentType") {
        params.sort = "nearMissType," + query.orderDirection;
      }
      if (query.orderBy.field === "incidentDate") {
        params.sort = "incidentDate," + query.orderDirection;
      }
      if (query.orderBy.field === "notificationDate") {
        params.sort = "notificationDate," + query.orderDirection;
      }
      if (query.orderBy.field === "company") {
        params.sort = "company.name," + query.orderDirection;
      }
      if (query.orderBy.field === "department") {
        params.sort = "department.name," + query.orderDirection;
      }
      if (query.orderBy.field === "townName") {
        params.sort = "town.name," + query.orderDirection;
      }
      if (query.orderBy.field === "description") {
        params.sort = "note," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/unsuitInspections/monthly",
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      console.log(data);
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          region: data[i].region, 
          month: data[i].month,
          nonconformities: data[i].totalConformity,
          resolvedNonconformities: data[i].totalApprovedConformity,
          year: data[i].year,
          departmentName: data[i].departmentName,
          ratioOfResolvedNonconformities:
            data[i].totalConformity !== null ||
            data[i].totalApprovedConformity !== null
              ? (data[i].totalApprovedConformity / data[i].totalConformity) * 30
              : 0,
        };
        temp.push(edit);
      }
      setNumOfEntries(temp.length);
      resolve({
        data: temp,
        totalCount: temp.length,
      });
    }
  });
  return data;
}
