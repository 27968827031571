import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import IconTooltipButton from "../../../../components/Buttons/IconTooltipButton";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({
  alignLeft: {
    display: "flex",
    alignItems: "flex-start",
    paddingLeft: "20px",
  },
  title: {
    fontSize: "22px",
  },
  subtitle: {
    fontSize: "16px",
  },
  addButton: {
    marginLeft: "100px",
    marginTop: "5px",
  },
  towns: {
    fontSize: "14px",
  },
  content: {
    backgroundColor: "#2D3446",
    color: "white",
    textAlign: "center",
    alignItems: "center",
  },
  regionsArea: {},
  townsArea: {
    margin: "0 auto",
    width: "60%",
    overflow: "auto",
    maxHeight: 400,
    border: "1px solid #474D5C",
  },
}));

export default function RegionsArea({
  setRegionModal,
  setDeleteRegionId,
  setOpenDialog,
  setDeleteTownFromRegionIds,
  regions,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const handleOpenRegionModal = () => {
    setRegionModal(true);
  };

  const handleOpenRegionDialog = (id) => {
    setDeleteRegionId(id);
    setOpenDialog(true);
  };

  const handleOpenDetachDialog = (townId, regionId) => {
    setDeleteTownFromRegionIds([townId, regionId]);
    setOpenDialog(true);
  };

  const PrintRegionTowns = ({ region }) => {
    return region.towns.map((data, index) => {
      return (
        <div key={index}>
          <Grid container>
            <Grid item xs={3} className={classes.alignLeft} />
            <Grid item xs={1} className={classes.alignLeft}>
              <IconTooltipButton
                title={language.tableDetails.removeTown}
                onClick={() => handleOpenDetachDialog(data?.id, region?.id)}
              >
                <RemoveIcon />
              </IconTooltipButton>
            </Grid>
            <Grid item xs={2} className={classes.alignLeft}>
              <p className={classes.towns}>{data?.id}</p>
            </Grid>
            <Grid item xs={6} className={classes.alignLeft}>
              <p className={classes.towns}>{data?.name}</p>
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  const PrintRegions = () => {
    return regions.map((data, index) => {
      return (
        <div key={index} style={{ textAlign: "center" }}>
          <Grid container>
            <Grid item xs={3} className={classes.alignLeft} />
            <Grid item xs={1} className={classes.alignLeft}>
              <IconTooltipButton
                title={"Bölgeyi Sil"}
                onClick={() => handleOpenRegionDialog(data?.id)}
              >
                <DeleteIcon />
              </IconTooltipButton>
            </Grid>
            <Grid item xs={2} className={classes.alignLeft}>
              <p className={classes.towns}>{data?.id}</p>
            </Grid>
            <Grid item xs={6} className={classes.alignLeft}>
              <p className={classes.towns}>{data?.name}</p>
            </Grid>
          </Grid>

          <div key={index} className={classes.townsArea}>
            {data.towns.length < 1 ? (
              <p style={{ color: "red" }}>Bölgeye Bağlı İlçe Yok</p>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={4} className={classes.alignLeft}></Grid>
                  <Grid item xs={2} className={classes.alignLeft}>
                    <p className={classes.subtitle}>İlçe No</p>
                  </Grid>
                  <Grid item xs={6} className={classes.alignLeft}>
                    <p className={classes.subtitle}>İlçe</p>
                  </Grid>
                </Grid>
                <PrintRegionTowns region={data} />
              </>
            )}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className={classes.regionsArea}>
        <h2 style={{paddingTop: 20}}>Bölgeler</h2>
        <Grid container>
          <Grid item xs={4} className={classes.alignLeft}></Grid>
          <Grid item xs={2} className={classes.alignLeft}>
            <p className={classes.title}>Bölge No</p>
          </Grid>
          <Grid item xs={6} className={classes.alignLeft}>
            <p className={classes.title}>Bölge</p>
            <div className={classes.addButton}>
              <IconTooltipButton
                title={"Yeni Bölge Ekle"}
                onClick={handleOpenRegionModal}
              >
                <AddIcon />
              </IconTooltipButton>
            </div>
          </Grid>
        </Grid>
        <PrintRegions />
      </div>
    </>
  );
}
