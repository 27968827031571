import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function NewCityModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  oldCompanyProps,
  opts,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [newCompanyProps, setNewCompanyProps] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setNewCompanyProps({
      name: oldCompanyProps ? oldCompanyProps.name : null,
      cityTown: oldCompanyProps ? oldCompanyProps.cityId : null,
      eCompanyType: oldCompanyProps ? oldCompanyProps.eCompanyType : null,
      nameShort: oldCompanyProps ? oldCompanyProps.nameShort : null,
      taxOffice: oldCompanyProps ? oldCompanyProps.taxOffice : null,
      taxIdentificationNumber: oldCompanyProps
        ? oldCompanyProps.taxIdentificationNumber
        : null,
      //companySGKNos: oldCompanyProps ? oldCompanyProps.companySGKNos : []
    });
    setOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldCompanyProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setNewCompanyProps({
      name: null,
      cityTown: null,
      eCompanyType: null,
      nameShort: null,
      taxOffice: null,
      taxIdentificationNumber: null,
      //companySGKNos: []
    });
  };

  const submitNewCompany = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/companies", newCompanyProps);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateCompany = async () => {
    setLoading(true);
    let props = { ...newCompanyProps, cityTownId: newCompanyProps?.cityTown };
    const resp = await Request(
      "patch",
      "/api/companies/" + oldCompanyProps.id,
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              closeUpperModal={handleCloseModal}
              newCompanyProps={newCompanyProps}
              oldCompanyProps={oldCompanyProps}
              setNewCompanyProps={setNewCompanyProps}
              submitNewCompany={submitNewCompany}
              updateCompany={updateCompany}
              options={options}
              loading={loading}
              setSeverity={setSeverity}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbar={setSnackbar}
              tableRef={tableRef}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
