import React from "react";
import { ResponsiveContainer } from "recharts";
import PropTypes from "prop-types";
import {
  AppBar,
  createTheme,
  makeStyles,
  Tab,
  Tabs,
  ThemeProvider,
  Box,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import WorkAccidentCharts from "./WorkAccidentCharts";
import ActionCharts from "./ActionCharts";
import NearMissCharts from "./NearMissCharts";
import TrainingCharts from "./TrainingCharts";
import HealthCharts from "./HealthCharts";
import { DateSettings } from "./Settings";
import Charts from "./Charts";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
    width: "100%",
    height: window.innerHeight > 900 ? "88vh" : "84vh",
    margin: "0 auto",
  },
  panel: {
    overflowY: "auto",
  },
  divider: {
    margin: 10,
  },
  submit: {
    marginTop: "10px",
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
  root: {
    justifyContent: "center",
  },
  scroller: {
    flexGrow: "0",
  },
  isgSection: {
    height: window.innerHeight > 900 ? "75vh" : "70vh",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DashboardTabs({ setBackdropLoading, filterProps, value, setValue }) {
  const classes = useStyles();
  const darkTheme = createTheme({ palette: { type: "dark" } });
  // const [value, setValue] = React.useState(0);

  const [startDate, setStartDate] = React.useState(
    new moment()
      .set("date", 1)
      .set("hour", 0)
      .set("minute", 0)
      .toISOString(true)
  );
  const [endDate, setEndDate] = React.useState(
    new moment()
      .add("month", 1)
      .set("date", 1)
      .set("hour", 23)
      .set("minute", 59)
      .add("days", -1)
      .toISOString(true)
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ResponsiveContainer>
      <>
        <div className={classes.details}>
          <ThemeProvider theme={darkTheme}>
            <AppBar position="static">
              <Tabs
                // there is issue with scrollable tabs and centered
                // https://github.com/mui/material-ui/issues/10235#issuecomment-531505399
                classes={{ root: classes.root, scroller: classes.scroller }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab label="İŞ KAZALARI" {...a11yProps(0)} />
                <Tab label="AKSİYONLAR" {...a11yProps(1)} />
                <Tab label="EĞİTİM" {...a11yProps(2)} />
                <Tab label="SAĞLIK" {...a11yProps(3)} />
                <Tab label="RAMAK KALA" {...a11yProps(4)} />
                <Tab label="İSG DENETİMLERİ" {...a11yProps(5)} />
              </Tabs>
            </AppBar>
            {filterProps.startDate && filterProps.endDate && (
              <>
                <TabPanel className={classes.panel} value={value} index={0}>
                  <WorkAccidentCharts
                    setBackdropLoading={setBackdropLoading}
                    filterProps={filterProps}
                  />
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={1}>
                  <ActionCharts
                    setBackdropLoading={setBackdropLoading}
                    filterProps={filterProps}
                  />
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={2}>
                  <TrainingCharts
                    setBackdropLoading={setBackdropLoading}
                    filterProps={filterProps}
                  />
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={3}>
                  <HealthCharts
                    setBackdropLoading={setBackdropLoading}
                    filterProps={filterProps}
                  />
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={4}>
                  <NearMissCharts
                    setBackdropLoading={setBackdropLoading}
                    filterProps={filterProps}
                  />
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={5}>
                  <div className={classes.isgSection}>
                    <DateSettings
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                    <Charts
                      startDate={startDate}
                      endDate={endDate}
                      setBackdropLoading={setBackdropLoading}
                    />
                  </div>
                </TabPanel>
              </>
            )}
          </ThemeProvider>
        </div>
      </>
    </ResponsiveContainer>
  );
}

export default DashboardTabs;
