import React, { useState, useEffect } from "react";
import { IconButton, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";

const MultipleSnackbar = ({ snackbars, setSnackbars }) => {
  const handleClose = (index) => {
    setSnackbars((prevSnackbars) => {
      const updatedSnackbars = prevSnackbars.slice();
      if (updatedSnackbars[index]) {
        updatedSnackbars[index].open = false;
      }
      return updatedSnackbars.filter(Boolean);
    });
  };

  const printSnackbar = () => {
    let topPosition = 20; // İlk snackbar'ın başlangıç yüksekliği

    const snackbarElements = snackbars.map((snackbar, index) => {
      const snackbarHeight = snackbar?.messages?.length * 20 + 82; 

      const style = {
        top: `${topPosition}px`,
      };

      topPosition += snackbarHeight; // Sonraki snackbar'ın başlangıç yüksekliğini güncelle

      return (
        <Snackbar
          key={index}
          open={snackbar?.open}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          autoHideDuration={null}
          style={style}
        >
          <Alert severity={snackbar?.severity}>
            {snackbar?.key}:
            {snackbar?.messages?.map((message, i) => (
              <p style={{ margin: 0, padding: 0 }} key={i}>
                {message}
              </p>
            ))}
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => handleClose(index)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Alert>
        </Snackbar>
      );
    });

    return snackbarElements;
  };

  return <>{printSnackbar()}</>;
};

export default MultipleSnackbar;