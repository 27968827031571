import React from 'react';
import { useLocation } from 'react-router-dom';
import UserDetails from './UserDetails';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import UnauthorizedPage from '../../UnauthorizedPage';
import SessionHelper from '../../../helpers/SessionHelper';

export default function UserDetailsPage() {
  const location = useLocation();
  const id = location.pathname.substring(6);
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "usersTable");
  
  return (
    <>
      {authorization?.view ? <UserDetails id={id}/> : <UnauthorizedPage/>}
    </>
  );
}