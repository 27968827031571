import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",
/* 
      //FILTERS
      filterTerminationStart: filterProps.filterTerminationStart,
      filterTerminationEnd: filterProps.filterTerminationEnd,
      filterApprovedStart: filterProps.filterApprovedStart,
      filterApprovedEnd: filterProps.filterApprovedEnd,
      filterDepartmentId: filterProps.department?.id,
      filterCompanyId: filterProps.company?.id,
      filterAssignedBy: filterProps.assignedBy,
      filterAssignedTo: filterProps.assignedTo,
      filterStatus: filterProps.status */
    };
/*     if(filterProps.personal) {params.userId = user.id;} */
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "heavyVehicleType") { params.sort = "heavyVehicleType," + query.orderDirection; }
      else if (query.orderBy.field === "plate") { params.sort = "user.fName,user.lName," + query.orderDirection; }
      else if (query.orderBy.field === "brand") { params.sort = "brand," + query.orderDirection; }
      else if (query.orderBy.field === "model") { params.sort = "model," + query.orderDirection; }
      else if (query.orderBy.field === "year") { params.sort = "year," + query.orderDirection; }
      else if (query.orderBy.field === "fuelType") { params.sort = "fuelType," + query.orderDirection; }
      else if (query.orderBy.field === "gearType") { params.sort = "gearType," + query.orderDirection; }
      else if (query.orderBy.field === "company") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "department") { params.sort = "department.name," + query.orderDirection; }
    }
    if(query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/vehicles/heavy-vehicles", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          brand: content[i].brand,
          company: content[i].company?.name,
          department: content[i].department?.name,
          fuelType: content[i].fuelType,
          gearType: content[i].gearType,
          heavyVehicleType: content[i].heavyVehicleType,
          id: content[i].id,
          model: content[i].model,
          plate: content[i].plate,
          year: content[i].year,
          drivers: formatLists(content[i]?.drivers),
          driverObjects: formatDrivers(content[i]?.drivers),
/*           fileKeys: [content[i].registrationDocument], */
        };
/*         edit.files = [];
        edit.files.push(
          new File(
            [content[i].registrationDocument?.fileName],
            content[i].registrationDocument?.fileName,
            { type: "text/plain" }
          )
        ); */
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      })
    }
  })
  return data;
}

const formatLists = (lst) => {
  const array = [];
  for(let prt of lst) {
    array.push(prt.id);
  }
  return array;
}

const formatDrivers = (lst) => {
  const array = [];
  for(let dri of lst) {
    array.push({
      name: dri.fName + " " + dri.lName,
      id: dri.id
    });
  }
  return array;
}