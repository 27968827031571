import Request from "../../../helpers/Request";

export const getUsers = async () => {
  const resp = await Request("get", "/api/kkd-users/all", null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] =
      data[i].fname +
      " " +
      data[i].lname +
      " (" +
      data[i].shortCompanyName +
      ")";
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getCrews = async () => {
  const resp = await Request("get", "/api/crews/all", null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let crew = {};
    crew["name"] = data[i].crewName;
    crew["id"] = data[i].crewId;
    arr.push(crew);
  }
  return arr;
};

export const getKKDs = async (kindId) => {
  const resp = await Request("get", "/api/kkds/by-kind/" + kindId);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let kkd = {};
    kkd["name"] = data[i].kkdName;
    kkd["id"] = data[i].id;
    arr.push(kkd);
  }
  return arr;
};

export const getKKDKinds = async (userId, crewId) => {
  const param = {
    userId: userId,
    crewId: crewId,
  };
  const resp = await Request("get", "/api/kkd-kinds/all", null, param);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let kkd = {};
    kkd["name"] = data[i].name;
    kkd["id"] = data[i].id;
    arr.push(kkd);
  }
  return arr;
};

export const getReturnReasons = async (id) => {
  const params = {
    userId: id ? id : undefined,
  };
  const resp = await Request("get", "/api/enums/return-reasons", null, params);
  return resp.data;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getRegions = async () => {
  const resp = await Request("get", "/api/cities-towns/regions");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let region = {};
    region["name"] = data[i].name;
    region["id"] = data[i].id;
    arr.push(region);
  }
  return arr;
};

export const getCities = async () => {
  const resp = await Request("get", "/api/cities-towns/cities-full");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let city = {};
    city["name"] = data[i].name;
    city["id"] = data[i].id;
    arr.push(city);
  }
  return arr;
};

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const departments = [];
  const [users, reasons, crews, companies, regions, cities] = await Promise.all(
    [
      getUsers(),
      getReturnReasons(),
      getCrews(),
      getCompanies(),
      getRegions(),
      getCities(),
    ]
  );
  const options = {
    users,
    crews,
    reasons,
    departments,
    companies,
    regions,
    cities,
  };
  return options;
}
