import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { TextSelector, TextArea } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  id: null,
  trainingType: null,
  name: null,
  time: null,
  scope: null,
  targetGroup: null,
  fileKeys: [],
};

export default function CatalogueModal({
  catalogueProps,
  setCatalogueModal,
  options,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const classes = useStyles();
  const isNewCatalogue =
    catalogueProps && Object.keys(catalogueProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [files, setFiles] = React.useState([]);
  const [props, setProps] = React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    trainingType: null,
    name: null,
    time: null,
  });

  const init = useCallback(async () => {
    !isNewCatalogue && catalogueProps && setProps({ ...catalogueProps });
    !isNewCatalogue && catalogueProps && setFiles(catalogueProps.fileKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialContractor, user, catalogueProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!props?.time) {
      setErrors({ ...errors, time: errorMessage });
      error = true;
    }
    if (!props?.name) {
      setErrors({ ...errors, name: errorMessage });
      error = true;
    }
    if (!props?.trainingType) {
      setErrors({ ...errors, trainingType: errorMessage });
      error = true;
    }

    if (!error) {
      if (isNewCatalogue) {
        submitNewCatalogue();
      } else {
        updateCatalogue();
      }
    }
  };

  const submitNewCatalogue = async () => {
    setLoading(true);
    let formData = new FormData();
    for (let file of files) {
      formData.append("files", file);
    }
    props.companyId = user?.companyId;
    delete props.company;
    delete props.companyName;
    delete props.id;
    delete props.fileKeys;
    formData.append(
      "newCatalogRequest",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("post", "/api/training/catalog", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateCatalogue = async () => {
    setLoading(true);
    let formData = new FormData();
    for (let file of files) {
      formData.append("files", file);
    }
    props.companyId = user?.companyId;
    props.catalogId = props?.id;
    delete props.companyName;
    delete props.company;
    delete props.id;
    delete props.fileKeys;
    delete props.tableData;
    formData.append(
      "modifyCatalogRequest",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("patch", "/api/training/catalog", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setFiles([]);
    setProps(propsDefault);
    setCatalogueModal(null);
  };

  return (
    <TableEntryModal
      modal={catalogueProps ? true : false}
      handleCloseModal={handleCloseModal}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <TextSelector //TRAINING TYPE
          label={"Eğitim Tipi"}
          required
          error={errors.trainingType}
          value={props?.trainingType}
          shrink={props?.trainingType}
          style={classes.textField}
          onChangeFunc={(value) => {
            if (value?.target?.value === "TOOLBOX EĞİTİMİ") {
              setProps({
                ...props,
                time: 0.25,
                trainingType: value.target.value,
              });
              errors.time && setErrors({ ...errors, time: null });
              errors.trainingType &&
                setErrors({ ...errors, trainingType: null });
            } else {
              setProps({ ...props, trainingType: value.target.value });
              errors.trainingType &&
                setErrors({ ...errors, trainingType: null });
            }
          }}
          options={jsonMap("trainingTypes", options)}
        />
        <TextArea //NAME
          required
          error={errors.name}
          label={"Adı"}
          value={props?.name}
          onChangeFunc={(value) => {
            setProps({ ...props, name: value.target.value });
            errors.name && setErrors({ ...errors, name: null });
          }}
          style={classes.textField}
        />
        <TextArea //TIME
          required
          error={errors.time}
          label={"Süre (Saat)"}
          value={props?.time}
          disabled={props?.trainingType === "TOOLBOX EĞİTİMİ"}
          shrink={props?.time}
          onChangeFunc={(value) => {
            const val = value.target.value;
            setProps({ ...props, time: val });
            errors.time && setErrors({ ...errors, time: null });
          }}
          style={classes.textField}
        />
        <TextArea //SCOPE
          label={"Kapsam"}
          value={props?.scope}
          onChangeFunc={(value) =>
            setProps({ ...props, scope: value.target.value })
          }
          style={classes.textField}
        />
        <TextArea //TARGET GROUP
          label={"Hedef Kitle"}
          value={props?.targetGroup}
          onChangeFunc={(value) =>
            setProps({ ...props, targetGroup: value.target.value })
          }
          style={classes.textField}
        />
        <CustomFileDropzone
          files={files}
          setFiles={(value) => {
            setFiles(value);
          }}
          fileLimit={20}
          dropzoneText={
            "Dosyaları yüklemek için sürükleyin ya da buraya tıklayın."
          }
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewCatalogue ? "GÜNCELLE" : "OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
