import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/reports/received-for-illness/" + companyId + "/departments"
    );
    console.log(resp);
    const data = resp.data.content;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request(
    "get",
    "/api/reports/received-for-illness/companies"
  );
  console.log(resp);
  const data = resp.data.content;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

export const getRegions = async () => {
  const resp = await Request("get", "/api/cities-towns/regions");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let region = {};
    region["name"] = data[i].name;
    region["id"] = data[i].id;
    arr.push(region);
  }
  return arr;
};

export const getUsers = async (deptId) => {
  const resp = await Request(
    "get",
    "/api/reports/received-for-illness/" + deptId + "/users"
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [companies, departments, regions, users] = await Promise.all([
    getCompanies(),
    // getDepartments(SessionHelper.getUser().companyId),
    getDepartments(),
    getRegions(),
    // getUsers(SessionHelper.getUser().departmentId),
    getUsers(),
  ]);
  const options = { departments, companies, regions, users };
  return options;
}
