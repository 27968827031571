import { MenuItem } from "@material-ui/core";
import React from "react";
import Request from "../../../../helpers/Request";
import { taskStatuses, inspectionTypes } from "../../../../assets/constants";
import SessionHelper from "../../../../helpers/SessionHelper";

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const exists = {
    true: "Var",
    false: "Yok",
  };
  const compsAndDepts = null;
  const [users, supervisors, departments, towns, regions, companies, compsAndDeptsCurrentUser] = await Promise.all([
    getUsers(), getSupervisors(), getDepartments(), getTowns(), getRegions(), getCompanies(), getCompaniesAndDepartments(SessionHelper.getUser().id)
  ]);
  const filterDepartments = [];
  let filterCompanies = [];
  for(let comp in compsAndDeptsCurrentUser) {
    let temp = {};
    temp["name"] = comp;
    filterCompanies.push(temp);
  };
  const userRole = [];

  const options = {
    users,
    regions,
    compsAndDepts,
    compsAndDeptsCurrentUser,
    taskStatuses,
    inspectionTypes,
    exists,
    departments,
    towns,
    companies,
    filterCompanies,
    filterDepartments,
    userRole,
    supervisors
  };
  return options;
}

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let company = {};
    company["name"] = data[i].isActive ? data[i].name : data[i].name + " (Silinmiş)";
    company["id"] = data[i].id;
    arr.push(company);
  }
  return arr;
};

export const getDepartments = async () => {
  const resp = await Request("get", "/api/departments/basic");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].active ? data[i].name : data[i].name + " (Silinmiş)";
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

/**
 * Gets the user data to use in the options menu.
 */
export const getUsers = async () => {
  const resp = await Request("get", "/api/users/isg-users", null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getSupervisors = async () => {
  const resp = await Request("get", "/api/users/supervisors", null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getUserRoles = async (id) => {
  const resp = await Request("get", "/api/users/" + id, null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data?.roles?.length; i++) {
    let role = {};
    role["name"] = data?.roles[i]?.name;
    role["id"] = data?.roles[i]?.id;
    arr.push(role);
  }
  return arr;
}

/**
 * Request to get all the personnel names and ids.
 * @returns {obj} a json object that contains inspection details
 */
export const getPersonnel = async (deptId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-department/" + deptId
  );
  const json = resp.data;
  let arr = [];
  for (let i = 0; i < json.length; i++) {
    const obj = (
      <MenuItem value={json[i].id}>
        {json[i].fName + " " + json[i].lName}
      </MenuItem>
    );
    arr.push(obj);
  }
  return arr;
};

/**
 * Gets the town data to use in the options menu.
 */
export const getTowns = async () => {
  const resp = await Request("get", "/api/cities-towns/towns-full");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] =
      data[i].name === "Merkez"
        ? data[i].belongingCityName + " " + data[i].name
        : data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getTownsOfRegion = async () => {
  const resp = await Request("get", "/api/cities-towns/towns-full");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] =
      data[i].name === "Merkez"
        ? data[i].belongingCityName + " " + data[i].name
        : data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getRegions = async () => {
  const resp = await Request("get", "/api/cities-towns/regions");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let region = {};
    region["towns"] = data[i].towns;
    region["name"] = data[i].name;
    region["id"] = data[i].id;
    arr.push(region);
  }
  return arr;
};

/**
 * Gets the department data to use in the options menu.
 */
export const getCompaniesAndDepartments = async (id) => {
  const param = {
    "only-edc": SessionHelper.getUser().roles.includes("ROLE_BÖLGE_MÜDÜRÜ"),
  };
  const resp = await Request(
    "get",
    "/api/departments/" + id + "/get-according-to-user/table",
    null,
    param
  );
  return resp.data;
};
