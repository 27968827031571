import React from 'react';
import IconTooltipButton from '../../../../components/Buttons/IconTooltipButton';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LanguageHelper from '../../../../helpers/LanguageHelper';

export default function UnsuitabilityButton({rowData, large, label}) {
  const language = LanguageHelper.getLanguage();

  const goToUnsuitTask = async () => {
    window.open('/unsuittask/' + rowData?.id);
  }

  return (
    <>
      {(rowData.unsuitability !== "Yok" && rowData.unsuitability !== "-") &&
        <IconTooltipButton title={language.tableDetails.unsuitability} label={label && "Uygunsuzluk Giderme Görevine Git"} onClick={() => goToUnsuitTask()}> 
          <AssignmentIcon fontSize={large && "large"}/>
        </IconTooltipButton>}
    </>
  )
}
