import axios from "axios";
import SessionHelper from "./SessionHelper";

export const url = "https://demoisg.eu-west-1.elasticbeanstalk.com"; //AMAZON DEV
export const fileUrl =
  "https://isg-demo.s3.eu-central-1.amazonaws.com/";

// export const url = "https://isgapi.gdzelektrik.com.tr:8443"; //LINUX TEST
// export const fileUrl = "https://isgapi.gdzelektrik.com.tr:8443/open-file/";

/**
 * Manages the requests made to the REST api.
 * @param {string} action get/post/patch etc.
 * @param {string} urlExtension api part of the url
 * @param {string} body json object given as a string for the body of the request
 * @param {string} params json object given as a string for the params of the request
 * @param {string} headers json object given as a string for the extra headers of the request
 * @returns {obj} the json object
 */
const Request = async (action, urlExtension, body, params, headers) => {
  // check if user is logged in and session time is expired
  if (SessionHelper.getUser()) {
    if (isSessionTimeExpired()) {
      window.location.href = "/signin";
      SessionHelper.deleteUser(); // logout
      return;
    } else {
      SessionHelper.setLoginTime(); // set last request time
    }
  }

  let header = SessionHelper.getUser()
    ? {
        Authorization: "Bearer " + SessionHelper.getUser().accessToken,
        "Accept-Language": "tr-TR",
      }
    : null;
  if (headers) {
    header = { ...header, ...headers };
  }
  let fetch;
  const new_url = url + urlExtension;
  await axios({
    method: action,
    url: new_url,
    data: body,
    params: params,
    headers: header,
  })
    .then((response) => {
      fetch = response;
    })
    .catch((error) => {
      fetch = error.response;
      if (fetch.status === 401) {
        window.location = "/signin";
        SessionHelper.deleteUser();
      }
    });
  return fetch;
};

export const RequestAll = async (requests) => {
  let axiosReqs = [];
  for (let i = 0; i < requests.length; i++) {
    const req = requests[i];
    const { action, urlExtension, body, params, headers } = req;
    let header = SessionHelper.getUser()
      ? {
          Authorization: "Bearer " + SessionHelper.getUser().accessToken,
          "Accept-Language": "tr-TR",
        }
      : null;
    if (headers) {
      header = { ...header, ...headers };
    }
    const new_url = url + urlExtension;
    axiosReqs.push(
      axios({
        method: action,
        url: new_url,
        data: body,
        params: params,
        headers: header,
      })
    );
  }
  let fetch;
  await axios
    .all(axiosReqs)
    .then(
      axios.spread((...responses) => {
        fetch = responses;
      })
    )
    .catch((error) => {
      fetch = error.response;
      if (fetch.status === 401) {
        window.location = "/signin";
        SessionHelper.deleteUser();
      }
    });
  return fetch;
};

function isSessionTimeExpired() {
  let requestTime = new Date();
  // difference between current request time and last request time
  // https://stackoverflow.com/a/7709819
  let sessionTime = new Date(SessionHelper.getLoginTime())
  var diffMs = requestTime - sessionTime; // milliseconds
  var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours

  if (diffHrs >= 1) {
    return true; // expired
  } else {
    return false; // not expired
  }
}

function getIPFromAmazon() {
  axios({method: "get",url: "https://checkip.amazonaws.com/"}).then(res => console.log(res))
}


export default Request;
