import Request from "../../../../helpers/Request";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      company: filterProps.filterCompany?.id,
      department: filterProps.filterDepartment?.id,
      recordWeek: filterProps.recordWeek,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "number") {
        params.sort = "number," + query.orderDirection;
      } else if (query.orderBy.field === "userName") {
        params.sort = "user.fName,user.lName," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "company.name," + query.orderDirection;
      } else if (query.orderBy.field === "departmentName") {
        params.sort = "department.name," + query.orderDirection;
      } else if (query.orderBy.field === "date") {
        params.sort = "analysisDate," + query.orderDirection;
      } else if (query.orderBy.field === "createdAt") {
        params.sort = "createdAt," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/crew-numbers", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          companyName: data[i].companyName,
          departmentName: data[i].departmentName,
          weekStart: data[i].weekStart,
          toolboxDay: data[i].toolboxDay,
          d1: data[i].d1,
          d2: data[i].d2,
          d3: data[i].d3,
          d4: data[i].d4,
          d5: data[i].d5,
          d6: data[i].d6,
          d7: data[i].d7,
          savedAt: data[i].savedAt ? true : false,
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
