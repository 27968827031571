import React from "react";
import Grid from "@material-ui/core/Grid";
import PageSelector from "../../../components/Selectors/PageSelector";
import { TextField } from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    textAlign: "center",
  },
  container: {
    display: "flex",
    direction: "row",
    margin: "2rem 2rem 0",
    justifyContent: "space-between",
  },
  message: {
    fontSize: "small",
    marginLeft: "1rem",
  },
}));

/**
 * Renders the question part of the details panel and the settings part of the details panel.
 * Question can be changed, page can be changed from the page selector, new questions can be added
 * and existing questions can be deleted from the template.
 * @param {formTemplate: obj, changeQuestionRequest: func, question: string,
 * changeQuestion: func, activeStep: number, handleDialogOpen: func, done: boolean, pages: number[],
 * handlePageChange: func, handleOpenQuestionMenu: func} param props of the content component
 */
export default function Header({
  formTemplate,
  changeQuestionRequest,
  question,
  changeQuestion,
  activeStep,
  handleDialogOpen,
  done,
  pages,
  handlePageChange,
  handleOpenQuestionMenu,
  isDefault,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  return (
    <Grid item>
      <div className={classes.container}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <PageSelector
            pages={pages}
            handlePageChange={handlePageChange}
            activeStep={activeStep}
          />
          <div>
            {formTemplate.questions[activeStep] && (
              <TextField
                multiline={true}
                inputProps={{ style: { fontSize: 20, color: "black" } }}
                className={classes.textField}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    changeQuestionRequest();
                    ev.preventDefault();
                  }
                }}
                value={question}
                onChange={(value) => changeQuestion(value.target.value)}
              />
            )}
            {done && !isDefault && (
              <IconTooltipButton
                title={language.form.approveChange}
                onClick={changeQuestionRequest}
              >
                <DoneIcon />
              </IconTooltipButton>
            )}
          </div>
        </div>
        {!isDefault && (
          <Grid item>
            <IconTooltipButton
              title={language.form.newQuestion}
              onClick={handleOpenQuestionMenu}
            >
              <AddIcon />
              <span className={classes.message}>
                {language.form.newQuestion}
              </span>
            </IconTooltipButton>
            {pages.length !== 0 && (
              <IconTooltipButton
                title={language.form.deleteQuestion}
                onClick={() => handleDialogOpen(null)}
              >
                <DeleteIcon />
                <span className={classes.message}>
                  {language.form.deleteQuestion}
                </span>
              </IconTooltipButton>
            )}
          </Grid>
        )}
      </div>
    </Grid>
  );
}
