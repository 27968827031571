import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import "../../../index.css";
import { TextArea } from "../../../components/Fields/TextFields";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../helpers/LanguageHelper";

const language = LanguageHelper.getLanguage();

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5,
  },
  fields: {
    marginTop: -5,
    color: "#B8B8B8",
  },
  textField: {
    marginTop: -10,
    marginBottom: 30,
  },
  gridContainer: {
    textAlign: "center",
  },
  required: {
    marginTop: -5,
    color: "#b24d50",
    fontSize: 13,
  },
}));

export default function InstructorsList({ props, setProps, options, err }) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [autocompleteValue, setAutocompleteValue] = React.useState("");
  const [external, setExternal] = React.useState("");

  const RenderUsersBox = () => {
    let instructors = props.instructors ? [...props.instructors] : [];
    let externalInstructors = props.externalInstructors
      ? [...props.externalInstructors]
      : [];

    return (
      <div>
        {(instructors && instructors.length > 0) ||
        (externalInstructors && externalInstructors.length > 0) ? (
          <List className={classes.list}>
            {instructors.map((data, i) => (
              <ListItem key={i}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container xs={7} direction="row">
                    <IconTooltipButton
                      title={language.tableDetails.deleteInstructor}
                      onClick={() => {
                        instructors.splice(i, 1);
                        setProps({ ...props, instructors: instructors });
                      }}
                    >
                      <DeleteIcon />
                    </IconTooltipButton>
                    <ListItemText
                      style={{ marginTop: 15 }}
                      secondary={
                        data.name ||
                        data.fName + " " + data.lName ||
                        language.etc.error
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
            {externalInstructors.map((data, i) => (
              <ListItem key={i}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container xs={7} direction="row">
                    <IconTooltipButton
                      title={language.tableDetails.deleteInstructor}
                      onClick={() => {
                        externalInstructors.splice(i, 1);
                        setProps({
                          ...props,
                          externalInstructors: externalInstructors,
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconTooltipButton>
                    <ListItemText
                      style={{ marginTop: 15 }}
                      secondary={
                        data + language.etc.external || language.etc.error
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={classes.list} style={{ textAlign: "center" }}>
            <p style={{ color: "red", fontSize: 14, marginLeft: "auto" }}>
              {language.errors.noInstructor}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Grid item xs={6}>
        <p className={classes.fields}>{language.errors.maxMin}</p>
      </Grid>
      <RenderUsersBox />
      {props.instructors.length + props.externalInstructors.length < 3 && (
        <Grid container className={classes.gridContainer}>
          <Grid item xs={5}>
            <Autocomplete
              id="combo-box"
              options={options.instructors?.sort(function (a, b) {
                return collator.compare(a.name, b.name);
              })}
              getOptionLabel={(option) => option.name}
              fullWidth={true}
              disableCloseOnSelect={true}
              clearOnBlur={true}
              disableClearable={true}
              inputValue={autocompleteValue}
              onInputChange={(event, value, reason) => {
                if (reason === "reset") {
                  setAutocompleteValue("");
                } else if (reason === "input") {
                  setAutocompleteValue(value);
                }
              }}
              onChange={(event, value, reason) => {
                if (value?.id && value !== "") {
                  const instructors = props.instructors
                    ? [...props.instructors]
                    : [];
                  if (!instructors.includes(value)) {
                    instructors.push(value);
                    setProps({ ...props, instructors: instructors });
                  }
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  error={err}
                  helperText={err}
                  label={language.tableDetails.addInstructor}
                  className={classes.textField}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <p style={{ color: "red" }}>{language.etc.or}</p>
          </Grid>
          <Grid item xs={5}>
            <TextArea
              required
              error={err}
              label={language.tableDetails.addExternalIns}
              value={external}
              shrink={external}
              style={classes.textField}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  const instructors = props.externalInstructors
                    ? [...props.externalInstructors]
                    : [];
                  if (!instructors.includes(external) && external !== "") {
                    instructors.push(external);
                    setProps({ ...props, externalInstructors: instructors });
                    setExternal("");
                  }
                  ev.preventDefault();
                }
              }}
              onChangeFunc={(value) => {
                setExternal(value.target.value);
              }}
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
}
