import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
} from "@material-ui/core";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  lastInsuranceDate: moment().toISOString(true),
  //maintenanceKm: null,
  renewDate: null,
  files: [],
};

export default function BasketInsulationModal({
  modal,
  setModal,
  options,
  setOptions,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  id,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState(propsDefault);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const init = useCallback(async () => {
    setModalLoading(true);
    setProps({...propsDefault, vehicle: id});
    setModalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const submitCarInsurance = async () => {
    setLoading(true);
    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("trafficInsuranceFile", file);
      }
    }
    formData.append(
      "request",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("post", "/api/vehicles/" + id + "/traffic-insurance", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setModal(false);
    setProps(propsDefault);
    setFiles([]);
  };

  return (
    <TableEntryModal
      modal={modal}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <CustomDatePicker
          style={classes.dateField}
          label={"Trafik Sigortası Tarihi"}
          value={props?.lastInsuranceDate}
          shrink={true}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              lastInsuranceDate: moment(value).toISOString(true),
            })
          }
        />
        <CustomDatePicker
          style={classes.dateField}
          label={"Yenileme Tarihi"}
          value={props?.renewDate}
          shrink={true}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              renewDate: moment(value).toISOString(true),
            })
          }
        />
        <CustomFileDropzone
          files={files}
          setFiles={(value) => {
            setFiles(value);
          }}
          fileLimit={200}
          dropzoneText={"Dosya yüklemek için sürükleyin ya da buraya tıklayın."}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => submitCarInsurance()}
              className={classes.submit}
            >
              {"OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
