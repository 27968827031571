import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    // const params = {
    //   page: query.page,
    //   size: query.pageSize,
    //   sort: "id,desc",
    // };
    // if (query.orderBy !== undefined) {
    //   if (query.orderBy.field === "id") {
    //     params.sort = "id," + query.orderDirection;
    //   } else if (query.orderBy.field === "type") {
    //     params.sort = "type," + query.orderDirection;
    //   }
    // }
    // if (query.search !== "") {
    //   params.search = query.search;
    // }
    const resp = await Request("get", "/api/ibys/calisma-sekli");
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          description: data[i].description,
          name: data[i].name,
        };
        temp.push(edit);
      }
      // setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        // page: originalData.pageable.pageNumber,
        // totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
