import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
}));

export default function ModalForm({
  workingTypeProps,
  setWorkingTypeProps,
  label,
  loading,
  setLoading,
  handleCloseModal,
  oldWorkingTypeProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [errors, setErrors] = React.useState({
    idError: null,
    nameError: null,
    descriptionError: null,
  });

  const onButtonClick = () => {
    let error = false;
    let errorMessage = language.errors.blankField;
    let errors = {
      idError: null,
      nameError: null,
      descriptionError: null,
    };
    if (!workingTypeProps.id) {
      errors.idError = errorMessage;
      error = true;
    }
    if (!workingTypeProps.name) {
      errors.nameError = errorMessage;
      error = true;
    }
    if (!workingTypeProps.description) {
      errors.descriptionError = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      submitNewWorkingType();
    }
  };

  /**
   * Request to replace the old data with the new data.
   */
  // const updateWorkingType = async () => {
  //   setLoading(true);
  //   const resp = await Request(
  //     "patch",
  //     "/api/calisma-sekli/",
  //     oldWorkingTypeProps.id,
  //     workingTypeProps
  //   );
  //   console.log(resp);
  //   showSnackbar(
  //     resp,
  //     setSeverity,
  //     setSnackbarMessage,
  //     setSnackbar,
  //     language.etc.updated,
  //     tableRef
  //   );
  //   handleCloseModal();
  //   setLoading(false);
  // };

  const submitNewWorkingType = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/ibys/calisma-sekli", workingTypeProps);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.created,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        shrink={workingTypeProps.id}
        label={language.ibysPages.idNo}
        required
        error={errors.idError}
        value={workingTypeProps.id}
        style={classes.textField}
        onChangeFunc={(value) => {
          setWorkingTypeProps({
            ...workingTypeProps,
            id: value.target.value,
          });
          errors.idError && setErrors({ ...errors, idError: null });
        }}
      />
      <TextArea
        shrink={workingTypeProps.name}
        label={language.ibysPages.name}
        required
        error={errors.nameError}
        value={workingTypeProps.name}
        style={classes.textField}
        onChangeFunc={(value) => {
          setWorkingTypeProps({
            ...workingTypeProps,
            name: value.target.value,
          });
          errors.nameError && setErrors({ ...errors, nameError: null });
        }}
      />
      <TextArea
        shrink={workingTypeProps.description}
        label={language.ibysPages.description}
        required
        error={errors.descriptionError}
        value={workingTypeProps.description}
        style={classes.textField}
        onChangeFunc={(value) => {
          setWorkingTypeProps({
            ...workingTypeProps,
            description: value.target.value,
          });
          errors.descriptionError &&
            setErrors({ ...errors, descriptionError: null });
        }}
      />
      <div className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onButtonClick()}
            className={classes.submit}
          >
            {label}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
