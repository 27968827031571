import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";

const language = LanguageHelper.getLanguage();

/**
 * Gets the options to use in the table fields.
 */
/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const active = {
    true: "Aktif",
    false: "Aktif Değil",
  };
  const isgTraining = {
    true: "Evet",
    false: "Hayır",
  };
  const [
    accidentDefinitions,
    accidentTypes,
    accidentTypesEPDK,
    lostLimb,
    bodyParts,
    accidentOutcomes,
    injuryTypes,
    companies,
    departments,
    edcCompanies,
    edu,
    marital,
    gender,
    sections,
    regions,
    cities,
    filterCompanies,
    places,
  ] = await Promise.all([
    getAccidentDefinitions(),
    getAccidentTypes(),
    getAccidentTypesEPDK(),
    getLostLimb(),
    getBodyParts(),
    getAccidentOutcomes(),
    getInjuryTypes(),
    getCompanies(),
    getDepartments(SessionHelper.getUser().companyId),
    getEDCCompanies(),
    getEducationStatuses(),
    getMaritalStatuses(),
    getGenders(),
    getSections(),
    getRegions(),
    getCities(),
    getFilterCompanies(),
    getPlaces(),
  ]);
  const filterDepartments = [];
  const towns = [];
  const users = null;
  const actionUsers = null;
  const sgkNos = null;
  const options = {
    accidentDefinitions,
    accidentTypesEPDK,
    lostLimb,
    bodyParts,
    accidentOutcomes,
    injuryTypes,
    companies,
    edu,
    marital,
    gender,
    active,
    isgTraining,
    sgkNos,
    sections,
    accidentTypes,
    users,
    actionUsers,
    edcCompanies,
    departments,
    regions,
    cities,
    towns,
    filterCompanies,
    filterDepartments,
    places,
  };
  return options;
}

const getAccidentDefinitions = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/accident-definitions"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the user data to use in the options menu.
 */
const getAccidentTypes = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/accident-types"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the user data to use in the options menu.
 */
const getAccidentTypesEPDK = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/accident-types-epdk"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the user data to use in the options menu.
 */
const getAccidentOutcomes = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/accident-outcomes"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the user data to use in the options menu.
 */
const getInjuryTypes = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/injury-types"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the work accident types to use in the options menu.
 */
const getLostLimb = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/lost-limbs"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  fetch = new_json;
  return fetch;
};

const getBodyParts = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/body-parts"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  fetch = new_json;
  return fetch;
};

export const getMaritalStatuses = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/marital");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getEducationStatuses = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/education");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getGenders = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/gender");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getSections = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/sections"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the company data to use in the options menu.
 */
const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info", null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

/**
 * Gets the edc company data to use in the options menu.
 */
const getEDCCompanies = async () => {
  let fetch = {};
  const resp = await Request("get", "/api/companies/type-edc", null);
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    new_json[json[i].id] = json[i].name;
  }
  fetch = new_json;
  return fetch;
};

export const getUsers = async (id, onlyActives) => {
  const params = {
    "company-id": id,
    "only-actives": onlyActives,
  };
  const resp = await Request("get", "/api/users/all-with-names", null, params);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getActionUsers = async () => {
  const resp = await Request("get", "/api/users/edc-users");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getSGKofCompany = async (id) => {
  let fetch = {};
  const resp = await Request("get", "/api/companies/" + id, null);
  const sgks = resp.data.companySGKNos;
  let new_json = {};
  for (let i = 0; i < sgks.length; i++) {
    new_json[sgks[i]] = sgks[i];
  }
  fetch = new_json;
  return fetch;
};

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getCities = async () => {
  const resp = await Request("get", "/api/cities-towns/cities-full/");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let city = {};
    city["name"] = data[i].name;
    city["id"] = data[i].id;
    arr.push(city);
  }
  return arr;
};

export const getTowns = async (cityId) => {
  const resp = await Request("get", "/api/cities-towns/towns/" + cityId);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] = data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getRegions = async () => {
  const resp = await Request("get", "/api/cities-towns/regions");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let region = {};
    region["towns"] = data[i].towns;
    region["name"] = data[i].name;
    region["id"] = data[i].id;
    arr.push(region);
  }
  return arr;
};

export const getFilterDepartments = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/departments/belongs-to-company/" + companyId + "/basic-info"
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

/**
 * Gets the company data to use in the filter menu.
 */
export const getFilterCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getPlaces = async () => {
  let fetch;
  const resp = await Request(
    "get",
    "/api/industrial-accidents/selections/accident-place"
  );
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  // new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};
