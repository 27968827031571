import React, { useCallback } from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DoneIcon from "@material-ui/icons/Done";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { TextSelector, TextArea } from '../../../components/Fields/TextFields';
import { FormControl, Grid, makeStyles, Popover } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 10
  },
  textField: {
    marginBottom: 10,
    whiteSpace: 'pre-wrap'
  },
  buttonContainer: {
    paddingBottom: 20, 
    textAlign: "center"
  },
  saveButton: {
    marginTop: 10,
    marginLeft: 20
  }
}));

export default function StatusColumn({
  rowData,
  roles,
  jsonMap,
  statuses,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [dropdown, setDropdown] = React.useState(statuses);
  const [disabled, setDisabled] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [save, setSave] = React.useState(false);
  const [successful, setSuccessful] = React.useState(false);
  const initValue = React.useRef(rowData.status);

  const init = useCallback(async () => {
    setSave(false);
    setStatus(rowData.status);
    if(initValue.current === "TODO") {
      if(user.id === rowData.assignedTo) {
        setDropdown({
          TODO: "Başlanmadı",
          DONE: "Onay Bekliyor"
        });
      } else {
        setDisabled(true);
      }
    } else if(initValue.current === "DONE") { 
      if(roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
        roles.includes("ROLE_ISG_UZMANI") ||
        roles.includes("ROLE_OSGB_ISG_UZMANI") ||
        user.id === rowData.givenBy) {
        setDropdown({
          DONE: "Onay Bekliyor",
          APPROVED: "Onaylandı",
          REJECTED: "Reddedildi"
        });
      } else {
        setDisabled(true);
      }
    } else if(initValue.current === "APPROVED") { 
      setDisabled(true);
    } else if(initValue.current === "REJECTED") { 
      if(user.id === rowData.assignedTo) {
        setDropdown({
          DONE: "Onay Bekliyor",
          REJECTED: "Reddedildi"
        });
      } else {
        setDisabled(true);
      }
    }
  }, [rowData, roles, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const changeStatus = async (setSave, message) => {
    let resp;
    const body = {
      status: rowData.status,
      actionId: rowData.id,
      message: message || null
    };
    resp = await Request("patch", "/api/isgCommittee/action/change-status", body);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    if(resp.status === 200) {
      setSuccessful(true);
      initValue.current = status;
    }
    setSave(false);
  };

  const RenderMessageField = () => {
    const [message, setMessage] = React.useState(null);
    const [save, setSave] = React.useState(false);

    return(
      <FormControl
          noValidate
          autoComplete="off"
          className={classes.formControl}
        >
        <Grid container direction="row">
          <Grid item>
            <TextArea //DESCRIPTION
              label={"Açıklama"}
              value={message}
              multiline
              required
              shrink={message && message !== ""}
              style={classes.textField}
              onChangeFunc={(value) => {
                setMessage(value.target.value);
                value.target.value !== "" ? setSave(true) : setSave(false);
              }}
            />
          </Grid>
          <Grid item>
          {save && (
            <div className={classes.saveButton}>
              <IconTooltipButton
                title={language.form.approveChange}
                onClick={() => changeStatus(setSave, message)}
              >
                <DoneIcon />
              </IconTooltipButton>
            </div>
          )}
          </Grid>
        </Grid>
      </FormControl>
    )
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TextSelector
        required
        disabled={disabled}
        value={status}
        error={rowData.status !== initValue.current && !successful && "Değişiklik henüz kaydedilmedi."}
        options={jsonMap(dropdown)}
        onChangeFunc={(value) => {
          setSuccessful(false);
          rowData.status = value.target.value;
          setStatus(value.target.value);
          if(value.target.value !== initValue.current) {
            if(value.target.value !== "APPROVED") {
              handleClick(value);
              setSave(false);
            } else {
              setSave(true);
            }
          } else {
            setSave(false);
          }
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <RenderMessageField/>
      </Popover>
      <div style={{ marginLeft: "20px" }}>
        {save && (
          <IconTooltipButton
            title={language.form.approveChange}
            onClick={() => changeStatus(setSave)}
          >
            <DoneIcon />
          </IconTooltipButton>
        )}
      </div>
    </div>
  );
}
