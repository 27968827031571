import React, { useCallback } from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AssignmentIcon from "@material-ui/icons/Assignment";
import StatusColumn from "./StatusColumn";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import { GDZ_EYAŞ, taskStatuses } from "../../../assets/constants";
import GetFilterFields from "./UnsuitFilters";
import showSnackbar from "../../../components/Utils/showSnackbar";
import moment from "moment";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import UnsuitModal from "./UnsuitModal";

/**
 * A table component that lists the unsuitability correction reports.
 * Files of these reports can be found in their respective details panel.
 */
export default function UnsuitTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;

  const authName = "unsuitTaskTable";
  const authorization = getAuthorizationForPage(roles, authName);
  const title = "Uygunsuzluk Giderme Görevleri";

  let statuses = { ...taskStatuses };
  statuses.DONE = "Onay Bekliyor";

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState([]);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [companyDisabled, setCompanyDisabled] = React.useState(false)

  const [unsuitModal, setUnsuitModal] = React.useState(null);
  const [modalLoading, setModalLoading] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    startDate: null,
    endDate: null,
    department: null,
    company: null,
    region: null,
    town: null,
    unsuitUserName: null,
    inspectUserName: null,
    status: '',
    personal: false,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    checkEyasIsgRole()
    setColumns([
      { title: language.tableColumns.id, field: "id", editable: "never" },
      { title: "Görev No", field: "customId", editable: "never" },
      {
        title: "Uygunsuzluk Giderici Kişi",
        field: "fullName",
        editable: "never",
      },
      {
        title: "Denetlenen Kişiler",
        field: "supervisedUsers",
        editable: "never",
        render: (rowData) => (
          <>
            {rowData?.supervisedUsers.length > 0 ? (
              <List
                style={{
                  width: 250,
                  height: 100,
                  overflow: "auto",
                }}
              >
                {rowData?.supervisedUsers.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={data.fName + " " + data.lName} />
                  </ListItem>
                ))}
              </List>
            ) : (
              "Yok"
            )}
          </>
        ),
      },
      {
        title: "Denetim Yapan Kişi",
        field: "inspectionUserName",
        editable: "never",
        render: (rowData) => (
          <>
            <label style={{ marginRight: "20px" }}>
              {rowData.inspectionUserName}
            </label>
            {rowData.inspection && (
              <IconTooltipButton
                title={"Denetim görevine git."}
                onClick={() => goToTask(rowData?.inspectionId)}
              >
                <AssignmentIcon />
              </IconTooltipButton>
            )}
          </>
        ),
      },
      { title: "Onaylayan Kişi", field: "approvedBy", editable: "never" },
      { title: "Şirket", field: "company", editable: "never" },
      { title: "Bölüm", field: "department", editable: "never" },
      { title: "Şehir", field: "cityName", editable: "never" },
      { title: "Faaliyet Bölgesi", field: "townName", editable: "never" },
      { title: "Faaliyet Tipi", field: "departmentField", editable: "never" },
      {
        title: language.tableColumns.terminationDate,
        field: "terminationTime",
        type: "date",
        editable: "never",
        render: rowData => moment(rowData.terminationTime).format("DD-MM-YYYY HH:mm:ss")
      },
      {
        title: language.tableColumns.completionDate,
        field: "completionTime",
        type: "date",
        editable: "never",
      },
      {
        title: "Onaylanma Tarihi",
        field: "approvalDate",
        type: "date",
        editable: "never",
      },
      {
        title: "Açıklama",
        field: "nonconformityMessages",
        editable: "never",
        render: (rowData) => (
          <div
            style={{
              overflowY: "scroll",
              maxHeight: "100px",
              width: 200,
              alignItems: "center",
            }}
          >
            {rowData.nonconformityMessages}
          </div>
        ),
      },
      {
        title: language.tableColumns.state,
        field: "eStatus",
        editable: "never",
        lookup: taskStatuses,
        render: (rowData) => {
          return (
            <StatusColumn
              rowData={rowData}
              roles={roles}
              jsonMap={jsonMap}
              statuses={statuses}
              setSeverity={setSeverity}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbar={setSnackbar}
              tableRef={tableRef}
            />
          );
        },
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const goToTask = async (id) => {
    window.open("/task/" + id);
  };

  const jsonMap = (json) => {
    return (
      json &&
      Object.keys(json).map((data) => (
        <MenuItem key={data} value={data}>
          {json[data]}
        </MenuItem>
      ))
    );
  };

  const checkEyasIsgRole = () =>{
    if(roles.includes(GDZ_EYAŞ.isgSpecialistName)){
      setFilterProps({
        ...filterProps,
        company: {name: GDZ_EYAŞ.companyName}
      })
      setCompanyDisabled(true)
    }
  }

  // const handleEdit = async (newData, oldData) => {
  //   setRequestLoading(true);
  //   const newDate = new moment(newData.terminationTime).format(
  //     "YYYY-MM-DDTHH:mm:ss.SSSZ"
  //   );

  //   let body = {
  //     terminationTime: newDate,
  //   };

  //   const resp = await Request(
  //     "patch",
  //     "/api/unsuitInspections/" + oldData.id,
  //     body
  //   );
  //   console.log(resp);
  //   showSnackbar(
  //     resp,
  //     setSeverity,
  //     setSnackbarMessage,
  //     setSnackbar,
  //     language.etc.updated,
  //     tableRef
  //   );
  //   setRequestLoading(false);
  // };

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    setUnsuitModal(data || {});
    setModalLoading(false);
  }

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const removeTask = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/unsuitInspections/" + idToDelete
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const removeTasks = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join(),
    };
    const resp = await Request(
      "delete",
      "/api/unsuitInspections/",
      null,
      param
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
          companyDisabled={companyDisabled}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      startDate: null,
      endDate: null,
      department: null,
      company: companyDisabled ? filterProps.company : null,
      region: null,
      town: null,
      unsuitUserName: null,
      inspectUserName: null,
      status: '',
      personal: false,
    });
  };

  const getDifferenceBetweenDates = (date1, date2) => {
    date1 =
      date1 === "Tamamlanmadı"
        ? moment()
        : moment(date1, "DD-MM-YYYY HH:mm:ss");
    date2 = moment(date2, "DD-MM-YYYY HH:mm:ss");
    let duration = moment.duration(date1.diff(date2));
    let hours = duration.asHours();
    return hours;
  };

  const getRowStyle = (rowData) => {
    const dif = getDifferenceBetweenDates(
      rowData.completionTime,
      rowData.terminationTime
    );
    if (dif > 24 * 5) {
      return { backgroundColor: "#805f4f" };
    }
    if (dif > 24) {
      return { backgroundColor: "#80794f" };
    }
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeTasks : idToDelete && removeTask}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <UnsuitModal
        modalLoading={modalLoading}
        unsuitProps={unsuitModal}
        setUnsuitModal={setUnsuitModal}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters />
      <Table
        tableName={title}
        authName={authName}
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isEditable={() =>
          roles.includes("ROLE_SİSTEM_ADMİNİ") ||
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI")
        }
        // isEditHidden={() => !authorization.edit}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI")
        }
        isDeleteHidden={() => !authorization.delete}
        detailsWindow={(rowData) => window.open("/unsuittask/" + rowData.id)}
        rowStyle={(rowData) => getRowStyle(rowData)}
        // updateFunc={handleEdit}
        headerComponents={
          <FormControlLabel
            control={
              <Checkbox
                checked={filterProps.personal}
                onChange={() => {
                  setFilterProps({
                    ...filterProps,
                    personal: !filterProps.personal,
                  });
                  tableRef.current && tableRef.current.onQueryChange();
                }}
                name="personalTasks"
              />
            }
            label="Kendi Uygunsuzluk Giderme Görevlerimi Göster"
          />
        }
      />
    </>
  );
}
