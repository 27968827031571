import React from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Grid } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AddIcon from "@material-ui/icons/Add";
import PrintOptions from "./PrintOptions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  printOptionsContainer: {
    marginLeft: "90px",
  },
  newOptionButtonContainer: {
    marginLeft: "80px",
  },
}));

/**
 * Renders the options using the PrintOptions component and a button to add options to the question.
 * Only used if the question is SELECTIONGROUP or MULTISELECTIONGROUP.
 * @param {handleOpenOptionMenu: func, formTemplate: obj, activeStep: number, handleDialogOpen: func} param
 * props of the selectiongroup
 */
export default function SelectionGroup({
  handleOpenOptionMenu,
  formTemplate,
  activeStep,
  handleDialogOpen,
  isDefault,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid item className={classes.printOptionsContainer}>
        <PrintOptions
          formTemplate={formTemplate}
          activeStep={activeStep}
          handleDialogOpen={handleDialogOpen}
          isDefault={isDefault}
        />
      </Grid>
      {!isDefault && (
        <Grid item>
          <Grid
            container
            direction="column"
            className={classes.newOptionButtonContainer}
          >
            <IconTooltipButton
              title={language.form.newOption}
              onClick={handleOpenOptionMenu}
            >
              <AddIcon />
            </IconTooltipButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
