import Request from "../../../helpers/Request";

export const getUsers = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-company/" + companyId,
    null
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getKKDTasks = async () => {
  const params = {
    type: "EKİPSEL",
  };
  const resp = await Request("get", "/api/kkd-tasks/all/", null, params);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let task = {};
    task["taskName"] = data[i].taskName;
    task["id"] = data[i].id;
    arr.push(task);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions() {
  const users = [];
  const departments = [];
  const [companies, kkdTasks] = await Promise.all([
    getCompanies(),
    getKKDTasks(),
  ]);
  const options = { users, departments, companies, kkdTasks };
  return options;
}
