import React, { useCallback } from "react";
import moment from "moment";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import Table from "../../../../components/Table/Table";
import { examType, trueFalse } from "../../../../assets/constants";
import FetchData from "./FetchData";

export default function HealthInspections({
  id,
  userProps,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();

  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [filterProps] = React.useState({
    user: id,
  });

  const init = useCallback(async () => {
    setLoading(true);
    setColumns([
      {
        field: "examDate",
        title: language.healthReport.examinationTime,
        render: (rowData) => moment(rowData.examDate).format("DD-MM-YYYY"),
      },
      {
        title: language.healthReport.examinationType,
        field: "examType",
        lookup: examType,
      },
      { title: language.healthReport.company, field: "companyName" },
      { title: language.healthReport.department, field: "departmentName" },
      {
        title: language.healthReport.workAtHeight,
        field: "workAtHeight",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workAtNight,
        field: "workAtNight",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workInShift,
        field: "workInShift",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workInDangerousWorkplace,
        field: "workInDangerousWorkplace",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workInVeryDangerousWorkplace,
        field: "workInVeryDangerousWorkplace",
        lookup: trueFalse,
      },
      {
        title: language.healthReport.workWithScreenedVehicle,
        field: "workWithScreenedVehicle",
        lookup: trueFalse,
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={loading} />
      <Table
        noRowActions
        tableRef={tableRef}
        authName="usersTable"
        tableName={language.healthReport.tableName}
        columns={columns}
        filters={filterProps}
        fetchData={FetchData}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        height={window.innerHeight > 900 ? "57vh" : "47vh"}
        headerComponents={
          <h4>
            Sonraki Muayene Tarihi:
            {userProps.nextPeriodicExaminationDate
              ? ` ${moment(
                  userProps.nextPeriodicExaminationDate.replaceAll("Z", "")
                ).format("DD-MM-YYYY HH:mm")}`
              : " Yok"}
          </h4>
        }
      />
    </>
  );
}
