import LanguageHelper from '../../../helpers/LanguageHelper';
import Request from '../../../helpers/Request';
import moment from 'moment';

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc" 
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "inspectionId") { params.sort = "inspection.id," + query.orderDirection; }
      else if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "userId") { params.sort = "inspection.user.id," + query.orderDirection; }
      else if (query.orderBy.field === "fullName") { params.sort = "inspection.user.fName,inspection.user.lName," + query.orderDirection; }
      else if (query.orderBy.field === "templateTitle") { params.sort = "formTemplate.title," + query.orderDirection; }
      else if (query.orderBy.field === "submissionDate") { params.sort = "submissionDate," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/forms", null, params);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          inspectionId: data[i].inspectionId,
          userId: data[i].user.id,
          fullName: data[i].user.fullName,
          templateId: data[i].templateId,
          templateTitle: data[i].templateTitle,
          submissionDate: data[i].submissionDate && (moment(data[i].submissionDate)).format("DD-MM-YYYY")
        };
        temp.push(edit);
      }
      setNumOfEntries(resp.data.totalElements);
      resolve({
        data: temp,
        page: resp.data.pageable.pageNumber,
        totalCount: resp.data.totalElements,
      })
    }
  })
  return data;
}