import React, { useCallback } from "react";
import ModalForm from "./ModalForm";
import TableEntryModal from "../../components/Modal/TableEntryModal";

export default function JobRiskAnalysisModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldNoticeProps,
  setOldNoticeProps,
  modalLoading,
}) {
  const defaultProps = {
    type: null,
    text: null,
  };

  const [noticeProps, setNoticeProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldNoticeProps) {
      setNoticeProps({
        type: oldNoticeProps.type || null,
        text: oldNoticeProps.text || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldNoticeProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setNoticeProps(defaultProps);
      setOldNoticeProps(null);
    }, 300);
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          noticeProps={noticeProps}
          setNoticeProps={setNoticeProps}
          label={oldNoticeProps ? "GÜNCELLE" : "OLUŞTUR"}
          loading={loading}
          setLoading={setLoading}
          handleCloseModal={handleCloseModal}
          oldNoticeProps={oldNoticeProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </>
  );
}
