import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestVisitTypeProps,
  visitTypeProps,
  setRequestVisitTypeProps,
  submitNewVisitType,
  updateVisitType,
  loading,
  options,
}) {
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={language.visitTypes.name}
        value={requestVisitTypeProps.name}
        onChangeFunc={(value) =>
          setRequestVisitTypeProps({
            ...requestVisitTypeProps,
            name: value.target.value,
          })
        }
        style={classes.textField}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.equipments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={requestVisitTypeProps.equipmentIds}
        onChange={(event, value) => {
          setRequestVisitTypeProps({
            ...requestVisitTypeProps,
            equipmentIds: value,
          });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitTypes.equipments}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.documents?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={requestVisitTypeProps.documentIds}
        onChange={(event, value) => {
          setRequestVisitTypeProps({
            ...requestVisitTypeProps,
            documentIds: value,
          });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitTypes.documents}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.videos?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={requestVisitTypeProps.videoIds}
        onChange={(event, value) => {
          setRequestVisitTypeProps({
            ...requestVisitTypeProps,
            videoIds: value,
          });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitTypes.videos}
            className={classes.textField}
          />
        )}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              visitTypeProps ? updateVisitType() : submitNewVisitType()
            }
            className={classes.submit}
          >
            {visitTypeProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
