import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SessionHelper from '../../../helpers/SessionHelper';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { List, ListItem, ListItemText, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import CustomSnackbar from '../../../components/Snackbar/Snackbar';
import StatusColumn from '../../unsuitpage/components/StatusColumn';
import { taskStatuses } from '../../../assets/constants';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  column: {
    fontSize: 14,
    fontColor: "darkblue"
  }
}));

export default function UnsuitData({data}) {
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const language = LanguageHelper.getLanguage();

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  const jsonMap = (json) => {
    return (
      json && Object.keys(json).map((data) => (
        <MenuItem key={data} value={data}>
          {json[data]}
        </MenuItem>
      ))
    )
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{language.tableColumns.id}</TableCell>
              <TableCell align="left">{"Uygunsuzluk Giderici Kişi"}</TableCell>
              <TableCell align="left">{"Denetlenen Kişiler"}</TableCell>
              <TableCell align="left">{"Denetim Yapan Kişi"}</TableCell>
              <TableCell align="left">{"Onaylayan Kişi"}</TableCell>
              <TableCell align="left">{"Şirket"}</TableCell>
              <TableCell align="left">{"Bölüm"}</TableCell>
              <TableCell align="left">{"Şehir"}</TableCell>
              <TableCell align="left">{"Faaliyet Bölgesi"}</TableCell>
              <TableCell align="left">{"Faaliyet Tipi"}</TableCell>
              <TableCell align="left">{"Termin Tarihi"}</TableCell>
              <TableCell align="left">{"Tamamlanma Tarihi"}</TableCell>
              <TableCell align="left">{"Onaylanma Tarihi"}</TableCell>
              <TableCell align="left">{language.tableColumns.status}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell align="left">{data.id}</TableCell>
              <TableCell align="left">{data.user?.fName + " " + data.user?.lName}</TableCell>
              <TableCell align="left">
                <>
                  {data.supervisedUsers && data?.supervisedUsers.length > 0 ?
                  <List style={{
                    width: 250,
                    height: 100,
                    overflow: "auto"
                  }}>
                    {
                      data?.supervisedUsers.map((data, index) => (
                        <ListItem key={index}>
                          <ListItemText secondary={data.fName + " " + data.lName}/>
                        </ListItem>
                      ))
                    }
                  </List>
                  :
                  "Yok"}
                </>
              </TableCell>
              <TableCell align="left">{data.inspectionUser ? (data.inspectionUser?.fName + " " + data.inspectionUser?.lName) : "Yok"}</TableCell>
              <TableCell align="left">{data.approvedBy ? (data.approvedBy?.fName + " " + data.approvedBy?.lName) : "Yok"}</TableCell>
              <TableCell align="left">{data.company?.name}</TableCell>
              <TableCell align="left">{data.department?.name}</TableCell>
              <TableCell align="left">{data.cityTown?.belongingCity?.name}</TableCell>
              <TableCell align="left">{data.cityTown?.name}</TableCell>
              <TableCell align="left">{data.department?.fieldOfActivity}</TableCell>
              <TableCell align="left">{data.terminationTime ? (new moment(data.terminationTime)).format("DD-MM-YYYY HH:mm:ss") : "Yok"}</TableCell>
              <TableCell align="left">{data.completionTime ? (new moment(data.completionTime)).format("DD-MM-YYYY HH:mm:ss") : "Tamamlanmadı"}</TableCell>
              <TableCell align="left">{data.approvmentDate ? (new moment(data.approvmentDate)).format("DD-MM-YYYY HH:mm:ss") : "Onaylanmadı"}</TableCell>
              <TableCell align="left">
                <StatusColumn
                  rowData={data}
                  roles={roles}
                  jsonMap={jsonMap}
                  statuses={taskStatuses} 
                  setSeverity={setSeverity}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbar={setSnackbar}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) 
}