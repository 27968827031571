import Request from "../../helpers/Request";
import { taskStatuses } from "../../assets/constants";
import SessionHelper from "../../helpers/SessionHelper";

export const getConsumptionUnits = async () => {
  const resp = await Request("get", "/api/unit-management/units");
  const arr = resp.data.content;
  let units = {};
  for (let i = 0; i < arr.length; i++) {
    units[arr[i].id] = arr[i].name;
  }
  return units;
};

export const getConsumptionTypes = async () => {
  const resp = await Request(
    "get",
    "/api/consumption-management/consumption-type/get-all"
  );
  const data = resp.data.content;
  let obj = {};
  for (let i = 0; i < data.length; i++) {
    obj[data[i].id] = data[i].name;
  }
  return obj;
};

export const getUsers = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-company/" + companyId,
    null
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getInstructors = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/training/instructors/" + companyId,
    null
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getCities = async () => {
  const resp = await Request("get", "/api/training/record/city-list/");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let city = {};
    city["name"] = data[i].name;
    city["id"] = data[i].id;
    arr.push(city);
  }
  return arr;
};

export const getTowns = async (cityId) => {
  const resp = await Request("get", "/api/training/record/town-list/" + cityId);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] = data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/training/record/company-list");
  return resp.data.map((record) => ({
    name: record.name,
    id: record.id,
  }));
};

export const getDepartments = async (companyId) => {
  const { data } = await Request(
    "get",
    "/api/training/record/department-list/" + companyId
  );
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

const convertToArray = (obj) => {
  let arr = [];
  for (let key in obj) {
    arr.push({ id: key, name: obj[key] });
  }
  return arr;
};

export async function GetOptions(isCatalogue, companyId, cityId) {
  const user = SessionHelper.getUser();
  const status = { ...taskStatuses };
  delete status.APPROVED;
  delete status.REJECTED;
  let consumptionUnits;
  let cities;
  let companies;
  let departments;
  let users;
  let instructors;
  let towns;
  let consumptionTypes;
  [consumptionUnits, companies, departments, consumptionTypes] =
    await Promise.all([
      getConsumptionUnits(),
      getCompanies(),
      getDepartments(companyId || user?.companyId),
      getConsumptionTypes(),
    ]);
  if (!isCatalogue) {
    [cities, users, instructors] = await Promise.all([
      getCities(),
      getUsers(companyId || user?.companyId),
      getInstructors(companyId || user?.companyId),
    ]);
    cityId && (towns = await getTowns(cityId));
  }
  const consumptionTypesArray = convertToArray(consumptionTypes);
  const options = {
    status,
    consumptionUnits,
    consumptionTypes,
    consumptionTypesArray,
    cities,
    towns,
    companies,
    departments,
    users,
    instructors,
  };
  return options;
}
