import Request from "../../helpers/Request";
import LanguageHelper from "../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.id,
      department: filterProps.department?.id,
      endTime: filterProps.endTime,
      startTime: filterProps.startTime,
      name: filterProps.name,
      surname: filterProps.surname,
      shoe: filterProps.shoe,
      vest: filterProps.vest,
      jacket: filterProps.jacket,
      trouser: filterProps.trouser,
      shirt: filterProps.shirt,
    };

    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") {
        params.sort = "date," + query.orderDirection;
      } else if (query.orderBy.field === "level") {
        params.sort = "level," + query.orderDirection;
      } else if (query.orderBy.field === "message") {
        params.sort = "message," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }

    const resp = await Request(
      "get",
      "/api/users/personnel-files/clothes-reports",
      null,
      params
    );
    console.log(resp.data);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          id: content[i]?.id,
          tcNo: content[i]?.tcNo,
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          title:
            content[i].title !== null ? content[i].title : language.etc.empty,
          name: content[i]?.name,
          surname: content[i]?.surname,
          educationStatus: content[i]?.educationStatus,
          shoeSize:
            content[i]?.props?.shoeSize !== null
              ? content[i]?.props?.shoeSize
              : language.etc.empty,
          trouserSize:
            content[i]?.props?.trouserSize !== null
              ? content[i].props.trouserSize
              : language.etc.empty,
          jacketSize:
            content[i]?.props?.jacketSize !== null
              ? content[i].props.jacketSize
              : language.etc.empty,
          vestSize:
            content[i]?.props?.vestSize !== null
              ? content[i].props.vestSize
              : language.etc.empty,
          shirtSize:
            content[i]?.props?.shirtSize !== null
              ? content[i].props.shirtSize
              : language.etc.empty,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      });
    }
  });
  return data;
}
