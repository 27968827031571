import moment from "moment";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    var params = new URLSearchParams();
    for (let department of filterProps.departmentIds) {
      params.append("departmentIds", department.id);
    }
    for (let region of filterProps.regionIds) {
      params.append("regionIds", region.id);
    }
    for (let month of filterProps.months) {
      params.append("months", new moment(month).format("YYYY-MM-DD"));
    }

    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "year") {
        params.append("sort", "getYear," + query.orderDirection);
      }
      if (query.orderBy.field === "month") {
        params.append("sort", "getMonth," + query.orderDirection);
      }
      if (query.orderBy.field === "company") {
        params.append("sort", "getCompany," + query.orderDirection);
      }
      if (query.orderBy.field === "region") {
        params.append("sort", "getRegion," + query.orderDirection);
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/isg-performance-report/ita-score",
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          month: data[i].month,
          year: data[i].year,
          region: data[i].region,
          itaCount: data[i].itaCount ? data[i].itaCount : 0,
          crewCountForIta: data[i].crewCountForIta
            ? data[i].crewCountForIta
            : 0,
          itaScore: data[i].itaScore ? data[i].itaScore : 0,
          department: data[i].department,
        };
        temp.push(edit);
      }
      setNumOfEntries(temp.length);
      resolve({
        data: temp,
        totalCount: temp.length,
      });
    }
  });
  return data;
}
