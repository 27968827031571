import React, { forwardRef, useCallback } from "react";
import "../../../../App.css";
import SessionHelper from "../../../../helpers/SessionHelper";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../../helpers/AuthorizationHelper";
import Request, { fileUrl } from '../../../../helpers/Request';
import CustomSnackbar from "../../../../components/Snackbar/Snackbar";
import Table from "../../../../components/Table/Table";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import GetOptions from './GetOptions';
import showSnackbar from "../../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../../components/Dialog/DeleteDialog";
import GetFilterFields from './DrillFilters';
import DrillModal from './DrillModal';
import moment from "moment";
import { getUsers, getDepartments } from "./GetOptions";
import GetAppIcon from '@material-ui/icons/GetApp';
import Details from './Details';
import AddBox from "@material-ui/icons/AddBox";
import ActionModal from './ActionModal';

export default function DrillsTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "healthMonitoring");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [dialog, setDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedDrill, setSelectedDrill] = React.useState(null);
  const [actionModalLoading, setActionModalLoading] = React.useState(false);
  const [actionModal, setActionModal] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterCompany: null,
    filterDepartment: null,
    filterCompletionStart: null,
    filterCompletionEnd: null,
    filterPlannedStart: null,
    filterPlannedEnd: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Şirket", field: "companyName" },
      { title: "Bölüm", field: "departmentName" },
      { title: "Planlanma Tarihi", field: "plannedDate", render: 
        rowData => moment(rowData.plannedDate.replaceAll("Z", "")).format("DD-MM-YYYY HH:mm") },
      { title: "Tamamlanma Tarihi", field: "completionDate", render: 
        rowData => moment(rowData.plannedDate.replaceAll("Z", "")).format("DD-MM-YYYY HH:mm") },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    data && setSelectedDrill(data);
    setModalLoading(true);
    setEditModal(true);
    let opts = {...options};
    (data && data.company) ? opts.departments = await getDepartments(data.company) : opts.departments = await getDepartments(user.companyId);
    (data && data.department) ? opts.users = await getUsers(data.department) : opts.users = [];
    setOptions(opts);
    setModalLoading(false);
  }

  const handleOpenActionModal = async (data) => {
    setActionModalLoading(true);
    setActionModal(true);
    let opts = {...options};
    (data && data.department) ? opts.users = await getUsers(data.department) : opts.users = [];
    setOptions(opts);
    setSelectedDrill(data);
    setActionModalLoading(false);
  }

  const handleOpenDeleteDialog = (data) => {
    setSelectedDrill(data);
    setDialog(true);
  }

  const handleCloseDeleteDialog = () => {
    setSelectedDrill(null);
    setDialog(false);
  }

  const removeDrill = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", '/api/emergency/practise' + selectedDrill?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const removeDrills = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join()
    }
    const resp = await Request("delete", '/api/emergency/practise', null, param);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterCompany: null,
      filterDepartment: null,
      filterCompletionStart: null,
      filterCompletionEnd: null,
      filterPlannedStart: null,
      filterPlannedEnd: null,
    });
  }

  const DownloadFile = async (rowData) => {
    if(rowData.practiseFile && rowData.practiseFile.name) {
      const url = fileUrl + rowData.practiseFile.name;
      window.open(url);
    }
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeDrills : (selectedDrill && removeDrill)}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <DrillModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldDrillProps={selectedDrill}
        setOldDrillProps={setSelectedDrill}
        options={options}
        setOptions={setOptions}
        modalLoading={modalLoading}
      />
      <ActionModal
        modalLoading={actionModalLoading}
        modal={actionModal}
        setModal={setActionModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        drillProps={selectedDrill}
        opts={options}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters/>
      <Table
        tableName={"Tatbikatlar"}
        authName={"drills"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        filters={filterProps}
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => <Details rowData={rowData}/>}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ")
        }
        extraAction={{
          auth: true,
          icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
          tooltip: "Tatbikatı İndir",
          position: "row",
          onClick: (rowData) => DownloadFile(rowData)
        }}
        actionModal={{
          auth: authorization.create,
          icon: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          tooltip: "Aksiyon Yarat",
          position: "row",
          onClick: (rowData) => handleOpenActionModal(rowData)
        }}
      />
    </>
  );
}
