import React, { forwardRef, useCallback } from "react";
import "../../../../App.css";
import SessionHelper from "../../../../helpers/SessionHelper";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../../helpers/AuthorizationHelper";
import Request, { fileUrl } from '../../../../helpers/Request';
import CustomSnackbar from "../../../../components/Snackbar/Snackbar";
import Table from "../../../../components/Table/Table";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import GetOptions from './GetOptions';
import showSnackbar from "../../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../../components/Dialog/DeleteDialog";
import GetFilterFields from './EmergencyPlansFilters';
import EmergencyPlanModal from './EmergencyPlanModal';
import moment from "moment";
import { getDepartments } from "./GetOptions";
import GetAppIcon from '@material-ui/icons/GetApp';
import Details from './Details';

export default function EmergencyPlansTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "emergencyPlans");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [dialog, setDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedEmergencyPlan, setSelectedEmergencyPlan] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterDepartment: null,
    filterCompany: null,
    filterPlannedEnd: null,
    filterPlannedStart: null,
    filterValidationEnd: null,
    filterValidationStart: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Şirket", field: "companyName" },
      { title: "Bölüm", field: "departmentName" },
      { title: "Planlanma Tarihi", field: "plannedDate", render: 
        rowData => moment(rowData.plannedDate.replaceAll("Z", "")).format("DD-MM-YYYY HH:mm") },
      { title: "Onaylanma Tarihi", field: "validationDate", render: 
        rowData => moment(rowData.plannedDate.replaceAll("Z", "")).format("DD-MM-YYYY HH:mm") },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    data && setSelectedEmergencyPlan(data);
    setModalLoading(true);
    setEditModal(true);
    let opts = {...options};
    (data && data.company) ? opts.departments = await getDepartments(data.company) : opts.departments = await getDepartments(user.companyId);
    setOptions(opts);
    setModalLoading(false);
  }

  const handleOpenDeleteDialog = (data) => {
    setSelectedEmergencyPlan(data);
    setDialog(true);
  }

  const handleCloseDeleteDialog = () => {
    setSelectedEmergencyPlan(null);
    setDialog(false);
  }

  const removeEmergencyPlan = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", '/api/emergency/plan/' + selectedEmergencyPlan?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const removeEmergencyPlans = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join()
    }
    const resp = await Request("delete", '/api/emergency/plan/', null, param);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterDepartment: null,
      filterCompany: null,
      filterPlannedEnd: null,
      filterPlannedStart: null,
      filterValidationEnd: null,
      filterValidationStart: null,
    });
  }

  const DownloadFile = async (rowData) => {
    if(rowData.planFile && rowData.planFile.name) {
      const url = fileUrl + rowData.planFile.name;
      window.open(url);
    }/*  else {
      setLoading(true);
      const resp = await Request("get", "/api/examination/" + rowData.id + "/report");
      console.log(resp);
      if(resp.status === 200) {
        const fileName = resp.headers["content-disposition"].substring(resp.headers["content-disposition"].indexOf('=') + 1);
        const contentType = fileName.split('.').pop();
        const source = "data:application/" + contentType + ";base64, " + encodeURI(resp.data);
        FileSaver.saveAs(source, fileName);
        // if(contentType === "xlsx") {
        //   const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
        //   FileSaver.saveAs(source, fileName);
        // } else if(contentType === "docx") {
        //   const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
        //   FileSaver.saveAs(source, fileName);
        // } else {

        // }
      }
      setLoading(false);
    } */
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeEmergencyPlans : (selectedEmergencyPlan && removeEmergencyPlan)}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <EmergencyPlanModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldEmergencyPlanProps={selectedEmergencyPlan}
        setOldEmergencyPlanProps={setSelectedEmergencyPlan}
        options={options}
        setOptions={setOptions}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters/>
      <Table
        tableName={"Acil Durum Planları"}
        authName={"emergencyPlans"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        filters={filterProps}
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => <Details rowData={rowData}/>}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ")
        }
        extraAction={{
          auth: true,
          icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
          tooltip: "Planı İndir",
          position: "row",
          onClick: (rowData) => DownloadFile(rowData)
        }}
      />
    </>
  );
}
