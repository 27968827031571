import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import { getDepartments } from "./GetOptions";
import RoleList from "./RoleList";
import moment from "moment";
import jsonMap from "../../../components/Utils/jsonMap";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import { Autocomplete } from "@material-ui/lab";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import FileDropzone from "../../../components/Files/FileDropzone";
import ActivationModal from "./ActivationModal";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

/**
 * The form control component used in the user creation/editing modal.
 * @param {newUserProps: obj, setNewUserProps: func, options: obj[], submitNewUser: func} props of the form control
 */
export default function ModalForm({
  closeUpperModal,
  newUserProps,
  setNewUserProps,
  file,
  setFile,
  length,
  setLength,
  options,
  setOptions,
  submitNewUser,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
  loading,
}) {
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [departments, setDepartments] = React.useState(
    options.departments ? options.departments : []
  );
  const [activationModal, setActivationModal] = React.useState(false);
  const [companyType, setCompanyType] = React.useState("");
  const [userId, setUserId] = React.useState(null);
  /*   const [fileError, setFileError] = React.useState(false); */
  const [emailError, setEmailError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [surnameError, setSurnameError] = React.useState(null);
  const [tcError, setTcError] = React.useState(null);
  const [companyError, setCompanyError] = React.useState(null);
  const [departmentError, setDepartmentError] = React.useState(null);
  const [roleError, setRoleError] = React.useState(null);
  const [phoneError, setPhoneError] = React.useState(null);
  /* const [eduError, setEduError] = React.useState(null); */

  const init = useCallback(async () => {
    if (specialContractor) {
      companyError && setCompanyError(null);
      let newOpts = options;
      newOpts.departments = await getDepartments(user?.company?.id);
      setOptions(newOpts);
      setNewUserProps({ ...newUserProps, companyId: user?.company?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialContractor, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!newUserProps.fName) {
      setNameError(errorMessage);
      error = true;
    }
    if (!newUserProps.lName) {
      setSurnameError(errorMessage);
      error = true;
    }
    if (!newUserProps.tcNo) {
      setTcError(errorMessage);
      error = true;
    }
    if (newUserProps.phoneNo.length < 11) {
      setPhoneError(errorMessage);
      error = true;
    }
    if (!newUserProps.email) {
      setEmailError(errorMessage);
      error = true;
    }
    if (!newUserProps.companyId) {
      setCompanyError(errorMessage);
      error = true;
    }
    if (!newUserProps.departmentId) {
      setDepartmentError(errorMessage);
      error = true;
    }
    /*     if (!newUserProps.educationStatusId) {
      setEduError(errorMessage);
      error = true;
    } */
    if (newUserProps.roles.length === 0) {
      setRoleError(errorMessage);
      error = true;
    }
    /*     if (!file) {
      setFileError(true);
      error = true;
    } */

    if (!error) {
      submitNewUser();
    }
  };

  const openModal = async () => {
    if (specialContractor) {
      let newOpts = { ...options };
      newOpts.departments = await getDepartments(user?.companyId);
      setOptions(newOpts);
    }
    setActivationModal(true);
  };

  const RenderButton = () => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        className={classes.activateButton}
        onClick={openModal}
      >
        KULLANICIYI AKTİVE ET
      </Button>
    );
  };

  return (
    <>
      <ActivationModal
        closeUpperModal={closeUpperModal}
        activationModal={activationModal}
        setActivationModal={setActivationModal}
        userId={userId}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <FormControl required autoComplete="off" className={classes.formControl}>
        <TextArea //EMAIL
          required
          error={emailError}
          label={"E-Posta Adresi"}
          value={newUserProps.email}
          onChangeFunc={(value) => {
            const invalid = ["ş", "ç", "ö", "ğ", "ü", "ı"];
            setNewUserProps({ ...newUserProps, email: value.target.value });
            userId && setUserId(null);
            let re =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
            if (re.test(value.target.value)) {
              if (invalid.some((el) => value.target.value.includes(el))) {
                setEmailError("Lütfen geçerli bir e-posta adresi girin.");
              } else {
                emailError && setEmailError(null);
                Request(
                  "get",
                  "/api/users/is-user-exist?&search=" + value.target.value
                ).then((resp) => {
                  if (resp.data) {
                    if (resp.data.active && resp.data.exist) {
                      setEmailError(
                        "Bu E-Posta adresine sahip bir kullanıcı bulunmaktadır. Kullanıcının eski şirketinden silinmesi için bağlı olduğunuz EDAŞ İSG Uzmanı ile iletişime geçiniz."
                      );
                    } else if (!resp.data.active && resp.data.exist) {
                      setEmailError(
                        "Bu E-Posta adresine sahip inaktif bir kullanıcı bulunmaktadır. Yeniden aktifleştirmek ister misiniz?"
                      );
                      setUserId(resp.data.id);
                    }
                  }
                });
              }
            } else {
              setEmailError("Lütfen geçerli bir e-posta adresi girin.");
            }
          }}
          style={classes.textField}
        />
        {userId && <RenderButton />}
        <TextArea //TC
          required
          label={"TC No"}
          error={tcError}
          shrink={newUserProps.tcNo !== null}
          value={newUserProps.tcNo}
          style={classes.textField}
          onChangeFunc={(value) => {
            const invalid = ["ş", "ç", "ö", "ğ", "ü", "ı"];
            setNewUserProps({ ...newUserProps, tcNo: value.target.value });
            userId && setUserId(null);
            // eslint-disable-next-line no-useless-escape
            let re = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
            if (re.test(value.target.value)) {
              if (invalid.some((el) => value.target.value.includes(el))) {
                setTcError("Lütfen gerçek bir TC kimlik no girin.");
              } else {
                tcError && setTcError(null);
                Request(
                  "get",
                  "/api/users/is-user-exist?&search=" + value.target.value
                ).then((resp) => {
                  if (resp.data) {
                    if (resp.data.active && resp.data.exist) {
                      setTcError(
                        "Bu TC kimlik no'ya sahip bir kullanıcı bulunmaktadır. Kullanıcının eski şirketinden silinmesi için bağlı olduğunuz EDAŞ İSG Uzmanı ile iletişimie geçiniz."
                      );
                    } else if (!resp.data.active && resp.data.exist) {
                      setTcError(
                        "Bu TC kimlik no'ya sahip inaktif bir kullanıcı bulunmaktadır. Yeniden aktifleştirmek ister misiniz?"
                      );
                      setUserId(resp.data.id);
                    }
                  }
                });
              }
            } else {
              setTcError("Lütfen gerçek bir TC kimlik no girin.");
            }
          }}
        />
        {userId && <RenderButton />}
        <TextArea //NAME
          required
          error={nameError}
          label={"Adı"}
          value={newUserProps.fName}
          onChangeFunc={(value) => {
            if (value.target.value.length > 30) {
              setNameError("İsim 30 karakterden uzun olamaz.");
            } else {
              nameError && setNameError(null);
              setNewUserProps({ ...newUserProps, fName: value.target.value });
            }
          }}
          style={classes.textField}
        />
        <TextArea //SURNAME
          required
          error={surnameError}
          label={"Soyadı"}
          value={newUserProps.lName}
          onChangeFunc={(value) => {
            if (value.target.value.length > 30) {
              setSurnameError("İsim 30 karakterden uzun olamaz.");
            } else {
              surnameError && setSurnameError(null);
              setNewUserProps({ ...newUserProps, lName: value.target.value });
            }
          }}
          style={classes.textField}
        />
        {!specialContractor && (
          <Autocomplete //COMPANIES
            required
            id="combo-box"
            options={options.companies?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              newUserProps.companyId
                ? getFromId(newUserProps.companyId, options.companies)
                : null
            }
            onChange={async (event, value) => {
              companyError && setCompanyError(null);
              setDepartments(await getDepartments(value?.id));
              setCompanyType(value?.type);
              setNewUserProps({
                ...newUserProps,
                companyId: value?.id,
                companyName: value?.nameShort,
                roles: [],
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={companyError}
                required
                label="Şirketi"
                className={classes.textField}
              />
            )}
          />
        )}
        <Autocomplete //DEPARTMENT
          required
          id="combo-box"
          options={departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          disabled={!newUserProps.companyId}
          fullWidth={true}
          value={
            newUserProps.departmentId
              ? getFromId(newUserProps.departmentId, departments)
              : null
          }
          onChange={async (event, value) => {
            departmentError && setDepartmentError(null);
            setNewUserProps({ ...newUserProps, departmentId: value?.id });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={departmentError}
              required
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
        {newUserProps.roles && !specialContractor && (
          <RoleList //ROLES
            newUserProps={newUserProps}
            setNewUserProps={setNewUserProps}
            options={options}
            roleError={roleError}
            setRoleError={setRoleError}
            companyType={companyType}
            companies={options.companies}
          />
        )}
        <TextSelector //EDUCATION
          label={"Eğitim Durumu"}
          /*           required
          error={eduError} */
          value={newUserProps.educationStatusId}
          shrink={newUserProps.educationStatusId !== null}
          style={classes.textField}
          onChangeFunc={(value) =>
            setNewUserProps({
              ...newUserProps,
              educationStatusId: value.target.value,
            })
          }
          options={jsonMap("edu", options)}
        />
        <TextSelector //APPUSER
          label={"Uygulama Kullanıcısı"}
          value={newUserProps.isAppUser}
          shrink={newUserProps.isAppUser !== null}
          style={classes.textField}
          onChangeFunc={(value) => {
            setNewUserProps({ ...newUserProps, isAppUser: value.target.value });
          }}
          options={jsonMap("isg", options)}
        />
        <TextArea //PHONE NO
          required
          error={phoneError}
          label={"Telefon No"}
          value={newUserProps.phoneNo}
          defaultValue={newUserProps.phoneNo}
          onChangeFunc={(value) => {
            if (value.target.value.substring(0, 2) !== "05") {
              setPhoneError("Telefon No ilk 2 rakamı 05 olmalıdır.");
            } else {
              if (value.target.value.length <= 11) {
                phoneError && setPhoneError(null);
                setNewUserProps({
                  ...newUserProps,
                  phoneNo: value.target.value,
                });
              }
            }
          }}
          style={classes.textField}
        />
        <CustomDatePicker //START DATE
          style={classes.dateField}
          label={"İşe Başlama Tarihi"}
          value={newUserProps.startDateOfWork}
          shrink={newUserProps.startDateOfWork}
          onChangeFunc={(value) =>
            setNewUserProps({
              ...newUserProps,
              startDateOfWork: moment(value)
                .set("hour", 12)
                .set("minute", 0)
                .toISOString(true),
            })
          }
        />
        <FileDropzone
          files={file}
          setFiles={setFile}
          fileName={"sgkIn"}
          length={length}
          setLength={setLength}
          /*           fileError={fileError}
          setFileError={setFileError} */
          dropzoneText={
            "SGK işe giriş bildirgesini yüklemek için sürükleyin ya da buraya tıklayın."
          }
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {"OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </>
  );
}
