import React from "react";
import { Autocomplete } from "@material-ui/lab";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import { TextArea } from "../../../components/Fields/TextFields";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import { getDepartments } from "./GetOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  // filterCompanyId
  // filterDepartmentId
  // filterStatus
  // filterPlannedStart
  // filterPlannedEnd
  // filterCompletionStart
  // filterCompletionEnd

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete //COMPANY
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.filterCompany}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (!value) {
                    options.departments = [];
                  } else {
                    options.departments = await getDepartments(value?.id);
                  }
                  setTableFilters({ ...tableFilters, filterCompany: value });
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.filterCompany}
                value={tableFilters.filterDepartment}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (value === null) {
                    setTableFilters({
                      ...tableFilters,
                      filterDepartment: null,
                    });
                  } else {
                    setTableFilters({
                      ...tableFilters,
                      filterDepartment: value,
                    });
                  }
                  setBackdropLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.filterName !== null}
                label={"Ekip Adı"}
                value={tableFilters.filterName}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    filterName: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
