import React, { forwardRef, useCallback } from "react";
import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import GetOptions, {
  getSGKofCompany,
  getUsers,
  getActionUsers,
  getDepartments,
} from "./GetOptions";
import NewAccidentModal from "./NewAccidentModal";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import AccidentPersonnelDetails from "./AccidentPersonnelDetails";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { makeStyles } from "@material-ui/core";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import SessionHelper from "../../../helpers/SessionHelper";
import AddBox from "@material-ui/icons/AddBox";
import GetFilterFields from "./MainWorkAccidentFilters";
import ActionModal from "./ActionModal";
import { severities } from "../../../assets/constants";

const useStyles = makeStyles(() => ({
  text: {
    maxHeight: 100,
    width: 200,
    overflowY: "scroll",
    alignItems: "center",
  },
  detailsContainer: {
    backgroundColor: "#2D3446",
    color: "white",
    alignItems: "center",
    textAlign: "center",
    padding: 10,
  },
}));

const textStyle = {
  maxHeight: 100,
  width: 200,
  overflowY: "scroll",
  alignItems: "center",
};

const detailStyle = {
  backgroundColor: "#2D3446",
  color: "white",
  alignItems: "center",
  textAlign: "center",
  padding: 10,
};

/**
 * A table component that lists the work accidents. The details panel contains the files of
 * the accidents.
 */
export default function MainWorkAccidentTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "workAccidentTable");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [oldAccident, setOldAccident] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [createActionModal, setCreateActionModal] = React.useState(false);
  const [selectedAccident, setSelectedAccident] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    startTime: null,
    endTime: null,
    department: null,
    company: null,
    region: null,
    city: null,
    town: null,
  });

  // incidentUsers: data[i].incidentUsers,

  const init = useCallback(async () => {
    let options = await GetOptions(language);
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Kaza No", field: "accidentId" },
      { title: "Kazazede Sayısı", field: "numOfCasualties", sorting: false },
      { title: "Sorumlu Şirket", field: "responsibleCompanyName" },
      { title: "Dağıtım Şirketi", field: "edcCompanyName" },
      { title: "Şirket SGK No", field: "companySGKSicilNo" },
      { title: "Bölüm", field: "departmentName" },
      { title: "Şehir", field: "cityName" },
      { title: "İlçe", field: "townName" },
      { title: "Bildiren", field: "reporter" },
      {
        title: "Kaza/Olay Türü",
        field: "accidentDefinition",
        lookup: options.accidentDefinitions,
      },
      { title: "Kaza Tarihi", field: "accidentDateFormatted" },
      { title: "Kaza Saati", field: "accidentTimeFormatted" },
      {
        title: "Kaza/Olay Şekli",
        field: "accidentType",
        lookup: options.accidentTypes,
      },
      {
        title: "Kazanın Yeri",
        field: "accidentPlace",
        lookup: options.places,
      },
      {
        title: "EPDK Kaza Tipi",
        field: "accidentTypeEPDK",
        lookup: options.accidentTypesEPDK,
      },
      {
        title: "Ciddiyet",
        field: "severity",
        lookup: severities,
      },
      { title: "Kayıp İş Günü", field: "totalLostTime", sorting: false },
      {
        title: "Kaza Özeti",
        field: "summary",
        render: (rowData) => <div style={textStyle}>{rowData.summary}</div>,
      },
      // {
      //   title: "Görünen Sebepler",
      //   field: "visibleCauses",
      //   render: (rowData) => (
      //     <div style={textStyle}>{rowData.visibleCauses}</div>
      //   ),
      // },
      {
        title: "Alınan Anlık Aksiyonlar",
        field: "instantAction",
        render: (rowData) => (
          <div style={textStyle}>{rowData.instantAction}</div>
        ),
      },
    ]);
    setBackdropLoading(false);
  }, [language, classes]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenDeleteDialog = (data) => {
    setSelectedAccident(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedAccident(null);
    setDialog(false);
  };

  /**
   * Request to delete the work accident from the table.
   */
  const deleteEntry = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/industrial-accidents/" + selectedAccident?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    setModal(true);
    if (data) {
      let opts = options;
      [opts.users, opts.sgkNos] = await Promise.all([
        getUsers(data.responsibleCompanyId, false),
        getSGKofCompany(data.responsibleCompanyId),
      ]);
      setOptions({ ...options, opts });
    }
    let opts = { ...options };
    data && data.responsibleCompanyId
      ? (opts.departments = await getDepartments(data.responsibleCompanyId))
      : (opts.departments = await getDepartments(user.companyId));
    setOptions(opts);
    setOldAccident(data);
    setModalLoading(false);
  };

  const actionModal = async (data) => {
    setModalLoading(true);
    setCreateActionModal(true);
    const users = await getActionUsers();
    setOptions({ ...options, actionUsers: users });
    setSelectedAccident(data);
    setCreateActionModal(true);
    setModalLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      startTime: null,
      endTime: null,
      department: null,
      company: null,
      region: null,
      city: null,
      town: null,
    });
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteEntry}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <NewAccidentModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        modalLoading={modalLoading}
        tableRef={tableRef}
        oldAccidentProps={oldAccident}
        opts={options}
      />
      <ActionModal
        modalLoading={modalLoading}
        modal={createActionModal}
        setModal={setCreateActionModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        opts={options}
        accidentProps={selectedAccident}
      />
      <RenderFilters />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Table
        tableName={language.sidebar.workaccidents_main}
        authName="workAccidentTable"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        //deleteEntry={deleteEntry}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        rowStyle={(rowData) => ({
          backgroundColor: !rowData.active && "#804f4f",
        })}
        isDeletable={(user, rowData) => rowData.active === true}
        detailsPanel={(rowData) => (
          <div style={detailStyle}>
            <AccidentPersonnelDetails
              rowData={rowData}
              tableRef={tableRef}
              options={options}
            />
          </div>
        )}
        extraAction={{
          auth: authorization.create,
          icon: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          tooltip: "Aksiyon Yarat",
          position: "row",
          onClick: (rowData) => actionModal(rowData),
        }}
      />
    </>
  );
}
