import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import CustomFileDropzone from "../../../../../components/Files/CustomFileDropzone";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    textAlign: "left",
    padding: 10,
    marginBottom: 10
  },
}));

export default function KKDDebt({files, setFiles, setChangeMadeToFiles}) {
  const classes = useStyles();

  return(
    <>
      <h2>Talimatlar</h2>
      <Grid container className={classes.container}>
        <Grid item xs={12}>
          <CustomFileDropzone
            files={files.kkdZimmets}
            setFiles={(value) => {
              setFiles({...files, kkdZimmets: value})
              setChangeMadeToFiles(true);
            }}
            fileLimit={100}
            dropzoneText={"Talimat belgelerini yüklemek için sürükleyin ya da buraya tıklayın."}
          />
        </Grid>
      </Grid>
    </>
  )
}