import React from "react";
import Grid from "@material-ui/core/Grid";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SelectionGroup from "./SelectionGroup";
import TextInput from "./TextInput";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
    textAlign: "center",
  },
  container: {
    display: "flex",
    direction: "row",
    margin: "2rem 2rem 0",
    justifyContent: "space-between",
  },
}));

/**
 * Renders the option part of the details panel.
 * New options can be added to an existing question
 * and existing options can be deleted from the question.
 * @param {formTemplate: obj,  handleOpenOptionMenu: func, activeStep: number,handleDialogOpen: func} param
 * props of the content component
 */
export default function Content({
  formTemplate,
  handleOpenOptionMenu,
  activeStep,
  handleDialogOpen,
  isDefault,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <Grid>
      {formTemplate &&
        formTemplate.questions[activeStep]?.questionType ===
          "SELECTIONGROUP" && (
          <SelectionGroup
            handleOpenOptionMenu={handleOpenOptionMenu}
            formTemplate={formTemplate}
            activeStep={activeStep}
            handleDialogOpen={handleDialogOpen}
            isDefault={isDefault}
          />
        )}
      {formTemplate &&
        formTemplate.questions[activeStep]?.questionType ===
          "MULTIPLESELECTIONGROUP" && (
          <SelectionGroup
            handleOpenOptionMenu={handleOpenOptionMenu}
            formTemplate={formTemplate}
            activeStep={activeStep}
            handleDialogOpen={handleDialogOpen}
            isDefault={isDefault}
          />
        )}
      {formTemplate &&
        formTemplate.questions[activeStep]?.questionType === "TEXTINPUT" && (
          <TextInput />
        )}
    </Grid>
  );
}
