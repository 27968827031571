import Request from "../../../../helpers/Request";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      department: filterProps.department?.id,
      endTime: filterProps.endTime,
      startTime: filterProps.startTime,
      user: filterProps.user?.id,
      type: filterProps.type,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "user.company.name," + query.orderDirection;
      } else if (query.orderBy.field === "departmentName") {
        params.sort = "user.department.name," + query.orderDirection;
      } else if (query.orderBy.field === "personnelName") {
        params.sort = "user.fName," + query.orderDirection;
      } else if (query.orderBy.field === "personnelSurname") {
        params.sort = "user.lName," + query.orderDirection;
      } else if (query.orderBy.field === "examDate") {
        params.sort = "examinationTime," + query.orderDirection;
      } else if (query.orderBy.field === "examType") {
        params.sort = "examinationType," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/examination/", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          personnel: data[i].userId,
          personnelName: data[i].staffName,
          personnelSurname: data[i].staffSurname,
          company: data[i].companyId,
          companyName: data[i].companyName,
          department: data[i].departmentId,
          departmentName: data[i].departmentName,
          examType: data[i].examinationType,
          examDate: data[i].examinationDate,
          examinationFile: data[i].examinationFile
            ? new File(
                [data[i].examinationFile.fileName],
                data[i].examinationFile.fileName,
                { type: "text/plain" }
              )
            : null,
          aracKullanimi: data[i].ibys?.aracKullanimi,
          calisanMeslegi: data[i].ibys?.calisanMeslegi,
          calismaPozisyonu: data[i].ibys?.calismaPozisyonu,
          idrarTetkikiYapildiMi: data[i].ibys?.idrarTetkikiYapildiMi
            ? data[i].ibys?.idrarTetkikiYapildiMi
            : false,
          isitmeTestiYapildiMi: data[i].ibys?.isitmeTestiYapildiMi
            ? data[i].ibys?.isitmeTestiYapildiMi
            : false,
          kanTetkikiYapildiMi: data[i].ibys?.kanTetkikiYapildiMi
            ? data[i].ibys?.kanTetkikiYapildiMi
            : false,
          rontgenYapildiMi: data[i].ibys?.rontgenYapildiMi
            ? data[i].ibys?.rontgenYapildiMi
            : false,
          solunumFonkTestiYapildiMi: data[i].ibys?.solunumFonkTestiYapildiMi
            ? data[i].ibys?.solunumFonkTestiYapildiMi
            : false,
          raporGecerlikTarihi: data[i].ibys?.raporGecerlikTarihi,
          raporTuru: data[i].ibys?.raporTuru,
          sonucVeKanaat: data[i].ibys?.sonucVeKanaat,
          calismaZamani: data[i].ibys?.calismaZamani,
          biyolojikEtkenlerSinifi: data[i].ibys?.biyolojikEtkenlerSinifi,
          elektrikSinifi: data[i].ibys?.elektrikSinifi,
          fizikiOrtamSinifi: data[i].ibys?.fizikiOrtamSinifi,
          gurultuSinifi: data[i].ibys?.gurultuSinifi,
          havaSinifi: data[i].ibys?.havaSinifi,
          kimyasalMaddeSinifi: data[i].ibys?.kimyasalMaddeSinifi,
          tozYonetmeligiSinifi: data[i].ibys?.tozYonetmeligiSinifi,
          isEkipmanlari: data[i].ibys?.isEkipmanlari,
          bildirimNo: data[i].ibys?.bildirimNo,
          gonderimDurumu: data[i].ibys?.bildirimNo
            ? data[i].ibys?.bildirimNo == "!"
              ? "Başarısız"
              : "Başarılı"
            : "Gönderilmedi",
        };
        edit.files = [];
        for (let file of data[i]?.files) {
          edit.files.push(
            new File([file.fileName], file.fileName, { type: "text/plain" })
          );
        }
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
