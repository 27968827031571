import React from "react";
import TaskDetails from "./components/TaskDetails";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useLocation } from "react-router-dom";
import { tableContainerStyles } from "../../../assets/styles/tableContainer";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import UnauthorizedPage from "../../UnauthorizedPage";

export default function TaskDetailsPage() {
  const location = useLocation();
  const id = location.pathname.substring(6);

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Grid>
        {true ? (
          <TaskDetails
            id={id}
            setSnackbar={setSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSeverity={setSeverity}
          />
        ) : (
          <UnauthorizedPage />
        )}
      </Grid>
    </Container>
  );
}
