import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import { TextArea } from '../../../../../components/Fields/TextFields';
import CustomFileDropzone from "../../../../../components/Files/CustomFileDropzone";
import { CustomDatePicker } from "../../../../../components/Selectors/DatePickers";
import AltTable from '../../../../../components/Table/AltTable';
import moment from 'moment';
import IconTooltipButton from '../../../../../components/Buttons/IconTooltipButton';
import DoneIcon from '@material-ui/icons/Done';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    overflowY: "auto",
    maxHeight: "700px",
    margin: "0 auto",
    textAlign: "left",
    padding: 10,
    marginBottom: 10
  },
  formControl: {
    width: "100%",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  dropzone: {
    height: "100%",
    width: "18vw"
  }
}));

export default function EKAT({files, setFiles, fileProps, setFileProps, errors, setErrors, setChangeMadeToFiles}) {
  const classes = useStyles();
  const fileExists = files.ekatFile?.length > 0;

  const titles = ["Alınan Tarih", "Geçerlilik Tarihi", "Geçerlilik Süresi", "Dosya"];
  const [rows, setRows] = React.useState([]);
  
  const init = useCallback(async () => {
    let rows = [];
    rows.push([
      <RenderTakenDate key={"empty_taken_date"} />,
      <RenderValidityDate key={"empty_validity_date"} />,
      <RenderPeriodOfValidity key={"empty_period"} />,
      <RenderDropzone key={"empty_dropzone"} />,
    ]);
    if(fileExists) { 
      for(let i = files.ekatFile.length - 1; i >= 0; i--) {
        const diff = fileProps.ekatRequestList[i]?.ekatValidityDate
          ? getDifferenceBetweenDates(moment(), moment(fileProps.ekatRequestList[i].ekatValidityDate))
          : -1;
        const expired = diff < 0 ? false : true;
        rows.push([
          <RenderTakenDate index={i} key={files.ekatFile[i].name + "_taken_date"} />,
          <RenderValidityDate index={i} key={files.ekatFile[i].name + "_validity_date"} />,
          <RenderPeriodOfValidity index={i} key={files.ekatFile[i].name + "_period"} />,
          <RenderDropzone index={i} key={files.ekatFile[i].name + "_dropzone"} />,
          { backgroundColor: expired && "#805f4f" },
        ]);
      }
    }
    await setRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.ekatRequestList, errors]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getDifferenceBetweenDates = (date1, date2) => {
    date1 = moment(date1, 'DD-MM-YYYY HH:mm:ss');
    date2 = moment(date2, 'DD-MM-YYYY HH:mm:ss');
    let duration = moment.duration(date1.diff(date2));
    let hours = duration.asHours();
    return hours;
  }

  const RenderDropzone = useCallback(({index}) => {
    return(
      <div className={classes.dropzone}>
        <CustomFileDropzone
          files={index !== undefined ? [files.ekatFile[index]] : []}
          setFiles={async (value) => {
            // If file added to dropzone, create the errors for it
            if(value[0] && !errors.ekatErrors[files.ekatFile.length]) {
              let temp = [...errors.ekatErrors];
              temp[files.ekatFile.length] = {
                ekatTakenDate: null,
                ekatValidityDate: null,
                ekatPeriodOfValidity: null
              }
              await setErrors({...errors, ekatErrors: temp});
            } 

            // If file added to dropzone, create the file props for it
            if(value[0] && !fileProps.ekatRequestList[files.ekatFile.length]) {
              let temp = [...fileProps.ekatRequestList];
              temp[files.ekatFile.length] = {
                ekatTakenDate: null,
                ekatValidityDate: null,
                ekatPeriodOfValidity: null
              }
              await setFileProps({...fileProps, ekatRequestList: temp});
            }

            // If file added to dropzone, push the new value to the file array
            // If file deleted from dropzone, remove the file from that index
            let temp = [...files.ekatFile];
            value[0] ? temp.push(value[0]) : temp.splice(index, 1);
            await setFiles({...files, ekatFile: temp});

            // If file deleted from dropzone, also remove the file props and errors from that index
            if(!value[0]) {
              let temp = fileProps?.ekatRequestList;
              temp.splice(index, 1);
              await setFileProps({...fileProps, ekatRequestList: temp});
              temp = errors.ekatErrors;
              temp.splice(index, 1);
              await setErrors({...errors, ekatErrors: temp});
            }
            setChangeMadeToFiles(true);
          }}
          index={index}
          fileLimit={1}
          onlyShowTitleWhenEmpty
          onChange={clearProps}
          dropzoneText={"EKAT belgesini yüklemek için sürükleyin ya da buraya tıklayın."}
        />
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, fileProps.ekatRequestList, errors.ekatErrors]);

  const RenderTakenDate = ({index}) => {
    let val = index !== undefined ? fileProps?.ekatRequestList[index]?.ekatTakenDate : null;
    val === undefined && (val = null);
    const fileExists = index !== undefined && files.ekatFile[index];
    return(
      <CustomDatePicker
        required
        label={"Alınan Tarih"}
        error={index !== undefined && errors.ekatErrors[index] && errors.ekatErrors[index].ekatTakenDate}
        value={val}
        disabled={!fileExists}
        shrink={val}
        onChangeFunc={value => {
          let temp = fileProps?.ekatRequestList;
          !temp[index] && (temp[index] = {});
          temp[index].ekatTakenDate = value;
          setFileProps({...fileProps, ekatRequestList: temp});
          setChangeMadeToFiles(true);
        }}
        style={classes.textField}
      />
    )
  }

  const RenderValidityDate = ({index}) => {
    let val = index !== undefined ? fileProps?.ekatRequestList[index]?.ekatValidityDate : null;
    val === undefined && (val = null);
    const fileExists = index !== undefined && files.ekatFile[index];
    return(
      <CustomDatePicker
        required
        label={"Geçerlilik Tarihi"}
        error={index !== undefined && errors.ekatErrors[index] && errors.ekatErrors[index].ekatValidityDate}
        value={val}
        disabled={!fileExists}
        shrink={val}
        onChangeFunc={value => {
          let temp = fileProps?.ekatRequestList;
          !temp[index] && (temp[index] = {});
          temp[index].ekatValidityDate = value;
          setFileProps({...fileProps, ekatRequestList: temp});
          setChangeMadeToFiles(true);
        }}
        style={classes.textField}
      />
    )
  }

  const RenderPeriodOfValidity = ({index}) => {
    const fileExists = index !== undefined && files.ekatFile[index];
  
    const [saveButton, setSaveButton] = React.useState(false);
    const [value, setValue] = React.useState(fileProps?.ekatRequestList[index]?.ekatPeriodOfValidity);
  
    const onChange = () => {
      let temp = fileProps?.ekatRequestList;
      !temp[index] && (temp[index] = {});
      temp[index].ekatPeriodOfValidity = value;
      setFileProps({...fileProps, ekatRequestList: temp});
      setChangeMadeToFiles(true);
    }
  
    return(
      <div style={{display: "flex", flexDirection: "row"}}>
        <TextArea
          required
          label={"Geçerlilik Süresi"}
          error={index !== undefined && errors.ekatErrors[index] && errors.ekatErrors[index].ekatPeriodOfValidity}
          value={value}
          disabled={!fileExists}
          shrink={value || saveButton}
          style={classes.textField}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onChange();
              ev.preventDefault();
            }
          }}
          onChangeFunc={(event) => {
            let value = event.target.value.replace(/[^0-9.]/g, '');
            setValue(value);
            !saveButton && setSaveButton(true);
          }}
          onBlurFunc={(event) => {onChange();}}
        />
        <div style={{marginTop: 20}}>
        {saveButton &&
          <IconTooltipButton title={"Değişikliği Kaydet"} onClick={onChange}>
            <DoneIcon />
          </IconTooltipButton>
        }
        </div>
      </div>
    )
  }

  const clearProps = () => {
    setFileProps({
      ...fileProps,
      ekatTakenDate: null,
      ekatValidityDate: null,
      ekatPeriodOfValidity: null,
    });
    setErrors({
      ...errors,
      ekatTakenDate: null,
      ekatPeriodOfValidity: null,
      ekatValidityDate: null,
    });
  }

  return(
    <div>
      <h2>EKAT</h2>
      <div className={classes.root}>
        <AltTable
          titles={titles}
          rows={rows}
        />
      </div>
    </div>
  )
}