import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import VideosModal from "./VideosModal";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";

export default function VideosTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "visitorVideos");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedVideo, setSelectedVideo] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: language.visitorVideos.videoName, field: "name" },
      {
        title: language.visitorVideos.videoFile,
        field: "videoFile",
        render: (rowData) => (
          <div>
            <CustomFileDropzone
              files={rowData.videoFile}
              disabled
              fileLimit={1}
              dropzoneText={language.tableDetails.dropzone}
            />
          </div>
        ),
      },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = (data) => {
    setSelectedVideo(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedVideo(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedVideo(null);
    setDialog(false);
  };

  const removeVideo = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/vm/video/delete/" + selectedVideo?.id,
      null,
      selectedVideo?.id // TODO Change endpoint
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <React.Fragment>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeVideo}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <VideosModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        videoProps={selectedVideo}
      />
      <Table
        tableName={language.visitorVideos.videos}
        authName="visitorVideos"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => authorization?.delete}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </React.Fragment>
  );
}
