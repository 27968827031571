import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { GetOptions } from "./GetOptions";

const useStyles = makeStyles((theme) => modalStyles(theme));

const propsDefault = {
  name: null,
  equipmentIds: [],
  videoIds: [],
  documentIds: [],
};

export default function VisitorsModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  visitTypeProps,
}) {
  const isNewType = visitTypeProps && Object.keys(visitTypeProps).length === 0;
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestVisitTypeProps, setRequestVisitTypeProps] =
    React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    if (!isNewType && visitTypeProps) {
      const resp = await Request(
        "get",
        "/api/vm/visit-type/get/" + visitTypeProps.id
      );
      console.log(resp);
      setRequestVisitTypeProps({
        name: visitTypeProps ? visitTypeProps.name : null,
        equipmentIds: resp.data.equipments,
        videoIds: resp.data.videos,
        documentIds: resp.data.documents,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitTypeProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setRequestVisitTypeProps(propsDefault);
    setModal(false);
  };

  const submitNewVisitType = async () => {
    setLoading(true);
    let props = { ...requestVisitTypeProps };
    let documentIds = [];
    let equipmentIds = [];
    let videoIds = [];
    for (let doc of requestVisitTypeProps.documentIds) {
      documentIds.push(doc.id);
    }
    for (let eq of requestVisitTypeProps.equipmentIds) {
      equipmentIds.push(eq.id);
    }
    for (let video of requestVisitTypeProps.videoIds) {
      videoIds.push(video.id);
    }
    props = {
      ...requestVisitTypeProps,
      documentIds,
      equipmentIds,
      videoIds,
    };
    const resp = await Request("post", "/api/vm/visit-type/create/", props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateVisitType = async () => {
    setLoading(true);
    let props = { ...requestVisitTypeProps };
    let documentIds = [];
    let equipmentIds = [];
    let videoIds = [];
    for (let doc of props.documentIds) {
      documentIds.push(doc.id);
    }
    for (let eq of props.equipmentIds) {
      equipmentIds.push(eq.id);
    }
    for (let video of props.videoIds) {
      videoIds.push(video.id);
    }
    props.id = visitTypeProps.id;
    props = { ...props, documentIds, equipmentIds, videoIds };
    const resp = await Request(
      "put",
      "/api/vm/visit-type/update/" + props.id,
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestVisitTypeProps={requestVisitTypeProps}
              visitTypeProps={visitTypeProps}
              setRequestVisitTypeProps={setRequestVisitTypeProps}
              submitNewVisitType={submitNewVisitType}
              updateVisitType={updateVisitType}
              loading={loading}
              options={options}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
