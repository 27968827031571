import React from "react";
import { ResponsiveContainer } from "recharts";
import { CircularProgress, makeStyles, Button } from "@material-ui/core";
import FileSaver from "file-saver";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  approveReportButton: {
    cursor: "pointer",
    margin: 20,
    marginTop: 30,
  },
  approveReportLabel: {
    cursor: "pointer",
  },
  divider: {
    margin: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
  },
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: 600,
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px",
  },
  textField: {
    marginBottom: "20px",
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
  fileButton: {
    cursor: "pointer",
    marginBottom: 5,
  },
  fileButtonLabel: {
    cursor: "pointer",
    fontSize: "0.8vw",
  },
}));

export default function Details({ rowData }) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  console.log(rowData);

  const RenderFiles = () => {
    return (
      <div>
        <label>
          Dosyalar
          <br />
        </label>
        {rowData && rowData.forms?.length > 0 ? (
          <div>
            {rowData.forms.map((form, index) => {
              return (
                <div key={index}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.fileButton}
                    onClick={async () => {
                      const resp = await Request(
                        "get",
                        "/api/quality-control-forms/" + form?.id
                      );
                      console.log(resp);
                      const source =
                        "data:application/xlsx;base64, " +
                        encodeURI(resp?.data);
                      FileSaver.saveAs(source, form.formName + ".xls");
                    }}
                  >
                    <label className={classes.fileButtonLabel}>
                      {form.formName}
                    </label>
                  </Button>
                </div>
              );
            })}
          </div>
        ) : (
          <p className={classes.redText}>
            {language.tableDetails.noFile}
            <br />
          </p>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <ResponsiveContainer>
        {rowData ? (
          <div className={classes.details}>
            <RenderFiles />
          </div>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </ResponsiveContainer>
    </React.Fragment>
  );
}
