import React, { useCallback } from "react";
import Request from "../../../helpers/Request";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  TextField,
  MenuItem,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import DeleteDialog from "./DeleteDialog";
import FormModal from "./FormModal";
import Save from "./Save";
import PageStepper from "./PageStepper";
import Content from "./Content";
import Header from "./Header";

/**
 * Request to get the details of the form template such as the questions and options
 * @param {number} id id of the form template
 * @returns {obj} a json object that contains form template details
 */
const getFormTemplate = async (id) => {
  const resp = await Request("get", "/api/form-templates/" + id, null);
  return resp.data;
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
  },
  multipleOptionsContainer: {
    maxHeight: "300px",
    overflow: "auto",
  },
  optionCountTextField: {
    marginBottom: "10px",
  },
  submitQuestionButton: {
    marginTop: "10px",
  },
}));

/**
 * A component to render the details panel of the templates.
 * It includes a page stepper, page selector, buttons to add a question,
 * add an option, remove a question, remove an option and an editable question field
 * @param {rowData: obj} param row data used to get the form template id
 */
export default function Details({ rowData, tableRef }) {
  const classes = useStyles();
  const theme = createTheme({
    palette: {
      type: "dark",
    },
  });
  const language = LanguageHelper.getLanguage();
  const [change, setChange] = React.useState(false);
  const [formTemplate, setFormTemplate] = React.useState(null);
  const [pages, setPages] = React.useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [optionCount, setOptionCount] = React.useState(0);
  const [type, setType] = React.useState("TEXTINPUT");
  const [question, setQuestion] = React.useState("");
  const [optionIndex, setOptionIndex] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [openOptionMenu, setOpenOptionMenu] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newOption, setNewOption] = React.useState("");
  const [newQuestionProps, setNewQuestionProps] = React.useState({
    questionType: "TEXTINPUT",
    maxSelection: null,
    minSelection: null,
    placeholderText: "",
    questionText: "",
    options: [],
  });

  const types = [
    {
      value: "TEXTINPUT",
      label: language.form.textInput,
    },
    {
      value: "SELECTIONGROUP",
      label: language.form.selectionGroup,
    },
    {
      value: "MULTIPLESELECTIONGROUP",
      label: language.form.mSelectionGroup,
    },
  ];

  const getForm = useCallback(async () => {
    const formTemplate = await getFormTemplate(rowData.id);
    console.log(formTemplate);
    setQuestion(
      formTemplate.questions[activeStep]
        ? formTemplate.questions[activeStep].questionText
        : ""
    );
    setFormTemplate(formTemplate);
    const tempArray = [];
    for (let i = 0; i < formTemplate.questions.length; i++) {
      tempArray.push(i);
    }
    setPages(tempArray);
  }, [rowData]);
  React.useEffect(() => {
    getForm();
  }, [getForm]);

  /**
   * Opens the delete dialog and sets the option index for the dialog to
   * decide between deleting a question or an option. If the index is null,
   * it deletes the question. Otherwise it deletes the option with the given index.
   * @param {number} index option index
   */
  const handleDialogOpen = useCallback(
    (index) => {
      setOptionIndex(index);
      setOpenDialog(true);
    },
    [setOptionIndex, setOpenDialog]
  );

  /**
   * Closes the dialog and clears the option index.
   */
  const handleDialogClose = useCallback(() => {
    setOptionIndex(null);
    setOpenDialog(false);
  }, [setOptionIndex, setOpenDialog]);

  /**
   * Changes the activeStep to the page number given and
   * then changes the question accordingly.
   * @param {number} value page number
   */
  const handlePageChange = useCallback(
    (value) => {
      setActiveStep(value);
      setQuestion(formTemplate.questions[value].questionText);
    },
    [setActiveStep, setQuestion, formTemplate]
  );

  /**
   * Increments the activeStep and
   * then changes the question accordingly.
   */
  const handleNext = useCallback(() => {
    const newStep = activeStep + 1;
    setActiveStep(newStep);
    setQuestion(formTemplate.questions[newStep].questionText);
  }, [setActiveStep, setQuestion, formTemplate, activeStep]);

  /**
   * Decrements the activeStep and
   * then changes the question accordingly.
   */
  const handleBack = useCallback(() => {
    const newStep = activeStep - 1;
    setActiveStep(newStep);
    setQuestion(formTemplate.questions[newStep].questionText);
  }, [setActiveStep, setQuestion, formTemplate, activeStep]);

  /**
   * Opens the question creation modal.
   */
  const handleOpenQuestionMenu = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  /**
   * Closes the question creation modal.
   */
  const handleCloseQuestionMenu = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  /**
   * Opens the option creation modal.
   */
  const handleOpenOptionMenu = useCallback(() => {
    setOpenOptionMenu(true);
  }, [setOpenOptionMenu]);

  /**
   * Closes the option creation modal.
   */
  const handleCloseOptionMenu = useCallback(() => {
    setOpenOptionMenu(false);
  }, [setOpenOptionMenu]);

  /**
   * Used in the question creation modal to change the question type.
   * This type state is used so that settings related to certain question
   * types don't show up for other question types.
   * @param {obj} event the question type selected
   */
  const handleChangeQuestionType = useCallback(
    (event) => {
      setType(event.target.value);
      setNewQuestionProps({
        ...newQuestionProps,
        questionType: event.target.value,
      });
    },
    [setType, setNewQuestionProps, newQuestionProps]
  );

  /**
   * Used to set the number of option selectors, checks if the given input
   * is a number, is less than 10 and more than 0.
   * @param {obj} event input to the number of options field
   */
  const handleOptions = useCallback(
    (event) => {
      const val = event.target.value;
      if (!isNaN(val) && parseInt(val) < 20 && parseInt(val) > 0) {
        setOptionCount(parseInt(val));
      }
    },
    [setOptionCount]
  );

  /**
   * Creates a new question.
   */
  const handleSubmitNewQuestion = useCallback(() => {
    let newTemplate = formTemplate;
    let questionTempArray = newTemplate.questions;
    questionTempArray.push(newQuestionProps);
    newTemplate.questions = questionTempArray;
    let newStep = newTemplate.questions.length - 1;
    console.log(newStep);
    setActiveStep(newStep);
    let incPages = pages;
    incPages.push(newStep);
    setPages(incPages);
    setQuestion(newTemplate.questions[newStep].questionText);
    setNewQuestionProps({
      questionType: "TEXTINPUT",
      maxSelection: null,
      minSelection: null,
      placeholderText: "",
      questionText: "",
      options: [],
    });
    setType("TEXTINPUT");
    setFormTemplate(newTemplate);
    setChange(true);
    handleCloseQuestionMenu();
  }, [handleCloseQuestionMenu, newQuestionProps, formTemplate, pages]);

  /**
   * Removes the question from the template.
   */
  const removeQuestion = useCallback(() => {
    let newTemplate = formTemplate;
    newTemplate.questions.splice(activeStep, 1);
    setFormTemplate(newTemplate);
    setChange(true);
    setActiveStep(0);
    setQuestion(newTemplate.questions[0]?.questionText);
    setOpenDialog(false);
  }, [setActiveStep, setOpenDialog, formTemplate, activeStep]);

  /**
   * Changes the question to the edited value and renders the done button.
   * @param {string} value new value of the question field
   */
  const changeQuestion = useCallback(
    (value) => {
      setQuestion(value);
      setDone(true);
    },
    [setQuestion, setDone]
  );

  /**
   * Changes the question to the input value in the template.
   */
  const changeQuestionRequest = useCallback(() => {
    let newTemplate = formTemplate;
    let questionTempArray = newTemplate.questions;
    questionTempArray[activeStep].questionText = question;
    newTemplate.questions = questionTempArray;
    setFormTemplate(newTemplate);
    setChange(true);
    setDone(false);
  }, [question, formTemplate, activeStep, setDone]);

  /**
   * Adds a new option to the question.
   */
  const handleAddOption = useCallback(() => {
    const options = {
      optionText: newOption,
      value: newOption,
    };
    let newTemplate = formTemplate;
    newTemplate.questions[activeStep].options.push(options);
    setChange(true);
    handleCloseOptionMenu();
  }, [newOption, formTemplate, activeStep, handleCloseOptionMenu]);

  /**
   * Removes the option from the question.
   */
  const handleRemoveOption = useCallback(
    (index) => {
      let newTemplate = formTemplate;
      newTemplate.questions[activeStep].options.splice(index, 1);
      setFormTemplate(newTemplate);
      setChange(true);
      handleDialogClose();
    },
    [formTemplate, activeStep, handleDialogClose]
  );

  /**
   * Used in the question creation modal to handle the option fields.
   */
  const MultipleOptions = useCallback(() => {
    const optionArray = [];
    for (let i = 0; i < optionCount; i++) {
      optionArray.push({ key: i, value: "" });
    }

    return optionArray.map((data, index) => {
      return (
        <div key={index}>
          <TextField
            required
            value={newQuestionProps.options[index]?.optionText}
            onChange={(value) =>
              newQuestionProps.options.length < index + 1
                ? newQuestionProps.options.push({
                    optionText: value.target.value,
                    value: value.target.value,
                  })
                : (newQuestionProps.options[index] = {
                    optionText: value.target.value,
                    value: value.target.value,
                  })
            }
            label={language.form.option + " " + (data.key + 1)}
            fullWidth={true}
            style={{ marginBottom: "10px" }}
          />
        </div>
      );
    });
  }, [newQuestionProps, language, optionCount]);

  /**
   * After all the changes are done and the save button is clicked, this method is called to
   * send the request for the changes after which the table is refreshed because the form ID
   * changes.
   */
  const putEditedTemplate = useCallback(async () => {
    console.log(formTemplate);
    if (formTemplate.questions.length === 0) {
      const resp = await Request(
        "delete",
        "/api/form-templates/" + formTemplate.id
      );
      console.log(resp);
    } else {
      const resp = await Request(
        "put",
        "/api/form-templates",
        formTemplate,
        null,
        { "Content-Type": "application/json" }
      );
      console.log(resp);
    }
    setChange(false);
    tableRef.current && tableRef.current.onQueryChange();
  }, [formTemplate, tableRef]);

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        {formTemplate ? (
          <div>
            <Header
              formTemplate={formTemplate}
              changeQuestionRequest={changeQuestionRequest}
              question={question}
              changeQuestion={changeQuestion}
              activeStep={activeStep}
              handleDialogOpen={handleDialogOpen}
              done={done}
              pages={pages}
              handlePageChange={handlePageChange}
              handleOpenQuestionMenu={handleOpenQuestionMenu}
              isDefault={rowData.isDefault}
            />
            <Content
              formTemplate={formTemplate}
              handleOpenOptionMenu={handleOpenOptionMenu}
              activeStep={activeStep}
              handleDialogOpen={handleDialogOpen}
              isDefault={rowData.isDefault}
            />
            <Save change={change} putEditedTemplate={putEditedTemplate} />
            <PageStepper
              formTemplate={formTemplate}
              activeStep={activeStep}
              handleNext={handleNext}
              handleBack={handleBack}
            />

            <FormModal open={open} onClose={handleCloseQuestionMenu}>
              <FormControl
                noValidate
                autoComplete="off"
                className={classes.formControl}
              >
                <TextField
                  id="standard-select"
                  select
                  required
                  fullWidth={true}
                  label={language.form.questionType}
                  value={type}
                  onChange={handleChangeQuestionType}
                  className={classes.textField}
                >
                  {types.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  label={language.form.question}
                  onChange={(value) =>
                    setNewQuestionProps({
                      ...newQuestionProps,
                      questionText: value.target.value,
                    })
                  }
                  fullWidth={true}
                  style={{ marginBottom: "10px" }}
                />
                {type !== "TEXTINPUT" && (
                  <div>
                    <TextField
                      required
                      onChange={handleOptions}
                      label={language.form.numOfOptions}
                      fullWidth={true}
                      className={classes.optionCountTextField}
                    />
                    <div className={classes.multipleOptionsContainer}>
                      <MultipleOptions />
                    </div>
                  </div>
                )}
                {type === "MULTIPLESELECTIONGROUP" && (
                  <div>
                    <TextField
                      required
                      label={language.form.max}
                      onChange={(value) =>
                        setNewQuestionProps({
                          ...newQuestionProps,
                          minSelection: parseInt(value.target.value),
                        })
                      }
                      fullWidth={true}
                      style={{ marginBottom: "10px" }}
                    />
                    <TextField
                      required
                      label={language.form.min}
                      onChange={(value) =>
                        setNewQuestionProps({
                          ...newQuestionProps,
                          maxSelection: parseInt(value.target.value),
                        })
                      }
                      fullWidth={true}
                      style={{ marginBottom: "10px" }}
                    />
                  </div>
                )}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submitQuestionButton}
                  onClick={() => handleSubmitNewQuestion()}
                >
                  {language.form.submit}
                </Button>
              </FormControl>
            </FormModal>

            <FormModal open={openOptionMenu} onClose={handleCloseOptionMenu}>
              <FormControl noValidate autoComplete="off" style={{ width: 600 }}>
                <TextField
                  required
                  label={language.form.option}
                  onChange={(value) => setNewOption(value.target.value)}
                  fullWidth={true}
                  style={{ marginBottom: "10px" }}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "10px" }}
                  onClick={() => handleAddOption()}
                  className={classes.submit}
                >
                  {language.form.submit}
                </Button>
              </FormControl>
            </FormModal>

            <DeleteDialog
              openDialog={openDialog}
              handleDialogClose={handleDialogClose}
              optionIndex={optionIndex}
              removeQuestion={removeQuestion}
              handleRemoveOption={handleRemoveOption}
            />
          </div>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </ThemeProvider>
    </React.Fragment>
  );
}
