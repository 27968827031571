import React, { useCallback } from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import showSnackbar from "../../../components/Utils/showSnackbar";
import VehicleModal from "./VehicleModal";
import DeleteDialog from '../../../components/Dialog/DeleteDialog';

export default function VehicleTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "vehicleTable");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [vehicleModal, setVehicleModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(false);

/*   const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterTerminationStart: null,
    filterTerminationEnd: null,
    filterApprovedStart: null,
    filterApprovedEnd: null,
    department: null,
    company: null,
    assignedBy: null,
    assignedTo: null,
    status: null,
    personal: null
  }); */

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: "Plaka", field: "plate" },
      { title: "Marka", field: "brand" },
      { title: "Model", field: "model" },
      { title: "Yıl", field: "year" },
      { title: "Yakıt", field: "fuelType" , lookup: options.fuelTypes },
      { title: "Vites", field: "gearType" , lookup: options.gearTypes  },
      { title: "Şirket", field: "company" },
      { title: "Bölüm", field: "department" },
      { field: 'driverObjects', title: 'Sürücüler', render: rowData => 
      <>
        <List style={{
          width: 250,
          height: 100,
          overflow: "auto"
        }}>
          {
            rowData?.driverObjects.map((data, index) => (
              <ListItem key={index}>
                <ListItemText secondary={data?.name}/>
              </ListItem>
            ))
          }
        </List>
      </> },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = () => {
    setVehicleModal(true);
  }

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  }

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  }

  const removeVehicle = async () => {
    setLoading(true);
    const resp = await Request("delete", '/api/vehicles/passenger-vehicles/' + idToDelete);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setLoading(false);
  }

/*   const clearFilters = () => {
    setFilterProps({
    });
  }; */

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeVehicle} 
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
      />
      <VehicleModal
        modal={vehicleModal}
        setModal={setVehicleModal}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <Table
        tableName={"Binek Araçlar"}
        authName={"vehicleTable"}
        columns={columns}
        tableRef={tableRef}
        /* filters={filterProps} */
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ") ||
          roles.includes("ROLE_YUKLENICI_YÖNETİCİ") ||
          roles.includes("ROLE_YUKLENICI_ISG_UZMANI")
        }
        isDeleteHidden={() => !authorization.delete}
        detailsWindow={(rowData) => window.open("/vehicle/" + rowData.id)}
      />
    </>
  );
}
