import React from 'react';
import DrillActionDetails from './DrillActionDetails';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router-dom';
import { tableContainerStyles } from '../../../../assets/styles/tableContainer';

export default function DrillActionDetailsPage() {
  const location = useLocation();
  const id = location.pathname.substring(15);

  return (
    <Container maxWidth={false} style={tableContainerStyles}>
      <Grid>
        <DrillActionDetails id={id}/>
      </Grid>
    </Container>
  );
}