import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Request from "../../../helpers/Request";
// import SessionHelper from "../../../helpers/SessionHelper";
import {
  // Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
// import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
// import { taskStatuses } from "../../../assets/constants";
// import UnsuitabilityButton from "../../taskspage/components/UnsuitabilityButton";

const useStyles = makeStyles(() => ({
  formButton: {
    cursor: "pointer",
    margin: 5,
  },
  formButtonLabel: {
    cursor: "pointer",
  },
  unsuitContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
  },
  dividerStyle: {
    margin: 20,
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
}));

export default function PrintUnsuitability({
  data,
  setModal,
  setDetails,
  setFormUnsuitDetails,
}) {
  const classes = useStyles();
  // const user = SessionHelper.getUser();

  //TO-DO make the auth correct
  //   const authorization = getAuthorizationForPage(roles, "");

  const page = 0;
  const size = 50;

  const [numberOfElements, setNumberOfElements] = useState(0);

  const [list, setList] = useState([]);

  const getUnsuitabilityDetails = async () => {
    const resp = await Request(
      "get",
      `/api/nearMiss/action?filterNearMissId=${data.id}&page=${page}&size=${size}`
    );

    setList(resp.data.content);
    setNumberOfElements(resp.data.numberOfElements);

    return resp.data;
  };

  // const handleOpenModal = async (details) => {
  //   setDetails(details);
  //   setModal(true);
  //   if (details) {
  //     setFormUnsuitDetails(await getUnsuitabilityDetails());
  //   }
  // };

  useEffect(() => {
    getUnsuitabilityDetails();
  }, []);

  const goToActionPage = async (i) => {
    window.open("/action/" + i?.id);
  };

  const RenderUnsuitabilities = () => {
    return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{"Ramak Kala No"}</TableCell>
              <TableCell align="left">{"Aksiyon No"}</TableCell>
              <TableCell align="left">{"Atanan kişi"}</TableCell>
              <TableCell align="left">{"Atayan kişi"}</TableCell>
              <TableCell align="left">{"Açıklama"}</TableCell>
              <TableCell align="left">{"         "}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((i) => (
              <TableRow>
                <TableCell align="left">{data.id}</TableCell>
                <TableCell align="left">{i.id}</TableCell>
                <TableCell align="left">
                  {i.assignedTo.fName + " " + i.assignedTo.lName}
                </TableCell>
                <TableCell align="left">
                  {i.assignedBy.fName + " " + i.assignedBy.lName}
                </TableCell>
                <TableCell align="left">{i.note}</TableCell>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.formButton}
                  onClick={() => goToActionPage(i)}
                >
                  <label className={classes.formButtonLabel}>
                    Aksiyona git
                  </label>
                </Button>
                {/* {<UnsuitabilityButton rowData={data} />} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  //   {(!data.nonconformityAssignedAuto ||
  //     data.inspectionType === "BEHAVIORDRIVENAUDITING") &&
  //     authorization.assign &&
  //     )}

  return (
        <div className={classes.unsuitContainer}>
          {/*
          <Button
            variant="contained"
            color="secondary"
            className={classes.formButton}
            onClick={() => getUnsuitabilityDetails()}
          >
            <label className={classes.formButtonLabel}>Aksiyonlar</label>
          </Button>
          */}
          {/* {unsuitId && <UnsuitabilityButton large label rowData={data}/>} */}
          {/* {data.nonConformityReports &&
            data.nonConformityReports.length > 0 && <RenderUnsuitabilities />} */}
          {numberOfElements > 0 ? (
          <RenderUnsuitabilities />
          ) : (
            <p className={classes.redText}>
              Aksiyon yok
              <br />
            </p>
          )}
        </div>
  );
}
