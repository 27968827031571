import React, { useCallback } from "react";
import ModalForm from "./ModalForm";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function JobRiskAnalysisModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldWorkingTypeProps,
  setOldWorkingTypeProps,
  modalLoading,
}) {
  const defaultProps = {
    id: null,
    description: null,
    name: null,
  };
  const language = LanguageHelper.getLanguage();

  const [workingTypeProps, setWorkingTypeProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldWorkingTypeProps) {
      setWorkingTypeProps({
        id: oldWorkingTypeProps.id || null,
        description: oldWorkingTypeProps.description || null,
        name: oldWorkingTypeProps.name || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldWorkingTypeProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setWorkingTypeProps(defaultProps);
      setOldWorkingTypeProps(null);
    }, 300);
  };

  return (
    <React.Fragment>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          workingTypeProps={workingTypeProps}
          setWorkingTypeProps={setWorkingTypeProps}
          label={
            oldWorkingTypeProps ? language.modal.update : language.modal.submit
          }
          loading={loading}
          setLoading={setLoading}
          handleCloseModal={handleCloseModal}
          oldWorkingTypeProps={oldWorkingTypeProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </React.Fragment>
  );
}
