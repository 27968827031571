import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  edcRoles,
  contractorIsgRoles,
  contractorOprRoles,
  contractorOsgbRoles,
  ygkMember,
  inovenRole,
  eyasRoles,
} from "../../../assets/constants";

const language = LanguageHelper.getLanguage();

/**
 * Gets the department data to use in the options menu.
 * @param {companyId: number} id
 */
export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getRegions = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/companies/" + companyId + "/get-cities"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      const regions = data[i].regions;
      for (let j = 0; j < regions.length; j++) {
        let dept = {};
        dept["cityName"] = data[i].name;
        dept["name"] = regions[j].name;
        dept["id"] = regions[j].id;
        arr.push(dept);
      }
    }
  }
  return arr;
};

/**
 * Gets the company data to use in the options menu.
 */
export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["nameShort"] = data[i].nameShort;
    comp["id"] = data[i].id;
    comp["type"] = data[i].eCompanyType;
    arr.push(comp);
  }
  return arr;
};

/**
 * Gets the manager data to use in the options menu.
 */
export const getManagers = async (id) => {
  let fetch;
  const resp = await Request("get", "/api/users/" + id + "/get-managers-com");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    new_json[json[i].id] = json[i].fName + " " + json[i].lName;
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getMaritalStatuses = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/marital");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getEducationStatuses = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/education");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getGenders = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/gender");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].id] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].id] = json[i].nameEng;
    }
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

export const getCertificateTypes = async () => {
  const resp = await Request("get", "/api/enums/operator-certificates");
  const arr = resp.data;
  let new_json = {};
  for (let i = 0; i < arr.length; i++) {
    new_json[arr[i]] = arr[i];
  }
  return new_json;
};

export const getDriversLicenseTypes = async () => {
  const resp = await Request("get", "/api/enums/driver-licences");
  const arr = resp.data;
  let new_json = {};
  for (let i = 0; i < arr.length; i++) {
    new_json[arr[i]] = arr[i];
  }
  return new_json;
};

export const getRoles = async () => {
  const resp = await Request("get", "/api/enums/roles");
  return resp.data;
};

export const getKKDTasks = async () => {
  const resp = await Request("get", "/api/kkd-tasks/all");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let kkd = {};
    kkd["taskName"] = data[i].taskName;
    kkd["id"] = data[i].id;
    arr.push(kkd);
  }
  return arr;
};

export const getReturnReasons = async (id) => {
  const params = {
    userId: id ? id : undefined,
  };
  const resp = await Request("get", "/api/enums/return-reasons", null, params);
  return resp.data;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions(edit) {
  const isg = {
    true: "Evet",
    false: "Hayır",
  };
  const active = {
    true: "Aktif",
    false: "Aktif Değil",
  };
  const ekat = {
    true: language.certificateReport.required,
    false: language.certificateReport.notRequired,
  };
  let marital = {};
  let gender = {};
  let operatorCertificateTypes = {};
  let driversLicenseTypes = {};
  let shoeSizes = {};
  let returnReasons = {};
  let sizes = {
    xs: "XS",
    s: "S",
    m: "M",
    l: "L",
    xl: "XL",
    xxl: "XXL",
    xxxl: "XXXL",
    xxxxl: "XXXXL",
    xxxxxl: "XXXXXL",
  };
  for (let i = 35; i < 51; i++) {
    shoeSizes[i] = i;
  }
  if (edit) {
    [
      marital,
      gender,
      operatorCertificateTypes,
      driversLicenseTypes,
      returnReasons,
    ] = await Promise.all([
      getMaritalStatuses(),
      getGenders(),
      getCertificateTypes(),
      getDriversLicenseTypes(),
      getReturnReasons(),
    ]);
  }
  const departments = [];
  const regions = [];
  const managers = null;
  const [companies, edu, roles] = await Promise.all([
    getCompanies(),
    getEducationStatuses(),
    getRoles(),
  ]);
  const options = {
    roles,
    operatorCertificateTypes,
    driversLicenseTypes,
    departments,
    companies,
    active,
    managers,
    edu,
    marital,
    gender,
    isg,
    ekat,
    regions,
    shoeSizes,
    sizes,
    returnReasons,
    edcRoles,
    contractorIsgRoles,
    contractorOprRoles,
    contractorOsgbRoles,
    ygkMember,
    inovenRole,
    eyasRoles
  };
  return options;
}
