import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import SessionHelper from "../../../helpers/SessionHelper";
import TableBackdrop from '../../../components/Table/TableBackdrop';
// import jsonMap from '../../../components/Utils/jsonMap';
// import { TextSelector } from '../../../components/Fields/TextFields';
import { CustomDatePicker } from '../../../components/Selectors/DatePickers';
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import { getDepartments } from './GetOptions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  const user = SessionHelper.getUser();

  //const { REJECTED, APPROVED, ...statuses } = taskStatuses;

  const isSpecial = 
    user?.roles.includes("ROLE_SİSTEM_ADMİNİ") || 
    user?.roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
    user?.roles.includes("ROLE_ISG_UZMANI") ||
    user?.roles.includes("ROLE_OSGB_ISG_UZMANI");

  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

// filterCompanyId
// filterDepartmentId
// filterStatus
// filterPlannedStart
// filterPlannedEnd
// filterCompletionStart
// filterCompletionEnd

  return(
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Planlanma Tarihi Başlangıç" 
                value={tableFilters.filterPlannedStart ? moment(tableFilters.filterPlannedStart) : null} 
                shrink={tableFilters.filterPlannedStart} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterPlannedStart: moment(value).set("hour", 0).set("minute", 0).toISOString(true)})}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Planlanma Tarihi Bitiş" 
                value={tableFilters.filterPlannedEnd ? moment(tableFilters.filterPlannedEnd) : null} 
                shrink={tableFilters.filterPlannedEnd} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterPlannedEnd: moment(value).set("hour", 23).set("minute", 59).toISOString(true)})}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Tamamlanma Tarihi Başlangıç" 
                value={tableFilters.filterCompletionStart ? moment(tableFilters.filterCompletionStart) : null} 
                shrink={tableFilters.filterCompletionStart} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterCompletionStart: moment(value).set("hour", 0).set("minute", 0).toISOString(true)})}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Tamamlanma Tarihi Bitiş" 
                value={tableFilters.filterCompletionEnd ? moment(tableFilters.filterCompletionEnd) : null} 
                shrink={tableFilters.filterCompletionEnd} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterCompletionEnd: moment(value).set("hour", 23).set("minute", 59).toISOString(true)})}
              />
            </Grid>
            {isSpecial &&
            <Grid item xs={6}>
              <Autocomplete //COMPANY
                id="combo-box"
                options={options.companies?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.filterCompany}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if(!value) {
                    options.departments = [];
                  } else {
                    options.departments = await getDepartments(value?.id);
                  }
                  setTableFilters({...tableFilters, filterCompany: value});
                  setBackdropLoading(false);
                }} 
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Şirket" className={classes.textField}/>}
              />
            </Grid>}
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={options.departments?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.filterCompany}
                value={tableFilters.filterDepartment}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if(value === null) {
                    setTableFilters({...tableFilters, filterDepartment: null});
                  } else {
                    setTableFilters({...tableFilters, filterDepartment: value});
                  }
                  setBackdropLoading(false);
                }} 
                renderInput={(params) => <TextField {...params} label="Bölüm" className={classes.textField}/>}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextSelector
                label="Durumu"
                value={tableFilters.filterStatus}
                shrink={tableFilters.filterStatus !== null}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    filterStatus: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap("statuses", options)}
              />
            </Grid> */}
          </Grid>
  
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  )
}