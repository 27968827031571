import Request from "../../../helpers/Request";
import { taskStatuses } from "../../../assets/constants";
import SessionHelper from "../../../helpers/SessionHelper";

export const getUsers = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-company/" + companyId,
    null
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/training/record/company-list");
  return resp.data.map((record) => ({
    name: record.name,
    id: record.id,
  }));
};

export const getMethods = async () => {
  const resp = await Request("get", "/api/enums/risk-methods");
  let obj = {};
  resp.data.forEach((data) => {
    obj[data] = data;
  });
  return obj;
};



export const getDepartments = async (companyId) => {
  const { data } = await Request("get", "/api/training/record/department-list/" + companyId);
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

export const getRiskLevels = async () => {
  const resp = await Request("get", "/api/enums/risk-levels");
  let obj = {};
  resp.data.forEach((data) => {
    obj[data] = data;
  });
  return obj;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions() {
  const user = SessionHelper.getUser();
  const statuses = { ...taskStatuses };
  delete statuses.REJECTED;
  const [methods, companies, departments, users, riskLevels] = await Promise.all([
    getMethods(),
    getCompanies(),
    getDepartments(user?.companyId),
    getUsers(user?.companyId),
    getRiskLevels()
  ]);
  const options = {
    methods,
    statuses,
    companies,
    departments,
    users,
    riskLevels
  };
  return options;
}
