import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListOfFiles from '../../../components/Files/ListOfFiles';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { Button, Grid } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone'
import Request from '../../../helpers/Request';
import showSnackbar from '../../../components/Utils/showSnackbar';

const useStyles = makeStyles((theme) => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropzoneMargin: {
    margin: "20px"
  },
  fileListContainer: {
    margin: "0 auto"
  },
  fileListLabel: {
    margin: 30
  }
}));

/**
 * The section of the details panel that renders the other files section which also includes one dropzone.
 * @param {rowData: obj, tableRef: obj} params table parameters 
 */
export default function OtherFiles({ rowData, tableRef, setSeverity, setSnackbarMessage, setSnackbar }) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [files, setFiles] = React.useState(new FormData());
  const [length, setLength] = React.useState(0);

  /**
   * Keeps the uploaded file(s) in a FormData object to upload when the button is clicked.
   * @param {obj} acceptedFiles 
   */
  const handleDrop = async (acceptedFiles) => {
    const formData = files;
    formData.set("file", acceptedFiles[0], acceptedFiles[0].name);
    /* for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append("files", acceptedFiles[i], acceptedFiles[i].name);
    } */
    setLength(1);
    setFiles(formData);
  }

  /**
   * Deletes the file from the FormData object.
   * @param {obj} file 
   */
  const handleDelete = async (file) => {
    setLength(length - 1);
    let formData = files;
    let newFormData = new FormData();
    let index = 0;

    for (let pair of files.entries()) {
      if (pair[1].name === file.name) {
        break;
      }
      index++;
    }

    let values = formData.getAll("file");
    values.splice(index, 1);
    for (let i = 0; i < values.length; i++) {
      newFormData.append("file", values[i]);
    }
    setFiles(newFormData);
  }

  /**
   * Sends a post request to upload the FormData object which may include multiple files.
   */
  const sendFiles = async () => {
    const resp = await Request("post", "/api/epdk/casualty/" + rowData.casualtyId + "/add-file-list", files);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, "Başarıyla yüklendi.", tableRef);
    setFiles(new FormData());
    setLength(0);
  }

  for (var pair of files.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
  }
  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={3}>
        <div className={classes.dropzoneMargin}>
          <DropzoneArea
            dropzoneText={language.tableDetails.dropzone}
            filesLimit={1}
            maxFileSize={10000000}
            useChipsForPreview
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
            previewChipProps={{ classes: { root: classes.previewChip } }}
            previewText="Seçili Dosya"
            onDrop={files => handleDrop(files)}
            onDelete={file => handleDelete(file)}
          />
        </div>
        {length > 0 && <Button variant="contained" fullWidth color="primary" onClick={() => sendFiles()}>{language.tableDetails.upload}</Button>}
      </Grid>
      <Grid item xs={9} className={classes.fileListContainer}>
        <label>Dosya</label>
        {rowData.fileKeys
          ?
          <>
            <ListOfFiles fileKeys={[rowData.fileKeys]}></ListOfFiles>
          </>
          :
          <p style={{fontSize: 14, color: "red"}}>{language.tableDetails.noFile}</p>}
      </Grid>
    </Grid>
  )
}