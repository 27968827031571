import Request from '../../../helpers/Request';
import moment from 'moment';
import SessionHelper from '../../../helpers/SessionHelper';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      userId: filterProps.personal ? SessionHelper.getUser().id : undefined,
      sort: "id,desc",

      //FILTERS
      filterStart: filterProps.startDate,
      filterEnd: filterProps.endDate,
      filterDepartmentId: filterProps.department?.id,
      filterCompanyName: filterProps.company?.name,
      filterTownId: filterProps.town?.id,
      filterRegionId: filterProps.region?.id,
      filterUnsuitUserName: filterProps.unsuitUserName,
      filterInspectUserName: filterProps.inspectUserName,
      filterStatus: filterProps.status
    };
    if(type === "personal") {params.userId = user.id;}
    else if(type === "department") {params.departmentId = user.department.id;}
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "customId") { params.sort = "customId," + query.orderDirection; }
      else if (query.orderBy.field === "townName") { params.sort = "cityTown.name," + query.orderDirection; }
      else if (query.orderBy.field === "company") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "department") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "cityName") { params.sort = "inspection.cityTown.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentField") { params.sort = "department.eDepartmentType," + query.orderDirection; }
      else if (query.orderBy.field === "approvalDate") { params.sort = "approvmentDate," + query.orderDirection; }
      else if (query.orderBy.field === "fullName") { params.sort = "user.fName,user.lName," + query.orderDirection; }
      else if (query.orderBy.field === "eStatus") { params.sort = "eStatus," + query.orderDirection; }
      else if (query.orderBy.field === "inspectionUserName") { params.sort = "inspection.user.fName,inspection.user.lName," + query.orderDirection; }
      else if (query.orderBy.field === "completionTime") { params.sort = "completionTime," + query.orderDirection; }
      else if (query.orderBy.field === "terminationTime") { params.sort = "terminationTime," + query.orderDirection; }
    }
    if(query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/unsuitInspections", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        let approve = "";
        let comp_date = "-";
        let appr_date = "-";
        // let term_date = "";
        if (data[i].completionTime) { comp_date = (new moment(data[i].completionTime)).format('DD-MM-YYYY HH:mm:ss'); }
        if (data[i].approvmentDate) { appr_date = (new moment(data[i].approvmentDate)).format('DD-MM-YYYY HH:mm:ss'); }
        // if (data[i].terminationTime) { term_date = (new moment(data[i].terminationTime)).format('DD-MM-YYYY HH:mm:ss'); }
        const edit = {
          approvedBy: data[i].approvedBy ? data[i].approvedBy?.fName + " " + data[i].approvedBy?.lName : null,
          approvalDate: appr_date,
          townName: data[i].cityTown?.name,
          cityName: data[i].cityTown?.belongingCity?.name,
          cityTownId: data[i].cityTown?.id,
          inspectionCompletionTime: data[i].inspectionCompletionTime,
          formTemplateName: data[i].formTemplateName,
          completionTime: comp_date,
          inspectionAttachments: data[i].inspectionAttachments,
          inspectionUser: data[i].inspectionUser,
          inspectionUserName: data[i].inspectionUser ? data[i].inspectionUser?.fName + " " + data[i].inspectionUser?.lName : language.etc.empty,
          inspection: data[i].inspection,
          inspectionId: data[i].id,
          attachments: data[i].attachments,
          approved: approve,
          company: data[i].company.name,
          departmentField: data[i].department?.fieldOfActivity,
          department: data[i].department.name,
          fullName: data[i].user?.fName + " " + data[i].user?.lName,
          id: data[i].id,
          customId: data[i].customId,
          user: data[i].user?.id,
          unsuitability: data[i].unsuitability,
          terminationTime: data[i].terminationTime,
          eStatus: data[i].eStatus,
          managerText: data[i].managerText,
          supervisedUsers: data[i].supervisedUsers,
          nonconformityMessages: ParseDescription({list: data[i].nonconformityMessageList, completionTime: data[i].inspectionCompletionTime})
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      })
    }
  })
  return data;
}

const ParseDescription = ({list, completionTime}) => {
  let messageString = "Yok\n";
  if(list && list.length > 0) {
    const messages = list;
    if(messages.length > 0) {messageString = ""}
    for(let i = 0; i < messages.length; i++) {
      messages[i].noteTime ? 
      messageString += `${messages[i].user.fName} ${messages[i].user.lName}: ${messages[i].note} (${(new moment(messages[i].noteTime)).format("HH:mm DD-MM-YYYY")})\n`
      : 
      messageString += `${messages[i].user.fName} ${messages[i].user.lName}: ${messages[i].note} (${(new moment(completionTime)).format("HH:mm DD-MM-YYYY")})\n`;
    }
  }
  return messageString;
}