import React from "react";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function Files({
  trainingDocuments,
  setTrainingDocuments,
  participationForms,
  setParticipationForms,
  otherDocuments,
  setOtherDocuments,
}) {
  const language = LanguageHelper.getLanguage();

  return (
    <div>
      <CustomFileDropzone
        files={trainingDocuments}
        setFiles={(value) => {
          setTrainingDocuments(value);
        }}
        fileLimit={200}
        dropzoneText={language.tableDetails.dropzone9}
      />
      <CustomFileDropzone
        files={participationForms}
        setFiles={(value) => {
          setParticipationForms(value);
        }}
        fileLimit={200}
        dropzoneText={language.tableDetails.dropZone10}
      />
      <CustomFileDropzone
        files={otherDocuments}
        setFiles={(value) => {
          setOtherDocuments(value);
        }}
        fileLimit={200}
        dropzoneText={language.tableDetails.dropzone11}
      />
    </div>
  );
}
