import LanguageHelper from '../../../helpers/LanguageHelper';
import Request from '../../../helpers/Request';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc"
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "firstName") { params.sort = "fName," + query.orderDirection; }
      else if (query.orderBy.field === "lastName") { params.sort = "lName," + query.orderDirection; }
      else if (query.orderBy.field === "department") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "company") { params.sort = "company.nameShort," + query.orderDirection; }
      else if (query.orderBy.field === "roles") { params.sort = "roles," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/users/life-community-members", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id ? data[i].id : null,
          firstName: data[i].fName ? data[i].fName : null,
          lastName: data[i].lName ? data[i].lName : null,
          roles: data[i].roleList ? data[i].roleList : null,
          department: data[i].department ? data[i].department?.name : null,
          company: data[i].department ? data[i].department?.companyName : null,
          goal: true,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}