import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Request from "../../../helpers/Request";
import { modalStyles } from "../../../assets/styles/tableContainer";
import LanguageHelper from "../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  dividerStyle: {
    margin: 20,
  },
  text: {
    fontSize: "1vw",
    float: "left",
  },
  title: {
    float: "left",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  noCasualties: {
    color: "red",
    fontSize: "2vw",
  },
}));

const useModalStyles = makeStyles((theme) => modalStyles(theme));
const language = LanguageHelper.getLanguage();


export default function RecordDetails({ rowData, tableRef, options }) {
  console.log(rowData);
  const modalStyles = useModalStyles();
  const classes = useStyles();

  const [notifications, setNotifications] = React.useState(rowData.ibysResults);
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);

//   const PrintNotifications = () => {
//     return notifications.map(())
//   }

  return <div>RecordDetails</div>;
}
