import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @param {func} setData a function to set the newly received data
 * An important note about setData: data state itself is never used directly, only the setData function
 * is used to update the page when new data is received.
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc" 
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "edas") { params.sort = "accident.edcName," + query.orderDirection; }
      else if (query.orderBy.field === "sn") { params.sort = "accident.id," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "accident.edcOrContractorName," + query.orderDirection; }
      else if (query.orderBy.field === "companySgk") { params.sort = "accident.companySgkNo," + query.orderDirection; }
      //else if (query.orderBy.field === "tminusOneYearPersonnel") { params.sort = "accident.tminusOneYearPersonnel," + query.orderDirection; }
      else if (query.orderBy.field === "personnelName") { params.sort = "fName,lName," + query.orderDirection; }
      else if (query.orderBy.field === "personnelSgk") { params.sort = "fName," + query.orderDirection; }
      else if (query.orderBy.field === "accidentDate") { params.sort = "accident.accidentDate," + query.orderDirection; }
      else if (query.orderBy.field === "accidentYear") { params.sort = "accident.accidentDate," + query.orderDirection; }
      else if (query.orderBy.field === "accidentType") { params.sort = "accident.accidentTypeEPDK.id," + query.orderDirection; }
      else if (query.orderBy.field === "lostLimb") { params.sort = "lostLimb," + query.orderDirection; }
      else if (query.orderBy.field === "lostTime") { params.sort = "lostTimeInDays," + query.orderDirection; }
      else if (query.orderBy.field === "accidentReason") { params.sort = "accident.accidentReasonEPDK," + query.orderDirection; }
      else if (query.orderBy.field === "fileLink") { params.sort = "file.fileKey," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/epdk/view", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          accidentDate: data[i].accidentDate,
          accidentReason: data[i].accidentReason,
          accidentType: data[i].accidentType,
          accidentYear: data[i].accidentYear,
          incapacitationStart: data[i].incapacitationStart,
          incapacitationEnd: data[i].incapacitationEnd,
          companyName: data[i].companyName,
          companySgk: data[i].companySgk,
          edas: data[i].edas,
          fileLink: data[i].fileLink,
          id: data[i].id,
          lostLimb: data[i].lostLimb,
          lostTime: data[i].lostTime,
          personnelName: data[i].personnelName,
          personnelSgk: data[i].personnelSgk,
          sn: data[i].sn,
          //tminusOneYearPersonnel: data[i].tminusOneYearPersonnel,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}
