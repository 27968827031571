import React, { useCallback } from "react";
import moment from "moment";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import VisitorsModal from "./VisitsModal";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import VisitorVisitList from "./VisitVisitorList";
import { visitStatus } from "../../../assets/constants";

const detailStyle = {
  backgroundColor: "#2D3446",
  color: "white",
  alignItems: "center",
  textAlign: "center",
  padding: 10,
};

export default function VisitsTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const isAppUser = user?.appUser;
  const isSecurity = roles.includes("ROLE_GÜVENLİK_GÖREVLİSİ");
  const authorization = getAuthorizationForPage(roles, "visitors");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedVisit, setSelectedVisit] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [deleteVisit, setDeleteVisit] = React.useState(null);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: language.visits.departmentName, field: "departmentName" },
      { title: language.visits.hostName, field: "hostName" },
      { title: language.visits.visitTypeName, field: "visitTypeName" },
      { title: language.visits.topic, field: "topic" },
      { title: language.visits.plannedDate, field: "plannedDate" },
      { title: language.visits.note, field: "note" },
      { title: language.visits.status, field: "status", lookup: visitStatus },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    if (data !== null) {
      const resp = await Request("get", "/api/vm/visit/" + data.id);
      const id = resp.data?.id;
      const departmentId = resp.data?.department?.id;
      const note = resp.data?.note;
      const status = resp.data?.status;
      const topic = resp.data?.topic;
      const participantObjects = resp.data?.participants.map((data) => ({
        name: data.user.fName + " " + data.user.lName,
        id: data.user.id,
      }));
      const plannedDate = moment(resp.data?.plannedDate).format(
        "DD-MM-YYYY HH:mm:ss"
      );
      const visitTypeId = resp.data?.visitType?.id;
      const hostId = data?.hostId;

      let visitors = [];

      for (let i = 0; i < resp.data?.visitorLists.length; i++) {
        let visitor = {
          visitorCompanyId: resp.data?.visitorLists[i]?.visitor?.company?.id,
          visitorId: resp.data?.visitorLists[i]?.visitor?.id,
          equipmentIds: [],
          videoIds: [],
          documentIds: [],
        };
        for (const equipment of resp.data?.visitorEquipments) {
          if (equipment.visitor.id === visitor.visitorId) {
            visitor.equipmentIds.push(equipment.equipment);
          }
        }
        for (const video of resp.data?.visitorVideos) {
          if (video.visitor.id === visitor.visitorId) {
            visitor.videoIds.push({
              id: video.video.id,
              name: video.video.name,
            });
          }
        }
        for (const doc of resp.data?.visitorDocuments) {
          if (doc.visitor.id === visitor.visitorId) {
            visitor.documentIds.push(doc.document);
          }
        }
        visitors.push(visitor);
      }

      const visit = {
        departmentId,
        note,
        participantObjects,
        plannedDate,
        visitTypeId,
        topic,
        visitors,
        status,
        id,
        hostId,
      };
      setSelectedVisit(visit);
    } else {
      setSelectedVisit(data);
    }
    setModal(true);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setDeleteVisit(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteVisit(null);
    setDialog(false);
  };

  const removeVisit = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/vm/visit/delete/" + deleteVisit?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <React.Fragment>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeVisit}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <VisitorsModal
        modal={modal}
        setModal={setModal}
        modalLoading={modalLoading}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        visitProps={selectedVisit}
        setVisitProps={setSelectedVisit}
      />
      <Table
        tableName={language.visits.visits}
        authName="visitors"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => authorization?.delete}
        isEditable={(user, rowData) =>
          !(rowData.status === "DONE" || rowData.status === "CANCELED") &&
          // !(rowData.participants?.find((x) => x === user.id) === user.id) &&
          isAppUser &&
          (rowData.hostId === user.id || isSecurity)
        }
        isEditHidden={!authorization?.edit}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => (
          <div style={detailStyle}>
            <VisitorVisitList rowData={rowData} />
          </div>
        )}
      />
    </React.Fragment>
  );
}
