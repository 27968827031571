import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Request from '../../../../helpers/Request';
import SessionHelper from '../../../../helpers/SessionHelper';
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { getAuthorizationForPage } from '../../../../helpers/AuthorizationHelper';
import { taskStatuses } from '../../../../assets/constants';
import UnsuitabilityButton from '../../taskspage/components/UnsuitabilityButton';

const useStyles = makeStyles(() => ({
  formButton: {
    cursor: "pointer",
    margin: 5
  },
  formButtonLabel: {
    cursor: "pointer"
  },
  unsuitContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    margin: "0 auto"
  },
  dividerStyle: {
    margin: 20
  },
}));

export default function PrintUnsuitability({ data, setModal, setDetails, setFormUnsuitDetails }) {
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "tasksTable");

  const getUnsuitabilityDetails = async () => {
    const resp = await Request("get", '/api/unsuitInspections/' + data.id);
    return resp.data;
  };

  const handleOpenModal = async (details) => {
    setDetails(details);
    setModal(true);
    if(details) {
      setFormUnsuitDetails(await getUnsuitabilityDetails()); 
    }
  };

  const RenderUnsuitabilities = () => {
    return(
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{"No"}</TableCell>
              <TableCell align="left">{"Uygunsuzluk Giderici Kişi"}</TableCell>
              <TableCell align="left">{"Denetim Yapan Kişi"}</TableCell>
              <TableCell align="left">{"Açıklama"}</TableCell>
              <TableCell align="left">{"Durumu"}</TableCell>
              <TableCell align="left">{"Uygunsuzluk Giderme Görevine Git"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.nonConformityReports.map((data) => 
              <TableRow >
                <TableCell align="left">{data.id}</TableCell>
                <TableCell align="left">{data.user.fName + " " + data.user.lName}</TableCell>
                <TableCell align="left">{data.inspectionUser.fName + " " + data.inspectionUser.lName}</TableCell>
                <TableCell align="left">{data.note || "Açıklama Yok"}</TableCell>
                <TableCell align="left">{taskStatuses[data.estatus]}</TableCell>
                <TableCell align="left">{<UnsuitabilityButton rowData={data}/>}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  
  return (
    <>
      {(data.unsuitability || (data.nonConformityReports && data.nonConformityReports.length > 0)) &&
      <>
        <Divider className={classes.dividerStyle} />
        <label>Uygunsuzluk Giderme Görevleri</label>
        <div className={classes.unsuitContainer}>
            {(!data.nonconformityAssignedAuto || data.inspectionType === "BEHAVIORDRIVENAUDITING") && authorization.assign &&
              <Button variant="contained" color="secondary" className={classes.formButton} onClick={() => handleOpenModal(false)}>
                <label className={classes.formButtonLabel}>UYGUNSUZLUK GİDERME GÖREVİ OLUŞTUR</label>
              </Button>}
            
            {/* {unsuitId && <UnsuitabilityButton large label rowData={data}/>} */}
            {data.nonConformityReports && data.nonConformityReports.length > 0 &&
            <RenderUnsuitabilities/>}
        </div>
      </>}
    </>
  );
}