import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
} from "@material-ui/core";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../components/Table/TableFilters";
import { CustomDatePicker, TimePicker } from '../../components/Selectors/DatePickers';
import moment from "moment";
import { TextSelector } from '../../components/Fields/TextFields';
import jsonMap from '../../components/Utils/jsonMap';
import { logLevels } from '../../assets/constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  tableRef,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Tarih" 
                value={tableFilters.date} 
                shrink={tableFilters.date} 
                onChangeFunc={value => {
                  setTableFilters({...tableFilters, date: moment(value).toISOString(true)});
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                style={classes.textField}
                label={"Saat"}
                value={tableFilters.time ? parseTime(tableFilters.time) : null} 
                shrink={tableFilters.time} 
                keyboardButtonProps={{"aria-label": "change time"}}
                onChangeFunc={(date, value) => {
                  setTableFilters({...tableFilters, time: value.substr(0, 5) + ":00"});
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Level"
                value={tableFilters.level}
                shrink={tableFilters.level !== null}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    level: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, logLevels)}
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}

const parseTime = (time) => {
  if (time) {
    let d = new Date();
    let [hours, minutes, seconds] = time.split(":");
    d.setHours(+hours);
    d.setMinutes(minutes);
    d.setSeconds(seconds);
    return d;
  }
};