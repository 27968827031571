import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PieChart from "../../../components/Chart/PieChart";
import BarChart from "../../../components/Chart/BarChart";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import { Bar, LabelList } from "recharts";
import { monthsForNumber, severitiesByCount } from "../../../assets/constants";
import {
  getWorkAccidentCount,
  getWorkAccidentCountBySeverity,
  getWorkAccidentCountByType,
  getMissingDayCount,
} from "./GetOptions";
import { Footer } from "./Settings";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red"];
const fixedHeight = 650;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  section: {
    height: window.innerHeight > 900 ? "75vh" : "70vh",
  },
}));

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, offset } = props;

  const xOffset = width / 2 + offset - 1;
  const yOffset = height / 2 + 5;

  return (
    <text x={x + xOffset} y={y + yOffset} fill={"black"} textAnchor="end">
      {value === 0 ? "" : value}
    </text>
  );
};

export function WorkAccidentCountBarChart({
  title,
  data,
  Footer,
  filterProps,
}) {
  return (
    <BarChart
      dataSet={data}
      title={title}
      FooterComponent={() => Footer("bar", filterProps)}
    >
      <Bar
        isAnimationActive={false}
        dataKey="İş Kazası Sayısı"
        fill={COLORS[0]}
      >
        <LabelList
          dataKey="İş Kazası Sayısı"
          position="center"
          content={renderCustomizedLabel}
          style={{ fill: "white" }}
        />
      </Bar>
    </BarChart>
  );
}

export function MissingDayCountBarChart({ title, data, Footer, filterProps }) {
  return (
    <BarChart
      dataSet={data}
      title={title}
      FooterComponent={() => Footer("bar", filterProps)}
    >
      <Bar
        isAnimationActive={false}
        dataKey="Kayıp Gün Sayısı"
        fill={COLORS[0]}
      >
        <LabelList
          dataKey="Kayıp Gün Sayısı"
          position="center"
          content={renderCustomizedLabel}
          style={{ fill: "white" }}
        />
      </Bar>
    </BarChart>
  );
}

export default function WorkAccidentCharts({
  setBackdropLoading,
  filterProps,
}) {
  const user = SessionHelper.getUser();
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "dashboard");
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [workAccidentCount, setWorkAccidentCount] = React.useState(null);
  const [workAccidentCountBySeverity, setWorkAccidentCountBySeverity] =
    React.useState(null);
  const [missingDayCount, setMissingDayCount] = React.useState(null);
  const [workAccidentCountByType, setWorkAccidentCountByType] =
    React.useState(null);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const [
      workAccidentCountData,
      workAccidentCountBySeverityData,
      missingDayCountData,
      workAccidentCountByTypeData,
    ] = await Promise.all([
      getWorkAccidentCount(filterProps),
      getWorkAccidentCountBySeverity(filterProps),
      getMissingDayCount(filterProps),
      getWorkAccidentCountByType(filterProps),
    ]);
    setWorkAccidentCount(
      formatDataForBarCharts(workAccidentCountData, "İş Kazası Sayısı")
    );
    setWorkAccidentCountBySeverity(
      formatDataForPieCharts(workAccidentCountBySeverityData)
    );
    setMissingDayCount(
      formatDataForBarCharts(missingDayCountData, "Kayıp Gün Sayısı")
    );
    setWorkAccidentCountByType(
      formatTypeDataForPieChart(workAccidentCountByTypeData)
    );
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    filterProps.startDate,
    filterProps.endDate,
    filterProps.companyIds,
    filterProps.regionIds,
    filterProps.townIds,
    filterProps.departmentIds,
    filterProps.departmentTypes,
  ]);
  React.useEffect(() => {
    init();
  }, [init]);

  const formatDataForBarCharts = (data, propName) => {
    let chartData = [];
    for (let p in data) {
      const row = {
        name: monthsForNumber[data[p].month],
        [propName]: data[p].count ?? 0,
      };
      chartData.push(row);
    }
    return chartData;
  };

  const formatTypeDataForPieChart = (data) => {
    let chartData = [];
    let totalCount = 0;
    for (let p in data) {
      totalCount += data[p].count ?? 0;
    }
    for (let p in data) {
      const row = {
        name: data[p].value,
        value: data[p].count ?? 0,
        percent: ((data[p].count / totalCount) * 100).toFixed(2),
      };
      chartData.push(row);
    }
    return chartData;
  };

  const formatDataForPieCharts = (data) => {
    let chartData = [];
    for (let prop in data) {
      let row = {
        name: severitiesByCount[data[prop].value],
        value: data[prop].count,
      };
      chartData.push(row);
    }
    return chartData;
  };

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <WorkAccidentCountBarChart
              data={workAccidentCount}
              title={"İş Kazası Sayıları"}
              Footer={Footer}
              filterProps={filterProps}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={workAccidentCountBySeverity}
              title={"Ciddiyetine Göre İş Kazası Sayıları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <MissingDayCountBarChart
              data={missingDayCount}
              title={"Kayıp Gün Sayıları"}
              Footer={Footer}
              filterProps={filterProps}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={workAccidentCountByType}
              title={"Kaza/Olay Şekline Göre İş Kazası Sayıları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
