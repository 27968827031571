import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { TextArea } from "../../../components/Fields/TextFields";
import { Autocomplete } from "@material-ui/lab";
import { getDepartments } from "./GetOptions";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  let newOpts = options;
                  newOpts.departments = value?.id
                    ? await getDepartments(value?.id)
                    : [];
                  setOptions(newOpts);
                  setTableFilters({ ...tableFilters, company: value });
                  if (!value) {
                    setTableFilters({
                      ...tableFilters,
                      company: value,
                      department: value,
                    });
                  }
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.fullName !== null}
                label={"Kullanıcı"}
                value={tableFilters.fullName}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    fullName: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
