import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import TableEntryModal from '../../../../../components/Modal/TableEntryModal';
import { TextArea } from '../../../../../components/Fields/TextFields';
import { CustomDatePicker } from '../../../../../components/Selectors/DatePickers';
import moment from "moment";
import CustomFileDropzone from '../../../../../components/Files/CustomFileDropzone';
import Request from "../../../../../helpers/Request";
import showSnackbar from '../../../../../components/Utils/showSnackbar';
import LanguageHelper from "../../../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: 'pre-wrap'
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20
  }
}));

const propsDefault = {
  id: null,
  trainingType: null,
  topics: null,
  date: moment().toISOString(true),
  time: null,
  place: null,
  status: null,
  instructorId: null,
  instructorName: null,
  participants: [],
  files: [],
}

export default function TrainingModal({
  userId,
  trainingProps,
  setTrainingModal,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  update
}) {
  const classes = useStyles();
  const isNewTraining = trainingProps && Object.keys(trainingProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [files, setFiles] = React.useState([]);
  const [props, setProps] = React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const init = useCallback(async () => {
    setModalLoading(true);
    if(!isNewTraining && trainingProps) {    
      setProps(trainingProps);
      setFiles(trainingProps.files);
    }
    setModalLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trainingProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    /*let errorMessage = "Bu alan boş bırakılamaz.";
    if(!newUserProps?.fName) {
      setNameError(errorMessage);
      error = true;
    } */

    if(!error) {
      if(isNewTraining) {
        submitNewRecord();
      } else {
        updateRecord()
      }
    }
  }

  const submitNewRecord = async () => {
    setLoading(true);
    let formData = new FormData();
    for(let file of files) {
      formData.append("files", file);
    }
    props.userId = userId;
    delete props.id;
    delete props.instructorName;
    delete props.status;
    delete props.trainingType;
    delete props.instructorId;
    delete props.participants;
    delete props.files;
    delete props.tableData;
    formData.append("newTrainingRequest", new Blob([JSON.stringify(props)], {type: "application/json"}));
    const resp = await Request("post", '/api/training/other', formData);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, null, update);
    handleCloseModal();
    setLoading(false);
  }

  const updateRecord = async () => {
    setLoading(true);
    let formData = new FormData();
    for(let file of files) {
      formData.append("files", file);
    }
    props.userId = userId;
    props.trainingId = props?.id;
    delete props.id;
    delete props.instructorName;
    delete props.status;
    delete props.trainingType;
    delete props.instructorId;
    delete props.participants;
    delete props.files;
    delete props.tableData;
    formData.append("modifyTrainingRequest", new Blob([JSON.stringify(props)], {type: "application/json"}));
    const resp = await Request("patch", '/api/training/other', formData);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, null, update);
    handleCloseModal();
    setLoading(false);
  }

  const handleCloseModal = () => {
    setFiles([]);
    setProps(propsDefault);
    setTrainingModal(null);
  }

  return (
    <TableEntryModal
      modal={trainingProps ? true : false}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
      >
      <div className={classes.formControl}>
        <TextArea //TOPICS
          required
          label={"Adı"}
          value={props?.topics}
          onChangeFunc={value => setProps({...props, topics: value.target.value})}
          style={classes.textField}
        />
        <CustomDatePicker //DATE
          style={classes.dateField}
          label={"Tarih"}
          value={props?.date} 
          shrink={true} 
          onChangeFunc={value => setProps({...props, date: moment(value).toISOString(true)})}
        />  
        <TextArea //TIME
          required
          label={"Süre (Saat)"}
          value={props?.time}
          shrink={props?.time}
          onChangeFunc={value => {
            const val = value.target.value.replace(/\s/g, "").replace(/\D/g,'');
            setProps({...props, time: val})
          }}
          style={classes.textField}
        />
        <TextArea //PLACE
          required
          label={"Eğitim Yeri"}
          value={props?.place}
          onChangeFunc={value => setProps({...props, place: value.target.value})}
          style={classes.textField}
        />
        <TextArea //INSTRUCTOR
          required
          label={"Eğitmen (Kişi/Kuruluş)"}
          value={props?.instructor}
          onChangeFunc={value => setProps({...props, instructor: value.target.value})}
          style={classes.textField}
        />
        <CustomFileDropzone
          files={files}
          setFiles={(value) => {
            setFiles(value);
          }}
          fileLimit={20}
          dropzoneText={"Dosyaları yüklemek için sürükleyin ya da buraya tıklayın."}
        />
        <div style={{paddingBottom: 20, textAlign: "center"}}>
          {loading ?
          <CircularProgress color="secondary"/>
          :
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onButtonClick()}
            className={classes.submit}>
            {!isNewTraining ? "GÜNCELLE" : "OLUŞTUR"}
          </Button>}
        </div>
      </div>
    </TableEntryModal>
  );
}