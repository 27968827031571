import React, { useCallback } from "react";
import ModalForm from "./ModalForm";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function WorkingAreaModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldWorkingAreaProps,
  setOldWorkingAreaProps,
  modalLoading,
  options,
}) {
  const defaultProps = {
    id: null,
    type: null,
    name: null,
  };
  const language = LanguageHelper.getLanguage();

  const [workingAreaProps, setWorkingAreaProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldWorkingAreaProps) {
      setWorkingAreaProps({
        id: oldWorkingAreaProps.id || null,
        type: oldWorkingAreaProps.type || null,
        name: oldWorkingAreaProps.name || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldWorkingAreaProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setWorkingAreaProps(defaultProps);
      setOldWorkingAreaProps(null);
    }, 300);
  };

  return (
    <React.Fragment>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          workingAreaProps={workingAreaProps}
          setWorkingAreaProps={setWorkingAreaProps}
          label={
            oldWorkingAreaProps ? language.modal.update : language.modal.submit
          }
          loading={loading}
          setLoading={setLoading}
          handleCloseModal={handleCloseModal}
          oldWorkingAreaProps={oldWorkingAreaProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          options={options}
        />
      </TableEntryModal>
    </React.Fragment>
  );
}
