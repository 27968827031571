import React from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Grid } from "@material-ui/core";

/**
 * Renders the options of the question, every option has a delete button next to it
 * which when clicked, opens a delete dialog.
 * @param {formTemplate: obj, activeStep: number, handleDialogOpen: func} param
 * props of the option printer component
 */
export default function PrintOptions({
  formTemplate,
  activeStep,
  handleDialogOpen,
  isDefault,
}) {
  const language = LanguageHelper.getLanguage();
  return formTemplate.questions[activeStep].options.map((data, index) => {
    return (
      <div key={index}>
        <Grid container direction="row">
          {!isDefault && (
            <IconTooltipButton
              title={language.form.deleteOption}
              onClick={() => handleDialogOpen(index)}
            >
              <DeleteIcon />
            </IconTooltipButton>
          )}
          <h6>
            {language.form.option + " " + (index + 1) + ": " + data.optionText}
          </h6>
        </Grid>
      </div>
    );
  });
}
