import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.name,
      department: filterProps.department?.name,
      // Backend developers asked for this fullName parameter
      name: filterProps.name ? "%" + filterProps.name + "%" : filterProps.name,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "companyName") { params.sort = "crew.company.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "crew.department.name," + query.orderDirection; }
      else if (query.orderBy.field === "crewName") { params.sort = "user," + query.orderDirection; }
      /* else if (query.orderBy.field === "missingKKDs") { params.sort = "missingKKDs," + query.orderDirection; } */
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/crews/missing-kkds", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          crewName: content[i]?.crewName,
          missingKKDs: formatKKDs(content[i]?.missingKKDs)
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      })
    }
  })
  return data;
}

const formatKKDs = (lst) => {
  const array = [];
  for(let kkd of lst) {
    array.push({
      name: kkd.name,
    });
  }
  return array;
}