import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      filterCompanyId: filterProps.filterCompany?.id,
      filterDepartmentId: filterProps.filterDepartment?.id,
      filterStatus: filterProps.filterStatus,
      filterPlannedStart: filterProps.filterPlannedStart,
      filterPlannedEnd: filterProps.filterPlannedEnd,
      filterCompletionStart: filterProps.filterCompletionStart,
      filterCompletionEnd: filterProps.filterCompletionEnd
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "analysisNo") { params.sort = "analysisNo," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "riskEvaluationMethod") { params.sort = "riskEvaluationMethod," + query.orderDirection; }
      else if (query.orderBy.field === "avgRiskScore") { params.sort = "avgRiskScore," + query.orderDirection; }
      else if (query.orderBy.field === "avgPriority") { params.sort = "avgPriority," + query.orderDirection; }
      else if (query.orderBy.field === "avgRemainingScore") { params.sort = "avgPriority," + query.orderDirection; }
      else if (query.orderBy.field === "avgRemainingPriority") { params.sort = "avgRemainingPriority," + query.orderDirection; }
      else if (query.orderBy.field === "updateDate") { params.sort = "updateDate," + query.orderDirection; }
      else if (query.orderBy.field === "completionDate") { params.sort = "completionDate," + query.orderDirection; }
      else if (query.orderBy.field === "validationDate") { params.sort = "validationDate," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/riskAnalysis", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {    
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const props = {
          id: data[i]?.id,
          analysisNo: data[i]?.analysisNo,
          company: data[i]?.company,
          companyId: data[i]?.company?.id,
          companyName: data[i]?.company?.name,
          department: data[i]?.department,
          departmentId: data[i]?.department?.id,
          departmentName: data[i]?.department?.name,
          completionDate: data[i]?.completionDate,
          updateDate: data[i]?.updateDate,
          validationDate: data[i]?.validationDate,
          riskEvaluationMethod: data[i]?.riskEvaluationMethod,
          riskEvaluationTeam: data[i]?.riskEvaluationTeam,
          avgRiskScore: data[i]?.avgRiskScore,
          avgPriority: data[i]?.avgPriority,
          avgRemainingScore: data[i]?.avgRemainingScore,
          avgRemainingPriority: data[i]?.avgRemainingPriority,
          risks: data[i]?.risks,
        };
        // props.files = [];
        // for(let file of data[i]?.files) {
        //   props.files.push(new File([file?.fileName], file?.fileName, { type: "text/plain" }));
        // }
        temp.push(props);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}