import React, { useCallback } from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DoneIcon from "@material-ui/icons/Done";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { TextSelector } from "../../../components/Fields/TextFields";

export default function StatusColumn({
  rowData,
  roles,
  jsonMap,
  statuses,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();

  const [dropdown, setDropdown] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);
  const [status, setStatus] = React.useState(null);
  const [save, setSave] = React.useState(false);

  const init = useCallback(async () => {
    setSave(false);
    setStatus(rowData.status);
    if (
      rowData.status === "DONE" &&
      (roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
        roles.includes("ROLE_ISG_UZMANI") ||
        roles.includes("ROLE_OSGB_ISG_UZMANI"))
    ) {
      setDropdown({
        DONE: "Onay Bekliyor",
        APPROVED: "Onaylandı",
      });
    } else if (rowData.status === "TODO") {
      setDropdown({
        TODO: "Başlanmadı",
        DONE: "Onay Bekliyor",
      });
    } else {
      setDropdown(statuses);
      setDisabled(true);
    }
    if (
      !(
        roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
        roles.includes("ROLE_ISG_UZMANI") ||
        roles.includes("ROLE_OSGB_ISG_UZMANI")
      )
    ) {
      user.id !== rowData.assignedBy.id && setDisabled(true);
    } else {
      if (
        !(
          (user.id !== rowData.assignedBy.id &&
            (rowData.status === "DONE" || rowData.status === "APPROVED")) ||
          user.id === rowData.assignedBy.id
        )
      ) {
        setDisabled(true);
      }
    }
  }, [rowData, roles, statuses, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const changeStatus = async () => {
    let resp;
    const body = {
      status: rowData.status,
      id: rowData.id,
    };
    resp = await Request("patch", "/api/nearMiss/action/", body);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    setSave(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <TextSelector
        required
        disabled={disabled}
        value={status}
        onChangeFunc={(value) => {
          rowData.status = value.target.value;
          setStatus(value.target.value);
          setSave(true);
        }}
        options={jsonMap(dropdown)}
      />
      <div style={{ marginLeft: "20px" }}>
        {save && (
          <IconTooltipButton
            title={language.form.approveChange}
            onClick={changeStatus}
          >
            <DoneIcon />
          </IconTooltipButton>
        )}
      </div>
    </div>
  );
}
