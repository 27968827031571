import React, { useCallback } from "react";
import "../../../../App.css";
import SessionHelper from "../../../../helpers/SessionHelper";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../../helpers/AuthorizationHelper";
import Request from '../../../../helpers/Request';
import CustomSnackbar from "../../../../components/Snackbar/Snackbar";
import Table from "../../../../components/Table/Table";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import GetOptions from './GetOptions';
import showSnackbar from "../../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../../components/Dialog/DeleteDialog";
import GetFilterFields from './JobRiskAnalysisFilters';
import JobRiskAnalysisModal from './JobRiskAnalysisModal';
import moment from "moment";
import { getUsers, getDepartments } from "./GetOptions";
import Details from './Details';

export default function JobRiskAnalysisTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "jobRiskAnalysis");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [dialog, setDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedJRA, setSelectedJRA] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterCompany: null,
    filterDepartment: null,
    filterStart: null,
    filterEnd: null,
    filterUser: null
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "İş Tehlike Risk Analiz Sayısı", field: "number" },
      { title: "Personel", field: "userName" },
      { title: "Şirket", field: "companyName" },
      { title: "Bölüm", field: "departmentName" },
      { title: "Tarih", field: "date", render: 
        rowData => moment(rowData.date.replaceAll("Z", "")).format("DD-MM-YYYY") },
      { title: "Oluşturma Tarihi", field: "createdAt", render: 
        rowData => moment(rowData.createdAt.replaceAll("Z", "")).format("DD-MM-YYYY HH:mm") },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    data && setSelectedJRA(data);
    setModalLoading(true);
    setEditModal(true);
    let opts = {...options};
    (data && data.company) ? opts.departments = await getDepartments(data.company) : opts.departments = await getDepartments(user.companyId);
    (data && data.department) ? opts.users = await getUsers(data.department) : opts.users = [];
    setOptions(opts);
    setModalLoading(false);
  }

  const handleOpenDeleteDialog = (data) => {
    setSelectedJRA(data);
    setDialog(true);
  }

  const handleCloseDeleteDialog = () => {
    setSelectedJRA(null);
    setDialog(false);
  }

  const removeJRA = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", '/api/job-risk/' + selectedJRA?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const removeJRAs = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join()
    }
    const resp = await Request("delete", '/api/job-risk/', null, param);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterCompany: null,
      filterDepartment: null,
      filterStart: null,
      filterEnd: null,
      filterUser: null
    });
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeJRAs : (selectedJRA && removeJRA)}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <JobRiskAnalysisModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldJRAProps={selectedJRA}
        setOldJRAProps={setSelectedJRA}
        options={options}
        setOptions={setOptions}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters/>
      <Table
        tableName={"İş Tehlike Analizi"}
        authName={"jobRiskAnalysis"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        filters={filterProps}
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => <Details rowData={rowData}/>}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ")
        }
      />
    </>
  );
}
