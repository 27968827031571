import React, { useCallback } from 'react';
import Request from '../../../helpers/Request';
import { makeStyles } from '@material-ui/core/styles';
import LanguageHelper from '../../../helpers/LanguageHelper';
import FilledForm from '../../../components/Forms/FilledForm';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Divider, CircularProgress, Grid } from '@material-ui/core';
import moment from 'moment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import IconTooltipButton from '../../../components/Buttons/IconTooltipButton';
import ListOfFiles from '../../../components/Files/ListOfFiles';
import FetchData from './FetchData';
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from '../../../components/Table/Table';
import GridOnIcon from '@material-ui/icons/GridOn';
import TableBackdrop from '../../../components/Table/TableBackdrop';

/**
  * Request to get the details of the inspection.
  * @param {number} id id of the inspection
  * @returns {obj} a json object that contains inspection details
  */
const getInspectionDetails = async (id) => {
  const resp = await Request("get", "/api/inspections/" + id, null);
  return resp.data;
};

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2D3446',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
    width: "auto",
    height: "min-content"
  },
  inspectionButton: {
    marginLeft: 60
  },
  propBorder: {
    margin: "10px",
    border: '1px solid #595959',
    borderRadius: "10px",
    padding: "10px",
    fontSize: "0.8vw"
  },
  pdfIconLabel: {
    marginRight: "60px"
  },
  inspectionIconLabel: {
    marginRight: "60px"
  }
}));

/**
 * A table component that lists the forms. These forms can be opened in a new tab as pdf files.
 * The details of the inspection task that these forms are connected to can also be viewed in a modal.
 */
export default function FormTable() {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [inspectionDetails, setInspectionDetails] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [modalLoading, setModalLoading] = React.useState(false);
  const [backdropLoading, setBackdropLoading] = React.useState(false);

  /**
   * Opens the inspection details modal.
   * @param {number} id id of the inspection
   */
  const handleOpen = useCallback(async (id) => {
    setModalLoading(true);
    setOpen(true);
    setInspectionDetails(await getInspectionDetails(id));
    setModalLoading(false);
  }, [setOpen, setInspectionDetails]);

  /**
   * Closes the inspection details modal.
   */
  const handleClose = useCallback(() => {
    setOpen(false);
    setInspectionDetails(null);
  }, [setOpen, setInspectionDetails]);

  const RenderIdAndExportButtons = ({rowData}) => {
    return (
      <div style={{display:"flex", flexDirection:"row"}}>
        <label className={classes.pdfIconLabel}>{rowData.id}</label>
        <IconTooltipButton title={"Form'un PDF Dosyası"} onClick={() => FilledForm(rowData.id, false, setSnackbar, setSnackbarMessage, setSeverity, setBackdropLoading)}>
          <PictureAsPdfIcon />
        </IconTooltipButton>
        <IconTooltipButton title={"Form'un XLSX Dosyası"} onClick={() => FilledForm(rowData.id, true, setSnackbar, setSnackbarMessage, setSeverity, setBackdropLoading)}>
          <GridOnIcon />
        </IconTooltipButton>
      </div>
    )
  }

  const RenderTaskIdAndButton = ({rowData}) => {
    return (
      <>
        <label className={classes.inspectionIconLabel}>{rowData.inspectionId}</label>
        <IconTooltipButton title={language.tableDetails.inspection} onClick={() => handleOpen(rowData.inspectionId)}>
          <AssignmentIcon />
        </IconTooltipButton>
      </>
    )
  }

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.formId, field: "id", render: rowData => <RenderIdAndExportButtons rowData={rowData}/>},
      { title: language.tableColumns.inspectionId, field: "inspectionId", render: rowData => <RenderTaskIdAndButton rowData={rowData}/>},
      { title: language.tableColumns.userId, field: "userId" },
      { title: language.tableColumns.templateTitle, field: "templateTitle" },
      { title: language.tableColumns.submissionDate, field: "submissionDate" },
      { title: language.tableColumns.name, field: "fullName" },
    ]);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={language.sidebar.forms}
        authName="formTable"
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={rowData =>
          <div className={classes.details}>
            <label style={{ margin: 30 }}>{language.tableDetails.files}</label>
            {rowData && rowData.fileKeys?.length > 0
              ?
              <>
                <ListOfFiles fileKeys={rowData.fileKeys}></ListOfFiles>
              </>
              :
              <p style={{color: "red", fontSize: 16}}>{language.tableDetails.noFile}<br /></p>}
          </div>
        }
      />
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={open}>
          <div className={classes.paper}>
            {inspectionDetails === null ? <CircularProgress color="secondary" /> :
              <Grid container>
                <Grid item>
                  <div className={classes.propBorder}><h2>{language.tableColumns.inspectionId}</h2><Divider /><p>{inspectionDetails && inspectionDetails.id}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.name}</h2><Divider /><p>{inspectionDetails && inspectionDetails.user.fullName}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.inspectionType}</h2><Divider /><p>{inspectionDetails && inspectionDetails.inspectionType}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.unsuitability}</h2><Divider /><p>{inspectionDetails && inspectionDetails.unsuitability.toString()}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.status}</h2><Divider /><p>{inspectionDetails && inspectionDetails.eStatus}</p></div>
                </Grid>
                <Grid item>
                  <div className={classes.propBorder}><h2>{language.tableColumns.inspectionDate}</h2><Divider /><p>{inspectionDetails && (new moment(inspectionDetails.completionDate)).format("DD-MM-YYYY")}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.terminationDate}</h2><Divider /><p>{inspectionDetails && (new moment(inspectionDetails.terminationTime)).format("DD-MM-YYYY")}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.company}</h2><Divider /><p>{inspectionDetails && inspectionDetails.company.name}</p></div>
                  <div className={classes.propBorder}><h2>{language.tableColumns.department}</h2><Divider /><p>{inspectionDetails && inspectionDetails.department.name}</p></div>
                </Grid>
                <Divider orientation="vertical" flexItem={true} style={{ marginRight: "10px" }} />
                <Grid item>
                  <h2 className={classes.text}>{language.tableDetails.files}</h2>
                  {inspectionDetails && inspectionDetails.fileKeys?.length > 0 ? <ListOfFiles fileKeys={inspectionDetails.fileKeys}></ListOfFiles> : <label>{language.tableDetails.noFile}</label>}
                </Grid>
              </Grid>}
          </div>
        </Fade>
      </Modal>
    </>
  );
}