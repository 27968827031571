import Request from "../../../helpers/Request";

// WORK ACCIDENTS ENDPOINTS

export const getWorkAccidentCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/industrial-accidents/graph/count",
    params
  );
  return resp.data;
};

export const getWorkAccidentCountBySeverity = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/industrial-accidents/graph/severity",
    params
  );
  return resp.data;
};

export const getWorkAccidentCountByType = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/industrial-accidents/graph/type",
    params
  );
  return resp.data;
};

export const getMissingDayCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/industrial-accidents/graph/missing",
    params
  );
  return resp.data;
};

export const getAccidentActionsCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request("post", "/api/actions/graph/accident", params);
  return resp.data;
};

// ACTION ENDPOINTS

export const getUnsuitCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/actions/graph/nonconformity",
    params
  );
  return resp.data;
};

export const getNearMissActionsCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request("post", "/api/actions/graph/near-miss", params);
  return resp.data;
};

export const getIsgBoardActionsCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/actions/graph/isg-committee",
    params
  );
  return resp.data;
};

export const getRiskAnalysisActionsCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/actions/graph/risk-analysis",
    params
  );
  return resp.data;
};

export const getDrillActionsCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/actions/graph/emergency-practise",
    params
  );
  return resp.data;
};

// NEAR MISS ENDPOINTS

export const getNearMissCount = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request("post", "/api/nearMiss/count", params);
  return resp.data;
};

// TRAINING ENDPOINTS

export const getCompletedTrainingHourByMonth = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/training/graph/completed-training-hour-by-month",
    params
  );
  return resp.data;
};

export const getCompletedTrainingHourByTrainingType = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/training/graph/completed-training-hour-by-training-type",
    params
  );
  return resp.data;
};

export const getCompletedTrainingManHourByMonth = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/training/graph/completed-training-man-hour-by-month",
    params
  );
  return resp.data;
};

export const getCompletedTrainingManHourByTrainingType = async (
  filterProps
) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/training/graph/completed-training-man-hour-by-training-type",
    params
  );
  return resp.data;
};

export const getCompletedTrainingPieceByTrainingType = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/training/graph/completed-training-piece-by-training-type",
    params
  );
  return resp.data;
};

export const getPersonnelCountByIsgTraining = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/training/graph/personnel-count-by-isg-training",
    params
  );
  return resp.data;
};

// HEALTH ENDPOINTS

export const getPersonnelCountByPeriodicExaminations = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/examination/graph/personnel-count-by-periodic-examinations",
    params
  );
  return resp.data;
};

export const getPersonnelCountByIllnessReports = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/examination/graph/personnel-count-by-report-received-for-illness",
    params
  );
  return resp.data;
};

export const getPersonnelCountByWorkEntryExaminations = async (filterProps) => {
  const params = {
    companyIds: filterProps.companyIds.map((comp) => comp.id),
    departmentIds: filterProps.departmentIds.map((dept) => dept.id),
    regionIds: filterProps.regionIds.map((reg) => reg.id),
    townIds: filterProps.townIds.map((town) => town.id),
    departmentType: filterProps.departmentTypes,
    startDate: filterProps.startDate,
    endDate: filterProps.endDate,
  };
  const resp = await Request(
    "post",
    "/api/examination/graph/personnel-count-by-work-entry-examinations",
    params
  );
  return resp.data;
};

// FILTER ENDPOINTS

export const getRegions = async () => {
  const resp = await Request("get", "/api/cities-towns/regions");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let region = {};
    region["name"] = data[i].name;
    region["id"] = data[i].id;
    arr.push(region);
  }
  return arr;
};

export const getTowns = async (regionId) => {
  const resp = await Request(
    "get",
    "/api/cities-towns/towns-of-region/" + regionId
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] = data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info/");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getDepartmentTypes = async () => {
  const resp = await Request("get", "/api/enums/department-types/");
  const data = resp.data;
  return data;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [regions, companies, departmentTypes] = await Promise.all([
    getRegions(),
    getCompanies(),
    getDepartmentTypes(),
  ]);
  const options = {
    regions,
    companies,
    departmentTypes,
    departments: [],
    towns: [],
  };
  return options;
}
