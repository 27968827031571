import moment from 'moment';
import LanguageHelper from '../../../helpers/LanguageHelper';
import Request from '../../../helpers/Request';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    let departmentIds = [];
    let regionIds = [];
    let yearMonths = [];
    for(let department of filterProps.departmentIds) {
      departmentIds.push(department.id);
    }
    for(let region of filterProps.regionIds) {
      regionIds.push(region.id);
    }
    for(let month of filterProps.months) {
      yearMonths.push(new moment(month).format("YYYY-MM-DD"));
    }
    let params = { departmentIds: departmentIds, regionIds: regionIds, yearMonths: yearMonths, isForGraph: false };

    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      if (query.orderBy.field === "customId") { params.sort = "customId," + query.orderDirection; }
      if (query.orderBy.field === "notifyingUser") { params.sort = "user.fName,user.lName" + query.orderDirection; }
      if (query.orderBy.field === "incidentType") { params.sort = "nearMissType," + query.orderDirection; }
      if (query.orderBy.field === "incidentDate") { params.sort = "incidentDate," + query.orderDirection; }
      if (query.orderBy.field === "notificationDate") { params.sort = "notificationDate," + query.orderDirection; }
      if (query.orderBy.field === "company") { params.sort = "company.name," + query.orderDirection; }
      if (query.orderBy.field === "department") { params.sort = "department.name," + query.orderDirection; }
      if (query.orderBy.field === "townName") { params.sort = "town.name," + query.orderDirection; }
      if (query.orderBy.field === "description") { params.sort = "note," + query.orderDirection; }
    }
    if(query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("post", "/api/inspections/report/audit_scores_for_contractors", params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          region: content[i].region, 
          month: content[i].month,
          totalInspectionNumber: content[i].totalInspectionNumber,
          totalInspectionScore: content[i].totalInspectionScore,
          year: content[i].year,
          departmentName: content[i].departmentName,
          average: content[i].averageScore
        };
        temp.push(edit);
      }
      setNumOfEntries(temp.length);
      resolve({
        data: temp,
        totalCount: temp.length,
      })
    }
  })
  return data;
}