import React from "react";
import Request from "../../../../helpers/Request";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function RemoveDialog({
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  openDialog,
  setOpenDialog,
  deleteTownId,
  deleteRegionId,
  deleteTownFromRegionIds,
  regions,
  setRegions,
  towns,
  setTowns
}) {
  const language = LanguageHelper.getLanguage();
  const [loading, setLoading] = React.useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteTown = async () => {
    setLoading(true);
    const resp = await Request(
      "delete",
      "/api/cities-towns/" + deleteTownId
    );
    console.log(resp);
    if(resp?.status !== 200) { 
      setSeverity("error");
      setSnackbarMessage(resp?.data?.message);
      setSnackbar(true);
    } else {
      let tempTowns = [...towns];
      for(let i in tempTowns) {
        if(tempTowns[i]?.id === deleteTownId) {
          tempTowns?.splice(i, 1);
        }
      }
      setTowns(tempTowns);
    }
    setOpenDialog(false);
    setLoading(false);
  };

  const deleteRegion = async () => {
    setLoading(true);
    const resp = await Request(
      "delete",
      "/api/cities-towns/" + deleteRegionId
    );
    console.log(resp);
    if(resp?.status !== 200) { 
      setSeverity("error");
      setSnackbarMessage(resp?.data?.message);
      setSnackbar(true);
    } else {
      let tempRegions = [...regions];
      for(let i in tempRegions) {
        if(tempRegions[i]?.id === deleteRegionId) {
          tempRegions[i].splice(i, 1);
        }
      }
      setRegions(tempRegions);
    }
    setOpenDialog(false);
    setLoading(false);
  };

  const removeTownFromRegion = async () => {
    setLoading(true);
    const townId = deleteTownFromRegionIds[0];
    const regionId = deleteTownFromRegionIds[1];
    const resp = await Request(
      "put",
      "/api/cities-towns/update-town/" + townId + "/-1"
    );
    console.log(resp);
    if(resp?.status !== 200) { 
      setSeverity("error");
      setSnackbarMessage(resp?.data?.message);
      setSnackbar(true);
    } else {
      let tempRegions = [...regions];
      let tempTowns = [...towns];
      for(let i in tempRegions) {
        if(tempRegions[i]?.id === regionId) {
          for(let j in tempRegions[i]?.towns) {
            if(tempRegions[i]?.towns[j]?.id === townId) {
              tempTowns.push(tempRegions[i]?.towns[j]);
            }
          }
          tempRegions[i]?.towns.splice(i, 1);
        }
      }
      setTowns(tempTowns);
      setRegions(tempRegions);
    }
    setOpenDialog(false);
    setLoading(false);
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.form.alert}</DialogTitle>
        <DialogActions>
          {loading ?
          <CircularProgress color="secondary"/>
          :
          <Button 
            onClick={
              (deleteTownId && deleteTown) ||
              (deleteRegionId && deleteRegion) ||
              (deleteTownFromRegionIds && removeTownFromRegion)
            } 
            color="secondary"
            >
            {language.form.delete}
          </Button>}
          <Button onClick={handleCloseDialog} color="secondary" autoFocus>
            {language.form.closeDialog}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
