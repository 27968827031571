import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import PieChart from "../../../components/Chart/PieChart";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import {
  getAccidentActionsCount,
  getUnsuitCount,
  getNearMissActionsCount,
  getIsgBoardActionsCount,
  getRiskAnalysisActionsCount,
  getDrillActionsCount,
} from "./GetOptions";
import { actionStatusByNumber } from "../../../assets/constants";
import { Footer } from "./Settings";

const fixedHeight = 650;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  section: {
    height: window.innerHeight > 900 ? "75vh" : "70vh",
  },
}));

export default function ActionCharts({ setBackdropLoading, filterProps }) {
  const user = SessionHelper.getUser();
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "dashboard");
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [accidentActionsCount, setAccidentActionsCount] = React.useState(null);
  const [unsuitCount, setUnsuitCount] = React.useState(null);
  const [nearMissActionsCount, setNearMissActionsCount] = React.useState(null);
  const [isgBoardActionsCount, setIsgBoardActionsCount] = React.useState(null);
  const [riskAnalysisActionsCount, setRiskAnalysisActionsCount] =
    React.useState(null);
  const [drillActionsCount, setDrillActionsCount] = React.useState(null);
  const [allActionsCount, setAllActionsCount] = React.useState(null);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const [
      accidentActionsCountData,
      unsuitCountData,
      nearMissActionsCountData,
      isgBoardActionsCountData,
      riskAnalysisActionsCountData,
      drillActionsCountData,
    ] = await Promise.all([
      getAccidentActionsCount(filterProps),
      getUnsuitCount(filterProps),
      getNearMissActionsCount(filterProps),
      getIsgBoardActionsCount(filterProps),
      getRiskAnalysisActionsCount(filterProps),
      getDrillActionsCount(filterProps),
    ]);
    setAccidentActionsCount(formatDataForPieChart(accidentActionsCountData));

    setUnsuitCount(formatDataForPieChart(unsuitCountData));
    setNearMissActionsCount(formatDataForPieChart(nearMissActionsCountData));
    setIsgBoardActionsCount(formatDataForPieChart(isgBoardActionsCountData));
    setRiskAnalysisActionsCount(
      formatDataForPieChart(riskAnalysisActionsCountData)
    );
    setDrillActionsCount(formatDataForPieChart(drillActionsCountData));
    setAllActionsCount(
      formatDataForAllActionsPieChart(
        accidentActionsCountData,
        unsuitCountData,
        nearMissActionsCountData,
        isgBoardActionsCountData,
        riskAnalysisActionsCountData,
        drillActionsCountData
      )
    );
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    filterProps.startDate,
    filterProps.endDate,
    filterProps.companyIds,
    filterProps.regionIds,
    filterProps.townIds,
    filterProps.departmentIds,
    filterProps.departmentTypes,
  ]);
  React.useEffect(() => {
    init();
  }, [init]);

  const formatDataForPieChart = (data) => {
    let chartData = [];
    let totalCount = 0;
    for (let p in data) {
      totalCount += data[p].count ?? 0;
    }
    for (let p in data) {
      const row = {
        name: actionStatusByNumber[data[p].value],
        value: data[p].count ?? 0,
        percent: ((data[p].count / totalCount) * 100).toFixed(2),
      };
      chartData.push(row);
    }
    return chartData;
  };

  const formatDataForAllActionsPieChart = (
    accidentAction,
    unsuit,
    nearMissAction,
    isgBoardAction,
    riskAnalysisAction,
    drillAction
  ) => {
    let chartData = [];
    let totalCount = 0;
    // calculate all action count
    for (let p in accidentAction) {
      totalCount += accidentAction[p].count ?? 0;
    }
    for (let p in unsuit) {
      totalCount += unsuit[p].count ?? 0;
    }
    for (let p in nearMissAction) {
      totalCount += nearMissAction[p].count ?? 0;
    }
    for (let p in isgBoardAction) {
      totalCount += isgBoardAction[p].count ?? 0;
    }
    for (let p in riskAnalysisAction) {
      totalCount += riskAnalysisAction[p].count ?? 0;
    }
    for (let p in drillAction) {
      totalCount += drillAction[p].count ?? 0;
    }
    for (let p in accidentAction) {
      checkChartDataAndUpdate(chartData, accidentAction[p], totalCount);
    }
    for (let p in unsuit) {
      checkChartDataAndUpdate(chartData, unsuit[p], totalCount);
    }
    for (let p in nearMissAction) {
      checkChartDataAndUpdate(chartData, nearMissAction[p], totalCount);
    }
    for (let p in isgBoardAction) {
      checkChartDataAndUpdate(chartData, isgBoardAction[p], totalCount);
    }
    for (let p in riskAnalysisAction) {
      checkChartDataAndUpdate(chartData, riskAnalysisAction[p], totalCount);
    }
    for (let p in drillAction) {
      checkChartDataAndUpdate(chartData, drillAction[p], totalCount);
    }
    return chartData;
  };

  const checkChartDataAndUpdate = (chartData, action, totalCount) => {
    // if there is an object in chartData with action status key
    // find index
    const itemIndex = chartData.findIndex(
      (e) => e.name === actionStatusByNumber[action.value]
    );
    // if item exists
    if (itemIndex !== -1) {
      // update object's count and percentage data
      chartData[itemIndex].value += action.count ?? 0;
      chartData[itemIndex].percent = (
        (chartData[itemIndex].value / totalCount) *
        100
      ).toFixed(2);
    } else {
      // if item is not exist with action status key
      // create new object and push to chartData array
      const row = {
        name: actionStatusByNumber[action.value],
        value: action.count ?? 0,
        percent: ((action.count / totalCount) * 100).toFixed(2),
      };
      chartData.push(row);
    }
  };

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={allActionsCount}
              title={"Durumlarına Göre Tüm Aksiyonlar"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={accidentActionsCount}
              title={"Durumlarına Göre İş Kazası Aksiyonları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={unsuitCount}
              title={"Durumlarına Göre Uygunsuzluk Giderme Görevleri"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={nearMissActionsCount}
              title={"Durumlarına Göre Ramak Kala Aksiyonları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={isgBoardActionsCount}
              title={"Durumlarına Göre İSG Kurul Aksiyonları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={riskAnalysisActionsCount}
              title={"Durumlarına Göre Risk Analizi Aksiyonları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={drillActionsCount}
              title={"Durumlarına Göre Tatbikat Aksiyonları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
