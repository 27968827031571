import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @param {func} setData a function to set the newly received data
 * An important note about setData: data state itself is never used directly, only the setData function
 * is used to update the page when new data is received.
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",
    };

    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "title") {
        params.sort = "title," + query.orderDirection;
      }
    }

    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/form-templates", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          title: data[i].title,
        };
        temp.push(edit);
      }
      setNumOfEntries(resp.data.totalElements);
      resolve({
        data: temp,
        page: resp.data.pageable.pageNumber,
        totalCount: resp.data.totalElements,
      });
    }
  });
  return data;
}
