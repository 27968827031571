import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetOptions from "./GetOptions";
import KKDTaskModal from './KKDTaskModal';
import KKDTable from '../kkd/KKDTable';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

export default function KKDTaskTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "kkdTask");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedKKDTask, setSelectedKKDTask] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Görev Adı", field: "taskName" },
      { title: "Cinsi", field: "type", lookup: options.taskTypes },
      { title: "Şirket", field: "companyName" },
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedKKDTask(data);
    setModal(true);
  }

  const handleOpenDeleteDialog = (data) => {
    setSelectedKKDTask(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedKKDTask(null);
    setDialog(false);
  };

  const removeKKDTask = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", '/api/kkd-tasks/' + selectedKKDTask?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeKKDTask}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <KKDTaskModal
        modal={modal} 
        setModal={setModal} 
        setSnackbarMessage={setSnackbarMessage} 
        setSnackbar={setSnackbar} 
        setSeverity={setSeverity}
        tableRef={tableRef} 
        opts={options}
        kkdTaskProps={selectedKKDTask}
      />
      <Table
        tableName={"Görevler"}
        authName="kkdTask"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => authorization?.delete}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        //deleteEntry={removeKKDTask}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={rowData => 
          <div style={{
            backgroundColor: "#2D3446",
            color: "white",
            textAlign: "center",
            alignItems: "center",
            padding: 20
          }}>
            <KKDTable task={{
              taskId: rowData.id,
              taskName: rowData.taskName,
              kkdIds: []
            }}  />
          </div>
        }
        //rowStyle={rowData => ({backgroundColor: !rowData.active && "#804f4f"})}
      />
    </>
  );
}