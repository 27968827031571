import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Divider, Grid } from "@material-ui/core";
import Request from "../../../helpers/Request";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    color: "#66a1ff",
    backgroundColor: "#2D3446",
    maxHeight: 700,
    overflow: "auto",
    textAlign: "center",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  log: {
    textAlign: "left",
    fontSize: "18px",
  },
  span: {
    fontSize: "16px",
    color: "white",
  },
}));

/**
 * Second page for the details panel, shows the changes made to the table as logs.
 * @param {rowData: ojb, tableRef: obj} params props of the table
 */
export default function Logs({ rowData, tableRef }) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  /**
   * Fetches the logs from the server.
   */
  const getLogs = useCallback(async () => {
    const resp = await Request("get", "/api/epdk/single-row/logs/", null, {
      id: rowData.casualtyId,
    });
    console.log(resp);
    return resp.data;
  }, [rowData]);

  /**
   * Creates a JSX <p> tag to show the elements of the logs, then adds these paragraphs to the logArray.
   * @param {obj[]} logs
   */
  const formatLogs = useCallback(
    async (logs) => {
      console.log(logs);
      let logArray = [];
      for (let i = 0; i < logs.length; i++) {
        if (logs[i].targetTable === "EPDKSingleRow") {
          const p = (
            <p className={classes.log}>
              {language.logs.id}:{" "}
              <span className={classes.span}>
                {logs[i].id}
                <br />
              </span>
              {language.logs.date}:{" "}
              <span className={classes.span}>
                {new moment(logs[i].createdDate).format("DD-MM-YYYY HH:mm")}
                <br />
              </span>
              {language.logs.user}:{" "}
              <span className={classes.span}>
                {logs[i].user.fName + " " + logs[i].user.lName}
                <br />
              </span>
              {language.logs.change}:{" "}
              <span className={classes.span}>
                {logs[i].txtArea}
                <br />
              </span>
            </p>
          );
          logArray.push(p);
        }
      }
      return logArray;
    },
    [classes, language]
  );

  const init = useCallback(async () => {
    const logs = await getLogs();
    setLogs(await formatLogs(logs));
    logs && logs.length > 0 && setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [formatLogs, getLogs]);
  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Iterates through the logs to render them.
   */
  const printLogs = () => {
    return logs.map((data, index) => {
      return (
        <Grid justifyContent="flex-start" alignItems="flex-start" key={index}>
          {logs[index]}
          {index !== logs.length - 1 && <Divider />}
        </Grid>
      );
    });
  };

  return (
    <div className={classes.details}>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : logs.length > 0 ? (
        printLogs()
      ) : (
        <p style={{ color: "red" }}>Kayıt Yok.</p>
      )}
    </div>
  );
}
