import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import BarChart from "../../components/Chart/BarChart";
import SessionHelper from "../../helpers/SessionHelper";
import Copyright from "../../components/Footer/Copyright";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import moment from "moment";
import SaveAlt from "@material-ui/icons/SaveAlt";
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import TableBackdrop from "../../components/Table/TableBackdrop";
import ChartsPDF from "../../components/Reports/ChartsPDF";
import { Bar, LabelList } from "recharts";
import {
  eventPerTypeForDepartment,
  eventPerTypePerUserForDepartment,
} from "../../components/Chart/ChartData";
import { MonthPicker } from "../../components/Selectors/DatePickers";
import "moment/locale/tr";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import Request from "../../helpers/Request";
import { TextField } from "@material-ui/core";
import { inspectionTypes } from "../../assets/constants";
import UnauthorizedPage from "../UnauthorizedPage";

const COLORS = ["#0088FE", "#FFBB28"];
const fixedHeight = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  footerComponent: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 40
  },
  rate: {
    fontSize: 18
  },
  percentage: {
    margin: 115,
  },
  dateFooter: {
    marginTop: 30,
    marginLeft: 0,
    marginRight: "auto",
  },
}));

/**
 * A dashboard component that renders charts depending on the role of the user.
 */
export default function GoalCharts() {
  moment.locale("tr");
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "comitteeDepartmentGoalCharts");
  const isAdmin = roles.includes("ROLE_SİSTEM_ADMİNİ");
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const collator = new Intl.Collator("tr");

  const [edcs, setEdcs] = React.useState([]);
  const [edc, setEdc] = React.useState(null);
  const [department, setDepartment] = React.useState(null);
  const [departments, setDepartments] = React.useState([]);
  const [eventPerTypeForDepartmentData, setEventPerTypeForDepartmentData] = React.useState(null);
  const [eventPerTypePerUserForDepartmentData, setEventPerTypePerUserForDepartmentData] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    new moment().set("date", 1).set("hour", 0).set("minute", 0).toISOString(true)
  );
  const [endDate, setEndDate] = React.useState(
    new moment().add("month", 1).set("date", 1).set("hour", 23).set("minute", 59).add("days", -1).toISOString(true)
  );

  const init = useCallback(async () => {
    setBackdropLoading(true);
    !isAdmin && !department && setDepartment(user?.department?.id);
    departments.length === 0 && setDepartments(await getDepartments());
    setEventPerTypeForDepartmentData(
      await eventPerTypeForDepartment(startDate, endDate, department)
    );
    setEventPerTypePerUserForDepartmentData(
      await eventPerTypePerUserForDepartment(startDate, endDate, department)
    );
    isAdmin && setEdcs(await getEdcs());
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, startDate, department]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getEdcs = async () => {
    let resp;
    resp = await Request("get", "/api/companies/type-edc");
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let comp = {};
      comp["name"] = data[i].name;
      comp["id"] = data[i].id;
      arr.push(comp);
    }
    return arr;
  };

  const getDepartments = async (edcId) => {
    let arr = [];
    const params = {
      "only-edc": true,
      "edc-id": isAdmin ? edcId : undefined
    };
    /* if(SessionHelper.getUser().roles.includes("ROLE_SİSTEM_ADMİNİ")) {
      const resp = await Request("get", "/api/departments/" + user.id + "/get-according-to-user/table", null, params);
      const comps = resp.data;
      for(let prop in comps) {
        const data = comps[prop];
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          let dept = {};
          dept["companyName"] = data[i].companyName;
          dept["name"] = data[i].name;
          dept["id"] = data[i].id;
          arr.push(dept);
        }
      }
    } else { */
    const resp = await Request("get", "/api/departments/" + user.id + "/get-according-to-user", null, params);
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
    return arr;
  };

  const exportPdf = async () => {
    setBackdropLoading(true);
    ChartsPDF(
      document.getElementsByClassName(
        "MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-3"
      )[1],
      fixedHeight,
      setBackdropLoading
    );
  };

  /**
   * Formats the data array to use with the chart.
   * @param {data: obj[]} data chart data to format
   * @return {obj[]} formatted data
   */
  const formatDataForBarGeneralEventsChart = (data) => {
    let chartData = [];
    for (let p in data) {
      let name = "";
      let row = {};
      for (let prop in data[p]) {
        if (data[p][prop] !== null) {
          if (prop === "goalInMonths") {
            row["Hedef"] = data[p][prop];
          } else if (prop === "completedInspections") {
            row["Gerçekleşen"] = data[p][prop];
          } else if (prop === "type") {
            name = inspectionTypes[`${data[p][prop]}`];
          }
        }
      }
      row.name = name;
      chartData.push(row);
    }
    return chartData;
  };
  
  /**
   * Formats the data array to use with the chart.
   * @param {data: obj[]} data chart data to format
   * @return {obj[]} formatted data
   */
  const formatDataForBarSpecificEventChart = (data, dataSet) => {
    let chartData = [];
    for (let p in data) {
      let name = "";
      let row = {};
      for (let prop in data[p]) {
        if (data[p][prop] !== null) {
          if (prop === "statistics") {
            let type = null;
            if (dataSet === "oditEventPerUserForDepartment") {
              type = data[p][prop][0];
            } else if (dataSet === "apekEventPerUserForDepartment") {
              type = data[p][prop][2];
            } else if (dataSet === "isgEventPerUserForDepartment") {
              type = data[p][prop][3];
            } else if (dataSet === "briefingEventPerUserForDepartment") {
              type = data[p][prop][1];
            }
            row["Hedef"] = type.goalInMonths;
            row["Gerçekleşen"] = type.completedInspections;
          } else if (prop === "user") {
            name = data[p][prop].fName + " " + data[p][prop].lName;
          }
        }
      }
      row.name = name;
      chartData.push(row);
    }
    return chartData;
  };

  const completionRate = useCallback(
    (data) => {
      let innerData = data.data;
      return (
        <div className={`barDateFooter ${classes.footerComponent}`}>
          {innerData[0] && (
            <div style={{display: "flex", flexDirection: "column"}}>
              <label className={`barDateFooter ${classes.rate}`}>{"Gerçekleşme Oranı:\n"}</label>
              <label className={`barDateFooter ${classes.rate}`}>
                <span className={classes.percentage}>
                  %{((innerData[0]["Gerçekleşen"] / innerData[0]["Hedef"]) * 100).toFixed(2)}
                </span>
                <span className={classes.percentage}>
                  %{((innerData[1]["Gerçekleşen"] / innerData[1]["Hedef"]) * 100).toFixed(2)}
                </span>
                <span className={classes.percentage}>
                  %{((innerData[2]["Gerçekleşen"] / innerData[2]["Hedef"]) * 100).toFixed(2)}
                </span>
                <span className={classes.percentage}>
                  %{((innerData[3]["Gerçekleşen"] / innerData[3]["Hedef"]) * 100).toFixed(2)}
                </span>
              </label>
            </div>
          )}
          <DateFooter />
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classes, startDate, endDate]
  );

  const DateFooter = useCallback(() => {
    return (
      <div className={classes.footerComponent}>
        <label className={`barDateFooter ${classes.dateFooter}`}>
          {moment(startDate).format("MMM yyyy")}
        </label>
      </div>
    );
  }, [startDate, classes]);

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const filterOptions = createFilterOptions({
    stringify: ({ name, companyName }) => `${name} ${companyName}`
  });

  const RenderEDCAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={edcs?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        defaultValue={edc ? getFromId(edc, edcs) : null}
        onChange={async (event, value) => {
          setEdc(value?.id);
          isAdmin && setDepartments(await getDepartments(value?.id));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="EDC"
            className={classes.textField}
          />
        )}
      />
    );
  };

  const RenderAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={departments?.sort(function(a, b) {
          return collator.compare(a.name, b.name);
        })?.sort(function(a, b) {
          return collator.compare(a.companyName, b.companyName);
      })}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.companyName}
        disabled={isAdmin && edc == null}
        filterOptions={filterOptions}
        fullWidth={true}
        defaultValue={department ? getFromId(department, departments) : null}
        onChange={(event, value) => setDepartment(value?.id)}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Bölüm"
            className={classes.textField}
          />
        )}
      />
    );
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, offset } = props;

    const xOffset = width / 2 + offset - 1;
    const yOffset = height / 2 + 5;

    return (
      <text x={x + xOffset} y={y + yOffset} fill={"black"} textAnchor="end">
        {value === 0 ? "" : value}
      </text>
    );
  };

  return (
    <div className={classes.root}>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CssBaseline />
      {authorization?.view ?
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} justifyContent="space-between">
            {/* SETTINGS */}
            <Grid item xs={10}>
              <Paper className={classes.paper} style={{ height: 100 }}>
                <Grid container>
                  <Grid xs={isAdmin ? 4 : 6}>
                    <MonthPicker
                      style={classes.textField}
                      label="Rapor Tarihi"
                      //value={moment(startDate)}
                      value={moment(startDate).add(3, "hour")}
                      shrink={startDate}
                      onChangeFunc={(value) => {
                        setStartDate(moment(value).set("date", 1).set("hour", 0).set("minute", 0).toISOString(true));
                        setEndDate(moment(value).set("date", 1).set("hour", 23).set("minute", 59).add(1, "month").add(-1, "days").toISOString(true));
                        // setStartDate(moment(value).toISOString(true));
                        // setEndDate(
                        //   moment(value)
                        //     .add(1, "month")
                        //     .add(-1, "day")
                        //     .set("hour", 23)
                        //     .set("minute", 59)
                        //     .toISOString(true)
                        // );
                      }}
                    />
                  </Grid>
                  {isAdmin &&
                    <Grid xs={4}>
                      <RenderEDCAutocomplete />
                    </Grid>}
                  <Grid xs={isAdmin ? 4 : 6}>
                    <RenderAutocomplete />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* EXPORT BUTTON */}
            <Grid item>
              <Paper
                className={classes.paper}
                style={{ height: 100, width: 100 }}
              >
                <div style={{ margin: "auto", marginLeft: "32%" }}>
                  <IconTooltipButton
                    title="PDF Olarak İndir"
                    onClick={exportPdf}
                  >
                    <SaveAlt />
                  </IconTooltipButton>
                </div>
              </Paper>
            </Grid>

            <Grid container spacing={3}>
              {/* CHARTS */}
              {authorization.view && (
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <BarChart
                      dataSet={formatDataForBarGeneralEventsChart(
                        eventPerTypeForDepartmentData
                      )}
                      title={"Yaşam Güvenliği Komitesi Gerçekleşmeleri"}
                      FooterComponent={(data) => completionRate(data)}
                    >
                      <Bar
                        isAnimationActive={false}
                        dataKey="Hedef"
                        fill={COLORS[0]}
                      >
                        <LabelList
                          dataKey="Hedef"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                      <Bar
                        isAnimationActive={false}
                        dataKey="Gerçekleşen"
                        fill={COLORS[1]}
                      >
                        <LabelList
                          dataKey="Gerçekleşen"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                    </BarChart>
                  </Paper>
                </Grid>
              )}
              {authorization.view && (
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <BarChart
                      dataSet={formatDataForBarSpecificEventChart(
                        eventPerTypePerUserForDepartmentData,
                        "oditEventPerUserForDepartment"
                      )}
                      title={"ODİT (Davranış Odaklı Denetim) Gerçekleşmeleri"}
                      FooterComponent={DateFooter}
                    >
                      <Bar
                        isAnimationActive={false}
                        dataKey="Hedef"
                        fill={COLORS[0]}
                      >
                        <LabelList
                          dataKey="Hedef"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                      <Bar
                        isAnimationActive={false}
                        dataKey="Gerçekleşen"
                        fill={COLORS[1]}
                      >
                        <LabelList
                          dataKey="Gerçekleşen"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                    </BarChart>
                  </Paper>
                </Grid>
              )}
              {authorization.view && (
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <BarChart
                      dataSet={formatDataForBarSpecificEventChart(
                        eventPerTypePerUserForDepartmentData,
                        "apekEventPerUserForDepartment"
                      )}
                      title={
                        "APEK (Aylık Planlı Emniyet Kontrolü) Gerçekleşmeleri"
                      }
                      FooterComponent={DateFooter}
                    >
                      <Bar
                        isAnimationActive={false}
                        dataKey="Hedef"
                        fill={COLORS[0]}
                      >
                        <LabelList
                          dataKey="Hedef"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                      <Bar
                        isAnimationActive={false}
                        dataKey="Gerçekleşen"
                        fill={COLORS[1]}
                      >
                        <LabelList
                          dataKey="Gerçekleşen"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                    </BarChart>
                  </Paper>
                </Grid>
              )}
              {authorization.view && (
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <BarChart
                      dataSet={formatDataForBarSpecificEventChart(
                        eventPerTypePerUserForDepartmentData,
                        "isgEventPerUserForDepartment"
                      )}
                      title={"İSG ve Çevre Uygunsuzlukları Gerçekleşmeleri"}
                      FooterComponent={DateFooter}
                    >
                      <Bar
                        isAnimationActive={false}
                        dataKey="Hedef"
                        fill={COLORS[0]}
                      >
                        <LabelList
                          dataKey="Hedef"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                      <Bar
                        isAnimationActive={false}
                        dataKey="Gerçekleşen"
                        fill={COLORS[1]}
                      >
                        <LabelList
                          dataKey="Gerçekleşen"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                    </BarChart>
                  </Paper>
                </Grid>
              )}
              {authorization.view && (
                <Grid item xs={12}>
                  <Paper className={fixedHeightPaper}>
                    <BarChart
                      dataSet={formatDataForBarSpecificEventChart(
                        eventPerTypePerUserForDepartmentData,
                        "briefingEventPerUserForDepartment"
                      )}
                      title={
                        "Bire Bir Brifing Yaşam Güvenliği Konuşmaları Gerçekleşmeleri"
                      }
                      FooterComponent={DateFooter}
                    >
                      <Bar
                        isAnimationActive={false}
                        dataKey="Hedef"
                        fill={COLORS[0]}
                      >
                        <LabelList
                          dataKey="Hedef"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                      <Bar
                        isAnimationActive={false}
                        dataKey="Gerçekleşen"
                        fill={COLORS[1]}
                      >
                        <LabelList
                          dataKey="Gerçekleşen"
                          position="center"
                          content={renderCustomizedLabel}
                          style={{ fill: "white" }}
                        />
                      </Bar>
                    </BarChart>
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright
              string={"DELTA Smart Technologies"}
              url={"https://deltasmart.tech/en/home/"}
              date={new Date().getFullYear()}
            />
          </Box>
        </Container>
      </main>
      : <UnauthorizedPage/>}
    </div>
  );
}
