import Request from "../../../helpers/Request";

export const getKKDs = async () => {
  const resp = await Request("get", "/api/kkds/all");
  const data = resp.data;
  // let arr = [];
  // for (let i = 0; i < data.length; i++) {
  //   let kkd = {};
  //   kkd["name"] = data[i].kkdName;
  //   kkd["id"] = data[i].id;
  //   arr.push(kkd);
  // }
  // return arr;
  let obj = {};
  for (let i = 0; i < data.length; i++) {
    obj[data[i].id] = data[i].kkdName;
  }
  return obj;
};

export const getKKDKinds = async () => {
  const resp = await Request("get", "/api/kkd-kinds/all");
  const data = resp.data;
  let obj = {};
  for (let i = 0; i < data.length; i++) {
    obj[data[i].id] = data[i].name;
  }
  return obj;
};


// export const getKKDKinds = async () => {
//   const resp = await Request("get", "/api/kkd-kinds/all");
//   const data = resp.data;
//   let arr = [];
//   for (let i = 0; i < data.length; i++) {
//     arr.push({id: data[i].name, name: data[i].name})
//   }
//   return data;
// };

const convertToArray = (obj) => {   
  let arr = [];
  for(let key in obj) {
    arr.push({id: obj[key], name: obj[key]})
  }
  return arr;
}

export default async function GetOptions() {
  const types = {
    EKİPSEL: "Ekipsel",
    KİSİSEL: "Kişisel"
  };
  const [kkds, kkdKinds] = await Promise.all([
    getKKDs(), getKKDKinds()
  ]);
  const kkdKindsArray = convertToArray(kkdKinds);
  const options = {types, kkds, kkdKinds, kkdKindsArray};
  return options;
};
