import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Divider, Grid } from '@material-ui/core';
import LanguageHelper from '../../../../helpers/LanguageHelper';
import moment from 'moment';
import ListOfFiles from '../../../../components/Files/ListOfFiles';

const useStyles = makeStyles((theme) => ({
  formButton: {
    cursor: "pointer",
    margin: 5
  },
  formButtonLabel: {
    cursor: "pointer"
  },
  formContainer: {
    margin: "0 auto",
    marginTop: 15,
    marginBottom: 10,
    width: "80%",
    border: "1px solid black",
    borderRadius: "15px",
    backgroundColor: "#c5c8db"
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: 15,
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  CustomDatePicker: {
    width: "100%"
  },
  unsuitReportButton: {
    cursor: "pointer", 
    marginTop: 30
  },
  unsuitReportLabel: {
    cursor: "pointer"
  },
  createdMessage: {
    color: "#63cbf7", 
    fontSize: 15
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
    minWidth: "30%",
    maxHeight: "90%"
  },
  formsContainer: {
    margin: "0 auto",
    width: "70%"
  },
  formDivider: {
    margin: "0 auto",
    marginTop: 5,
    marginBottom: 5,
  },
  description: {
    overflow: "auto", 
    maxHeight: 200, 
    whiteSpace: "pre-wrap", 
    maxWidth: 600
  },
  filesContainer: {
    overflow: "auto", 
    maxHeight: 300,
    maxWidth: 600
  }
}));

export default function PrintDetails({ formUnsuitDetails }) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  let messageString = "Yok\n";
  
  if(formUnsuitDetails) {
    const messages = formUnsuitDetails.nonconformityMessageList;
    if(messages.length > 0) {messageString = ""}
    for(let i = 0; i < messages.length; i++) {
      messages[i].noteTime ? 
      messageString += `${messages[i].user.fName} ${messages[i].user.lName}: ${messages[i].note} (${(new moment(messages[i].noteTime)).format("HH:mm DD-MM-YYYY")})\n`
      : 
      messageString += `${messages[i].user.fName} ${messages[i].user.lName}: ${messages[i].note} (${(new moment()).format("HH:mm DD-MM-YYYY")})\n`;
    }
  }

  return(
    formUnsuitDetails ?
    <>
      <Grid container>
        <Grid item>
          <div className={classes.propBorder}><h2>{language.tableColumns.id}</h2><Divider /><p>{formUnsuitDetails.id}</p></div>
          <div className={classes.propBorder}><h2>{language.tableColumns.name}</h2><Divider /><p>{formUnsuitDetails.user.fName + " " + formUnsuitDetails.user.lName}</p></div>
          <div className={classes.propBorder}><h2>{language.tableColumns.userId}</h2><Divider /><p>{formUnsuitDetails.user.id}</p></div>
          <div className={classes.propBorder}><h2>{language.tableColumns.department}</h2><Divider /><p>{formUnsuitDetails.department.name}</p></div>
          <div className={classes.propBorder}><h2>{language.tableColumns.terminationDate}</h2><Divider /><p>{(new moment(formUnsuitDetails.terminationTime)).format("DD-MM-YYYY")}</p></div>
          <div className={classes.propBorder}><h2>{language.tableColumns.status}</h2><Divider /><p>{formUnsuitDetails.eStatus}</p></div>
        
        </Grid>
        <Divider orientation="vertical" flexItem={true} style={{ marginRight: "10px", marginLeft: "10px" }} />
        <Grid item>
          <div className={classes.propBorder}><h2>{language.tableDetails.description}</h2><Divider />
            <p className={classes.description}>{messageString}</p>
          </div>  
          <h2 className={classes.text}>{language.tableDetails.files}</h2>
          <div className={classes.filesContainer}>
            {formUnsuitDetails.attachments?.length > 0 ? <ListOfFiles fileKeys={formUnsuitDetails.attachments}></ListOfFiles> : <p style={{fontSize: 14, color: "red"}}>{language.tableDetails.noFile}</p>}
          </div>
        </Grid>
      </Grid>
    </>
    :
    <CircularProgress color="secondary" />
  )
}