import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
// import showSnackbar from "../../../components/Utils/showSnackbar";
import VisitorsModal from "./VisitTypeModal";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
// import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import TypeDetails from "./TypeDetails";

const detailStyle = {
  backgroundColor: "#2D3446",
  color: "white",
  alignItems: "center",
  textAlign: "center",
  padding: 10,
};

export default function VisitTypesTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "visitTypes");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedVisitType, setSelectedVisitType] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  // const [dialog, setDialog] = React.useState(false);
  // const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: language.visitTypes.name, field: "name" },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = (data) => {
    setSelectedVisitType(data);
    setModal(true);
  };

  // const handleOpenDeleteDialog = (data) => {
  //   setSelectedVisitType(data);
  //   setDialog(true);
  // };

  // const handleCloseDeleteDialog = () => {
  //   setSelectedVisitType(null);
  //   setDialog(false);
  // };

  // const removeVisitor = async () => {
  //   setRequestLoading(true);
  //   const resp = await Request(
  //     "delete",
  //     "/api/kkd-tasks/" + selectedVisitType?.id // TODO Change endpoint
  //   );
  //   console.log(resp);
  //   showSnackbar(
  //     resp,
  //     setSeverity,
  //     setSnackbarMessage,
  //     setSnackbar,
  //     language.etc.deleted,
  //     tableRef
  //   );
  //   handleCloseDeleteDialog();
  //   setRequestLoading(false);
  // };

  return (
    <React.Fragment>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      {/* <DeleteDialog
        remove={removeVisitor}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      /> */}
      <VisitorsModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        visitTypeProps={selectedVisitType}
      />
      <Table
        tableName={language.sidebar.visitTypes}
        authName="visitors"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        // handleOpenDeleteModal={handleOpenDeleteDialog}
        // isDeletable={(rowData) => authorization?.delete}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => (
          <div style={detailStyle}>
            <TypeDetails rowData={rowData} />
          </div>
        )}
      />
    </React.Fragment>
  );
}
