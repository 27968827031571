import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import moment from "moment";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
}));

const propsDefault = {
  terminationTime: moment().toISOString(true),
};

export default function UnsuitModal({
  unsuitProps,
  setUnsuitModal,
  modalLoading,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState({ propsDefault });
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (unsuitProps) {
      setProps({ terminationTime: unsuitProps.terminationTime });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsuitProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const updateRecord = async () => {
    setLoading(true);
    const resp = await Request(
      "patch",
      "/api/unsuitInspections/" + unsuitProps.id,
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setProps(propsDefault);
    setUnsuitModal(null);
  };

  return (
    <TableEntryModal
      modal={unsuitProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <CustomDatePicker //PLANNED DATE
          style={classes.dateField}
          label={"Termin Tarihi"}
          value={props?.terminationTime}
          shrink={props?.terminationTime}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              terminationTime: moment(value).toISOString(true),
            })
          }
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => updateRecord()}
              className={classes.submit}
            >
              {"GÜNCELLE"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
