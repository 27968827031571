import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  Button,
  CircularProgress,
  FormControlLabel,
  FormControl,
  FormGroup,
  Checkbox,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";
import { getFunctions } from "./GetOptions";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  checkbox: {
    marginBottom: "2vw",
  },
}));

export default function ModalForm({
  requestAuthProps,
  authProps,
  setRequestAuthProps,
  updateAuth,
  submitNewAuth,
  loading,
  options,
  setOptions,
}) {
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //MODULE
        id="combo-box"
        options={options.modules?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          requestAuthProps.moduleId
            ? getFromId(options.modules, requestAuthProps.moduleId)
            : null
        }
        onChange={async (event, value) => {
          setRequestAuthProps({ ...requestAuthProps, moduleId: value?.id });
          let opts = { ...options };
          opts.functions = await getFunctions(value?.id);
          setOptions(opts);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Modül"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //FUNCTION
        id="combo-box"
        options={options.functions?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          requestAuthProps.moduleFunctionId
            ? getFromId(options.functions, requestAuthProps.moduleFunctionId)
            : null
        }
        onChange={async (event, value) => {
          setRequestAuthProps({
            ...requestAuthProps,
            moduleFunctionId: value?.id,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Fonksiyon"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //ROLE
        id="combo-box"
        options={options.rolesId?.sort(function (a, b) {
          return collator.compare(a.nameTr, b.nameTr);
        })}
        getOptionLabel={(option) => option.nameTr}
        fullWidth={true}
        value={
          requestAuthProps.roleId
            ? getFromId(options.rolesId, requestAuthProps.roleId)
            : null
        }
        onChange={async (event, value) => {
          setRequestAuthProps({ ...requestAuthProps, roleId: value?.id });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Rol"
            className={classes.textField}
          />
        )}
      />
      <TextArea
        required
        label={"Görüntüleme Yetkisi"}
        value={requestAuthProps.readOpNote}
        onChangeFunc={(value) =>
          setRequestAuthProps({
            ...requestAuthProps,
            readOpNote: value.target.value,
          })
        }
        style={classes.textField}
      />
      <FormGroup className={classes.textField} row>
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.readOpWeb}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  readOpWeb: event.target.checked,
                });
              }}
            />
          }
          label="Web"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.readOpMobile}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  readOpMobile: event.target.checked,
                });
              }}
            />
          }
          label="Mobil"
        />
      </FormGroup>
      <TextArea
        required
        label={"Oluşturma Yetkisi"}
        value={requestAuthProps.createOpNote}
        onChangeFunc={(value) =>
          setRequestAuthProps({
            ...requestAuthProps,
            createOpNote: value.target.value,
          })
        }
        style={classes.textField}
      />
      <FormGroup className={classes.textField} row>
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.createOpWeb}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  createOpWeb: event.target.checked,
                });
              }}
            />
          }
          label="Web"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.createOpMobile}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  createOpMobile: event.target.checked,
                });
              }}
            />
          }
          label="Mobil"
        />
      </FormGroup>
      <TextArea
        required
        label={"Değiştirme Yetkisi"}
        value={requestAuthProps.updateOpNote}
        onChangeFunc={(value) =>
          setRequestAuthProps({
            ...requestAuthProps,
            updateOpNote: value.target.value,
          })
        }
        style={classes.textField}
      />
      <FormGroup className={classes.textField} row>
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.updateOpWeb}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  updateOpWeb: event.target.checked,
                });
              }}
            />
          }
          label="Web"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.updateOpMobile}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  updateOpMobile: event.target.checked,
                });
              }}
            />
          }
          label="Mobil"
        />
      </FormGroup>
      <TextArea
        required
        label={"Silme Yetkisi"}
        value={requestAuthProps.deleteOpNote}
        onChangeFunc={(value) =>
          setRequestAuthProps({
            ...requestAuthProps,
            deleteOpNote: value.target.value,
          })
        }
        style={classes.textField}
      />
      <FormGroup className={classes.textField} row>
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.deleteOpWeb}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  deleteOpWeb: event.target.checked,
                });
              }}
            />
          }
          label="Web"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={requestAuthProps.deleteOpMobile}
              onChange={async (event) => {
                setRequestAuthProps({
                  ...requestAuthProps,
                  deleteOpMobile: event.target.checked,
                });
              }}
            />
          }
          label="Mobil"
        />
      </FormGroup>
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => (authProps ? updateAuth() : submitNewAuth())}
            className={classes.submit}
          >
            {authProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
