import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      department: filterProps.department?.name,
      company: filterProps.company?.name,
      city: filterProps.city?.name,
      region: filterProps.region?.name,
      type: filterProps.type,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "userName") {
        params.sort =
          "kkdUser.user.fName, kkdUser.user.lName," + query.orderDirection;
      } else if (query.orderBy.field === "company") {
        params.sort = "kkdUser.user.company.name," + query.orderDirection;
      } else if (query.orderBy.field === "department") {
        params.sort = "kkdUser.user.department.name," + query.orderDirection;
      } else if (query.orderBy.field === "kkdName") {
        params.sort = "kkd.kkdName," + query.orderDirection;
      } else if (query.orderBy.field === "kkdSerialNo") {
        params.sort = "kkdSerialNo," + query.orderDirection;
      } else if (query.orderBy.field === "givenDate") {
        params.sort = "givenDate," + query.orderDirection;
      } else if (query.orderBy.field === "productionDate") {
        params.sort = "productionDate," + query.orderDirection;
      } else if (query.orderBy.field === "returnDate") {
        params.sort = "returnDate," + query.orderDirection;
      } else if (query.orderBy.field === "returnReason") {
        params.sort = "returnReason," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/kkd-debits", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          userId: data[i]?.userId,
          crewId: data[i]?.crewId,
          userName: data[i]?.crewName
            ? data[i]?.crewName
            : data[i]?.fname + " " + data[i]?.lname,
          kkdId: data[i]?.kkd?.id,
          kkdName: data[i]?.kkd?.kkdName,
          kkdSerialNo: data[i].kkdSerialNo,
          givenDate: data[i].givenDate,
          productionDate: data[i].productionDate,
          company: data[i]?.company,
          department: data[i]?.department,
          fileKeys: data[i]?.fileKeys,
        };
        edit.files = [];
        if (data[i]?.fileKeys) {
          for (let file of data[i]?.fileKeys) {
            edit.files.push(
              new File([file.fileName], file.fileName, { type: "text/plain" })
            );
          }
        }
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
