import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      "page-size": query.pageSize,
      "sort-by": "id",
      "sort-direction": "DESC",

      regionId: filterProps.filterRegion?.id,
      departmentId: filterProps.filterDepartment?.id,
      companyId: filterProps.filterCompany?.id,
      endDate: filterProps.filterEndDate,
      startDate: filterProps.filterStartDate,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params["sort-by"] = "id";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "companyName") {
        params["sort-by"] = "company_name";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "regionName") {
        params["sort-by"] = "region_name";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "departmentName") {
        params["sort-by"] = "department_name";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "startDate") {
        params["sort-by"] = "start_date";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "endDate") {
        params["sort-by"] = "end_date";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "name") {
        params["sort-by"] = "first_name";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      } else if (query.orderBy.field === "surname") {
        params["sort-by"] = "last_name";
        params["sort-direction"] = query.orderDirection.toUpperCase();
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/reports/received-for-illness",
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      const originalData = resp.data;
      const data = originalData.content;
      const content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          id: content[i].id,
          companyName: content[i].company,
          departmentName: content[i].department.name,
          regionName: content[i].region.name,
          name: content[i].user.fName,
          surname: content[i].user.lName,
          departmentId: content[i].department.id,
          regionId: content[i].region.id,
          userId: content[i].user.id,
          startDate: content[i].startDate,
          endDate: content[i].endDate,
          numberOfDays: content[i].numberOfDays,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      });
    }
  });
  return data;
}
