import React from "react";
import { ResponsiveContainer } from "recharts";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { CircularProgress, makeStyles, Divider } from "@material-ui/core";
import ListOfFiles from "../../../components/Files/ListOfFiles";
import PrintUnsuitability from "./printUnsuitability";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  approveReportButton: {
    cursor: "pointer",
    margin: 20,
    marginTop: 30,
  },
  approveReportLabel: {
    cursor: "pointer",
  },
  divider: {
    margin: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
  },
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: 600,
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px",
  },
  textField: {
    marginBottom: "20px",
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
}));

export default function Details({ rowData }) {
  const [, setModal] = React.useState(false);
  const [, setDetails] = React.useState(null);
  const [, setFormUnsuitDetails] = React.useState(null);

  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const RenderFiles = () => {
    return (
      <>
        <label>
          Dosyalar
          <br />
        </label>
        {rowData && rowData.files?.length > 0 ? (
          <>
            <ListOfFiles fileKeys={rowData.files} />
          </>
        ) : (
          <p className={classes.redText}>
            {language.tableDetails.noFile}
            <br />
          </p>
        )}
      </>
    );
  };

  const RenderUnsuitabilities = () => {
    return (
      <>
        <label>
          Aksiyonlar
          <br />
        </label>
        <PrintUnsuitability
          data={rowData}
          setModal={setModal}
          setDetails={setDetails}
          setFormUnsuitDetails={setFormUnsuitDetails}
        />
      </>
    );
  };

  return (
    <>
      <ResponsiveContainer>
        {rowData ? (
          <div className={classes.details}>
            <RenderFiles />
            <Divider className={classes.divider} />
            <RenderUnsuitabilities />
          </div>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </ResponsiveContainer>
    </>
  );
}
