import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Request from "../../../helpers/Request";
import Details from "./Details";
import { modalStyles } from "../../../assets/styles/tableContainer";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  dividerStyle: {
    margin: 20,
  },
  text: {
    fontSize: "1vw",
    float: "left",
  },
  title: {
    float: "left",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  noCasualties: {
    color: "red",
    fontSize: "2vw",
  },
}));

const useModalStyles = makeStyles((theme) => modalStyles(theme));

export default function AccidentPersonnelDetails({
  rowData,
  tableRef,
  options,
}) {
  const modalStyles = useModalStyles();
  const classes = useStyles();

  const [accidents, setAccidents] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [selectedCasualty, setSelectedCasualty] = React.useState({});

  const getAccidents = useCallback(async (id) => {
    let fetchedAccidents = [...accidents];
    const resp = await Request(
      "get",
      "/api/industrial-accidents/" + id + "/casualties"
    );
    console.log(resp);
    let arr = resp.data;
    for (let i in arr) {
      let bodyPartIds = [];
      if (arr[i]?.injuredBodyParts.length > 0) {
        for (let index in arr[i].injuredBodyParts) {
          bodyPartIds.push(arr[i].injuredBodyParts[index].id);
        }
      }
      let accident = {
        id: arr[i].id,
        personnel: arr[i].personnelFName + " " + arr[i].personnelLName,
        personnelId: arr[i].personnelId,
        lostLimbId: arr[i].lostLimb?.id,
        injuredBodyPartIds: bodyPartIds,
        fallDistanceOnFallAccidentsInMeters:
          arr[i].fallDistanceOnFallAccidentsInMeters,
        incapacitationStartDate: arr[i].incapacitationStartDate,
        incapacitationEndDate: arr[i].incapacitationEndDate,
        incapacitationDays: arr[i].incapacitationDays,
        // accidentOutcomeId: arr[i].outcome?.id,
        injuryTypeId: arr[i].injuryType?.id,
        workStartTimeAtAccidentDay: arr[i].workStartTimeAtAccidentDay,
        sgkNotificationDate: arr[i].sgkNotificationDate,
        sectionId: arr[i].section?.id,
        // note: arr[i].note,
        // summary: arr[i].summary,
      };

      fetchedAccidents.push(accident);
    }
    return fetchedAccidents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(async () => {
    const temp = await getAccidents(rowData.id);
    setAccidents(temp);
    temp.length > 0 && setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [getAccidents, rowData]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleOpenModal = async (index) => {
    setSelectedCasualty(index);
    setModal(true);
  };

  const page = 0;
  const size = 50;

  const [numberOfElements, setNumberOfElements] = React.useState(0);
  const [list, setList] = React.useState([]);

  const getUnsuitabilityDetails = async () => {
    const resp = await Request(
      "get",
      `/api/industrial-accidents/actions?filterActionId=${rowData.id}&page=${page}&size=${size}`
    );

    //setIdAction(resp.data.content.id);
    // setAssignedByShortCompanyName(
    //   resp.data.content[0].assignedByShortCompanyName
    // );
    setList(resp.data.content);

    setNumberOfElements(resp.data.totalElements);
    return resp.data;
  };

  useEffect(() => {
    getUnsuitabilityDetails();
  }, []);

  const goToActionPage = async (i) => {
    window.open("/workaccidents-action/" + i);
  };

  const PrintAccidents = () => {
    return accidents.map((data, index) => {
      return (
        <>
          <TableRow>
            <TableCell align="left">
              <p className={classes.text}>{data.id}</p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>{data.personnel}</p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {options.lostLimb[data.lostLimbId]}
              </p>
            </TableCell>
            <TableCell align="left">
              {data.injuredBodyParts ? (
                <List className={classes.list}>
                  {data.injuredBodyParts.map((data, index) => (
                    <ListItem key={index}>
                      <ListItemText secondary={data.nameTr} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <p className={classes.text}>YOK</p>
              )}
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {data.incapacitationStartDate
                  ? data.incapacitationStartDate
                  : "-"}
              </p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {data.incapacitationEndDate ? data.incapacitationEndDate : "-"}
              </p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {data.incapacitationDays ? data.incapacitationDays : "-"}
              </p>
            </TableCell>
            {/* <TableCell align="left">
              <p className={classes.text}>
                {options.accidentOutcomes[data.accidentOutcomeId]}
              </p>
            </TableCell> */}
            <TableCell align="left">
              <p className={classes.text}>
                {options.injuryTypes[data.injuryTypeId]}
              </p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {options.sections[data.sectionId]}
              </p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {data.workStartTimeAtAccidentDay
                  ? data.workStartTimeAtAccidentDay
                  : "-"}
              </p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>
                {data.sgkNotificationDate ? data.sgkNotificationDate : "-"}
              </p>
            </TableCell>
            {/* <TableCell align="left">
              <p className={classes.text}>{data.note}</p>
            </TableCell> */}
            <TableCell align="left">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpenModal(index);
                }}
                className={classes.submit}
              >
                {"Dosyalara Git"}
              </Button>
            </TableCell>
          </TableRow>
        </>
      );
    });
  };

  return (
    <>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : accidents.length > 0 ? (
        <div className={classes.details}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <p className={classes.title}>{"Kazazede No"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Kazazede İsim"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Kayıp Uzuv"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Yaralı Vücut Parçası"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Rapor Başlangıç Tarihi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Rapor Bitiş Tarihi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Raporlu Gün Sayısı"}</p>
                  </TableCell>
                  {/* <TableCell align="left">
                    <p className={classes.title}>{"Kaza Sonucu"}</p>
                  </TableCell> */}
                  <TableCell align="left">
                    <p className={classes.title}>{"Yaralanma Tipi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Seksiyonu"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>
                      {"Kaza Günü İşe Başlama Saati"}
                    </p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"SGK Bildirim Tarihi"}</p>
                  </TableCell>
                  {/* <TableCell align="left">
                    <p className={classes.title}>{"Açıklama"}</p>
                  </TableCell> */}
                  <TableCell align="left">
                    <p className={classes.title}>{"Dosyalar"}</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <PrintAccidents />
              </TableBody>

              <div className={classes.unsuitContainer}>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.formButton}
                  onClick={() => getUnsuitabilityDetails()}
                >
                  <label className={classes.formButtonLabel}>Aksiyonlar</label>
                </Button>
              </div>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <p className={classes.title}>{"Aksiyon Id"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Atayan Kişi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Atanan Kişi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Şirket İsmi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Başlangıç Tarihi"}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{"Bitiş Tarihi"}</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              {numberOfElements > 0 && list !== null && list !== undefined && (
                <TableBody>
                  {list.map((i) => (
                    <TableRow>
                      <TableCell>
                        <p className={classes.text}>{i.id} </p>
                      </TableCell>
                      <TableCell>
                        <p className={classes.text}>
                          {i.assignedBy.fName} {i.assignedBy.lName}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p className={classes.text}>
                          {i.assignedTo.fName} {i.assignedTo.lName}
                        </p>
                      </TableCell>
                      <TableCell>
                        <p className={classes.text}>{i.company.name}</p>
                      </TableCell>
                      <TableCell>
                        <p className={classes.text}>{i.creationDate}</p>
                      </TableCell>
                      <TableCell>
                        <p className={classes.text}>{i.terminationDate}</p>
                      </TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.formButton}
                        onClick={() => goToActionPage(i.id)}
                      >
                        <label className={classes.formButtonLabel}>
                          Aksiyona git
                        </label>
                      </Button>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </div>
      ) : (
        <label className={classes.noCasualties}>Kazazede Yok</label>
      )}
      <Modal
        className={modalStyles.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div
            className={modalStyles.paper}
            style={{ backgroundColor: "#2D3446" }}
          >
            <Details
              rowData={rowData}
              casualty={selectedCasualty}
              tableRef={tableRef}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
