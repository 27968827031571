import React, { useCallback } from "react";
import ModalForm from "./ModalForm";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function WorkEquipmentModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldWorkEquipmentProps,
  setOldWorkEquipmentProps,
  modalLoading,
}) {
  const defaultProps = {
    id: null,
    ustRef: null,
    name: null,
  };
  const language = LanguageHelper.getLanguage();

  const [workEquipmentProps, setWorkEquipmentProps] =
    React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldWorkEquipmentProps) {
      setWorkEquipmentProps({
        id: oldWorkEquipmentProps.id || null,
        ustRef: oldWorkEquipmentProps.ustRef || null,
        name: oldWorkEquipmentProps.name || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldWorkEquipmentProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setWorkEquipmentProps(defaultProps);
      setOldWorkEquipmentProps(null);
    }, 300);
  };

  return (
    <React.Fragment>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          workEquipmentProps={workEquipmentProps}
          setWorkEquipmentProps={setWorkEquipmentProps}
          label={
            oldWorkEquipmentProps
              ? language.modal.update
              : language.modal.submit
          }
          loading={loading}
          setLoading={setLoading}
          handleCloseModal={handleCloseModal}
          oldWorkEquipmentProps={oldWorkEquipmentProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </React.Fragment>
  );
}
