import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import { getTowns, getUsers } from "./GetOptions";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import BelongingDepartmentList from "./BelongingDepartmentList";
import { Autocomplete } from "@material-ui/lab";
import jsonMap from "../../../components/Utils/jsonMap";
import FieldList from "./FieldsList";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import ISGUsersList from "./ISGUsersList";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  oldDepartmentProps,
  options,
  newDepartmentProps,
  setNewDepartmentProps,
  updateDepartment,
  submitNewDepartment,
  setOptions,
  loading,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [sgkError, setSgkError] = React.useState(null);
  const [nameError, setNameError] = React.useState(null);
  const [cityError, setCityError] = React.useState(null);
  const [townError, setTownError] = React.useState(null);
  const [, setFieldsError] = React.useState(null);
  const [companyError, setCompanyError] = React.useState(null);
  const [belongingDepartmentError, setBelongingDepartmentError] =
    React.useState(null);
  const [riskError, setRiskError] = React.useState(null);

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!newDepartmentProps.name) {
      setNameError(errorMessage);
      error = true;
    }
    if (newDepartmentProps.parentDepartmentIds.length <= 0) {
      setBelongingDepartmentError(errorMessage);
      error = true;
    }
    if (!newDepartmentProps.cityId) {
      setCityError(errorMessage);
      error = true;
    }
    if (!newDepartmentProps.townId) {
      setTownError(errorMessage);
      error = true;
    }
    if (newDepartmentProps.fieldsOfActivity.length === 0) {
      setFieldsError(errorMessage);
      error = true;
    }
    if (!newDepartmentProps.companyId) {
      setCompanyError(errorMessage);
      error = true;
    }
    if (!newDepartmentProps.riskClass) {
      setRiskError(errorMessage);
      error = true;
    }
    if (!newDepartmentProps.sgkno) {
      setSgkError(errorMessage);
      error = true;
    }

    if (!error) {
      oldDepartmentProps ? updateDepartment() : submitNewDepartment();
    }
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea //NAME
        required
        error={nameError}
        label={"Adı"}
        value={newDepartmentProps.name}
        onChangeFunc={(value) => {
          if (value.target.value.length > 60) {
            setNameError("Adı 60 karakterden uzun olamaz.");
          } else {
            nameError && setNameError(null);
            setNewDepartmentProps({
              ...newDepartmentProps,
              name: value.target.value,
            });
          }
        }}
        style={classes.textField}
      />
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        defaultValue={
          oldDepartmentProps
            ? getFromId(options.companies, oldDepartmentProps.company)
            : null
        }
        onChange={async (event, value) => {
          companyError && setCompanyError(null);
          let newOpts = options;
          newOpts.unsuitUser = value?.id ? await getUsers(value?.id) : [];
          setOptions(newOpts);
          setNewDepartmentProps({
            ...newDepartmentProps,
            companyId: value?.id,
          });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={companyError}
            helperText={companyError}
            required
            label="Şirket"
            className={classes.textField}
          />
        )}
      />
      <TextArea //SGK
        label={"SGK Sicil No"}
        error={sgkError}
        value={newDepartmentProps.sgkno}
        onChangeFunc={(value) => {
          let val = value.target.value;
          val = val.replace(/\s/g, "").replace(/\D/g, "");
          if (val.length !== 26) {
            setSgkError(
              "SGK Numarası 26 haneli olup sadece sayılardan oluşmalıdır.\nÖrnek: 11234010112345670010199000000"
            );
          } else {
            sgkError && setSgkError(null);
          }
          setNewDepartmentProps({ ...newDepartmentProps, sgkno: val });
        }}
        style={classes.textField}
      />
      <BelongingDepartmentList
        oldDepartmentProps={oldDepartmentProps}
        newDepartmentProps={newDepartmentProps}
        setNewDepartmentProps={setNewDepartmentProps}
        options={options}
        collator={collator}
        belongingDepartmentError={belongingDepartmentError}
        setBelongingDepartmentError={setBelongingDepartmentError}
      />
      {oldDepartmentProps && (
        <Autocomplete //MANAGER
          id="combo-box"
          options={options.managers?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          defaultValue={
            oldDepartmentProps
              ? getFromId(options.managers, oldDepartmentProps.manager)
              : null
          }
          onChange={async (event, value) =>
            setNewDepartmentProps({
              ...newDepartmentProps,
              managerId: value?.id,
            })
          }
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="Yönetici"
              className={classes.textField}
            />
          )}
        />
      )}
      {oldDepartmentProps && (
        <ISGUsersList
          newDepartmentProps={newDepartmentProps}
          setNewDepartmentProps={setNewDepartmentProps}
          options={options}
        />
      )}
      <TextSelector //CITY
        label={"Şehir"}
        error={cityError}
        value={newDepartmentProps.cityId}
        required
        onChangeFunc={async (value) => {
          cityError && setCityError(null);
          let newOpts = options;
          newOpts.towns = await getTowns(value.target.value);
          setOptions(newOpts);
          setNewDepartmentProps({
            ...newDepartmentProps,
            cityId: value.target.value,
          });
        }}
        style={classes.textField}
        options={jsonMap("cities", options)}
      />
      <Autocomplete //TOWN
        id="combo-box"
        options={options.towns?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        disabled={!newDepartmentProps.cityId}
        defaultValue={
          oldDepartmentProps
            ? getFromId(options.towns, newDepartmentProps.townId)
            : null
        }
        onChange={(event, value) => {
          townError && setTownError(null);
          setNewDepartmentProps({ ...newDepartmentProps, townId: value?.id });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={townError}
            helperText={townError}
            required
            label="İlçe"
            className={classes.textField}
          />
        )}
      />
      <FieldList
        newDepartmentProps={newDepartmentProps}
        setNewDepartmentProps={setNewDepartmentProps}
        options={options}
      />
      <TextSelector //RISK CLASS
        label={"Tehlike Sınıfı"}
        required
        error={riskError}
        value={newDepartmentProps.riskClass}
        onChangeFunc={(value) => {
          riskError && setRiskError(null);
          setNewDepartmentProps({
            ...newDepartmentProps,
            riskClass: value.target.value,
          });
        }}
        style={classes.textField}
        options={jsonMap("riskClasses", options)}
      />
      <TextSelector //FIELD OF ACTIVITY
        label={"Faaliyet Tipi"}
        value={newDepartmentProps.fieldOfActivity}
        onChangeFunc={(value) =>
          setNewDepartmentProps({
            ...newDepartmentProps,
            fieldOfActivity: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap("fieldOfActivities", options)}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onButtonClick()}
            className={classes.submit}
          >
            {oldDepartmentProps ? "GÜNCELLE" : "OLUŞTUR"}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
