import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from '../../../assets/styles/tableContainer';
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from '../../../components/Utils/jsonMap';

const useStyles = makeStyles((theme) => (modalFormStyles(theme)));

export default function ModalForm({
  requestKKDTaskProps,
  setRequestKKDTaskProps,
  submitNewKKDTask,
  options,
  kkdTaskProps,
  updateKKDTask,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={"Görev Adı"}
        value={requestKKDTaskProps.taskName}
        onChangeFunc={(value) =>
          setRequestKKDTaskProps({ ...requestKKDTaskProps, taskName: value.target.value })
        }
        style={classes.textField}
      />
      <TextSelector
        required
        label={"Cinsi"}
        value={requestKKDTaskProps.type}
        onChangeFunc={(value) =>
          setRequestKKDTaskProps({ ...requestKKDTaskProps, type: value.target.value })
        }
        style={classes.textField}
        options={jsonMap("taskTypes", options)}
      />
      <div style={{paddingBottom: 20, textAlign: "center"}}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => (kkdTaskProps ? updateKKDTask() : submitNewKKDTask())}
          className={classes.submit}
        >
          {language.form.submit}
        </Button>}
      </div>
    </FormControl>
  );
}