import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import showSnackbar from "../../../components/Utils/showSnackbar";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import { getDepartments, getUsers } from "./GetOptions";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
}));

export default function ModalForm({
  reportProps,
  setReportProps,
  label,
  loading,
  setLoading,
  options,
  setOptions,
  handleCloseModal,
  oldReportProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();

  const [errors, setErrors] = React.useState({
    region: null,
    company: null,
    department: null,
    user: null,
    startDate: null,
    endDate: null,
  });

  const getFromName = (options, name) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].name === name) {
        return options[i];
      }
    }
    return null;
  };

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  /**
   * Request to replace the old data with the new data.
   */
  const updateReport = async () => {
    setLoading(true);
    const resp = await Request(
      "put",
      "/api/reports/received-for-illness/",
      reportProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const submitNewReport = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/reports/received-for-illness",
      reportProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.created,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      region: null,
      company: null,
      department: null,
      startDate: null,
      endDate: null,
      user: null,
    };
    if (!reportProps.regionId) {
      errors.region = errorMessage;
      error = true;
    }
    if (!reportProps.companyName) {
      errors.company = errorMessage;
      error = true;
    }
    if (!reportProps.departmentId) {
      errors.department = errorMessage;
      error = true;
    }
    if (!reportProps.startDate) {
      errors.startDate = errorMessage;
      error = true;
    }
    if (!reportProps.endDate) {
      errors.endDate = errorMessage;
      error = true;
    }
    if (!reportProps.userId) {
      errors.user = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      oldReportProps ? updateReport() : submitNewReport();
    }
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //REGION
        id="combo-box"
        options={options.regions?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          reportProps.regionId
            ? getFromId(options.regions, reportProps.regionId)
            : null
        }
        onChange={async (event, value) => {
          setReportProps({
            ...reportProps,
            regionId: value?.id,
          });
          errors.region && setErrors({ ...errors, region: null });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.region}
            helperText={errors.region}
            label="Bölge"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          reportProps.companyName
            ? getFromName(options.companies, reportProps.companyName)
            : null
        }
        onChange={async (event, value) => {
          setReportProps({
            ...reportProps,
            companyName: value?.name,
          });
          let opts = { ...options };
          opts.departments = await getDepartments(value?.id);
          setOptions(opts);
          errors.company && setErrors({ ...errors, company: null });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.company}
            helperText={errors.company}
            label="Şirket"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //DEPARTMENT
        id="combo-box"
        options={options.departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          reportProps.departmentId
            ? getFromId(options.departments, reportProps.departmentId)
            : null
        }
        onChange={async (event, value) => {
          setReportProps({
            ...reportProps,
            departmentId: value?.id,
          });
          let opts = { ...options };
          opts.users = await getUsers(value?.id);
          setOptions(opts);
          errors.department && setErrors({ ...errors, department: null });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.department}
            helperText={errors.department}
            label="Bölüm"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //USERS
        id="combo-box"
        options={options.users?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          reportProps.userId
            ? getFromId(options.users, reportProps.userId)
            : null
        }
        onChange={async (event, value) => {
          setReportProps({
            ...reportProps,
            userId: value?.id,
          });
          errors.user && setErrors({ ...errors, user: null });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.user}
            helperText={errors.user}
            label="Personel"
            className={classes.textField}
          />
        )}
      />
      <CustomDatePicker //Report Start Date
        style={classes.dateField}
        required
        label="Rapor Başlangıç Tarihi"
        value={
          reportProps.startDate
            ? reportProps.startDate.replaceAll("Z", "")
            : null
        }
        shrink={reportProps.startDate}
        onChangeFunc={(value) => {
          const startDate = moment(value).toISOString(false);

          if (
            reportProps.endDate &&
            moment(startDate).isAfter(reportProps.endDate)
          ) {
            setSnackbar(true);
            setSeverity("error");
            setSnackbarMessage(
              "Rapor başlangıç tarihi, bitiş tarihinden daha sonraki bir tarihte olamaz!"
            );
          } else {
            setReportProps({
              ...reportProps,
              startDate: startDate,
            });
            errors.startDate && setErrors({ ...errors, startDate: null });
          }
        }}
      />
      <CustomDatePicker //Report End Date
        style={classes.dateField}
        label="Rapor Bitiş Tarihi"
        value={
          reportProps.endDate ? reportProps.endDate.replaceAll("Z", "") : null
        }
        shrink={reportProps.endDate}
        onChangeFunc={(value) => {
          const endDate = moment(value).toISOString(false);
          if (
            reportProps.startDate &&
            moment(endDate).isBefore(reportProps.startDate)
          ) {
            setSnackbar(true);
            setSeverity("error");
            setSnackbarMessage(
              "Rapor bitiş tarihi, başlangıç tarihinden daha önceki bir tarihte olamaz!"
            );
          } else {
            setReportProps({
              ...reportProps,
              endDate: endDate,
            });
            errors.endDate && setErrors({ ...errors, endDate: null });
          }
        }}
      />

      <div className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            className={classes.submit}
            disabled={!reportProps.startDate}
          >
            {label}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
