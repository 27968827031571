import React, { forwardRef, useCallback, useRef } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import GridOnIcon from "@material-ui/icons/GridOn";
import AddBox from "@material-ui/icons/AddBox";
import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import RecordModal from "./RecordModal";
import showSnackbar from "../../../components/Utils/showSnackbar";
import moment from "moment";
import { taskStatuses } from "../../../assets/constants";
import GetFilterFields from "./RecordFilters";
import Table from "../../../components/Table/Table";
import FetchRecordData from "./FetchRecordData";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { GetOptions } from "../GetOptions";
import FileSaver from "file-saver";
import { getCatalogues } from "../catalogues/Catalogues";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import IbysPostDialog from "../../../components/Dialog/IbysPostDialog";
import { Send } from "@material-ui/icons";
import RecordDetails from "./RecordDetails";

const detailStyle = {
  backgroundColor: "#2D3446",
  color: "white",
  alignItems: "center",
  textAlign: "center",
  padding: 10,
};

export default function Records() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const id = user?.company?.id;
  const roles = user?.roles;
  const isSpecial =
    roles.includes("ROLE_SİSTEM_ADMİNİ") ||
    roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
    roles.includes("ROLE_ISG_UZMANI") ||
    roles.includes("ROLE_OSGB_ISG_UZMANI");
  const authorization = getAuthorizationForPage(roles, "trainingRecords");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [recordModal, setRecordModal] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [catalogues, setCatalogues] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [modalLoading, setModalLoading] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [postDialog, setPostDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [idsSelected, setIdsSelected] = React.useState([]);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: isSpecial ? null : user?.company,
    department: null,
    instructor: null,
    trainingType: null,
    city: null,
    town: null,
    plannedDateStart: null,
    plannedDateEnd: null,
    completionDateStart: null,
    completionDateEnd: null,
  });

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { field: "id", title: language.tableColumns.id },
      { field: "topics", title: language.tableColumns.name },
      { field: "trainingType", title: language.tableColumns.educationType },
      { field: "companyName", title: language.auditTasks.company },
      { field: "departmentName", title: language.auditTasks.department },
      {
        field: "createdDate",
        title: language.tableColumns.recordDate,
        render: (rowData) => moment(rowData.createdDate).format("DD-MM-YYYY"),
      },
      {
        field: "plannedDate",
        title: language.tableColumns.plannedDate,
        render: (rowData) => moment(rowData.plannedDate).format("DD-MM-YYYY"),
      },
      {
        field: "startDate",
        title: language.auditTasks.startingDate,
        render: (rowData) => moment(rowData.startDate).format("DD-MM-YYYY"),
      },
      {
        field: "completionDate",
        title: language.tableColumns.completionDate,
        render: (rowData) =>
          rowData.completionDate
            ? moment(rowData.completionDate).format("DD-MM-YYYY")
            : language.etc.incompleted,
      },
      { field: "time", title: language.etc.time },
      { field: "cityName", title: language.auditTasks.educationCity },
      {
        field: "towns",
        title: language.auditTasks.educationDistricts,
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 150,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData?.towns.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.name} />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        field: "status",
        title: language.tableColumns.status,
        lookup: taskStatuses,
      },
      {
        field: "allInstructors",
        title: language.tableColumns.instructors,
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData?.allInstructors.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data} />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      { field: "manHour", title: language.tableDetails.manHour },
      {
        field: "numOfParticipants",
        title: language.tableDetails.participantsNumber,
      },
      {
        field: "participantObjects",
        title: language.tableColumns.participants,
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData?.participantObjects.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.name} />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      { field: "atWorkplace", title: language.tableColumns.atWorkplace },
      { field: "remote", title: language.tableColumns.remote },
      {
        field: "ibysTrainingTypeName",
        title: language.tableColumns.ibysTrainingType,
      },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    setRecordModal(data || {});
    if (!catalogues) {
      const cats = await getCatalogues(id);
      let catalogueProps = [];
      for (let catalogue of cats) {
        const props = {
          id: catalogue?.id,
          trainingType: catalogue?.trainingType,
          name: catalogue?.name,
          time: catalogue?.time,
          scope: catalogue?.scope,
          targetGroup: catalogue?.targetGroup,
        };
        let tempFiles = [];
        for (let file of catalogue?.fileKeys) {
          const name = file.name ? file.name : file?.fileName;
          tempFiles.push(new File([name], name, { type: "text/plain" }));
        }
        props.fileKeys = tempFiles;
        catalogueProps.push(props);
      }
      setCatalogues(catalogueProps);
    }
    setOptions(await GetOptions(false, data?.companyId, data?.cityId));
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    if (!idsSelected.length > 0) {
      setSelectedRecord(data);
    }
    // setSelectedRecord(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    if (!idsSelected.length > 0) {
      setSelectedRecord(null);
    }
    // setSelectedRecord(null);
    setDialog(false);
  };

  const handleOpenPostDialog = (data) => {
    if (!idsSelected.length > 0) {
      setSelectedRecord(data);
    }
    // setSelectedRecord(data);
    setPostDialog(true);
  };

  const handleClosePostDialog = () => {
    if (!idsSelected.length > 0) {
      setSelectedRecord(null);
    }
    // setSelectedRecord(null);
    setPostDialog(false);
  };

  const deleteRecord = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/training/record/" + selectedRecord?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const postRecord = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "get",
      "/api/ibys/training/" + selectedRecord?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.ibysPost.result,
      tableRef
    );
    handleClosePostDialog();
    setRequestLoading(false);
  };

  const postRecords = async () => {
    setRequestLoading(true);
    console.log(idsSelected);
    const resp = await Request("get", "/api/ibys/trainings", idsSelected);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.ibysPost.result,
      tableRef
    );
    handleClosePostDialog();
    setRequestLoading(false);
  };

  const deleteRecords = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsSelected.join(),
    };
    console.log(param);
    const resp = await Request("delete", "/api/training/record", null, param);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: isSpecial ? null : user?.company,
      department: null,
      instructor: null,
      trainingType: null,
      city: null,
      town: null,
      plannedDateStart: null,
      plannedDateEnd: null,
      completionDateStart: null,
      completionDateEnd: null,
    });
  };

  return (
    <React.Fragment>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={
          idsSelected.length > 0
            ? deleteRecords
            : selectedRecord && deleteRecord
        }
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <IbysPostDialog
        post={
          idsSelected.length > 0 ? postRecords : selectedRecord && postRecord
        }
        open={postDialog}
        loading={requestLoading}
        close={handleClosePostDialog}
      />
      <RecordModal
        modalLoading={modalLoading}
        setModalLoading={setModalLoading}
        recordProps={recordModal}
        setRecordModal={setRecordModal}
        catalogues={catalogues}
        setCatalogues={setCatalogues}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <RenderFilters />
      <Table
        tableRef={tableRef}
        authName="trainingRecords"
        tableName={language.sidebar.trainingRecords}
        columns={columns}
        fetchData={FetchRecordData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        extraAction={{
          auth: true,
          icon: forwardRef((props, ref) => <GridOnIcon {...props} ref={ref} />),
          tooltip: language.etc.formDownload,
          position: "row",
          onClick: async (rowData) => {
            const resp = await Request(
              "get",
              "/api/training/record/" + rowData?.id + "/participation-form"
            );
            console.log(resp);
            const source =
              "data:application/xlsx;base64, " + encodeURI(resp?.data);
            FileSaver.saveAs(
              source,
              "Eğitim Kaydı " + rowData?.topics + ".xlsx"
            );
          },
        }}
        //deleteEntry={deleteRecord}
        isDeletable={(rowData) => authorization?.delete}
        isEditable={(rowData) => authorization?.edit}
        filters={filterProps}
        detailsPanel={(rowData) => (
          <div style={detailStyle}>
            <RecordDetails
              rowData={rowData}
              tableRef={tableRef}
              options={options}
            />
          </div>
        )}
        setSelectionIds={setIdsSelected}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        ibysPost={{
          auth:
            roles.includes("ROLE_ISG_UZMANI") ||
            roles.includes("ROLE_OSGB_ISG_UZMANI") ||
            roles.includes("ROLE_SİSTEM_ADMİNİ"),
          icon: forwardRef((props, ref) => <Send {...props} ref={ref} />),
          tooltip: language.ibysPost.tooltip,
          position: "row",
          onClick: (rowData) => handleOpenPostDialog(rowData),
        }}
      />
    </React.Fragment>
  );
}
