import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const resp = await Request(
      "get",
      "/api/authority/" + filterProps.moduleId + "/module-functions"
    );
    console.log(resp);

    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(
        language.etc.fetchingError + resp?.data?.messageResponse
      );
      setSnackbar(true);
    } else {
      let data = resp.data?.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          name: data[i].name,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.length);
      resolve({
        data: temp,
        // page: otherData.pageable.pageNumber,
        totalCount: data.length,
      });
    }
  });
  return data;
}
