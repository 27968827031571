import LanguageHelper from "../helpers/LanguageHelper";
const language = LanguageHelper.getLanguage();

export const taskStatuses = {
  DONE: "Tamamlandı",
  TODO: "Başlanmadı",
  APPROVED: "Onaylandı",
  REJECTED: "Reddedildi",
};

export const roles = {
  ROLE_SİSTEM_ADMİNİ: "Sistem Admini",
  ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ:
    "Yaşam Güvenliği Komitesi Yöneticisi",
  ROLE_ISG_UZMANI: "İSG Uzmanı",
  ROLE_YONETSEL_BIRIM_YONETICISI: "Yönetsel Birim Yöneticisi",
  ROLE_YONETSEL_BIRIM_PERSONELI: "Yönetsel Birim Personeli",
  ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ: "Operasyonel Birim Yöneticisi",
  ROLE_OPERASYONEL_BİRİM_PERSONELİ: "Operasyonel Birim Personeli",
  ROLE_YUKLENICI_YÖNETİCİ: "Yüklenici Yöneticisi",
  ROLE_YUKLENICI_PERSONEL: "Yüklenici Personeli",
  ROLE_YUKLENICI_ISG_UZMANI: "Yüklenici İSG Uzmanı",
  ROLE_YUKLENICI_OSGB_ISG_UZMANI: "Yüklenici OSGB İSG Uzmanı",
  ROLE_OSGB_ISG_UZMANI: "OSGB İSG Uzmanı",
  ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ: "Yaşam Güvenliği Komitesi Üyesi",
  ROLE_BÖLGE_MÜDÜRÜ: "Bölge Müdürü",
  ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ: "Yüklenici OSGB Sağlık Personeli",
  ROLE_OSGB_SAĞLIK_PERSONELİ: "OSGB Sağlık Personeli",
  ROLE_SAĞLIK_PERSONELİ: "Sağlık Personeli",
  ROLE_GDZ_EYAŞ_İSG_UZMANI: "GDZ Eyaş İsg Uzmanı",
  ROLE_KALITE_KONTROL_YONETICISI: "Kalite Kontrol Yöneticisi",
  ROLE_KALITE_KONTROL_PERSONELI: "Kalite Kontrol Personeli",
};

export const examType = {
  WORK_ENTRY: "İşe Giriş Muayenesi",
  PREGNANT: "Gebe Muayenesi",
  GENERAL_HEALTH: "Periyodik Sağlık Muayenesi",
  CHRONIC_AND_DISABLED: "Kronik Hasta ve Engelli Muayenesi",
  JOB_ACCIDENT_BACK: "İş Kazası Dönüş Muayenesi",
};

export const inspectionTypes = {
  // BEHAVIORDRIVENAUDITING: "Davranış Odaklı Denetim",
  // BRIEFING: "Briefing",
  // MONTLYPLANNEDSAFETYCHECK: "Aylık Planlı Emniyet Kontrolleri",
  // NEARMISSREPORT: "İSG ve Çevre Uygunsuzluk Denetimi",
  // VEHICLE_INSPECTION: "Binek ve Platformlu Araç Kontrolü",
  SAMPLE: "Örnek Uygunsuzluk Denetimi",
};

export const taskGoal = {
  1: "Ayda Bir",
  2: "İki Haftada Bir",
  4: "Haftada Bir",
  null: "Yok",
};

export const kkdTypes = {
  KİSİSEL: "Kişisel",
  EKİPSEL: "Ekipsel",
};

export const companyTypes = {
  EDC: "Elektrik Dağıtım Şirketi (EDC)",
  CONTRACTOROPR: "Yüklenici Operasyonel",
  CONTRACTORISG: "Yüklenici ISG",
  CONTRACTOROSGB: "Yüklenici OSGB",
};

export const companyUserTypes = {
  CONTRACTOROPR: "Yüklenici Operasyonel",
  CONTRACTORISG: "Yüklenici ISG",
  CONTRACTOROSGB: "Yüklenici OSGB",
};

export const logLevels = {
  ERROR: "ERROR",
  INFO: "INFO",
  WARN: "WARN",
};

export const months = {
  JANUARY: "Ocak",
  FEBRUARY: "Şubat",
  MARCH: "Mart",
  APRIL: "Nisan",
  MAYIS: "Mayıs",
  JUNE: "Haziran",
  JULY: "Temmuz",
  AUGUST: "Ağustos",
  SEPTEMBER: "Eylül",
  OCTOBER: "Ekim",
  NOVEMBER: "Kasım",
  DECEMBER: "Aralık",
};

export const eyasRoles = {
  ROLE_OSGB_ISG_UZMANI: "OSGB İSG Uzmanı",
  ROLE_OSGB_SAĞLIK_PERSONELİ: "OSGB Sağlık Personeli",
  ROLE_GDZ_EYAŞ_İSG_UZMANI: "Eyaş İsg Uzmanı",
};

export const GDZ_EYAŞ = {
  companyName: "GDZ ENERJİ YATIRIMLARI A.Ş.",
  companyId: 59,
  isgSpecialistName: "ROLE_GDZ_EYAŞ_İSG_UZMANI",
};

export const edcRoles = {
  ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ:
    "Yaşam Güvenliği Komitesi Yöneticisi",
  ROLE_ISG_UZMANI: "İSG Uzmanı",
  ROLE_BÖLGE_MÜDÜRÜ: "Bölge Müdürü",
  ROLE_YONETSEL_BIRIM_YONETICISI: "Yönetsel Birim Yöneticisi",
  ROLE_YONETSEL_BIRIM_PERSONELI: "Yönetsel Birim Personeli",
  ROLE_OPERASYONEL_BİRİM_YÖNETİCİSİ: "Operasyonel Birim Yöneticisi",
  ROLE_OPERASYONEL_BİRİM_PERSONELİ: "Operasyonel Birim Personeli",
  ROLE_SAĞLIK_PERSONELİ: "Sağlık Personeli",
};

export const contractorIsgRoles = {
  ROLE_OSGB_ISG_UZMANI: "OSGB İSG Uzmanı",
  ROLE_OSGB_SAĞLIK_PERSONELİ: "OSGB Sağlık Personeli",
};

export const contractorOprRoles = {
  ROLE_YUKLENICI_YÖNETİCİ: "Yüklenici Yöneticisi",
  ROLE_YUKLENICI_PERSONEL: "Yüklenici Personeli",
  ROLE_YUKLENICI_ISG_UZMANI: "Yüklenici İSG Uzmanı",
};

export const contractorOsgbRoles = {
  ROLE_YUKLENICI_OSGB_ISG_UZMANI: "Yüklenici OSGB İSG Uzmanı",
  ROLE_YÜKLENİCİ_OSGB_SAĞLIK_PERSONELİ: "Yüklenici OSGB Sağlık Personeli",
};

export const ygkMember = {
  ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ: "Yaşam Güvenliği Komitesi Üyesi",
};

export const inovenRole = {
  ROLE_INNOVEN_ISG_UZMANI: "İnoven İSG Uzmanı",
};

export const days = {
  1: "Pazartesi",
  2: "Salı",
  3: "Çarşamba",
  4: "Perşembe",
  5: "Cuma",
  6: "Cumartesi",
  7: "Pazar",
};

export const severities = {
  LOW: language.severities.low,
  MEDIUM: language.severities.medium,
  HIGH: language.severities.high,
};

export const certificateStatus = {
  YES: language.certificateReport.true,
  NO: language.certificateReport.none,
  NOT_REQUIRED: language.certificateReport.notRequired,
};

export const reportStatus = {
  true: language.firstAid.exist,
  false: language.firstAid.absent,
};

// export const suitability = {
//   "true": language.suitability.true,
//   "false": language.suitability.false,
// };

export const status = {
  true: language.status.exist,
  false: language.status.absent,
};

export const size = {
  xs: "xsmall",
  s: "small",
  m: "medium",
  l: "large",
  xl: "xlarge",
  xxl: "xxlarge",
  xxxl: "xxxlarge",
  xxxxl: "xxxxlarge",
  xxxxxl: "xxxxxlarge",
  xxxxxxl: "xxxxxxlarge",
};

export const suitability = {
  true: language.suitability.true,
  false: language.suitability.false,
};

export const trueFalse = {
  true: language.etc.yes,
  false: language.etc.no,
};

export const succession = {
  true: language.history.successful,
  false: language.history.unsuccessful,
};

export const platform = {
  MOBILE: "Mobile",
  WEB: "Web",
};

export const active = {
  true: language.workAccidentSelections.active,
  false: language.workAccidentSelections.nonActive,
};

export const approveStatus = {
  true: "Onaylandı",
  false: "Onaylanmadı",
};

export const monthsForNumber = {
  1: "Ocak",
  2: "Şubat",
  3: "Mart",
  4: "Nisan",
  5: "Mayıs",
  6: "Haziran",
  7: "Temmuz",
  8: "Ağustos",
  9: "Eylül",
  10: "Ekim",
  11: "Kasım",
  12: "Aralık",
};

export const severitiesByCount = {
  0: language.severities.low,
  1: language.severities.medium,
  2: language.severities.high,
};

export const actionStatusByNumber = {
  0: "Onay Bekliyor",
  1: "Reddedildi",
  2: "Başlanmadı",
  3: "Onaylandı",
  "Termin Süresi Geçmiş": "Termin Süresi Geçmiş",
};

export const monthsWithTwoDigits = {
  "01": "Ocak",
  "02": "Şubat",
  "03": "Mart",
  "04": "Nisan",
  "05": "Mayıs",
  "06": "Haziran",
  "07": "Temmuz",
  "08": "Ağustos",
  "09": "Eylül",
  10: "Ekim",
  11: "Kasım",
  12: "Aralık",
};

export const nearMissTypes = {
  0: "Tehlikeli Durum",
  1: "Tehlikeli Davranış",
  2: "Ramak Kala",
};

export const visitStatus = {
  DONE: language.visits.done,
  CANCELED: language.visits.canceled,
  PLANNED: language.visits.planned,
};
export const visitStatusForSecurity = {
  DONE: language.visits.done,
  PLANNED: language.visits.planned,
};
export const visitStatusForHost = {
  PLANNED: language.visits.planned,
  CANCELED: language.visits.canceled,
};
