import React from "react";
import AccidentDefinitionTable from "./AccidentDefinition/AccidentDefinitionTable";
import AccidentPlaceTable from "./AccidentPlace/AccidentPlaceTable";
import AccidentTypeTable from "./AccidentType/AccidentTypeTable";
import BodyPartTable from "./BodyPart/BodyPartTable";
import InjuryTypeTable from "./InjuryType/InjuryTypeTable";
import LostLimbTable from "./LostLimb/LostLimbTable";
import SectionTable from "./Section/SectionTable";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import SessionHelper from "../../helpers/SessionHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import { useLocation } from "react-router-dom";

export default function WorkAccidentsSelectionsPage() {
  const roles = SessionHelper.getUser().roles;
  const location = useLocation();
  const page = location.pathname.substring(1);
  const accidentDefinitionAuth = getAuthorizationForPage(
    roles,
    "accidentDefinition"
  );
  const accidentPlaceAuth = getAuthorizationForPage(roles, "accidentPlace");
  const accidentTypeAuth = getAuthorizationForPage(roles, "accidentType");
  const bodyPartAuth = getAuthorizationForPage(roles, "bodyPart");
  const injuryTypeAuth = getAuthorizationForPage(roles, "injuryType");
  const lostLimbAuth = getAuthorizationForPage(roles, "lostLimb");
  const sectionAuth = getAuthorizationForPage(roles, "section");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {(page === "accidentDefinition" &&
          (accidentDefinitionAuth?.view ? (
            <AccidentDefinitionTable />
          ) : (
            <UnauthorizedPage />
          ))) ||
          (page === "accidentPlace" &&
            (accidentPlaceAuth?.view ? (
              <AccidentPlaceTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "accidentType" &&
            (accidentTypeAuth?.view ? (
              <AccidentTypeTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "bodyPart" &&
            (bodyPartAuth?.view ? <BodyPartTable /> : <UnauthorizedPage />)) ||
          (page === "injuryType" &&
            (injuryTypeAuth?.view ? (
              <InjuryTypeTable />
            ) : (
              <UnauthorizedPage />
            ))) ||
          (page === "lostLimb" &&
            (lostLimbAuth?.view ? <LostLimbTable /> : <UnauthorizedPage />)) ||
          (page === "section" &&
            (sectionAuth?.view ? <SectionTable /> : <UnauthorizedPage />))}
      </Grid>
    </Container>
  );
}
