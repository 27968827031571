import Request, { RequestAll } from "../../../helpers/Request";

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions(id) {
  const {
    companies,
    cities,
    riskClasses,
    fieldOfActivities,
    allTowns,
    allUnsuitUsers,
    allManagers,
    deptUsers,
    allApprovers,
  } = await getAll(id);
  const options = {
    managers: [],
    isgUsers: [],
    approvers: [],
    companies,
    cities,
    towns: [],
    departments: [],
    riskClasses,
    unsuitUser: [],
    fieldOfActivities,
    allTowns,
    allUnsuitUsers,
    allManagers,
    deptUsers,
    allApprovers,
  };
  return options;
}

const getAll = async (dept_id) => {
  let arr = [
    { action: "get", urlExtension: "/api/enums/department-types" },
    { action: "get", urlExtension: "/api/enums/risk-classes" },
    { action: "get", urlExtension: "/api/cities-towns/towns" },
    { action: "get", urlExtension: "/api/users/all-department-managers" },
    { action: "get", urlExtension: "/api/users/all-nonconformity-users" },
    { action: "get", urlExtension: "/api/companies/basic-info" },
    { action: "get", urlExtension: "/api/cities-towns/cities-full" },
  ];
  dept_id &&
    arr.push({
      action: "get",
      urlExtension: "/api/users/belongs-to-department/" + dept_id,
    });
  const resps = await RequestAll(arr);

  const fieldOfActivities = await getActivities(resps[0]);
  const riskClasses = await getRiskClasses(resps[1]);
  const allTowns = await getAllTowns(resps[2]);
  const allManagers = await getAllManagers(resps[3]);
  const allUnsuitUsers = await getAllUnsuitUsers(resps[4]);
  const companies = await getCompanies(resps[5]);
  const cities = await getCities(resps[6]);
  const deptUsers = dept_id ? await getDepartmentUsers(resps[7]) : [];
  const allApprovers = await getAllApprovers(dept_id);
  return {
    fieldOfActivities,
    riskClasses,
    allTowns,
    allManagers,
    allUnsuitUsers,
    companies,
    cities,
    deptUsers,
    allApprovers,
  };
};

export const getActivities = async (reqAllResp) => {
  let fetch;
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/enums/department-types");
  const data = resp.data;
  let new_json = {};
  for (let i = 0; i < data.length; i++) {
    new_json[data[i]] = data[i];
  }
  fetch = new_json;
  return fetch;
};

export const getRiskClasses = async (reqAllResp) => {
  let fetch;
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/enums/risk-classes");
  const data = resp.data;
  let new_json = {};
  for (let i = 0; i < data.length; i++) {
    new_json[data[i]] = data[i];
  }
  fetch = new_json;
  return fetch;
};

export const getTowns = async (id) => {
  const resp = await Request("get", "/api/cities-towns/towns/" + id);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] = data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getAllTowns = async (reqAllResp) => {
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/cities-towns/towns");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let towns = {};
    towns["name"] = data[i].name;
    towns["id"] = data[i].id;
    towns["cityName"] = data[i].belongingCity?.name;
    arr.push(towns);
  }
  return arr;
};

// export const getDepartments = async (id) => {
//   //let fetch;
//   const resp = await Request(
//     "get",
//     "/api/departments/belongs-to-company/" + id + "/basic-info"
//   );
//   const data = resp.data;
//   let arr = [];
//   for (let i = 0; i < data.length; i++) {
//     let user = {};
//     user["companyName"] = data[i]?.companyName;
//     user["name"] = data[i]?.name;
//     user["id"] = data[i]?.id;
//     arr.push(user);
//   }
//   return arr;
//   /*
//   const json = resp.data;
//   let new_json = {};
//   for (let i = 0; i < json.length; i++) {
//     new_json[json[i].id] = {name: json[i]?.name, companyName: json[i]?.companyName};
//   }
//   fetch = new_json;
//   return(fetch); */
// };

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["companyName"] = data[i]?.companyName;
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getUsersWithRoleAndCompany = async (role, department) => {
  const param = {
    departmentId: department,
    for: role,
  };
  const resp = await Request("get", "/api/users/list", null, param);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getAllManagers = async (reqAllResp) => {
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/users/all-department-managers");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getAllUnsuitUsers = async (reqAllResp) => {
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/users/all-nonconformity-users");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getAllApprovers = async (dept_id) => {
  let arr = [];
  if (dept_id) {
    const resp = await Request("get", "/api/departments/approver/" + dept_id);
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let user = {};
      user["name"] = data[i].fName + " " + data[i].lName;
      user["id"] = data[i].id;
      arr.push(user);
    }
  }
  return arr;
};

const getCompanies = async (reqAllResp) => {
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let company = {};
    company["name"] = data[i].isActive
      ? data[i].name
      : data[i].name + " (Silinmiş)";
    company["id"] = data[i].id;
    arr.push(company);
  }
  return arr;
};

const getCities = async (reqAllResp) => {
  let fetch;
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/cities-towns/cities-full");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    new_json[json[i].id] = json[i].name;
  }
  fetch = new_json;
  return fetch;
};

export const getUsers = async (comp_id) => {
  const resp = await Request("get", "/api/users/belongs-to-company/" + comp_id);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getDepartmentUsers = async (reqAllResp, dept_id) => {
  const resp = reqAllResp
    ? reqAllResp
    : await Request("get", "/api/users/belongs-to-department/" + dept_id);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};
