import { makeStyles } from "@material-ui/core/styles";
import {
  TextArea,
  TextSelector,
} from "../../../../components/Fields/TextFields";
import { FormControl } from "@material-ui/core";
import { CustomDatePicker } from "../../../../components/Selectors/DatePickers";
import SessionHelper from "../../../../helpers/SessionHelper";
import jsonMap from "../../../../components/Utils/jsonMap";
import moment from "moment";
import Request from "../../../../helpers/Request";
import React from "react";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "40%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  textField: {
    marginBottom: "3vw",
    whiteSpace: "pre-wrap",
    textAlign: "left",
  },
  dateField: {
    marginBottom: "3vw",
    marginTop: 0,
  },
}));

export default function PersonalDataForm({
  userProps,
  setUserProps,
  errors,
  setErrors,
  options,
  setChangeMadeToProps,
  initialDatas,
}) {
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const isAdmin = user?.roles.includes("ROLE_SİSTEM_ADMİNİ");
  const { nameError, surnameError, emailError, tcError, sgkError, phoneError } =
    errors;

  return (
    <FormControl required autoComplete="off" className={classes.formControl}>
      <TextArea //NAME
        required
        error={nameError}
        label={"Adı"}
        value={userProps.fName}
        onChangeFunc={(value) => {
          if (value.target.value.length > 30) {
            setErrors({
              ...errors,
              nameError: "İsim 30 karakterden uzun olamaz.",
            });
          } else {
            nameError && setErrors({ ...errors, nameError: null });
            const val = value.target.value;
            setUserProps({ ...userProps, fName: val ? val : null });
            setChangeMadeToProps(true);
          }
        }}
        style={classes.textField}
      />
      <TextArea //SURNAME
        required
        error={surnameError}
        label={"Soyadı"}
        value={userProps.lName}
        onChangeFunc={(value) => {
          if (value.target.value.length > 30) {
            setErrors({
              ...errors,
              surnameError: "İsim 30 karakterden uzun olamaz.",
            });
          } else {
            surnameError && setErrors({ ...errors, surnameError: null });
            const val = value.target.value;
            setUserProps({ ...userProps, lName: val ? val : null });
            setChangeMadeToProps(true);
          }
        }}
        style={classes.textField}
      />
      <TextArea //EMAIL
        required
        error={emailError}
        label={"E-Posta Adresi"}
        value={userProps.email}
        onChangeFunc={(value) => {
          const invalid = ["ş", "ç", "ö", "ğ", "ü", "ı"];
          const val = value.target.value;
          setUserProps({ ...userProps, email: val ? val : null });
          setChangeMadeToProps(true);
          let re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
          if (re.test(value.target.value)) {
            if (invalid.some((el) => value.target.value.includes(el))) {
              setErrors({
                ...errors,
                emailError: "Lütfen geçerli bir e-posta adresi girin.",
              });
            } else {
              emailError && setErrors({ ...errors, emailError: null });
              if (
                initialDatas.email !== null &&
                initialDatas.email !== value.target.value
              ) {
                Request(
                  "get",
                  "/api/users/is-user-exist?&search=" + value.target.value
                ).then((resp) => {
                  if (resp.data && resp.data.exist) {
                    setErrors({
                      ...errors,
                      emailError:
                        "Bu E-Posta adresine sahip bir kullanıcı bulunmaktadır.",
                    });
                  }
                });
              }
            }
          } else {
            setErrors({
              ...errors,
              emailError: "Lütfen geçerli bir e-posta adresi girin.",
            });
          }
        }}
        style={classes.textField}
      />
      <TextArea //TC
        required
        label={"TC No"}
        error={tcError}
        shrink={userProps.tcNo !== null}
        value={userProps.tcNo}
        style={classes.textField}
        onChangeFunc={(value) => {
          const invalid = ["ş", "ç", "ö", "ğ", "ü", "ı"];
          const val = value.target.value;
          setUserProps({ ...userProps, tcNo: val ? val : null });
          setChangeMadeToProps(true);
          // eslint-disable-next-line no-useless-escape
          let re = /^[1-9]{1}[0-9]{9}[02468]{1}$/;
          if (re.test(value.target.value)) {
            if (invalid.some((el) => value.target.value.includes(el))) {
              setErrors({
                ...errors,
                tcError: "Lütfen gerçek bir TC kimlik no girin.",
              });
            } else {
              tcError && setErrors({ ...errors, tcError: null });
              if (
                initialDatas.tcNo !== null &&
                initialDatas.tcNo !== value.target.value
              ) {
                Request(
                  "get",
                  "/api/users/is-user-exist?&search=" + value.target.value
                ).then((resp) => {
                  if (resp.data && resp.data.exist) {
                    setErrors({
                      ...errors,
                      tcError:
                        "Bu TC kimlik no'ya sahip bir kullanıcı bulunmaktadır.",
                    });
                  }
                });
              }
            }
          } else {
            setErrors({
              ...errors,
              tcError: "Lütfen gerçek bir TC kimlik no girin.",
            });
          }
        }}
      />
      <TextSelector //APPUSER
        label={"Uygulama Kullanıcısı"}
        value={userProps.isAppUser}
        shrink={userProps.isAppUser !== null}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, isAppUser: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("isg", options)}
      />
      {isAdmin && (
        <TextSelector //ACTIVE
          label={"Aktiflik"}
          value={userProps.enabled}
          shrink={userProps.enabled !== null}
          style={classes.textField}
          onChangeFunc={(value) => {
            const val = value.target.value;
            setUserProps({ ...userProps, enabled: val ? val : null });
            setChangeMadeToProps(true);
          }}
          options={jsonMap("active", options)}
        />
      )}
      <TextArea //SGK
        error={sgkError}
        label={"SGK Numarası"}
        value={userProps.userSGKNo}
        onChangeFunc={(value) => {
          if (value.target.value.length > 26) {
            setErrors({
              ...errors,
              sgkError: "SGK Numarası 26 karakterden uzun olamaz.",
            });
          } else {
            sgkError && setErrors({ ...errors, sgkError: null });
            const val = value.target.value;
            setUserProps({ ...userProps, userSGKNo: val ? val : null });
            setChangeMadeToProps(true);
            if (
              initialDatas.userSGKNo !== null &&
              initialDatas.userSGKNo !== value.target.value
            ) {
              Request(
                "get",
                "/api/users/is-user-exist?&search=" + value.target.value
              ).then((resp) => {
                if (resp.data && resp.data.exist) {
                  setErrors({
                    ...errors,
                    sgkError:
                      "Bu SGK numarasına sahip bir kullanıcı bulunmaktadır.",
                  });
                }
              });
            }
          }
        }}
        style={classes.textField}
      />
      <CustomDatePicker //BIRTHDATE
        style={classes.dateField}
        label={"Doğum Tarihi"}
        value={userProps.birthDate}
        shrink={userProps.birthDate}
        onChangeFunc={(value) => {
          setUserProps({
            ...userProps,
            birthDate: value
              ? moment(value).set("hour", 12).set("minute", 0).toISOString(true)
              : null,
          });
          setChangeMadeToProps(true);
        }}
      />
      <TextArea //PHONENO
        required
        label={"Telefon Numarası"}
        value={userProps.phoneNo}
        error={phoneError}
        style={classes.textField}
        onChangeFunc={(value) => {
          const invalid = ["ş", "ç", "ö", "ğ", "ü", "ı"];
          const val = value.target.value;
          setUserProps({ ...userProps, phoneNo: val ? val : null });
          setChangeMadeToProps(true);
          // eslint-disable-next-line no-useless-escape
          let re = /^(05)([0-9]{2})\s?([0-9]{3})\s?([0-9]{2})\s?([0-9]{2})$/;
          if (re.test(value.target.value)) {
            if (invalid.some((el) => value.target.value.includes(el))) {
              setErrors({
                ...errors,
                phoneError: "Lütfen gerçek bir telefon numarası girin.",
              });
            } else {
              phoneError && setErrors({ ...errors, phoneError: null });
              if (
                initialDatas.phoneNo !== null &&
                initialDatas.phoneNo !== value.target.value
              ) {
                Request(
                  "get",
                  "/api/users/is-user-exist?&search=" + value.target.value
                ).then((resp) => {
                  if (resp.data && resp.data.exist) {
                    setErrors({
                      ...errors,
                      phoneError:
                        "Bu telefon numarasına sahip bir kullanıcı bulunmaktadır.",
                    });
                  }
                });
              }
            }
          } else {
            setErrors({
              ...errors,
              phoneError: "Lütfen gerçek bir telefon numarası girin.",
            });
          }
        }}
      />
      <TextSelector //EDUCATION
        label={"Eğitim Durumu"}
        value={userProps.educationStatusId}
        shrink={userProps.educationStatusId !== null}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, educationStatusId: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("edu", options)}
      />
      <TextSelector //MARITAL
        label={"Evlilik Durumu"}
        value={userProps.maritalStatusId}
        shrink={userProps.maritalStatusId !== null}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, maritalStatusId: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("marital", options)}
      />
      <TextSelector //GENDER
        label={"Cinsiyeti"}
        value={userProps.genderId}
        shrink={userProps.genderId !== null}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, genderId: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("gender", options)}
      />
    </FormControl>
  );
}
