import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import { getFilterDepartments, getTowns } from "./GetOptions";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [towns, setTowns] = React.useState(options?.towns);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Başlangıç Tarihi"
                value={
                  tableFilters.startTime ? moment(tableFilters.startTime) : null
                }
                shrink={tableFilters.startTime}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    startTime: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Bitiş Tarihi"
                value={
                  tableFilters.endTime ? moment(tableFilters.endTime) : null
                }
                shrink={tableFilters.endTime}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    endTime: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.filterCompanies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  let newOpts = options;
                  newOpts.filterDepartments = value?.id
                    ? await getFilterDepartments(value?.id)
                    : [];
                  setOptions(newOpts);
                  setTableFilters({ ...tableFilters, company: value });
                  if (!value) {
                    setTableFilters({
                      ...tableFilters,
                      company: value,
                      department: value,
                    });
                  }
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.filterDepartments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.regions?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.region}
                onChange={(event, value) => {
                  setTableFilters({ ...tableFilters, region: value });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölge"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.cities?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.city}
                onChange={async (event, value) => {
                  let newOpts = options;
                  newOpts.towns = value?.id
                    ? await getTowns(value?.id)
                    : options?.towns;
                  setOptions(newOpts);
                  setTableFilters({ ...tableFilters, city: value });
                  setTowns(value ? await getTowns(value?.id) : options?.towns);

                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="İl"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={towns?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.city}
                value={tableFilters.town}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, town: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="İlçe"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
