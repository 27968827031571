import React, { useCallback } from "react";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from "../../../../helpers/Request";
import moment from 'moment';
import ModalForm from './ModalForm';
import TableEntryModal from "../../../../components/Modal/TableEntryModal";
import showSnackbar from "../../../../components/Utils/showSnackbar";

export default function NewTaskModal({modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, tableRef, oldTaskProps, opts, modalLoading}) {
  const language = LanguageHelper.getLanguage();
  
  const [newTaskProps, setNewTaskProps] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setNewTaskProps({
      userID: oldTaskProps ? oldTaskProps.user : null,
      departmentID: oldTaskProps ? oldTaskProps.department : null,
      startTime: oldTaskProps ? oldTaskProps.startTime : moment().toISOString(true),
      startTimes: [],
      deadline: oldTaskProps ? oldTaskProps.term : moment().set("hour", 23).set("minute", 59).toISOString(true),
      deadlines: [],
      eInspectionTypes: oldTaskProps ? oldTaskProps.inspectionType : [],
      eStatus: oldTaskProps ? oldTaskProps.eStatus : null,
      cityTownId: oldTaskProps ? oldTaskProps.cityTownId : null,
    });
    setOptions(opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldTaskProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);
  
  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setNewTaskProps({
        userID: null,
        departmentID: null,
        startTime: moment().toISOString(true),
        startTimes: [],
        deadline: moment().set("hour", 23).set("minute", 59).toISOString(true),
        deadlines: [],
        eInspectionTypes: [],
        eStatus: null,
        cityTownId: null,
      });
      let newOpts = opts;
      newOpts.compsAndDepts = null;
      setOptions(newOpts);
    }, 300);
  }

  /**
    * Request to post a new inspection task with the data given.
    */
  const submitNewTask = async () => {
    setLoading(true);
    const resp = await Request("post", '/api/inspections/save-inspection', newTaskProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  /**
    * Request to replace the old data with the new data.
    */
  const updateTask = async () => {
    setLoading(true);
    const resp = await Request("patch", '/api/inspections/' + oldTaskProps.id, newTaskProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}>
        <ModalForm
          oldTaskProps={oldTaskProps}
          options={options}
          setOptions={setOptions}
          newTaskProps={newTaskProps}
          setNewTaskProps={setNewTaskProps}
          updateTask={updateTask}
          submitNewTask={submitNewTask}
          loading={loading}
        />
      </TableEntryModal>
    </>
  );
}