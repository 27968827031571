import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../assets/styles/tableContainer";
import showSnackbar from "../../components/Utils/showSnackbar";
import { GetOptions } from "./GetOptions";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function AuditTypesModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  auditTypesProps,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestAuditTypesProps, setRequestAuditTypesProps] = React.useState(
    {}
  );
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setRequestAuditTypesProps({
      forms: auditTypesProps ? auditTypesProps.forms : null,
      name: auditTypesProps ? auditTypesProps.name : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditTypesProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestAuditTypesProps({
      forms: null,
      name: null,
    });
  };

  const submitNewAuditType = async () => {
    setLoading(true);
    requestAuditTypesProps.formTemplateIds = requestAuditTypesProps?.forms.map(
      (form) => form.id
    );
    delete requestAuditTypesProps.forms;
    const resp = await Request(
      "post",
      "/api/inspection-type",
      requestAuditTypesProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateAuditType = async () => {
    setLoading(true);
    let props = { ...requestAuditTypesProps };
    props.id = auditTypesProps.id;
    props.formTemplateIds = props?.forms.map((form) => form.id);
    delete props.forms;
    const resp = await Request(
      "put",
      "/api/inspection-type/" + props.id,
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestAuditTypesProps={requestAuditTypesProps}
              auditTypesProps={auditTypesProps}
              setRequestAuditTypesProps={setRequestAuditTypesProps}
              submitNewAuditType={submitNewAuditType}
              updateAuditType={updateAuditType}
              loading={loading}
              options={options}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
