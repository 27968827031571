import React, { useCallback } from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import { getDepartments } from "./GetOptions";
import { trueFalse } from "../../../assets/constants";
import SessionHelper from "../../../helpers/SessionHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters
 */
export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");

  const admin = user.roles.includes("ROLE_SİSTEM_ADMİNİ");

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [departments, setDepartments] = React.useState([]);

  const init = useCallback(async () => {
    if (specialContractor) {
      setDepartments(await getDepartments(user?.company?.id));
      setTableFilters({
        ...tableFilters,
        company: user?.company?.id,
        department: null,
      });
    } else {
      if (tableFilters?.company) {
        setDepartments(await getDepartments(tableFilters?.company?.id));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialContractor, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />

      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            {!specialContractor && (
              <Grid item xs={6}>
                <Autocomplete
                  id="combo-box"
                  options={options.companies?.sort(function (a, b) {
                    return collator.compare(a.name, b.name);
                  })}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  fullWidth={true}
                  value={tableFilters.company}
                  onChange={async (event, value) => {
                    setDepartments(await getDepartments(value?.id));
                    setTableFilters({
                      ...tableFilters,
                      company: value,
                      department: null,
                    });
                  }}
                  renderOption={(option) => <span>{option.name}</span>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Şirket"
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={"Rol"}
                value={tableFilters.role}
                onChangeFunc={async (value) =>
                  setTableFilters({ ...tableFilters, role: value.target.value })
                }
                style={classes.textField}
                options={jsonMap("roles", options)}
              />
            </Grid>
            {admin && (
              <Grid item xs={6}>
                <TextSelector
                  label={"Silinmiş Kullanıcı"}
                  value={tableFilters.active}
                  onChangeFunc={(value) =>
                    setTableFilters({
                      ...tableFilters,
                      active: value.target.value,
                    })
                  }
                  style={classes.textField}
                  options={jsonMap("isg", options)}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextSelector
                label={"Aktiflik"}
                value={tableFilters.enabled}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    enabled: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap("active", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={"Uygulama Kullanıcısı"}
                value={tableFilters.isAppUser}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    isAppUser: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
