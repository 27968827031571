import React from 'react';
import { useLocation } from 'react-router-dom';
import SessionHelper from '../../../helpers/SessionHelper';
import DepartmentDetails from './DepartmentDetails';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import UnauthorizedPage from '../../UnauthorizedPage';

export default function DepartmentDetailsPage() {
  const location = useLocation();
  const id = location.pathname.substring(12);
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "departmentsTable");

  return (
    <>
      {authorization?.view ? <DepartmentDetails id={id}/> : <UnauthorizedPage/>}
    </>
  );
}