import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "40%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  sgkContainer: {
    width: "95%",
    border: "1px solid #d5d5d5",
    borderRadius: "15px",
    margin: "0 auto",
    textAlign: "left",
    padding: 10,
    marginBottom: 10
  }
}));

export default function SGKLogs({logs}) {
  const classes = useStyles();

  const RenderLogs = () => {
    return(
      logs.map((data, index) => {
        return (
          <div key={index} className={classes.sgkContainer}>
            <p>{"Kayıt Bilgisi: " + data.updateInfo}</p>
            <p>{"Şirket: " + data.company.name}</p>
            <p>{"Bölüm: " + data.department.name}</p>
            <p>{"Bölüm SGK: " + (data.departmentSGKNo ? data.departmentSGKNo : "Yok")}</p>
            <p>{"İşten Çıkış Tarihi: " + (data.endDate ? moment(data.endDate).format('DD-MM-YYYY') : "Yok")}</p>
            <p>{"İşe Giriş Tarihi: " + (data.startDate ? moment(data.startDate).format('DD-MM-YYYY') : "Yok")}</p>
          </div>
        )
      })
    )
  }

  return (
    <div className={classes.root}>
      <RenderLogs/>
    </div>
  );
};