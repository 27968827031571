import Request from "../../../../helpers/Request";
import SessionHelper from "../../../../helpers/SessionHelper";

export const getUsers = async (deptId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-department/" + deptId
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getDepartments = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/departments/belongs-to-company/" + companyId + "/basic-info"
  ); // TODO change this endpoint with record/department-list-v2/{companyId}
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

export const getWorkingTypes = async () => {
  const resp = await Request("get", "/api/ibys/calisma-sekli");
  const data = resp.data;
  let calismaZamani = {};
  let calismaPozisyonu = {};
  let aracKullanimi = {};
  for (let i = 0; i < data.length; i++) {
    if (data[i].id >= 100 && data[i].id <= 199) {
      calismaPozisyonu[data[i].id] = data[i].name;
    } else if (data[i].id >= 200 && data[i].id <= 299) {
      calismaZamani[data[i].id] = data[i].name;
    } else if (data[i].id >= 300 && data[i].id <= 399) {
      aracKullanimi[data[i].id] = data[i].name;
    }
  }
  return [calismaPozisyonu, calismaZamani, aracKullanimi];
};

export const getWorkingAreas = async () => {
  const resp = await Request("get", "/api/ibys/calisma-ortami");
  const data = resp.data;
  let biyolojikEtkenlerSinifi = [];
  let elektrikSinifi = [];
  let fizikiOrtamSinifi = [];
  let gurultuSinifi = [];
  let havaSinifi = [];
  let kimyasalMaddeSinifi = [];
  let tozYonetmeligiSinifi = [];
  for (let i = 0; i < data.length; i++) {
    let area = {};
    area["name"] = data[i].name;
    area["id"] = data[i].id;
    if (data[i].type === "FIZIKI") {
      fizikiOrtamSinifi.push(area);
    } else if (data[i].type === "HAVA") {
      havaSinifi.push(area);
    } else if (data[i].type === "GURULTU") {
      gurultuSinifi.push(area);
    } else if (data[i].type === "BIYOLOJIK") {
      biyolojikEtkenlerSinifi.push(area);
    } else if (data[i].type === "KIMYASAL") {
      kimyasalMaddeSinifi.push(area);
    } else if (data[i].type === "TOZ") {
      tozYonetmeligiSinifi.push(area);
    } else if (data[i].type === "ELEKTRIK") {
      elektrikSinifi.push(area);
    }
  }
  return [
    biyolojikEtkenlerSinifi,
    elektrikSinifi,
    fizikiOrtamSinifi,
    gurultuSinifi,
    havaSinifi,
    kimyasalMaddeSinifi,
    tozYonetmeligiSinifi,
  ];
};

export const getWorkEquipments = async () => {
  const resp = await Request("get", "/api/ibys/is-ekipmanlari");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let eq = {};
    eq["name"] = data[i].name;
    eq["id"] = data[i].id;
    arr.push(eq);
  }
  return arr;
};

export const getRaporTuru = async () => {
  const resp = await Request("get", "/api/ibys/rapor-turu");
  const data = resp.data;
  return data;
};

export const getSonucKanaat = async () => {
  const resp = await Request("get", "/api/ibys/sonuc-ve-kanaat");
  const data = resp.data;
  return data;
};
export const getWorkingProfessions = async () => {
  const resp = await Request("get", "/api/ibys/calisma-meslegi");
  console.log(resp);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let eq = {};
    eq["name"] = data[i].value;
    eq["id"] = data[i].code;
    arr.push(eq);
  }
  console.log(arr);
  return arr;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [
    users,
    departments,
    allWorkingTypes,
    raporTuru,
    sonucVeKanaat,
    allWorkingAreas,
    isEkipmanlari,
    workerProfessions,
  ] = await Promise.all([
    getUsers(SessionHelper.getUser().department?.id),
    getDepartments(SessionHelper.getUser().companyId),
    getWorkingTypes(),
    getRaporTuru(),
    getSonucKanaat(),
    getWorkingAreas(),
    getWorkEquipments(),
    getWorkingProfessions(),
  ]);
  let calismaPozisyonu = allWorkingTypes[0];
  let calismaZamani = allWorkingTypes[1];
  let aracKullanimi = allWorkingTypes[2];

  let biyolojikEtkenlerSinifi = allWorkingAreas[0];
  let elektrikSinifi = allWorkingAreas[1];
  let fizikiOrtamSinifi = allWorkingAreas[2];
  let gurultuSinifi = allWorkingAreas[3];
  let havaSinifi = allWorkingAreas[4];
  let kimyasalMaddeSinifi = allWorkingAreas[5];
  let tozYonetmeligiSinifi = allWorkingAreas[6];

  const options = {
    departments,
    users,
    calismaPozisyonu,
    calismaZamani,
    aracKullanimi,
    raporTuru,
    sonucVeKanaat,
    biyolojikEtkenlerSinifi,
    elektrikSinifi,
    fizikiOrtamSinifi,
    gurultuSinifi,
    havaSinifi,
    kimyasalMaddeSinifi,
    tozYonetmeligiSinifi,
    isEkipmanlari,
    workerProfessions,
  };
  return options;
}
