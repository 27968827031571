import React, { useCallback, useRef } from "react";
import Request from '../../../helpers/Request';
import LanguageHelper from "../../../helpers/LanguageHelper";
import showSnackbar from '../../../components/Utils/showSnackbar';
import moment from "moment";
import { taskStatuses } from '../../../assets/constants';
import GetFilterFields from './ActionFilters';
import Table from '../../../components/Table/Table';
import FetchData from './FetchData';
import TableBackdrop from '../../../components/Table/TableBackdrop';
import CustomSnackbar from '../../../components/Snackbar/Snackbar';
import GetOptions from './GetOptions';
import SessionHelper from "../../../helpers/SessionHelper";
import ActionModal from './ActionModal';
import Details from './Details';
import { getUsers } from '../analysis/GetOptions';
import StatusColumn from './StatusColumn';
import { Checkbox, FormControlLabel, MenuItem } from "@material-ui/core";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

export default function Actions() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const id = user?.company?.id;
  const roles = user?.roles;
  const isAdmin = roles.includes("ROLE_SİSTEM_ADMİNİ");

  let statuses = {...taskStatuses};
  statuses.DONE = "Onay Bekliyor";

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [actionModalProps, setActionModalProps] = React.useState(null);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [columns, setColumns] = React.useState([]);
  const [selectedAction, setSelectedAction] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterCompany: null,
    filterDepartment: null,
    filterGivenBy: null,
    filterAssignedTo: null,
    filterStatus: null,
    filterTerminationStart: null,
    filterTerminationEnd: null,
    filterCompletionStart: null,
    filterCompletionEnd: null,
    filterApprovedStart: null,
    filterApprovedEnd: null,
    personal: null
  });

  const jsonMap = (json) => {
    return (
      json &&
      Object.keys(json).map((data) => (
        <MenuItem key={data} value={data}>
          {json[data]}
        </MenuItem>
      ))
    );
  };

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { field: 'id', title: 'No' },
      { field: 'customId', title: 'Risk No' },
      { field: 'givenByName', title: 'Atayan Kişi' },
      { field: 'assignedToName', title: 'Atanan Kişi' },
      { field: 'terminationDate', title: 'Termin Tarih', render: rowData => 
        moment(rowData.terminationDate).format("DD-MM-YYYY")
      },
      { field: 'completionDate', title: 'Tamamlanma Tarih', render: rowData => 
        rowData.completionDate ? moment(rowData.completionDate).format("DD-MM-YYYY") : "Tamamlanmadı" 
      },
      { field: 'approvedDate', title: 'Onaylanma Tarih', render: rowData => 
        rowData.approvedDate ? moment(rowData.approvedDate).format("DD-MM-YYYY") : "Onaylanmadı" 
      },
      { field: 'messages', title: 'Açıklama', render: rowData => 
        <div style={{overflowY: "auto", maxHeight: "200px", width: "200px", whiteSpace: "pre-wrap"}}>
          {rowData.messages}
        </div> 
      },
      {
        title: "Durum",
        field: "status",
        lookup: taskStatuses,
        render: (rowData) => {
          return (
            <StatusColumn
              rowData={rowData}
              roles={roles}
              jsonMap={jsonMap}
              statuses={options.statuses}
              setSeverity={setSeverity}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbar={setSnackbar}
              tableRef={tableRef}
            />
          );
        },
      },
    ]);
    setBackdropLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModalLoading(true);
    const users = await getUsers(id);
    setOptions({...options, users: users});
    setActionModalProps(data || {});
    setModalLoading(false);
  }

  const handleOpenDeleteDialog = (data) => {
    setSelectedAction(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedAction(null);
    setDialog(false);
  };

  const deleteAction = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", "/api/riskAnalysis/action/" + selectedAction?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterCompany: null,
      filterDepartment: null,
      filterGivenBy: null,
      filterAssignedTo: null,
      filterStatus: null,
      filterTerminationStart: null,
      filterTerminationEnd: null,
      filterCompletionStart: null,
      filterCompletionEnd: null,
      filterApprovedStart: null,
      filterApprovedEnd: null,
    });
  }

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteAction}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <ActionModal
        modalLoading={modalLoading}
        modal={actionModalProps}
        setModal={setActionModalProps}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        options={options}
      />
      <RenderFilters />
      <Table
        disableCreate
        tableRef={tableRef}
        authName="boardActions"
        tableName="Aksiyonlar"
        columns={columns}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        //deleteEntry={deleteAction}
        isDeletable={(rowData) => isAdmin || rowData?.companyId === id}
        isEditable={(rowData) => isAdmin || rowData?.companyId === id}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        headerComponents={
          <FormControlLabel
            control={<Checkbox checked={filterProps.personal} onChange={() => {
              setFilterProps({...filterProps, personal: !filterProps.personal});
              tableRef.current && tableRef.current.onQueryChange();
            }} name="futureTasks" />}
            label="Kendi Aksiyonlarımı Göster"
          />
        }
        detailsPanel={rowData => 
          <div style={{
            backgroundColor: "#2D3446",
            color: "white",
            textAlign: "center",
            alignItems: "center",
            padding: 20
          }}>
            <Details
              rowData={rowData}
            />
          </div>
        }
      />
    </>
  );
}
