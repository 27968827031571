import Request from "../../../helpers/Request";

export const getUsers = async () => {
  const resp = await Request("get", "/api/users/edc-users");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getFilterUsers = async () => {
  const resp = await Request("get", "/api/nearMiss/users/filterUsers");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
}

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const users = [];
  const filterUsers = await getFilterUsers();
  const options = {users, filterUsers}
  return options;
};
