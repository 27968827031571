import React, { useCallback } from "react";
import { ResponsiveContainer } from "recharts";
import PropTypes from "prop-types";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  createTheme,
  makeStyles,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import Request from "../../../helpers/Request";
import moment from "moment";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import PersonalDataForm from "./personaldata/PersonalDataForm";
import GetOptions from "../../users/components/GetOptions";
import {
  getRegions,
  getDepartments,
  getManagers,
} from "../components/GetOptions";
import WorkDataForm from "./workdata/WorkDataForm";
import showSnackbar from "../../../components/Utils/showSnackbar";
import SGKLogs from "./sgk/SGKLogs";
import UserFiles from "./userfiles/UserFiles";
import SlideDialog from "./SlideDialog";
import Trainings from "./trainingdata/Trainings";
import KKD from "./kkd/KKD";
import IsgCard from "./isgcard/IsgCard";
import HealthInspections from "./health/HealthInspections";
import Accidents from "./accidents/Accidents";
import NearMisses from "./nearmiss/NearMisses";
import Inspections from "./inspections/Inspections";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
    width: "100%",
    height: window.innerHeight > 900 ? "88vh" : "84vh",
    margin: "0 auto",
  },
  panel: {
    overflowY: "auto",
  },
  divider: {
    margin: 10,
  },
  submit: {
    marginTop: "10px",
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const formatRoles = (roles) => {
  let newArray = [];
  for (let i = 0; i < roles.length; i++) {
    newArray.push(roles[i].name);
  }
  return newArray;
};

function UserDetails({ id }) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const darkTheme = createTheme({ palette: { type: "dark" } });

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [data, setData] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const [generalError, setGeneralError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [changeMadeToFiles, setChangeMadeToFiles] = React.useState(false);
  const [changeMadeToProps, setChangeMadeToProps] = React.useState(false);
  const [fileRequestMade, setFileRequestMade] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [sgkLogs, setSgkLogs] = React.useState({});
  const [userProps, setUserProps] = React.useState({});
  const [files, setFiles] = React.useState({
    diploma: [],
    ekatFile: [],
    healthReport: [],
    operatorCertificates: [],
    driversLicenses: [],
    kkdZimmets: [],
    isgGeneralEducationCertificates: [],
    sgkIns: [],
    sgkOuts: [],
    firstAidCertificateFile: [],
  });
  const [fileProps, setFileProps] = React.useState({
    userId: null,
    healthReportRequestList: [],
    ekatRequestList: [],
    operatorCertificateRequestList: [],
    isgGeneralEducationCertificateRequestList: [],
    driversLicenceRequestsList: [],
    firstAidCertificateRequestList: [],
  });
  const [errors, setErrors] = React.useState({
    nameError: null,
    surnameError: null,
    emailError: null,
    titleError: null,
    tcError: null,
    sgkError: null,
    phoneError: null,
    hourOfWorkError: null,
    companyError: null,
    departmentError: null,
    roleError: null,
    ekatErrors: [],
    healthReportErrors: [],
    operatorCertificateErrors: [],
    isgErrors: [],
    driversLicenceErrors: [],
    firstAidErrors: [],
  });
  const [initialDatas, setInitialDatas] = React.useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getDetails = useCallback(async (id) => {
    const resp = await Request("get", "/api/users/" + id);
    console.log(resp);
    return resp;
  }, []);

  const getSgkLogs = useCallback(async (id) => {
    const resp = await Request("get", "/api/users/" + id + "/sgk-logs");
    console.log(resp);
    return resp.data;
  }, []);

  const init = useCallback(async () => {
    const [options, resp, logs] = await Promise.all([
      GetOptions(true),
      getDetails(id),
      getSgkLogs(id),
    ]);
    const data = resp.data;
    setOptions(options);
    setData(resp);
    setSgkLogs(logs);

    const regionList = await getRegions(data?.company?.id);
    let deps = data?.company ? await getDepartments(data?.company?.id) : [];
    let managers = data?.department
      ? await getManagers(data?.department?.id)
      : null;
    specialContractor && (deps = await getDepartments(user?.companyId));
    options.departments = deps;
    options.managers = managers;
    options.regions = regionList;
    setOptions(options);

    let regions = [];
    if (data) {
      for (let prop in data.responsibilityRegions) {
        regions.push(data.responsibilityRegions[prop].id);
      }
    }

    const props = {
      trouserSize: data.trouserSize || null,
      tshirtSize: data.tshirtSize || null,
      vestSize: data.vestSize || null,
      shoeSize: data.shoeSize || null,
      jacketSize: data.jacketSize || null,
      enabled: data.enabled ? data.enabled : false,
      isActive: data.active ? data.active : null,
      isAppUser: data.isAppUser ? data.isAppUser : false,
      title: data.title ? data.title : null,
      userSGKNo: data.userSGKNo ? data.userSGKNo : null,
      email: data.email ? data.email : null,
      fName: data.fName ? data.fName : null,
      lName: data.lName ? data.lName : null,
      responsibilityRegions: regions,
      roles: data.roles
        ? formatRoles(data.roles)
        : specialContractor
        ? ["ROLE_YUKLENICI_PERSONEL"]
        : [],
      companyId: data.company
        ? data?.company?.id
        : specialContractor
        ? user.company?.id
        : null,
      companyType: data.company ? data?.company?.eCompanyType : null,
      departmentId: data.department ? data?.department?.id : null,
      managerId: data.manager ? data?.manager?.id : null,
      id: data.id ? data.id : null,
      didGetISGTraining: data.didGetISGTraining ? data.didGetISGTraining : null,
      ekatRequired: data.ekatRequired ? data.ekatRequired : null,
      tcNo: data.tcNo ? data.tcNo : null,
      birthDate: data.birthDate
        ? moment(data.birthDate).toISOString(true)
        : null,
      phoneNo: data.phoneNo ? data.phoneNo : null,
      dailyHourOfWork: data.dailyHourofWork ? data.dailyHourofWork : null,
      educationStatusId: data.educationStatus
        ? data?.educationStatus?.id
        : null,
      maritalStatusId: data.maritalStatus ? data?.maritalStatus?.id : null,
      genderId: data.gender ? data?.gender?.id : null,
      startDateOfWork: data.startDateOfWork
        ? moment(data.startDateOfWork).toISOString(true)
        : null,
      nextPeriodicExaminationDate: data.nextPeriodicExaminationDate || null,
    };
    setInitialDatas({
      email: props.email,
      tcNo: props.tcNo,
      phoneNo: props.phoneNo,
      userSGKNo: props.userSGKNo,
    });
    setUserProps(props);
    setLoading(false);
  }, [id, getDetails, user, specialContractor, getSgkLogs]);
  React.useEffect(() => {
    init();
  }, [init]);

  const updateUser = async () => {
    setRequestLoading(true);
    setGeneralError(false);
    let formData = new FormData();
    let resp;
    if (changeMadeToProps) {
      resp = await Request("patch", "/api/users", userProps);
    }
    if (changeMadeToFiles) {
      await populateFormData(formData);
      resp = await Request("patch", "/api/users/add-files", formData);
    }
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      null,
      true
    );
    setRequestLoading(false);
  };

  const populateFormData = async (formData) => {
    for (let key in files) {
      for (let file of files[key]) {
        formData.append(key, file);
      }
    }
    let props = { ...fileProps };
    for (let key in props) {
      let file = props[key];
      for (let prop in file) {
        delete file[prop].fileName;
      }
    }
    formData.append(
      "fileAttributes",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    return formData;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!userProps.fName) {
      setErrors({ ...errors, nameError: errorMessage });
      setValue(0);
      error = true;
    }
    if (!userProps.lName) {
      setErrors({ ...errors, surnameError: errorMessage });
      setValue(0);
      error = true;
    }
    if (!userProps.tcNo) {
      setErrors({ ...errors, tcError: errorMessage });
      setValue(0);
      error = true;
    }
    if (!userProps.email) {
      setErrors({ ...errors, emailError: errorMessage });
      setValue(0);
      error = true;
    }
    if (!userProps.companyId) {
      setErrors({ ...errors, companyError: errorMessage });
      setValue(1);
      error = true;
    }
    if (!userProps.departmentId) {
      setErrors({ ...errors, departmentError: errorMessage });
      setValue(1);
      error = true;
    }
    if (userProps.roles.length === 0) {
      setErrors({ ...errors, roleError: errorMessage });
      setValue(1);
      error = true;
    }
    let fileErrors = checkForFileErrors(errorMessage);
    fileErrors && (error = true);

    if (!error) {
      updateUser();
    } else {
      setGeneralError(true);
    }
  };

  const checkForFileErrors = (errorMessage) => {
    let error = false;
    if (files.ekatFile && files?.ekatFile.length > 0) {
      let localError = false;
      let ekatErrors = [...errors.ekatErrors];
      for (let i = 0; i < fileProps.ekatRequestList.length; i++) {
        const prop = fileProps.ekatRequestList[i];
        ekatErrors[i] = {
          ekatTakenDate: null,
          ekatPeriodOfValidity: null,
          ekatValidityDate: null,
        };
        ekatErrors[i].ekatTakenDate = prop.ekatTakenDate ? null : errorMessage;
        ekatErrors[i].ekatPeriodOfValidity = prop.ekatPeriodOfValidity
          ? null
          : errorMessage;
        ekatErrors[i].ekatValidityDate = prop.ekatValidityDate
          ? null
          : errorMessage;
        if (
          !prop.ekatTakenDate ||
          !prop.ekatPeriodOfValidity ||
          !prop.ekatValidityDate
        ) {
          error = true;
          localError = true;
        }
      }
      localError && setErrors({ ...errors, ekatErrors: ekatErrors });
      localError && setValue(2);
    }
    if (
      files.firstAidCertificateFile &&
      files?.firstAidCertificateFile.length > 0
    ) {
      let localError = false;
      let firstAidErrors = [...errors.firstAidErrors];
      for (
        let i = 0;
        i < fileProps.firstAidCertificateRequestList.length;
        i++
      ) {
        const prop = fileProps.firstAidCertificateRequestList[i];
        firstAidErrors[i] = {
          firstAidCertificateTakenDate: null,
          firstAidCertificatePeriodOfValidity: null,
          firstAidCertificateValidityDate: null,
        };
        firstAidErrors[i].firstAidCertificateTakenDate =
          prop.firstAidCertificateTakenDate ? null : errorMessage;
        firstAidErrors[i].firstAidCertificatePeriodOfValidity =
          prop.firstAidCertificatePeriodOfValidity ? null : errorMessage;
        firstAidErrors[i].firstAidCertificateValidityDate =
          prop.firstAidCertificateValidityDate ? null : errorMessage;
        if (
          !prop.firstAidCertificateTakenDate ||
          !prop.firstAidCertificatePeriodOfValidity ||
          !prop.firstAidCertificateValidityDate
        ) {
          error = true;
          localError = true;
        }
      }
      localError && setErrors({ ...errors, firstAidErrors: firstAidErrors });
      localError && setValue(2);
    }
    if (
      files.isgGeneralEducationCertificates &&
      files?.isgGeneralEducationCertificates.length > 0
    ) {
      let localError = false;
      let isgErrors = [...errors.isgErrors];
      for (
        let i = 0;
        i < fileProps.isgGeneralEducationCertificateRequestList.length;
        i++
      ) {
        const prop = fileProps.isgGeneralEducationCertificateRequestList[i];
        isgErrors[i] = {
          isgTakenDate: null,
          isgPeriodOfValidity: null,
          isgValidityDate: null,
        };
        isgErrors[i].isgTakenDate = prop.isgGeneralEducationCertificateTakenDate
          ? null
          : errorMessage;
        isgErrors[i].isgPeriodOfValidity =
          prop.isgGeneralEducationCertificatePeriodOfValidity
            ? null
            : errorMessage;
        isgErrors[i].isgValidityDate =
          prop.isgGeneralEducationCertificateValidityDate ? null : errorMessage;
        if (
          !prop.isgGeneralEducationCertificateTakenDate ||
          !prop.isgGeneralEducationCertificatePeriodOfValidity ||
          !prop.isgGeneralEducationCertificateValidityDate
        ) {
          error = true;
          localError = true;
        }
      }
      localError && setErrors({ ...errors, isgErrors: isgErrors });
      localError && setValue(2);
    }
    if (files.healthReport && files?.healthReport.length > 0) {
      let localError = false;
      let healthReportErrors = [...errors.healthReportErrors];
      for (let i = 0; i < fileProps.healthReportRequestList.length; i++) {
        const prop = fileProps.healthReportRequestList[i];
        healthReportErrors[i] = {
          healthReportDateTaken: null,
          healthReportDescription: null,
          doctorsName: null,
        };
        healthReportErrors[i].healthReportDateTaken = prop.healthReportDateTaken
          ? null
          : errorMessage;
        healthReportErrors[i].healthReportDescription =
          prop.healthReportDescription ? null : errorMessage;
        healthReportErrors[i].doctorsName = prop.doctorsName
          ? null
          : errorMessage;
        if (
          !prop.healthReportDateTaken ||
          !prop.healthReportDescription ||
          !prop.doctorsName
        ) {
          error = true;
          localError = true;
        }
      }
      localError &&
        setErrors({ ...errors, healthReportErrors: healthReportErrors });
      localError && setValue(2);
    }
    if (files.operatorCertificates && files?.operatorCertificates.length > 0) {
      let localError = false;
      let operatorCertificateErrors = [...errors.operatorCertificateErrors];
      for (
        let i = 0;
        i < fileProps.operatorCertificateRequestList.length;
        i++
      ) {
        const prop = fileProps.operatorCertificateRequestList[i];
        operatorCertificateErrors[i] = {
          operatorCertificateType: null,
          operatorCertificateDateTaken: null,
          operatorCertificatePeriodOfValidity: null,
          operatorCertificateValidityDate: null,
        };
        operatorCertificateErrors[i].operatorCertificateType =
          prop.operatorCertificateType ? null : errorMessage;
        operatorCertificateErrors[i].operatorCertificateDateTaken =
          prop.operatorCertificateDateTaken ? null : errorMessage;
        operatorCertificateErrors[i].operatorCertificatePeriodOfValidity =
          prop.operatorCertificatePeriodOfValidity ? null : errorMessage;
        operatorCertificateErrors[i].operatorCertificateValidityDate =
          prop.operatorCertificateValidityDate ? null : errorMessage;
        if (
          !prop.operatorCertificateType ||
          !prop.operatorCertificateDateTaken ||
          !prop.operatorCertificatePeriodOfValidity ||
          !prop.operatorCertificateValidityDate
        ) {
          error = true;
          localError = true;
        }
      }
      localError &&
        setErrors({
          ...errors,
          operatorCertificateErrors: operatorCertificateErrors,
        });
      localError && setValue(2);
    }
    if (files.driversLicenses && files?.driversLicenses.length > 0) {
      let localError = false;
      let driversLicenceErrors = [...errors.driversLicenceErrors];
      for (let i = 0; i < fileProps.driversLicenceRequestsList.length; i++) {
        const prop = fileProps.driversLicenceRequestsList[i];
        driversLicenceErrors[i] = {
          driversLicenceType: null,
          driversLicencedDteTaken: null,
          driversLicenceValidityDate: null,
          driversLicencePeriodOfValidity: null,
        };
        driversLicenceErrors[i].driversLicenceType = prop.driversLicenceType
          ? null
          : errorMessage;
        driversLicenceErrors[i].driversLicencedDteTaken =
          prop.driversLicencedDteTaken ? null : errorMessage;
        driversLicenceErrors[i].driversLicenceValidityDate =
          prop.driversLicenceValidityDate ? null : errorMessage;
        driversLicenceErrors[i].driversLicencePeriodOfValidity =
          prop.driversLicencePeriodOfValidity ? null : errorMessage;
        if (
          !prop.driversLicenceType ||
          !prop.driversLicencedDteTaken ||
          !prop.driversLicenceValidityDate ||
          !prop.driversLicencePeriodOfValidity
        ) {
          error = true;
          localError = true;
        }
      }
      localError &&
        setErrors({ ...errors, driversLicenceErrors: driversLicenceErrors });
      localError && setValue(2);
    }
    return error;
  };

  const RenderLoading = () => {
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1500);
    return (
      <>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <p style={{ color: "red", fontSize: 18 }}>Veri Yok</p>
        )}
      </>
    );
  };

  const RenderError = () => {
    if (data.status === 404) {
      return <p style={{ fontSize: 40, color: "red" }}>KULLANICI BULUNAMADI</p>;
    } else if (data.status === 400) {
      return (
        <p style={{ fontSize: 40, color: "red" }}>
          KULLANICIYA ERİŞİM YETKİNİZ YOKTUR
        </p>
      );
    } else {
      return (
        <>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <p style={{ color: "red", fontSize: 18 }}>Veri Yok</p>
          )}
        </>
      );
    }
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <SlideDialog
        open={generalError}
        handleClose={() => setGeneralError(false)}
      />
      <ResponsiveContainer>
        {data ? (
          <>
            {data.status === 200 ? (
              <div className={classes.details}>
                <ThemeProvider theme={darkTheme}>
                  <AppBar position="static">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      <Tab label="KİŞİSEL BİLGİLER" {...a11yProps(0)} />
                      <Tab label="İŞ BİLGİLERİ" {...a11yProps(1)} />
                      <Tab label="KULLANICI DOSYALARI" {...a11yProps(2)} />
                      <Tab label="EĞİTİM" {...a11yProps(3)} />
                      <Tab
                        label="Kişisel Koruyucu Donanımlar"
                        {...a11yProps(4)}
                      />
                      <Tab label="SGK KAYITLARI" {...a11yProps(5)} />
                      <Tab label="ISG Kartı" {...a11yProps(6)} />
                      <Tab
                        label={language.healthReport.tableName}
                        {...a11yProps(7)}
                      />
                      <Tab
                        label={language.workAccidents.tableName}
                        {...a11yProps(8)}
                      />
                      <Tab
                        label={language.nearMiss.tableName}
                        {...a11yProps(9)}
                      />
                      <Tab
                        label={language.audits.tableName}
                        {...a11yProps(10)}
                      />
                    </Tabs>
                  </AppBar>
                  <TabPanel className={classes.panel} value={value} index={0}>
                    {Object.keys(userProps).length > 0 ? (
                      <PersonalDataForm
                        userProps={userProps}
                        setUserProps={setUserProps}
                        errors={errors}
                        setErrors={setErrors}
                        options={options}
                        files={files}
                        setOptions={setOptions}
                        setChangeMadeToProps={setChangeMadeToProps}
                        initialDatas={initialDatas}
                      />
                    ) : (
                      <RenderLoading />
                    )}
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={1}>
                    {Object.keys(userProps).length > 0 ? (
                      <WorkDataForm
                        userProps={userProps}
                        setUserProps={setUserProps}
                        errors={errors}
                        setErrors={setErrors}
                        options={options}
                        setOptions={setOptions}
                        setChangeMadeToProps={setChangeMadeToProps}
                      />
                    ) : (
                      <RenderLoading />
                    )}
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={2}>
                    <UserFiles
                      requestMade={fileRequestMade}
                      setRequestMade={setFileRequestMade}
                      userProps={userProps}
                      files={files}
                      setFiles={setFiles}
                      fileProps={fileProps}
                      setFileProps={setFileProps}
                      errors={errors}
                      setErrors={setErrors}
                      options={options}
                      setChangeMadeToFiles={setChangeMadeToFiles}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={3}>
                    <Trainings
                      userProps={userProps}
                      options={options}
                      errors={errors}
                      setErrors={setErrors}
                      setSnackbar={setSnackbar}
                      setSnackbarMessage={setSnackbarMessage}
                      setSeverity={setSeverity}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={4}>
                    <KKD
                      userProps={userProps}
                      setUserProps={setUserProps}
                      setChangeMade={setChangeMadeToProps}
                      options={options}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={5}>
                    {Object.keys(sgkLogs).length > 0 ? (
                      <SGKLogs logs={sgkLogs} />
                    ) : (
                      <RenderLoading />
                    )}
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={6}>
                    <IsgCard
                      id={id}
                      userProps={data.data}
                      setSnackbar={setSnackbar}
                      setSnackbarMessage={setSnackbarMessage}
                      setSeverity={setSeverity}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={7}>
                    <HealthInspections
                      id={id}
                      userProps={userProps}
                      setSnackbar={setSnackbar}
                      setSnackbarMessage={setSnackbarMessage}
                      setSeverity={setSeverity}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={8}>
                    <Accidents
                      id={id}
                      setSnackbar={setSnackbar}
                      setSnackbarMessage={setSnackbarMessage}
                      setSeverity={setSeverity}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={9}>
                    <NearMisses
                      id={id}
                      setSnackbar={setSnackbar}
                      setSnackbarMessage={setSnackbarMessage}
                      setSeverity={setSeverity}
                    />
                  </TabPanel>
                  <TabPanel className={classes.panel} value={value} index={10}>
                    <Inspections
                      id={id}
                      setSnackbar={setSnackbar}
                      setSnackbarMessage={setSnackbarMessage}
                      setSeverity={setSeverity}
                    />
                  </TabPanel>
                  {(value === 0 ||
                    value === 1 ||
                    value === 2 ||
                    value === 4) && (
                    <div style={{ textAlign: "center" }}>
                      {requestLoading ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={!(changeMadeToFiles || changeMadeToProps)}
                          onClick={() => onButtonClick()}
                          className={classes.submit}
                        >
                          {"GÜNCELLE"}
                        </Button>
                      )}
                    </div>
                  )}
                </ThemeProvider>
              </div>
            ) : (
              <RenderError />
            )}
          </>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </ResponsiveContainer>
    </>
  );
}

export default React.memo(UserDetails);
