import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import ModuleModal from "./ModuleModal";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import FunctionTable from "../functions/FunctionTable";

export default function ModuleTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedModule, setSelectedModule] = React.useState("");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: language.tableColumns.name,
        field: "name",
      },
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = (data) => {
    setSelectedModule(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedModule(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedModule(null);
    setDialog(false);
  };

  const removeModule = async () => {
    setRequestLoading(true);
    let params = {
      "module-id": selectedModule?.id,
    };
    const resp = await Request(
      "delete",
      "/api/authority/module/",
      null,
      params
    );

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeModule}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <ModuleModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        moduleProps={selectedModule}
      />
      <Table
        tableName={language.sidebar.modules}
        authName="moduleTable"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={() => true}
        isEditable={() => true}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={(rowData) => (
          <div
            style={{
              backgroundColor: "#2D3446",
              color: "white",
              textAlign: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <FunctionTable
              module={{
                moduleId: rowData.id,
                moduleName: rowData.name,
              }}
            />
          </div>
        )}
      />
    </>
  );
}
