import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./PersonnelDressSizeFilters";
import LanguageHelper from "../../helpers/LanguageHelper";
import GetOptions from "./GetOptions";
import { size } from "../../assets/constants";

export default function PersonnelDressSizeTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [options, setOptions] = React.useState({});

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: null,
    department: null,
    name: null,
    surname: null,
    shoe: null,
    vest: null,
    jacket: null,
    trouser: null,
    shirt: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.personnelDressSize.no, field: "id" },
      { title: language.personnelDressSize.name, field: "name" },
      { title: language.personnelDressSize.surname, field: "surname" },
      { title: language.personnelDressSize.title, field: "title" },
      { title: language.personnelDressSize.company, field: "companyName" },
      {
        title: language.personnelDressSize.department,
        field: "departmentName",
      },
      {
        title: language.personnelDressSize.shoe,
        field: "shoeSize",
      },
      {
        title: language.personnelDressSize.jacket,
        field: "jacketSize",
        lookup: size,
      },
      {
        title: language.personnelDressSize.vest,
        field: "vestSize",
        lookup: size,
      },
      {
        title: language.personnelDressSize.shirt,
        field: "shirtSize",
        lookup: size,
      },
      {
        title: language.personnelDressSize.trouser,
        field: "trouserSize",
        lookup: size,
      },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: null,
      department: null,
      name: null,
      surname: null,
      shoe: null,
      vest: null,
      jacket: null,
      trouser: null,
      shirt: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noRowActions
        tableName={language.personnelDressSize.personnelDressSizeReportTable}
        authName="personnelDressSize"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
