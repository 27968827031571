import React from "react";
import SessionHelper from "../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import { useLocation } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import Records from "./consumptionRecords/Records";
import Catalogues from "./consumptionCatalogues/Catalogues";

import { tableContainerStyles } from "../../assets/styles/tableContainer";

export default function ConsumptionManagementPage() {
  const location = useLocation();
  const roles = SessionHelper.getUser().roles;
  const consumptionRecordsAuth = getAuthorizationForPage(
    roles,
    "consumptionManagementRecords"
  );
  const consumptionCataloguesAuth = getAuthorizationForPage(
    roles,
    "consumptionManagementCatalogues"
  );
  const page = location.pathname.substring(24);

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {(page === "records" &&
          (consumptionRecordsAuth?.view ? (
            <Records />
          ) : (
            <UnauthorizedPage />
          ))) ||
          (page === "catalogues" &&
            (consumptionCataloguesAuth?.view ? (
              <Catalogues />
            ) : (
              <UnauthorizedPage />
            )))}
      </Grid>
    </Container>
  );
}
