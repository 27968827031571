import React from "react";
import FormTable from "./components/FormTable";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import SessionHelper from "../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import UnauthorizedPage from "../UnauthorizedPage";

export default function FormPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "tasksTable");
  return (
    <Container maxWidth={false} style={tableContainerStyles}>
      <Grid>{authorization?.view ? <FormTable /> : <UnauthorizedPage />}</Grid>
    </Container>
  );
}
