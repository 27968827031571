import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      "page-size": query.pageSize,
      sort: "id,desc",

      //FILTERS
      consumptionTypeFilter: filterProps.consumptionTypeFilter,
      consumptionUnitFilter: filterProps.consumptionUnitFilter,
      yearFilter: filterProps.yearFilter,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "company.name," + query.orderDirection;
      } else if (query.orderBy.field === "departmentName") {
        params.sort = "department.name," + query.orderDirection;
      } else if (query.orderBy.field === "cityName") {
        params.sort = "city.name," + query.orderDirection;
      } else if (query.orderBy.field === "townName") {
        params.sort = "town.name," + query.orderDirection;
      } else if (query.orderBy.field === "trainingType") {
        params.sort = "trainingType," + query.orderDirection;
      } else if (query.orderBy.field === "topics") {
        params.sort = "topics," + query.orderDirection;
      } else if (query.orderBy.field === "plannedDate") {
        params.sort = "plannedDate," + query.orderDirection;
      } else if (query.orderBy.field === "completionDate") {
        params.sort = "completionDate," + query.orderDirection;
      } else if (query.orderBy.field === "time") {
        params.sort = "time," + query.orderDirection;
      } else if (query.orderBy.field === "instructorName") {
        params.sort =
          "instructor.fName,instructor.lName," + query.orderDirection;
      } else if (query.orderBy.field === "status") {
        params.sort = "status," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/consumption-management/catalogs",
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let otherContent = data.content;
      let content = otherContent.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const props = {
          id: content[i]?.id,
          company: content[i]?.company?.id,
          companyName: content[i]?.company?.name,
          consumptionName: content[i]?.consumptionName,
          consumptionTypeId: content[i]?.consumptionType,
          consumptionUnitId: content[i]?.consumptionUnit?.id,
          year: content[i]?.year,
        };
        temp.push(props);
      }
      setNumOfEntries(otherContent.totalElements);
      resolve({
        data: temp,
        page: otherContent.pageable.pageNumber,
        totalCount: otherContent.totalElements,
      });
    }
  });
  return data;
}
