import React from "react";
import HeavyEquipmentTable from './HeavyEquipmentTable';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from '../../../assets/styles/tableContainer';
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import UnauthorizedPage from "../../UnauthorizedPage";

export default function HeavyEquipmentPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "heavyEquipmentTable");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <HeavyEquipmentTable /> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}
