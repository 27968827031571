import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { GetOptions } from "./GetOptions";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function VisitorsModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  visitorProps,
  companyId,
  // loading,
  // setLoading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestVisitorProps, setRequestVisitorProps] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setRequestVisitorProps({
      email: visitorProps ? visitorProps.email : null,
      name: visitorProps ? visitorProps.name : null,
      surname: visitorProps ? visitorProps.surname : null,
      phoneNo: visitorProps ? visitorProps.phoneNo : null,
      companyName: visitorProps ? visitorProps.companyName : null,
      companyId: visitorProps
        ? visitorProps.companyId
        : companyId
        ? companyId
        : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitorProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestVisitorProps({
      email: null,
      name: null,
      surname: null,
      phoneNo: null,
      companyName: null,
      companyId: null,
    });
  };

  const submitNewVisitor = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/vm/visitor/create",
      requestVisitorProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateVisitor = async () => {
    setLoading(true);
    let props = { ...requestVisitorProps };
    props.id = visitorProps.id;
    const resp = await Request(
      "put",
      "/api/vm/visitor/update/" + props.id,
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestVisitorProps={requestVisitorProps}
              visitorProps={visitorProps}
              setRequestVisitorProps={setRequestVisitorProps}
              submitNewVisitor={submitNewVisitor}
              updateVisitor={updateVisitor}
              loading={loading}
              options={options}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
