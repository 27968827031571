import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SessionHelper from "../../helpers/SessionHelper";
import Copyright from "../../components/Footer/Copyright";
import moment from "moment";
import SaveAlt from "@material-ui/icons/SaveAlt";
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import "moment/locale/tr";
import Request from "../../helpers/Request";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import "moment/locale/tr";
import FileSaver from "file-saver";
import TableBackdrop from "../../components/Table/TableBackdrop";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import LanguageHelper from "../../helpers/LanguageHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";

const fixedHeight = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  subtitle: {
    margin: "0 auto",
    color: "#C3C3C3",
  },
}));

export default function KKDReport() {
  const collator = new Intl.Collator("tr");
  moment.locale("tr");
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "kkdReport");
  const roleCheck =
    roles.includes("ROLE_YUKLENICI_YÖNETİCİ") ||
    roles.includes("ROLE_YUKLENICI_ISG_UZMANI");

  const [departments, setDepartments] = React.useState([]);
  const [department, setDepartment] = React.useState(-1);
  const [companies, setCompanies] = React.useState([]);
  const [company, setCompany] = React.useState(-1);
  const [regions, setRegions] = React.useState([]);
  const [region, setRegion] = React.useState(-1);
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  const getReport = async () => {
    setBackdropLoading(true);
    const resp = await Request("get", "/api/report/" + region + "/" + company + "/" + department + "/base64/kkdReport");
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
      FileSaver.saveAs(
        source,
        "Kişisel Koruyucu Donanımlar Raporları.xlsx"
      );
    }
    setBackdropLoading(false);
    return;
  };

  const getDepartments = useCallback(async (regionId, compId) => {
    const reg = (regionId && regionId) || region || -1;
    const comp = (compId && compId) || company || -1;
    let resp;
    if(roleCheck) {
      resp = await Request("get", "/api/departments/" + user.id + "/get-according-to-user");
    } else {
      resp = await Request("get", "/api/departments/belongs-to-company-and-region/" 
        + (comp || -1) + "/"
        + (reg || -1));
    }
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
    return arr;
  }, [roleCheck, user, company, region]);

  const getRegions = useCallback(async (compId) => {
    const comp = (compId && compId) || company || -1;
    const resp = await Request("get", "/api/cities-towns/regions-of-company/" + comp);
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let reg = {};
      reg["cityName"] = data[i].name;
      reg["name"] = data[i].name;
      reg["id"] = data[i].id;
      arr.push(reg);
    }
    return arr;
  }, [company]);

  const getCompanies = useCallback(async (regionId) => {
    const reg = (regionId && regionId) || region || -1;
    const resp = await Request("get", "/api/companies/list/" + reg);
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let comp = {};
      comp["name"] = data[i].name;
      comp["id"] = data[i].id;
      arr.push(comp);
    }
    return arr;
  }, [region]);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const [departments, regions, companies] = await Promise.all([
      getDepartments(), getRegions(), getCompanies()
    ])
    setDepartments(departments);
    setRegions(regions);
    setCompanies(companies);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDepartments, getRegions, getCompanies, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const filterOptions = createFilterOptions({
    stringify: ({ name }) => `${name}`,
  });

  const RenderRegionAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={regions
          ?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })
          ?.sort(function (a, b) {
            return collator.compare(a.cityName, b.cityName);
          })}
        getOptionLabel={(option) => option.name}
        filterOptions={filterOptions}
        fullWidth={true}
        disabled={roleCheck}
        defaultValue={region ? getFromId(region, regions) : null}
        onChange={async (event, value) => {
          const reg = value ? value.id : -1;
          setRegion(reg);
          const [companies, departments] = await Promise.all([
            getCompanies(reg), getDepartments(reg)
          ])
          setCompanies(companies);
          setDepartments(departments);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Bölge" className={classes.textField} />
        )}
      />
    );
  };

  const RenderCompanyAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={companies?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        filterOptions={filterOptions}
        fullWidth={true}
        defaultValue={company ? getFromId(company, companies) : null}
        onChange={async (event, value) => {
          const comp = value ? value.id : -1;
          setCompany(comp);
          const [regions, departments] = await Promise.all([
            getRegions(comp), getDepartments(undefined, comp)
          ])
          setRegions(regions);
          setDepartments(departments);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Şirket" className={classes.textField} />
        )}
      />
    );
  };

  const RenderDepartmentAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        filterOptions={filterOptions}
        fullWidth={true}
        disabled={!roleCheck && (!(company && company > -1) && !(region && region > -1))}
        defaultValue={department ? getFromId(department, departments) : null}
        onChange={async (event, value) => {
          const dept = value ? value.id : -1;
          setDepartment(dept);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Bölüm" className={classes.textField} />
        )}
      />
    );
  };

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      {authorization?.view ? (
        <>
          <h2>Kişisel Koruyucu Donanım Raporları</h2>
          <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3} justifyContent="space-between">
                  <Grid item xs={department && department > -1 ? 10 : 12}>
                    <Paper
                      className={classes.paper}
                      style={{ height: 100, marginTop: 20 }}
                    >
                      <Grid container justifyContent="center">
                        <Grid item xs={4}>
                          {!roleCheck &&
                          <RenderCompanyAutocomplete />}
                        </Grid>
                        <Grid item xs={4}>
                          {!roleCheck &&
                          <RenderRegionAutocomplete />}
                        </Grid>
                        <Grid item xs={roleCheck ? 12 : 4}>
                          <RenderDepartmentAutocomplete />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  {/* EXPORT BUTTON */}
                  {department && department > -1 && (
                    <Grid item>
                      <Paper
                        className={classes.paper}
                        style={{ height: 100, width: 100, marginTop: 20 }}
                      >
                        <div style={{ margin: "auto", marginLeft: "32%" }}>
                          <IconTooltipButton
                            title="Raporu İndir"
                            onClick={getReport}
                          >
                            <SaveAlt />
                          </IconTooltipButton>
                        </div>
                      </Paper>
                    </Grid>
                  )}
                </Grid>{" "}
                <Box pt={4} style={{ marginTop: "40%" }}>
                  <Copyright
                    string={"DELTA Smart Technologies"}
                    url={"https://deltasmart.tech/en/home/"}
                    date={new Date().getFullYear()}
                  />
                </Box>
              </Container>
            </main>
          </div>
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
}