import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import { getDepartments } from "./GetOptions";
import { taskStatuses } from "../../../assets/constants";
import jsonMap from "../../../components/Utils/jsonMap";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj, type: string} param main parameters for the filters
 */
export default function GetFilterFields({
  type,
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  let statuses = {...taskStatuses};
  statuses.DONE = "Onay Bekliyor";

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />

      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
          <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Termin Tarihi Başlangıç" 
                value={tableFilters.filterTerminationStart ? moment(tableFilters.filterTerminationStart) : null} 
                shrink={tableFilters.filterTerminationStart} 
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    filterTerminationStart: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                    })
                  }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Termin Tarihi Bitiş"
                value={tableFilters.filterTerminationEnd ? moment(tableFilters.filterTerminationEnd) : null}
                shrink={tableFilters.filterTerminationEnd}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    filterTerminationEnd: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Onaylanma Tarihi Başlangıç"
                value={tableFilters.filterApprovedStart ? moment(tableFilters.filterApprovedStart) : null}
                shrink={tableFilters.filterApprovedStart}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    filterApprovedStart: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Onaylanma Tarihi Bitiş"
                value={tableFilters.filterApprovedEnd ? moment(tableFilters.filterApprovedEnd) : null}
                shrink={tableFilters.filterApprovedEnd}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    filterApprovedEnd: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            {type !== "personal" && (
              <Grid item xs={6}>
                <TextArea
                  shrink={tableFilters.assignedBy !== null}
                  label={"Atayan Kişi"}
                  value={tableFilters.assignedBy}
                  onChangeFunc={(value) =>
                    setTableFilters({
                      ...tableFilters,
                      assignedBy: value.target.value,
                    })
                  }
                  style={classes.textField}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.assignedTo !== null}
                label={"Atanan Kişi"}
                value={tableFilters.assignedTo}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    assignedTo: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            {type === "all" && (
              <Grid item xs={6}>
                <Autocomplete
                  id="combo-box"
                  options={options.companies?.sort(function (a, b) {
                    return collator.compare(a.name, b.name);
                  })}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  fullWidth={true}
                  value={tableFilters.company}
                  onChange={async (event, value) => {
                    let newOpts = options;
                    newOpts.departments = value?.id ? await getDepartments(value?.id) : [];
                    setOptions(newOpts);
                    setTableFilters({ ...tableFilters, company: value });
                    if(!value) {
                      setTableFilters({ ...tableFilters, company: value, department: value });
                    }
                  }}
                  renderOption={(option) => <span>{option.name}</span>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Şirket"
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>
            )}
            {type === "all" && (
              <Grid item xs={6}>
                <Autocomplete
                  id="combo-box"
                  options={options.departments?.sort(function (a, b) {
                    return collator.compare(a.name, b.name);
                  })}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  fullWidth={true}
                  disabled={!tableFilters.company}
                  value={tableFilters.department}
                  onChange={(event, value) =>
                    setTableFilters({ ...tableFilters, department: value })
                  }
                  renderOption={(option) => <span>{option.name}</span>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Bölüm"
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <TextSelector
                label="Durumu"
                value={tableFilters.status}
                shrink={tableFilters.status !== null}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    status: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, statuses)}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
