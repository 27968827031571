import React from 'react';
import MedicalExamTable from './components/MedicalExamTable';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { tableContainerStyles } from '../../../assets/styles/tableContainer';
import UnauthorizedPage from '../../UnauthorizedPage';
import SessionHelper from '../../../helpers/SessionHelper';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';

export default function MedicalExamPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "healthMonitoring");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <MedicalExamTable /> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}