import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import ModalForm from './ModalForm';
import showSnackbar from "../../../components/Utils/showSnackbar";

export default function EPDKModal({modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, tableRef, oldAccidentProps, opts}) {
  const language = LanguageHelper.getLanguage();
  
  const [newAccidentProps, setNewAccidentProps] = React.useState({});
  const [options, setOptions] = React.useState(opts);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setOptions(opts);
    setNewAccidentProps({
      sn: oldAccidentProps ? oldAccidentProps.sn : null,
      personnelName: oldAccidentProps ? oldAccidentProps.personnelName : null,
      personnelSgkNo: oldAccidentProps ? oldAccidentProps.personnelSGKNo : null,
      companySgkNo: oldAccidentProps ? oldAccidentProps.companySgkNo : null,
      accidentDate: oldAccidentProps ? oldAccidentProps.accidentDate : null,
      incapacitationStart: oldAccidentProps ? oldAccidentProps.incapacitationStart : null,
      incapacitationEnd: oldAccidentProps ? oldAccidentProps.incapacitationEnd : null,
      accidentTypeEPDKId: oldAccidentProps && oldAccidentProps.accidentTypeId ? oldAccidentProps.accidentTypeId : null,
      lostLimb: oldAccidentProps ? oldAccidentProps.lostLimb : null,
      lostTimeInDays: oldAccidentProps ? oldAccidentProps.lostDays : null,
      accidentReasonEPDKId: oldAccidentProps && oldAccidentProps.accidentCauseId ? oldAccidentProps.accidentCauseId : null,
      year: oldAccidentProps && oldAccidentProps.year ? oldAccidentProps.year : null,
    });
  }, [setOptions, opts, oldAccidentProps]);
  React.useEffect(() => {
    init();
  }, [init, modal]);

  /**
   * Closes the modal and flushes the states.
   */
   const handleCloseModal = () => {
    setModal(false);
    setNewAccidentProps({
      personnelName: null,
      personnelSgkNo: null,
      accidentDate: null,
      companySgkNo: null,
      incapacitationStart: null,
      incapacitationEnd: null,
      accidentTypeEPDKId: null,
      lostLimb: null,
      lostTimeInDays: null,
      accidentReasonEPDKId: null,
      year: null,
    });
  }

  /**
    * Request to submit a new accident with the data given.
    */
   const submitNewAccident = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/epdk/single-row", newAccidentProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  /**
    * Request to replace the old data with the new data.
    */
  const updateAccident = async () => {
    setLoading(true);
    const resp = await Request("put", "/api/epdk/single-row/" + oldAccidentProps.casualtyId, newAccidentProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}>
        <ModalForm 
          newAccidentProps={newAccidentProps} 
          setNewAccidentProps={setNewAccidentProps} 
          loading={loading}
          options={options}
          submitNewAccident={submitNewAccident}
          updateAccident={updateAccident}
          oldAccidentProps={oldAccidentProps}
        />
      </TableEntryModal>  
    </>
  );
}