import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import showSnackbar from "../../../components/Utils/showSnackbar";

export default function PersonnelNumberModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldAccidentProps,
  opts,
}) {
  const language = LanguageHelper.getLanguage();

  const [newAccidentProps, setNewAccidentProps] = React.useState({});
  const [options, setOptions] = React.useState(opts);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setOptions(opts);
    setNewAccidentProps({
      edcOrContractorName: oldAccidentProps
        ? oldAccidentProps.companyName
        : null,
      year: oldAccidentProps ? oldAccidentProps.year : null,
      edcName: oldAccidentProps ? oldAccidentProps.edcName : null,
      companySgkNo: oldAccidentProps ? oldAccidentProps.companySgkNo : null,
      t1: oldAccidentProps ? oldAccidentProps.t1 : null,
    });
  }, [setOptions, opts, oldAccidentProps]);
  React.useEffect(() => {
    init();
  }, [init, modal]);

  /**
   * Closes the modal and flushes the states.
   */
  const handleCloseModal = () => {
    setModal(false);
    setNewAccidentProps({
      edcOrContractorName: null,
      year: null,
      edcName: null,
      companySgkNo: null,
      t1: null,
    });
  };

  /**
   * Request to submit a new accident with the data given.
   */
  const submitNewAccident = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/epdk/user", newAccidentProps);
    console.log("post epdk user -> ", resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  /**
   * Request to replace the old data with the new data.
   */
  const updateAccident = async () => {
    setLoading(true);
    const resp = await Request(
      "patch",
      "/api/epdk/user/" + oldAccidentProps.id,
      newAccidentProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <TableEntryModal modal={modal} handleCloseModal={handleCloseModal}>
        <ModalForm
          newAccidentProps={newAccidentProps}
          setNewAccidentProps={setNewAccidentProps}
          loading={loading}
          options={options}
          submitNewAccident={submitNewAccident}
          updateAccident={updateAccident}
          oldAccidentProps={oldAccidentProps}
        />
      </TableEntryModal>
    </>
  );
}
