import React, { useCallback } from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../components/Table/TableFilters";
import { TextSelector, TextArea } from '../../components/Fields/TextFields';
import jsonMap from '../../components/Utils/jsonMap';
import { months } from '../../assets/constants';
import { getDepartments } from './GetOptions';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [departments, setDepartments] = React.useState([]);

  const init = useCallback(async () => {
    tableFilters.company && setDepartments(await getDepartments(tableFilters.company?.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters.company, tableFilters.region]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
          <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  setTableFilters({...tableFilters, company: value, department: null});
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                label="Yıl"
                value={tableFilters.year}
                shrink={tableFilters.year !== null}
                style={classes.textField}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    year: value.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Ay"
                value={tableFilters.month}
                shrink={tableFilters.month !== null}
                style={classes.textField}
                options={jsonMap(false, months)}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    month: value.target.value,
                  })
                }
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}