import Request from '../../helpers/Request';
import LanguageHelper from '../../helpers/LanguageHelper';
import moment from 'moment';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.id,
      department: filterProps.department?.id,
      educationStatus: filterProps.educationStatus,
      ekatStatus: filterProps.ekatStatus,
      endTime: filterProps.endTime,
      startTime: filterProps.startTime,
      name: filterProps.name,
      surname: filterProps.surname,
      dateTakenStart: filterProps.dateTakenStart,
      dateTakenEnd: filterProps.dateTakenEnd,
      validityStart: filterProps.validityStart,
      validityEnd: filterProps.validityEnd,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") { params.sort = "date," + query.orderDirection; }
      else if (query.orderBy.field === "level") { params.sort = "level," + query.orderDirection; }
      else if (query.orderBy.field === "message") { params.sort = "message," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/users/personnel-files/ekat-reports", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          id: content[i]?.id,
          tcNo: content[i]?.tcNo,
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          title: content[i]?.title,
          name: content[i]?.name,
          surname: content[i]?.surname,
          startDateOfWork: moment(content[i]?.startDateOfWork).format("DD-MM-YYYY"),
          educationStatus: content[i]?.educationStatus,
          ekatStatus: content[i]?.props?.ekatStatus,
          ekatTakenDate: content[i]?.props?.ekatTakenDate ? moment(content[i]?.props?.ekatTakenDate).format("DD-MM-YYYY") : null,
          ekatValidityDate: content[i]?.props?.ekatValidityDate ? moment(content[i]?.props?.ekatValidityDate).format("DD-MM-YYYY") : null,
          ekatPeriodOfValidity: content[i]?.props?.ekatPeriodOfValidity
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      })
    }
  })
  return data;
}