import React from "react";
import Request from "../../../../helpers/Request";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  Modal,
} from "@material-ui/core";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import { TextArea } from "../../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: 600,
  },
  textField: {
    marginBottom: "20px",
  },
  regionModal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function NewRegionModal({
  rowData,
  tableRef,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  regionModal,
  setRegionModal,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const [newRegionName, setNewRegionName] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const handleCloseRegionModal = () => {
    setRegionModal(false);
    setNewRegionName("");
  };

  const submitNewRegion = async () => {
    setLoading(true);
    const body = {
      name: newRegionName,
      belongingCityId: rowData.id,
    };
    const resp = await Request("post", "/api/cities-towns/region", body);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    setRegionModal(false);
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.regionModal}
        open={regionModal}
        onClose={handleCloseRegionModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={regionModal}>
          <div className={classes.paper}>
            <FormControl
              noValidate
              autoComplete="off"
              className={classes.formControl}
            >
              <TextArea
                required
                label={"Adı"}
                value={newRegionName}
                onChangeFunc={(value) => setNewRegionName(value.target.value)}
                style={classes.textField}
              />
              <div style={{textAlign: "center"}}>
                {loading ? 
                <CircularProgress color="secondary"/>
                :
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={() => submitNewRegion()}
                  className={classes.submit}
                >
                  {language.form.submit}
                </Button>}
              </div>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
