import React, { useCallback, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";
import MaterialTable from "@material-table/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import CatalogueModal from "./CatalogueModal";
import { forwardRef } from "react";
import showSnackbar from "../../../components/Utils/showSnackbar";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import { GetOptions } from "../GetOptions";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SyncAltIcon from "@material-ui/icons/SyncAlt";

export const getCatalogues = async (id) => {
  const resp = await Request("get", "/api/training/catalog");
  console.log(resp);
  return Array.isArray(resp.data.content) ? resp.data.content : [];
};

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Transfer: forwardRef((props, ref) => <SyncAltIcon {...props} ref={ref} />),
};

const columns = [
  { field: "id", title: "No" },
  { field: "companyName", title: "Şirket" },
  { field: "trainingType", title: "Eğitim Tipi" },
  { field: "name", title: "Adı" },
  { field: "time", title: "Süre (Saat)" },
  { field: "scope", title: "Kapsam" },
  { field: "targetGroup", title: "Hedef Kitle" },
];

export default function Catalogues() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const id = user?.company?.id;
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "trainingCatalogues");

  const [loading, setLoading] = React.useState(false);
  const [catalogueModal, setCatalogueModal] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [catalogues, setCatalogues] = React.useState(0);
  const [options, setOptions] = React.useState(null);

  const [pageSize, setPageSize] = React.useState(20);

  const init = useCallback(async () => {
    const [options, catalogues] = await Promise.all([
      GetOptions(true), getCatalogues(id)
    ]);
    setLoading(true);
    setOptions(options);
    let catalogueProps = [];

    for (let catalogue of catalogues) {
      const props = {
        id: catalogue?.id,
        company: catalogue?.company?.id,
        companyName: catalogue?.company?.name,
        trainingType: catalogue?.trainingType,
        name: catalogue?.name,
        time: catalogue?.time,
        scope: catalogue?.scope,
        targetGroup: catalogue?.targetGroup,
      };
      let tempFiles = [];
      for (let file of catalogue?.fileKeys) {
        const name = file.name ? file.name : file?.fileName;
        tempFiles.push(new File([name], name, { type: "text/plain" }));
      }
      props.fileKeys = tempFiles;
      catalogueProps.push(props);
    }
    setCatalogues(catalogueProps);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tableRef, snackbar]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setCatalogueModal(data);
  };

  const deleteCatalogue = async (data) => {
    setLoading(true);
    const resp = await Request("delete", "/api/training/catalog/" + data?.id);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    setLoading(false);
  };

  return (
    <>
      <TableBackdrop backdropLoading={loading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      {options && (
        <CatalogueModal
          catalogueProps={catalogueModal}
          setCatalogueModal={setCatalogueModal}
          options={options}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      )}
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (
        <MaterialTable
          tableRef={tableRef}
          title="Eğitim Katalogları"
          localization={language.tableLocalization}
          icons={tableIcons}
          columns={columns}
          isLoading={loading}
          data={catalogues ? [...catalogues] : []}
          onRowsPerPageChange={(pageSize) => setPageSize(pageSize)}
          // options={{
          //   minBodyHeight: 860,
          //   maxBodyHeight: 860,
          //   emptyRowsWhenPaging: false,
          //   //doubleHorizontalScroll: true,
          // }}
          options={{
            headerStyle: {
              position: 'sticky',
              top: 0,
              backgroundColor: '#D3D3D3',
            },
            maxBodyHeight: '650px',
            emptyRowsWhenPaging: false,
            doubleHorizontalScroll: true,
            pageSize: pageSize,
            pageSizeOptions: [20, 50, 100, catalogues.length]
              .filter((n) => n <= 200)
              .sort(function (a, b) {
                return a - b;
              }),

            /* headerStyle: { 
              position: 'sticky', 
              top: 120
            }, */
          }}
          editable={{
            isDeletable: (rowData) => authorization.delete,
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  deleteCatalogue(oldData);
                  resolve();
                }, 500);
              }),
          }}
          actions={[
            (rowData) =>
              authorization.edit && {
                icon: tableIcons.Edit,
                tooltip: language.tableLocalization.body.editTooltip,
                position: "row",
                onClick: (event, rowData) => handleOpenModal(rowData),
              },
            authorization.create && {
              icon: tableIcons.Add,
              tooltip: language.tableLocalization.body.addTooltip,
              isFreeAction: true,
              onClick: (event, rowData) => handleOpenModal(rowData),
            },
          ]}
        />
      )}
    </>
  );
}
