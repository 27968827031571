import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import SessionHelper from "../../helpers/SessionHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import PersonnelDressSizeTable from "./PersonnelDressSizeTable";

function PersonnelDressSizePage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "personnelDressSize");
  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? (
          <PersonnelDressSizeTable />
        ) : (
          <UnauthorizedPage />
        )}
      </Grid>
    </Container>
  );
}

export default PersonnelDressSizePage;
