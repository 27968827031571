import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import jsonMap from "../../../components/Utils/jsonMap";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import Request from "../../../helpers/Request";
import ActivationModal from "./ActivationModal";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  closeUpperModal,
  newCompanyProps,
  setNewCompanyProps,
  submitNewCompany,
  options,
  oldCompanyProps,
  updateCompany,
  loading,
  setSeverity,
  setSnackbar,
  setSnackbarMessage,
  tableRef,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const [taxIdentificationNumberError, setTaxIdentificationNumberError] =
    useState(null);
  const [company, setCompany] = useState(null);
  const [activationModal, setActivationModal] = useState(false);

  const RenderButton = () => {
    return (
      <Button
        variant="outlined"
        color="secondary"
        style={{ width: "40%", margin: "0 auto", marginBottom: 20 }}
        onClick={() => setActivationModal(true)}
      >
        ŞİRKETİ AKTİVE ET
      </Button>
    );
  };

  return (
    <>
      <ActivationModal
        closeUpperModal={closeUpperModal}
        activationModal={activationModal}
        setActivationModal={setActivationModal}
        company={company}
        options={options}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
        oldProps={company}
      />
      <FormControl
        noValidate
        autoComplete="off"
        className={classes.formControl}
      >
        <TextArea
          required
          label={"Şirket Adı"}
          value={newCompanyProps.name}
          onChangeFunc={(value) =>
            setNewCompanyProps({ ...newCompanyProps, name: value.target.value })
          }
          style={classes.textField}
        />
        <TextArea
          required
          label={"Şirket Kısa Adı"}
          value={newCompanyProps.nameShort}
          onChangeFunc={(value) =>
            setNewCompanyProps({
              ...newCompanyProps,
              nameShort: value.target.value,
            })
          }
          style={classes.textField}
        />
        {/* <SGKList newCompanyProps={newCompanyProps} setNewCompanyProps={setNewCompanyProps}/> */}
        <TextSelector
          required
          label={"Şirket Tipi"}
          value={newCompanyProps.eCompanyType}
          onChangeFunc={(value) =>
            setNewCompanyProps({
              ...newCompanyProps,
              eCompanyType: value.target.value,
            })
          }
          style={classes.textField}
          options={jsonMap("companytypes", options)}
        />
        <TextSelector
          required
          label={"Şehir"}
          value={newCompanyProps.cityTown}
          onChangeFunc={(value) =>
            setNewCompanyProps({
              ...newCompanyProps,
              cityTown: value.target.value,
            })
          }
          style={classes.textField}
          options={jsonMap("cities", options)}
        />
        <TextArea
          required
          label={"Vergi Dairesi"}
          value={newCompanyProps.taxOffice}
          onChangeFunc={(value) =>
            setNewCompanyProps({
              ...newCompanyProps,
              taxOffice: value.target.value,
            })
          }
          style={classes.textField}
        />
        <TextArea
          required
          error={taxIdentificationNumberError}
          label={"Vergi Kimlik Numarası"}
          value={newCompanyProps.taxIdentificationNumber}
          onChangeFunc={(value) => {
            setNewCompanyProps({
              ...newCompanyProps,
              taxIdentificationNumber: value.target.value,
            });
            company && setCompany(null);
            taxIdentificationNumberError &&
              setTaxIdentificationNumberError(null);
            Request(
              "get",
              "/api/companies/is-exists?&tax-identification-number=" +
                value.target.value
            ).then((resp) => {
              console.log(resp);
              if (resp.data && resp.status === 200) {
                if (!resp.data.content.isActive) {
                  if (oldCompanyProps) {
                    setTaxIdentificationNumberError(
                      "Bu vergi numarasına sahip inaktif bir şirket bulunmaktadır. Şirket ekleme ekranından yeniden aktifleştirebilirsiniz."
                    );
                  } else {
                    setTaxIdentificationNumberError(
                      "Bu vergi numarasına sahip inaktif bir şirket bulunmaktadır. Yeniden aktifleştirmek ister misiniz?"
                    );
                    console.log(resp.data.content);
                    setCompany(resp.data.content);
                  }
                } else {
                  if (resp.data.content.id !== oldCompanyProps?.id) {
                    setTaxIdentificationNumberError(
                      "Bu vergi kimlik numarasına sahip aktif bir şirket var."
                    );
                  } else {
                    setTaxIdentificationNumberError(
                      "Bu vergi kimlik numarasına sahip bir şirket bulunmaktadır."
                    );
                  }
                }
              }
            });
          }}
          style={classes.textField}
        />
        {company && <RenderButton />}
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() =>
                oldCompanyProps ? updateCompany() : submitNewCompany()
              }
              className={classes.submit}
            >
              {language.form.submit}
            </Button>
          )}
        </div>
      </FormControl>
    </>
  );
}
