import React from 'react';
import LanguageHelper from '../../../../helpers/LanguageHelper';
import { Backdrop, Button, Fade, FormControl, makeStyles, Modal } from '@material-ui/core';
import { TextArea } from '../../../../components/Fields/TextFields';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3)
  },
  submit: {
    marginTop: "10px"
  },
  formControl: {
    width: 600
  },
  textField: {
    marginBottom: "20px"
  },
}));

export default function RejectionModal({open, handleCloseModal, rejection, setRejection, rejectReport, data}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [descriptionError, setDescriptionError] = React.useState(null);

  return(
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className={classes.paper}>
          <FormControl noValidate autoComplete="off" className={classes.formControl}>
            <TextArea
              required
              error={descriptionError}
              label={"Ret Açıklaması"}
              value={rejection}
              shrink={rejection}
              style={classes.textField}
              onChangeFunc={value => {
                if(value.target.value.length > 300) {
                  setDescriptionError("Açıklama 300 karakterden uzun olamaz.");
                } else {
                  descriptionError && setDescriptionError(null);
                  setRejection(value.target.value);
                }
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => rejectReport(data.id)}
              className={classes.submit}>
              {language.form.submit}
            </Button>
          </FormControl>
        </div>
      </Fade>
    </Modal>
  )
}