import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, Fade, FormControl, Modal, TextField, CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { modalFormStyles } from '../../../assets/styles/tableContainer';
import Request from '../../../helpers/Request';
import SessionHelper from '../../../helpers/SessionHelper';
import showSnackbar from '../../../components/Utils/showSnackbar';

const useStyles = makeStyles((theme) => (modalFormStyles(theme)));
const modalStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
  },
}))

export default function TransferFormModal({data, modal, setModal, setSnackbar, setSnackbarMessage, setSeverity, tableRef, updated, setUpdated, isUnsuit}) {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const currentUser = SessionHelper.getUser();
  const collator = new Intl.Collator("tr");

  const [compsAndDepts, setCompsAndDepts] = React.useState([]);
  const [company, setCompany] = React.useState(null);
  const [companies, setCompanies] = React.useState([]);
  const [department, setDepartment] = React.useState(null);
  const [departments, setDepartments] = React.useState([]);
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  
  const init = useCallback(async () => {
    if(modal) {
      const param = {
        "only-edc": currentUser?.roles.includes("ROLE_BÖLGE_MÜDÜRÜ"),
      };
      const resp = await Request("get", "/api/departments/" + currentUser?.id + "/get-according-to-user/table", null, param);
      console.log(resp);
      const data = resp.data;
      let comps = [];
      for(let company in data) {
        comps.push(company);     
      }
      setCompanies(comps);
      setCompsAndDepts(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getUsersOfDepartment = async (departmentId) => {
    const resp = await Request("get", "/api/users/belongs-to-department/" + departmentId);
    console.log(resp);
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let user = {};
      user["name"] = data[i]?.fName + " " + data[i]?.lName;
      user["id"] = data[i]?.id;
      arr.push(user);
    }
    return arr;
  }

  const transferToUser = async () => {
    for(let i = 0; i < data.length; i++) {
      data[i].userId = user;
    }
    let body = {
      inspectionUserChangeRequestList: isUnsuit ? [] : data, 
      nonconformityUserChangeRequestList: isUnsuit ? data : []
    };
    const resp = await Request("put", "/api/tasks/update-task-users", body);
    console.log(resp);
    setUpdated(!updated);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, "Görevler devredildi.", tableRef);
    handleCloseModal();
  }

  const handleCloseModal = () => {
    setModal(false);
    setUser(null);
    setDepartment(null);
    setCompany(null);
  }

  const getFromId = (id, options) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }
  
  return (
    <>
      <Modal
        className={modalClasses.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={modalClasses.paper}>
            <FormControl noValidate autoComplete="off" className={classes.formControl}>
              <Autocomplete //COMPANY
                id="combo-box"
                options={companies?.sort(function(a, b) {
                  return collator.compare(a, b)
                })}
                getOptionLabel={(option) => option}
                fullWidth={true}
                loading={compsAndDepts.length === 0}
                loadingText={<CircularProgress color="secondary"/>}
                value={company ? company : null}
                onChange={async (event, value) => {
                  setCompany(value);
                  setDepartments(compsAndDepts[value] ? compsAndDepts[value] : []);
                }} 
                renderInput={(params) => <TextField {...params} required label="Şirket" className={classes.textField}/>}
              /> 
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={departments?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionLabel={(option) => option.name}
                disabled={!company}
                fullWidth={true}
                value={department ? getFromId(department, departments) : null}
                onChange={async (event, value) => {
                  setDepartment(value?.id);
                  setUsers(await getUsersOfDepartment(value?.id));
                }} 
                renderInput={(params) => <TextField {...params} required label="Bölüm" className={classes.textField}/>}
              /> 
              <Autocomplete //USER
                id="combo-box"
                options={users?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionLabel={(option) => option.name}
                disabled={!department}
                fullWidth={true}
                value={user ? getFromId(user, users) : null}
                onChange={async (event, value) => {
                  setUser(value?.id)
                }} 
                renderInput={(params) => <TextField {...params} required label="Kullanıcı" className={classes.textField}/>}
              /> 
              <Button
                type="submit"
                fullWidth
                disable={user}
                variant="contained"
                color="primary"
                onClick={() => transferToUser()}
                className={classes.submit}>
                GÖREVLERİ DEVRET
              </Button>
            </FormControl>
          </div>
        </Fade>
      </Modal>
    </>
  ) 
}