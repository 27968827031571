import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import { useLocation, useNavigate } from "react-router-dom";
import LanguageHelper from "../../helpers/LanguageHelper";
import logo from "../../assets/images/pincident.png";
import Copyright from "../../components/Footer/Copyright";
import { Box } from "@material-ui/core";
import Request from "../../helpers/Request";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from "@material-ui/core/Tooltip";
import Settings from "../../components/Buttons/Settings";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: 396,
  },
  avatar: {
    margin: 20,
    width: 300,
    height: "auto",
  },
  passwordCheck: { width: 396 },
  password: { width: 396, marginLeft: theme.spacing(5) },
  passwordIcon: {
    margin: theme.spacing(4, 0, 2, 2),
  },
}));

export default function ResetPassword() {
  let navigate = useNavigate();
  let location = useLocation();
  const tokenstr = location.pathname.substring(7);
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const darkTheme = createTheme({ palette: { type: "dark" } });
  const lightTheme = createTheme({ palette: { type: "light" } });
  const regexRules =
    /(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\d)(?=.*[!@#$%&*'(),\-+<=>:;?{}^._])[A-Za-z\d!@#$%&*'(),\-+<=>:;?{}^._]{8,32}$/;
  const passwordRules = `- Şifreniz Türkçe karakter içermemelidir\n- Şifreniz en az 8, en çok 32 karakterden oluşmalıdır.\n- Şifreniz en az 1 küçük ve 1 büyük harf içermelidir.\n- Şifreniz en az 1 rakam içermelidir.\n- Şifreniz !@#$%&*'(),-+<=>:;?{}^._ sembollerinden en az 1'ini içermelidir.`;

  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [message, setMessage] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode")
  );
  const [, setLanguage] = React.useState(language);
  // eslint-disable-next-line no-unused-vars
  const [severity, setSeverity] = useState("info");
  const [error, setError] = React.useState(null);
  const [strongPassword, setStrongPassword] = useState(false);

  async function handleReset() {
    if (password === "") {
      setSnackbarMessage(language.login.providePassword);
      setSnackbar(true);
    } else if (passwordCheck === "") {
      setSnackbarMessage(language.login.providePasswordConfirm);
      setSnackbar(true);
    } else if (password !== passwordCheck) {
      setSnackbarMessage(language.login.passwordMatch);
      setSnackbar(true);
    } else if (!strongPassword) {
      setSnackbarMessage(error);
      setSnackbar(true);
    } else {
      const resp = await Request(
        "post",
        "/api/auth/confirm-reset/" + tokenstr,
        { newPassword: password }
      );
      console.log(resp);
      if (resp?.status === 200) {
        setSnackbarMessage(language.login.passChanged);
        setMessage(true);
        setTimeout(() => {
          navigate("/signin");
        }, 3000);
      } else {
        if (resp?.status === 429) {
          setSnackbarMessage(
            "Çok fazla istekte bulundunuz. Lütfen daha sonra tekrar deneyiniz."
          );
          setSnackbar(true);
        } else {
          if (resp?.data && resp?.data.message) {
            setSnackbarMessage(resp.data.message);
            setSnackbar(true);
          } else {
            setSnackbarMessage(language.login.unexpectedError);
            setSnackbar(true);
          }
        }
      }
    }
  }

  const checkPassword = (newPassword) => {
    if (!regexRules.test(newPassword)) {
      setError("Şifreniz belirtilen kurallara uymamaktadır.");
      setStrongPassword(false);
      setPassword(newPassword);
    } else {
      setPassword(newPassword);
      setStrongPassword(true);
      setError(null);
    }
  };

  return (
    <Container maxWidth={false}>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Settings
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        setLanguage={setLanguage}
      />
      <Container component="main" maxWidth="sm">
        <ThemeProvider theme={darkMode === "true" ? darkTheme : lightTheme}>
          <CssBaseline />
          <div className={classes.paper}>
            <img className={classes.avatar} alt={logo} src={logo} />
            <Typography component="h1" variant="h5">
              {language.login.reset}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                error={snackbar || error}
                helperText={error}
                variant="outlined"
                margin="normal"
                required
                //fullWidth
                className={classes.password}
                label={language.login.password}
                type="password"
                autoFocus
                onChange={(password) => checkPassword(password.target.value)}
              />
              <Tooltip
                title={
                  <div style={{ whiteSpace: "pre-line", fontSize: 12 }}>
                    {passwordRules}
                  </div>
                }
              >
                <HelpIcon
                  className={classes.passwordIcon}
                  color="primary"
                  fontSize="medium"
                />
              </Tooltip>
              <TextField
                error={snackbar}
                variant="outlined"
                margin="normal"
                required
                // fullWidth
                label={language.login.passwordConfirm}
                className={classes.passwordCheck}
                type="password"
                autoFocus
                onChange={(passwordCheck) =>
                  setPasswordCheck(passwordCheck.target.value)
                }
              />
              {password !== passwordCheck && passwordCheck !== "" && (
                <Typography style={{ color: "red" }}>
                  {language.login.passwordMatch}
                </Typography>
              )}
              {message && (
                <Typography style={{ color: "blue" }}>
                  {snackbarMessage}
                </Typography>
              )}
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                // disabled={!strongPassword}
                onClick={() => handleReset()}
              >
                {language.login.reset}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="#"
                    variant="body2"
                    onClick={() => {
                      navigate("/signin");
                    }}
                  >
                    {language.login.signin}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright
              string={"DELTA Smart Technologies"}
              date={new Date().getFullYear()}
              url={"https://deltasmart.tech/en/home/"}
            />
          </Box>
        </ThemeProvider>
      </Container>
    </Container>
  );
}
