import React from "react";
import SessionHelper from "../../../helpers/SessionHelper";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { FormControl } from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import ParticipantsList from "./ParticipantsList";
import { DateTimePicker } from "../../../components/Selectors/DatePickers";
import jsonMap from "../../../components/Utils/jsonMap";
import { getDocuments, getEquipments, getVideos } from "./GetOptions";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  visitProps,
  requestVisitProps,
  setRequestVisitProps,
  loading,
  setLoading,
  options,
  setOptions,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const isSecurity = roles.includes("ROLE_GÜVENLİK_GÖREVLİSİ");
  const isHost = visitProps?.hostId === user?.id;

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      {(!isSecurity || !visitProps || (isSecurity && isHost)) && (
        <>
          <Autocomplete
            id="combo-box"
            options={options.visitTypes?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              requestVisitProps.visitTypeId
                ? getFromId(requestVisitProps.visitTypeId, options.visitTypes)
                : null
            }
            onChange={async (event, value) => {
              setLoading(true);
              let newOpts = options;
              newOpts.equipments = value?.id
                ? await getEquipments(value?.id)
                : await getEquipments();
              newOpts.videos = value?.id
                ? await getVideos(value?.id)
                : await getVideos();
              newOpts.documents = value?.id
                ? await getDocuments(value?.id)
                : await getDocuments();
              setOptions(newOpts);
              setRequestVisitProps({
                ...requestVisitProps,
                visitTypeId: value?.id,
              });
              setLoading(false);
            }}
            renderOption={(option) => <span>{option.name}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label={language.visits.visitTypeName}
                className={classes.textField}
              />
            )}
          />
          <Autocomplete
            id="combo-box"
            options={options.departments?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              requestVisitProps.departmentId
                ? getFromId(requestVisitProps.departmentId, options.departments)
                : null
            }
            onChange={(event, value) => {
              setRequestVisitProps({
                ...requestVisitProps,
                departmentId: value?.id,
              });
            }}
            renderOption={(option) => <span>{option.name}</span>}
            renderInput={(params) => (
              <TextField
                {...params}
                label={language.visits.departmentName}
                className={classes.textField}
              />
            )}
          />
          <DateTimePicker
            style={classes.dateField}
            label={language.visits.plannedDate}
            value={
              requestVisitProps.plannedDate
                ? requestVisitProps.plannedDate.replaceAll("Z", "")
                : null
            }
            shrink={requestVisitProps.plannedDate}
            onChangeFunc={(value) => {
              setRequestVisitProps({
                ...requestVisitProps,
                plannedDate: moment(value)
                  .toISOString(true)
                  .replace("+03:00", "Z"),
              });
            }}
          />
          <TextArea
            required
            label={language.visits.topic}
            value={requestVisitProps.topic}
            onChangeFunc={(value) =>
              setRequestVisitProps({
                ...requestVisitProps,
                topic: value.target.value,
              })
            }
            style={classes.textField}
          />
          <TextArea
            required
            label={language.visits.note}
            value={requestVisitProps.note}
            onChangeFunc={(value) =>
              setRequestVisitProps({
                ...requestVisitProps,
                note: value.target.value,
              })
            }
            style={classes.textField}
          />
        </>
      )}
      {visitProps && (isHost || isSecurity) && (
        <TextSelector
          label={language.visits.status}
          value={requestVisitProps.status}
          shrink={requestVisitProps.status !== null}
          onChangeFunc={(value) => {
            setRequestVisitProps({
              ...requestVisitProps,
              status: value.target.value,
            });
          }}
          style={classes.textField}
          options={jsonMap(
            (isSecurity && !isHost) ? "statusForSecurity" : "statusForHost",
            options
          )}
        />
      )}
      {(!isSecurity || !visitProps || (isSecurity && isHost)) &&
        options.users && (
          <ParticipantsList
            props={requestVisitProps}
            setProps={setRequestVisitProps}
            options={options}
          />
        )}
    </FormControl>
  );
}
