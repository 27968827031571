import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchRecordData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      "page-size": query.pageSize,
      sort: "id,desc",

      //FILTERS
      departmentFilter: filterProps.department?.id,
      wasteTypeFilter: filterProps.wasteTypeFilter,
      wasteUnitFilter: filterProps.wasteUnitFilter,
      yearFilter: filterProps.yearFilter,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "cityName") { params.sort = "city.name," + query.orderDirection; }
      else if (query.orderBy.field === "townName") { params.sort = "town.name," + query.orderDirection; }
      else if (query.orderBy.field === "trainingType") { params.sort = "trainingType," + query.orderDirection; }
      else if (query.orderBy.field === "topics") { params.sort = "topics," + query.orderDirection; }
      else if (query.orderBy.field === "plannedDate") { params.sort = "plannedDate," + query.orderDirection; }
      else if (query.orderBy.field === "completionDate") { params.sort = "completionDate," + query.orderDirection; }
      else if (query.orderBy.field === "time") { params.sort = "time," + query.orderDirection; }
      else if (query.orderBy.field === "instructorName") { params.sort = "instructor.fName,instructor.lName," + query.orderDirection; }
      else if (query.orderBy.field === "status") { params.sort = "status," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    let resp;
    if (params.departmentFilter) {
      resp = await Request("get", "/api/waste-measurement/getAll", null, params);
    } else {
      resp = await Request("get", "/api/waste-measurement/consolidated", null, params);
    }
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {    
      let data = resp.data;
      let otherContent = data.content;
      let content = otherContent.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const props = {
          id: content[i]?.id,
          companyId: content[i]?.catalog?.company?.id,
          companyName: content[i]?.catalog?.company?.name,
          departmentName: content[i]?.department?.name,
          catalogueName: content[i]?.catalog?.wasteName,
          catalogueId: content[i]?.catalog?.id,
          january: content[i]?.january,
          february: content[i]?.february,
          march: content[i]?.march,
          april: content[i]?.april,
          may: content[i]?.may,
          june: content[i]?.june,
          july: content[i]?.july,
          august: content[i]?.august,
          september: content[i]?.september,
          october: content[i]?.october,
          november: content[i]?.november,
          december: content[i]?.december,
          sum: content[i]?.sum,
          average: content[i]?.average,
        };
        temp.push(props);
      }
      setNumOfEntries(otherContent.totalElements);
      resolve({
        data: temp,
        page: otherContent.pageable.pageNumber,
        totalCount: otherContent.totalElements,
      })
    }
  })
  return data;
}
