import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { taskStatuses } from '../../../assets/constants';
import { inspectionTypes } from '../../../assets/constants';
import MaterialTable from "@material-table/core";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import LanguageHelper from '../../../helpers/LanguageHelper';
import TransferFormModal from './TransferFormModal';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  column: {
    fontSize: 14,
    fontColor: "darkblue"
  },
  transferButton: {
    padding: 20,
    backgroundColor: '#C51162',
    marginTop: 30
    /* position: "absolute",
    backgroundColor: '#C51162',
    borderRadius: 0,
    marginBottom: 90,
    padding: 20,
    bottom: '0',
    top: 'unset', */
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Transfer: forwardRef((props, ref) => <SyncAltIcon {...props} ref={ref} />)
};

export default function UnsuitTasksTransfer({data, updated, setUpdated, setSnackbar, setSnackbarMessage, setSeverity, loading}) {
  const classes = useStyles();
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const columns = [
    { field: 'id', title: 'No' },
    { field: 'user', title: 'Görev Atanan Kişi' },
    { field: 'inspectionUser', title: 'Denetim Yapan Kişi' },
    { field: 'company', title: 'Şirket' },
    { field: 'department', title: 'Bölüm' },
    { field: 'city', title: 'Şehir' },
    { field: 'town', title: 'Faaliyet Bölgesi' },
    { field: 'departmentField', title: 'Faaliyet Tipi' },
    { field: 'terminationTime', title: 'Termin Tarihi' },
    { field: 'completionTime', title: 'Tamamlanma Tarihi' },
    { field: 'approvalDate', title: 'Onaylanma Tarihi' },
    { field: 'status', title: 'Durum' },
  ];

  const [formModal, setFormModal] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [tasksToBeTransfered, setTasksToBeTransfered] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  
  const init = useCallback(async () => {
    setTableData(TableRows());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = () => {
    let temp = [];
    for(let index in selectedRows) {
      const task = {nonconformityId: selectedRows[index]?.id}
      temp.push(task)
    }
    setTasksToBeTransfered(temp);
    setFormModal(true);
  }

  const formatField = (field, fieldType) => {
    let ret = "";
    if(fieldType === "userName" || fieldType === "inspectionUserName") {
      ret = field ? field?.fName + " " + field?.lName : field;
    } else if(fieldType === "inspectionType") {
      ret = inspectionTypes[field];
    } else if(fieldType === "departmentField") {
      ret = field?.fieldOfActivity;
    } else if(fieldType === "approvalDate" || fieldType === "completionDate" || fieldType === "terminationTime") {
      ret = field && moment(field).format('DD-MM-YYYY HH:mm:ss');
    } else if(fieldType === "company" || fieldType === "department" || fieldType === "town") {
      ret = field?.name;
    } else if(fieldType === "city") {
      ret = field?.belongingCity?.name;
    } else if(fieldType === "status") {
      ret = taskStatuses[field];
    } else {
      ret = field;
    }
    return ret ? ret : "Yok";
  }

  const TableRows = () => {
    const dataToReturn = [];
    for(let index in data) {
      const value = data[index];
      const row = {
        id: formatField(value?.id),
        user: formatField(value?.user, "userName"),
        inspectionUser: formatField(value?.inspectionUser, "inspectionUserName"),
        company: formatField(value?.company, "company"),
        department: formatField(value?.department, "department"),
        city: formatField(value?.cityTown, "city"),
        town: formatField(value?.cityTown, "town"),
        departmentField: formatField(value?.department, "departmentField"),
        terminationTime: formatField(value?.terminationTime, "terminationTime"),
        completionTime: formatField(value?.completionTime, "completionDate"),
        approvalDate: formatField(value?.approvmentDate, "approvalDate"),
        status: formatField(value?.eStatus, "status"),
      }
      dataToReturn.push(row);
    }
    return dataToReturn;
  }

  return (
    <>
      <TransferFormModal 
        data={tasksToBeTransfered}
        modal={formModal}
        setModal={setFormModal}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        tableRef={tableRef}
        updated={updated}
        setUpdated={setUpdated}
        isUnsuit={true}
      />
      <div className={classes.table}>
        <MaterialTable
          title="Uygunsuzluk Giderme Görevleri"
          localization={language.tableLocalization}
          icons={tableIcons}
          columns={columns}
          isLoading={loading}
          data={tableData}        
          onSelectionChange={(rows) => setSelectedRows(rows)}  
          options={{
            minBodyHeight: "520px",
            maxBodyHeight: "520px",
            emptyRowsWhenPaging: false,
            doubleHorizontalScroll: true,
            selection: true
          }}
        />
      </div>
      {selectedRows.length > 0 && 
      <Button onClick={handleOpenModal} className={classes.transferButton}>SEÇİLEN GÖREVLERİ DEVRET</Button>}
    </>
  ) 
}