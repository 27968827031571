import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import "../../../index.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../helpers/LanguageHelper";
const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5,
  },
  fields: {
    marginTop: -5,
    color: "#B8B8B8",
  },
  textFieldFields: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%",
  },
  required: {
    marginTop: -5,
    color: "#b24d50",
    fontSize: 13,
  },
}));

export default function ParticipantsList({ props, setProps, options, err }) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const RenderUsersBox = () => {
    let users = props.participantObjects ? [...props.participantObjects] : [];

    return (
      <div>
        {users && users.length > 0 ? (
          <List className={classes.list}>
            {users.map((data, i) => (
              <ListItem key={i}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container xs={7} direction="row">
                    <IconTooltipButton
                      title={language.tableDetails.deleteParticipant}
                      onClick={() => {
                        users.splice(i, 1);
                        setProps({ ...props, participantObjects: users });
                      }}
                    >
                      <DeleteIcon />
                    </IconTooltipButton>
                    <ListItemText
                      style={{ marginTop: 15 }}
                      secondary={data.name || language.etc.error}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={classes.list} style={{ textAlign: "center" }}>
            <p style={{ color: "red", fontSize: 14, marginLeft: "auto" }}>
              {language.errors.noParticipants}
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ textAlign: "left" }}>
      <Grid item xs={6}>
        <p className={classes.fields}>{language.tableColumns.cciParticipants}</p>
      </Grid>
      <RenderUsersBox />
      <div style={{ textAlign: "center" }}>
        <Autocomplete
          id="combo-box"
          options={options.users?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if (reason === "reset") {
              setAutocompleteValue("");
            } else if (reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value, reason) => {
            if (value?.id && value !== "") {
              const users = props.participantObjects
                ? [...props.participantObjects]
                : [];
              if (!users.includes(value)) {
                users.push(value);
                setProps({ ...props, participantObjects: users });
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              // required
              // error={err}
              // helperText={err}
              label={language.tableDetails.addParticipant}
              className={classes.textFieldFields}
            />
          )}
        />
      </div>
    </div>
  );
}
