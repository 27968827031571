import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl } from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

/**
 * The form control component used in the user creation/editing modal.
 * @param {newUserProps: obj, setNewUserProps: func, options: obj[], submitNewUser: func} props of the form control
 */
export default function ActivationModal({
  closeUpperModal,
  activationModal,
  setActivationModal,
  company,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
  oldProps,
}) {
  const user = SessionHelper.getUser();

  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  console.log(oldProps);
  const [name, setName] = useState(null);
  const [nameShort, setNameShort] = useState(null);
  const [cityTown, setCityTown] = useState(null);
  const [companyType, setCompanyType] = useState(null);

  const [nameError, setNameError] = useState(null);
  const [nameShortError, setNameShortError] = useState(null);
  const [cityTownError, setCityTownError] = useState(null);
  const [companyTypeError, setCompanyTypeError] = useState(null);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!name) {
      setNameError(errorMessage);
      error = true;
    }
    if (!nameShort) {
      setNameShortError(errorMessage);
      error = true;
    }
    if (!cityTown) {
      setCityTownError(errorMessage);
      error = true;
    }
    if (!companyType) {
      setCompanyTypeError(errorMessage);
      error = true;
    }
    if (!error) {
      activate();
    }
  };

  const handleCloseModal = () => {
    setActivationModal(false);
  };

  React.useEffect(() => {
    setName(oldProps?.name);
    setNameShort(oldProps?.nameShort);
    setCityTown(oldProps && String(oldProps?.cityTown?.id));
    setCompanyType(oldProps?.companyType);
  }, [oldProps]);

  const activate = async () => {
    const props = {
      id: company.id,
      name: name,
      nameShort: nameShort,
      companyType: companyType,
      cityTown: Number(cityTown),
    };
    console.log(props);
    const resp = await Request("post", "/api/companies/activate", props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      "Başarıyla aktive edildi.",
      tableRef
    );
    handleCloseModal();
    closeUpperModal();
  };

  return (
    <TableEntryModal
      modal={activationModal}
      handleCloseModal={handleCloseModal}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <TextArea
          required
          error={nameError}
          label={"Şirket Adı"}
          value={name}
          onChangeFunc={(value) => setName(value.target.value)}
          style={classes.textField}
        />
        <TextArea
          required
          error={nameShortError}
          label={"Şirket Kısa Adı"}
          value={nameShort}
          onChangeFunc={(value) => setNameShort(value.target.value)}
          style={classes.textField}
        />
        <TextSelector
          required
          error={companyTypeError}
          label={"Şirket Tipi"}
          value={companyType}
          onChangeFunc={(value) => setCompanyType(value.target.value)}
          style={classes.textField}
          options={jsonMap("companytypes", options)}
        />
        <TextSelector
          required
          error={cityTownError}
          label={"Şehir"}
          value={cityTown}
          onChangeFunc={(value) => setCityTown(value.target.value)}
          style={classes.textField}
          options={jsonMap("cities", options)}
        />
        <div style={{ paddingBottom: 20 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            className={classes.submit}
          >
            {"AKTİFLEŞTİR"}
          </Button>
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
