import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import {
  Backdrop,
  Button,
  CircularProgress,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
  Modal,
} from "@material-ui/core";
import ListOfFiles from "../../../../components/Files/ListOfFiles";
import Forms from "./Forms";
import Request from "../../../../helpers/Request";
import TaskData from "./TaskData";
import { getPersonnel } from "../../taskspage/components/GetOptions";
import PrintUnsuitability from "./PrintUnsuitability";
import moment from "moment";
import PrintDetails from "./PrintDetails";
import { modalStyles } from "../../../../assets/styles/tableContainer";
import ModalForm from "./ModalForm";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import { openPDF } from "../../../../components/Forms/FilledForm";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  labelStyle: {
    margin: 30,
  },
  dividerStyle: {
    margin: 20,
  },
  list: {
    border: "1px solid #474D5D",
    borderRadius: "10px",
    margin: "0 auto",
    width: 400,
    maxHeight: 300,
    overflow: "auto",
  },
}));

const useModalStyles = makeStyles((theme) => modalStyles(theme));

/**
 * A table component that lists the inspection tasks. The details panel contains the files and forms of
 * the inspections.
 */
export default function TaskDetails({
  id,
  rowData,
  tableRef,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const classes = useStyles();
  const modalStyles = useModalStyles();
  const language = LanguageHelper.getLanguage();

  const [data, setData] = React.useState(null);
  const [users, setUsers] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [details, setDetails] = React.useState(false);
  const [user, setUser] = React.useState("");
  const [values, setValues] = React.useState({ id: null, date: moment() });
  const [formUnsuitDetails, setFormUnsuitDetails] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(false);

  const getTask = useCallback(async (id) => {
    const resp = await Request("get", "/api/inspections/" + id);
    return resp.data;
  }, []);

  const init = useCallback(async () => {
    const data = id ? await getTask(id) : rowData;
    setData(data);
    setUsers(
      id
        ? await getPersonnel(await data.department.id)
        : await getPersonnel(rowData.department)
    );
  }, [getTask, id, rowData]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setFormUnsuitDetails(null);
  };

  const sendPdfRequest = async () => {
    setBackdropLoading(true);
    const resp = await Request(
      "get",
      "/api/inspections/" + data.id + "/as-pdf",
      null,
      null,
      { Accept: "application/pdf" }
    );
    openPDF(resp, "form", setSnackbar, setSnackbarMessage, setSeverity);
    setBackdropLoading(false);
  };

  const RenderUnsuitablePersonnelList = () => {
    return (
      <>
        {data?.nonconformityUsers?.length > 0 && (
          <div style={{ marginTop: 10 }}>
            <label className={classes.labelStyle}>
              Uygunsuzluk Tespit Edilen Personeller
            </label>
            <List className={classes.list}>
              {data.nonconformityUsers.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText>{data.fName + " " + data.lName}</ListItemText>
                </ListItem>
              ))}
            </List>
            <Divider className={classes.dividerStyle} />
          </div>
        )}
      </>
    );
  };

  const RenderFiles = () => {
    return (
      <div style={id && { marginTop: 30 }}>
        <label className={classes.labelStyle}>
          {language.tableDetails.files}
        </label>
        {data && data.fileKeys?.length > 0 ? (
          <ListOfFiles fileKeys={data.fileKeys}></ListOfFiles>
        ) : (
          <p style={{ fontSize: 14, color: "red" }}>
            {language.tableDetails.noFile}
          </p>
        )}
      </div>
    );
  };

  const RenderForms = () => {
    return (
      <>
        <label className={classes.labelStyle}>
          {language.tableDetails.forms}
        </label>
        {data && data.forms?.length > 0 ? (
          <Forms
            data={data}
            setSnackbar={setSnackbar}
            setSnackbarMessage={setSnackbarMessage}
            setSeverity={setSeverity}
            setBackdropLoading={setBackdropLoading}
          />
        ) : (
          <p style={{ fontSize: 14, color: "red" }}>
            {language.tableDetails.noForm}
          </p>
        )}
      </>
    );
  };

  const RenderPDF = () => {
    return (
      <>
        <label className={classes.labelStyle}>{"PDF"}</label>
        {data && data.forms?.length > 0 ? (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 5, width: "59.3%" }}
              onClick={sendPdfRequest}
            >
              PDF'e Aktar
            </Button>
          </>
        ) : (
          <p style={{ fontSize: 14, color: "red" }}>
            {language.tableDetails.noForm}
          </p>
        )}
      </>
    );
  };

  const RenderLocationButton = () => {
    return (
      <>
        <label className={classes.labelStyle}>{"Konum"}</label>
        {data.location ? (
          <>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 5, width: "59.3%" }}
              onClick={() =>
                window.open(
                  `http://maps.google.com.tr/maps?z=12&t=m&q=loc:${data.location?.latitude}+${data.location?.longitude}`
                )
              }
            >
              Konumu Aç
            </Button>
          </>
        ) : (
          <p style={{ fontSize: 14, color: "red" }}>{"Konum Bilgisi Yok"}</p>
        )}
      </>
    );
  };

  return (
    <>
      {data ? (
        <>
          {data.status !== 404 ? (
            <Grid container>
              <Grid item xs={12} className={classes.details}>
                {/*TASK DATA SECTION (only for details page not details panel)*/}
                {id && <TaskData data={data} />}
                <RenderUnsuitablePersonnelList />

                {/*FILES SECTION*/}
                <RenderFiles />
                <Divider className={classes.dividerStyle} />

                {/*FORMS SECTION*/}
                <RenderForms />
                <Divider className={classes.dividerStyle} />

                {/*PDF SECTION*/}
                <RenderPDF />
                <Divider className={classes.dividerStyle} />

                {/*LOCATION SECTION*/}
                <RenderLocationButton />

                {/*SEE UNSUIT TASK DETAILS / ASSIGN UNSUIT TASK SECTION*/}
                {((data.unsuitability && data.unsuitability !== "Yok") ||
                  (data.nonConformityReports &&
                    data.nonConformityReports.length > 0)) && (
                  <PrintUnsuitability
                    data={data}
                    setModal={setModal}
                    setDetails={setDetails}
                    setFormUnsuitDetails={setFormUnsuitDetails}
                  />
                )}
              </Grid>
            </Grid>
          ) : (
            <>NOT FOUND</>
          )}
        </>
      ) : (
        <CircularProgress color="secondary" />
      )}
      <TableBackdrop backdropLoading={backdropLoading} />
      <Modal
        className={modalStyles.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={modalStyles.paper}>
            {details ? (
              <PrintDetails formUnsuitDetails={formUnsuitDetails} />
            ) : (
              <ModalForm
                data={data}
                user={user}
                setUser={setUser}
                values={values}
                setValues={setValues}
                handleCloseModal={handleCloseModal}
                users={users}
                setSnackbar={setSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                setSeverity={setSeverity}
                tableRef={tableRef}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}
