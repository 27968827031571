import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Collapse, Divider, FormControl, TextField } from "@material-ui/core";
import React from "react";
import ISGUsersList from '../../components/ISGUsersList';
import Request from '../../../../helpers/Request';
import { Autocomplete } from "@material-ui/lab";
import AltTable from "../../../../components/Table/AltTable";
import EmployeeRepresentatives from '../../components/EmployeeRepresentatives';
import EmergencyTeams from "../../components/EmergencyTeams";
import RiskAssessment from '../../components/RiskAssessment';
import HealthUsersList from '../../components/HealthUsersList';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "60%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  textField: {
    marginBottom: "3vw",
    whiteSpace: 'pre-wrap',
    textAlign: "left",
  },
  dateField: {
    marginBottom: "3vw",
    marginTop: 0	
  },
  button: {
    width: "20vw",
    margin: "0 auto",
    padding: 10,
    marginBottom: 20,
  },
  personnelContainer: {
    border: "1px solid #808080",
    width: "100%",
    margin: "0 auto",
    borderRadius: "5px",
    padding: 15,
    marginBottom: 20,
    maxHeight: 300,
    overflow: "auto",
  } 
}));

export default function PersonnelDataForm({setChangeMade, departmentProps, setDepartmentProps, options}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  const titles = ["No", "İsim", "E-Posta Adresi"];

  const [list, setList] = React.useState(false);
  const [personnel, setPersonnel] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const getUsersOfDepartment = async () => {
    setLoading(true);
    const resp = await Request("get", "/api/users/belongs-to-department/" + departmentProps.id);
    setLoading(false);
    console.log(resp);
    return resp.data;
  }

  const getFromId = (options, id) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  const togglePersonnelList = async () => {
    setList(!list);
    if(personnel.length === 0) {
      const pers = await getUsersOfDepartment();
      let rows = [];
      for(let i = 0; i < pers.length; i++) {
        let cells = [];
        cells.push(pers[i].id ? pers[i].id : "Yok");
        cells.push((pers[i].fName && pers[i].lName) ? pers[i].fName + " " + pers[i].lName : "Yok");
        cells.push(pers[i].email ? pers[i].email : "Yok");
        rows.push(cells);
      }
      setPersonnel(rows);
    } 
  }

  const RenderPersonnel = () => {
    return(
      <div className={classes.personnelContainer}>
      {loading ? <CircularProgress color="secondary"/> :
        <>
        {personnel.length > 0 ?
          <AltTable
            titles={titles}
            rows={personnel}
          />
          :
          <p style={{color: "red", fontSize: 14}}>BÖLÜME KAYITLI PERSONEL YOK</p>
        }
        </>
      }
      </div>
    )
  }
  
  return(
    <FormControl required autoComplete="off" className={classes.formControl}>
      <Button variant="contained" color="secondary" onClick={() => togglePersonnelList()} className={classes.button}>
        {list ? "PERSONEL LİSTESİNİ GİZLE" : "PERSONEL LİSTESİNİ GÖSTER"}
      </Button>
      <div style={{display: "flex", flexDirection: "column"}}>
      <Collapse in={list}>
        <RenderPersonnel/>
      </Collapse>
        <Divider style={{marginBottom: 20}}/>
        <Autocomplete //MANAGER
          id="combo-box"
          options={options.managers?.sort(function(a, b) {
            return collator.compare(a.name, b.name)
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          defaultValue={getFromId(options.managers, departmentProps.managerId)}
          onChange={async (event, value) => {
            setDepartmentProps({...departmentProps, managerId: value?.id});
            setChangeMade && setChangeMade(true);
          }} 
          renderInput={(params) => <TextField {...params} label="Yönetici" className={classes.textField}/>}
        />
        <ISGUsersList
          newDepartmentProps={departmentProps} 
          setNewDepartmentProps={setDepartmentProps} 
          options={options}
          setChangeMade={setChangeMade}
        />
        <Autocomplete //APPROVER
          id="combo-box"
          options={options.allApprovers?.sort(function(a, b) {
            return collator.compare(a.name, b.name)
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          defaultValue={getFromId(options.allApprovers, departmentProps.approverId)}
          onChange={async (event, value) => {
            setDepartmentProps({...departmentProps, approverId: value?.id});
            setChangeMade && setChangeMade(true);
          }} 
          renderInput={(params) => <TextField {...params} label="Aksiyon Onaylama İSG Uzmanı" className={classes.textField}/>}
        />
        <HealthUsersList
          newDepartmentProps={departmentProps} 
          setNewDepartmentProps={setDepartmentProps} 
          options={options}
          setChangeMade={setChangeMade}
        />
        <EmployeeRepresentatives
          newDepartmentProps={departmentProps} 
          setNewDepartmentProps={setDepartmentProps} 
          options={options}
          setChangeMade={setChangeMade}
        />
        <RiskAssessment
          newDepartmentProps={departmentProps} 
          setNewDepartmentProps={setDepartmentProps} 
          options={options}
          setChangeMade={setChangeMade}
        />
        <EmergencyTeams
          newDepartmentProps={departmentProps} 
          setNewDepartmentProps={setDepartmentProps} 
          options={options}
          setChangeMade={setChangeMade}
        />
      </div>
    </FormControl>
  )
}