import React from 'react';
import FormTemplateTable from './components/FormTemplateTable';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { tableContainerStyles } from '../../assets/styles/tableContainer';
import SessionHelper from '../../helpers/SessionHelper';
import { getViewAuthorizationForAll, getAuthorizationForPage } from '../../helpers/AuthorizationHelper';
import UnauthorizedPage from '../UnauthorizedPage';

export default function FormTemplatePage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "formTemplateTable");
  return (
    <Container maxWidth={false} style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <FormTemplateTable /> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}