import React, { useState, useRef, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
// import Grid from "@material-ui/core/Grid";
import Copyright from "../../components/Footer/Copyright";
// import moment from "moment-timezone";
import TableBackdrop from "../../components/Table/TableBackdrop";
// import { ExportButton, DateSettings } from "./components/Settings";
// import Charts from "./components/Charts";
import { useLocation } from "react-router-dom";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import DashboardTabs from "./components/DashboardTabs";
import GetOptions from "./components/GetOptions";
import GetFilterFields from "./components/Filters";
import SessionHelper from "../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  copyright: {
    position: "relative",
    bottom: 0,
  },
}));

/**
 * A dashboard component that renders charts depending on the role of the user.
 */
export default function Dashboard() {
  const classes = useStyles();
  const location = useLocation();
  const gdzlink = (
    <a href="https://isg.gdzelektrik.com.tr/forgot#">
      https://isg.gdzelektrik.com.tr/forgot#
    </a>
  );
  const message = (
    <>
      Şifreniz bilgi güvenliği gereksinimlerini karşılamamaktadır. Lütfen daha
      güçlü bir şifre belirleyiniz. Şifre Yenileme ({gdzlink})
    </>
  );
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "dashboard");

  const chartsRef = useRef();

  const [snackbar, setSnackbar] = useState(
    location.state?.error ? true : false
  );
  const [snackbarMessage] = useState(location.state?.error ? message : "");
  const [severity] = useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [value, setValue] = React.useState(0);
  // const [startDate, setStartDate] = React.useState(
  //   new moment()
  //     .set("date", 1)
  //     .set("hour", 0)
  //     .set("minute", 0)
  //     .toISOString(true)
  // );
  // const [endDate, setEndDate] = React.useState(
  //   new moment()
  //     .add("month", 1)
  //     .set("date", 1)
  //     .set("hour", 23)
  //     .set("minute", 59)
  //     .add("days", -1)
  //     .toISOString(true)
  // );
  const [filtersOpen, setFiltersOpen] = React.useState(true);
  const [filterProps, setFilterProps] = React.useState({
    companyIds: [],
    departmentIds: [],
    regionIds: [],
    townIds: [],
    startDate: null,
    endDate: null,
    departmentTypes: [],
  });

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          chartsRef={chartsRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      companyIds: [],
      departmentIds: [],
      regionIds: [],
      townIds: [],
      startDate: null,
      endDate: null,
      departmentTypes: [],
    });
  };

  const init = useCallback(async () => {
    setBackdropLoading(true);
    if (authorization.view) {
      const options = await GetOptions();
      setOptions(options);
    }
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  if (authorization.view) {
    if (options.companies && Array.isArray(options.companies)) {
      return (
        <div className={classes.root}>
          <TableBackdrop backdropLoading={backdropLoading} />
          <CssBaseline />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <CustomSnackbar
                snackbar={snackbar}
                setSnackbar={setSnackbar}
                snackbarMessage={snackbarMessage}
                severity={severity}
              />
              {value !== 5 && <RenderFilters />}
              {filterProps.startDate && filterProps.endDate && (
                <DashboardTabs
                  setBackdropLoading={setBackdropLoading}
                  filterProps={filterProps}
                  value={value}
                  setValue={setValue}
                />
              )}
              {/* <Grid container spacing={3} justifyContent="space-between">
              <DateSettings
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <ExportButton setBackdropLoading={setBackdropLoading} />

              <Charts
                startDate={startDate}
                endDate={endDate}
                setBackdropLoading={setBackdropLoading}
              />
            </Grid> */}
            </Container>
            <Box pt={4} className={classes.copyright}>
              <Copyright
                string={"DELTA Smart Technologies"}
                url={"https://deltasmart.tech/en/home/"}
                date={new Date().getFullYear()}
              />
            </Box>
          </main>
        </div>
      );
    } else {
      return <TableBackdrop backdropLoading={backdropLoading} />;
    }
  } else {
    return (
      <div className={classes.root}>
        <TableBackdrop backdropLoading={backdropLoading} />
        <CssBaseline />
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <CustomSnackbar
              snackbar={snackbar}
              setSnackbar={setSnackbar}
              snackbarMessage={snackbarMessage}
              severity={severity}
            />
          </Container>
          <Box pt={4} className={classes.copyright}>
            <Copyright
              string={"DELTA Smart Technologies"}
              url={"https://deltasmart.tech/en/home/"}
              date={new Date().getFullYear()}
            />
          </Box>
        </main>
      </div>
    );
  }
}
