import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import TableEntryModal from "../../../../../components/Modal/TableEntryModal";
import { TextArea } from "../../../../../components/Fields/TextFields";
import { CustomDatePicker } from "../../../../../components/Selectors/DatePickers";
import moment from "moment";
import Request from "../../../../../helpers/Request";
import showSnackbar from "../../../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../../../helpers/LanguageHelper";
import { getKKDs } from "../../../../kkdPages/kkdDebt/GetOptions";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  kkdId: null,
  kkdSerialNo: null,
  productionDate: null,
  givenDate: null,
};

export default function KKDModal({
  id,
  kkdProps,
  kkdKinds,
  setKKDModal,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  update,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");

  const [props, setProps] = React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [kkds, setKKDs] = React.useState([]);
  const [kkdKind, setKKDKind] = React.useState(null);
  const [errors, setErrors] = React.useState({
    givenDateError: null,
  });

  const init = useCallback(async () => {
    if (kkdProps && id) {
      setProps(kkdProps);
      if (kkdProps?.kkdKind) {
        setKKDKind(kkdProps?.kkdKind);
        setKKDs(await getKKDs(kkdProps.kkdKind?.id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkdProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      givenDateError: null,
    };
    if (!props?.givenDate) {
      errors.givenDateError = errorMessage;
      error = true;
    }

    setErrors(errors);

    if (!error) {
      submitNewKKD();
      // if(isNewKKD) {
      //   submitNewKKD();
      // } else {
      //   updateKKD()
      // }
    }
  };

  const submitNewKKD = async () => {
    setLoading(true);
    props.userId = id;
    const resp = await Request("post", "/api/kkd-debits", props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      null,
      update
    );
    handleCloseModal();
    setLoading(false);
  };

  // const updateKKD = async () => {
  //   setLoading(true);
  //   props.userId = id;
  //   const resp = await Request("patch", '/api/kkd-debits', props);
  //   console.log(resp);
  //   showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, null, update);
  //   handleCloseModal();
  //   setLoading(false);
  // }

  const handleCloseModal = () => {
    setProps(propsDefault);
    setKKDKind(null);
    setKKDModal(null);
    setErrors({
      givenDateError: null,
    });
  };

  return (
    <TableEntryModal
      modal={kkdProps ? true : false}
      handleCloseModal={handleCloseModal}
    >
      <div className={classes.formControl}>
        <Autocomplete //KKD KIND
          id="combo-box"
          options={kkdKinds?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={kkdKind}
          onChange={async (event, value) => {
            setKKDKind(value);
            setKKDs(await getKKDs(value?.id));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="KKD Tipi"
              className={classes.textField}
            />
          )}
        />
        <Autocomplete //KKD
          id="combo-box"
          options={kkds?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!kkdKind}
          value={props.kkdId ? getFromId(props.kkdId, kkds) : null}
          onChange={async (event, value) => {
            setProps({ ...props, kkdId: value?.id });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="KKD"
              className={classes.textField}
            />
          )}
        />
        <TextArea
          required
          label={"KKD Seri No"}
          value={props.kkdSerialNo}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              kkdSerialNo: value.target.value,
            })
          }
          style={classes.textField}
        />
        <CustomDatePicker
          required
          error={errors.givenDateError}
          style={classes.textField}
          label="Veriliş Tarihi"
          value={props.givenDate ? moment(props.givenDate) : null}
          shrink={props.givenDate}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              givenDate: moment(value)
                .set("hour", 0)
                .set("minute", 0)
                .toISOString(true),
            });
            errors.givenDateError &&
              setErrors({ ...errors, givenDateError: null });
          }}
        />
        <CustomDatePicker
          style={classes.textField}
          label="Üretim Tarihi"
          value={props.productionDate ? moment(props.productionDate) : null}
          shrink={props.productionDate}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              productionDate: moment(value)
                .set("hour", 0)
                .set("minute", 0)
                .toISOString(true),
            })
          }
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {"OLUŞTUR"}
            </Button>
          )}
        </div>
      </div>
    </TableEntryModal>
  );
}
