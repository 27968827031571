import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import showSnackbar from "../../components/Utils/showSnackbar";
import LanguageHelper from "../../helpers/LanguageHelper";
import Request from "../../helpers/Request";
import { TextArea, TextSelector } from "../../components/Fields/TextFields";
import jsonMap from "../../components/Utils/jsonMap";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
}));

const type = {
  PRIVATE: "Private",
  PUBLIC: "Public",
};

export default function ModalForm({
  noticeProps,
  setNoticeProps,
  label,
  loading,
  setLoading,
  handleCloseModal,
  oldNoticeProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  /**
   * Request to replace the old data with the new data.
   */
  const updateNotice = async () => {
    setLoading(true);
    const resp = await Request(
      "patch",
      "/api/notice/",
      oldNoticeProps.id,
      noticeProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const submitNewNotice = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/notice", null, noticeProps);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.created,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        shrink={noticeProps.text}
        label={"Text"}
        required
        value={noticeProps.text}
        style={classes.textField}
        onChangeFunc={(value) => {
          setNoticeProps({ ...noticeProps, text: value.target.value });
        }}
      />
      <TextSelector
        label="Tipi"
        value={noticeProps.type}
        shrink={noticeProps.type !== null}
        onChangeFunc={(value) =>
          setNoticeProps({
            ...noticeProps,
            type: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap(false, type)}
      />
      <div className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              oldNoticeProps ? updateNotice() : submitNewNotice()
            }
            className={classes.submit}
          >
            {label}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
