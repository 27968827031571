import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { getDepartments } from "./GetOptions";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  deptsList: {
    maxHeight: 180,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5,
  },
  belongingDepartments: {
    marginTop: -5,
    color: "#B8B8B8",
  },
  textField: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%",
  },
}));

export default function BelongingDepartmentList({
  oldDepartmentProps,
  newDepartmentProps,
  setNewDepartmentProps,
  options,
  collator,
  setChangeMade,
  belongingDepartmentError,
  setBelongingDepartmentError,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [update, setUpdate] = React.useState(false);
  const [deptDropdown, setDeptDropdown] = React.useState([]);
  const [belongingDepartmentsLookup, setBelongingDeparmentsLookup] =
    React.useState({});
  const [companyForDeptDropdown, setCompanyForDeptDropdown] =
    React.useState(null);
  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const init = useCallback(async () => {
    if (oldDepartmentProps) {
      let temp = belongingDepartmentsLookup;
      const depts = oldDepartmentProps.belongingDepartments
        ? oldDepartmentProps.belongingDepartments
        : oldDepartmentProps.parentDepartments;
      for (let index in depts) {
        const department = depts[index];
        temp[department.id] = {
          name: department?.name,
          companyName: department?.company?.nameShort,
        };
      }
      setBelongingDeparmentsLookup(temp);
    }
    setUpdate(!update);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setBelongingDeparmentsLookup,
    belongingDepartmentsLookup,
    oldDepartmentProps,
  ]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const RenderList = useCallback(() => {
    return (
      <>
        {newDepartmentProps.parentDepartmentIds.length > 0 ? (
          <List className={classes.deptsList}>
            {newDepartmentProps.parentDepartmentIds.map((data, index) => {
              return (
                <ListItem key={index}>
                  <IconTooltipButton
                    title={language.form.delete}
                    onClick={() => {
                      let belongingDepartments = [
                        ...newDepartmentProps.parentDepartmentIds,
                      ];
                      belongingDepartments.splice(index, 1);
                      setNewDepartmentProps({
                        ...newDepartmentProps,
                        parentDepartmentIds: belongingDepartments,
                      });
                      setChangeMade && setChangeMade(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconTooltipButton>
                  <ListItemText
                    secondary={
                      belongingDepartmentsLookup[data]?.name +
                      " (" +
                      belongingDepartmentsLookup[data]?.companyName +
                      ")"
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <div className={classes.deptsList} style={{ textAlign: "center" }}>
            <p style={{ color: "red", fontSize: 14, marginLeft: "auto" }}>
              Bağlı olduğu bölüm yok.
            </p>
          </div>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    belongingDepartmentsLookup,
    newDepartmentProps.parentDepartmentIds,
    classes,
    language,
  ]);

  return (
    <div style={{ textAlign: "left" }}>
      <h5 className={classes.belongingDepartments}>Bağlı Olduğu Bölümler</h5>
      {/* {belongingDepartmentError && (
        <div style={{ color: "red", fontSize: 15 }}>
          {belongingDepartmentError}
        </div>
      )} */}
      <RenderList />
      <div style={{ textAlign: "center" }}>
        <Autocomplete //COMPANY
          id="combo-box"
          options={options.companies?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={
            companyForDeptDropdown
              ? getFromId(options.companies, companyForDeptDropdown)
              : null
          }
          onChange={async (event, value) => {
            const temp = { ...belongingDepartmentsLookup };
            const depts = await getDepartments(value?.id);
            setCompanyForDeptDropdown(value?.id);
            setDeptDropdown(depts);
            for (let index in depts) {
              temp[depts[index]?.id] = {
                name: depts[index]?.name,
                companyName: depts[index]?.companyName,
              };
            }
            setBelongingDeparmentsLookup(temp);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={belongingDepartmentError}
              helperText={belongingDepartmentError}
              label="Şirket"
              className={classes.textField}
            />
          )}
        />
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={deptDropdown?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disabled={!companyForDeptDropdown}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if (reason === "reset") {
              setAutocompleteValue("");
            } else if (reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          //value={newDepartment ? getFromId(deptDropdown, newDepartment) : null}
          onChange={async (event, value) => {
            let belongingDepartments = [
              ...newDepartmentProps.parentDepartmentIds,
            ];
            belongingDepartments.push(parseInt(value?.id));
            belongingDepartmentError && setBelongingDepartmentError(null);
            setNewDepartmentProps({
              ...newDepartmentProps,
              parentDepartmentIds: belongingDepartments,
            });
            setChangeMade && setChangeMade(true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={belongingDepartmentError}
              helperText={belongingDepartmentError}
              label="Bağlayacak Bölüm Seç"
              className={classes.textField}
            />
          )}
        />
      </div>
    </div>
  );
}
