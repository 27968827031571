import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import KKDDebtModal from "./KKDDebtModal";
import DeleteModal from "./DeleteModal";
import GetFilterFields from "./KKDDebtFilters";
import moment from "moment";

export default function KKDDebtTable() {
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedKKDDebt, setSelectedKKDDebt] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    department: null,
    company: null,
    region: null,
    city: null,
    kkdType: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Kullanıcı", field: "userName" },
      { title: "Şirket", field: "company" },
      { title: "Bölüm", field: "department" },
      { title: "KKD", field: "kkdName" },
      { title: "KKD Seri No", field: "kkdSerialNo" },
      {
        title: "Veriliş Tarihi",
        field: "givenDate",
        render: (rowData) => moment(rowData.givenDate).format("DD-MM-YYYY"),
      },
      {
        title: "Üretim Tarihi",
        field: "productionDate",
        render: (rowData) =>
          moment(rowData.productionDate).format("DD-MM-YYYY"),
      },
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedKKDDebt(data || {});
    setModal(true);
  };

  const handleOpenDeleteModal = async (data) => {
    console.log(data);
    setDeleteModal(data?.id);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      department: null,
      company: null,
      region: null,
      city: null,
      kkdType: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteModal
        options={options}
        modal={deleteModal}
        setModal={setDeleteModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
      />
      <KKDDebtModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        opts={options}
        kkdDebtProps={selectedKKDDebt}
        setKKDDebtProps={setSelectedKKDDebt}
      />
      <RenderFilters />
      <Table
        height="65vh"
        tableName={"Zimmetler"}
        authName="kkdDebt"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
        isDeletable={() => true}
        isEditable={(user, rowData) => rowData.crewId !== null}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        //rowStyle={rowData => ({backgroundColor: !rowData.active && "#804f4f"})}
      />
    </>
  );
}
