import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../../components/Table/TableFilters";
import { CustomDatePicker } from '../../../../components/Selectors/DatePickers';
import moment from "moment";
import { getDepartments } from './GetOptions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters 
 */
 export default function GetFilterFields({
  options,
  tableRef,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [departments, setDepartments] = React.useState([]);

  return(
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.companies?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.filterCompany}
                onChange={async (event, value) => {
                  setTableFilters({...tableFilters, filterCompany: value});
                  setDepartments(await getDepartments(value?.id));
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Şirket" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={departments?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.filterDepartment}
                onChange={async (event, value) => {
                  setTableFilters({...tableFilters, filterDepartment: value});
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Bölüm" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Planlanma Tarihi Başlangıç" 
                value={tableFilters.filterPlannedStart ? moment(tableFilters.filterPlannedStart) : null} 
                shrink={tableFilters.filterPlannedStart} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterPlannedStart: moment(value).set("hour", 0).set("minute", 0).toISOString(true)})}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Planlanma Tarihi Bitiş" 
                value={tableFilters.filterPlannedEnd ? moment(tableFilters.filterPlannedEnd) : null} 
                shrink={tableFilters.filterPlannedEnd} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterPlannedEnd: moment(value).set("hour", 23).set("minute", 59).toISOString(true)})}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Onaylanma Tarihi Başlangıç" 
                value={tableFilters.filterValidationStart ? moment(tableFilters.filterValidationStart) : null} 
                shrink={tableFilters.filterValidationStart} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterValidationStart: moment(value).set("hour", 0).set("minute", 0).toISOString(true)})}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Onaylanma Tarihi Bitiş" 
                value={tableFilters.filterValidationEnd ? moment(tableFilters.filterValidationEnd) : null} 
                shrink={tableFilters.filterValidationEnd} 
                onChangeFunc={value => setTableFilters({...tableFilters, filterValidationEnd: moment(value).set("hour", 23).set("minute", 59).toISOString(true)})}
              />
            </Grid>
          </Grid> 
  
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  )
}