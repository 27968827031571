import React from 'react';
import DrillActionTable from './DrillActionTable';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { tableContainerStyles } from '../../../../assets/styles/tableContainer';
import UnauthorizedPage from '../../../UnauthorizedPage';
import SessionHelper from '../../../../helpers/SessionHelper';
import { getAuthorizationForPage } from '../../../../helpers/AuthorizationHelper';

export default function DrillActionsPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "drillActionTable");

  return (
    <Container maxWidth={false} style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <DrillActionTable/> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}