import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../assets/styles/tableContainer";
import { TextArea } from "../../components/Fields/TextFields";
import FormList from "./FormList";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestAuditTypesProps,
  auditTypesProps,
  setRequestAuditTypesProps,
  submitNewAuditType,
  updateAuditType,
  loading,
  options,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={language.inspectionType.name}
        value={requestAuditTypesProps.name}
        onChangeFunc={(value) =>
          setRequestAuditTypesProps({
            ...requestAuditTypesProps,
            name: value.target.value,
          })
        }
        style={classes.textField}
      />
        {options.forms && (
            <FormList 
            required
            props={requestAuditTypesProps}
            setProps={setRequestAuditTypesProps}
            options={options}
            />
        )

        }
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              auditTypesProps ? updateAuditType() : submitNewAuditType()
            }
            className={classes.submit}
          >
            {language.modal.update}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
