import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetFilterFields from "./SignInFilters";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { succession, platform } from "../../../assets/constants";

export default function SignInHistoryTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    userId: null,
    isSuccessful: '',
    platform: '',
    createdAtStart: null,
    createdAtEnd: null,
  });

  const init = useCallback(async () => {
    setColumns([
      { title: language.history.id, field: "id" },
      { title: "Email", field: "email" },
      { title: language.history.info, field: "info", cellStyle: {
        width: "20%"
      } },
      { title: language.history.userId, field: "userId" },
      { title: language.history.fName, field: "fName" },
      { title: language.history.lName, field: "lName" },
      { title: language.history.companyName, field: "companyName" },
      { title: language.history.tfaType, field: "tfaType" },
      { title: language.history.createdAt, field: "createdAt" },
      { title: language.history.lastModifiedAt, field: "lastModifiedAt" },
      { title: language.history.signedInAt, field: "signedInAt" },
      { title: "IP Address", field: "ipAddress" },
      {
        title: "Sign-in Succession Status",
        field: "successful",
        lookup: succession
      },
      { title: "platform", field: "platform", lookup: platform },
    ]);
    setBackdropLoading(false);
  }, [language]);
  
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      userId: null,
      isSuccessful: '',
      platform: '',
      createdAtStart: null,
      createdAtEnd: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noRowActions
        tableName={language.history.signIn}
        authName="signInHistory"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
