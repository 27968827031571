import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { LabelList, Scatter } from "recharts";
import JointLineScatterChart from "../../../components/Chart/JointLineScatterChart";
import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import TableBackdrop from "../../../components/Table/TableBackdrop";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red"];
const fixedHeight = 500;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  footerComponent: {
    display: "flex",
    flexDirection: "column",
  },
  dateFooter: {
    marginTop: 30,
    marginLeft: 0,
    marginRight: "auto",
  },
}));

/**
 * A dashboard component that renders charts depending on the role of the user.
 */
export default function ToolboxScoreScatterChart({
  filters,
  setBackdropLoading,
  backdropLoading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [tbScore, setTbScore] = React.useState(null);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    var params = new URLSearchParams();
    for (let department of filters.departmentIds) {
      params.append("departmentIds", department.id);
    }
    for (let region of filters.regionIds) {
      params.append("regionIds", region.id);
    }
    for (let month of filters.months) {
      params.append("months", new moment(month).format("YYYY-MM-DD"));
    }

    const resp = await Request(
      "get",
      "/api/isg-performance-report/tb-score",
      null,
      params
    );
    console.log(resp);

    setTbScore(formatDataForScatterCharts(resp.data));
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);
  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Formats the data array to use with the chart.
   * @param {data: obj[]} data chart data to format
   * @return {obj[]} formatted data
   */
  const formatDataForScatterCharts = (data) => {
    let chartData = [];
    let allmonths = [];
    // format filter's month for comparing
    for (let date of filters.months) {
      let formattedDate = new moment(date).format("YYYY-MM");
      allmonths.push(formattedDate);
    }
    for (let p in data) {
      let department = "";
      let month;
      let year;
      let row = {};
      for (let prop in data[p]) {
        if (prop === "toolboxScore") {
          row[language.contractorToolbox.tbScore] = data[p][prop];
          if (data[p][prop] === null) {
            row[language.contractorToolbox.tbScore] = 0;
          }
        } else if (prop === "department") {
          department = data[p][prop];
        } else if (prop === "month") {
          month = data[p][prop];
        } else if (prop === "year") {
          year = data[p][prop];
        }
      }
      row.name = department;
      row.months = new moment([year, month - 1]).format("YYYY-MM");
      chartData.push(row);
      // add new empty data if there is no accident at given month
      // Example: user checks if there is accident at October,
      // if there is not, then give empty data for October
      for (let month of allmonths) {
        let formattedDate = new moment(month, "YYYY-MM");
        var index = data.findIndex(
          (x) =>
            x.department == department &&
            x.year == formattedDate.year() &&
            x.month == formattedDate.month() + 1
        );
        var otherIndex = chartData.findIndex(
          (x) => x.name == department && x.months == month
        );
        // give empty data
        index === -1 &&
          otherIndex === -1 &&
          chartData.push({
            name: department,
            months: month,
            [language.contractorToolbox.tbScore]: 0,
          });
      }
    }
    // sort last data
    chartData.sort(function (a, b) {
      // check if name is equal
      if (a.name === b.name) {
        // sort months ascending
        return (
          new moment(a.months, "YYYY-MM").diff() -
          new moment(b.months, "YYYY-MM").diff()
        );
      }
      // if name is different then sort it
      return a.name > b.name ? 1 : -1;
    });
    return chartData;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, offset } = props;

    const xOffset = width / 2 + offset - 1;
    const yOffset = height / 2 + 5;

    return (
      <text
        x={x + xOffset}
        y={y + yOffset - 10}
        fill={"black"}
        textAnchor="end"
      >
        {value}
      </text>
    );
  };

  function getRandomColor() {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function groupBy(array, property) {
    var hash = {};
    for (var i = 0; i < array.length; i++) {
      if (!hash[array[i][property]]) hash[array[i][property]] = [];
      hash[array[i][property]].push(array[i]);
    }
    return hash;
  }

  const drawCharts = (data) => {
    const charts = [];
    let scatterData = groupBy(data, "name");
    for (let department in scatterData) {
      charts.push(
        <Scatter
          name={scatterData[department][0].name}
          data={scatterData[department]}
          dataKey={language.contractorToolbox.tbScore}
          fill={getRandomColor()}
          line
        >
          <LabelList
            dataKey={language.contractorToolbox.tbScore}
            position="center"
            content={renderCustomizedLabel}
            style={{ fill: "white" }}
          />
        </Scatter>
      );
    }
    return charts;
  };

  if (tbScore) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={fixedHeightPaper}>
            <JointLineScatterChart
              dataSet={tbScore}
              title={language.contractorToolbox.scatterChartTitle}
            >
              {drawCharts(tbScore)}
            </JointLineScatterChart>
          </Paper>
        </Grid>
      </Grid>
    );
  } else {
    return <TableBackdrop backdropLoading={backdropLoading} />;
  }
}
