import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function AccidentPlaceModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  accidentPlaceProps,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestAccidentPlaceProps, setRequestAccidentPlaceProps] = React.useState(
    {}
  );
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setRequestAccidentPlaceProps({
      name: accidentPlaceProps ? accidentPlaceProps.name : null,
      "selection-type": accidentPlaceProps ? "AccidentPlace" : null,
      active: accidentPlaceProps ? accidentPlaceProps.active : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accidentPlaceProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestAccidentPlaceProps({
      name: null,
      "selection-type": null,
    });
  };

  const submitNewAccidentPlace = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/selections/add",
      null,
      requestAccidentPlaceProps
    );
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateAccidentPlace = async () => {
    setLoading(true);
    let props = { ...requestAccidentPlaceProps };
    props.id = accidentPlaceProps.id;
    const resp = await Request(
      "patch",
      "/api/selections/update/" + props.id,
      null,
      props
    );
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestAccidentPlaceProps={requestAccidentPlaceProps}
              accidentPlaceProps={accidentPlaceProps}
              setRequestAccidentPlaceProps={setRequestAccidentPlaceProps}
              submitNewAccidentPlace={submitNewAccidentPlace}
              updateAccidentPlace={updateAccidentPlace}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
