import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl, TextField } from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from '../../../components/Modal/TableEntryModal';
import Request from "../../../helpers/Request";
import showSnackbar from '../../../components/Utils/showSnackbar';
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from 'moment';
import { TextSelector, TextArea } from '../../../components/Fields/TextFields';
import { CustomDatePicker } from '../../../components/Selectors/DatePickers';
import { getDepartments, getUsers } from './GetOptions';
import jsonMap from '../../../components/Utils/jsonMap';
import RiskEvaluationTeam from './RiskEvaluationTeam';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: 'pre-wrap'
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20
  }
}));

const propsDefault = {
  analysisNo: null,
  companyId: null,
  departmentId: null,
  updateDate: null,
  validationDate: null,
  completionDate: null,
  avgPriority: null,
  avgRemainingScore: null,
  avgRemainingPriority: null,
  avgRiskScore: null,
  riskEvaluationMethod: null,
  riskEvaluationTeam: [],
}

export default function AnalysisModal({analysisProps, setAnalysisProps, options, setOptions, setSeverity, setSnackbarMessage, setSnackbar, tableRef}) {
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const manager =
  user.roles.includes("ROLE_ISG_UZMANI") ||
  user.roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
  user.roles.includes("ROLE_OSGB_ISG_UZMANI");
  const isAdmin = user.roles.includes("ROLE_SİSTEM_ADMİNİ");
  const isNewAnalysis = analysisProps && Object.keys(analysisProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState({
    ...propsDefault,
    companyId: user?.companyId,
    departmentId: user?.department?.id,
  });
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({
    companyId: null,
    departmentId: null,
  });

  const init = useCallback(async () => {
    setModalLoading(true);
    if(!isNewAnalysis && analysisProps) {
      setProps({...analysisProps});
    }
    setModalLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, analysisProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      companyId: null,
      departmentId: null,
    };
    if(!props.companyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if(!props.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    setErrors(errors);

    if(!error) {
      isNewAnalysis ? submitNewAnalysis() : updateAnalysis();
    }
  }

  const submitNewAnalysis = async () => {
    setLoading(true);
    props.riskEvaluationTeam = props?.riskEvaluationTeam.map((prt) => prt.id);
    const resp = await Request("post", '/api/riskAnalysis', props);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  const updateAnalysis = async () => {
    setLoading(true);
    const riskEvaluationTeam = props?.riskEvaluationTeam.map((prt) => prt.id);
    const body = (({ analysisNo, companyId, departmentId, updateDate, 
      validationDate, completionDate, avgPriority, avgRemainingScore, 
      avgRemainingPriority, avgRiskScore, riskEvaluationMethod }) => ({ 
        analysisNo, companyId, departmentId, updateDate, validationDate, 
        completionDate, avgPriority, avgRemainingScore, avgRemainingPriority, 
        avgRiskScore, riskEvaluationMethod }))(props);
    body.riskEvaluationTeam = riskEvaluationTeam;
    body.analysisId = analysisProps.id; 
    body.risks = analysisProps.risks; 
    const resp = await Request("patch", '/api/riskAnalysis', body);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  const handleCloseModal = () => {
    setProps(propsDefault);
    setAnalysisProps(null);
  }

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <TableEntryModal
      modal={analysisProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
      >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <TextArea
          shrink={props.analysisNo}
          label={"Analiz No"}
          required
          error={errors.analysisNo}
          value={props.analysisNo}
          onChangeFunc={value => {
            let val = value.target.value;
            val = val.replace(/\s/g, "").replace(/\D/g, "");
            setProps({...props, analysisNo: val});
            errors.analysisNo && setErrors({...errors, analysisNo: null});
          }}
          style={classes.textField}
        />
        {(manager || isAdmin) && (
          <Autocomplete //COMPANY
            id="combo-box"
            options={options.companies?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.companyId
                ? getFromId(props.companyId, options.companies)
                : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, companyId: null });
                setOptions({
                  ...options,
                  departments: [],
                  users: [],
                  instructors: [],
                });
              } else {
                setProps({ ...props, companyId: value?.id });
                const [depts, users] = await Promise.all([
                  getDepartments(value?.id),
                  getUsers(value?.id)
                ]);
                setOptions({
                  ...options,
                  departments: depts,
                  users: users,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                error={errors.companyId}
                helperText={errors.companyId}
                label="Şirket"
                className={classes.textField}
              />
            )}
          />
        )}
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!props.companyId}
          value={
            props.departmentId
              ? getFromId(props.departmentId, options.departments)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, departmentId: null });
            } else {
              setProps({ ...props, departmentId: value?.id });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={errors.departmentId}
              helperText={errors.departmentId}
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
        <TextSelector //RISK EVALUATION METHOD
          label={"Risk Değerlendirme Metodu"}
          required
          error={errors.riskEvaluationMethod}
          value={props?.riskEvaluationMethod}
          shrink={props?.riskEvaluationMethod}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, riskEvaluationMethod: value.target.value });
            errors.riskEvaluationMethod && setErrors({ ...errors, riskEvaluationMethod: null });
          }}
          options={jsonMap("methods", options)}
        />
        <CustomDatePicker //UPDATE DATE
          error={errors.updateDate}
          required
          style={classes.dateField}
          label={"Güncellenme Tarihi"}
          value={props?.updateDate}
          shrink={props?.updateDate}
          onChangeFunc={(value) => {
            setProps({...props, updateDate: moment(value).toISOString(true)});
            errors.updateDate && setErrors({ ...errors, updateDate: null });
          }}
        />
        <CustomDatePicker //VALIDATION DATE
          error={errors.validationDate}
          required
          style={classes.dateField}
          label={"Onaylanma Tarihi"}
          value={props?.validationDate}
          shrink={props?.validationDate}
          onChangeFunc={(value) => {
            setProps({...props, validationDate: moment(value).toISOString(true)});
            errors.validationDate && setErrors({ ...errors, validationDate: null });
          }}
        />
        <CustomDatePicker //COMPLETION DATE
          error={errors.completionDate}
          required
          style={classes.dateField}
          label={"Tamamlanma Tarihi"}
          value={props?.completionDate}
          shrink={props?.completionDate}
          onChangeFunc={(value) => {
            setProps({...props, completionDate: moment(value).toISOString(true)});
            errors.completionDate && setErrors({ ...errors, completionDate: null });
          }}
        />
        <TextArea
          shrink={props.avgPriority}
          required
          label={"Önem Derecesi Ortalaması"}
          error={errors.avgPriority}
          value={props.avgPriority}
          onChangeFunc={value => {
            let val = value.target.value;
            val = val.replace(/[^0-9.]/g, "");
            setProps({...props, avgPriority: val});
            errors.avgPriority && setErrors({...errors, avgPriority: null});
          }}
          style={classes.textField}
        />
        <TextArea
          shrink={props.avgRemainingScore}
          required
          label={"Kalan Risk Skoru Ortalaması"}
          error={errors.avgRemainingScore}
          value={props.avgRemainingScore}
          onChangeFunc={value => {
            let val = value.target.value;
            val = val.replace(/[^0-9.]/g, "");
            setProps({...props, avgRemainingScore: val});
            errors.avgRemainingScore && setErrors({...errors, avgRemainingScore: null});
          }}
          style={classes.textField}
        />
        <TextArea
          shrink={props.avgRiskScore}
          required
          label={"Risk Skoru Ortalaması"}
          error={errors.avgRiskScore}
          value={props.avgRiskScore}
          onChangeFunc={value => {
            let val = value.target.value;
            val = val.replace(/[^0-9.]/g, "");
            setProps({...props, avgRiskScore: val});
            errors.avgRiskScore && setErrors({...errors, avgRiskScore: null});
          }}
          style={classes.textField}
        />
        <TextArea
          shrink={props.avgRemainingPriority}
          required
          label={"Kalan Önem Derecesi Ortalaması"}
          error={errors.avgRemainingPriority}
          value={props.avgRemainingPriority}
          onChangeFunc={value => {
            let val = value.target.value;
            val = val.replace(/[^0-9.]/g, "");
            setProps({...props, avgRemainingPriority: val});
            errors.avgRemainingPriority && setErrors({...errors, avgRemainingPriority: null});
          }}
          style={classes.textField}
        />
        {options.users && (
          <RiskEvaluationTeam
            props={props}
            setProps={setProps}
            options={options}
          />
        )}
        <div style={{paddingBottom: 20, textAlign: "center"}}>
          {loading ?
          <CircularProgress color="secondary"/>
          :
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => onButtonClick()}
            className={classes.submit}>
            {!isNewAnalysis ? "GÜNCELLE" : "OLUŞTUR"}
          </Button>}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}