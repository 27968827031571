import React, { useCallback } from "react";
import SessionHelper from "../../../../helpers/SessionHelper";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import GetOptions from "./GetOptions";
import NewTaskModal from "./NewTaskModal";
import { getAuthorizationForPage } from "../../../../helpers/AuthorizationHelper";
import { getCompaniesAndDepartments } from "./GetOptions";
import Request from "../../../../helpers/Request";
import CustomSnackbar from "../../../../components/Snackbar/Snackbar";
import UnsuitabilityButton from "../../taskspage/components/UnsuitabilityButton";
import Table from "../../../../components/Table/Table";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import GetFilterFields from "./TasksFilters";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../../components/Dialog/DeleteDialog";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { GDZ_EYAŞ } from "../../../../assets/constants";

/**
 * A table component that lists the inspection tasks. The details panel contains the files and forms of
 * the inspections.
 */
export default function TasksTable() {
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "tasksTable");
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [futureTasks, setFutureTasks] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [oldTask, setOldTask] = React.useState(null);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [modalLoading, setModalLoading] = React.useState(false);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [idToDelete, setIdToDelete] = React.useState(null);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [companyDisabled, setCompanyDisabled] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    creationStartDate: null,
    creationEndDate: null,
    completionStartDate: null,
    completionEndDate: null,
    company: null,
    department: null,
    town: null,
    userName: null,
    role: "",
    status: "",
    personal: false,
  });

  const init = useCallback(async () => {
    let options = await GetOptions();
    setOptions(options);
    checkEyasIsgRole();
    setColumns([
      { title: language.tableColumns.id, field: "id", editable: "never" },
      { title: "Görev No", field: "customId", editable: "never" },
      { title: "Görev Atanan Kişi", field: "fullName" },
      { title: "Görevi Atayan Kişi", field: "givenByName" },
      { title: "Gözetmen", field: "supervisorName" },
      {
        title: language.tableColumns.inspectionType,
        field: "inspectionType",
        lookup: options.inspectionTypes,
      },
      {
        title: language.tableColumns.unsuitability,
        field: "unsuitability",
        render: (rowData) => {
          console.log(rowData);
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <label style={{ marginRight: "20px" }}>
                {rowData.unsuitability}
              </label>
              {/* <UnsuitabilityButton rowData={rowData} /> */}
            </div>
          );
        },
      },
      { title: "Atanma Tarihi", field: "createdAt", type: "date" },
      { title: "Başlangıç Tarihi", field: "startTime", type: "date" },
      {
        title: language.tableColumns.inspectionDate,
        field: "completionDate",
        type: "date",
      },
      {
        title: language.tableColumns.terminationDate,
        field: "terminationTime",
        type: "date",
      },
      { title: language.tableColumns.company, field: "companyName" },
      { title: language.tableColumns.department, field: "departmentName" },
      { title: "Faaliyet Bölgesi", field: "cityTownName" },
      { title: "Skor", field: "score" },
      {
        title: "Açıklama",
        field: "text",
        render: (rowData) => (
          <div style={{ maxHeight: 150, width: 175, overflow: "auto" }}>
            {rowData.text}
          </div>
        ),
      },
      {
        title: language.tableColumns.status,
        field: "eStatus",
        lookup: options.taskStatuses,
      },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModal(true);
    setModalLoading(true);
    setOldTask(data);
    let newOpts = options;
    newOpts.compsAndDepts = data
      ? await getCompaniesAndDepartments(data.user)
      : null;
    setOptions(newOpts);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const removeTask = async () => {
    setLoading(true);
    const resp = await Request("delete", "/api/inspections/" + idToDelete);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setLoading(false);
  };

  const removeTasks = async () => {
    setLoading(true);
    const param = {
      ids: idsToDelete.join(),
    };
    const resp = await Request("delete", "/api/inspections/", null, param);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setLoading(false);
  };

  const checkEyasIsgRole = () => {
    if (roles.includes(GDZ_EYAŞ.isgSpecialistName)) {
      setFilterProps({
        ...filterProps,
        company: { name: GDZ_EYAŞ.companyName },
      });
      setCompanyDisabled(true);
    }
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
          companyDisabled={companyDisabled}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      creationStartDate: null,
      creationEndDate: null,
      completionStartDate: null,
      completionEndDate: null,
      company: companyDisabled ? filterProps.company : null,
      department: null,
      town: null,
      userName: null,
      role: "",
      status: "",
      personal: false,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <DeleteDialog
        remove={idsToDelete.length > 0 ? removeTasks : idToDelete && removeTask}
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
        customTitle={
          "Denetim görevine bağlı uygunsuzluk giderme görevi de silinecek, devam etmek istediğine emin misin?"
        }
      />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <NewTaskModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldTaskProps={oldTask}
        modalLoading={modalLoading}
        opts={options}
      />
      <RenderFilters />
      <Table
        tableName={"İSG Denetim Görevleri"}
        authName="tasksTable"
        columns={columns}
        tableRef={tableRef}
        headerComponents={
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={futureTasks}
                  onChange={() => {
                    setFutureTasks(!futureTasks);
                    tableRef.current && tableRef.current.onQueryChange();
                  }}
                  name="futureTasks"
                />
              }
              label="İleri Tarihli Görevleri Göster"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterProps.personal}
                  onChange={() => {
                    setFilterProps({
                      ...filterProps,
                      personal: !filterProps.personal,
                    });
                    tableRef.current && tableRef.current.onQueryChange();
                  }}
                  name="personalTasks"
                />
              }
              label="Kendi Denetim Görevlerimi Göster"
            />
          </>
        }
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        filters={filterProps}
        type={futureTasks}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        setSelectionIds={setIdsToDelete}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={(user, rowData) =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          (user.id === rowData.givenById &&
            authorization.delete &&
            rowData.eStatus !== "DONE")
        }
        detailsWindow={(rowData) => window.open("/task/" + rowData.id)}
      />
    </>
  );
}
