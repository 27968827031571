import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./TehcnicalTrainingReportFilters";
import LanguageHelper from "../../helpers/LanguageHelper";
import GetOptions from "./GetOptions";

export default function TechnicalTrainingReportTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [options, setOptions] = React.useState({});

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    company: null,
    department: null,
    name: null,
    surname: null,
    edStartTime: null,
    edEndTime: null,
    edStatus: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.technicalTrainingReport.id, field: "id" },
      { title: language.technicalTrainingReport.name, field: "name" },
      { title: language.technicalTrainingReport.surname, field: "surname" },
      { title: language.technicalTrainingReport.title, field: "title" },
      { title: language.technicalTrainingReport.company, field: "companyName" },
      {
        title: language.technicalTrainingReport.department,
        field: "departmentName",
      },
      { title: language.technicalTrainingReport.tcNo, field: "tcNo" },
      {
        title: language.technicalTrainingReport.educationStatus,
        field: "educationStatus",
      },
      {
        title: language.technicalTrainingReport.workStartDate,
        field: "startDateOfWork",
      },
      {
        title: language.technicalTrainingReport.technicalEducationStatus,
        field: "technicalEducationStatus",
      },
      {
        title: language.technicalTrainingReport.technicalTrainingName,
        field: "trainingName",
      },
      {
        title: language.technicalTrainingReport.trainingSource,
        field: "trainingSource",
      },
      {
        title: language.technicalTrainingReport.trainingDate,
        field: "trainingDate",
      },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      company: null,
      department: null,
      name: null,
      surname: null,
      edStartTime: null,
      edEndTime: null,
      edStatus: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noRowActions
        tableName={language.technicalTrainingReport.titleTable}
        authName="technicalTrainingReport"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
