import React from "react";
import { Collapse, Grid, makeStyles, Paper } from "@material-ui/core";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import jsonMap from "../../../components/Utils/jsonMap";
import { TextSelector, TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const classes = useStyles();

  const [backdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <TextSelector
                label="Atık Tipi"
                value={tableFilters.wasteTypeFilter}
                shrink={tableFilters.wasteTypeFilter}
                onChangeFunc={(value) => {
                  setTableFilters({
                    ...tableFilters,
                    wasteTypeFilter: value.target.value,
                  });
                }}
                style={classes.textField}
                options={jsonMap("wasteTypes", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Atık Birimi"
                value={tableFilters.wasteUnitFilter}
                shrink={tableFilters.wasteUnitFilter}
                onChangeFunc={(value) => {
                  setTableFilters({
                    ...tableFilters,
                    wasteUnitFilter: value.target.value,
                  });
                }}
                style={classes.textField}
                options={jsonMap("wasteUnits", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Bertaraf Yöntemi"
                value={tableFilters.disposalMethodFilter}
                shrink={tableFilters.disposalMethodFilter}
                onChangeFunc={(value) => {
                  setTableFilters({
                    ...tableFilters,
                    disposalMethodFilter: value.target.value,
                  });
                }}
                style={classes.textField}
                options={jsonMap("wasteDisposalMethods", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.yearFilter !== null}
                label={"Yılı"}
                value={tableFilters.yearFilter}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    yearFilter: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
