import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "33.4vw",
    overflow: "auto"
  },
  sgkContainer: {
    width: "95%",
    border: "1px solid #d5d5d5",
    borderRadius: "15px",
    margin: "0 auto",
    textAlign: "left",
    padding: 10,
    marginBottom: 10
  }
}));

export default function SGKLogs({logs}) {
  const classes = useStyles();

  const RenderLogs = () => {
    return(
      logs.map((data, index) => {
        return (
          <div key={index} className={classes.sgkContainer}>
            <p>{"Şirket: " + data.department?.company?.name}</p>
            <p>{"Yeni Bölüm SGK: " + (data.newSGKNo ? data.newSGKNo : "Yok")}</p>
            <p>{"Eski Bölüm SGK: " + (data.oldSGKNo ? data.oldSGKNo : "Yok")}</p>
          </div>
        )
      })
    )
  }

  return (
    <div className={classes.root}>
      <RenderLogs/>
    </div> 
  );
};