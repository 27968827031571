import React, { useCallback } from "react";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";

export default function KKDDebts({ rowData }) {
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    let cols = [
      { title: "No", field: "id", width: "60px" },
      { title: "KKD İsmi", field: "name" },
    ];
    setColumns(cols);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={"Zimmetler"}
        authName="crews"
        columns={columns}
        tableRef={tableRef}
        isEditHidden={true}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        data={rowData?.kkds}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        noRowActions
      />
    </>
  );
}
