import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: 600,
  },
  textField: {
    marginBottom: "20px",
  },
}));

export default function ModalForm({
  newAccidentProps,
  setNewAccidentProps,
  loading,
  options,
  submitNewAccident,
  updateAccident,
  oldAccidentProps,
}) {
  const classes = useStyles();

  const [datePicked] = React.useState(false);

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        label={"Elektrik Dağıtım Şirketi / Yüklenici Şirket"}
        value={newAccidentProps.edcOrContractorName}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            edcOrContractorName: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Şirket SGK Sicil No"}
        value={newAccidentProps.companySgkNo}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            companySgkNo: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"S/N"}
        value={newAccidentProps.sn}
        onChangeFunc={(value) =>
          setNewAccidentProps({ ...newAccidentProps, sn: value.target.value })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Kaza Geçiren Personel Adı Soyadı"}
        value={newAccidentProps.personnelName}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            personnelName: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Kaza Geçiren Personel SGK Sicil No"}
        value={newAccidentProps.personnelSgkNo}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            personnelSgkNo: value.target.value,
          })
        }
        style={classes.textField}
      />
      <CustomDatePicker
        style={classes.textField}
        label={"Kaza Tarihi"}
        value={
          datePicked
            ? moment(newAccidentProps.accidentDate)
            : newAccidentProps.accidentDate
        }
        shrink={newAccidentProps.accidentDate}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentDate: moment(value).toISOString(true),
          })
        }
      />
      <TextArea
        label={"Kaza Yılı"}
        value={newAccidentProps.year}
        onChangeFunc={(value) =>
          setNewAccidentProps({ ...newAccidentProps, year: value.target.value })
        }
        style={classes.textField}
      />
      <CustomDatePicker
        style={classes.textField}
        label={"İş Göremezlik Raporu Başlangıç Tarihi"}
        value={
          datePicked
            ? moment(newAccidentProps.incapacitationStart)
            : newAccidentProps.incapacitationStart
        }
        shrink={newAccidentProps.incapacitationStart}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            incapacitationStart: moment(value).toISOString(true),
          })
        }
      />
      <CustomDatePicker
        style={classes.textField}
        label={"İş Göremezlik Raporu Bitiş Tarihi"}
        value={
          datePicked
            ? moment(newAccidentProps.incapacitationEnd)
            : newAccidentProps.incapacitationEnd
        }
        shrink={newAccidentProps.incapacitationEnd}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            incapacitationEnd: moment(value).toISOString(true),
          })
        }
      />
      <TextSelector
        label={"İş Kazası Tipi"}
        value={newAccidentProps.accidentTypeEPDKId}
        style={classes.textField}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentTypeEPDKId: value.target.value,
          })
        }
        options={jsonMap("accidentTypes", options)}
      />
      <TextArea
        label={"Kayıp Uzuv (Varsa)"}
        value={newAccidentProps.lostLimb}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            lostLimb: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Kayıp Gün Süresi"}
        value={newAccidentProps.lostTimeInDays}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            lostTimeInDays: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextSelector
        label={"Kaza Sebebi"}
        value={newAccidentProps.accidentReasonEPDKId}
        style={classes.textField}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentReasonEPDKId: value.target.value,
          })
        }
        options={jsonMap("accidentReasons", options)}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={oldAccidentProps ? updateAccident : submitNewAccident}
            className={classes.submit}
          >
            {oldAccidentProps ? "GÜNCELLE" : "OLUŞTUR"}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
