import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

const useStyles = makeStyles(() => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210
  },
  dropzoneMargin: {
    margin: "20px",
  },
  fileListContainer: {
    margin: "0 auto"
  },
  fileListLabel: {
    margin: 30
  },
  label: {
    fontSize: 16
  }
}));

export default function Files({ files, setFiles, length, setLength, fileError, setFileError }) {
  const classes = useStyles();

  /**
   * Keeps the uploaded file(s) in a FormData object to upload when the button is clicked.
   * @param {obj} acceptedFiles 
   */
  const handleDrop = async (acceptedFiles) => {
    const formData = files;
    formData.set("files", acceptedFiles[0], acceptedFiles[0].name);
    /* for (let i = 0; i < acceptedFiles.length; i++) {
      formData.append("files", acceptedFiles[i], acceptedFiles[i].name);
    } */
    setLength(1);
    setFiles(formData);
    setFileError(null);
  }

  /**
   * Deletes the file from the FormData object.
   * @param {obj} file 
   */
  const handleDelete = async (file) => {
    setLength(length - 1);
    let formData = files;
    let newFormData = new FormData();
    let index = 0;

    for (let pair of files.entries()) {
      if (pair[1].name === file.name) {
        break;
      }
      index++;
    }

    let values = formData.getAll("file");
    values.splice(index, 1);
    for (let i = 0; i < values.length; i++) {
      newFormData.append("file", values[i]);
    }
    setFiles(newFormData);
  }

  return (
    <Grid container direction="row" alignItems="center">
      <div className={classes.dropzoneMargin} style={{color: fileError && "red"}}>
        <DropzoneArea
          dropzoneText={"SGK işten çıkış bildirgesini yüklemek için sürükleyin ya da buraya tıklayın."}
          filesLimit={1}
          maxFileSize={10000000}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          previewText="Seçili Dosya"
          onDrop={files => handleDrop(files)}
          onDelete={file => handleDelete(file)}
        />
      </div>
    </Grid>
  )
}