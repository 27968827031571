import React from 'react';
import moment from 'moment';
import 'moment/locale/tr';
import Request from '../../helpers/Request';
import FileSaver from 'file-saver';
import TableBackdrop from '../../components/Table/TableBackdrop';
import CustomSnackbar from '../../components/Snackbar/Snackbar';
import LanguageHelper from '../../helpers/LanguageHelper';
import SharedSettings from './SharedSettings';
import SessionHelper from '../../helpers/SessionHelper';
import { getAuthorizationForPage } from '../../helpers/AuthorizationHelper';
import UnauthorizedPage from '../UnauthorizedPage';

/**
 * A dashboard component that renders charts depending on the role of the user.
 */
export default function ContractorFieldInspection() {
  moment.locale("tr");
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const userRegion = user?.department?.town?.belongingRegion?.id;
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "contractorFieldInspection");

  const [isOldFormat, setOldFormat] = React.useState(false);
  const [contractors, setContractors] = React.useState([]);
  const [contractor, setContractor] = React.useState(-1);
  const [edcs, setEdcs] = React.useState([]);
  const [edc, setEdc] = React.useState(null);
  const [regions, setRegions] = React.useState([]);
  const [region, setRegion] = React.useState(user.roles.includes("ROLE_BÖLGE_MÜDÜRÜ") ? userRegion : -1);
  const [startDate, setStartDate] = React.useState(moment().add(-1, "month").set("hour", 0).set("minute", 0).toISOString(true));
  const [endDate, setEndDate] = React.useState(moment().set("hour", 23).set("minute", 59).toISOString(true));
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return "Rapor";
  };

  const getReport = async () => {
    setBackdropLoading(true);
    const param = {
      "end-date": endDate,
      "start-date": startDate
    }
    let resp;
    if(isOldFormat) {
      resp = await Request("get", "/api/report/export/" + contractor + "/" + region + "/saha-denetim", null, param);
    } else {
      resp = await Request("get", "/api/report/export/" + contractor + "/" + region + "/saha-denetim-new", null, param);
    }
    console.log(resp);
    if(resp.status !== 200) {
      if(resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
      if(contractor > -1 && region === -1) {
        FileSaver.saveAs(source, getFromId(contractor, contractors)?.name + "_" + moment(startDate).format("DD-MM-YYYY") + "_" + moment(endDate).format("DD-MM-YYYY") + "_Saha_Denetim_Raporu.xlsx");
      } else if(region > -1 && contractor === -1) {
        FileSaver.saveAs(source, getFromId(region, regions)?.name + "_" + moment(startDate).format("DD-MM-YYYY") + "_" + moment(endDate).format("DD-MM-YYYY") + "_Saha_Denetim_Raporu.xlsx");
      } else {
        FileSaver.saveAs(source, getFromId(contractor, contractors)?.name + "_" + getFromId(region, regions).name + "_" + moment(startDate).format("DD-MM-YYYY") + "_" + moment(endDate).format("DD-MM-YYYY") + "_Saha_Denetim_Raporu.xlsx");
      }
    }
    setBackdropLoading(false);
    return;
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      {authorization?.view ?
      <>
        <h2>Yüklenici Saha Denetim Raporları</h2>
        <SharedSettings
          getReport={getReport} 
          setBackdropLoading={setBackdropLoading} 
          edc={edc}
          setEdc={setEdc}
          contractor={contractor}
          setContractor={setContractor}
          region={region}
          setRegion={setRegion}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          contractors={contractors}
          setContractors={setContractors}
          edcs={edcs}
          setEdcs={setEdcs}
          regions={regions}
          setRegions={setRegions}
          isOldFormat={isOldFormat}
          setOldFormat={setOldFormat}
          departmentRequired
        />
      </>
      : <UnauthorizedPage/>}
    </>
  );
}
