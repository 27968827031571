import { makeStyles } from "@material-ui/core/styles";
import {
  TextArea,
  TextSelector,
} from "../../../../components/Fields/TextFields";
import { FormControl, TextField } from "@material-ui/core";
import { CustomDatePicker } from "../../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import SessionHelper from "../../../../helpers/SessionHelper";
import RoleList from "../../components/RoleList";
import RegionList from "../../components/RegionList";
import jsonMap from "../../../../components/Utils/jsonMap";
import { getDepartments, getManagers } from "../../components/GetOptions";
import moment from "moment";
import React from "react";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "40%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  textField: {
    marginBottom: "3vw",
    whiteSpace: "pre-wrap",
    textAlign: "left",
  },
  dateField: {
    marginBottom: "3vw",
    marginTop: 0,
  },
}));

export default function WorkDataForm({
  userProps,
  setUserProps,
  errors,
  setErrors,
  options,
  setOptions,
  setChangeMadeToProps,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const user = SessionHelper.getUser();
  const isAdmin = user.roles.includes("ROLE_SİSTEM_ADMİNİ");
  const edc = userProps?.companyType === "EDC";
  const [companyType, setCompanyType] = React.useState(userProps?.companyType);
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const expert =
    user.roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
    user.roles.includes("ROLE_ISG_UZMANI");
  const contractorIsg = user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI");
  const { titleError, hourOfWorkError, companyError, departmentError } = errors;

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  return (
    <FormControl required autoComplete="off" className={classes.formControl}>
      <TextArea //TITLE
        label={"Ünvanı"}
        error={titleError}
        value={userProps.title}
        onChangeFunc={(value) => {
          if (value.target.value.length > 250) {
            setErrors({
              ...errors,
              titleError: "Ünvan 250 karakterden uzun olamaz.",
            });
          } else {
            titleError && setErrors({ ...errors, titleError: null });
            const val = value.target.value;
            setUserProps({ ...userProps, title: val ? val : null });
            setChangeMadeToProps(true);
          }
        }}
        style={classes.textField}
      />
      {!specialContractor && (
        <Autocomplete //COMPANIES
          required
          id="combo-box"
          options={options.companies?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!isAdmin}
          value={
            userProps.companyId
              ? getFromId(userProps.companyId, options.companies)
              : null
          }
          onChange={async (event, value) => {
            companyError && setErrors({ ...errors, companyError: null });
            let newOpts = options;
            newOpts.departments = await getDepartments(value?.id);
            setOptions(newOpts);
            const val = value?.id;
            setUserProps({
              ...userProps,
              companyId: val ? val : null,
              roles: [],
            });
            setCompanyType(value?.type);
            setChangeMadeToProps(true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={companyError}
              required
              label="Şirketi"
              className={classes.textField}
            />
          )}
        />
      )}
      <Autocomplete //DEPARTMENT
        required
        id="combo-box"
        options={options.departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        disabled={!(isAdmin || (edc && expert) || contractorIsg)}
        fullWidth={true}
        value={
          userProps.departmentId
            ? getFromId(userProps.departmentId, options.departments)
            : null
        }
        onChange={async (event, value) => {
          departmentError && setErrors({ ...errors, departmentError: null });
          let newOpts = options;
          newOpts.managers = await getManagers(value?.id);
          setOptions(newOpts);
          const val = value?.id;
          setUserProps({ ...userProps, departmentId: val ? val : null });
          setChangeMadeToProps(true);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={departmentError}
            required
            label="Bölüm"
            className={classes.textField}
          />
        )}
      />
      {userProps.roles && (
        <RoleList //ROLES
          newUserProps={userProps}
          setNewUserProps={setUserProps}
          options={options}
          errors={errors}
          setErrors={setErrors}
          setChangeMadeToProps={setChangeMadeToProps}
          companyType={companyType}
          companies={options.companies}
        />
      )}
      <RegionList
        newUserProps={userProps}
        setNewUserProps={setUserProps}
        options={options}
        setChangeMadeToProps={setChangeMadeToProps}
      />
      <TextSelector //MANAGER
        label={"Yöneticisi"}
        value={userProps.managerId}
        disabled={!userProps.departmentId}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, managerId: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("managers", options)}
      />
      <TextArea //DAILY HOUR OF WORK
        label={"Günlük Çalışma Saati"}
        value={userProps.dailyHourOfWork}
        error={hourOfWorkError}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, dailyHourOfWork: val ? val : null });
          if (isNaN(value.target.value)) {
            setErrors({ ...errors, hourOfWorkError: "Lütfen sayı giriniz." });
          } else {
            if (parseInt(value.target.value) > 24) {
              setErrors({
                ...errors,
                hourOfWorkError: "Günlük çalışma saati 24'ten fazla olamaz.",
              });
            } else {
              if (value.target.value) {
                hourOfWorkError &&
                  setErrors({ ...errors, hourOfWorkError: null });
                setUserProps({
                  ...userProps,
                  dailyHourOfWork: val ? parseInt(val) : null,
                });
                setChangeMadeToProps(true);
              }
            }
          }
        }}
      />
      <CustomDatePicker //START DATE
        style={classes.dateField}
        label={"İşe Başlama Tarihi"}
        value={userProps.startDateOfWork}
        shrink={userProps.startDateOfWork}
        onChangeFunc={(value) => {
          setUserProps({
            ...userProps,
            startDateOfWork: value
              ? moment(value).set("hour", 12).set("minute", 0).toISOString(true)
              : null,
          });
          setChangeMadeToProps(true);
        }}
      />
      <TextSelector //ISG TRAINING
        label={"İSG Eğitimi Aldı Mı"}
        value={userProps.didGetISGTraining}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, didGetISGTraining: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("isg", options)}
      />
      <TextSelector // EKAT REQUIRED
        label={"EKAT Durumu"}
        value={userProps.ekatRequired}
        style={classes.textField}
        onChangeFunc={(value) => {
          const val = value.target.value;
          setUserProps({ ...userProps, ekatRequired: val ? val : null });
          setChangeMadeToProps(true);
        }}
        options={jsonMap("ekat", options)}
      />
    </FormControl>
  );
}
