import React from 'react';
import SessionHelper from '../../helpers/SessionHelper';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const LandingPage = (props) => {
    const navigate = useNavigate();
    console.log(props)
    const isLoggedIn = SessionHelper.getIsLoggedIn();
    console.log('LoggedIn = ', isLoggedIn);
    if (isLoggedIn) {
        navigate('dashboard');
    } else navigate("signIn");

    useEffect(() => {
        if (isLoggedIn) {
            navigate('dashboard');
        } else navigate("signIn");
    })

    return (
        <>

        </>
    );
}

export default LandingPage;