import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Grid, List, ListItem, ListItemText, TextField } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  fields: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  textFieldFields: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%"
  },
}));

export default function ISGUsersList({newDepartmentProps, setNewDepartmentProps, options, setChangeMade}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const getFromId = (options, id) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  const RenderFieldsBox = () => {
    return (
      <Grid item xs={12}>
        {newDepartmentProps.isgUserIds.length > 0 
        ?
        <List className={classes.list}>
          {newDepartmentProps.isgUserIds.map((data, index) => (
            <ListItem key={index}>
              <Grid container>

                <Grid item xs={6}>
                  <div style={{display: "flex", flexDirection: "row"}}>
                    <IconTooltipButton title={language.form.deleteQuestion} onClick={() => {
                      let isgs = [...newDepartmentProps.isgUserIds];
                      isgs.splice(index, 1);
                      setNewDepartmentProps({...newDepartmentProps, isgUserIds: isgs});
                      setChangeMade && setChangeMade(true);
                    }}>
                      <DeleteIcon />
                    </IconTooltipButton>
                    <ListItemText style={{marginTop: 15}} secondary={data.name ? data.name : (options.isgUsers ? getFromId(options.isgUsers, data)?.name : null)}/>
                  </div>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
        :
        <div className={classes.list} style={{textAlign: "center"}}>
          <p style={{color: "red", fontSize: 14, marginLeft: "auto"}}>Ekli İSG Uzmanı yok.</p>
        </div>}
      </Grid>
    )
  }
  return (
    <div style={{textAlign: "left"}}>
      <Grid item xs={6}><p className={classes.fields}>İSG Uzmanları</p></Grid>
      <RenderFieldsBox/>

      <div style={{textAlign: "center"}}>
        <Autocomplete //USERS
          id="combo-box"
          options={options.isgUsers?.sort(function(a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if(reason === "reset") {
              setAutocompleteValue("");
            } else if(reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value, reason) => {
            if(value?.id && value !== "") {
              let isg = [...newDepartmentProps.isgUserIds];
              if(!isg.includes(parseInt(value?.id))) {
                isg.push(parseInt(value?.id));
                setNewDepartmentProps({...newDepartmentProps, isgUserIds: isg});
                setChangeMade && setChangeMade(true);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} required label="İSG Uzmanı Ekle" className={classes.textFieldFields}/>}
        />
      </div>
    </div>
  );
}