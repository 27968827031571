import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, FormControl, TextField } from "@material-ui/core";
import { getDepartments, getManagers } from "./GetOptions";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import FileDropzone from "../../../components/Files/FileDropzone";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { TextArea } from "../../../components/Fields/TextFields";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

/**
 * The form control component used in the user creation/editing modal.
 * @param {newUserProps: obj, setNewUserProps: func, options: obj[], submitNewUser: func} props of the form control
 */
export default function ActivationModal({
  closeUpperModal,
  activationModal,
  setActivationModal,
  userId,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [company, setCompany] = React.useState(
    specialContractor ? user?.company.id : null
  );
  const [department, setDepartment] = React.useState(null);
  const [startDateOfWork, setStartDateOfWork] = React.useState(null);
  const [phoneNo, setPhoneNo] = React.useState(null);
  const [file, setFile] = React.useState(new FormData());
  const [length, setLength] = React.useState(0);
  const [companyError, setCompanyError] = React.useState(null);
  const [departmentError, setDepartmentError] = React.useState(null);
  const [, setDateError] = React.useState(null);
  const [phoneNoError, setPhoneNoError] = React.useState(null);
  /*   const [fileError, setFileError] = React.useState(null); */

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!company) {
      setCompanyError(errorMessage);
      error = true;
    }
    if (!department) {
      setDepartmentError(errorMessage);
      error = true;
    }
    if (!startDateOfWork) {
      setDateError(errorMessage);
      error = true;
    }
    if (!phoneNo) {
      setPhoneNoError(errorMessage);
      error = true;
    }
    /*     if(!file) {
      setFileError(true);
      error = true;
    } */

    if (!error) {
      activate();
    }
  };

  const handleCloseModal = () => {
    setActivationModal(false);
    setLength(0);
    setFile(new FormData());
  };

  const activate = async () => {
    const props = {
      id: userId,
      companyId: company,
      departmentId: department,
      phoneNo: phoneNo,
      startDateOfWork: startDateOfWork,
    };
    file.append(
      "newUser",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("patch", "/api/users/activate-user", file);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      "Başarıyla aktive edildi.",
      tableRef
    );
    handleCloseModal();
    closeUpperModal();
    window.open("/user/" + userId);
  };

  return (
    <TableEntryModal
      modal={activationModal}
      handleCloseModal={handleCloseModal}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        {!specialContractor && (
          <Autocomplete //COMPANIES
            required
            id="combo-box"
            options={options.companies?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={company ? getFromId(company, options.companies) : null}
            onChange={async (event, value) => {
              companyError && setCompanyError(null);
              let newOpts = options;
              newOpts.departments = await getDepartments(value?.id);
              setOptions(newOpts);
              setCompany(value?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={companyError}
                required
                label="Şirketi"
                className={classes.textField}
              />
            )}
          />
        )}
        <Autocomplete //DEPARTMENT
          required
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          disabled={!company}
          fullWidth={true}
          value={department ? getFromId(department, options.departments) : null}
          onChange={async (event, value) => {
            departmentError && setDepartmentError(null);
            let newOpts = options;
            newOpts.managers = await getManagers(value?.id);
            setOptions(newOpts);
            setDepartment(value?.id);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={departmentError}
              required
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
        <TextArea //PHONENO
          required
          label={"Telefon Numarası"}
          value={phoneNo}
          error={phoneNoError}
          style={classes.textField}
          onChangeFunc={(value) => {
            if (value.target.value.substring(0, 2) !== "05") {
              setPhoneNoError("Telefon No ilk 2 rakamı 05 olmalıdır.");
            } else {
              if (value.target.value.length <= 11) {
                phoneNoError && setPhoneNoError(null);
                setPhoneNo(value.target.value);
              }
            }
          }}
        />
        <CustomDatePicker //START DATE
          style={classes.dateField}
          required
          label={"İşe Başlama Tarihi"}
          value={startDateOfWork}
          shrink={startDateOfWork}
          onChangeFunc={(value) =>
            setStartDateOfWork(
              moment(value).set("hour", 12).set("minute", 0).toISOString(true)
            )
          }
        />
        <FileDropzone
          files={file}
          setFiles={setFile}
          fileName={"sgkIn"}
          length={length}
          setLength={setLength}
          /*           fileError={fileError}
          setFileError={setFileError} */
          dropzoneText={
            "SGK işe giriş bildirgesini yüklemek için sürükleyin ya da buraya tıklayın."
          }
        />
        <div style={{ paddingBottom: 20 }}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            className={classes.submit}
          >
            {"AKTİFLEŞTİR"}
          </Button>
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
