import React, { useCallback, useState } from "react";
import moment from "moment";
import jsPDF from "jspdf";
import Request from "../../../../helpers/Request";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import { CircularProgress, makeStyles } from "@material-ui/core";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import GridOn from "@material-ui/icons/GridOn";
import IconTooltipButton from "../../../../components/Buttons/IconTooltipButton";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import XLSXStyle from "xlsx-js-style";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    width: "40%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  sgkContainer: {
    width: "95%",
    border: "1px solid #d5d5d5",
    borderRadius: "15px",
    margin: "0 auto",
    textAlign: "left",
    padding: 10,
    marginBottom: 10,
  },
  redText: {
    color: "red",
  },
  greenText: {
    color: "green",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function IsgCard({
  id,
  userProps,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [trainingTime, setTrainingTime] = useState(null);
  const [ekatStatus, setEkatStatus] = useState(null);
  const [healthReportStatus, setHealthReportStatus] = useState(null);
  const [kkdStatus, setKKDStatus] = useState(null);
  const [accidentCount, setAccidentCount] = useState(null);
  const [nearMissCount, setNearMissCount] = useState(null);
  const [dangerousBehaviorCount, setDangerousBehaviorCount] = useState(null);
  const [inspectionsCount, setInspectionsCount] = useState(null);
  const [unsuitabilityCount, setUnsuitabilityCount] = useState(null);

  const [loading, setLoading] = useState(true);

  const getTrainingTimes = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/training/record/isg-card/" + userProps?.id + "/missing-time"
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }
    return resp.data;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getEkatStatus = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/users/personnel-files/ekat-reports/isg-card/" + userProps?.id
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }
    return resp.data;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getHealthReportStatus = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/examination/isg-card/" + userProps?.id
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }
    return resp.data;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getKKDStatus = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/kkd-users/missing-kkds/" + userProps?.id + "/isg-card"
    );
    console.log(resp);
    // if (resp.status !== 200) {
    //   setSeverity("error");
    //   setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
    //   setSnackbar(true);
    // }
    return resp.data;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getAccidentCount = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/industrial-accidents/isg-card/" + userProps?.id + "/accidents",
      null,
      { page: 0, size: 1, currentCompany: true }
    );

    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }

    return resp.data?.totalElements;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getNearMissCount = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/nearMiss/isg-card/" + userProps?.id,
      null,
      { page: 0, size: 1, currentCompany: true, type: "RAMAK_KALA" }
    );

    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }

    return resp.data?.totalElements;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getDangerousBehaviorCount = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/nearMiss/isg-card/" + userProps?.id,
      null,
      { page: 0, size: 1, currentCompany: true, type: "TEHLİKELİ_DAVRANIŞ" }
    );

    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }

    return resp.data?.totalElements;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getInspectionsCount = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/inspections/isg-card/" + userProps?.id,
      null,
      { page: 0, size: 1, currentCompany: true, status: "DONE" }
    );

    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }

    return resp.data?.totalElements;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const getUnsitabilityCount = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/inspections/isg-card/" + userProps?.id,
      null,
      {
        page: 0,
        size: 1,
        currentCompany: true,
        status: "DONE",
        unsuitability: true,
      }
    );

    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }

    return resp.data?.totalElements;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const init = useCallback(async () => {
    const [
      trainingTime,
      ekatStatus,
      healthReportStatus,
      kkdStatus,
      accidentCount,
      nearMissCount,
      dangerousBehaviorCount,
      inspectionsCount,
      unsuitabilityCount,
    ] = await Promise.all([
      getTrainingTimes(),
      getEkatStatus(),
      getHealthReportStatus(),
      getKKDStatus(),
      getAccidentCount(),
      getNearMissCount(),
      getDangerousBehaviorCount(),
      getInspectionsCount(),
      getUnsitabilityCount(),
    ]);
    setTrainingTime(trainingTime);
    setEkatStatus(ekatStatus);
    setHealthReportStatus(healthReportStatus);
    setKKDStatus(kkdStatus);
    setAccidentCount(accidentCount);
    setNearMissCount(nearMissCount);
    setDangerousBehaviorCount(dangerousBehaviorCount);
    setInspectionsCount(inspectionsCount);
    setUnsuitabilityCount(unsuitabilityCount);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProps, getTrainingTimes]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onClickPdf = () => {
    let cols = [
      {
        title: "Details",
        dataKey: "details",
      },
      {
        title: "Values",
        dataKey: "values",
      },
    ];

    let optionsContainer = {
      base: {},
      horizontal: {
        showHead: false,
        columnStyles: {
          details: {
            fillColor: [41, 128, 185],
            textColor: 255,
            fontStyle: "bold",
          },
        },
        styles: {
          font: "calibri-400",
          fontStyle: "normal",
          cellWidth: "wrap",
          overflow: "linebreak",
        },
      },
    };

    let rows = [
      {
        details: "Adı",
        values: userProps?.fName,
      },
      {
        details: "Soyadı",
        values: userProps?.lName,
      },
      {
        details: "Şirketi",
        values: userProps?.company?.name,
      },
      {
        details: "Bölümü",
        values: userProps?.department?.name,
      },
      {
        details: "İşe Başlama Tarihi",
        values: userProps?.startDateOfWork
          ? moment(userProps?.startDateOfWork).format("DD-MM-YYYY")
          : "Yok",
      },
      {
        details: "Rapor Çekilme Tarihi",
        values: moment().format("DD-MM-YYYY"),
      },
      {
        details: "Temel İSG Eğitim Durumu",
        values:
          trainingTime?.requiredTime - trainingTime?.totalTime > 0
            ? "EKSİK"
            : "TAMAM",
      },
      {
        details: "Eksik Temel İSG Eğitimi Süresi",
        values: trainingTime?.requiredTime - trainingTime?.totalTime,
      },
      {
        details: "EKAT Eğitim Durumu",
        values: ekatStatus.content.anyEkatFilePresent
          ? "VAR"
          : ekatStatus.content.isEkatRequiredForUser &&
            !ekatStatus.content.anyEkatFilePresent
          ? "YOK"
          : ekatStatus.content.isEkatRequiredForUser === false
          ? "GEREKLİ DEĞİL"
          : "BELİRSİZ",
      },
      {
        details: "Sağlık Durumu Raporu",
        values: healthReportStatus.data ? "VAR" : "YOK",
      },
      {
        details: "KKD Durumu",
        values: kkdStatus.data?.length > 0 ? "EKSİK" : "TAMAM",
      },
      {
        details: "İş Kazası Sayısı",
        values: accidentCount,
      },
      {
        details: "Ramak Kala Sayısı",
        values: nearMissCount,
      },
      {
        details: "Tehlikeli Davranış Sayısı",
        values: dangerousBehaviorCount,
      },
      {
        details: "Denetim Sayısı",
        values: inspectionsCount,
      },
      {
        details: "Uygunsuzluk Sayısı",
        values: unsuitabilityCount,
      },
    ];

    const doc = new jsPDF();

    doc.autoTable(cols, rows, optionsContainer["horizontal"]);

    doc.save("Personel-ISG-Kartı.pdf");
  };

  const onClickExcel = () => {
    const data = [
      {
        details: "Adı",
        values: userProps?.fName,
      },
      {
        details: "Soyadı",
        values: userProps?.lName,
      },
      {
        details: "Şirketi",
        values: userProps?.company?.name,
      },
      {
        details: "Bölümü",
        values: userProps?.department?.name,
      },
      {
        details: "İşe Başlama Tarihi",
        values: userProps?.startDateOfWork
          ? moment(userProps?.startDateOfWork).format("DD-MM-YYYY")
          : "Yok",
      },
      {
        details: "Rapor Çekilme Tarihi",
        values: moment().format("DD-MM-YYYY"),
      },
      {
        details: "Temel İSG Eğitim Durumu",
        values:
          trainingTime?.requiredTime - trainingTime?.totalTime > 0
            ? "EKSİK"
            : "TAMAM",
      },
      {
        details: "Eksik Temel İSG Eğitimi Süresi",
        values: trainingTime?.requiredTime - trainingTime?.totalTime,
      },
      {
        details: "EKAT Eğitim Durumu",
        values: ekatStatus.content.anyEkatFilePresent
          ? "VAR"
          : ekatStatus.content.isEkatRequiredForUser &&
            !ekatStatus.content.anyEkatFilePresent
          ? "YOK"
          : ekatStatus.content.isEkatRequiredForUser === false
          ? "GEREKLİ DEĞİL"
          : "BELİRSİZ",
      },
      {
        details: "Sağlık Durumu Raporu",
        values: healthReportStatus.data ? "VAR" : "YOK",
      },
      {
        details: "KKD Durumu",
        values: kkdStatus.data?.length > 0 ? "EKSİK" : "TAMAM",
      },
      {
        details: "İş Kazası Sayısı",
        values: accidentCount,
      },
      {
        details: "Ramak Kala Sayısı",
        values: nearMissCount,
      },
      {
        details: "Tehlikeli Davranış Sayısı",
        values: dangerousBehaviorCount,
      },
      {
        details: "Denetim Sayısı",
        values: inspectionsCount,
      },
      {
        details: "Uygunsuzluk Sayısı",
        values: unsuitabilityCount,
      },
    ];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(data, {
      skipHeader: true,
    });

    const colName = [
      "A1",
      "A2",
      "A3",
      "A4",
      "A5",
      "A6",
      "A7",
      "A8",
      "A9",
      "A10",
      "A11",
      "A12",
      "A13",
      "A14",
      "A15",
      "A16",
      "B1",
      "B2",
      "B3",
      "B4",
      "B5",
      "B6",
      "B7",
      "B8",
      "B9",
      "B10",
      "B11",
      "B12",
      "B13",
      "B14",
      "B15",
      "B16",
    ];
    for (const itm of colName) {
      // if excel column starts with A (A1, A2 etc.)
      if (itm.charAt(0) === "A") {
        ws[itm].s = {
          fill: {
            bgColor: { rgb: "FFFCD5B4" },
            fgColor: { rgb: "FFFCD5B4" },
          },
          font: {
            bold: true,
          },
          alignment: {
            vertical: "center",
            horizontal: "left",
          },
          border: {
            bottom: {
              style: "thin",
              color: "black",
            },
            right: {
              style: "thin",
              color: "black",
            },
          },
        };
      } else {
        // if excel column starts with B (B1, B2 etc.)
        ws[itm].s = {
          alignment: {
            vertical: "center",
            horizontal: "left",
          },
          border: {
            bottom: {
              style: "thin",
              color: "black",
            },
            right: {
              style: "thin",
              color: "black",
            },
          },
        };
      }
    }

    fixWidth(ws);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSXStyle.write(wb, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(blob, "Personel-ISG-Kartı" + fileExtension);
  };

  // https://stackoverflow.com/a/72949574/10113516
  const fixWidth = (worksheet) => {
    const data = XLSX.utils.sheet_to_json(worksheet);
    const colLengths = Object.keys(data[0]).map((k) => k.toString().length);
    for (const d of data) {
      Object.values(d).forEach((element, index) => {
        const length = element.toString().length;
        if (colLengths[index] < length) {
          colLengths[index] = length;
        }
      });
    }
    worksheet["!cols"] = colLengths.map((l) => {
      return {
        wch: l,
      };
    });
  };

  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div className={classes.root}>
      <div className={classes.sgkContainer}>
        <>{"Adı: " + userProps?.fName}</>
        <>{"Soyadı: " + userProps?.lName}</>
        <>{"Şirketi: " + userProps?.company?.name}</>
        <>{"Bölümü: " + userProps?.department?.name}</>
        <>
          {"İşe Başlama Tarihi: " +
            (userProps?.startDateOfWork
              ? moment(userProps?.startDateOfWork).format("DD-MM-YYYY")
              : "Yok")}
        </>
        <div
          className={
            trainingTime?.requiredTime - trainingTime?.totalTime > 0
              ? classes.redText
              : classes.greenText
          }
        >
          {"Temel İSG Eğitim Durumu: " +
            (trainingTime?.requiredTime - trainingTime?.totalTime > 0
              ? "EKSİK"
              : "TAMAM")}
        </div>
        <div
          className={
            trainingTime?.requiredTime - trainingTime?.totalTime > 0
              ? classes.redText
              : classes.greenText
          }
        >
          {"Eksik Temel İSG Eğitimi Süresi: " +
            (trainingTime?.requiredTime - trainingTime?.totalTime > 0
              ? trainingTime?.requiredTime - trainingTime?.totalTime
              : 0)}
        </div>
        <div
          className={
            ekatStatus.content.anyEkatFilePresent
              ? classes.greenText
              : ekatStatus.content.isEkatRequiredForUser &&
                !ekatStatus.content.anyEkatFilePresent
              ? classes.redText
              : ekatStatus.content.isEkatRequiredForUser === false
              ? classes.greenText
              : classes.redText
          }
        >
          {"EKAT Eğitim Durumu: " +
            (ekatStatus.content.anyEkatFilePresent
              ? "VAR"
              : ekatStatus.content.isEkatRequiredForUser &&
                !ekatStatus.content.anyEkatFilePresent
              ? "YOK"
              : ekatStatus.content.isEkatRequiredForUser === false
              ? "GEREKLİ DEĞİL"
              : "BELİRSİZ")}
        </div>
        <div
          className={
            healthReportStatus.data ? classes.greenText : classes.redText
          }
        >
          {"Sağlık Durumu Raporu: " + (healthReportStatus.data ? "VAR" : "YOK")}
        </div>
        <div
          className={
            kkdStatus.data?.length > 0 || !kkdStatus.hasTask
              ? classes.redText
              : classes.greenText
          }
        >
          {"KKD Durumu: " +
            (kkdStatus.data?.length > 0
              ? "EKSİK"
              : kkdStatus.hasTask
              ? "TAMAM"
              : "TANIMLANMAMIŞ")}
        </div>
        <div className={accidentCount ? classes.redText : classes.greenText}>
          {"İş Kazası Sayısı: " + accidentCount}
        </div>
        <div className={nearMissCount ? classes.redText : classes.greenText}>
          {"Ramak Kala Sayısı: " + nearMissCount}
        </div>
        <div
          className={
            dangerousBehaviorCount ? classes.redText : classes.greenText
          }
        >
          {"Tehlikeli Davranış Sayısı: " + dangerousBehaviorCount}
        </div>
        <div className={inspectionsCount ? classes.greenText : classes.redText}>
          {"Denetim Sayısı: " + inspectionsCount}
        </div>
        <div
          className={unsuitabilityCount ? classes.redText : classes.greenText}
        >
          {"Uygunsuzluk Sayısı: " + unsuitabilityCount}
        </div>
      </div>
      <div className={classes.buttons}>
        <IconTooltipButton title="PDF" label="PDF" onClick={onClickPdf}>
          <PictureAsPdf />
        </IconTooltipButton>
        <IconTooltipButton
          title="EXCEL"
          label="EXCEL"
          color="primary"
          style={{
            color: "#5FD602",
            marginLeft: 10,
          }}
          onClick={onClickExcel}
        >
          <GridOn />
        </IconTooltipButton>
      </div>
    </div>
  );
}
