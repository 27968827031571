import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

export default function MultipleUserModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  modalLoading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [usersFile, setUsersFile] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const sendTemplateRequest = async () => {
    setLoading(true);
    const resp = await Request(
      "get",
      "/template/Toplu-Kullanici-Girisi.xlsx",
      null,
      null,
      { Accept: "application/xlsx" }
    );
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
      FileSaver.saveAs(source, "template.xlsx");
    }
    setLoading(false);
  };

  /**
   * Closes the modal and flushes the states.
   */
  const handleCloseModal = () => {
    setModal(false);
    setUsersFile(new FormData());
  };
  /**
   * Request to submit a new user with the data given.
   */
  const submitNewUsers = async () => {
    setLoading(true);
    let formData = new FormData();
    if (usersFile && Array.isArray(usersFile)) {
      for (let file of usersFile) {
        formData.append("file", file);
      }
    }
    const resp = await Request("post", "/api/users/add/batch/file", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef,
      null,
      Array.isArray(resp.data)
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <>
          <Button
            variant="contained"
            color="secondary"
            style={{ margin: 5, width: "100%" }}
            onClick={sendTemplateRequest}
          >
            {"Şablonu İndİrmek İçİn Tıklayınız."}
          </Button>
        </>
        <CustomFileDropzone
          files={usersFile}
          setFiles={(value) => {
            setUsersFile(value);
          }}
          dropzoneText={
            "Kullanıcı dosyası yüklemek için sürükleyin ya da buraya tıklayın."
          }
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => submitNewUsers()}
              className={classes.submit}
            >
              {"OLUŞTUR"}
            </Button>
          )}
        </div>
      </TableEntryModal>
    </>
  );
}
