import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../components/Table/TableFilters";
import { getDepartments } from "./GetOptions";
import TableBackdrop from "../../components/Table/TableBackdrop";
import { TextArea, TextSelector } from "../../components/Fields/TextFields";
import LanguageHelper from "../../helpers/LanguageHelper";
import { suitability, trueFalse } from "../../assets/constants";
import jsonMap from "../../components/Utils/jsonMap";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.isgTrainingReport.workStartDateStart}
                value={
                  tableFilters.startTime ? moment(tableFilters.startTime) : null
                }
                shrink={tableFilters.startTime}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    startTime: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true)
                      .slice(0, -10),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.isgTrainingReport.workStartDateEnd}
                value={
                  tableFilters.endTime ? moment(tableFilters.endTime) : null
                }
                shrink={tableFilters.endTime}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    endTime: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true)
                      .slice(0, -10),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //COMPANY
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (!value) {
                    options.departments = [];
                  } else {
                    [options.departments] = await Promise.all([
                      getDepartments(value?.id),
                    ]);
                  }
                  setTableFilters({ ...tableFilters, company: value });
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.healthReport.company}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (value === null) {
                    setTableFilters({ ...tableFilters, department: null });
                  } else {
                    setTableFilters({ ...tableFilters, department: value });
                  }
                  setBackdropLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.healthReport.department}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.name}
                label={language.healthReport.name}
                value={tableFilters.name}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    name: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.surname}
                label={language.healthReport.surname}
                value={tableFilters.surname}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    surname: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.eligibleToWork}
                value={tableFilters.eligible}
                shrink={tableFilters.eligible}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    eligible: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, suitability)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.workAtHeight}
                value={tableFilters.high}
                shrink={tableFilters.high}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    high: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.workAtNight}
                value={tableFilters.night}
                shrink={tableFilters.night}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    night: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.workInShift}
                value={tableFilters.shift}
                shrink={tableFilters.shift}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    shift: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.workInDangerousWorkplace}
                value={tableFilters.dangerous}
                shrink={tableFilters.dangerous}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    dangerous: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.workInVeryDangerousWorkplace}
                value={tableFilters.veryDangerous}
                shrink={tableFilters.veryDangerous}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    veryDangerous: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.healthReport.workWithScreenedVehicle}
                value={tableFilters.screen}
                shrink={tableFilters.screen}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    screen: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, trueFalse)}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
