import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      "page-size": query.pageSize,
      sort: "id,desc" 
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "email") { params.sort = "email," + query.orderDirection; }
      else if (query.orderBy.field === "name") { params.sort = "name," + query.orderDirection; }
      else if (query.orderBy.field === "surname") { params.sort = "surname," + query.orderDirection; }
      else if (query.orderBy.field === "phoneNumber") { params.sort = "phoneNumber," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "companyName," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/vm/visitor/get-all", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          email:data[i].email,
          name:data[i].name,
          surname:data[i].surname,
          phoneNo:data[i].phoneNo,
          companyName: data[i].company?.name,
          companyId: data[i]?.company?.id,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}
