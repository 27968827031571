import React, { useCallback, useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import {
  CircularProgress,
  Divider,
  Button,
  makeStyles,
} from "@material-ui/core";
import { ToggleButton } from "@material-ui/lab";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import Request from "../../../helpers/Request";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import VisitData from "./VisitData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { fileUrl } from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import SessionHelper from "../../../helpers/SessionHelper";
import { TextArea } from "../../../components/Fields/TextFields";
import moment from "moment";
import { DateTimePicker } from "../../../components/Selectors/DatePickers";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  approveReportButton: {
    cursor: "pointer",
    margin: 20,
    marginTop: 30,
  },
  approveReportLabel: {
    cursor: "pointer",
  },
  divider: {
    margin: 10,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
  },
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: 600,
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px",
  },
  textField: {
    marginBottom: "20px",
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
  greenText: {
    color: "green",
    fontSize: 16,
  },
  greyText: {
    color: "grey",
    fontSize: 16,
  },
  check: {
    color: "white",
    fontSize: 16,
    backgroundColor: "green",
    marginLeft: "10px",
  },
  cross: {
    color: "white",
    fontSize: 16,
    backgroundColor: "red",
    marginLeft: "10px",
  },
  fileButton: {
    cursor: "pointer",
    marginBottom: 5,
  },
  fileButtonLabel: {
    cursor: "pointer",
    fontSize: "0.8vw",
  },
  text: {
    backgroundColor: "white",
    width: "40rem",
    borderRadius: "0.5rem",
    whiteSpace: "pre-wrap",
    margin: 10,
  },
  dateContainer: {
    display: "flex",
  },
  dateField: {
    display: "inline-block",
    margin: "2rem",
    color: "white",
  },
}));

function VisitDetails({ visitId, visitorId, tableRef }) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const isSecurity = roles.includes("ROLE_GÜVENLİK_GÖREVLİSİ");
  const isISGSpecialist = roles.includes("ROLE_ISG_UZMANI");

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [data, setData] = useState(null);
  const [visitorDocuments, setVisitorDocuments] = useState(null);
  const [visitorVideos, setVisitorVideos] = useState(null);
  const [visitorEquipments, setVisitorEquipments] = useState(null);
  const [rejectionIds, setRejectionIds] = React.useState([]);
  const [approvalIds, setApprovalIds] = React.useState([]);
  const [equipmentRejectionIds, setEquipmentRejectionIds] = React.useState([]);
  const [equipmentApprovalIds, setEquipmentApprovalIds] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [reviewerNotes, setReviewerNotes] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [dateAndTime, setDateAndTime] = React.useState("");
  const getVisit = useCallback(async (id) => {
    const resp = await Request("get", "/api/vm/visit/" + id);
    console.log(resp);
    setStatus(resp.data.status);
    return resp.data;
  }, []);

  const getVisitorDocuments = useCallback(async (visitId, visitorId) => {
    const resp = await Request(
      "get",
      `/api/vm/visit/get/visitor-documents/${visitId}/${visitorId}`
    );
    return resp.data;
  }, []);

  const getVisitorVideos = useCallback(async (visitId, visitorId) => {
    const resp = await Request(
      "get",
      `/api/vm/visit/get/visitor-equipments/${visitId}/${visitorId}`
    );
    return resp.data;
  }, []);

  const init = useCallback(async () => {
    const visit = await getVisit(visitId);
    const documentsData = await getVisitorDocuments(visitId, visitorId);
    let visitorEq = visit.visitorEquipments.filter(
      (eq) => eq.visitor.id == visitorId
    );
    let visitorDate = visit.visitorLists.filter(
      (date) => date.visitor.id == visitorId
    );

    let documentsArray = [];
    for (let index = 0; index < documentsData.length; index++) {
      const element = documentsData[index];
      documentsArray.push({
        id: element.id,
        reviewerNote: element.reviewerNote,
      });
    }

    setDateAndTime({
      entranceDate: visitorDate[0]?.entranceDate
        ? visitorDate[0]?.entranceDate
        : null,
      exitDate: visitorDate[0]?.exitDate ? visitorDate[0]?.exitDate : null,
    });
    setReviewerNotes(documentsArray);
    console.log(visit);
    setData(visit);
    setVisitorDocuments(documentsData);
    setVisitorVideos(await getVisitorVideos(visitId, visitorId));
    setVisitorEquipments(visitorEq);
  }, [
    visitId,
    visitorId,
    getVisit,
    getVisitorDocuments,
    getVisitorVideos,
    setDateAndTime,
  ]);
  useEffect(() => {
    init();
  }, [init]);

  const approveReport = async () => {
    setBackdropLoading(true);
    const params = { visitId, visitorId };
    const documents = DocumentMerge();
    const resp = await Request(
      "put",
      "/api/vm/visit/approve-reject/visitor-documents",
      documents,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.data) {
        setSeverity("error");
        setSnackbarMessage(resp.data);
        setSnackbar(true);
      } else if (resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      tableRef && tableRef.current && tableRef.current.onQueryChange();
      setSeverity("success");
      setSnackbarMessage(language.etc.updated);
      setSnackbar(true);
      !tableRef && window.location.reload();
    }
    setBackdropLoading(false);
  };

  const approveEquipment = async () => {
    setBackdropLoading(true);
    let { entranceDate, exitDate } = dateAndTime;
    entranceDate = entranceDate
      ? moment(entranceDate).toISOString(true).replace("+03:00", "Z")
      : null;
    exitDate = exitDate
      ? moment(exitDate).toISOString(true).replace("+03:00", "Z")
      : null;

    let updateVisitorEquipments = [];
    for (let index = 0; index < equipmentApprovalIds.length; index++) {
      let obj = {
        equipped: true,
        visitorEquipmentId: equipmentApprovalIds[index],
      };
      updateVisitorEquipments.push(obj);
    }
    for (let index = 0; index < equipmentRejectionIds.length; index++) {
      let obj = {
        equipped: false,
        visitorEquipmentId: equipmentRejectionIds[index],
      };
      updateVisitorEquipments.push(obj);
    }

    let body = {
      updateVisitorEquipments,
      visitId,
      visitorId,
    };
    if (entranceDate !== null) {
      body = { ...body, entranceDate };
    }
    if (exitDate !== null) {
      body = { ...body, exitDate };
    }
    const resp = await Request(
      "put",
      "/api/vm/visit/approve/visitor-equipments",
      body
    );
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.data) {
        setSeverity("error");
        setSnackbarMessage(resp.data);
        setSnackbar(true);
      } else if (resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      tableRef && tableRef.current && tableRef.current.onQueryChange();
      setSeverity("success");
      setSnackbarMessage(language.etc.updated);
      setSnackbar(true);
      !tableRef && window.location.reload();
    }
    setBackdropLoading(false);
  };

  const RenderDescription = ({ visitorList }) => {
    let visitor = visitorList.find((x) => x.visitor?.id == visitorId)?.visitor;
    return (
      <div>
        <label>
          {language.visits.visitor}
          <br />
        </label>
        {visitor ? (
          <p
            style={{
              fontSize: 16,
              whiteSpace: "pre-wrap",
              overflow: "auto",
              maxHeight: 170,
            }}
          >
            {visitor?.name + " " + visitor?.surname}
          </p>
        ) : (
          <p className={classes.redText}>
            {language.tableDetails.noText}
            <br />
          </p>
        )}
      </div>
    );
  };

  const RenderVideos = () => {
    return (
      <div>
        <Divider className={classes.divider} />
        <label>
          {language.visitTypes.videos}
          <br />
        </label>
        {visitorVideos && visitorVideos.length > 0 ? (
          visitorVideos.map((data, index) => (
            <p
              style={{
                fontSize: 16,
                whiteSpace: "pre-wrap",
                overflow: "auto",
                maxHeight: 170,
              }}
              key={index}
            >
              {data.video.name + " "}
              <span
                className={data.watched ? classes.greenText : classes.redText}
              >
                {data.watched
                  ? language.visits.watched
                  : language.visits.notWatched}
              </span>
            </p>
          ))
        ) : (
          <p className={classes.redText}>
            {language.visits.noVideo}
            <br />
          </p>
        )}
      </div>
    );
  };

  const RenderDocumentsForISGSpecialist = () => {
    return (
      <div>
        <Divider className={classes.divider} />
        <label>
          {language.visitTypes.documents}
          <br />
        </label>
        {visitorDocuments && visitorDocuments.length > 0 ? (
          visitorDocuments.map((data, index) => {
            return (
              <div key={index}>
                <p
                  style={{
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    overflow: "auto",
                    maxHeight: 170,
                  }}
                >
                  {data.document.name}
                </p>
                {data.filename && (
                  <p
                    className={
                      data.approved
                        ? classes.greenText
                        : data.approved === false
                        ? classes.redText
                        : classes.greyText
                    }
                  >
                    {data.approved
                      ? language.visits.confirmed
                      : data.approved === false
                      ? language.visits.rejected
                      : language.visitorPage.pending}
                  </p>
                )}
                {data.filename ? (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.fileButton}
                      onClick={() =>
                        window.open(
                          data.filename?.includes(fileUrl)
                            ? data.filename
                            : fileUrl + data.filename
                        )
                      }
                    >
                      <label className={classes.fileButtonLabel}>
                        {data.filename
                          ? data.filename.split(fileUrl)
                          : data.filename}
                      </label>
                    </Button>
                    {status == "PLANNED" && (
                      <>
                        <ToggleButton
                          value={data.approved}
                          disabled={data.approved}
                          selected={
                            approvalIds.findIndex((obj) => obj.id === data.id) >
                            -1
                          }
                          className={classes.check}
                          onChange={() => {
                            // check if document is already selected for rejection
                            const rejectionIndex = rejectionIds.findIndex(
                              (obj) => obj.id === data.id
                            );
                            // new array to avoid from side effects
                            // https://codingbeautydev.com/blog/javascript-remove-element-from-array-by-id/#:~:text=splice()%20methods
                            let arr = Array.from(rejectionIds);
                            // if it is selected for rejection, remove it
                            if (rejectionIndex > -1) {
                              arr.splice(rejectionIndex, 1);
                            }
                            setRejectionIds(arr);
                            // check if document is already selected for approval
                            const approvalIndex = approvalIds.findIndex(
                              (obj) => obj.id === data.id
                            );
                            // new array to avoid from side effects
                            let arr2 = Array.from(approvalIds);
                            // if it is not already selected, add for approval
                            if (approvalIndex <= -1) {
                              arr2.push(data);
                            }
                            setApprovalIds(arr2);
                          }}
                        >
                          <CheckIcon />
                        </ToggleButton>
                        <ToggleButton
                          value={data.approved}
                          disabled={data.approved}
                          selected={
                            rejectionIds.findIndex(
                              (obj) => obj.id === data.id
                            ) > -1
                          }
                          className={classes.cross}
                          onChange={() => {
                            // check if document is already selected for approval
                            const approvalIndex = approvalIds.findIndex(
                              (obj) => obj.id === data.id
                            );
                            // new array to avoid from side effects
                            let arr = Array.from(approvalIds);
                            // if it is selected for approval, remove it
                            if (approvalIndex > -1) {
                              arr.splice(approvalIndex, 1);
                            }
                            setApprovalIds(arr);
                            // check if document is already selected for rejection
                            const rejectionIndex = rejectionIds.findIndex(
                              (obj) => obj.id === data.id
                            );
                            // new array to avoid from side effects
                            let arr2 = Array.from(rejectionIds);
                            // if it is not already selected, add for rejection
                            if (rejectionIndex <= -1) {
                              arr2.push(data);
                            }

                            setRejectionIds(arr2);
                          }}
                        >
                          <ClearIcon />
                        </ToggleButton>
                      </>
                    )}
                    <div style={{ margin: "2rem 0" }}>
                      <TextArea
                        label={language.tableDetails.description}
                        value={reviewerNotes[index].reviewerNote}
                        style={classes.text}
                        disabled={!(status === "PLANNED") || data.approved}
                        inputLabelProps={{ margin: "0.3rem 0 0 1rem" }}
                        inputProps={{
                          style: {
                            minHeight: "5rem",
                            paddingLeft: "1rem",
                          },
                          disableUnderline: true,
                        }}
                        onChangeFunc={(value) => {
                          let temp = Array.from(reviewerNotes);
                          for (let index = 0; index < temp.length; index++) {
                            const element = temp[index];
                            if (element.id === data.id) {
                              element.reviewerNote = value.target.value;
                              break;
                            }
                          }
                          setReviewerNotes(temp);
                        }}
                        shrink={
                          reviewerNotes[index].reviewerNote || data.reviewerNote
                        }
                        multiline={true}
                      />
                    </div>
                  </>
                ) : (
                  <p className={classes.redText}>
                    {language.visits.notUploaded}
                    <br />
                  </p>
                )}
              </div>
            );
          })
        ) : (
          <p className={classes.redText}>
            {language.tableDetails.noFile}
            <br />
          </p>
        )}
      </div>
    );
  };

  const RenderDocuments = () => {
    return (
      <div>
        <Divider className={classes.divider} />
        <label>
          {language.visitTypes.documents}
          <br />
        </label>
        {visitorDocuments && visitorDocuments.length > 0 ? (
          visitorDocuments.map((data, index) => {
            return (
              <div key={index}>
                <p
                  style={{
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    overflow: "auto",
                    maxHeight: 170,
                  }}
                >
                  {data.document.name}
                </p>
                {data.filename ? (
                  <p
                    className={
                      data.approved
                        ? classes.greenText
                        : data.approved === false
                        ? classes.redText
                        : classes.greyText
                    }
                  >
                    {data.approved
                      ? language.visits.confirmed
                      : data.approved === false
                      ? language.visits.rejected
                      : language.visitorPage.pending}
                  </p>
                ) : (
                  <p className={classes.redText}>
                    {language.visits.notUploaded}
                    <br />
                  </p>
                )}
              </div>
            );
          })
        ) : (
          <p className={classes.redText}>
            {language.tableDetails.noFile}
            <br />
          </p>
        )}
      </div>
    );
  };

  const RenderEquipments = () => {
    return (
      <div>
        <Divider className={classes.divider} />
        <label>
          {language.visitTypes.equipments}
          <br />
        </label>
        {visitorEquipments && visitorEquipments.length > 0 ? (
          visitorEquipments.map((data, index) => {
            return (
              <div key={index}>
                <p
                  style={{
                    fontSize: 16,
                    whiteSpace: "pre-wrap",
                    overflow: "auto",
                    maxHeight: 170,
                  }}
                >
                  {data.equipment.name}
                </p>
                {isSecurity && (
                  <ToggleButton
                    value={data.equipped}
                    disabled={data.equipped}
                    selected={
                      equipmentApprovalIds.findIndex((obj) => obj === data.id) >
                      -1
                    }
                    className={classes.check}
                    onChange={() => {
                      // check if document is already selected for rejection
                      const rejectionIndex = equipmentRejectionIds.findIndex(
                        (obj) => obj === data.id
                      );
                      // new array to avoid from side effects
                      // https://codingbeautydev.com/blog/javascript-remove-element-from-array-by-id/#:~:text=splice()%20methods
                      let arr = Array.from(equipmentRejectionIds);
                      // if it is selected for rejection, remove it
                      if (rejectionIndex > -1) {
                        arr.splice(rejectionIndex, 1);
                      }
                      setEquipmentRejectionIds(arr);
                      // check if document is already selected for approval
                      const approvalIndex = equipmentApprovalIds.findIndex(
                        (obj) => obj.id === data.id
                      );
                      // new array to avoid from side effects
                      let arr2 = Array.from(equipmentApprovalIds);
                      // if it is not already selected, add for approval
                      if (approvalIndex <= -1) {
                        arr2.push(data.id);
                      }
                      setEquipmentApprovalIds(arr2);
                    }}
                  >
                    <CheckIcon />
                  </ToggleButton>
                )}
                {isSecurity && (
                  <ToggleButton
                    value={data.equipped}
                    disabled={data.equipped}
                    selected={
                      equipmentRejectionIds.findIndex(
                        (obj) => obj === data.id
                      ) > -1
                    }
                    className={classes.cross}
                    onChange={() => {
                      // check if document is already selected for approval
                      const approvalIndex = equipmentApprovalIds.findIndex(
                        (obj) => obj === data.id
                      );
                      // new array to avoid from side effects
                      let arr = Array.from(equipmentApprovalIds);
                      // if it is selected for approval, remove it
                      if (approvalIndex > -1) {
                        arr.splice(approvalIndex, 1);
                      }
                      setEquipmentApprovalIds(arr);
                      // check if document is already selected for rejection
                      const rejectionIndex = equipmentRejectionIds.findIndex(
                        (obj) => obj === data.id
                      );
                      // new array to avoid from side effects
                      let arr2 = Array.from(equipmentRejectionIds);
                      // if it is not already selected, add for rejection
                      if (rejectionIndex <= -1) {
                        arr2.push(data.id);
                      }
                      setEquipmentRejectionIds(arr2);
                    }}
                  >
                    <ClearIcon />
                  </ToggleButton>
                )}
                <p
                  className={
                    data.equipped ? classes.greenText : classes.redText
                  }
                >
                  {data.equipped
                    ? language.visits.equipped
                    : data.equipped === false && language.visits.notEquipped}
                </p>
              </div>
            );
          })
        ) : (
          <p className={classes.redText}>
            {language.tableDetails.noFile}
            <br />
          </p>
        )}
      </div>
    );
  };

  const RenderButtons = () => {
    let notApproved = visitorDocuments.find(
      (x) => x.approved === null || x.approved === false
    );
    return (
      <div>
        <Divider className={classes.divider} />
        {notApproved &&
          (notApproved.approved === null || notApproved.approved === false) &&
          status === "PLANNED" && (
            <div>
              <Button
                variant="contained"
                onClick={() => approveReport()}
                className={classes.approveReportButton}
              >
                <label className={classes.approveReportLabel}>
                  {language.tableDetails.saveChanges}
                </label>
              </Button>
            </div>
          )}
      </div>
    );
  };

  const RenderEquipmentButtons = () => {
    // let notApproved = visitorEquipments.find(            we just check visit entrance and exit date for rendering the buttons
    //   (x) => x.equipped === null || x.equipped === false
    // );
    return (
      <div>
        <Divider className={classes.divider} />
        {status === "PLANNED" && (
          <div>
            <Button
              variant="contained"
              onClick={() => approveEquipment()}
              className={classes.approveReportButton}
            >
              <label className={classes.approveReportLabel}>
                {language.tableDetails.saveChanges}
              </label>
            </Button>
          </div>
        )}
      </div>
    );
  };

  const RenderDateAndTime = () => {
    return (
      <div>
        <Divider className={classes.divider} />
        <div className={classes.dateContainer}>
          <DateTimePicker
            disabled={status === "DONE"}
            style={classes.dateField}
            label={language.visits.entranceDate}
            value={dateAndTime.entranceDate ? dateAndTime.entranceDate : null}
            shrink={dateAndTime.entranceDate}
            onChangeFunc={(value) => {
              setDateAndTime({
                ...dateAndTime,
                entranceDate: value,
              });
            }}
            inputLabelProps={{ color: "white" }}
            keyboardButtonProps={{ style: { color: "white" } }}
            inputProps={{
              style: { borderBottom: "1px solid white", color: "white" },
              disableUnderline: true,
            }}
          />
          <DateTimePicker
            disabled={dateAndTime.entranceDate === null || status === "DONE"}
            style={classes.dateField}
            label={language.visits.exitDate}
            value={
              dateAndTime.exitDate ? dateAndTime.exitDate : null
              // ? dateAndTime.exitDate.replaceAll("Z", "")
              // : null
            }
            shrink={dateAndTime.exitDate}
            onChangeFunc={(value) => {
              setDateAndTime({
                ...dateAndTime,
                exitDate: value,
                // moment(value)
                // .toISOString(true)
                // .replace("+03:00", "Z"),
              });
            }}
            inputLabelProps={{ color: "white" }}
            keyboardButtonProps={{ style: { color: "white" } }}
            inputProps={{
              style: { borderBottom: "1px solid white", color: "white" },
              disableUnderline: true,
            }}
          />
        </div>
      </div>
    );
  };

  const DocumentMerge = () => {
    let temp = [];
    for (let index = 0; index < reviewerNotes.length; index++) {
      const element1 = reviewerNotes[index];
      for (let index = 0; index < approvalIds.length; index++) {
        const element2 = approvalIds[index];
        if (element2.id === element1.id) {
          temp.push({
            visitorDocumentId: element1.id,
            reviewerNote: element1.reviewerNote,
            isApproved: true,
          });
        }
      }
      for (let index = 0; index < rejectionIds.length; index++) {
        const element3 = rejectionIds[index];
        if (element3.id === element1.id) {
          temp.push({
            visitorDocumentId: element1.id,
            reviewerNote: element1.reviewerNote,
            isApproved: false,
          });
        }
      }
    }
    return temp;
  };

  return (
    <React.Fragment>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <ResponsiveContainer>
        {data && visitorDocuments && visitorVideos && visitorEquipments ? (
          <div>
            {data.status !== 404 ? (
              <div className={classes.details}>
                <VisitData data={data} />
                <RenderDescription visitorList={data.visitorLists} />
                <>
                  {isSecurity && RenderDateAndTime()}
                  {(data?.host?.id === user?.id ||
                    data.participants.find((x) => x.user?.id == user?.id) ||
                    isISGSpecialist) && (
                    <>
                      <RenderVideos />
                    </>
                  )}
                  {isISGSpecialist && RenderDocumentsForISGSpecialist()}
                  {(isSecurity ||
                    data?.host?.id === user?.id ||
                    data.participants.find((x) => x.user?.id == user?.id)) && (
                    <>{RenderDocuments()}</>
                  )}
                  {(data?.host?.id === user?.id ||
                    data.participants.find((x) => x.user?.id == user?.id) ||
                    isISGSpecialist) && (
                    <>
                      <RenderEquipments />
                    </>
                  )}
                  {isISGSpecialist && <RenderButtons />}
                  {isSecurity && <RenderEquipmentButtons />}
                </>
              </div>
            ) : (
              <div>{language.visits.notFound}</div>
            )}
          </div>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </ResponsiveContainer>
    </React.Fragment>
  );
}

export default VisitDetails;
