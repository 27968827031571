import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../components/Table/TableFilters";
import { getDepartments } from "./GetOptions";
import TableBackdrop from "../../components/Table/TableBackdrop";
import { TextArea, TextSelector } from "../../components/Fields/TextFields";
import LanguageHelper from "../../helpers/LanguageHelper";
import jsonMap from "../../components/Utils/jsonMap";
import { size } from "../../assets/constants";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete //COMPANY
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (!value) {
                    options.departments = [];
                  } else {
                    [options.departments] = await Promise.all([
                      getDepartments(value?.id),
                    ]);
                  }
                  setTableFilters({ ...tableFilters, company: value });
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.personnelDressSize.company}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (value === null) {
                    setTableFilters({ ...tableFilters, department: null });
                  } else {
                    setTableFilters({ ...tableFilters, department: value });
                  }
                  setBackdropLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.personnelDressSize.department}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.name}
                label={language.personnelDressSize.name}
                value={tableFilters.name}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    name: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.surname}
                label={language.personnelDressSize.surname}
                value={tableFilters.surname}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    surname: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.shoe}
                label={language.personnelDressSize.shoe}
                value={tableFilters.shoe}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    shoe: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.personnelDressSize.vest}
                value={tableFilters.vest}
                shrink={tableFilters.vest}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    vest: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, size)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.personnelDressSize.jacket}
                value={tableFilters.jacket}
                shrink={tableFilters.jacket}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    jacket: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, size)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.personnelDressSize.trouser}
                value={tableFilters.trouser}
                shrink={tableFilters.trouser}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    trouser: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, size)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.personnelDressSize.shirt}
                value={tableFilters.shirt}
                shrink={tableFilters.shirt}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    shirt: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, size)}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
