import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import NewCompanyModal from "./NewCompanyModal";
import Details from "./Details";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetOptions from "./GetOptions";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

export default function CompanyTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "companiesTable");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [oldCompany, setOldCompany] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id", editable: "never" },
      { title: "Adı", field: "name" },
      { title: "Kısa Adı", field: "nameShort" },
      { title: "Şehir", field: "city" },
      { title: "Şirket Tipi", field: "eCompanyType", lookup: options.types },
      { title: "Vergi Dairesi", field: "taxOffice" },
      { title: "Vergi Kimlik Numarası", field: "taxIdentificationNumber" },
      /* { title: "Şirket SGK Sicil No", field: "companySGKNos", render: rowData => 
        <div>
          <List className={classes.list}>
            {
              rowData.companySGKNos.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data}/>
                </ListItem>
              ))
            }
          </List>
        </div>
      } */
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setOldCompany(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedCompany(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedCompany(null);
    setDialog(false);
  };

  const removeCompany = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/companies/" + selectedCompany?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeCompany}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <NewCompanyModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        opts={options}
        oldCompanyProps={oldCompany}
      />
      <Table
        tableName={language.sidebar.companies}
        authName="companiesTable"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        //deleteEntry={removeCompany}
        isDeletable={(rowData) => authorization?.delete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        rowStyle={(rowData) => ({
          backgroundColor: !rowData.active && "#804f4f",
        })}
        detailsPanel={(rowData) => <Details rowData={rowData} />}
      />
    </>
  );
}
