import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SessionHelper from '../../../../helpers/SessionHelper';
import LanguageHelper from '../../../../helpers/LanguageHelper';
import { MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import CustomSnackbar from '../../../../components/Snackbar/Snackbar';
import StatusColumn from '../actions/StatusColumn';
import { taskStatuses } from '../../../../assets/constants';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  column: {
    fontSize: 14,
    fontColor: "darkblue"
  }
}));

export default function AccidentActionData({data}) {
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const language = LanguageHelper.getLanguage();

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  let statuses = {...taskStatuses};
  statuses.DONE = "Onay Bekliyor";

  const jsonMap = (json) => {
    return (
      json && Object.keys(json).map((data) => (
        <MenuItem key={data} value={data}>
          {json[data]}
        </MenuItem>
      ))
    )
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{language.tableColumns.id}</TableCell>
              <TableCell align="left">{"Atayan Kişi"}</TableCell>
              <TableCell align="left">{"Atanan Kişi"}</TableCell>
              <TableCell align="left">{"Termin Tarihi"}</TableCell>
              <TableCell align="left">{"Onaylanma Tarihi"}</TableCell>
              <TableCell align="left">{language.tableColumns.status}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell align="left">{data.id}</TableCell>
              <TableCell align="left">{data.givenBy?.fName + " " + data.givenBy?.lName}</TableCell>
              <TableCell align="left">{data.assignedTo ? (data.assignedTo?.fName + " " + data.assignedTo?.lName) : "Yok"}</TableCell>
              <TableCell align="left">{data.terminationDate ? (new moment(data.terminationDate)).format("DD-MM-YYYY HH:mm:ss") : "Yok"}</TableCell>
              <TableCell align="left">{data.approvedDate ? (new moment(data.approvedDate)).format("DD-MM-YYYY HH:mm:ss") : "Onaylanmadı"}</TableCell>
              <TableCell align="left">
                <StatusColumn
                  rowData={data}
                  roles={roles}
                  jsonMap={jsonMap}
                  statuses={statuses} 
                  setSeverity={setSeverity}
                  setSnackbarMessage={setSnackbarMessage}
                  setSnackbar={setSnackbar}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) 
}