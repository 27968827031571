import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import { getTowns } from './GetOptions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters 
 */
 export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [towns, setTowns] = React.useState([]);
  
  return(
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />

      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.id !== null}
                label={"No"}
                value={tableFilters.id}
                onChangeFunc={value => setTableFilters({...tableFilters, id: value.target.value})}
                style={classes.textField}
              />  
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.name !== null}
                label={"Adı"}
                value={tableFilters.name}
                onChangeFunc={value => setTableFilters({...tableFilters, name: value.target.value})}
                style={classes.textField}
              />  
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.sgk !== null}
                label={"SGK Sicil No"}
                value={tableFilters.sgk}
                onChangeFunc={value => setTableFilters({...tableFilters, sgk: value.target.value})}
                style={classes.textField}
              /> 
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={"Şehir"}
                value={tableFilters.city}
                onChangeFunc={async value => {
                  setTableFilters({...tableFilters, city: value.target.value});
                  setTowns(await getTowns(value.target.value));
                }}
                style={classes.textField}
                options={jsonMap("cities", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={towns?.sort(function(a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.city}
                value={tableFilters.town}
                onChange={(event, value) => setTableFilters({...tableFilters, town: value})}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="İlçe" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.companies?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={(event, value) => setTableFilters({...tableFilters, company: value})}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Şirket" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.allManagers?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.manager}
                onChange={(event, value) => setTableFilters({...tableFilters, manager: value})}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Yönetici" className={classes.textField}/>}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.allUnsuitUsers?.sort(function(a, b) {
                  return collator.compare(a.name, b.name)
                })}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.unsuitUser}
                onChange={(event, value) => setTableFilters({...tableFilters, unsuitUser: value})}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => <TextField {...params} label="Uygunsuzluk Giderici Personel" className={classes.textField}/>}
              />
            </Grid>
          </Grid> 
          
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  )
}