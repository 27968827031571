import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../../components/Selectors/DatePickers";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from "../../../../helpers/Request";
import {
  TextArea,
  TextSelector,
} from "../../../../components/Fields/TextFields";
import { days } from "../../../../assets/constants";
import jsonMap from "../../../../components/Utils/jsonMap";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
}));

export default function ModalForm({
  crewNumberProps,
  setCrewNumberProps,
  label,
  loading,
  setLoading,
  options,
  setOptions,
  handleCloseModal,
  oldCrewNumberProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();

  const [errors, setErrors] = React.useState({
    departmentId: null,
    weekStart: null,
    toolboxDay: null,
    d1: null,
    d2: null,
    d3: null,
    d4: null,
    d5: null,
    d6: null,
    d7: null,
  });

  const getFromName = (options, name) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].name === name) {
        return options[i];
      }
    }
    return null;
  };

  /**
   * Request to replace the old data with the new data.
   */
  const updateCrewNumber = async () => {
    setLoading(true);
    if (oldCrewNumberProps.savedAt) {
      crewNumberProps.saved = true;
    }
    delete crewNumberProps.departmentId;
    const resp = await Request(
      "patch",
      "/api/crew-numbers/" + oldCrewNumberProps.id,
      crewNumberProps
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const submitCrewNumber = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/crew-numbers", crewNumberProps);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      departmentId: null,
      weekStart: null,
      toolboxDay: null,
      d1: null,
      d2: null,
      d3: null,
      d4: null,
      d5: null,
      d6: null,
      d7: null,
    };
    if (!oldCrewNumberProps && !crewNumberProps.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    if (!crewNumberProps.weekStart) {
      errors.weekStart = errorMessage;
      error = true;
    }
    if (!crewNumberProps.toolboxDay) {
      errors.toolboxDay = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d1) {
      errors.d1 = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d2) {
      errors.d2 = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d3) {
      errors.d3 = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d4) {
      errors.d4 = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d5) {
      errors.d5 = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d6) {
      errors.d6 = errorMessage;
      error = true;
    }
    if (!crewNumberProps.d7) {
      errors.d7 = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      oldCrewNumberProps ? updateCrewNumber() : submitCrewNumber();
    }
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      {!oldCrewNumberProps && (
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={
            crewNumberProps.departmentName
              ? getFromName(options.departments, crewNumberProps.departmentName)
              : null
          }
          onChange={async (event, value) => {
            setCrewNumberProps({
              ...crewNumberProps,
              departmentId: value?.id,
              departmentName: value?.name,
            });
            errors.departmentId && setErrors({ ...errors, departmentId: null });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              error={errors.department}
              helperText={errors.department}
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
      )}
      <CustomDatePicker
        style={classes.dateField}
        error={errors.weekStart}
        label="Tarih"
        required
        value={
          crewNumberProps.weekStart
            ? crewNumberProps.weekStart.replaceAll("Z", "")
            : null
        }
        shrink={crewNumberProps.weekStart}
        onChangeFunc={(value) => {
          setCrewNumberProps({
            ...crewNumberProps,
            weekStart: moment(value).isoWeekday(1).toISOString(true),
          });
          errors.weekStart && setErrors({ ...errors, weekStart: null });
        }}
      />
      <TextSelector
        label="Toolbox Eğitim Günü"
        value={crewNumberProps.toolboxDay}
        error={errors.toolboxDay}
        shrink={crewNumberProps.toolboxDay}
        onChangeFunc={(value) => {
          setCrewNumberProps({
            ...crewNumberProps,
            toolboxDay: value.target.value,
          });
          errors.toolboxDay && setErrors({ ...errors, toolboxDay: null });
        }}
        style={classes.textField}
        options={jsonMap(false, days)}
      />
      <TextArea
        shrink={crewNumberProps.d1}
        label={"Pazartesi Ekip Sayısı"}
        required
        error={errors.d1}
        value={crewNumberProps.d1}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d1: value.target.value });
          errors.d1 && setErrors({ ...errors, d1: null });
        }}
      />
      <TextArea
        shrink={crewNumberProps.d2}
        label={"Salı Ekip Sayısı"}
        required
        error={errors.d2}
        value={crewNumberProps.d2}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d2: value.target.value });
          errors.d2 && setErrors({ ...errors, d2: null });
        }}
      />
      <TextArea
        shrink={crewNumberProps.d3}
        label={"Çarşamba Ekip Sayısı"}
        required
        error={errors.d3}
        value={crewNumberProps.d3}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d3: value.target.value });
          errors.d3 && setErrors({ ...errors, d3: null });
        }}
      />
      <TextArea
        shrink={crewNumberProps.d4}
        label={"Perşembe Ekip Sayısı"}
        required
        error={errors.d4}
        value={crewNumberProps.d4}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d4: value.target.value });
          errors.d4 && setErrors({ ...errors, d4: null });
        }}
      />
      <TextArea
        shrink={crewNumberProps.d5}
        label={"Cuma Ekip Sayısı"}
        required
        error={errors.d5}
        value={crewNumberProps.d5}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d5: value.target.value });
          errors.d5 && setErrors({ ...errors, d5: null });
        }}
      />
      <TextArea
        shrink={crewNumberProps.d6}
        label={"Cumartesi Ekip Sayısı"}
        required
        error={errors.d6}
        value={crewNumberProps.d6}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d6: value.target.value });
          errors.d6 && setErrors({ ...errors, d6: null });
        }}
      />
      <TextArea
        shrink={crewNumberProps.d7}
        label={"Pazar Ekip Sayısı"}
        required
        error={errors.d7}
        value={crewNumberProps.d7}
        style={classes.textField}
        onChangeFunc={(value) => {
          setCrewNumberProps({ ...crewNumberProps, d7: value.target.value });
          errors.d7 && setErrors({ ...errors, d7: null });
        }}
      />
      <div className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            className={classes.submit}
          >
            {label}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
