import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../../assets/styles/tableContainer";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import UnauthorizedPage from "../../UnauthorizedPage";
import ActivationHistoryTable from "./ActivationHistoryTable";

export default function ActivationHistoryPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "activationHistory");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <ActivationHistoryTable /> : <UnauthorizedPage />}
      </Grid>
    </Container>
  );
}
