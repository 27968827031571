import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import FilledForm from "../../../../components/Forms/FilledForm";
import SlideDialog from "../../../../components/Dialog/SlideDialog";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3),
    minWidth: "30%",
    maxHeight: "90%",
  },
  formsContainer: {
    margin: "0 auto",
    width: "60%",
  },
  formButton: {
    margin: 5,
  },
  formButtonLabel: {
    cursor: "pointer",
  },
}));

export default function Forms({
  data,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setBackdropLoading,
}) {
  const classes = useStyles();

  const [formID, setFormID] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const PrintForms = () => {
    return data.forms.map((dt, index) => {
      return (
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={() => openDialog(dt.id)}
        >
          <label className={classes.formButtonLabel}>{dt.templateTitle}</label>
        </Button>
      );
    });
  };

  const openDialog = (id) => {
    setFormID(id);
    setOpen(true);
  };

  const closeDialog = () => {
    setFormID(null);
    setOpen(false);
  };

  const formAsPDF = () => {
    FilledForm(
      formID,
      false,
      setSnackbar,
      setSnackbarMessage,
      setSeverity,
      setBackdropLoading,
      false
    );
    closeDialog();
  };

  const formAsXLSX = () => {
    FilledForm(
      formID,
      true,
      setSnackbar,
      setSnackbarMessage,
      setSeverity,
      setBackdropLoading,
      false
    );
    closeDialog();
  };

  return (
    <>
      <SlideDialog
        open={open}
        handleClose={closeDialog}
        description={"Dosyayı hangi uzantıda açmak/indirmek istersiniz?"}
        button1Func={formAsPDF}
        button1Label={"PDF"}
        button2Func={formAsXLSX}
        button2Label={"Excel"}
      />
      <div className={classes.formsContainer}>
        <Grid container justifyContent="center" direction="column">
          <PrintForms />
        </Grid>
      </div>
    </>
  );
}
