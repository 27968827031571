import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import { MultiMonthDatePicker } from "../../../components/Selectors/DatePickers";
import { getFunctions } from "./GetOptions";
import TableBackdrop from "../../../components/Table/TableBackdrop";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters
 */
export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [backdropLoading, setBackdropLoading] = React.useState(false);

  const isoToDate = (dates) => {
    let startDates = [];
    for (let date of dates) {
      startDates.push(new Date(date));
    }
    return startDates;
  };

  const onChange = (dates) => {
    let months = [];
    for (let date of dates) {
      let temp = new Date(date);
      months.push(
        moment(temp).set("hour", 0).set("minute", 0).toISOString(true)
      );
      setTableFilters({ ...tableFilters, months: months });
    }
  };

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="combo-box"
                options={options.modules?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.moduleIds}
                onChange={async (event, value) => {
                  setTableFilters({ ...tableFilters, moduleIds: value });
                  setBackdropLoading(true);
                  let funcs = [];
                  for (let module of value) {
                    let newFuncs = await getFunctions(module.id);
                    funcs = funcs.concat(newFuncs);
                  }
                  options.functions = funcs;
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Modül"}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="combo-box"
                options={options.functions?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.moduleFunctionIds}
                disabled={tableFilters.moduleIds.length === 0}
                onChange={async (event, value) => {
                  setTableFilters({
                    ...tableFilters,
                    moduleFunctionIds: value,
                  });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Fonksiyon"}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //ROLE
                multiple
                id="combo-box"
                options={options.rolesId?.sort(function (a, b) {
                  return collator.compare(a.nameTr, b.nameTr);
                })}
                getOptionLabel={(option) => option.nameTr}
                getOptionSelected={(option, value) =>
                  option.nameTr === value.nameTr
                }
                fullWidth={true}
                value={tableFilters.roleIds}
                onChange={async (event, value) => {
                  setTableFilters({ ...tableFilters, roleIds: value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Rol"}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
