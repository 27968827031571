import React, { useCallback, useRef } from "react";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import Table from "../../../../components/Table/Table";
import FetchData from "./FetchData";

const textStyle = {
  maxHeight: 100,
  width: 200,
  overflowY: "scroll",
  alignItems: "center",
};

export default function Accidents({
  id,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const language = LanguageHelper.getLanguage();
  const tableRef = useRef();

  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [filterProps] = React.useState({
    user: id,
  });

  const init = useCallback(async () => {
    setLoading(true);
    setColumns([
      { title: language.workAccidents.accidentId, field: "accidentId" },
      { title: language.workAccidents.accidentDate, field: "accidentDate" },
      { title: language.workAccidents.companyName, field: "companyName" },
      { title: language.workAccidents.departmentName, field: "departmentName" },
      {
        title: language.workAccidents.totalLostTime,
        field: "totalLostTime",
        sorting: false,
      },
      {
        title: language.workAccidents.summary,
        field: "summary",
        render: (rowData) => <div style={textStyle}>{rowData.summary}</div>,
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={loading} />
      <Table
        noRowActions
        tableRef={tableRef}
        authName="usersTable"
        tableName={language.workAccidents.tableName}
        columns={columns}
        filters={filterProps}
        fetchData={FetchData}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        height={window.innerHeight > 900 ? "57vh" : "47vh"}
      />
    </>
  );
}
