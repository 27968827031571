import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../components/Table/TableFilters";
import { TextArea, TextSelector } from "../../components/Fields/TextFields";
import LanguageHelper from "../../helpers/LanguageHelper";
import { approveStatus, platform } from "../../assets/constants";
import { getDepartments } from "./GetOptions";
import jsonMap from "../../components/Utils/jsonMap";
import { CustomDatePicker } from "../../components/Selectors/DatePickers";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.regions?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.region}
                onChange={(event, value) => {
                  setTableFilters({
                    ...tableFilters,
                    region: value,
                  });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölge"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.company}
                onChange={async (event, value) => {
                  if (!value) {
                    options.departments = [];
                  } else {
                    [options.departments] = await Promise.all([
                      getDepartments(value?.id),
                    ]);
                  }
                  setTableFilters({ ...tableFilters, company: value });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.loginStatus.company}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.loginStatus.department}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.email !== null}
                label={"Email"}
                value={tableFilters.email}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    email: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Platform"
                value={tableFilters.platform}
                shrink={tableFilters.platform !== null}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    platform: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, platform)}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Onay Tarihi Başlangıç"
                value={
                  tableFilters.readTimeStart
                    ? moment(tableFilters.readTimeStart)
                    : null
                }
                shrink={tableFilters.readTimeStart}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    readTimeStart: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(false)
                      .slice(0, -5),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Onay Tarihi Bitiş"
                value={
                  tableFilters.readTimeEnd
                    ? moment(tableFilters.readTimeEnd)
                    : null
                }
                shrink={tableFilters.readTimeEnd}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    readTimeEnd: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(false)
                      .slice(0, -5),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Onay Durumu"
                value={tableFilters.approved}
                shrink={tableFilters.approved !== null}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    approved: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, approveStatus)}
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
