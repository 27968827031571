import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { TextSelector, TextArea } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  wasteUnitId: null,
  wasteTypeId: null,
  wasteName: null,
  year: null,
  disposalMethodId: null,
};

export default function CatalogueModal({
  catalogueProps,
  setCatalogueModal,
  options,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const specialContractor =
    user.roles.includes("ROLE_YUKLENICI_ISG_UZMANI") ||
    user.roles.includes("ROLE_YUKLENICI_YÖNETİCİ");
  const classes = useStyles();
  const isNewCatalogue =
    catalogueProps && Object.keys(catalogueProps).length === 0;
  const language = LanguageHelper.getLanguage();
  const collator = new Intl.Collator("tr");

  const [props, setProps] = React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({
    wasteUnitId: null,
    wasteTypeId: null,
    wasteName: null,
    year: null,
    disposalMethodId: null,
  });

  const init = useCallback(async () => {
    !isNewCatalogue && catalogueProps && setProps({ ...catalogueProps });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialContractor, user, catalogueProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if (!props?.year) {
      setErrors({ ...errors, year: errorMessage });
      error = true;
    }
    if (!props?.wasteName) {
      setErrors({ ...errors, wasteName: errorMessage });
      error = true;
    }
    if (!props?.wasteTypeId) {
      setErrors({ ...errors, wasteTypeId: errorMessage });
      error = true;
    }
    if (!props?.wasteUnitId) {
      setErrors({ ...errors, wasteUnitId: errorMessage });
      error = true;
    }
    if (!props?.disposalMethodId) {
      setErrors({ ...errors, disposalMethodId: errorMessage });
      error = true;
    }

    if (!error) {
      if (isNewCatalogue) {
        submitNewCatalogue();
      } else {
        updateCatalogue();
      }
    }
  };

  const submitNewCatalogue = async () => {
    setLoading(true);
    let wasteTypeId;
    props.wasteTypeId &&
      !props.wasteTypeId.id &&
      (wasteTypeId = await Request(
        "post",
        "/api/waste-discharge/waste-type/add",
        { name: props.wasteTypeId.name }
      ));
    if (wasteTypeId) {
      props.wasteTypeId = wasteTypeId.data.content.id;
    } else {
      props.wasteTypeId = props.wasteTypeId.id;
    }
    let disposalMethodId;
    props.disposalMethodId &&
      !props.disposalMethodId.id &&
      (disposalMethodId = await Request(
        "post",
        "/api/waste-discharge/disposal-method/add",
        { name: props.disposalMethodId.name }
      ));
    if (disposalMethodId) {
      props.disposalMethodId = disposalMethodId.data.content.id;
    } else {
      props.disposalMethodId = props.disposalMethodId.id;
    }
    const resp = await Request(
      "post",
      "/api/waste-discharge/catalog/add",
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateCatalogue = async () => {
    setLoading(true);
    let wasteTypeId;
    props.wasteTypeId &&
      !props.wasteTypeId.id &&
      (wasteTypeId = await Request(
        "post",
        "/api/waste-discharge/waste-type/add",
        { name: props.wasteTypeId.name }
      ));
    if (wasteTypeId) {
      props.wasteTypeId = wasteTypeId.data.content.id;
    } else {
      props.wasteTypeId = props.wasteTypeId.id;
    }
    let disposalMethodId;
    props.disposalMethodId &&
      !props.disposalMethodId.id &&
      (disposalMethodId = await Request(
        "post",
        "/api/waste-discharge/disposal-method/add",
        { name: props.disposalMethodId.name }
      ));
    if (disposalMethodId) {
      props.disposalMethodId = disposalMethodId.data.content.id;
    } else {
      props.disposalMethodId = props.disposalMethodId.id;
    }
    const resp = await Request(
      "patch",
      "/api/waste-discharge/catalog/modify",
      props
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setProps(propsDefault);
    setErrors({
      wasteUnitId: null,
      wasteTypeId: null,
      wasteName: null,
      year: null,
      statuaryLimit: null,
    });
    setCatalogueModal(null);
  };

  return (
    <TableEntryModal
      modal={catalogueProps ? true : false}
      handleCloseModal={handleCloseModal}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <TextSelector //WASTE UNIT
          label={"Atık Birimi"}
          required
          error={errors.wasteUnitId}
          value={props?.wasteUnitId}
          shrink={props?.wasteUnitId}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              wasteUnitId: value.target.value,
            });
            errors.wasteUnitId && setErrors({ ...errors, wasteUnitId: null });
          }}
          options={jsonMap("wasteUnits", options)}
        />
        <Autocomplete //WASTE TYPE
          id="combo-box"
          options={options.wasteTypesArray?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          fullWidth
          freeSolo
          autoSelect
          blurOnSelect
          value={props.wasteTypeId}
          onChange={(event, value) => {
            if (value && value.name) {
              setProps({ ...props, wasteTypeId: value });
            } else {
              setProps({
                ...props,
                wasteTypeId: { name: value },
              });
            }
          }}
          renderOption={(option) => <span>{option.name}</span>}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              error={errors.wasteTypeId}
              helperText={errors.wasteTypeId}
              label="Atık Tipi"
              className={classes.textField}
            />
          )}
        />
        <p style={{ color: "red", marginTop: -7 }}>
          Atık tipi seçeneklerde yoksa, tipi yukarıdaki alana yazınız.
        </p>
        <TextArea //WASTE NAME
          required
          error={errors.wasteName}
          label={"Adı"}
          value={props?.wasteName}
          onChangeFunc={(value) => {
            setProps({ ...props, wasteName: value.target.value });
            errors.wasteName && setErrors({ ...errors, wasteName: null });
          }}
          style={classes.textField}
        />
        <TextArea //YEAR
          required
          error={errors.year}
          label={"Yılı"}
          value={props?.year}
          shrink={props?.year}
          onChangeFunc={(value) => {
            const val = value.target.value;
            setProps({ ...props, year: val });
            errors.year && setErrors({ ...errors, year: null });
          }}
          style={classes.textField}
        />
        <Autocomplete //WASTE DISPOSAL METHOD
          id="combo-box"
          options={options.wasteDisposalMethodsArray?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          fullWidth
          freeSolo
          autoSelect
          blurOnSelect
          value={props.disposalMethodId}
          onChange={(event, value) => {
            if (value && value.name) {
              setProps({ ...props, disposalMethodId: value });
            } else {
              setProps({
                ...props,
                disposalMethodId: { name: value },
              });
            }
          }}
          renderOption={(option) => <span>{option.name}</span>}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              error={errors.disposalMethodId}
              helperText={errors.disposalMethodId}
              label="Bertaraf Yöntemi"
              className={classes.textField}
            />
          )}
        />
        <p style={{ color: "red", marginTop: -7 }}>
          Bertaraf yöntemi seçeneklerde yoksa, yöntemi yukarıdaki alana yazınız.
        </p>
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewCatalogue ? "GÜNCELLE" : "OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
