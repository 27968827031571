import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Request from "../../../helpers/Request";
import ModalForm from './ModalForm';
import { modalStyles } from '../../../assets/styles/tableContainer';
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => (modalStyles(theme)));

export default function NewCityModal({modal, setModal, setSnackbar, setSnackbarMessage, tableRef, setSeverity}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [newCityName, setNewCityName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  
  const handleCloseModal = () => {
    setModal(false);
    setNewCityName("");
  }

  const submitNewCity = async () => {
    setLoading(true);
    const body = {
      "name": newCityName
    }
    const resp = await Request("post", '/api/cities-towns/city', body);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm 
              newCityName={newCityName} 
              setNewCityName={setNewCityName} 
              submitNewCity={submitNewCity}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}