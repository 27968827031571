import React from 'react';
import { ResponsiveContainer } from 'recharts';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { CircularProgress, makeStyles } from '@material-ui/core';
import ListOfFiles from '../../../components/Files/ListOfFiles';

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2D3446',
  },
  approveReportButton: {
    cursor: "pointer", 
    margin: 20,
    marginTop: 30
  },
  approveReportLabel: {
    cursor: "pointer"
  },
  divider: {
    margin: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3)
  },submit: {
    marginTop: "10px"
  },
  formControl: {
    width: 600
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px"
  },
  textField: {
    marginBottom: "20px"
  },
  redText: {
    color: "red", 
    fontSize: 16
  }
}));

export default function Details({rowData}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const RenderFiles = () => {
    return(
      <>
        <label>Dosyalar<br /></label>
        {rowData && rowData.files?.length > 0
          ? 
          <>
            <ListOfFiles fileKeys={rowData.files}/>
          </>
          : <p className={classes.redText}>{language.tableDetails.noFile}<br /></p>}
      </>
    )
  }

  return (
    <>
      <ResponsiveContainer>
        {rowData 
          ?
          <div className={classes.details}>
            <RenderFiles/>
          </div>
          :
          <CircularProgress color="secondary"/>
        }
      </ResponsiveContainer>
    </>
  );
}