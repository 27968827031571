import React, { forwardRef, useCallback } from "react";
import "../../../App.css";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import Request from "../../../helpers/Request";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import showSnackbar from "../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import QualityControlModal from "./QualityControlModal";
import Details from "./Details";

export default function QualityControlTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "qualityControls");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedQualityControl, setSelectedQualityControl] =
    React.useState(null);
  const [idToDelete, setIdToDelete] = React.useState(false);

  //   const [filtersOpen, setFiltersOpen] = React.useState(false);
  //   const [filterProps, setFilterProps] = React.useState({
  //     reportedByFilter: null,
  //     incidentUserFilter: null,
  //   });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: language.qualityControls.company,
        field: "company",
      },
      {
        title: language.qualityControls.assignedUser,
        field: "assignedUser",
      },
      {
        title: language.qualityControls.productType,
        field: "productType",
      },
      {
        title: language.qualityControls.date,
        field: "dateFormatted",
      },
      {
        title: language.qualityControls.jobNumber,
        field: "jobNumber",
      },
      {
        title: language.qualityControls.shiftStartLotNo,
        field: "shiftStartLotNo",
      },
      {
        title: language.qualityControls.shift,
        field: "shift",
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setSelectedQualityControl(data || {});
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const removeQualityControl = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", "/api/quality-controls", null, {
      ids: idToDelete,
    });
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  //   const RenderFilters = () => {
  //     return (
  //       <div style={{ marginBottom: 10 }}>
  //         <GetFilterFields
  //           options={options}
  //           tableRef={tableRef}
  //           setOptions={setOptions}
  //           filterProps={filterProps}
  //           filtersOpen={filtersOpen}
  //           clearFilters={clearFilters}
  //           setFilterProps={setFilterProps}
  //           setFiltersOpen={setFiltersOpen}
  //         />
  //       </div>
  //     );
  //   };

  //   const clearFilters = () => {
  //     setFilterProps({
  //       reportedByFilter: null,
  //       incidentUserFilter: null,
  //     });
  //   };

  return (
    <React.Fragment>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeQualityControl}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <QualityControlModal
        qualityControlProps={selectedQualityControl}
        setQualityControlProps={setSelectedQualityControl}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <TableBackdrop backdropLoading={loading} />
      {/* <RenderFilters /> */}
      <Table
        tableName={language.sidebar.controls}
        authName={"qualityControls"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={() => authorization.delete}
        isDeleteHidden={() => !authorization.delete}
        detailsPanel={(rowData) => <Details rowData={rowData} />}
      />
    </React.Fragment>
  );
}
