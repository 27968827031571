import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";
import moment from "moment";
import { getKKDKinds } from "./GetOptions";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function KKDModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  kkdDebtProps,
  setKKDDebtProps,
  opts,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const isNewKKDDebt = kkdDebtProps && Object.keys(kkdDebtProps).length === 0;

  const [requestKKDDebtProps, setRequestKKDDebtProps] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [kkdKinds, setKKDKinds] = React.useState([]);
  const [errors, setErrors] = React.useState({
    givenDateError: null,
  });
  const [crew, setCrew] = React.useState(false);
  const [crewFiles, setCrewFiles] = React.useState([]);

  const init = useCallback(async () => {
    setRequestKKDDebtProps({
      crewId: kkdDebtProps ? kkdDebtProps.crewId : null,
      userId: kkdDebtProps ? kkdDebtProps.userId : null,
      kkdId: kkdDebtProps ? kkdDebtProps.kkdId : null,
      kkdSerialNo: kkdDebtProps ? kkdDebtProps.kkdSerialNo : null,
      givenDate: kkdDebtProps
        ? moment(kkdDebtProps.givenDate).toISOString(true)
        : null,
      productionDate: kkdDebtProps
        ? moment(kkdDebtProps.productionDate).toISOString(true)
        : null,
    });
    // if not new kkddebt and it is crew debt
    if (!isNewKKDDebt && kkdDebtProps?.crewId) {
      setCrew(true);
    } else if (!isNewKKDDebt && kkdDebtProps?.userId) {
      // if not new kkddebt and it is personal debt
      setCrew(false);
    }
    // if not new kkddebt and it is crew debt
    if (!isNewKKDDebt && kkdDebtProps?.crewId) {
      setCrewFiles(kkdDebtProps.files || []);
    }
    !isNewKKDDebt &&
      setKKDKinds(
        await getKKDKinds(kkdDebtProps?.userId, kkdDebtProps?.crewId)
      );
    setOptions(opts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkdDebtProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestKKDDebtProps({
      crewId: null,
      userId: null,
      kkdId: null,
      kkdSerialNo: null,
      givenDate: null,
      productionDate: null,
    });
    setErrors({
      givenDateError: null,
    });
    setCrew(false);
    setCrewFiles([]);
    setKKDDebtProps(null);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      givenDateError: null,
    };
    if (!requestKKDDebtProps?.givenDate) {
      errors.givenDateError = errorMessage;
      error = true;
    }

    setErrors(errors);

    if (!error) {
      isNewKKDDebt ? submitNewKKDDebt() : updateKKDDebt();
    }
  };

  const submitNewKKDDebt = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/kkd-debits", requestKKDDebtProps);
    console.log(resp);
    // if new kkd is succesfully added
    if (resp.status === 200) {
      let formData = new FormData();
      // if there is an added file and it is array
      if (crewFiles && Array.isArray(crewFiles)) {
        for (let file of crewFiles) {
          formData.append("file", file);
        }
      }
      const fileResp = await Request(
        "post",
        "/api/kkd-debits/file/" + resp.data.id,
        formData
      );
      console.log(fileResp);
    }
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateKKDDebt = async () => {
    setLoading(true);
    let formData = new FormData();
    if (kkdDebtProps.fileKeys) {
      // find added files by comparing fileNames which already in kkdDebtProps
      // and return new array with full of added files
      const addedFiles = crewFiles.filter(
        (o) => !kkdDebtProps.fileKeys.some((i) => i.fileName === o.name)
      );
      let addFileResp;
      if (addedFiles.length > 0) {
        for (let file of addedFiles) {
          formData.append("file", file);
        }
        addFileResp = await Request(
          "post",
          "/api/kkd-debits/file/" + kkdDebtProps.id,
          formData
        );
      }

      // find removed files by comparing fileNames which already in kkdDebtProps
      // and return new array with full of removed files
      const removedFiles = kkdDebtProps.fileKeys.filter(
        (o) => !crewFiles.some((i) => i.name === o.fileName)
      );
      let deleteFileResp;
      if (removedFiles.length > 0) {
        // create new array with full of ids
        let removedFilesById = removedFiles.map((a) => a.id);
        var params = new URLSearchParams();
        for (let id of removedFilesById) {
          params.append("files", id);
        }
        deleteFileResp = await Request(
          "delete",
          "/api/kkd-debits/file/" + kkdDebtProps.id,
          null,
          params
        );
      }
      console.log(addFileResp);
      console.log(deleteFileResp);
      // if file addition response returns an error
      if (addFileResp && addFileResp.status !== 200) {
        showSnackbar(
          addFileResp,
          setSeverity,
          setSnackbarMessage,
          setSnackbar,
          language.etc.updated,
          tableRef
        );
      } else if (deleteFileResp && deleteFileResp.status !== 200) {
        // if file deletion response returns an error
        showSnackbar(
          deleteFileResp,
          setSeverity,
          setSnackbarMessage,
          setSnackbar,
          language.etc.updated,
          tableRef
        );
      } else if (deleteFileResp || addFileResp) {
        // if both response returns success status
        showSnackbar(
          deleteFileResp || addFileResp,
          setSeverity,
          setSnackbarMessage,
          setSnackbar,
          language.etc.updated,
          tableRef
        );
      }
    }
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestKKDDebtProps={requestKKDDebtProps}
              setRequestKKDDebtProps={setRequestKKDDebtProps}
              onButtonClick={onButtonClick}
              options={options}
              loading={loading}
              errors={errors}
              setErrors={setErrors}
              setKKDKinds={setKKDKinds}
              kkdKinds={kkdKinds}
              crew={crew}
              setCrew={setCrew}
              isNewKKDDebt={isNewKKDDebt}
              crewFiles={crewFiles}
              setCrewFiles={setCrewFiles}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
