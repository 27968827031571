import React from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import moment from 'moment';
import showSnackbar from "../../../components/Utils/showSnackbar";
import { Button, CircularProgress, FormControl, makeStyles } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import Files from "./Files";

const useStyles = makeStyles((theme) => (modalFormStyles(theme)));

/**
 * Modal component used in the user table for deleting users.
 * @param {modal: boolean, setModal: func, setSnackbarMessage: func, setSnackbar: func, tableRef: obj} props of the user delete modal 
 */
export default function DeleteModal({modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, tableRef, companyType, id, setId}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  
  const [leaveDate, setLeaveDate] = React.useState(null);
  const [files, setFiles] = React.useState(new FormData());
  const [length, setLength] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const [dateError, setDateError] = React.useState(null);
/*   const [fileError, setFileError] = React.useState(null); */

  /**
   * Closes the modal and flushes the state.
   */
  const handleCloseModal = () => {
    setModal(false);
    setId(null);
    setLength(0);
    setFiles(new FormData());
    setLeaveDate(null);
    setDateError(null);
/*     setFileError(null); */
  }

  const deleteUser = async () => {
    setLoading(true);
    files.set("deletedAt", leaveDate);
    const resp = await Request("delete", "/api/users/with-file/" + id, files);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if(!leaveDate) {
      setDateError(errorMessage);
      error = true;
    }
/*     if(length === 0 && companyType !== "EDC") {
      setFileError(errorMessage);
      error = true;
    } */

    if(!error) {
      deleteUser();
    }
  }

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}>
        <FormControl noValidate autoComplete="off" className={classes.formControl}>
          <CustomDatePicker
            style={classes.dateField}
            required
            error={dateError}
            label={"İşten Çıkış Tarihi"}
            value={leaveDate}
            shrink={leaveDate}
            onChangeFunc={value => {
              setLeaveDate(moment(value).set("hour", 23).set("minute", 59).toISOString(true));
              dateError && setDateError(null);
            }}
          />
          <Files 
            files={files}
            setFiles={setFiles}
            length={length}
            setLength={setLength}
/*             fileError={fileError}
            setFileError={setFileError} */
          />
          <div style={{textAlign: "center"}}>
            {loading ?
            <CircularProgress color="secondary"/>
            :
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}>
              {"Kullanıcıyı Sil"}
            </Button>}
          </div>
        </FormControl>
      </TableEntryModal>  
    </>
  );
}