import React, { useCallback } from 'react';
import FetchData from './FetchData';
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from '../../../components/Table/Table';
import TableBackdrop from '../../../components/Table/TableBackdrop';
import { fileUrl } from '../../../helpers/Request';
import { Link } from '@material-ui/core';

/**
 * A table component that lists the EPDK work accidents. Read-only.
 */
export default function EDVARSWorkAccidentTable() {
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);

  const init = useCallback(async () => {
    setColumns([
      { title: "KAZAZEDE ID", field: "id" },
      { title: "EDAS", field: "edas" },
      { title: "S/N", field: "sn" },
      { title: "Elektrik Dagitim Sirketi / Yüklenici Şirket", field: "companyName" },
      { title: "Sirket SGK Sicil No", field: "companySgk" },
      // { title: "T-1 Yili Icinde Calismis Toplam Personel Sayisi", field: "tminusOneYearPersonnel" },
      { title: "Kaza Geciren Personel Adi Soyadi", field: "personnelName" },
      { title: "Kaza Geciren Personel SGK Sicil No", field: "personnelSgk" },
      { title: "Kaza Tarihi (Gun/Ay/Yil)", field: "accidentDate" },
      { title: "Kaza Yili", field: "accidentYear" },
      { title: "İş Göremezlik Raporu Başlangıç Tarihi", field: "incapacitationStart" },
      { title: "İş Göremezlik Raporu Bitiş Tarihi", field: "incapacitationEnd" },
      { title: "Is Kazasi Tipi", field: "accidentType" },
      { title: "Kayip Uzuv (Varsa)", field: "lostLimb" },
      { title: "Kayip Gun Suresi", field: "lostTime" },
      { title: "Is Kazasi Sebebi", field: "accidentReason" },
      { title: "Dosya Linki", field: "fileLink", render: rowData => 
        <Link onClick={() => window.open(rowData.fileLink)} href="#">{rowData.fileLink.split(fileUrl)}</Link>
      },
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={"İş Kazaları EDVARS"}
        authName="edvarsAccidentTable"
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        noRowActions
      />
    </>
  );
}