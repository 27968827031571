import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: 600,
  },
  textField: {
    marginBottom: "20px",
  },
}));

export default function ModalForm({
  newAccidentProps,
  setNewAccidentProps,
  loading,
  options,
  submitNewAccident,
  updateAccident,
  oldAccidentProps,
}) {
  const classes = useStyles();

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        label={"EDAŞ"}
        value={newAccidentProps.edcName}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            edcName: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Yıl"}
        value={newAccidentProps.year}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            year: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Elektrik Dağıtım Şirketi / Yüklenici Şirket"}
        value={newAccidentProps.edcOrContractorName}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            edcOrContractorName: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"Şirket SGK Sicil No"}
        value={newAccidentProps.companySgkNo}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            companySgkNo: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        label={"T-1 Yılı İçinde Çalışmış Toplam Personel Sayısı"}
        value={newAccidentProps.t1}
        onChangeFunc={(value) =>
          setNewAccidentProps({ ...newAccidentProps, t1: value.target.value })
        }
        style={classes.textField}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={oldAccidentProps ? updateAccident : submitNewAccident}
            className={classes.submit}
          >
            {oldAccidentProps ? "GÜNCELLE" : "OLUŞTUR"}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
