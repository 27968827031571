import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";

export default function CurrentVisitorsTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  //const roles = user?.roles;
  //const authorization = getAuthorizationForPage(roles, "visitors");


  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  //const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  //const [selectedVisitor, setSelectedVisitor] = React.useState(null);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  //const [dialog, setDialog] = React.useState(false);
  //const [requestLoading, setRequestLoading] = React.useState(false);


  const init = useCallback(async () => {
    setColumns([
      { title: language.currentVisitors.name, field: "name" },
      { title: language.currentVisitors.surname, field: "surname" },
      { title: language.currentVisitors.companyName, field: "companyName" },
      { title: language.currentVisitors.email, field: "email" },
      { title: language.currentVisitors.phoneNumber, field: "phoneNo" },
      { title: language.currentVisitors.hostName, field: "hostName" },
      { title: language.currentVisitors.visitedDepartmentName, field: "visitedDepartmentName" },
      { title: language.currentVisitors.entranceDate, field: "entranceDate" },
      
    ]);
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);
/*
  const removeVisitor = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/vm/visitor/delete/" + selectedVisitor?.id, selectedVisitor.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };
*/
  return (
    <React.Fragment>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      {/*<DeleteDialog
        remove={removeVisitor}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <VisitorsModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        visitorProps={selectedVisitor}
      />*/}
      <Table
        noRowActions
        tableName={language.currentVisitors.currentVisitors}
        authName="currentVisitors"
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </React.Fragment>
  );
}
