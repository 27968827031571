import Request from "../../helpers/Request";
import SessionHelper from "../../helpers/SessionHelper";

export const getTrainingTypes = async () => {
  const resp = await Request("get", "/api/enums/training-types");
  const arr = resp.data;
  let new_json = {};
  for (let i = 0; i < arr.length; i++) {
    new_json[arr[i]] = arr[i];
  }
  return new_json;
};

export const getUsers = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-company/" + companyId,
    null
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getInstructors = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/training/instructors/" + companyId,
    null
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getCities = async () => {
  const resp = await Request("get", "/api/training/record/city-list/");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let city = {};
    city["name"] = data[i].name;
    city["id"] = data[i].id;
    arr.push(city);
  }
  return arr;
};

export const getTowns = async (cityId) => {
  const resp = await Request("get", "/api/training/record/town-list/" + cityId);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let town = {};
    town["name"] = data[i].name;
    town["id"] = data[i].id;
    arr.push(town);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/training/record/company-list");
  return resp.data.map((record) => ({
    name: record.name,
    id: record.id,
  }));
};

export const getDepartments = async (companyId) => {
  const { data } = await Request(
    "get",
    "/api/training/record/department-list/" + companyId
  );
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions(companyId) {
  const user = SessionHelper.getUser();
  const trainingTypes = await getTrainingTypes();
  let cities;
  let companies = [];
  let departments;
  let users;
  [cities, companies, departments, users] = await Promise.all([
    getCities(),
    getCompanies(),
    getDepartments(companyId || user?.companyId),
    getUsers(companyId || user?.companyId),
  ]);
  const options = {
    trainingTypes,
    cities,
    companies,
    departments,
    users,
  };
  return options;
}
