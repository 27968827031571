import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import { MultiMonthDatePicker } from "../../../components/Selectors/DatePickers";
import { getDepartments } from "./GetOptions";
import TableBackdrop from "../../../components/Table/TableBackdrop";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters
 */
export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [backdropLoading, setBackdropLoading] = React.useState(false);

  const isoToDate = (dates) => {
    let startDates = [];
    for (let date of dates) {
      startDates.push(new Date(date));
    }
    return startDates;
  };

  const onChange = (dates) => {
    let months = [];
    for (let date of dates) {
      let temp = new Date(date);
      months.push(
        moment(temp).set("hour", 0).set("minute", 0).toISOString(true)
      );
      setTableFilters({ ...tableFilters, months: months });
    }
  };

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="combo-box"
                options={options.regions?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.regionIds}
                onChange={(event, value) => {
                  setTableFilters({ ...tableFilters, regionIds: value });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.contractorAudits.regions}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                multiple
                id="combo-box"
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.companyIds}
                onChange={async (event, value) => {
                  setTableFilters({ ...tableFilters, companyIds: value });
                  setBackdropLoading(true);
                  let departments = [];
                  for (let comp of value) {
                    let newDepartments = await getDepartments(comp.id);
                    // add new departments to previous ones
                    departments = departments.concat(newDepartments);
                  }
                  options.departments = departments;
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.contractorAudits.company}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                multiple
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                fullWidth={true}
                disabled={tableFilters.companyIds.length === 0}
                value={tableFilters.departmentIds}
                onChange={async (event, value) => {
                  setTableFilters({ ...tableFilters, departmentIds: value });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.contractorAudits.department}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <MultiMonthDatePicker
                placeholder={language.contractorAudits.months}
                onlyMonth
                id="task_multi_date_picker"
                minDate={new Date("2021-01-01")}
                dates={isoToDate(tableFilters.months)}
                onChangeFunc={onChange}
                required
                label={language.contractorAudits.selectedDates}
                shrink={true}
                style={{
                  width: "100%",
                  height: "40px",
                  margin: "0 auto",
                  marginTop: "20px",
                  marginRight: "50px",
                }}
              />
            </Grid>
          </Grid>
          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
