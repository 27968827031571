import React, { useCallback } from 'react';
import FetchData from './FetchData';
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from '../../../components/Table/Table';
import TableBackdrop from '../../../components/Table/TableBackdrop';

/**
 * A table component that lists the EPDK personnel number. Read-only.
 */
export default function EDVARSPersonnelNumberTable() {
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);

  const init = useCallback(async () => {
    setColumns([
      { title: "S/N", field: "id" },
      { title: "EDAŞ", field: "edcName" },
      { title: "Elektrik Dağıtım Şirketi / Yüklenici Şirket", field: "companyName" },
      { title: "Şirket SGK Sicil No", field: "companySgkNo" },
      { title: "T-1 Yılı İçinde Çalışmış Toplam Personel Sayısı", field: "t1" },
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={"Çalışan Sayısı EDVARS"}
        authName="workAccidentTable"
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        noRowActions
      />
    </>
  );
}