import React, { useCallback } from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Button,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import {
  TextArea,
  TextSelector,
} from "../../../../components/Fields/TextFields";
import { taskStatuses, roles } from "../../../../assets/constants";
import jsonMap from "../../../../components/Utils/jsonMap";
import { TableFiltersOpenButton } from "../../../../components/Table/TableFilters";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  applyButton: {},
  clearButton: {},
  buttons: {
    marginTop: 30,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters
 */
export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
  companyDisabled,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  let statuses = { ...taskStatuses };
  delete statuses.REJECTED;
  delete statuses.APPROVED;

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [departments, setDepartments] = React.useState([]);
  const [towns, setTowns] = React.useState(options?.towns);

  const init = useCallback(async () => {
    tableFilters.company && setDepartmentOptions(tableFilters.company?.name);
    tableFilters.region && setTowns(tableFilters.region?.towns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableFilters.company, tableFilters.region]);
  React.useEffect(() => {
    init();
  }, [init]);

  const setDepartmentOptions = (company) => {
    if (!company) {
      setDepartments([]);
      setTableFilters({ ...tableFilters, department: null, company: null });
    } else {
      const compsAndDepts = options.compsAndDeptsCurrentUser;
      for (let comp in compsAndDepts) {
        if (comp === company) {
          let tempArray = [];
          const depts = compsAndDepts[comp];
          for (let i = 0; i < depts.length; i++) {
            let temp = {};
            if (depts[i].id) {
              temp["name"] = depts[i].active
                ? depts[i].name
                : depts[i].name + " (Silinmiş)";
              temp["id"] = depts[i].id;
              tempArray.push(temp);
            }
          }
          setDepartments(tempArray);
        }
      }
    }
  };

  const filterHandler = () => {
    let comp = tableFilters.company;
    if (comp) {
      let cn = comp.name;
      comp.name = cn.includes(" (Silinmiş)")
        ? cn.substring(0, cn.length - 11)
        : cn;
    }
    let dept = tableFilters.department;
    if (dept) {
      let dpr = dept.name;
      dept.name = dpr.includes(" (Silinmiş)")
        ? dpr.substring(0, dpr.length - 11)
        : dpr;
    }
    setTableFilters({ ...tableFilters, company: comp, department: dept });
    setFilterProps(tableFilters);
  };

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />

      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Atanma Tarihi Başlangıç"
                value={
                  tableFilters.creationStartDate
                    ? moment(tableFilters.creationStartDate)
                    : null
                }
                shrink={tableFilters.creationStartDate}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    creationStartDate: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Atanma Tarihi Bitiş"
                value={
                  tableFilters.creationEndDate
                    ? moment(tableFilters.creationEndDate)
                    : null
                }
                shrink={tableFilters.creationEndDate}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    creationEndDate: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Tamamlanma Tarihi Başlangıç"
                value={
                  tableFilters.completionStartDate
                    ? moment(tableFilters.completionStartDate)
                    : null
                }
                shrink={tableFilters.completionStartDate}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    completionStartDate: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Tamamlanma Tarihi Bitiş"
                value={
                  tableFilters.completionEndDate
                    ? moment(tableFilters.completionEndDate)
                    : null
                }
                shrink={tableFilters.completionEndDate}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    completionEndDate: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.userName !== null}
                label={"Adı"}
                value={tableFilters.userName}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    userName: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Durumu"
                value={tableFilters.status}
                shrink={tableFilters.status !== ""}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    status: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, statuses)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.regions?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.region}
                onChange={(event, value) => {
                  //setTowns(value ? value?.towns : options?.towns);
                  setTableFilters({
                    ...tableFilters,
                    region: value,
                    town: null,
                  });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölge"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={towns?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.town}
                onChange={(event, value) => {
                  setTableFilters({ ...tableFilters, town: value });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="İlçe"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Rolü"
                value={tableFilters.role}
                shrink={tableFilters.role !== ""}
                onChangeFunc={(value) =>
                  setTableFilters({ ...tableFilters, role: value.target.value })
                }
                style={classes.textField}
                options={jsonMap(false, roles)}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.filterCompanies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={companyDisabled}
                value={tableFilters.company}
                onChange={(event, value) => {
                  //let val = value.name;
                  //value.name = val.includes(" (Silinmiş)") ? val.substring(0, val.length-11) : val;
                  setTableFilters({
                    ...tableFilters,
                    company: value,
                    department: null,
                  });
                  //setDepartmentOptions(value?.name);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Denetlenen Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, department: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Denetlenen Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container className={classes.buttons}>
            <Grid item xs={3}>
              <Button
                variant="outlined"
                className={classes.clearButton}
                onClick={() => {
                  clearFilters();
                }}
              >
                Filtreleri Temizle
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="primary"
                className={classes.applyButton}
                onClick={() => {
                  filterHandler();
                  setFilterProps(tableFilters);
                  tableRef.current && tableRef.current.onQueryChange();
                }}
              >
                Filtreleri Uygula
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          {/*<TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            onClick={() => {
              filterHandler();
              setFilterProps(tableFilters);
              tableRef.current && tableRef.current.onQueryChange();
            }}
            //setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />*/}
        </Paper>
      </Collapse>
    </>
  );
}
