import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      "page-size": query.pageSize,
      sort: "id,desc",
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "email") {
        params.sort = "email," + query.orderDirection;
      } else if (query.orderBy.field === "name") {
        params.sort = "name," + query.orderDirection;
      } else if (query.orderBy.field === "surname") {
        params.sort = "surname," + query.orderDirection;
      } else if (query.orderBy.field === "phoneNumber") {
        params.sort = "phoneNumber," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "companyName," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/vm/visit", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          departmentName: data[i].department?.name,
          departmentId: data[i].department?.id,
          hostName: data[i].host?.fName + " " + data[i].host?.lName,
          hostId: data[i].host?.id,
          visitType: data[i].visitType,
          visitTypeName: data[i].visitType?.name,
          visitTypeId: data[i].visitType?.id,
          plannedDate: new moment(data[i].plannedDate).format(
            "DD-MM-YYYY HH:mm:ss"
          ),
          participants: data[i]?.participants.map((data) => data.id),
          // participantObjects: data[i]?.participants.map((data) => ({
          //   name: data.fName + " " + data.lName,
          //   id: data.id,
          // })),
          topic: data[i].topic,
          note: data[i].note,
          status: data[i].status,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
