import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { modalFormStyles } from '../../../assets/styles/tableContainer';
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from '../../../components/Utils/jsonMap';

const useStyles = makeStyles((theme) => (modalFormStyles(theme)));

export default function ModalForm({
  requestKKDProps,
  setRequestKKDProps,
  submitNewKKD,
  options,
  kkdProps,
  updateKKD,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={"KKD Adı"}
        value={requestKKDProps.kkdName}
        onChangeFunc={(value) =>
          setRequestKKDProps({ ...requestKKDProps, kkdName: value.target.value })
        }
        style={classes.textField}
      />
      <Autocomplete
        id="combo-box"
        options={options.kkdKindsArray?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        fullWidth
        freeSolo
        autoSelect
        value={requestKKDProps.kkdKind}
        onChange={(event, value) => {
          if(value && value.name) {
            setRequestKKDProps({...requestKKDProps, kkdKind: value});
          } else {
            setRequestKKDProps({...requestKKDProps, kkdKind: {id: value, name: value}});
          }
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => <TextField required {...params} label="KKD Tipi" className={classes.textField}/>}
      />
      <p style={{color: "red", marginTop: -7}}>KKD tipi seçeneklerde yoksa, tipi yukarıdaki alana yazınız.</p>
      <TextArea
        required
        label={"Markası"}
        value={requestKKDProps.brand}
        onChangeFunc={(value) =>
          setRequestKKDProps({ ...requestKKDProps, brand: value.target.value })
        }
        style={classes.textField}
      />
      <TextArea
        required
        label={"Modeli"}
        value={requestKKDProps.model}
        onChangeFunc={(value) =>
          setRequestKKDProps({ ...requestKKDProps, model: value.target.value })
        }
        style={classes.textField}
      />
      <TextSelector
        required
        label={"Cinsi"}
        value={requestKKDProps.type}
        onChangeFunc={(value) =>
          setRequestKKDProps({ ...requestKKDProps, type: value.target.value })
        }
        style={classes.textField}
        options={jsonMap("types", options)}
      />
      <TextArea
        required
        label={"Özelliği"}
        value={requestKKDProps.characteristic}
        onChangeFunc={(value) =>
          setRequestKKDProps({ ...requestKKDProps, characteristic: value.target.value })
        }
        style={classes.textField}
      />
      <TextArea
        required
        label={"Raf Ömrü (Ay)"}
        value={requestKKDProps.shelfLife}
        onChangeFunc={(value) => {
          const val = value.target.value.replace(/\s/g, "").replace(/\D/g, "");
          if(val > 0 || val < 600) {
              setRequestKKDProps({
              ...requestKKDProps,
              shelfLife: val,
            })
          }
        }}
        style={classes.textField}
      />
      <TextArea
        required
        label={"Kullanım Ömrü (Ay)"}
        value={requestKKDProps.lifetime}
        onChangeFunc={(value) => {
          const val = value.target.value.replace(/\s/g, "").replace(/\D/g, "");
          if(val > 0 || val < 600) {
            setRequestKKDProps({
              ...requestKKDProps,
              lifetime: val,
            })
          }
        }}
        style={classes.textField}
      />
      <div style={{paddingBottom: 20, textAlign: "center"}}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => (kkdProps ? updateKKD() : submitNewKKD())}
          className={classes.submit}
        >
          {language.form.submit}
        </Button>}
      </div>
    </FormControl>
  );
}