import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { TextArea } from "../../../components/Fields/TextFields";
import { Autocomplete } from "@material-ui/lab";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { getDepartments } from "../GetOptions";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  consumptionCatalogId: null,
  departmentId: null,
  january: "0",
  february: "0",
  march: "0",
  april: "0",
  may: "0",
  june: "0",
  july: "0",
  august: "0",
  september: "0",
  october: "0",
  november: "0",
  december: "0",
};

export default function Modal({
  catalogueProps,
  recordProps,
  setRecordModal,
  modalLoading,
  setModalLoading,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const isNewRecord = catalogueProps;
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState({
    ...propsDefault,
    departmentId: user?.department?.id,
  });
  const [loading, setLoading] = React.useState(false);

  const [, setErrors] = React.useState({
    departmentError: null,
  });

  const init = useCallback(async () => {
    if (!isNewRecord && recordProps) {
      setProps({ ...recordProps });
    } else {
      const [depts] = await Promise.all([getDepartments(user?.company?.id)]);
      setOptions({
        ...options,
        departments: depts,
      });
      setProps({
        ...propsDefault,
        departmentId: user?.department?.id,
        consumptionCatalogId: catalogueProps?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordProps, catalogueProps, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      departmentError: null,
    };
    if (isNewRecord && !props.departmentId) {
      errors.departmentError = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      isNewRecord ? submitNewRecord() : updateRecord();
    }
  };

  const submitNewRecord = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/consumption-management/consumption/add",
      props
    );
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };
  const updateRecord = async () => {
    setLoading(true);
    delete props.average;
    delete props.catalogueId;
    delete props.catalogueName;
    delete props.companyId;
    delete props.companyName;
    delete props.departmentName;
    delete props.sum;
    delete props.tableData;
    const resp = await Request(
      "patch",
      "/api/consumption-management/consumption/modify",
      props
    );
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };
  const handleCloseModal = () => {
    setProps(propsDefault);
    setRecordModal(null);
  };
  return (
    <TableEntryModal
      modal={recordProps || catalogueProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        {isNewRecord && (
          <Autocomplete //DEPARTMENT
            id="combo-box"
            options={options.departments?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.departmentId
                ? getFromId(props.departmentId, options.departments)
                : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, departmentId: null });
              } else {
                setProps({ ...props, departmentId: value?.id });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Bölüm"
                className={classes.textField}
              />
            )}
          />
        )}
        <TextArea //january
          label={"Ocak"}
          value={props?.january}
          shrink={props?.january || typeof props?.january === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, january: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //february
          label={"Şubat"}
          value={props?.february}
          shrink={props?.february || typeof props?.february === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, february: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //march
          label={"Mart"}
          value={props?.march}
          shrink={props?.march || typeof props?.march === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, march: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //april
          label={"Nisan"}
          value={props?.april}
          shrink={props?.april || typeof props?.april === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, april: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //may
          label={"Mayıs"}
          value={props?.may}
          shrink={props?.may || typeof props?.may === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, may: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //june
          label={"Haziran"}
          value={props?.june}
          shrink={props?.june || typeof props?.june === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, june: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //july
          label={"Temmuz"}
          value={props?.july}
          shrink={props?.july || typeof props?.july === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, july: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //august
          label={"Ağustos"}
          value={props?.august}
          shrink={props?.august || typeof props?.august === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, august: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //september
          label={"Eylül"}
          value={props?.september}
          shrink={props?.september || typeof props?.september === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, september: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //october
          label={"Ekim"}
          value={props?.october}
          shrink={props?.october || typeof props?.october === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, october: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //november
          label={"Kasım"}
          value={props?.november}
          shrink={props?.november || typeof props?.november === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, november: value.target.value });
          }}
          style={classes.textField}
        />
        <TextArea //december
          label={"Aralık"}
          value={props?.december}
          shrink={props?.december || typeof props?.december === "number"}
          onChangeFunc={(value) => {
            setProps({ ...props, december: value.target.value });
          }}
          style={classes.textField}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewRecord ? "GÜNCELLE" : "OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
