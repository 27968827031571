import Request from "../../helpers/Request";

/**
 * Gets the department data to use in the options menu.
 * @param {companyId: number} id
 */
export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

/**
 * Gets the company data to use in the options menu.
 */
export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getRegions = async () => {
  const resp = await Request("get", "/api/cities-towns/regions");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let region = {};
    region["name"] = data[i].name;
    region["id"] = data[i].id;
    arr.push(region);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const departments = [];
  const [regions, companies] = await Promise.all([
    getRegions(),
    getCompanies(),
  ]);
  const options = { departments, companies, regions };
  return options;
}
