import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import { active } from "../../../assets/constants";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestAccidentTypeProps,
  accidentTypeProps,
  setRequestAccidentTypeProps,
  submitNewAccidentType,
  updateAccidentType,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      {accidentTypeProps && (
        <TextSelector
          label="Aktiflik Durumu"
          shrink={!accidentTypeProps.active}
          value={
            requestAccidentTypeProps.active
              ? requestAccidentTypeProps.active
              : accidentTypeProps.active
          }
          onChangeFunc={(value) =>
            setRequestAccidentTypeProps({
              ...requestAccidentTypeProps,
              active: value.target.value,
              "selection-type": "AccidentType",
            })
          }
          style={classes.textField}
          options={jsonMap(false, active)}
        />
      )}
      <TextArea
        required
        label={language.workAccidentSelections.accidentTypeName} 
        value={requestAccidentTypeProps.name}
        onChangeFunc={(value) =>
          setRequestAccidentTypeProps({
            ...requestAccidentTypeProps,
            name: value.target.value,
            "selection-type": "AccidentType",
          })
        }
        style={classes.textField}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              accidentTypeProps
                ? updateAccidentType()
                : submitNewAccidentType()
            }
            className={classes.submit}
          >
           {accidentTypeProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
