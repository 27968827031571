import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Bar, LabelList } from "recharts";
import moment from "moment";
import BarChart from "../../../components/Chart/BarChart";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import { getNearMissCount } from "./GetOptions";
import { monthsWithTwoDigits, nearMissTypes } from "../../../assets/constants";
import { Footer } from "./Settings";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red"];
const fixedHeight = 650;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  section: {
    height: window.innerHeight > 900 ? "75vh" : "70vh",
  },
}));

export default function NearMissCharts({ setBackdropLoading, filterProps }) {
  const user = SessionHelper.getUser();
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "dashboard");
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [nearMissCount, setNearMissCount] = React.useState(null);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const [nearMissCountData] = await Promise.all([
      getNearMissCount(filterProps),
    ]);
    setNearMissCount(formatDataForBarCharts(nearMissCountData));
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    filterProps.startDate,
    filterProps.endDate,
    filterProps.companyIds,
    filterProps.regionIds,
    filterProps.townIds,
    filterProps.departmentIds,
    filterProps.departmentTypes,
  ]);
  React.useEffect(() => {
    init();
  }, [init]);

  const formatDataForBarCharts = (data) => {
    let chartData = [];
    for (let p in data) {
      // find if chartdata has already an object with the same month key
      const itemIndex = chartData.findIndex(
        (e) => e.name === monthsWithTwoDigits[data[p].month]
      );
      if (itemIndex !== -1) {
        // if it has, then add new type of near miss to object
        chartData[itemIndex][nearMissTypes[data[p].type]] = data[p].count ?? 0;
      } else {
        // if it has not, then create new object with the month key
        const row = {
          name: monthsWithTwoDigits[data[p].month],
          [nearMissTypes[data[p].type]]: data[p].count ?? 0,
        };
        chartData.push(row);
      }
    }
    return chartData;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value, offset } = props;

    const xOffset = width / 2 + offset - 1;
    const yOffset = height / 2 + 5;

    return (
      <text x={x + xOffset} y={y + yOffset} fill={"black"} textAnchor="end">
        {value === 0 ? "" : value}
      </text>
    );
  };

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <BarChart
              dataSet={nearMissCount}
              title={
                "Tehlikeli Durum / Tehlikeli Davranış / Ramak Kala Sayıları"
              }
              FooterComponent={() => Footer("bar", filterProps)}
            >
              <Bar
                isAnimationActive={false}
                dataKey="Tehlikeli Durum"
                fill={COLORS[0]}
              >
                <LabelList
                  dataKey="Tehlikeli Durum"
                  position="center"
                  content={renderCustomizedLabel}
                  style={{ fill: "white" }}
                />
              </Bar>
              <Bar
                isAnimationActive={false}
                dataKey="Tehlikeli Davranış"
                fill={COLORS[1]}
              >
                <LabelList
                  dataKey="Tehlikeli Davranış"
                  position="center"
                  content={renderCustomizedLabel}
                  style={{ fill: "white" }}
                />
              </Bar>
              <Bar
                isAnimationActive={false}
                dataKey="Ramak Kala"
                fill={COLORS[2]}
              >
                <LabelList
                  dataKey="Ramak Kala"
                  position="center"
                  content={renderCustomizedLabel}
                  style={{ fill: "white" }}
                />
              </Bar>
            </BarChart>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
