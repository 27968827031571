import { companyTypes, companyUserTypes } from "../../../assets/constants";
import Request from "../../../helpers/Request";

export const getCities = async () => {
  let fetch;
  const resp = await Request("get", "/api/cities-towns/cities-full");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    new_json[json[i].id] = json[i].name;
  }
  fetch = new_json;
  return fetch;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const types = companyTypes;
  const companytypes = companyUserTypes;
  const cities = await getCities();
  const options = { types, cities, companytypes };
  return options;
}
