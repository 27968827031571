import React, { useCallback, useRef } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetFilterFields from "./CrewFilters";
import Table from "../../../components/Table/Table";
import FetchData from "./FetchData";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { GetOptions } from "./GetOptions";
import CrewModal from "./CrewModal";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import KKDDebts from "./KKDDebts";

const columns = [
  { field: "id", title: "No" },
  { field: "crewName", title: "Ekip" },
  { field: "companyName", title: "Şirket" },
  { field: "departmentName", title: "Bölüm" },
  {
    field: "memberObjects",
    title: "Üyeler",
    render: (rowData) => (
      <>
        <List
          style={{
            width: 250,
            height: 100,
            overflow: "auto",
          }}
        >
          {rowData?.memberObjects.map((data, index) => (
            <ListItem key={index}>
              <ListItemText secondary={data?.name} />
            </ListItem>
          ))}
        </List>
      </>
    ),
  },
  { field: "kkdTask", title: "KKD Görevi" },
];

export default function CrewTable() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [crewModal, setCrewModal] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterCompany: null,
    filterDepartment: null,
    filterName: null,
  });

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setCrewModal(data || {});
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const deleteCrew = async () => {
    setLoading(true);
    const resp = await Request("delete", "/api/crews/" + idToDelete);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterCompany: null,
      filterDepartment: null,
      filterName: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteCrew}
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
      />
      <CrewModal
        crewProps={crewModal}
        setCrewProps={setCrewModal}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <RenderFilters />
      <Table
        tableRef={tableRef}
        authName="crews"
        tableName="Ekipler"
        columns={columns}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={() => true}
        isEditable={() => true}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        detailsPanel={(rowData) => (
          <div
            style={{
              backgroundColor: "#2D3446",
              color: "white",
              textAlign: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <KKDDebts rowData={rowData} />
          </div>
        )}
      />
    </>
  );
}
