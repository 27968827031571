import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";
import { TextSelector } from "../../../components/Fields/TextFields";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import ParticipantsList from "../../trainings/records/ParticipantsList";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import { getDepartments, getUsers } from "./GetOptions";
import jsonMap from "../../../components/Utils/jsonMap";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));
const propsDefault = {
  id: null,
  companyId: SessionHelper.getUser()?.companyId,
  departmentId: SessionHelper.getUser()?.deparment?.id,
  plannedDate: moment().toISOString(true),
  completionDate: moment().toISOString(true),
  status: null,
  participants: [],
  notAttendedUsers: [],
  files: [],
};

export default function BoardModal({
  boardProps,
  setBoardProps,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const manager =
    user.roles.includes("ROLE_ISG_UZMANI") ||
    user.roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
    user.roles.includes("ROLE_OSGB_ISG_UZMANI");
  const isAdmin = user.roles.includes("ROLE_SİSTEM_ADMİNİ");
  const isNewBoard = boardProps && Object.keys(boardProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState(propsDefault);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({
    companyId: null,
    departmentId: null,
    plannedDate: null,
    completionDate: null,
    status: null,
  });

  const init = useCallback(async () => {
    setModalLoading(true);
    setProps({
      ...propsDefault,
      companyId: user?.companyId,
      departmentId: user?.department?.id,
    });
    if (!isNewBoard && boardProps) {
      setProps({ ...boardProps });
      setFiles(boardProps.files);
    }
    setModalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, boardProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      companyId: null,
      departmentId: null,
      plannedDate: null,
      completionDate: null,
      status: null,
      participants: null,
    };
    if (!props.companyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if (!props.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    if (!props.plannedDate) {
      errors.plannedDate = errorMessage;
      error = true;
    }
    if (!props.completionDate) {
      errors.completionDate = errorMessage;
      error = true;
    }
    if (!props.status) {
      errors.status = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      isNewBoard ? submitNewBoard() : updateBoard();
    }
  };

  const submitNewBoard = async () => {
    setLoading(true);
    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    props.participants = props?.participantObjects.map((prt) => prt.id);
    delete props.participantObjects;
    props.notAttendedUsers = props?.notAttendedUsers.map((prt) => prt.id);
    formData.append(
      "request",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("post", "/api/isgCommittee", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateBoard = async () => {
    setLoading(true);
    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    props.participants = props?.participantObjects.map((prt) => prt.id);
    delete props.participantObjects;
    props.notAttendedUsers = props?.notAttendedUsers.map((prt) => prt.id);
    formData.append(
      "request",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("patch", "/api/isgCommittee", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setProps(propsDefault);
    setBoardProps(null);
    setFiles([]);
  };

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <TableEntryModal
      modal={boardProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        {(manager || isAdmin) && (
          <Autocomplete //COMPANY
            id="combo-box"
            options={options.companies?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.companyId
                ? getFromId(props.companyId, options.companies)
                : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, companyId: null });
                setOptions({
                  ...options,
                  departments: [],
                  users: [],
                  instructors: [],
                });
              } else {
                setProps({ ...props, companyId: value?.id });
                const [depts] = await Promise.all([getDepartments(value?.id)]);
                setOptions({
                  ...options,
                  departments: depts,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.companyId}
                helperText={errors.companyId}
                label="Şirket"
                className={classes.textField}
              />
            )}
          />
        )}
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!props.companyId}
          value={
            props.departmentId
              ? getFromId(props.departmentId, options.departments)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, departmentId: null });
            } else {
              setProps({ ...props, departmentId: value?.id });
              const [users] = await Promise.all([getUsers(value?.id)]);
              setOptions({
                ...options,
                users: users,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors.departmentId}
              helperText={errors.departmentId}
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
        <TextSelector //STATUS
          label={"Durumu"}
          required
          error={errors.status}
          value={props?.status}
          shrink={props?.status}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, status: value.target.value });
            errors.status && setErrors({ ...errors, status: null });
          }}
          options={jsonMap("statuses", options)}
        />
        <CustomDatePicker //PLANNED DATE
          style={classes.dateField}
          label={"Planlanan Tarih"}
          error={errors.plannedDate}
          value={props?.plannedDate}
          shrink={true}
          onChangeFunc={(value) =>
            setProps({ ...props, plannedDate: moment(value).toISOString(true) })
          }
        />
        {props?.status === "DONE" && (
          <CustomDatePicker //COMPLETION DATE
            error={errors.completionDate}
            required
            style={classes.dateField}
            label={"Tamamlanma Tarihi"}
            value={props?.completionDate}
            shrink={props?.completionDate}
            onChangeFunc={(value) => {
              setProps({
                ...props,
                completionDate: moment(value).toISOString(true),
              });
              errors.completionDate &&
                setErrors({ ...errors, completionDate: null });
            }}
          />
        )}
        {options.users && (
          <ParticipantsList
            props={props}
            setProps={setProps}
            options={options}
          />
        )}
        <CustomFileDropzone
          files={files}
          setFiles={(value) => {
            setFiles(value);
          }}
          fileLimit={200}
          dropzoneText={"Dosya yüklemek için sürükleyin ya da buraya tıklayın."}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewBoard ? "GÜNCELLE" : "OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
