import React from 'react';
import SessionHelper from '../../helpers/SessionHelper';
import { getAuthorizationForPage } from '../../helpers/AuthorizationHelper';
import UnauthorizedPage from '../UnauthorizedPage';
import { useLocation } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import Catalogues from './catalogues/Catalogues';
import Records from './records/Records';
import { tableContainerStyles } from '../../assets/styles/tableContainer';

export default function WasteMeasurementsPage() {
  const location = useLocation();
  const roles = SessionHelper.getUser().roles;
  const recordAuth = getAuthorizationForPage(roles, "wasteMeasurementRecords");
  const catalogueAuth = getAuthorizationForPage(roles, "wasteMeasurementCatalogues");
  const page = location.pathname.substring(19);

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {((page === "records" && (recordAuth?.view ? <Records/> : <UnauthorizedPage/>)) ||
        (page === "catalogues" && (catalogueAuth?.view ? <Catalogues/> : <UnauthorizedPage/>)))}
      </Grid>
    </Container>
  );
}