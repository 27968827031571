import Request from '../../../helpers/Request';
import moment from 'moment';
import LanguageHelper from '../../../helpers/LanguageHelper';
import SessionHelper from '../../../helpers/SessionHelper';

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      startTime: filterProps.startTime,
      endTime: filterProps.endTime,
      department: filterProps.department?.name,
      company: filterProps.company?.name,
      town: filterProps.town?.name,
      region: filterProps.region?.name,
      city: filterProps.city?.name
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "responsibleCompanyName") { params.sort = "responsibleCompany.name," + query.orderDirection; }
      else if (query.orderBy.field === "edcCompanyName") { params.sort = "edcCompany.name," + query.orderDirection; }
      else if (query.orderBy.field === "companySGKSicilNo") { params.sort = "companySGKSicilNo," + query.orderDirection; }
      else if (query.orderBy.field === "accidentDateFormatted") { params.sort = "accidentDate," + query.orderDirection; }
      else if (query.orderBy.field === "accidentType") { params.sort = "accidentType.nameTr," + query.orderDirection; }
      else if (query.orderBy.field === "accidentTypeEPDK") { params.sort = "accidentTypeEPDK.nameTr," + query.orderDirection; }
      else if (query.orderBy.field === "accidentId") { params.sort = "accidentId," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "cityName") { params.sort = "city.name," + query.orderDirection; }
      else if (query.orderBy.field === "townName") { params.sort = "town.name," + query.orderDirection; }
      else if (query.orderBy.field === "reporter") { params.sort = "reporter.fname," + query.orderDirection; }
      else if (query.orderBy.field === "accidentDefinition") { params.sort = "accidentDefinition.nameTr," + query.orderDirection; }
      else if (query.orderBy.field === "summary") { params.sort = "summary," + query.orderDirection; }
      // else if (query.orderBy.field === "visibleCauses") { params.sort = "visibleCauses," + query.orderDirection; }
      else if (query.orderBy.field === "accidentPlace") { params.sort = "accidentPlace.nameTr," + query.orderDirection; }
      else if (query.orderBy.field === "instantAction") { params.sort = "instantAction," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/industrial-accidents/", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      let pageNumber = otherData.pageable.pageNumber;
      let totalElements = otherData.totalElements;
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          accidentId: data[i].accidentId,
          accidentType: data[i].accidentType?.id,
          accidentTypeEPDK: data[i].accidentTypeEPDK?.id,
          casualtyIds: data[i].casualtyIds,
          numOfCasualties: data[i].casualtyIds?.length,
          companySGKSicilNo: data[i].companySGKSicilNo,
          edcCompanyId: data[i].edcCompany?.id,
          edcCompanyName: data[i].edcCompany?.name,
          responsibleCompanyId: data[i].responsibleCompany?.id,
          responsibleCompanyName: data[i].responsibleCompany?.name,
          accidentDate: data[i].accidentDate ? data[i].accidentDate : null,
          accidentDateFormatted: data[i].accidentDate ? (new moment(data[i].accidentDate)).format("DD-MM-YYYY") : "Tarih Girilmemiş",
          accidentTime: data[i].accidentTime ? data[i].accidentTime : null,
          accidentTimeFormatted: data[i].accidentTime ? (new moment(data[i].accidentTime, 'HH:mm')).format('HH:mm') : "Saat Girilmemiş",
          active: data[i].isActive,
          totalLostTime: data[i].totalLostTime,
          reporter: data[i].reporter === "" ? null : data[i].reporter,
          city: data[i].city,
          cityName: data[i].city?.name,
          town: data[i].town,
          townName: data[i].town?.name,
          summary: data[i].summary,
          // visibleCauses: data[i].visibleCauses,
          instantAction: data[i].instantAction,
          incidentUsers: data[i].incidentUsers,
          department: data[i].department,
          departmentName: data[i].department?.name,
          departmentId: data[i].department?.id,
          accidentDefinition: data[i].accidentDefinition?.id,
          accidentPlace: data[i].accidentPlace?.id,
          severity: data[i].severity
        };
        if(!user.roles.includes("ROLE_SİSTEM_ADMİNİ")) {
          if(edit.active) {
            temp.push(edit);
          } else {
            totalElements--;
          }
        } else {
          temp.push(edit);
        }
      }
      setNumOfEntries(totalElements);
      resolve({
        data: temp,
        page: pageNumber,
        totalCount: totalElements,
      })
    }
  })
  return data;
}
