import LanguageHelper from '../../../helpers/LanguageHelper';
import Request from '../../../helpers/Request';

const language = LanguageHelper.getLanguage();

/**
 * Gets the options to use in the table fields.
 */
/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [accidentTypes, accidentReasons] = await Promise.all([
    getAccidentTypes(), getAccidentReasons()
  ]);
  const options = {accidentTypes, accidentReasons};
  return options;
};

const getAccidentTypes = async () => {
  let fetch;
  const resp = await Request("get", "/api/industrial-accidents/selections/accident-types-epdk");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if(language.title === "Türkçe") {new_json[json[i].id] = json[i].nameTr;}
    else if(language.title === "English") {new_json[json[i].id] = json[i].nameEng;}
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

const getAccidentReasons = async () => {
  let fetch;
  const resp = await Request("get", "/api/industrial-accidents/selections/accident-reasons-epdk");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if(language.title === "Türkçe") {new_json[json[i].id] = json[i].nameTr;}
    else if(language.title === "English") {new_json[json[i].id] = json[i].nameEng;}
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

