import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDropzone } from "react-dropzone";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Grid } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  dropzoneLabel: {
    fontSize: "18px",
    color: "white",
  },
  dropzoneTip: {
    fontSize: "16px",
  },
  fileLabel: {
    fontSize: "14px",
  },
  fileDeleteIcon: {
    marginLeft: "auto",
    marginRight: 0,
  },
}));

/**
 * A custom dropzone component made to be used in multiples.
 * @param {index: number, texts: arr[], formDatas: obj[], formFiles: obj, changeMade: number, setTexts: func,
 * setFormFiles: func, setChangeMade: func, formFilesToDelete: number[], setFormFilesToDelete: func,
 * setUploadedFormCount: func, uploadedFormCount: number} params props of the custom dropzone component
 */
export default function Dropzone({
  index,
  texts,
  formDatas,
  formFiles,
  changeMade,
  setTexts,
  active,
  setFormFiles,
  setChangeMade,
  formFilesToDelete,
  setFormFilesToDelete,
  setUploadedFormCount,
  uploadedFormCount,
}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const [fileExists, setFileExists] = React.useState(
    texts[index][2] ? true : false
  );
  const [uploaded, setUploaded] = React.useState(false);

  /**
   * Prepares the string to be rendered to show the file name and size.
   */
  const init = useCallback(async () => {
    if (formDatas.length > 0) {
      if (index < formDatas.length) {
        if (formDatas[index][1] !== undefined) {
          let txts = texts;
          txts[index][2] =
            formDatas[index][1] +
            (formDatas[index][3] ? " - " + formDatas[index][3] + " bytes" : "");
          setTexts(txts);
          setFileExists(true);
        }
      }
    }
  }, [formDatas, index, setTexts, texts]);
  React.useEffect(() => {
    init();
  }, [init]);

  /**
   * Keeps the uploaded file(s) in a FormData object to upload when the button is clicked.
   * Prepares the string to be rendered to show the file name and size.
   * @param {obj} acceptedFiles
   */
  const onDrop = useCallback(
    (acceptedFiles) => {
      let txts = texts;
      let form = formFiles;
      txts[index][2] =
        acceptedFiles[0].path + " - " + acceptedFiles[0].size + " bytes";
      form.append(
        "files" + (index + 1),
        acceptedFiles[0],
        acceptedFiles[0].name
      );
      setTexts(txts);
      setFormFiles(form);
      setFileExists(true);
      setUploaded(true);
      setUploadedFormCount(uploadedFormCount + 1);
      setChangeMade(changeMade + 1);
    },
    [
      texts,
      changeMade,
      formFiles,
      index,
      setChangeMade,
      setFormFiles,
      setTexts,
      setUploadedFormCount,
      uploadedFormCount,
    ]
  );

  /**
   * Opens the url of the file in another window. If the browser is capable of showing the file,
   * it is shown in browser. If not, the file is downloaded.
   * @param {obj} e used to stop propagation
   */
  const downloadFile = async (e) => {
    e.stopPropagation();
    let name = "file";
    let url = "";
    var element = document.createElement("a");
    if (uploaded) {
      for (var pair of formFiles.entries()) {
        if (pair[0] === "files" + (index + 1)) {
          name = pair[1].name;
          url = window.URL.createObjectURL(pair[1]);
        }
      }
      element.setAttribute("href", url);
      element.setAttribute("download", name);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    } else {
      name = formDatas[index][1];
      url = name;
      window.open(url);
    }
    window.URL.revokeObjectURL(url);
  };

  /**
   * If the file to be removed is a file that is uploaded by the user but not sent to the server yet,
   * the file is deleted from the texts array and the formFiles FormData object. If the file to be removed
   * is a file fetched from the server, the id of the file is added to the filesToDelete array which gets
   * deleted from the servers after the save changes button is clicked.
   * @param {obj} e used to stop propagation
   */
  const removeFile = useCallback(
    (e) => {
      e.stopPropagation();
      if (uploaded) {
        let txts = texts;
        let files = formFiles;
        files.delete("files" + (index + 1));
        txts[index][2] = null;
        setFormFiles(files);
        setTexts(txts);
        setUploaded(false);
        setUploadedFormCount(uploadedFormCount - 1);
        setChangeMade(changeMade - 1);
      } else {
        let filesToDelete = formFilesToDelete;
        filesToDelete.push(formDatas[index][2]);
        setFormFilesToDelete(filesToDelete);
        setChangeMade(changeMade + 1);
      }
      setFileExists(false);
    },
    [
      changeMade,
      setFormFiles,
      setTexts,
      setUploaded,
      setUploadedFormCount,
      setChangeMade,
      formFiles,
      formDatas,
      setFormFilesToDelete,
      formFilesToDelete,
      index,
      texts,
      uploaded,
      uploadedFormCount,
    ]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
  } = useDropzone({ maxFiles: 1, onDrop: onDrop, disabled: !active });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "gray",
    borderStyle: "dashed",
    margin: "10px",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: active ? "pointer" : "default",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: "#00e676",
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const focusedStyle = {
    borderStyle: "solid",
    borderColor: "white",
  };
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(isFileDialogActive ? focusedStyle : {}),
    }),
    [
      isDragActive,
      isDragReject,
      isDragAccept,
      isFileDialogActive,
      activeStyle,
      acceptStyle,
      rejectStyle,
      focusedStyle,
      baseStyle,
    ]
  );

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className={classes.dropzoneLabel}>{texts[index][1]}</p>
        {!fileExists && (
          <p className={classes.dropzoneTip}>
            {active
              ? language.tableDetails.dropzone
              : language.tableDetails.disabled}
          </p>
        )}
        {fileExists && (
          <Grid container direction="row">
            <IconTooltipButton
              title={language.tableDetails.downloadFile}
              onClick={(e) => downloadFile(e)}
            >
              <AttachFileIcon />
            </IconTooltipButton>
            <p className={classes.fileLabel}>{texts[index][2]}</p>
            {active && (
              <div className={classes.fileDeleteIcon}>
                <IconTooltipButton
                  title={language.tableDetails.deleteFile}
                  onClick={(e) => removeFile(e)}
                >
                  <ClearIcon />
                </IconTooltipButton>
              </div>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
}
