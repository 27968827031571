import React, { forwardRef, useCallback, useRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";
import LanguageHelper from "../../../helpers/LanguageHelper";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetFilterFields from "./CatalogueFilters";
import CatalogueModal from "./CatalogueModal";
import Table from "../../../components/Table/Table";
import FetchData from "./FetchData";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { GetOptions } from "../GetOptions";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import RecordModal from "../records/RecordModal";

const columns = [
  { field: "id", title: "No" },
  { field: "companyName", title: "Şirket" },
  { field: "wasteType", title: "Atık Tipi" },
  { field: "wasteUnit", title: "Atık Birimi" },
  { field: "wasteName", title: "Adı" },
  { field: "year", title: "Yıl" },
  { field: "statuaryLimit", title: "Yasal Limit" },
];

export default function CataloguesNew() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(
    roles,
    "wasteMeasurementCatalogues"
  );

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [catalogueModal, setCatalogueModal] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [selectedCatalogue, setSelectedCatalogue] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [recordModal, setRecordModal] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    wasteTypeFilter: null,
    wasteUnitFilter: null,
    yearFilter: null,
  });

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions(true);
    // [options.departments, options.instructors] = await Promise.all([
    //   getDepartments(dept.compId),
    //   getInstructors(dept.compId)
    // ]);
    setOptions(options);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setCatalogueModal(data || {});
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedCatalogue(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedCatalogue(null);
    setDialog(false);
  };

  const deleteCatalogue = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/waste-measurement/catalog/delete/" + selectedCatalogue?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      wasteTypeFilter: null,
      wasteUnitFilter: null,
      yearFilter: null,
    });
  };

  const actionModal = async (data) => {
    setModalLoading(true);
    setRecordModal(data || {});
    setModalLoading(false);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteCatalogue}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <RecordModal
        modalLoading={modalLoading}
        setModalLoading={setModalLoading}
        catalogueProps={recordModal}
        setRecordModal={setRecordModal}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <CatalogueModal
        catalogueProps={catalogueModal}
        setCatalogueModal={setCatalogueModal}
        options={options}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <RenderFilters />
      <Table
        tableRef={tableRef}
        authName="wasteMeasurementCatalogues"
        tableName="Atık Ölçüm Katalogları"
        columns={columns}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        //deleteEntry={deleteRecord}
        isDeletable={(rowData) => authorization?.delete}
        isEditable={(rowData) => authorization?.edit}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        extraAction={{
          auth: authorization.create,
          icon: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
          tooltip: "Atık Ölçüm Kaydı Yarat",
          position: "row",
          onClick: (rowData) => actionModal(rowData),
        }}
      />
    </>
  );
}
