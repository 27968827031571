import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import CustomFileDropzone from '../../../../../components/Files/CustomFileDropzone';
import moment from "moment";
import MaterialTable from "@material-table/core";
import LanguageHelper from "../../../../../helpers/LanguageHelper";
import { forwardRef } from "react";
import { taskStatuses } from '../../../../../assets/constants';
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { List, ListItem, ListItemText } from "@material-ui/core";
import Request from "../../../../../helpers/Request";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    width: "100%",
    maxHeight: "700px",
    margin: "0 auto",
    textAlign: "center",
    padding: 10,
    marginBottom: 10
  },
  formControl: {
    width: "100%",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  dropzone: {
    width: "350px",
  }
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Transfer: forwardRef((props, ref) => <SyncAltIcon {...props} ref={ref} />)
};

export default function MainTrainings({
  userProps,
  setSnackbar,
  setSnackbarMessage,
  setSeverity
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const columns = [
    { field: 'id', title: 'No' },
    { field: 'topics', title: 'Adı' },
    { field: 'trainingType', title: 'Eğitim Tipi' },
    { field: 'company', title: 'Şirket', render: rowData => rowData.company.name },
    { field: 'department', title: 'Bölüm', render: rowData => rowData.department.name },
    { field: 'plannedDate', title: 'Planlanan Tarih', render: rowData => moment(rowData.plannedDate).format("DD-MM-YYYY") },
    { field: 'completionDate', title: 'Tamamlanma Tarih', render: rowData => 
      rowData.completionDate ? moment(rowData.completionDate).format("DD-MM-YYYY") : "Tamamlanmadı" 
    },
    { field: 'time', title: 'Zaman' },
    { field: 'city', title: 'Eğitim İli', render: rowData => rowData.city.name },
    { field: 'towns', title: 'Eğitim İlçeleri', render: rowData => 
    <>
      <List style={{
        width: 150,
        height: 100,
        overflow: "auto"
      }}>
        {
          rowData?.towns.map((data, index) => (
            <ListItem key={index}>
              <ListItemText secondary={data?.name}/>
            </ListItem>
          ))
        }
      </List>
    </> },
    { field: 'status', title: 'Durum', lookup: taskStatuses },
    { field: 'instructors', title: 'Eğitmenler', render: rowData => 
    <>
      <List style={{
        width: 250,
        height: 100,
        overflow: "auto"
      }}>
        {
          rowData?.instructors.map((data, index) => (
            <ListItem key={index}>
              <ListItemText secondary={data?.fName + " " + data?.lName}/>
            </ListItem>
          ))
        }
      </List>
    </> },
    { field: 'participants', title: 'Katılımcılar', render: rowData => 
    <>
      <List style={{
        width: 250,
        height: 100,
        overflow: "auto"
      }}>
        {
          rowData?.participants.map((data, index) => (
            <ListItem key={index}>
              <ListItemText secondary={data?.fName + " " + data?.lName}/>
            </ListItem>
          ))
        }
      </List>
    </> },
    { field: 'trainingDocuments', title: 'Eğitim Dosyaları', render: rowData => <RenderDropzone files={rowData.trainingDocuments} />},
    { field: 'participationForms', title: 'Katılım Formu', render: rowData => <RenderDropzone files={rowData.participationForms} />},
    { field: 'otherDocuments', title: 'Diğer Dosyalar', render: rowData => <RenderDropzone files={rowData.otherDocuments} />}
  ];
  
  const [rows, setRows] = React.useState([]);

  const getTrainings = useCallback(async () => {
    const resp = await Request("get", "/api/training/record/by-user/" + userProps?.id);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    }
    return resp.data;
  }, [userProps, language, setSeverity, setSnackbarMessage, setSnackbar]);

  const init = useCallback(async () => {
    const data = formatFiles(await getTrainings());
    setRows(data);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProps, getTrainings]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderDropzone = useCallback(({files}) => {
    return(
      <div className={classes.dropzone}>
        <CustomFileDropzone
          files={files}
          setFiles={(value) => {console.log(value)}}
          fileLimit={30}
          onlyShowTitleWhenEmpty
          disabled
          dropzoneText={"Dosyalar"}
        />
      </div>
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProps]);

  const formatFiles = (trainings) => {
    for(let training of trainings) {
      let trainingDocuments = [];
      let otherDocuments = [];
      let participationForms = [];
      for(let file of training.trainingDocuments) {
        const name = file.name ? file.name : file?.fileName;
        trainingDocuments.push(new File([name], name, { type: "text/plain" }));
      }
      for(let file of training.otherDocuments) {
        const name = file.name ? file.name : file?.fileName;
        trainingDocuments.push(new File([name], name, { type: "text/plain" }));
      }
      for(let file of training.participationForms) {
        const name = file.name ? file.name : file?.fileName;
        trainingDocuments.push(new File([name], name, { type: "text/plain" }));
      }
      training.trainingDocuments = trainingDocuments;
      training.otherDocuments = otherDocuments;
      training.participationForms = participationForms;
    }
    return trainings;
  }

  return(
    <div>
      <h2>Eğitimler</h2>
      <div className={classes.root}>
        <MaterialTable
          title="Eğitimler"
          localization={language.tableLocalization}
          icons={tableIcons}
          columns={columns}
          data={rows ? [...rows] : []}
          options={{
            minBodyHeight: window.innerHeight > 900 ? "57vh" : "47vh",
            maxBodyHeight: window.innerHeight > 900 ? "57vh" : "47vh",
            emptyRowsWhenPaging: false,
          }}
        />
      </div>
    </div>
  )
}