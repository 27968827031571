import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import GetOptions from "./GetOptions";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import EPDKDetails from "./EPDKDetails";
import EPDKModal from "./EPDKModal";
import Logs from "./Logs";
import { forwardRef } from "react";
import showSnackbar from "../../../components/Utils/showSnackbar";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Request from "../../../helpers/Request";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

/**
 * A table component that lists the EPDK work accidents. Read-only.
 */
export default function EPDKWorkAccidentTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "jobRiskAnalysis");
  const ListAlt = forwardRef((props, ref) => (
    <ListAltIcon {...props} ref={ref} />
  ));

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [oldAccident, setOldAccident] = React.useState(null);
  // Delete operation related states
  const [selectedAccident, setSelectedAccident] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    const options = await GetOptions();
    setColumns([
      { title: "S/N", field: "sn" },
      { title: "Kaza No", field: "accidentId" },
      { title: "EDAŞ", field: "edcName" },
      {
        title: "Elektrik Dağıtım Şirketi / Yüklenici Şirket",
        field: "companyName",
      },
      { title: "Şirket SGK Sicil No", field: "companySgkNo" },
      //{ title: "T-1 Yılı İçinde Çalışmış Toplam Personel Sayısı", field: "nomOfPersonnelWorkedLastYear" },
      { title: "Kaza Geçiren Personel Adı Soyadı", field: "personnelName" },
      { title: "Kaza Geçiren Personel SGK Sicil No", field: "personnelSGKNo" },
      { title: "Kaza Tarihi", field: "accidentDateFormatted" },
      { title: "Kaza Yılı", field: "year" },
      {
        title: "İş Göremezlik Raporu Başlangıç Tarihi",
        field: "incapacitationStartFormatted",
      },
      {
        title: "İş Göremezlik Raporu Bitiş Tarihi",
        field: "incapacitationEndFormatted",
      },
      { title: "İş Kazası Tipi", field: "accidentType" },
      { title: "Kayıp Uzuv (Varsa)", field: "lostLimb" },
      { title: "Kayıp Gün Süresi", field: "lostDays" },
      { title: "Kaza Sebebi", field: "accidentCause" },
    ]);
    setOptions(options);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModal(true);
    setOldAccident(data);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedAccident(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedAccident(null);
    setDialog(false);
  };

  const deleteEntry = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/epdk/accident/" + selectedAccident?.accidentId
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <EPDKModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldAccidentProps={oldAccident}
        opts={options}
      />
      <DeleteDialog
        remove={deleteEntry}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Table
        tableName={language.sidebar.workaccidents_epdk}
        authName="workAccidentTable"
        columns={columns}
        tableRef={tableRef}
        //deleteEntry={deleteEntry}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={() => authorization.delete}
        detailsPanel={[
          {
            tooltip: "Dosya",
            render: (rowData) => (
              <EPDKDetails
                rowData={rowData}
                setSeverity={setSeverity}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbar={setSnackbar}
              />
            ),
          },
          {
            tooltip: "Kayıtlar",
            icon: ListAlt,
            render: (rowData) => <Logs rowData={rowData} tableRef={tableRef} />,
          },
        ]}
      />
    </>
  );
}
