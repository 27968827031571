import React from "react";
import VisitDetails from "./VisitDetails";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { useParams } from "react-router-dom";
import { tableContainerStyles } from "../../../assets/styles/tableContainer";

export default function VisitDetailsPage() {
  const { visitId, visitorId } = useParams();

  return (
    <Container maxWidth={false} style={tableContainerStyles}>
      <Grid>
        <VisitDetails visitId={visitId} visitorId={visitorId} />
      </Grid>
    </Container>
  );
}
