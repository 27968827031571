import React from 'react';
import LanguageHelper from '../../../helpers/LanguageHelper';

/**
 * Renders textInput string to indicate that the question does not have options.
 * Only used if the question is TEXTINPUT
 */
export default function TextInput() {
  const language = LanguageHelper.getLanguage();
  return (
    <h5 style={{textAlign: "center"}}>{language.form.textInput}</h5>
  )
};