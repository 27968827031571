import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, List, ListItem, ListItemText, TextField } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import '../../../index.css';
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  fields: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  textFieldFields: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%"
  },
  required: {
    marginTop: -5,
    color: "#b24d50",
    fontSize: 13
  },
}));

export default function DriversList({props, setProps, options}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const RenderUsersBox = () => {
    let users = props.driverObjects ? [...props.driverObjects] : [];

    return (
      <>
        {users && users.length > 0 ? (
          <List className={classes.list}>
            {users.map((data, i) => (
              <ListItem key={i}>
                <Grid 
                  container 
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid container xs={7} direction="row">
                    <IconTooltipButton
                      title={"Sürücüyü Sil"}
                      onClick={() => {
                        users.splice(i, 1);
                        setProps({ ...props, driverObjects: users });
                      }}
                    >
                      <DeleteIcon />
                    </IconTooltipButton>
                    <ListItemText
                      style={{ marginTop: 15 }}
                      secondary={data.name || "-HATA-"}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={classes.list} style={{ textAlign: "center" }}>
            <p style={{ color: "red", fontSize: 14, marginLeft: "auto" }}>
              Ekli Sürücü yok.
            </p>
          </div>
        )}
      </>
    );
  }

  return (
    <div style={{textAlign: "left"}}>
      <Grid item xs={6}><p className={classes.fields}>Sürücüler</p></Grid>
      <RenderUsersBox/>
      <div style={{textAlign: "center"}}>
        <Autocomplete
          id="combo-box"
          options={options.users?.sort(function(a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if(reason === "reset") {
              setAutocompleteValue("");
            } else if(reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value) => {
            if(value?.id && value !== "") {
              const users = props.driverObjects ? [...props.driverObjects] : [];
              if(!users.includes(value)) {
                users.push(value);
                setProps({...props, driverObjects: users});
              }
            }
          }}
          renderInput={(params) => <TextField {...params} label={"Sürücü Ekle"} className={classes.textFieldFields}/>}
        />
      </div>
    </div>
  );
}