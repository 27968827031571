import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 220,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5,
  },
  towns: {
    marginTop: -5,
    color: "#B8B8B8",
  },
  textFieldFields: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%",
  },
}));

const TownsList = ({ props, setProps, options }) => {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();
  const towns = options.towns || [];

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const getFromId = (id, opts) => {
    for (let i = 0; i < opts.length; i++) {
      if (opts[i].id === id) {
        return opts[i];
      }
    }
    return null;
  };

  const RenderFieldsBox = useCallback(() => {
    return (
      <div>
        {props.townIds.length > 0 ? (
          <List className={classes.list}>
            {props.townIds.map((data, index) => (
              <ListItem key={index}>
                <Grid container>
                  {/* RENDERS THE TOWNS AND THE DELETE BUTTON */}
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {props.townIds.length > 1 && (
                        <IconTooltipButton
                          title={language.form.deleteQuestion}
                          onClick={() => {
                            let towns = [...props.townIds];
                            towns.splice(index, 1);
                            setProps({ ...props, townIds: towns });
                          }}
                        >
                          <DeleteIcon />
                        </IconTooltipButton>
                      )}
                      <ListItemText
                        style={{ marginTop: 15 }}
                        secondary={
                          data.name
                            ? data.name
                            : towns
                            ? getFromId(data, towns)?.name
                            : null
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <div className={classes.list} style={{ textAlign: "center" }}>
            <p style={{ color: "red", fontSize: 14, marginLeft: "auto" }}>
              {language.errors.noTownAttached}
            </p>
          </div>
        )}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.towns, props.nonconformityUserRequest, props.townIds]);

  return (
    <div style={{ textAlign: "left" }}>
      <Grid item xs={6}>
        <h5 className={classes.towns}>{language.tableDetails.town_}</h5>
      </Grid>
      <RenderFieldsBox />
      <div style={{ textAlign: "center" }}>
        <Autocomplete //TOWN
          id="combo-box"
          options={towns?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          multiple={false}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if (reason === "reset") {
              setAutocompleteValue("");
            } else if (reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value, reason) => {
            if (value?.id && value !== "") {
              let towns = props.townIds ? [...props.townIds] : [];
              if (!towns.includes(parseInt(value?.id))) {
                towns.push(parseInt(value?.id));
                setProps({ ...props, townIds: towns });
              }
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label={language.actions.addTown}
              className={classes.textFieldFields}
            />
          )}
        />
      </div>
    </div>
  );
};

export default TownsList;
