import Request from "../../../helpers/Request";
import { taskGoal, inspectionTypes } from "../../../assets/constants";

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions(goalsPage) {
  let users = []; 
  if(!goalsPage) {
    users = await getAllNonCommitteeUsers();
  }
  const options = {
    taskGoal, 
    inspectionTypes, 
    users
  };
  return options;
};

const getAllNonCommitteeUsers = async () => {
  const resp = await Request("get", "/api/users/except-life-community-members");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
}