import React, { useCallback } from 'react';
import SessionHelper from '../../../helpers/SessionHelper';
import IconTooltipButton from '../../../components/Buttons/IconTooltipButton';
import DoneIcon from '@material-ui/icons/Done';
import LanguageHelper from '../../../helpers/LanguageHelper';
import Request from '../../../helpers/Request';
import showSnackbar from '../../../components/Utils/showSnackbar';
import { TextSelector } from '../../../components/Fields/TextFields';

export default function StatusColumn({rowData, roles, jsonMap, statuses, setSeverity, setSnackbarMessage, setSnackbar, tableRef}) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();

  const [dropdown, setDropdown] = React.useState({});
  const [disabled, setDisabled] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [save, setSave] = React.useState(false);

  const init = useCallback(async () => {
    setSave(false);
    setStatus(rowData.eStatus);
    if(rowData.eStatus === "DONE" && roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ")) {
      setDropdown({
        "DONE": "Onay Bekliyor",
        "APPROVED": "Onaylandı"
      });
    }
    else if(rowData.eStatus === "TODO") {
      setDropdown({
        "TODO": "Başlanmadı",
        "DONE": "Onay Bekliyor"
      })
    } else {
      setDropdown(statuses);
      setDisabled(true);
    }
    if(!roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ")) {
      user.id !== rowData.user && setDisabled(true);
    } else {
      if(!((user.id !== rowData.user && (rowData.eStatus === "DONE" || rowData.eStatus === "APPROVED")) || (user.id === rowData.user))) {
        setDisabled(true);
      } 
    }
  }, [rowData, roles, statuses, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const changeStatus = async () => {
    let resp;
    if(rowData.eStatus === "APPROVED" ) {
      const params = {
        "status": "APPROVED",
        "noncorformity-id": rowData.id,
      };
      resp = await Request('post', '/api/unsuitInspections/' + rowData.id + '/set-approved', null, params);
    } else {
      const body = {
        "status": rowData.eStatus,
      };
      resp = await Request('post', '/api/unsuitInspections/' + rowData.id + '/change-status-v2', body);
    }
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    setSave(false);
  }

  return(
    <div style={{display: "flex", flexDirection: "row"}}>
      <TextSelector 
        required
        disabled={disabled}
        value={status}
        onChangeFunc={value => {
          rowData.eStatus = value.target.value;
          setStatus(value.target.value);
          setSave(true);
        }}
        options={jsonMap(dropdown)}
      />
      <div style={{marginLeft: "20px"}}>
      {save &&
        <IconTooltipButton title={language.form.approveChange} onClick={changeStatus}>
          <DoneIcon />
        </IconTooltipButton>
      }
      </div>
    </div>
  ) 
}