import React from "react";
import ModuleTable from "./modules/ModuleTable";
import FunctionTable from "./functions/FunctionTable";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import SessionHelper from "../../helpers/SessionHelper";
import UnauthorizedPage from "../UnauthorizedPage";
import { useLocation } from "react-router-dom";
import RoleAuthTable from "./roleauths/RoleAuthTable";

export default function RoleAuthsPage() {
  const roles = SessionHelper.getUser().roles;
  const location = useLocation();
  const page = location.pathname.substring(1);
  const moduleAuth = getAuthorizationForPage(roles, "moduleTable");
  const roleAuths = getAuthorizationForPage(roles, "authTable");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {(page === "modules" &&
          (moduleAuth?.view ? <ModuleTable /> : <UnauthorizedPage />)) ||
          (page === "functions" &&
            (moduleAuth?.view ? <FunctionTable /> : <UnauthorizedPage />)) ||
          (page === "role-auths" &&
            (roleAuths?.view ? <RoleAuthTable /> : <UnauthorizedPage />))}
      </Grid>
    </Container>
  );
}
