import React, { useCallback, useRef } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import showSnackbar from "../../../components/Utils/showSnackbar";
import moment from "moment";
import { taskStatuses } from "../../../assets/constants";
import GetFilterFields from "./BoardsFilters";
import Table from "../../../components/Table/Table";
import FetchData from "./FetchData";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { GetOptions } from "./GetOptions";
import BoardModal from "./BoardModal";
import Decisions from "./Decisions";
import SessionHelper from "../../../helpers/SessionHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

const columns = [
  { field: "id", title: "No" },
  { field: "customId", title: "Kurul No" },
  { field: "companyName", title: "Şirket" },
  { field: "departmentName", title: "Bölüm" },
  {
    field: "plannedDate",
    title: "Planlanan Tarih",
    render: (rowData) => moment(rowData.plannedDate).format("DD-MM-YYYY"),
  },
  {
    field: "completionDate",
    title: "Tamamlanma Tarih",
    render: (rowData) =>
      rowData.completionDate
        ? moment(rowData.completionDate).format("DD-MM-YYYY")
        : "Tamamlanmadı",
  },
  { field: "status", title: "Durum", lookup: taskStatuses },
  {
    field: "participantObjects",
    title: "Katılımcılar",
    render: (rowData) => (
      <>
        <List
          style={{
            width: 250,
            height: 100,
            overflow: "auto",
          }}
        >
          {rowData?.participantObjects.map((data, index) => (
            <ListItem key={index}>
              <ListItemText secondary={data?.name} />
            </ListItem>
          ))}
        </List>
      </>
    ),
  },
];

export default function Boards() {
  const tableRef = useRef();
  const language = LanguageHelper.getLanguage();
  const user = SessionHelper.getUser();
  const id = user?.company?.id;
  const isAdmin = user?.roles.includes("ROLE_SİSTEM_ADMİNİ");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [boardModal, setBoardModal] = React.useState(null);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState(false);
  const [idToDelete, setIdToDelete] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterCompany: null,
    filterDepartment: null,
    filterStatus: null,
    filterPlannedStart: null,
    filterPlannedEnd: null,
    filterCompletionStart: null,
    filterCompletionEnd: null,
  });

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setBoardModal(data || {});
  };

  const handleOpenDeleteDialog = (data) => {
    setDialog(true);
    setIdToDelete(data?.id);
  };

  const handleCloseDeleteDialog = () => {
    setDialog(false);
    setIdToDelete(null);
  };

  const deleteBoard = async () => {
    setLoading(true);
    const resp = await Request("delete", "/api/isgCommittee/" + idToDelete);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      // company: isSpecial ? null : user?.company,
      // department: null,
      // instructor: null,
      // trainingType: null,
      // city: null,
      // town: null,
      // plannedDateStart: null,
      // plannedDateEnd: null,
      // completionDateStart: null,
      // completionDateEnd: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteBoard}
        open={dialog}
        loading={loading}
        close={handleCloseDeleteDialog}
        customTitle={
          "Kurula bağlı bütün kararlar ve aksiyonlar da silinecek, devam etmek istediğine emin misin?"
        }
      />
      <BoardModal
        boardProps={boardModal}
        setBoardProps={setBoardModal}
        options={options}
        setOptions={setOptions}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        tableRef={tableRef}
      />
      <RenderFilters />
      <Table
        tableRef={tableRef}
        authName="boards"
        tableName="İSG Kurulları"
        columns={columns}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => isAdmin || rowData?.companyId === id}
        isEditable={(rowData) => isAdmin || rowData?.companyId === id}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        detailsPanel={(rowData) => (
          <div
            style={{
              backgroundColor: "#2D3446",
              color: "white",
              textAlign: "center",
              alignItems: "center",
              padding: 20,
            }}
          >
            <Decisions
              rowData={rowData}
              options={options}
              setSeverity={setSeverity}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbar={setSnackbar}
            />
          </div>
        )}
      />
    </>
  );
}
