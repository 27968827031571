import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

/**
 * Fetches the table data every time the table is updated (sort, search, page change, page size change)
 * @param {obj} query a json object used by the table to store the sort, search, page etc. actions
 * @param {func} setData a function to set the newly received data
 * An important note about setData: data state itself is never used directly, only the setData function
 * is used to update the page when new data is received.
 * @returns {obj} a promise object that resolves the data to be used in the table
 */
export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "edcOrContractorName," + query.orderDirection;
      } else if (query.orderBy.field === "edcName") {
        params.sort = "edcName," + query.orderDirection;
      } else if (query.orderBy.field === "t1") {
        params.sort = "t1," + query.orderDirection;
      } else if (query.orderBy.field === "companySgkNo") {
        params.sort = "companySgkNo," + query.orderDirection;
      } else if (query.orderBy.field === "year") {
        params.sort = "year," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/epdk/user", null, params);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          companySgkNo: data[i].companySgkNo,
          t1: data[i].t1,
          companyName: data[i].edcOrContractorName,
          edcName: data[i].edcName,
          year: data[i].year,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
