import React, { useCallback } from "react";
import moment from "moment";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import Table from "../../../../components/Table/Table";
import FetchData from "./FetchData";

export default function NearMisses({
  id,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();

  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [filterProps] = React.useState({
    user: id,
  });

  const init = useCallback(async () => {
    setLoading(true);
    setColumns([
      {
        title: language.tableColumns.id,
        field: "id",
      },
      {
        field: "incidentDate",
        title: language.nearMiss.incidentDate,
        render: (rowData) => moment(rowData.incidentDate).format("DD-MM-YYYY"),
      },
      {
        title: language.nearMiss.incidentType,
        field: "incidentType",
      },
      { title: language.nearMiss.company, field: "company" },
      { title: language.nearMiss.department, field: "department" },
      {
        title: language.nearMiss.description,
        field: "description",
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={loading} />
      <Table
        noRowActions
        tableRef={tableRef}
        authName="usersTable"
        tableName={language.nearMiss.tableName}
        columns={columns}
        filters={filterProps}
        fetchData={FetchData}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        height={window.innerHeight > 900 ? "57vh" : "47vh"}
      />
    </>
  );
}
