import React, { useCallback } from "react";
import moment from "moment";
import { List, ListItem, ListItemText } from "@material-ui/core";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import Table from "../../../../components/Table/Table";
import FetchData from "./FetchData";
import { inspectionTypes, status } from "../../../../assets/constants";

export default function Inspections({
  id,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();

  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [filterProps] = React.useState({
    user: id,
  });

  const init = useCallback(async () => {
    setLoading(true);
    setColumns([
      {
        title: language.audits.customId,
        field: "customId",
      },
      {
        field: "completionDate",
        title: language.audits.completionDate,
        render: (rowData) =>
          moment(rowData.completionDate).format("DD-MM-YYYY"),
      },
      {
        title: language.audits.inspectionType,
        field: "inspectionType",
        lookup: inspectionTypes,
      },
      { title: language.audits.company, field: "company" },
      { title: language.audits.department, field: "department" },
      {
        title: language.audits.unsuitability,
        field: "personalUnsuitability",
        lookup: status,
      },
      {
        title: language.audits.description,
        field: "text",
      },
      {
        field: "questions",
        title: language.audits.questions,
        render: (rowData) => (
          <>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData?.questions.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data} />
                </ListItem>
              ))}
            </List>
          </>
        ),
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <TableBackdrop backdropLoading={loading} />
      <Table
        noRowActions
        tableRef={tableRef}
        authName="usersTable"
        tableName={language.audits.tableName}
        columns={columns}
        filters={filterProps}
        fetchData={FetchData}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        height={window.innerHeight > 900 ? "57vh" : "47vh"}
      />
    </>
  );
}
