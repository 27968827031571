import Request from "../../../helpers/Request";

export const getEquipments = async () => {
  const resp = await Request("get", "/api/vm/drop-down/equipments/");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let eq = {};
    eq["name"] = content[i].name;
    eq["id"] = content[i].id;
    arr.push(eq);
  }
  return arr;
};

export const getDocuments = async () => {
  const resp = await Request("get", "/api/vm/drop-down/documents/");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let doc = {};
    doc["name"] = content[i].name;
    doc["id"] = content[i].id;
    arr.push(doc);
  }
  return arr;
};

export const getVideos = async () => {
  const resp = await Request("get", "/api/vm/drop-down/videos/");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let video = {};
    video["name"] = content[i].name;
    video["id"] = content[i].id;
    arr.push(video);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions() {
  const [equipments, documents, videos] = await Promise.all([
    getEquipments(),
    getDocuments(),
    getVideos(),
  ]);
  const options = {
    equipments,
    documents,
    videos,
  };
  return options;
}
