import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function AuthModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  authProps,
  options,
  setOptions,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestAuthProps, setRequestAuthProps] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setRequestAuthProps({
      roleId: authProps ? authProps.roleId : null,
      moduleId: authProps ? authProps.moduleId : null,
      moduleFunctionId: authProps ? authProps.functionId : null,
      createOpNote: authProps ? authProps.createOpNote : null,
      createOpWeb: authProps ? authProps.createOpWeb : null,
      createOpMobile: authProps ? authProps.createOpMobile : null,
      deleteOpMobile: authProps ? authProps.deleteOpMobile : null,
      deleteOpNote: authProps ? authProps.deleteOpNote : null,
      deleteOpWeb: authProps ? authProps.deleteOpWeb : null,
      updateOpMobile: authProps ? authProps.updateOpMobile : null,
      updateOpNote: authProps ? authProps.updateOpNote : null,
      updateOpWeb: authProps ? authProps.updateOpWeb : null,
      readOpNote: authProps ? authProps.readOpNote : null,
      readOpWeb: authProps ? authProps.readOpWeb : null,
      readOpMobile: authProps ? authProps.readOpMobile : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestAuthProps({
      roleId: null,
      moduleId: null,
      moduleFunctionId: null,
      createOpNote: null,
      createOpWeb: null,
      createOpMobile: null,
      deleteOpMobile: null,
      deleteOpNote: null,
      deleteOpWeb: null,
      updateOpMobile: null,
      updateOpNote: null,
      updateOpWeb: null,
      readOpNote: null,
      readOpWeb: null,
      readOpMobile: null,
    });
  };

  const updateAuth = async () => {
    setLoading(true);
    let props = { ...requestAuthProps };
    props.id = authProps.id;
    const resp = await Request("put", "/api/authority/authority", props);

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const submitNewAuth = async () => {
    setLoading(true);
    const resp = await Request(
      "post",
      "/api/authority/authority",
      requestAuthProps
    );

    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestAuthProps={requestAuthProps}
              authProps={authProps}
              setRequestAuthProps={setRequestAuthProps}
              updateAuth={updateAuth}
              submitNewAuth={submitNewAuth}
              loading={loading}
              options={options}
              setOptions={setOptions}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
