import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { getDepartments, getTowns } from "./GetOptions";
import TableBackdrop from "../../../components/Table/TableBackdrop";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the charts.
 * @param {options: obj} param main parameters for the filters
 */
export default function GetFilterFields({
  options,
  chartsRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [chartsFilters, setChartsFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [backdropLoading, setBackdropLoading] = React.useState(false);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                multiple
                options={options.regions?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={chartsFilters.regionIds}
                onChange={async (event, value) => {
                  console.log(value)
                  setChartsFilters({
                    ...chartsFilters,
                    regionIds: value,
                  });
                  setBackdropLoading(true);
                  let towns = [];
                  for (let region of value) {
                    let newTowns = await getTowns(region.id);
                    towns = towns.concat(newTowns);
                  }
                  options.towns = towns;
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölge"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                multiple
                options={options.towns?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={chartsFilters.regionIds.length === 0}
                value={chartsFilters.townIds}
                onChange={(event, value) =>
                  setChartsFilters({ ...chartsFilters, townIds: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="İlçe"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                multiple
                options={options.companies?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={chartsFilters.companyIds}
                onChange={async (event, value) => {
                  setChartsFilters({ ...chartsFilters, companyIds: value });
                  setBackdropLoading(true);
                  let departments = [];
                  for (let comp of value) {
                    let newDepartments = await getDepartments(comp.id);
                    departments = departments.concat(newDepartments);
                  }
                  options.departments = departments;
                  setBackdropLoading(false);
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Şirket"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                multiple
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={chartsFilters.companyIds.length === 0}
                value={chartsFilters.departmentIds}
                onChange={async (event, value) => {
                  setChartsFilters({ ...chartsFilters, departmentIds: value });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                multiple
                options={options.departmentTypes?.sort(function (a, b) {
                  return collator.compare(a, b);
                })}
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                fullWidth={true}
                value={chartsFilters.departmentTypes}
                onChange={async (event, value) => {
                  console.log(value)
                  setChartsFilters({
                    ...chartsFilters,
                    departmentTypes: value,
                  });
                }}
                renderOption={(option) => <span>{option}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm Faaliyet Tipi"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Başlangıç Tarihi"
                value={
                  chartsFilters.startDate
                    ? moment(chartsFilters.startDate)
                    : null
                }
                shrink={chartsFilters.startDate}
                onChangeFunc={(value) =>
                  setChartsFilters({
                    ...chartsFilters,
                    startDate: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(false),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label="Bitiş Tarihi"
                value={
                  chartsFilters.endDate ? moment(chartsFilters.endDate) : null
                }
                shrink={chartsFilters.endDate}
                onChangeFunc={(value) =>
                  setChartsFilters({
                    ...chartsFilters,
                    endDate: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(false),
                  })
                }
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={chartsRef}
            newTableFilters={chartsFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
