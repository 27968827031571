import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import {
  getTowns,
  getDepartments,
  getInstructors,
  getCities,
} from "../GetOptions";
import SessionHelper from "../../../helpers/SessionHelper";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import { TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import LanguageHelper from "../../../helpers/LanguageHelper";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();

  //const { REJECTED, APPROVED, ...statuses } = taskStatuses;

  const isSpecial =
    user?.roles.includes("ROLE_SİSTEM_ADMİNİ") ||
    user?.roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
    user?.roles.includes("ROLE_ISG_UZMANI") ||
    user?.roles.includes("ROLE_OSGB_ISG_UZMANI");

  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <div>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.actions.plannedStartDate}
                value={
                  tableFilters.plannedDateStart
                    ? moment(tableFilters.plannedDateStart)
                    : null
                }
                shrink={tableFilters.plannedDateStart}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    plannedDateStart: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.actions.plannedEndDate}
                value={
                  tableFilters.plannedDateEnd
                    ? moment(tableFilters.plannedDateEnd)
                    : null
                }
                shrink={tableFilters.plannedDateEnd}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    plannedDateEnd: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.actions.completionStart}
                value={
                  tableFilters.completionDateStart
                    ? moment(tableFilters.completionDateStart)
                    : null
                }
                shrink={tableFilters.completionDateStart}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    completionDateStart: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.actions.completionEnd}
                value={
                  tableFilters.completionDateEnd
                    ? moment(tableFilters.completionDateEnd)
                    : null
                }
                shrink={tableFilters.completionDateEnd}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    completionDateEnd: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            {isSpecial && (
              <Grid item xs={6}>
                <Autocomplete //COMPANY
                  id="combo-box"
                  options={options.companies?.sort(function (a, b) {
                    return collator.compare(a.name, b.name);
                  })}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  fullWidth={true}
                  value={tableFilters.company}
                  onChange={async (event, value) => {
                    setBackdropLoading(true);
                    if (!value) {
                      options.departments = [];
                      options.instructors = [];
                    } else {
                      [options.departments, options.instructors] =
                        await Promise.all([
                          getDepartments(value?.id),
                          getInstructors(value?.id),
                        ]);
                    }
                    setTableFilters({ ...tableFilters, company: value });
                    setBackdropLoading(false);
                  }}
                  renderOption={(option) => <span>{option.name}</span>}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={language.auditTasks.company}
                      className={classes.textField}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.department}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (value === null) {
                    setTableFilters({ ...tableFilters, department: null });
                    options.cities = [];
                  } else {
                    setTableFilters({ ...tableFilters, department: value });
                    options.cities = await getCities(value?.id);
                  }
                  setBackdropLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.auditTasks.department}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete //CITY
                id="combo-box"
                options={options.cities?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.city}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (value === null) {
                    setTableFilters({ ...tableFilters, city: null });
                    options.towns = [];
                  } else {
                    setTableFilters({ ...tableFilters, city: value });
                    options.towns = await getTowns(value?.id);
                  }
                  setBackdropLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label={language.auditTasks.educationCity}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.towns?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.city}
                value={tableFilters.town}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, town: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.auditTasks.educationDistrict}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.tableColumns.educationType}
                value={tableFilters.trainingType}
                shrink={tableFilters.trainingType}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    trainingType: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap("trainingTypes", options)}
              />
            </Grid>
            {/* <Grid item xs={6}> 
              <TextSelector
                label="Durumu"
                value={tableFilters.status}
                shrink={tableFilters.status !== null}
                onChangeFunc={value => setTableFilters({...tableFilters, status: value.target.value})}
                style={classes.textField}
                options={jsonMap(false, statuses)}
              />
            </Grid> */}
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.instructors?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.company}
                value={tableFilters.instructor}
                onChange={(event, value) =>
                  setTableFilters({ ...tableFilters, instructor: value })
                }
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.tableColumns.instructor}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </div>
  );
}
