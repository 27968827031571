import React, { useCallback } from "react";
import ModalForm from './ModalForm';
import TableEntryModal from "../../../../components/Modal/TableEntryModal";

export default function EmergencyPlanModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldEmergencyPlanProps,
  setOldEmergencyPlanProps,
  options,
  setOptions,
  modalLoading
}) {
  const defaultProps = {
    companyId: null,
    departmentId: null,
    plannedDate: null,
    validationDate: null,
    planFile: null,
    files: []
  }

  const [emergencyPlanProps, setEmergencyPlanProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldEmergencyPlanProps) {
      setEmergencyPlanProps({
        companyId: oldEmergencyPlanProps.company || null,
        departmentId: oldEmergencyPlanProps.department || null,
        plannedDate: oldEmergencyPlanProps.plannedDate || null,
        validationDate: oldEmergencyPlanProps.validationDate || null,
        planFile: oldEmergencyPlanProps.planFile || null,
        files: oldEmergencyPlanProps.files || []
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldEmergencyPlanProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setEmergencyPlanProps(defaultProps);
      setOldEmergencyPlanProps(null);
    }, 300);
  };

  return (
    <>
      <TableEntryModal modal={modal} handleCloseModal={handleCloseModal} modalLoading={modalLoading}>
        <ModalForm
          emergencyPlanProps={emergencyPlanProps}
          setEmergencyPlanProps={setEmergencyPlanProps}
          label={oldEmergencyPlanProps ? "GÜNCELLE" : "OLUŞTUR"}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
          handleCloseModal={handleCloseModal}
          oldEmergencyPlanProps={oldEmergencyPlanProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </>
  );
}