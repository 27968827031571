import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";
import { taskStatuses } from "../../../assets/constants";

/**
 * Gets the department data to use in the options menu.
 * @param {companyId: number} id
 */
export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

/**
 * Gets the company data to use in the options menu.
 */
export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getUsers = async (companyId) => {
  const resp = await Request("get", "/api/users/by-user-company", null);
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const user = SessionHelper.getUser();
  const [users, departments, companies] = await Promise.all([
    getUsers(user?.companyId),
    getDepartments(user?.companyId),
    getCompanies(),
  ]);
  const statuses = { ...taskStatuses };
  statuses.DONE = "Onay Bekliyor";
  const options = { departments, companies, statuses, users };
  return options;
}
