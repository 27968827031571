import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      // name: "",
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      }
      if (query.orderBy.field === "customId") {
        params.sort = "customId," + query.orderDirection;
      }
      if (query.orderBy.field === "notifyingUser") {
        params.sort = "user.fName,user.lName" + query.orderDirection;
      }
      if (query.orderBy.field === "incidentType") {
        params.sort = "nearMissType," + query.orderDirection;
      }
      if (query.orderBy.field === "incidentDate") {
        params.sort = "incidentDate," + query.orderDirection;
      }
      if (query.orderBy.field === "notificationDate") {
        params.sort = "notificationDate," + query.orderDirection;
      }
      if (query.orderBy.field === "company") {
        params.sort = "company.name," + query.orderDirection;
      }
      if (query.orderBy.field === "department") {
        params.sort = "department.name," + query.orderDirection;
      }
      if (query.orderBy.field === "townName") {
        params.sort = "town.name," + query.orderDirection;
      }
      if (query.orderBy.field === "description") {
        params.sort = "note," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/quality-controls", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          productType: data[i].productType,
          jobNumber: data[i].jobNumber,
          assignedUser: data[i].user?.fName + " " + data[i].user?.lName,
          shiftStartLotNo: data[i].shiftStartLotNo,
          date: data[i].date,
          dateFormatted: moment(data[i].date).format("DD-MM-YYYY"),
          company: data[i].company?.name,
          companyId: data[i].company?.id,
          shift: data[i].shift,
          forms: data[i].forms,
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
