import React from 'react';
import { Button, DialogActions, DialogTitle, Dialog } from '@material-ui/core';
import LanguageHelper from '../../../helpers/LanguageHelper';

/**
 * A dialog that pops up when a delete button is pressed.
 * When the delete button is pressed, either goes to the removeQuestion function,
 * or the handleRemoveOption function depending on the state of the optionIndex.
 * Dialog is closed if the close button is pressed instead.
 * @param {openDialog: boolean, handleDialogClose: func, optionIndex: number, 
 * removeQuestion: func, handleRemoveOption: func} param props of the delete dialog box
 */
export default function DeleteDialog({ openDialog, handleDialogClose, optionIndex, removeQuestion, handleRemoveOption }) {
  const language = LanguageHelper.getLanguage();
  return (
    <Dialog
      open={openDialog}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{language.form.alert}</DialogTitle>
      <DialogActions>
        <Button onClick={optionIndex === null ? () => removeQuestion() : () => handleRemoveOption(optionIndex)} color="secondary">
          {language.form.delete}
        </Button>
        <Button onClick={handleDialogClose} color="secondary" autoFocus>
          {language.form.closeDialog}
        </Button>
      </DialogActions>
    </Dialog>
  )
}