import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import { active } from "../../../assets/constants";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestBodyPartProps,
  bodyPartProps,
  setRequestBodyPartProps,
  submitNewBodyPart,
  updateBodyPart,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      {bodyPartProps && (
        <TextSelector
          label="Aktiflik Durumu"
          shrink={!bodyPartProps.active}
          value={
            requestBodyPartProps.active
              ? requestBodyPartProps.active
              : bodyPartProps.active
          }
          onChangeFunc={(value) =>
            setRequestBodyPartProps({
              ...requestBodyPartProps,
              active: value.target.value,
              "selection-type": "BodyPart",
            })
          }
          style={classes.textField}
          options={jsonMap(false, active)}
        />
      )}
      <TextArea
        required
        label={language.workAccidentSelections.bodyPart} //will be changed
        value={requestBodyPartProps.name}
        onChangeFunc={(value) =>
          setRequestBodyPartProps({
            ...requestBodyPartProps,
            name: value.target.value,
            "selection-type": "BodyPart",
          })
        }
        style={classes.textField}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              bodyPartProps
                ? updateBodyPart()
                : submitNewBodyPart()
            }
            className={classes.submit}
          >
            {bodyPartProps? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
