import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, CircularProgress } from "@material-ui/core";
import OtherFiles from "./OtherFiles";
import FormFilesSection from "./FormFilesSection";
import Request from "../../../helpers/Request";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  details: {
    fontSize: 30,
    textAlign: "center",
    color: "white",
  },
  dividerStyle: {
    margin: 20,
  },
}));

/**
 * Details panel component, renders the files if exists and has a dropzone
 * to upload files.
 * @param {rowData: obj, tableRef: obj} props to use in the details panel
 */
export default function Details({ rowData, casualty, tableRef }) {
  const classes = useStyles();
  const [forms, setForms] = useState({});
  const [otherFiles, setOtherFiles] = useState([]);
  const [loading, setLoading] = React.useState(true);

  const getCasualtyForms = useCallback(async () => {
    const resp = await Request(
      "get",
      `/api/industrial-accidents/casualties/${rowData.casualtyIds[casualty]}/files`
    );
    setForms(resp.data.casualtyForms);
    setOtherFiles(resp.data.fileKeys)
    setLoading(false);

    //setIdAction(resp.data.content.id);
    // setAssignedByShortCompanyName(
    //   resp.data.content[0].assignedByShortCompanyName
    // );
    return resp;
  }, []);

  useEffect(() => {
    getCasualtyForms();
  }, [getCasualtyForms]);

  return loading ? (
    <CircularProgress color="secondary" />
  ) : (
    <div className={classes.details}>
      <FormFilesSection
        rowData={rowData}
        casualty={casualty}
        tableRef={tableRef}
        forms={forms}
      />
      <Divider className={classes.dividerStyle} />
      <OtherFiles rowData={rowData} casualty={casualty} forms={otherFiles} />
    </div>
  );
}
