import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Divider,
  Fade,
  Grid,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import { modalStyles } from "../../../assets/styles/tableContainer";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import VisitorForm from "./VisitorForm";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { getVisitors } from "./GetOptions";

const useOtherStyles = makeStyles({
  addButton: {
    marginLeft: "40%",
  },
  accidentsContainer: {
    minHeight: "2vw",
    border: "1px solid #C7C7C7",
    borderRadius: "5px",
  },
  groupContainer: {
    marginBottom: "20px",
  },
});

const useStyles = makeStyles((theme) => modalStyles(theme));

const propsDefault = {
  visitorCompanyId: null,
  visitorId: null,
  equipmentIds: [],
  documentIds: [],
  videoIds: [],
};

export default function Group({ options, visitors, setVisitors, loading }) {
  const classes = useStyles();
  const otherStyles = useOtherStyles();
  const language = LanguageHelper.getLanguage();

  const [oldPersonnelPropsShell, setOldPersonnelPropsShell] =
    React.useState(propsDefault);
  const [modal, setModal] = React.useState(false);
  const [index, setIndex] = React.useState(null);
  const [visitorList, setVisitorList] = React.useState([]);
  // const [loading, setLoading]=React.useState(false);

  const init = useCallback(async () => {
    const options = await getVisitors();
    setVisitorList(options);
  }, [visitors]);

  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data, index) => {
    setOldPersonnelPropsShell(data);
    setIndex(index);
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const getPersonnelFromId = (id) => {
    for (let i = 0; i < visitorList.length; i++) {
      if (visitorList[i].id === id) {
        return visitorList[i];
      }
    }
    return null;
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const PrintAccidents = () => {
    visitors = getUniqueListBy(visitors, "visitorId");
    return visitors.map((data, index) => {
      return (
        <div>
          {index > 0 && <Divider />}
          <Grid container direction="row" style={{ margin: 10 }}>
            <Grid xs={10}>
              <p>{getPersonnelFromId(data.visitorId)?.name}</p>
            </Grid>
            <Grid xs={2}>
              <IconTooltipButton
                title={language.visits.deleteVisitor}
                onClick={() => {
                  let temp = [...visitors];
                  temp.splice(index, 1);
                  setVisitors(temp);
                }}
              >
                <DeleteIcon />
              </IconTooltipButton>
              <IconTooltipButton
                title={language.visits.updateVisitor}
                onClick={() => {
                  handleOpenModal(data, index);
                }}
              >
                <EditIcon />
              </IconTooltipButton>
            </Grid>
          </Grid>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <Grid container direction="row" className={otherStyles.groupContainer}>
        <Grid item xs={11} className={otherStyles.accidentsContainer}>
          <PrintAccidents />
        </Grid>
        <Grid item xs={1}>
          <div className={otherStyles.addButton}>
            <IconTooltipButton
              title={language.visits.addVisitor}
              onClick={() => handleOpenModal(null)}
            >
              <AddBoxIcon />
            </IconTooltipButton>
          </div>
        </Grid>
      </Grid>

      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <VisitorForm
                oldPersonnelProps={oldPersonnelPropsShell}
                options={options}
                visitors={visitors}
                setVisitors={setVisitors}
                handleCloseModal={handleCloseModal}
                index={index}
                getPersonnelFromId={getPersonnelFromId}
              />
            )}
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
