import React, { useCallback } from 'react';
import { ResponsiveContainer } from 'recharts';
import PropTypes from 'prop-types';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { AppBar, Box, Button, CircularProgress, createTheme, makeStyles, Tab, Tabs, ThemeProvider, Typography } from '@material-ui/core';
import Request from '../../../helpers/Request';
import CustomSnackbar from '../../../components/Snackbar/Snackbar';
import PersonnelDataForm from './personneldata/PersonnelDataForm';
import GetOptions from '../../departments/components/GetOptions';
import DepartmentDataForm from './departmentdata/DepartmentDataForm';
import showSnackbar from '../../../components/Utils/showSnackbar';
import SGKLogs from './sgk/SGKLogs';
import { getTowns, getUsers, getUsersWithRoleAndCompany } from '../components/GetOptions';
import SlideDialog from '../../users/userdetails/SlideDialog';
import { ceil } from 'lodash';

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2D3446',
    width: "100%",
    height: window.innerHeight > 900 ? "88vh" : "84vh",
    margin: "0 auto"
  },
  panel: {
    overflowY: "auto"
  },
  divider: {
    margin: 10
  },
  submit: {
    marginTop: "10px"
  },
  redText: {
    color: "red", 
    fontSize: 16
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function DepartmentDetails({id, auth}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const darkTheme = createTheme({ palette: { type: 'dark' } });

  const requiredAmount = React.useRef(1);
  const ffRequiredAmount = React.useRef(null);
  const srRequiredAmount = React.useRef(null);
  const faRequiredAmount = React.useRef(null);

  const [requestLoading, setRequestLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [data, setData] = React.useState(null);
  const [changeMade, setChangeMade] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [generalError, setGeneralError] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const [sgkLogs, setSgkLogs] = React.useState({});
  const [departmentProps, setDepartmentProps] = React.useState({});
  const [errors, setErrors] = React.useState({
    nameError: null,
    sgkError: null,
    cityError: null,
    townError: null,
    fieldsError: null,
    companyError: null,
    riskError: null,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getDetails = useCallback(async (id) => {
    const resp = await Request("get", "/api/departments/" + id);
    console.log(resp);
    return resp;
  }, []);

  const getSgkLogs = useCallback(async (id) => {
    const resp = await Request("get", "/api/departments/" + id + "/sgk-logs");
    console.log(resp);
    return resp.data;
  }, []);

  const init = useCallback(async () => {
    const [options, resp, logs] = await Promise.all([
      GetOptions(id), getDetails(id), getSgkLogs(id)
    ])
    const data = resp.data;
    setOptions(options);
    setData(resp);
    setSgkLogs(logs);

    options.towns = data && data.city?.id ? await getTowns(data.city?.id) : [];
    const users = data && data.company?.id && await getUsers(data.company?.id);
    options.unsuitUser = users ? users : [];
    options.users = users ? users : null;
    [options.managers, options.isgUsers, options.healthUsers, options.approvers] = data && data.id ? await Promise.all([
      getUsersWithRoleAndCompany("MANAGERS", data?.id), 
      getUsersWithRoleAndCompany("ISGEXPERTS", data?.id), 
      getUsersWithRoleAndCompany("HEALTH_PERSONALS", data?.id)
    ]) : [[], [], []];
    setOptions(options);

    let parentDepartmentIds = [];
    let fields = [];
    let isgUsers = [];
    let healthUsers = [];
    let unsuitUsers = [];
    let reps = [];
    let riskAssessmentTeam = [];
    for(let prop in data.parentDepartments) {parentDepartmentIds.push(data.parentDepartments[prop].id)}
    for(let prop in data.fieldsOfActivity) {fields.push(data.fieldsOfActivity[prop].id)}
    for(let prop in data.isgUsers) {isgUsers.push(data.isgUsers[prop].id)}
    for(let prop in data.healthUsers) {healthUsers.push(data.healthUsers[prop].id)}
    for(let prop in data.employeeRepresentatives) {reps.push(data.employeeRepresentatives[prop].id)}
    for(let prop in data.riskAssessmentTeam) {riskAssessmentTeam.push(data.riskAssessmentTeam[prop].id)}
    for(let index in data.nonConformityUsers) {
      unsuitUsers.push({
        townId: data.nonConformityUsers[index]?.town?.id, 
        userId: data.nonConformityUsers[index]?.user?.id
      });
    }
    
    const props = {
      id: data.id ? data?.id : null,
      employeeCount: data.employeeCount ? data?.employeeCount : null,
      emergencyTeams: data.emergencyTeams ? data?.emergencyTeams : {},
      employeeRepresentativeIds: reps,
      name: data.name ? data?.name : null,
      sgkno: data.sgkno ? data?.sgkno : null,
      companyId: data.company ? data?.company?.id : null,
      cityId: data.city ? data?.city?.id : null,
      townId: data.town ? data?.town?.id : null,
      managerId: data.manager ? data?.manager?.id : null,
      approverId: data.approver ? data?.approver?.id : null,
      isgUserIds: isgUsers,
      healthUserIds: healthUsers,
      nonconformityUserRequest: unsuitUsers,
      riskAssessmentTeam: riskAssessmentTeam,
      fieldOfActivity: data.fieldOfActivity ? data?.fieldOfActivity : null,
      fieldsOfActivity: fields,
      parentDepartmentIds: parentDepartmentIds,
      riskClass: data.riskClass ? data?.riskClass : null
    }
    if(props.employeeCount > 50 && props.employeeCount < 100) {requiredAmount.current = 2;}
    else if(props.employeeCount >= 100) {requiredAmount.current = 3;}

    if(props.riskClass === "AZ_TEHLİKELİ") {
      ffRequiredAmount.current = ceil(props.employeeCount / 50);
      srRequiredAmount.current = ceil(props.employeeCount / 50);
      faRequiredAmount.current = ceil(props.employeeCount / 20);
    } else if(props.riskClass === "TEHLİKELİ") {
      ffRequiredAmount.current = ceil(props.employeeCount / 40);
      srRequiredAmount.current = ceil(props.employeeCount / 40);
      faRequiredAmount.current = ceil(props.employeeCount / 15);
    } else if(props.riskClass === "ÇOK_TEHLİKELİ") {
      ffRequiredAmount.current = ceil(props.employeeCount / 30);
      srRequiredAmount.current = ceil(props.employeeCount / 30);
      faRequiredAmount.current = ceil(props.employeeCount / 10);
    }

    setDepartmentProps(props);
  }, [id, getDetails, getSgkLogs]);
  React.useEffect(() => {
    init();
  }, [init]);

  const updateDepartment = async () => {
    setRequestLoading(true);
    const resp = await Request("patch", "/api/departments/" + id, departmentProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, null, true);
    setRequestLoading(false);
  }

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if(!departmentProps.name) {
      setErrors({...errors, nameError: errorMessage});
      error = true;
      setValue(0);
    }
    if(!departmentProps.cityId) {
      setErrors({...errors, cityError: errorMessage});
      error = true;
      setValue(0);
    }
    if(!departmentProps.townId) {
      setErrors({...errors, townError: errorMessage});
      error = true;
      setValue(0);
    }
    if(!departmentProps.sgkno) {
      setErrors({...errors, sgkError: errorMessage});
      error = true;
      setValue(0);
    }
    if(departmentProps.fieldsOfActivity.length === 0) {
      setErrors({...errors, fieldsError: errorMessage});
      error = true;
      setValue(0);
    }
    if(!departmentProps.companyId) {
      setErrors({...errors, companyError: errorMessage});
      error = true;
      setValue(0);
    }
    if(!departmentProps.riskClass) {
      setErrors({...errors, riskError: errorMessage});
      error = true;
      setValue(0);
    }
    if(!departmentProps.fieldOfActivity) {
      setErrors({...errors, fieldOfActivity: errorMessage});
      error = true;
      setValue(0);
    }
    /* if(departmentProps.employeeRepresentativeIds.length < requiredAmount.current) {
      error = true;
      setValue(1);
    }
    if(departmentProps.emergencyTeams.fireFighting.length < ffRequiredAmount.current) {
      error = true;
      setValue(1);
    }
    if(departmentProps.emergencyTeams.searchAndRescue.length < srRequiredAmount.current) {
      error = true;
      setValue(1);
    }
    if(departmentProps.emergencyTeams.firstAid.length < faRequiredAmount.current) {
      error = true;
      setValue(1);
    } */

    if(!error) {
      updateDepartment();
    } else {
      setGeneralError(true);
    }
  }

  const RenderLoading = () => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
    return(
      <>
      {loading ? <CircularProgress color="secondary"/> : <p style={{color: "red", fontSize: 18}}>Veri Yok</p>}
      </>
    )
  }

  const RenderError = () => {
    if(data.status === 404) {
      return <p style={{fontSize: 40, color: "red"}}>BÖLÜM BULUNAMADI</p>
    } else if(data.status === 400) {
      return <p style={{fontSize: 40, color: "red"}}>BÖLÜME ERİŞİM YETKİNİZ YOKTUR</p>
    } else {
      return(
        <>
        {loading ? <CircularProgress color="secondary"/> : <p style={{color: "red", fontSize: 18}}>Veri Yok</p>}
        </>
      )
    }
  }

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <SlideDialog
        open={generalError} 
        handleClose={() => setGeneralError(false)}
      />
      <ResponsiveContainer>
        {data 
          ?
          <>
            {data.status === 200 ?
              <div className={classes.details}>
                <ThemeProvider theme={darkTheme}>
                <AppBar position="static">
                  <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="BÖLÜM BİLGİLER" {...a11yProps(0)} />
                    <Tab label="PERSONEL BİLGİLERİ" {...a11yProps(1)} />
                    <Tab label="SGK KAYITLARI" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
                <TabPanel className={classes.panel} value={value} index={0}>
                  { Object.keys(departmentProps).length > 0 ?
                    <DepartmentDataForm
                      departmentProps={departmentProps}
                      setDepartmentProps={setDepartmentProps}
                      data={data?.data}
                      errors={errors}
                      setErrors={setErrors}
                      options={options}
                      setOptions={setOptions}
                      setChangeMade={setChangeMade}
                    />
                    : <RenderLoading/>
                  }
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={1}>
                  { Object.keys(departmentProps).length > 0 ?
                    <PersonnelDataForm 
                      departmentProps={departmentProps}
                      setDepartmentProps={setDepartmentProps}
                      options={options}
                      setChangeMade={setChangeMade}
                    />
                    : <RenderLoading/>
                  }
                </TabPanel>
                <TabPanel className={classes.panel} value={value} index={2}>
                  { Object.keys(sgkLogs).length > 0 ?
                    <SGKLogs
                      logs={sgkLogs}
                    />
                    : <RenderLoading/>
                  }
                </TabPanel>
                {(value === 0 || value === 1) &&
                <div style={{textAlign: "center"}}>
                  {requestLoading ?
                  <CircularProgress color="secondary"/>
                  :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!changeMade}
                    onClick={() => onButtonClick()}
                    className={classes.submit}>
                    {"GÜNCELLE"}
                  </Button>}
                </div>}
                </ThemeProvider>
              </div>
              :
              <RenderError/>
            }
          </>
          :
          <CircularProgress color="secondary"/>
        }
      </ResponsiveContainer>
    </>
  );
}