import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchRecordData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      filterCompany: filterProps.company?.id,
      filterDepartment: filterProps.department?.id,
      filterInstructor: filterProps.instructor?.id,
      filterTrainingType: filterProps.trainingType,
      filterCity: filterProps.city?.id,
      filterTown: filterProps.town?.id,
      filterPlannedDateEnd: filterProps.plannedDateEnd,
      filterPlannedDateStart: filterProps.plannedDateStart,
      filterCompletionDateEnd: filterProps.completionDateEnd,
      filterCompletionDateStart: filterProps.completionDateStart,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "company.name," + query.orderDirection;
      } else if (query.orderBy.field === "departmentName") {
        params.sort = "department.name," + query.orderDirection;
      } else if (query.orderBy.field === "cityName") {
        params.sort = "city.name," + query.orderDirection;
      } else if (query.orderBy.field === "townName") {
        params.sort = "town.name," + query.orderDirection;
      } else if (query.orderBy.field === "trainingType") {
        params.sort = "trainingType," + query.orderDirection;
      } else if (query.orderBy.field === "topics") {
        params.sort = "topics," + query.orderDirection;
      } else if (query.orderBy.field === "plannedDate") {
        params.sort = "plannedDate," + query.orderDirection;
      } else if (query.orderBy.field === "completionDate") {
        params.sort = "completionDate," + query.orderDirection;
      } else if (query.orderBy.field === "time") {
        params.sort = "time," + query.orderDirection;
      } else if (query.orderBy.field === "instructorName") {
        params.sort =
          "instructor.fName,instructor.lName," + query.orderDirection;
      } else if (query.orderBy.field === "status") {
        params.sort = "status," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/training/record", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const props = {
          id: data[i]?.id,
          companyId: data[i]?.company?.id,
          companyName: data[i]?.company?.name,
          departmentId: data[i]?.department?.id,
          departmentName: data[i]?.department?.name,
          trainingType: data[i]?.trainingType,
          topics: data[i]?.topics,
          createdDate: data[i]?.createdAt,
          plannedDate: data[i]?.plannedDate,
          startDate: data[i]?.startDate,
          completionDate: data[i]?.completionDate,
          time: data[i]?.time,
          cityId: data[i]?.city?.id,
          townIds: data[i]?.towns.map((data) => data.id),
          cityName: data[i]?.city?.name,
          towns: data[i]?.towns,
          status: data[i]?.status,
          instructors: data[i]?.instructors,
          manHour: data[i]?.participants.length * data[i]?.time,
          numOfParticipants: data[i]?.participants.length,
          externalInstructors: data[i]?.externalInstructors.map(
            (data) => data.fullName
          ),
          participants: data[i]?.participants.map((data) => data.id),
          notAttendedUsers: data[i]?.notAttendedUsers.map((data) => data.id),
          participantObjects: data[i]?.participants.map((data) => ({
            name: data.fName + " " + data.lName,
            id: data.id,
          })),
          allInstructors: formatInstructors(
            data[i]?.instructors,
            data[i]?.externalInstructors
          ),
          atWorkplace: data[i]?.atWorkplace,
          remote: data[i]?.remote,
          ibysTrainingTypeName: data[i]?.ibysTrainingType
            ? data[i]?.ibysTrainingType?.name
            : null,
          ibysTrainingTypeId: data[i]?.ibysTrainingType
            ? data[i]?.ibysTrainingType?.id
            : null,
          ibysResults: data[i]?.ibysResults,
        };
        props.otherDocuments = [];
        for (let file of data[i]?.otherDocuments) {
          props.otherDocuments.push(
            new File([file?.fileName], file?.fileName, { type: "text/plain" })
          );
        }
        props.participationForms = [];
        for (let file of data[i]?.participationForms) {
          props.participationForms.push(
            new File([file?.fileName], file?.fileName, { type: "text/plain" })
          );
        }
        props.trainingDocuments = [];
        for (let file of data[i]?.trainingDocuments) {
          props.trainingDocuments.push(
            new File([file?.fileName], file?.fileName, { type: "text/plain" })
          );
        }
        temp.push(props);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
const language = LanguageHelper.getLanguage();
const formatInstructors = (ins, extIns) => {
  const array = [];
  for (let prt of ins) {
    array.push(prt.fName + " " + prt.lName);
  }
  for (let prt of extIns) {
    array.push(prt.fullName + language.etc.external);
  }
  return array;
};
