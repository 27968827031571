import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import { TextSelector, TextArea } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import moment from "moment";
import ParticipantsList from "./ParticipantsList";
import { Autocomplete } from "@material-ui/lab";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  getUsers,
  getInstructors,
  getDepartments,
  getCities,
  getTowns,
} from "../GetOptions";
import TownsList from "./TownsList";
import Files from "./Files";
import InstructorsList from "./InstructorsList";
import { trueFalse } from "../../../assets/constants";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  id: null,
  companyId: null,
  departmentId: null,
  trainingType: null,
  topics: null,
  plannedDate: moment().toISOString(true),
  completionDate: moment().toISOString(true),
  startDate: moment().toISOString(true),
  time: null,
  cityId: null,
  townIds: [],
  status: null,
  instructors: [],
  externalInstructors: [],
  participants: [],
  notAttendedUsers: [],
  otherDocuments: [],
  participationForms: [],
  trainingDocuments: [],
  ibysTrainingTypeId: null,
  atWorkplace: null,
  remote: null,
};

export default function RecordModal({
  recordProps,
  setRecordModal,
  modalLoading,
  setModalLoading,
  catalogues,
  setCatalogues,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const manager =
    user.roles.includes("ROLE_ISG_UZMANI") ||
    user.roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
    user.roles.includes("ROLE_OSGB_ISG_UZMANI");
  const isAdmin = user.roles.includes("ROLE_SİSTEM_ADMİNİ");
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const isNewRecord = recordProps && Object.keys(recordProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [otherDocuments, setOtherDocuments] = React.useState([]);
  const [participationForms, setParticipationForms] = React.useState([]);
  const [trainingDocuments, setTrainingDocuments] = React.useState([]);
  const [props, setProps] = React.useState({ propsDefault });
  const [catalogue, setCatalogue] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({
    trainingTypeError: null,
    instructorError: null,
    statusError: null,
    completionDateError: null,
    timeError: null,
    topicsError: null,
  });

  const init = useCallback(async () => {
    setProps({
      ...propsDefault,
      companyId: user?.companyId,
      departmentId: user?.department?.id,
    });
    if (!isNewRecord && recordProps) {
      console.log(recordProps);
      setProps({ ...recordProps });
      setOtherDocuments([...recordProps.otherDocuments]);
      setParticipationForms([...recordProps.participationForms]);
      setTrainingDocuments([...recordProps.trainingDocuments]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordProps, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = language.errors.blankField;
    let errors = {
      trainingTypeError: null,
      instructorError: null,
      statusError: null,
      completionDateError: null,
      timeError: null,
      topicsError: null,
      participantsError: null,
    };
    if (props?.status === "DONE" && !props.completionDate) {
      errors.completionDateError = errorMessage;
      error = true;
    }
    if (
      !props.instructors ||
      (props.instructors.length === 0 && props.externalInstructors.length === 0)
    ) {
      errors.instructorError = errorMessage;
      error = true;
    }
    if (!props.trainingType) {
      errors.trainingTypeError = errorMessage;
      error = true;
    }
    if (!props.time) {
      errors.timeError = errorMessage;
      error = true;
    }
    if (!props.status) {
      errors.statusError = errorMessage;
      error = true;
    }
    if (!props.topics) {
      errors.topicsError = errorMessage;
      error = true;
    }
    if (!props.participantObjects || props.participantObjects.length === 0) {
      errors.participantsError = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      isNewRecord ? submitNewRecord() : updateRecord();
    }
  };

  const submitNewRecord = async () => {
    setLoading(true);
    let formData = new FormData();
    if (otherDocuments && Array.isArray(otherDocuments)) {
      for (let file of otherDocuments) {
        formData.append("otherDocuments", file);
      }
    }
    if (participationForms && Array.isArray(participationForms)) {
      for (let file of participationForms) {
        formData.append("participationForms", file);
      }
    }
    if (trainingDocuments && Array.isArray(trainingDocuments)) {
      for (let file of trainingDocuments) {
        formData.append("trainingDocuments", file);
      }
    }
    if (props.status == "TODO") {
      props.completionDate = null;
    }
    console.log(props);
    let participants = [];
    for (let prt of props.participantObjects) {
      participants.push(prt.id);
    }
    props.participants = participants;
    let notAttendedUsers = [];
    for (let prt of props.notAttendedUsers) {
      notAttendedUsers.push(prt.id);
    }
    props.notAttendedUsers = notAttendedUsers;
    let instructors = [];
    for (let inst of props.instructors) {
      inst.id ? instructors.push(inst.id) : instructors.push(inst);
    }
    props.instructorIds = instructors;
    props.externalInstructorRequests = props.externalInstructors;
    // delete props.externalInstructors;
    // delete props.instructors;
    // delete props.participantObjects;
    // delete props.id;
    // delete props.company;
    // delete props.companyName;
    // delete props.instructorName;
    // delete props.otherDocuments;
    // delete props.participationForms;
    // delete props.trainingDocuments;
    // delete props.notAttendedUsers;
    // delete props.tableData;
    console.log(props, otherDocuments, participationForms, trainingDocuments);
    formData.append(
      "newRecordRequest",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("post", "/api/training/record", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateRecord = async () => {
    setLoading(true);
    let formData = new FormData();
    if (otherDocuments && Array.isArray(otherDocuments)) {
      for (let file of otherDocuments) {
        formData.append("otherDocuments", file);
      }
    }
    if (participationForms && Array.isArray(participationForms)) {
      for (let file of participationForms) {
        formData.append("participationForms", file);
      }
    }
    if (trainingDocuments && Array.isArray(trainingDocuments)) {
      for (let file of trainingDocuments) {
        formData.append("trainingDocuments", file);
      }
    }
    if (props.status == "TODO") {
      props.completionDate = null;
    }
    let participants = [];
    for (let prt of props.participantObjects) {
      participants.push(prt.id);
    }
    props.participants = participants;
    let notAttendedUsers = [];
    for (let prt of props.notAttendedUsers) {
      notAttendedUsers.push(prt.id);
    }
    props.notAttendedUsers = notAttendedUsers;
    let instructors = [];
    for (let inst of props.instructors) {
      inst.id ? instructors.push(inst.id) : instructors.push(inst);
    }
    props.instructorIds = instructors;
    props.externalInstructorRequests = props.externalInstructors;
    props.recordId = props?.id;
    // delete props.externalInstructors;
    // delete props.instructors;
    // delete props.participantObjects;
    // delete props.id;
    // delete props.company;
    // delete props.companyName;
    // delete props.instructorName;
    // delete props.otherDocuments;
    // delete props.participationForms;
    // delete props.trainingDocuments;
    // delete props.tableData;
    console.log(props, otherDocuments, participationForms, trainingDocuments);
    formData.append(
      "modifyRecordRequest",
      new Blob([JSON.stringify(props)], { type: "application/json" })
    );
    const resp = await Request("patch", "/api/training/record", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setOtherDocuments([]);
    setTrainingDocuments([]);
    setParticipationForms([]);
    setProps(propsDefault);
    setRecordModal(null);
  };

  return (
    <TableEntryModal
      modal={recordProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        {isNewRecord && (
          <Autocomplete //CATALOGUE
            id="combo-box"
            options={catalogues?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={catalogue ? getFromId(catalogue, catalogues) : null}
            onChange={async (event, value) => {
              if (value === null) {
                setCatalogue(null);
              } else {
                setCatalogue(value?.id);
                let tempFiles = [];
                for (let file of value?.fileKeys) {
                  const name = file.name ? file.name : file?.fileName;
                  tempFiles.push(
                    new File([name], name, { type: "text/plain" })
                  );
                }
                setTrainingDocuments(tempFiles);
                setProps({
                  ...props,
                  trainingType: value?.trainingType,
                  time: value?.time,
                  topics: value?.name,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={"Katalog"}
                className={classes.textField}
              />
            )}
          />
        )}
        {(manager || isAdmin) && (
          <Autocomplete //COMPANY
            id="combo-box"
            options={options.companies?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.companyId
                ? getFromId(props.companyId, options.companies)
                : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, companyId: null });
                setOptions({
                  ...options,
                  departments: [],
                  users: [],
                  instructors: [],
                });
              } else {
                setProps({ ...props, companyId: value?.id });
                const [depts, users, instructors] = await Promise.all([
                  getDepartments(value?.id),
                  getUsers(value?.id),
                  getInstructors(value?.id),
                ]);
                setOptions({
                  ...options,
                  departments: depts,
                  users: users,
                  instructors: instructors,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={language.auditTasks.company}
                className={classes.textField}
              />
            )}
          />
        )}
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!props.companyId}
          value={
            props.departmentId
              ? getFromId(props.departmentId, options.departments)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, departmentId: null });
              setOptions({ ...options, cities: [] });
            } else {
              setProps({ ...props, departmentId: value?.id });
              const cities = await getCities(value?.id);
              setOptions({ ...options, cities: cities });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={language.auditTasks.department}
              className={classes.textField}
            />
          )}
        />
        {options.cities && (
          <Autocomplete //CITY
            id="combo-box"
            options={options.cities?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={
              props.cityId ? getFromId(props.cityId, options.cities) : null
            }
            onChange={async (event, value) => {
              if (value === null) {
                setProps({ ...props, cityId: null });
              } else {
                setProps({ ...props, cityId: value?.id, townIds: [] });
                const towns = await getTowns(value?.id);
                setOptions({ ...options, towns: towns });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={language.auditTasks.educationCity}
                className={classes.textField}
              />
            )}
          />
        )}
        <TownsList //TOWN
          props={props}
          setProps={setProps}
          options={options}
        />
        <TextSelector
          label={language.tableColumns.ibysTrainingType}
          value={props.ibysTrainingTypeId}
          shrink={props.ibysTrainingTypeId !== null}
          onChangeFunc={(value) =>
            setProps({
              ...props,
              ibysTrainingTypeId: value.target.value,
            })
          }
          style={classes.textField}
          options={jsonMap("ibysTrainingTypes", options)}
        />
        <TextSelector
          label={language.tableColumns.atWorkplace}
          value={props.atWorkplace}
          shrink={props.atWorkplace !== null}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              atWorkplace: value.target.value,
            });
          }}
          style={classes.textField}
          options={jsonMap(false, trueFalse)}
        />
        <TextSelector
          label={language.tableColumns.remote}
          value={props.remote}
          shrink={props.remote !== null}
          onChangeFunc={(value) => {
            setProps({
              ...props,
              remote: value.target.value,
            });
          }}
          style={classes.textField}
          options={jsonMap(false, trueFalse)}
        />
        <TextSelector //TRAINING TYPE
          error={errors.trainingTypeError}
          label={language.tableColumns.educationType}
          required
          value={props?.trainingType}
          shrink={props?.trainingType}
          style={classes.textField}
          onChangeFunc={(value) => {
            if (value.target.value === "TOOLBOX EĞİTİMİ") {
              setProps({
                ...props,
                time: 0.25,
                trainingType: value.target.value,
              });
              (errors.trainingTypeError || errors.timeError) &&
                setErrors({
                  ...errors,
                  timeError: null,
                  trainingTypeError: null,
                });
            } else {
              setProps({ ...props, trainingType: value.target.value });
              errors.trainingTypeError &&
                setErrors({ ...errors, trainingTypeError: null });
            }
          }}
          options={jsonMap("trainingTypes", options)}
        />
        <TextArea //TOPICS
          required
          error={errors.topicsError}
          label={language.tableColumns.name}
          value={props?.topics}
          onChangeFunc={(value) => {
            setProps({ ...props, topics: value.target.value });
            errors.topicsError && setErrors({ ...errors, topicsError: null });
          }}
          style={classes.textField}
        />
        <TextSelector //STATUS
          label={language.tableColumns.enabled}
          required
          error={errors.statusError}
          value={props?.status}
          shrink={props?.status}
          style={classes.textField}
          onChangeFunc={(value) => {
            setProps({ ...props, status: value.target.value });
            errors.statusError && setErrors({ ...errors, statusError: null });
          }}
          options={jsonMap("status", options)}
        />
        <CustomDatePicker //PLANNED DATE
          style={classes.dateField}
          label={language.tableColumns.plannedDate}
          value={props?.plannedDate}
          shrink={props?.plannedDate}
          onChangeFunc={(value) =>
            setProps({ ...props, plannedDate: moment(value).toISOString(true) })
          }
        />
        <CustomDatePicker //START DATE
          style={classes.dateField}
          label={language.auditTasks.startingDate}
          value={props?.startDate}
          shrink={props?.startDate}
          onChangeFunc={(value) =>
            setProps({ ...props, startDate: moment(value).toISOString(true) })
          }
        />
        {props?.status === "DONE" && (
          <CustomDatePicker //COMPLETION DATE
            error={errors.completionDateError}
            required
            style={classes.dateField}
            label={language.tableColumns.completionDate}
            value={props?.completionDate}
            shrink={props?.completionDate}
            onChangeFunc={(value) => {
              setProps({
                ...props,
                completionDate: moment(value).toISOString(true),
              });
              errors.completionDateError &&
                setErrors({ ...errors, completionDateError: null });
            }}
          />
        )}
        <TextArea //TIME
          required
          error={errors.timeError}
          disabled={props?.trainingType === "TOOLBOX EĞİTİMİ"}
          label={"Süre"}
          value={props?.time}
          shrink={props?.time}
          onChangeFunc={(value) => {
            const val = value.target.value
              .replace(/\s/g, "")
              .replace(/\D/g, "");
            setProps({ ...props, time: val });
            errors.timeError && setErrors({ ...errors, timeError: null });
          }}
          style={classes.textField}
        />
        {options.instructors && (
          <InstructorsList
            props={props}
            setProps={setProps}
            options={options}
            error={errors.instructorError}
          />
        )}
        {options.users && (
          <ParticipantsList
            props={props}
            setProps={setProps}
            options={options}
            error={errors.participantsError}
          />
        )}
        <Files
          trainingDocuments={trainingDocuments}
          setTrainingDocuments={setTrainingDocuments}
          participationForms={participationForms}
          setParticipationForms={setParticipationForms}
          otherDocuments={otherDocuments}
          setOtherDocuments={setOtherDocuments}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewRecord ? language.modal.update : language.modal.submit}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
