import Request from '../../helpers/Request';
import LanguageHelper from '../../helpers/LanguageHelper';
import { months } from '../../assets/constants';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.id,
      department: filterProps.department?.id,
      month: filterProps.month,
      year: filterProps.year,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "existingPersonal") { params.sort = "existingPersonal," + query.orderDirection; }
      else if (query.orderBy.field === "beginPersonal") { params.sort = "beginPersonal," + query.orderDirection; }
      else if (query.orderBy.field === "quitPersonal") { params.sort = "quitPersonal," + query.orderDirection; }
      else if (query.orderBy.field === "month") { params.sort = "month," + query.orderDirection; }
      else if (query.orderBy.field === "year") { params.sort = "year," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = "%" + query.search + "%";
    }
    const resp = await Request("get", "/api/monthly-user", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          beginPersonal: content[i]?.beginPersonal,
          id: content[i]?.id,
          companyId: content[i]?.companyId,
          companyName: content[i]?.companyName,
          departmentId: content[i]?.departmentId,
          departmentName: content[i]?.departmentName,
          existingPersonal: content[i]?.existingPersonal,
          month: content[i]?.month,
          monthTr: months[content[i]?.month],
          quitPersonal: content[i]?.quitPersonal,
          totalPersonal: content[i]?.totalPersonal,
          year: content[i]?.year,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      })
    }
  })
  return data;
}