import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      "page-size": query.pageSize,
      "sort-by": "id",
      "sort-direction": "DESC",
    };
    // if (query.orderBy !== undefined) {
    //   if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
    //   else if (query.orderBy.field === "name") { params.sort = "name," + query.orderDirection; }
    // }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/authority/modules", null, params);
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(
        language.etc.fetchingError + resp?.data?.messageResponse
      );
      setSnackbar(true);
    } else {
      let otherData = resp.data?.content;
      let data = otherData?.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          name: data[i].name,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}
