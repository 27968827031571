import React, { useCallback } from "react";
import LanguageHelper from "../../helpers/LanguageHelper";
import Request from "../../helpers/Request";
import ModalForm from './ModalForm';
import TableEntryModal from "../../components/Modal/TableEntryModal";
import showSnackbar from "../../components/Utils/showSnackbar";

export default function HeadcountsModal({modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, tableRef, oldHeadcounts, opts, modalLoading}) {
  const language = LanguageHelper.getLanguage();
  
  const [headcounts, setHeadcounts] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setHeadcounts({
      beginPersonal: oldHeadcounts ? oldHeadcounts.beginPersonal : null,
      departmentId: oldHeadcounts ? oldHeadcounts.departmentId : null,
      companyId: oldHeadcounts ? oldHeadcounts.companyId : null,
      existingPersonal: oldHeadcounts ? oldHeadcounts.existingPersonal : null,
      month: oldHeadcounts ? oldHeadcounts.month : null,
      quitPersonal: oldHeadcounts ? oldHeadcounts.quitPersonal : null,
      year: oldHeadcounts ? oldHeadcounts.year : null
    });
    setOptions(opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldHeadcounts, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);
  
  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setHeadcounts({
        beginPersonal: null,
        departmentId: null,
        existingPersonal: null,
        month: null,
        quitPersonal: null,
        year: null
      });
      let newOpts = opts;
      newOpts.departments = [];
      setOptions(newOpts);
    }, 300);
  }

  /**
    * Request to post a new inspection task with the data given.
    */
  const submitNewHeadcounts = async () => {
    setLoading(true);
    const resp = await Request("post", '/api/monthly-user', headcounts);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  /**
    * Request to replace the old data with the new data.
    */
  const updateHeadcounts = async () => {
    setLoading(true);
    const resp = await Request("patch", '/api/monthly-user/' + oldHeadcounts.id, headcounts);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}>
        <ModalForm
          oldHeadcounts={oldHeadcounts}
          options={options}
          setOptions={setOptions}
          headcounts={headcounts}
          setHeadcounts={setHeadcounts}
          updateHeadcounts={updateHeadcounts}
          submitNewHeadcounts={submitNewHeadcounts}
          loading={loading}
        />
      </TableEntryModal>
    </>
  );
}