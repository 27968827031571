import React, { useCallback } from "react";
import { List, ListItem, ListItemText } from "@material-ui/core";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import GetFilterFields from "./CrewMissingKKDFilters";

export default function CrewMissingKKDTable() {
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState([]);
  const [backdropLoading, setBackdropLoading] = React.useState(true);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    department: null,
    company: null,
    fullName: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "Ekip", field: "crewName" },
      { title: "Şirket", field: "companyName" },
      { title: "Bölüm", field: "departmentName" },
      { title: "Eksik KKD", field: "missingKKDs", render: rowData => 
      <>
        <List style={{
          width: 250,
          height: 100,
          overflow: "auto"
        }}>
          {
            rowData?.missingKKDs.map((data, index) => (
              <ListItem key={index}>
                <ListItemText secondary={data?.name}/>
              </ListItem>
            ))
          }
        </List>
      </> },
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options} 
          tableRef={tableRef}
          setOptions={setOptions} 
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      department: null,
      company: null,
      fullName: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        tableName={"Eksik Ekipsel KKD"}
        authName="missingKKD"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        noRowActions
      />
    </>
  );
}