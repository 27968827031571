import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, Divider, Fade, Grid, Modal } from "@material-ui/core";
import { modalStyles } from "../../../assets/styles/tableContainer";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AccidentPersonnelForm from "./AccidentPersonnelForm";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Request from "../../../helpers/Request";

const useOtherStyles = makeStyles({
  addButton: {
    marginLeft: "40%",
  },
  accidentsContainer: {
    minHeight: "2vw",
    border: "1px solid #C7C7C7",
    borderRadius: "5px",
  },
  groupContainer: {
    marginBottom: "20px",
  },
});

const useStyles = makeStyles((theme) => modalStyles(theme));

/**
 * The form control component used in the accident creation/editing modal.
 * @param {oldAccidentProps: obj, newAccidentProps: obj, setNewAccidentProps: func, options: obj[], updateAccident: func, submitNewAccident: func} props of the form control
 */
export default function Group({
  options,
  oldAccidentProps,
  accidents,
  setAccidents,
}) {
  const classes = useStyles();
  const otherStyles = useOtherStyles();

  const [oldPersonnelPropsShell, setOldPersonnelPropsShell] =
    React.useState(null);
  const [modal, setModal] = React.useState(false);
  const [index, setIndex] = React.useState(null);

  const getAccidents = useCallback(async (id) => {
    let fetchedAccidents = [...accidents];
    const resp = await Request(
      "get",
      "/api/industrial-accidents/" + id + "/casualties"
    );
    console.log(resp);
    let arr = resp.data;
    for (let i in arr) {
      let bodyPartIds = [];
      if (arr[i]?.injuredBodyParts.length > 0) {
        for (let index in arr[i].injuredBodyParts) {
          bodyPartIds.push(arr[i].injuredBodyParts[index].id);
        }
      }
      let accident = {
        id: arr[i].id,
        personnelId: arr[i].personnelId,
        lostLimbId: arr[i].lostLimb?.id,
        injuredBodyPartIds: bodyPartIds,
        fallDistanceOnFallAccidentsInMeters:
          arr[i].fallDistanceOnFallAccidentsInMeters,
        incapacitationStartDate: arr[i].incapacitationStartDate,
        incapacitationEndDate: arr[i].incapacitationEndDate,
        accidentOutcomeId: arr[i].outcome?.id,
        injuryTypeId: arr[i].injuryType?.id,
        lostTime: arr[i].lostTime,
        sgkNotificationDate: arr[i].sgkNotificationDate,
        // note: arr[i].note,
        // summary: arr[i].summary,
        workStartTimeAtAccidentDay: arr[i].workStartTimeAtAccidentDay,
        sectionId: arr[i].section?.id,
      };
      fetchedAccidents.push(accident);
    }
    return fetchedAccidents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(async () => {
    oldAccidentProps && setAccidents(await getAccidents(oldAccidentProps.id));
  }, [oldAccidentProps, setAccidents, getAccidents]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data, index) => {
    setOldPersonnelPropsShell(data);
    setIndex(index);
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const getPersonnelFromId = (id) => {
    for (let i = 0; i < options.users.length; i++) {
      if (options.users[i].id === id) {
        return options.users[i];
      }
    }
    return null;
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const PrintAccidents = () => {
    accidents = getUniqueListBy(accidents, "id");
    return accidents.map((data, index) => {
      return (
        <>
          {index > 0 && <Divider />}
          <Grid container direction="row" style={{ margin: 10 }}>
            <Grid xs={10}>
              <p>{getPersonnelFromId(data.personnelId)?.name}</p>
            </Grid>
            <Grid xs={2}>
              <IconTooltipButton
                title={"Kazazedeyi Sil"}
                onClick={() => {
                  let temp = [...accidents];
                  temp.splice(index, 1);
                  setAccidents(temp);
                }}
              >
                <DeleteIcon />
              </IconTooltipButton>
              <IconTooltipButton
                title={"Kazazedeyi Güncelle"}
                onClick={() => {
                  handleOpenModal(data, index);
                }}
              >
                <EditIcon />
              </IconTooltipButton>
            </Grid>
          </Grid>
        </>
      );
    });
  };

  return (
    <>
      <Grid container direction="row" className={otherStyles.groupContainer}>
        <Grid item xs={11} className={otherStyles.accidentsContainer}>
          <PrintAccidents />
        </Grid>
        <Grid item xs={1}>
          <div className={otherStyles.addButton}>
            <IconTooltipButton
              title={"Kazazede Ekle"}
              onClick={() => handleOpenModal(null)}
            >
              <AddBoxIcon />
            </IconTooltipButton>
          </div>
        </Grid>
      </Grid>

      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <AccidentPersonnelForm
              oldPersonnelProps={oldPersonnelPropsShell}
              options={options}
              accidents={accidents}
              setAccidents={setAccidents}
              handleCloseModal={handleCloseModal}
              getPersonnelFromId={getPersonnelFromId}
              index={index}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
