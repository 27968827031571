import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Request from "../../../helpers/Request";
import NewDepartmentModal from "./NewDepartmentModal";
import GetOptions from "./GetOptions";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetFilterFields from "./DepartmentFilters";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

const useStyles = makeStyles(() => ({
  fieldsList: {
    height: 110,
    width: 250,
    overflow: "auto",
    marginLeft: -15,
  },
  belongingDepartmentsList: {
    height: 110,
    width: 200,
    overflow: "auto",
    marginLeft: -15,
  },
}));

export default function DepartmentTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const classes = useStyles();

  const [options, setOptions] = React.useState({});
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [columns, setColumns] = React.useState([]);
  const [oldDepartment] = React.useState(null);
  const [modalLoading] = React.useState(false);
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    city: null,
    town: null,
    company: null,
    id: null,
    manager: null,
    name: null,
    unsuitUser: null,
    sgk: null,
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setColumns([
      { title: "No", field: "id", editable: "never" },
      { title: "Adı", field: "name" },
      { title: "Şirket", field: "companyName" },
      { title: "SGK Sicil No", field: "sgkno" },
      { title: "Şehir", field: "city" },
      { title: "İlçe", field: "town" },
      { title: "Yönetici", field: "managerName" },
      {
        title: "İSG Uzmanları",
        field: "isgUsers",
        render: (rowData) => (
          <>
            {rowData.isgUsers && rowData.isgUsers.length > 0 ? (
              <List className={classes.belongingDepartmentsList}>
                {rowData.isgUsers.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={data?.fName + " " + data?.lName} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <label>{language.etc.empty}</label>
            )}
          </>
        ),
      },
      {
        title: "Sağlık Personelleri",
        field: "healthUsers",
        render: (rowData) => (
          <>
            {rowData.healthUsers && rowData.healthUsers.length > 0 ? (
              <List className={classes.belongingDepartmentsList}>
                {rowData.healthUsers.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={data?.fName + " " + data?.lName} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <label>{language.etc.empty}</label>
            )}
          </>
        ),
      },
      { title: "Tehlike Sınıfı", field: "riskClass" },
      { title: "Faaliyet Tipi", field: "fieldOfActivity" },
      {
        title: "Faaliyet Bölgesi ve Uygunsuzluk Giderici Personel",
        field: "unsuitUsersAndTowns",
        render: (rowData) => (
          <>
            {rowData.unsuitUsersAndTowns &&
            rowData.unsuitUsersAndTowns.length > 0 ? (
              <List className={classes.fieldsList}>
                {rowData.unsuitUsersAndTowns.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={data} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <label>{language.etc.empty}</label>
            )}
          </>
        ),
      },
      {
        title: "Bağlı Olduğu Bölümler",
        field: "belongingDepartments",
        render: (rowData) => (
          <>
            {rowData.belongingDepartments &&
            rowData.belongingDepartments.length > 0 ? (
              <List className={classes.belongingDepartmentsList}>
                {rowData.belongingDepartments.map((data, index) => (
                  <ListItem key={index}>
                    <ListItemText secondary={data.name} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <label>{language.etc.empty}</label>
            )}
          </>
        ),
      },
    ]);
    setOptions(options);
    setBackdropLoading(false);
  }, [classes, language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedDepartment(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedDepartment(null);
    setDialog(false);
  };

  const removeDepartment = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/departments/" + selectedDepartment?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      city: null,
      town: null,
      company: null,
      id: null,
      manager: null,
      name: null,
      unsuitUser: null,
      sgk: null,
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeDepartment}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <NewDepartmentModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldDepartmentProps={oldDepartment}
        opts={options}
        modalLoading={modalLoading}
      />
      <RenderFilters />
      <Table
        tableName={language.sidebar.departments}
        authName="departmentsTable"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        //deleteEntry={removeDepartment}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isDeletable={(user, rowData) => rowData.active}
        isEditHidden={true}
        rowStyle={(rowData) => ({
          backgroundColor: !rowData.active && "#804f4f",
        })}
        detailsWindow={(rowData) => window.open("/department/" + rowData.id)}
      />
    </>
  );
}
