import React from "react";
import EDVARSWorkAccidentTable from "./components/EDVARSWorkAccidentTable";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from "../../assets/styles/tableContainer";
import SessionHelper from "../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../helpers/AuthorizationHelper";
import UnauthorizedPage from "../UnauthorizedPage";

export default function EDVARSWorkAccidentPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "edvarsAccidentTable");
  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? (
          <EDVARSWorkAccidentTable />
        ) : (
          <UnauthorizedPage />
        )}
      </Grid>
    </Container>
  );
}
//item xs={24} md={24} lg={24}
