import Request from '../../../helpers/Request';
import SessionHelper from '../../../helpers/SessionHelper';
import LanguageHelper from '../../../helpers/LanguageHelper';
import moment from 'moment';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      filterCompletionStart: filterProps.filterCompletionStart,
      filterCompletionEnd: filterProps.filterCompletionEnd,
      filterTerminationStart: filterProps.filterTerminationStart,
      filterTerminationEnd: filterProps.filterTerminationEnd,
      filterApprovedStart: filterProps.filterApprovedStart,
      filterApprovedEnd: filterProps.filterApprovedEnd,
      filterDepartment: filterProps.filterDepartment?.id,
      filterCompany: filterProps.filterCompany?.id,
      filterGivenBy: filterProps.filterGivenBy?.id,
      filterAssignedTo: filterProps.filterAssignedTo?.id,
      filterStatus: filterProps.filterStatus,
      filterIsgCommitteeCompletionDateStart: filterProps.filterIsgCommitteeCompletionDateStart,
      filterIsgCommitteeCompletionDateEnd: filterProps.filterIsgCommitteeCompletionDateEnd
    };
    if(filterProps.personal) {params.userId = user.id;}
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "customId") { params.sort = "customId," + query.orderDirection; }
      else if (query.orderBy.field === "approvedDate") { params.sort = "approvedDate," + query.orderDirection; }
      else if (query.orderBy.field === "assignedBy") { params.sort = "assignedBy.fName,assignedBy.lName," + query.orderDirection; }
      else if (query.orderBy.field === "status") { params.sort = "status," + query.orderDirection; }
      else if (query.orderBy.field === "assignedTo") { params.sort = "assignedTo.fName,assignedTo.lName," + query.orderDirection; }
      else if (query.orderBy.field === "terminationDate") { params.sort = "terminationDate," + query.orderDirection; }
      else if (query.orderBy.field === "completionDate") { params.sort = "completionDate," + query.orderDirection; }
      else if (query.orderBy.field === "isgCommitteeCompletionDate") { params.sort = "isgCommitteeCompletionDate," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
    }
    if(query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/isgCommittee/action", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          approvedDate: content[i].approvedDate,
          assignedTo: content[i].assignedTo?.id,
          assignedToObject: content[i].assignedTo,
          assignedToName: content[i].assignedTo ? content[i].assignedTo?.fName + " " + content[i].assignedTo?.lName : null,
          givenBy: content[i].givenBy?.id,
          givenByObject: content[i].givenBy,
          givenByName: content[i].givenBy ? content[i].givenBy?.fName + " " + content[i].givenBy?.lName : null,
          customId: content[i].customId,
          id: content[i].id,
          messages: formatMessages(content[i].messages),
          completionDate: content[i].completionDate,
          terminationDate: content[i].terminationDate,
          status: content[i].status,
          fileKeys: content[i].files,
          companyName: content[i].company?.name,
          departmentName: content[i].department?.name,
          isgCommitteeCompletionDate: content[i].isgCommitteeCompletionDate,
        };
        edit.files = [];
        for(let file of content[i]?.files) {
          edit.files.push(new File([file?.fileName], file?.fileName, { type: "text/plain" }));
        }
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      })
    }
  })
  return data;
}

const formatMessages = (messages) => {
  if(!messages) return null;
  const arr = messages.map((message) => 
    message.user.fName + " " + message.user.lName + " (" 
      + moment(message.createdAt).format("DD-MM-YYYY HH:mm:ss") 
      + "): " + message.note
  );
  return arr.join("\n");
}