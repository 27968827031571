import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  newCityName,
  setNewCityName,
  submitNewCity,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={"Şehir Adı"}
        value={newCityName}
        onChangeFunc={(value) => setNewCityName(value.target.value)}
        style={classes.textField}
      />
      <div style={{paddingBottom: 20, textAlign: "center"}}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => submitNewCity()}
          className={classes.submit}
        >
          {language.form.submit}
        </Button>}
      </div>
    </FormControl>
  );
}
