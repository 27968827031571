import Request from '../../../../helpers/Request';
import LanguageHelper from '../../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      company: filterProps.filterCompany?.id,
      department: filterProps.filterDepartment?.id,
      startTime: filterProps.filterStart,
      endTime: filterProps.filterEnd,
      user: filterProps.filterUser?.id,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "number") { params.sort = "number," + query.orderDirection; }
      else if (query.orderBy.field === "userName") { params.sort = "user.fName,user.lName," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "date") { params.sort = "analysisDate," + query.orderDirection; }
      else if (query.orderBy.field === "createdAt") { params.sort = "createdAt," + query.orderDirection; }
    }
    if(query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/job-risk", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          number: data[i].analysisNumber,
          user: data[i].userId,
          userName: data[i].user,
          company: data[i].companyId,
          companyName: data[i].company,
          department: data[i].departmentId,
          departmentName: data[i].department,
          date: data[i].date,
          createdAt: data[i].createdAt,
        };
        edit.files = [];
        for(let file of data[i]?.files) {
          edit.files.push(new File([file.name], file.name, { type: "text/plain" }));
        }
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      })
    }
  })
  return data;
}