import React from 'react';
import TasksTable from './components/TasksTable';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { tableContainerStyles } from '../../../assets/styles/tableContainer';
import UnauthorizedPage from '../../UnauthorizedPage';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import SessionHelper from '../../../helpers/SessionHelper';

export default function TasksPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "tasksTable");
  
  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view ? <TasksTable /> : <UnauthorizedPage/>}
      </Grid>
    </Container>
  );
}