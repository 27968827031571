import Request from "../../helpers/Request";
import LanguageHelper from "../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      company: filterProps.company?.id,
      department: filterProps.department?.id,
      reportStatus: filterProps.reportStatus,
      educationStatus: filterProps.educationStatus,
      endTime: filterProps.endTime,
      startTime: filterProps.startTime,
      name: filterProps.name,
      surname: filterProps.surname,
      dateTakenStart: filterProps.dateTakenStart,
      dateTakenEnd: filterProps.dateTakenEnd,
      validityStart: filterProps.validityStart,
      validityEnd: filterProps.validityEnd,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") {
        params.sort = "date," + query.orderDirection;
      } else if (query.orderBy.field === "level") {
        params.sort = "level," + query.orderDirection;
      } else if (query.orderBy.field === "message") {
        params.sort = "message," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/users/personnel-files/first-aid-certiciate-reports",
      null,
      params
    );
    console.log(resp.data);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          id: content[i]?.id,
          tcNo: content[i]?.tcNo,
          companyName: content[i]?.companyName,
          departmentName: content[i]?.departmentName,
          title: content[i]?.city?.title,
          name: content[i]?.name,
          surname: content[i]?.surname,
          startDateOfWork: moment(content[i]?.startDateOfWork).format(
            "DD-MM-YYYY"
          ),
          educationStatus: content[i]?.educationStatus,
          //reportStatus: content[i]?.props?.reportStatus,
          reportStatus: content[i]?.props ? (content[i]?.props.reportStatus === "Var"
            ? moment(content[i]?.props?.firstAidCertificateValidityDate).isSameOrAfter()
              ? true
              : false
            : false)
          : false,
          firstAidTakenDate: content[i]?.props?.firstAidCertificateTakenDate
            ? moment(content[i]?.props?.firstAidCertificateTakenDate).format("DD-MM-YYYY")
            : null,
          firstAidValidityDate: content[i]?.props?.firstAidCertificateValidityDate
            ? moment(content[i]?.props?.firstAidCertificateValidityDate).format(
                "DD-MM-YYYY"
              )
            : null,
          firstAidPeriodOfValidity: content[i]?.props?.firstAidCertificatePeriodOfValidity,
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      });
    }
  });
  return data;
}
