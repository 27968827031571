import React, { forwardRef, useCallback, useEffect } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import AddBox from "@material-ui/icons/AddBox";
import FileSaver from "file-saver";
import moment from "moment";
import { List, ListItem, ListItemText } from "@material-ui/core";
import "../../../../App.css";
import SessionHelper from "../../../../helpers/SessionHelper";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../../helpers/AuthorizationHelper";
import Request, { fileUrl } from "../../../../helpers/Request";
import CustomSnackbar from "../../../../components/Snackbar/Snackbar";
import Table from "../../../../components/Table/Table";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import DeleteDialog from "../../../../components/Dialog/DeleteDialog";
import GetFilterFields from "./MedicalExamFilters";
import MedicalExamModal from "./MedicalExamModal";
import { getUsers, getDepartments } from "./GetOptions";
import { examType, trueFalse } from "../../../../assets/constants";
import IbysPostDialog from "../../../../components/Dialog/IbysPostDialog";
import { Send } from "@material-ui/icons";
import MultipleSnackbar from "../../../../components/Snackbar/MultipleSnackbar";

export default function MedicalExamTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "healthMonitoring");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [dialog, setDialog] = React.useState(false);
  const [postDialog, setPostDialog] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [idsToDelete, setIdsToDelete] = React.useState([]);
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [selectedExam, setSelectedExam] = React.useState(null);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    department: null,
    endTime: null,
    startTime: null,
    user: null,
    type: null,
  });
  const [snackbars, setSnackbars] = React.useState([]);

  // const data = {
  //   responses: {
  //     "kayit 1": { messages: [], content: null, status: "OK" },
  //     "kayit 2": { messages: [], content: null, status: "BAD_REQUEST" },
  //   },
  // };

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.tableColumns.id, field: "id" },
      { title: language.tableColumns.personnel, field: "personnelName" },
      {
        title: language.tableColumns.personnelSurname,
        field: "personnelSurname",
      },
      { title: language.auditTasks.company, field: "companyName" },
      { title: language.auditTasks.department, field: "departmentName" },
      {
        title: language.auditTasks.inspectionType_,
        field: "examType",
        lookup: examType,
      },
      {
        title: language.actions.inspectionDate_,
        field: "examDate",
        render: (rowData) =>
          moment(rowData.examDate.replaceAll("Z", "")).format(
            "DD-MM-YYYY HH:mm"
          ),
      },
      { title: "Gönderim Durumu", field: "gonderimDurumu" },
      { title: language.ibysPages.no, field: "bildirimNo" },
      {
        title: language.ibysPages.aracKullanimi,
        field: "aracKullanimi",
        lookup: options.aracKullanimi,
      },
      {
        title: language.ibysPages.calisanMeslegi,
        field: "calisanMeslegi",
      },
      {
        title: language.ibysPages.calismaPozisyonu,
        field: "calismaPozisyonu",
        lookup: options.calismaPozisyonu,
      },
      {
        title: language.ibysPages.idrarTetkikiYapildiMi,
        field: "idrarTetkikiYapildiMi",
        lookup: trueFalse,
      },
      {
        title: language.ibysPages.isitmeTestiYapildiMi,
        field: "isitmeTestiYapildiMi",
        lookup: trueFalse,
      },
      {
        title: language.ibysPages.kanTetkikiYapildiMi,
        field: "kanTetkikiYapildiMi",
        lookup: trueFalse,
      },
      {
        title: language.ibysPages.solunumFonkTestiYapildiMi,
        field: "solunumFonkTestiYapildiMi",
        lookup: trueFalse,
      },
      {
        title: language.ibysPages.rontgenYapildiMi,
        field: "rontgenYapildiMi",
        lookup: trueFalse,
      },
      {
        title: language.ibysPages.calismaZamani,
        field: "calismaZamani",
        lookup: options.calismaZamani,
      },
      {
        title: language.ibysPages.raporTuru,
        field: "raporTuru",
        lookup: options.raporTuru,
      },
      {
        title: language.ibysPages.sonucVeKanaat,
        field: "sonucVeKanaat",
        lookup: options.sonucVeKanaat,
      },
      {
        title: language.ibysPages.raporGecerlilikTarihi,
        field: "raporGecerlikTarihi",
        render: (rowData) =>
          moment(rowData.raporGecerlikTarihi.replaceAll("Z", "")).format(
            "DD-MM-YYYY HH:mm"
          ),
      },
      {
        title: language.ibysPages.biyolojikEtkenlerSinifi,
        field: "biyolojikEtkenlerSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.biyolojikEtkenlerSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.biyolojikEtkenlerSinifi.find((x) => x.id == data)
                        ?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.elektrikSinifi,
        field: "elektrikSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.elektrikSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.elektrikSinifi.find((x) => x.id == data)?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.fizikiOrtamSinifi,
        field: "fizikiOrtamSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.fizikiOrtamSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.fizikiOrtamSinifi.find((x) => x.id == data)?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.gurultuSinifi,
        field: "gurultuSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.gurultuSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.gurultuSinifi.find((x) => x.id == data)?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.havaSinifi,
        field: "havaSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.havaSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.havaSinifi.find((x) => x.id == data)?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.kimyasalMaddeSinifi,
        field: "kimyasalMaddeSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.kimyasalMaddeSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.kimyasalMaddeSinifi.find((x) => x.id == data)
                        ?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.tozYonetmeligiSinifi,
        field: "tozYonetmeligiSinifi",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.tozYonetmeligiSinifi.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText
                    secondary={
                      options.tozYonetmeligiSinifi.find((x) => x.id == data)
                        ?.name
                    }
                  />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
      {
        title: language.ibysPages.workEquipments,
        field: "isEkipmanlari",
        render: (rowData) => (
          <div>
            <List
              style={{
                width: 250,
                height: 100,
                overflow: "auto",
              }}
            >
              {rowData.isEkipmanlari.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={data?.name} />
                </ListItem>
              ))}
            </List>
          </div>
        ),
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data) => {
    data && setSelectedExam(data);
    setModalLoading(true);
    setEditModal(true);
    let opts = { ...options };
    if (data && data.company) {
      opts.departments = await getDepartments(data.company);
    }
    if (data && data.department) {
      opts.users = await getUsers(data.department);
    }
    setOptions(opts);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    if (!idsToDelete.length > 0) {
      setSelectedExam(data);
    }
    // setSelectedExam(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    if (!idsToDelete.length > 0) {
      setSelectedExam(null);
    }
    setDialog(false);
  };

  const handleOpenPostDialog = (data) => {
    if (!idsToDelete.length > 0) {
      setSelectedExam(data);
    }
    setPostDialog(true);
  };

  const handleClosePostDialog = () => {
    if (!idsToDelete.length > 0) {
      setSelectedExam(null);
    }
    setPostDialog(false);
  };

  const removeExam = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/examination/" + selectedExam?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const postExam = async () => {
    setRequestLoading(true);
    console.log(selectedExam);
    const resp = await Request(
      "get",
      "/api/ibys/examination/" + selectedExam?.id
    );
    if (resp.status === 200) {
      setSeverity("success");
      setSnackbarMessage(resp.data.messages[0]);
      setSnackbar(true);
    } else {
      setSeverity("error");
      setSnackbarMessage(resp.data.messages[0]);
      setSnackbar(true);
    }

    handleClosePostDialog();
    setRequestLoading(false);
  };

  const postExams = async () => {
    setRequestLoading(true);
    let responsesArray = [];
    const resp = await Request("post", "/api/ibys/examinations", idsToDelete);
    console.log(resp);
    if (resp.status === 207) {
      responsesArray = Object.keys(resp.data.responses).map((key) => ({
        key: key,
        ...resp.data.responses[key],
        open: true,
        severity:
          resp.data.responses[key].status === "OK" ? "success" : "error",
      }));
      console.log(responsesArray);
    }
    setSnackbars(responsesArray);
    handleClosePostDialog();
    setRequestLoading(false);
    tableRef.current.onQueryChange();
  };

  const removeExams = async () => {
    setRequestLoading(true);
    const param = {
      ids: idsToDelete.join(),
    };
    const resp = await Request("delete", "/api/examination/", null, param);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      department: null,
      endTime: null,
      startTime: null,
      user: null,
      type: null,
    });
  };

  const DownloadFile = async (rowData) => {
    if (rowData.examinationFile) {
      const url = fileUrl + rowData.examinationFile.fileName;
      window.open(url);
    } else {
      setLoading(true);
      const resp = await Request(
        "get",
        "/api/examination/" + rowData.id + "/report"
      );
      console.log(resp);
      if (resp.status === 200) {
        const fileName = resp.headers["content-disposition"].substring(
          resp.headers["content-disposition"].indexOf("=") + 1
        );
        const contentType = fileName.split(".").pop();
        const source =
          "data:application/" +
          contentType +
          ";base64, " +
          encodeURI(resp.data);
        FileSaver.saveAs(source, fileName);
      }
      setLoading(false);
    }
  };

  // This function will be executed after a delay of 3000 milliseconds (3 seconds)

  return (
    <React.Fragment>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
        autoHideDuration={null}
      />
      <MultipleSnackbar snackbars={snackbars} setSnackbars={setSnackbars} />
      <DeleteDialog
        remove={
          idsToDelete.length > 0 ? removeExams : selectedExam && removeExam
        }
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <IbysPostDialog
        post={idsToDelete.length > 0 ? postExams : selectedExam && postExam}
        open={postDialog}
        loading={requestLoading}
        close={handleClosePostDialog}
      />
      <MedicalExamModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldExamProps={selectedExam}
        setOldExamProps={setSelectedExam}
        options={options}
        setOptions={setOptions}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters />
      <Table
        tableName={language.sidebar.examinations}
        authName={"healthMonitoring"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        handleOpenModal={handleOpenModal}
        filters={filterProps}
        setSelectionIds={setIdsToDelete}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        //detailsPanel={(rowData) => <Details rowData={rowData}/>}
        isDeleteHidden={() => !authorization.delete}
        isDeletable={() =>
          roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          roles.includes("ROLE_SİSTEM_ADMİNİ")
        }
        extraAction={{
          auth: true,
          icon: forwardRef((props, ref) => <GetAppIcon {...props} ref={ref} />),
          tooltip: language.downloadTemplate,
          position: "row",
          onClick: (rowData) => DownloadFile(rowData),
        }}
        ibysPost={{
          auth:
            roles.includes("ROLE_SAĞLIK_PERSONELİ") ||
            roles.includes("ROLE_OSGB_SAĞLIK_PERSONELİ") ||
            roles.includes("ROLE_SİSTEM_ADMİNİ"),
          icon: forwardRef((props, ref) => <Send {...props} ref={ref} />),
          tooltip: language.ibysPost.tooltip,
          position: "row",
          onClick: (rowData) => handleOpenPostDialog(rowData),
        }}
      />
    </React.Fragment>
  );
}
