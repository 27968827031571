import { makeStyles } from "@material-ui/core/styles";
import { Divider, FormControl } from "@material-ui/core";
import React from "react";
import KKDTable from "./components/KKDTable";
import Measurements from "./components/Measurements";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
  },
  divider: {
    marginTop: 20,
    marginBottom: 30,
  },
}));

export default function KKD({
  userProps,
  setUserProps,
  setChangeMade,
  options,
  errors,
  setErrors,
}) {
  const classes = useStyles();

  const RenderMeasurements = () => {
    return (
      <Measurements
        userProps={userProps}
        setUserProps={setUserProps}
        setChangeMade={setChangeMade}
        options={options}
      />
    );
  };

  const RenderKKDTable = () => {
    return (
      <KKDTable
        options={options}
        userProps={userProps}
        setUserProps={setUserProps}
        setChangeMade={setChangeMade}
        error={errors}
        setError={setErrors}
      />
    );
  };

  return (
    <>
      <FormControl required autoComplete="off" className={classes.formControl}>
        <h2>Kişisel Koruyucu Donanımlar</h2>
        <RenderKKDTable />
        <Divider className={classes.divider} />
        <RenderMeasurements />
      </FormControl>
    </>
  );
}
