import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LanguageHelper from '../../../../helpers/LanguageHelper';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { taskStatuses, inspectionTypes } from '../../../../assets/constants';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  column: {
    fontSize: 14,
    fontColor: "darkblue"
  }
}));

export default function TaskData({data}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const status = taskStatuses;
  let unsuit = "";
  if (data.unsuitability) { 
    if(data.personalUnsuitability) {unsuit = "Personelde Uygunsuzluk Var"}
    else if(data.departmentUnsuitability) {unsuit = "Bölümde Uygunsuzluk Var"}
    if(data.departmentUnsuitability && data.personalUnsuitability) {unsuit = "Personelde ve Bölümde Uygunsuzluk Var"}
  }
  else if(data.eStatus !== "DONE") { unsuit = "-" }
  else { unsuit = "Yok" }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">{language.tableColumns.id}</TableCell>
              <TableCell align="left">{language.tableColumns.name}</TableCell>
              <TableCell align="left">{language.tableColumns.inspectionType}</TableCell>
              <TableCell align="left">{language.tableColumns.unsuitability}</TableCell>
              <TableCell align="left">{language.tableColumns.inspectionDate}</TableCell>
              <TableCell align="left">{language.tableColumns.terminationDate}</TableCell>
              <TableCell align="left">{language.tableColumns.company}</TableCell>
              <TableCell align="left">{language.tableColumns.department}</TableCell>
              <TableCell align="left">{"Açıklama"}</TableCell>
              <TableCell align="left">{"Skor"}</TableCell>
              <TableCell align="left">{language.tableColumns.status}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow >
              <TableCell align="left">{data.id}</TableCell>
              <TableCell align="left">{data.user.fName + " " + data.user.lName}</TableCell>
              <TableCell align="left">{inspectionTypes[data.inspectionType]}</TableCell>
              <TableCell align="left">{unsuit}</TableCell>
              <TableCell align="left">{data.completionDate ? (new moment(data.completionDate)).format("DD-MM-YYYY") : "-"}</TableCell>
              <TableCell align="left">{(new moment(data.terminationDate)).format("DD-MM-YYYY")}</TableCell>
              <TableCell align="left">{data.company.name}</TableCell>
              <TableCell align="left">{data.department.name}</TableCell>
              <TableCell align="left">{data.text || "Yok"}</TableCell>
              <TableCell align="left">{(data.score !== undefined && data.score !== null) ? data.score : "Yok"}</TableCell>
              <TableCell align="left">{status[data.eStatus]}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  ) 
}