import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestVisitorProps,
  visitorProps,
  setRequestVisitorProps,
  submitNewVisitor,
  updateVisitor,
  loading,
  options,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={language.visitors.email}
        value={requestVisitorProps.email}
        onChangeFunc={(value) =>
          setRequestVisitorProps({
            ...requestVisitorProps,
            email: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        required
        label={language.visitors.name}
        value={requestVisitorProps.name}
        onChangeFunc={(value) =>
          setRequestVisitorProps({
            ...requestVisitorProps,
            name: value.target.value,
          })
        }
        style={classes.textField}
      />
      <TextArea
        required
        label={language.visitors.surname}
        value={requestVisitorProps.surname}
        onChangeFunc={(value) =>
          setRequestVisitorProps({
            ...requestVisitorProps,
            surname: value.target.value,
          })
        }
        style={classes.textField}
      />
      <PhoneInput
        required //does not work for now
        placeholder={language.visitors.phoneNumber}
        value={requestVisitorProps.phoneNo}
        onChange={(value) =>
          setRequestVisitorProps({
            ...requestVisitorProps,
            phoneNo: value,
          })
        }
        defaultCountry="TR"
        className={classes.textField}
      />
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          requestVisitorProps.companyId
            ? getFromId(requestVisitorProps.companyId, options.companies)
            : null
        }
        onChange={async (event, value) => {
          if (value === null) {
            setRequestVisitorProps({ ...requestVisitorProps, companyId: null });
          } else {
            setRequestVisitorProps({
              ...requestVisitorProps,
              companyId: value?.id,
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitors.companyName}
            className={classes.textField}
          />
        )}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              visitorProps ? updateVisitor() : submitNewVisitor()
            }
            className={classes.submit}
          >
            {language.modal.update}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
