import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Request from "../../../helpers/Request";
import ModalForm from './ModalForm';
import { modalStyles } from '../../../assets/styles/tableContainer';
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => (modalStyles(theme)));

export default function KKDModal({modal, setModal, setSnackbar, setSnackbarMessage, setSeverity, tableRef, kkdTaskProps, opts}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestKKDTaskProps, setRequestKKDTaskProps] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setRequestKKDTaskProps({
      taskName: kkdTaskProps ? kkdTaskProps.taskName : null,
      type: kkdTaskProps ? kkdTaskProps.type : null,
    });
    setOptions(opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkdTaskProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestKKDTaskProps({
      taskName: null,
      type: null
    });
  }

  const submitNewKKDTask = async () => {
    setLoading(true);
    const resp = await Request("post", '/api/kkd-tasks', requestKKDTaskProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }
  
  const updateKKDTask = async () => {
    setLoading(true);
    let props = {...requestKKDTaskProps};
    props.taskId = kkdTaskProps.id;
    const resp = await Request("patch", '/api/kkd-tasks', props);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm 
              requestKKDTaskProps={requestKKDTaskProps} 
              kkdTaskProps={kkdTaskProps} 
              setRequestKKDTaskProps={setRequestKKDTaskProps} 
              submitNewKKDTask={submitNewKKDTask}
              updateKKDTask={updateKKDTask}
              options={options}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}