import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";
import { active } from "../../../assets/constants";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestInjuryTypeProps,
  injuryTypeProps,
  setRequestInjuryTypeProps,
  submitNewInjuryType,
  updateInjuryType,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      {injuryTypeProps && (
        <TextSelector
          label="Aktiflik Durumu"
          shrink={!injuryTypeProps.active}
          value={
            requestInjuryTypeProps.active
              ? requestInjuryTypeProps.active
              : injuryTypeProps.active
          }
          onChangeFunc={(value) =>
            setRequestInjuryTypeProps({
              ...requestInjuryTypeProps,
              active: value.target.value,
              "selection-type": "InjuryType",
            })
          }
          style={classes.textField}
          options={jsonMap(false, active)}
        />
      )}
      <TextArea
        required
        label={language.workAccidentSelections.injuryType} 
        value={requestInjuryTypeProps.name}
        onChangeFunc={(value) =>
          setRequestInjuryTypeProps({
            ...requestInjuryTypeProps,
            name: value.target.value,
            "selection-type": "InjuryType",
          })
        }
        style={classes.textField}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() =>
              injuryTypeProps
                ? updateInjuryType()
                : submitNewInjuryType()
            }
            className={classes.submit}
          >
            {injuryTypeProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
