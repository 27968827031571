import React from "react";
import moment from 'moment';
import { modalFormStyles } from '../../../../../assets/styles/tableContainer';
import TableEntryModal from '../../../../../components/Modal/TableEntryModal';
import { CustomDatePicker } from '../../../../../components/Selectors/DatePickers';
import { TextSelector } from '../../../../../components/Fields/TextFields';
import { Button, CircularProgress, FormControl, makeStyles } from "@material-ui/core";
import LanguageHelper from "../../../../../helpers/LanguageHelper";
import showSnackbar from '../../../../../components/Utils/showSnackbar';
import Request from "../../../../../helpers/Request";
import jsonMap from '../../../../../components/Utils/jsonMap';


const useStyles = makeStyles((theme) => (modalFormStyles(theme)));

/**
 * Modal component used in the user kkd table for deleting debts.
 * @param {modal: boolean, setModal: func, setSnackbarMessage: func, setSnackbar: func, update: func} props of the debt delete modal 
 */
export default function DeleteModal({options, modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, update}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  
  const [loading, setLoading] = React.useState(false);
  const [props, setProps] = React.useState({
    id: modal,
    returnDate: null,
    returnReason: null
  })

  const [dateError, setDateError] = React.useState(null);
  const [reasonError, setReasonError] = React.useState(null);

  /**
   * Closes the modal and flushes the state.
   */
  const handleCloseModal = () => {
    setModal(false);
    setProps({
      id: null,
      returnDate: null,
      returnReason: null
    })
    setDateError(null);
    setReasonError(null);
    setLoading(false);
  }

  const deleteDebt = async () => {
    setLoading(true);
    props.id = modal;
    const resp = await Request("delete", "/api/kkd-debits/", props);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, null, update);
    handleCloseModal();
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    if(!props.returnDate) {
      setDateError(errorMessage);
      error = true;
    }
    if(!props.returnReason) {
      setReasonError(errorMessage);
      error = true;
    }

    if(!error) {
      deleteDebt();
    }
  }

  return (
    <>
      <TableEntryModal
        modal={modal ? true : false}
        handleCloseModal={handleCloseModal}>
        <FormControl noValidate autoComplete="off" className={classes.formControl}>
          <CustomDatePicker
            style={classes.dateField}
            required
            error={dateError}
            label={"İade tarihi"}
            value={props.returnDate}
            shrink={props.returnDate}
            onChangeFunc={value => {
              setProps({...props, returnDate: moment(value).toISOString(true)});
              dateError && setDateError(null);
            }}
          />
          <TextSelector
            error={reasonError}
            label={"İade Açıklaması"}
            required
            value={props.returnReason}
            style={classes.textField}
            onChangeFunc={value => {
              reasonError && setReasonError(null);
              setProps({...props, returnReason: value.target.value});
            }}
            options={jsonMap("returnReasons", options)}
          />
          <div style={{textAlign: "center"}}>
            {loading ?
            <CircularProgress color="secondary"/>
            :
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}>
              {"İADE ET"}
            </Button>}
          </div>
        </FormControl>
      </TableEntryModal>  
    </>
  );
}