import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { CircularProgress, Button, Grid } from "@material-ui/core";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { GetOptions } from "./GetOptions";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Group from "./Group";
import SessionHelper from "../../../helpers/SessionHelper";

const useStyles = makeStyles((theme) => modalStyles(theme));

const propsDefault = {
  plannedDate: null,
  visitTypeId: null,
  departmentId: null,
  participantIds: [],
  visitorIdDocumentId: [],
  visitorIdEquipmentId: [],
  visitorIdVideoId: [],
  visitorIds: [],
  topic: null,
  note: null,
  participantObjects: [],
  status: null,
};

export default function VisitsModal({
  modal,
  setModal,
  modalLoading,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  visitProps,
  setVisitProps,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestVisitProps, setRequestVisitProps] =
    React.useState(propsDefault);
  // Visitors icin degisiklik yapacagiz, modalda gostermek icin onceki verileri kullanacagiz.
  const [visitors, setVisitors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState({});
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const isSecurity = roles.includes("ROLE_GÜVENLİK_GÖREVLİSİ");
  const isHost = visitProps?.hostId === user?.id;

  const init = useCallback(async () => {
    if (visitProps) {
      const options = await GetOptions(visitProps.visitTypeId);
      setOptions(options);
      setRequestVisitProps({
        plannedDate: visitProps
          ? moment(visitProps?.plannedDate, "DD-MM-YYYY HH:mm:ss").toISOString(
              true
            )
          : null,
        visitTypeId: visitProps.visitTypeId,
        departmentId: visitProps.departmentId,
        participantIds: visitProps.participantIds,
        visitorIdDocumentId: visitProps.visitorIdDocumentId,
        visitorIdEquipmentId: visitProps.visitorIdEquipmentId,
        visitorIdVideoId: visitProps.visitorIdVideoId,
        visitorIds: visitProps.visitorIds,
        topic: visitProps.topic,
        note: visitProps.note,
        participantObjects: visitProps.participantObjects,
        visitorIdDocumentId: [],
        visitorIdVideoId: [],
        visitorIdEquipmentId: [],
        status: visitProps.status,
      });
      setVisitors(visitProps.visitors);
    } else {
      const options = await GetOptions();
      setOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestVisitProps(propsDefault);
    setVisitors([]);
    setVisitProps(null);
  };

  const submitNewVisit = async () => {
    setLoading(true);
    requestVisitProps.participantIds =
      requestVisitProps?.participantObjects.map((prt) => prt.id);
    delete requestVisitProps.participantObjects;
    requestVisitProps.visitorIds = visitors?.map((vstr) => vstr.visitorId);
    for (let i = 0; i < visitors.length; i++) {
      for (let j = 0; j < visitors[i]?.documentIds?.length; j++) {
        requestVisitProps.visitorIdDocumentId.push({
          materialId: visitors[i]?.documentIds[j]?.id,
          visitorId: visitors[i]?.visitorId,
        });
      }
    }
    for (let i = 0; i < visitors.length; i++) {
      for (let j = 0; j < visitors[i]?.videoIds?.length; j++) {
        requestVisitProps.visitorIdVideoId.push({
          materialId: visitors[i]?.videoIds[j]?.id,
          visitorId: visitors[i]?.visitorId,
        });
      }
    }
    for (let i = 0; i < visitors.length; i++) {
      for (let j = 0; j < visitors[i]?.equipmentIds?.length; j++) {
        requestVisitProps.visitorIdEquipmentId.push({
          materialId: visitors[i]?.equipmentIds[j]?.id,
          visitorId: visitors[i]?.visitorId,
        });
      }
    }
    const resp = await Request("post", "/api/vm/visit", requestVisitProps);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateVisit = async () => {
    setLoading(true);
    let props = { ...requestVisitProps };
    props.visitId = visitProps.id;
    props.participantIds = props?.participantObjects.map((prt) => prt.id);
    delete props.participantObjects;
    props.visitorIds = visitors?.map((vstr) => vstr.visitorId);
    for (let i = 0; i < visitors.length; i++) {
      for (let j = 0; j < visitors[i]?.documentIds?.length; j++) {
        props.visitorIdDocumentId.push({
          materialId: visitors[i]?.documentIds[j]?.id,
          visitorId: visitors[i]?.visitorId,
        });
      }
    }
    for (let i = 0; i < visitors.length; i++) {
      for (let j = 0; j < visitors[i]?.videoIds?.length; j++) {
        props.visitorIdVideoId.push({
          materialId: visitors[i]?.videoIds[j]?.id,
          visitorId: visitors[i]?.visitorId,
        });
      }
    }
    for (let i = 0; i < visitors.length; i++) {
      for (let j = 0; j < visitors[i]?.equipmentIds?.length; j++) {
        props.visitorIdEquipmentId.push({
          materialId: visitors[i]?.equipmentIds[j]?.id,
          visitorId: visitors[i]?.visitorId,
        });
      }
    }
    props.plannedDate = props.plannedDate.replace("+03:00", "Z");
    const resp = await Request("put", "/api/vm/visit/update/visit", props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <React.Fragment>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          requestVisitProps={requestVisitProps}
          visitProps={visitProps}
          setRequestVisitProps={setRequestVisitProps}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
        />
        {(!isSecurity || !visitProps || (isSecurity && isHost)) && (
          <div>
            <p style={{ color: "#C7C7C7" }}></p>
            <Grid item xs={6}>
              <p style={{ marginTop: -5, color: "#B8B8B8" }}>
                {language.visitors.visitors}
              </p>
            </Grid>
            <Group
              options={options}
              visitors={visitors}
              setVisitors={setVisitors}
              loading={loading}
            />
          </div>
        )}
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => (visitProps ? updateVisit() : submitNewVisit())}
              className={classes.submit}
            >
              {visitProps ? language.modal.update : language.modal.submit}
            </Button>
          )}
        </div>
      </TableEntryModal>
    </React.Fragment>
  );
}
