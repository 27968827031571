import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestForwardProps,
  setRequestForwardProps,
  submitForwardProps,
  loading,
  options,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete // Forward Users
        multiple
        id="combo-box"
        options={options.forwardUsers?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={requestForwardProps.to}
        onChange={(event, value) => {
          setRequestForwardProps({ ...requestForwardProps, to: value });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.workAccidentSelections.forwardUsers}
            className={classes.textField}
          />
        )}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => submitForwardProps()}
            className={classes.submit}
          >
            {language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
