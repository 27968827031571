import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { DateTimePicker } from "../../../../components/Selectors/DatePickers";
import { getUsers } from "./GetOptions";
import {
  TextArea,
  TextSelector,
} from "../../../../components/Fields/TextFields";
import jsonMap from "../../../../components/Utils/jsonMap";
import { examType, trueFalse } from "../../../../assets/constants";
import CustomFileDropzone from "../../../../components/Files/CustomFileDropzone";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from "../../../../helpers/Request";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
}));

export default function ModalForm({
  examProps,
  setExamProps,
  label,
  loading,
  setLoading,
  options,
  setOptions,
  handleCloseModal,
  oldExamProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();
  const meslekRegex = /[0-9]{4}\.[0-9]{2}$/;

  const [examinationFile, setExaminationFile] = React.useState(
    examProps.examinationFile
  );
  const [files, setFiles] = React.useState(examProps.files);
  const [errors, setErrors] = React.useState({
    userId: null,
    department: null,
    examinationTime: null,
    examinationType: null,
    aracKullanimi: null,
    calismaZamani: null,
    calismaPozisyonu: null,
    calisanMeslegi: null,
    raporGecerlilikTarihi: null,
    raporTuru: null,
    sonucVeKanaat: null,
    idrarTetkikiYapildiMi: null,
    isitmeTestiYapildiMi: null,
    kanTetkikiYapildiMi: null,
    rontgenYapildiMi: null,
    solunumFonkTestiYapildiMi: null,
    biyolojikEtkenlerSinifi: null,
    elektrikSinifi: null,
    fizikiOrtamSinifi: null,
    gurultuSinifi: null,
    havaSinifi: null,
    kimyasalMaddeSinifi: null,
    tozYonetmeligiSinifi: null,
    isEkipmanlari: null,
  });

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  /**
   * Request to replace the old data with the new data.
   */
  const updateExam = async () => {
    setLoading(true);
    const biyolojikEtkenlerSinifi = examProps.biyolojikEtkenlerSinifi.map(
      (val) => val.id
    );
    const elektrikSinifi = examProps.elektrikSinifi.map((val) => val.id);
    const fizikiOrtamSinifi = examProps.fizikiOrtamSinifi.map((val) => val.id);
    const gurultuSinifi = examProps.gurultuSinifi.map((val) => val.id);
    const havaSinifi = examProps.havaSinifi.map((val) => val.id);
    const kimyasalMaddeSinifi = examProps.kimyasalMaddeSinifi.map(
      (val) => val.id
    );
    const tozYonetmeligiSinifi = examProps.tozYonetmeligiSinifi.map(
      (val) => val.id
    );
    const isEkipmanlari = examProps.isEkipmanlari.map((val) => val.id);

    const body = {
      ...examProps,
      biyolojikEtkenlerSinifi,
      elektrikSinifi,
      fizikiOrtamSinifi,
      gurultuSinifi,
      havaSinifi,
      kimyasalMaddeSinifi,
      tozYonetmeligiSinifi,
      isEkipmanlari,
    };

    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    formData.append("examinationFile", examinationFile);
    delete body.examinationFile;
    formData.append(
      "body",
      new Blob([JSON.stringify(body)], { type: "application/json" })
    );
    const resp = await Request(
      "patch",
      "/api/examination/" + oldExamProps.id,
      formData
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const submitNewExam = async () => {
    setLoading(true);
    const biyolojikEtkenlerSinifi = examProps.biyolojikEtkenlerSinifi.map(
      (val) => val.id
    );
    const elektrikSinifi = examProps.elektrikSinifi.map((val) => val.id);
    const fizikiOrtamSinifi = examProps.fizikiOrtamSinifi.map((val) => val.id);
    const gurultuSinifi = examProps.gurultuSinifi.map((val) => val.id);
    const havaSinifi = examProps.havaSinifi.map((val) => val.id);
    const kimyasalMaddeSinifi = examProps.kimyasalMaddeSinifi.map(
      (val) => val.id
    );
    const tozYonetmeligiSinifi = examProps.tozYonetmeligiSinifi.map(
      (val) => val.id
    );
    const isEkipmanlari = examProps.isEkipmanlari.map((val) => val.id);

    const body = {
      ...examProps,
      biyolojikEtkenlerSinifi,
      elektrikSinifi,
      fizikiOrtamSinifi,
      gurultuSinifi,
      havaSinifi,
      kimyasalMaddeSinifi,
      tozYonetmeligiSinifi,
      isEkipmanlari,
    };

    let formData = new FormData();
    if (files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    formData.append("examinationFile", examinationFile);
    delete body.examinationFile;
    formData.append(
      "body",
      new Blob([JSON.stringify(body)], { type: "application/json" })
    );
    const resp = await Request("post", "/api/examination", formData);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.created,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = language.errors.blankField;
    let errors = {
      userId: null,
      department: null,
      examinationTime: null,
      examinationType: null,
      aracKullanimi: null,
      calismaPozisyonu: null,
      calismaZamani: null,
      raporGecerlilikTarihi: null,
      raporTuru: null,
      sonucVeKanaat: null,
      idrarTetkikiYapildiMi: null,
      isitmeTestiYapildiMi: null,
      kanTetkikiYapildiMi: null,
      rontgenYapildiMi: null,
      solunumFonkTestiYapildiMi: null,
      biyolojikEtkenlerSinifi: null,
      elektrikSinifi: null,
      fizikiOrtamSinifi: null,
      gurultuSinifi: null,
      havaSinifi: null,
      kimyasalMaddeSinifi: null,
      tozYonetmeligiSinifi: null,
      isEkipmanlari: null,
    };

    if (!examProps.userId) {
      errors.userId = errorMessage;
      error = true;
    }
    if (!examProps.department) {
      errors.department = errorMessage;
      error = true;
    }
    if (!examProps.examinationTime) {
      errors.examinationTime = errorMessage;
      error = true;
    }
    if (!examProps.examinationType) {
      errors.examinationType = errorMessage;
      error = true;
    }
    if (!examProps.aracKullanimi) {
      errors.aracKullanimi = errorMessage;
      error = true;
    }
    if (!examProps.calismaPozisyonu) {
      errors.calismaPozisyonu = errorMessage;
      error = true;
    }
    if (!examProps.calismaZamani) {
      errors.calismaZamani = errorMessage;
      error = true;
    }
    if (!examProps.calisanMeslegi) {
      errors.calisanMeslegi = errorMessage;
      error = true;
    }
    if (!examProps.raporGecerlilikTarihi) {
      errors.raporGecerlilikTarihi = errorMessage;
      error = true;
    }
    if (!examProps.raporTuru) {
      errors.raporTuru = errorMessage;
      error = true;
    }
    if (!examProps.sonucVeKanaat) {
      errors.sonucVeKanaat = errorMessage;
      error = true;
    }
    if (examProps.idrarTetkikiYapildiMi == null) {
      errors.idrarTetkikiYapildiMi = errorMessage;
      error = true;
    }
    if (examProps.isitmeTestiYapildiMi == null) {
      errors.isitmeTestiYapildiMi = errorMessage;
      error = true;
    }
    if (examProps.kanTetkikiYapildiMi == null) {
      errors.kanTetkikiYapildiMi = errorMessage;
      error = true;
    }
    if (examProps.rontgenYapildiMi == null) {
      errors.rontgenYapildiMi = errorMessage;
      error = true;
    }
    if (examProps.solunumFonkTestiYapildiMi == null) {
      errors.solunumFonkTestiYapildiMi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.biyolojikEtkenlerSinifi) &&
        examProps.biyolojikEtkenlerSinifi.length
      )
    ) {
      errors.biyolojikEtkenlerSinifi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.elektrikSinifi) &&
        examProps.elektrikSinifi.length
      )
    ) {
      errors.elektrikSinifi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.fizikiOrtamSinifi) &&
        examProps.fizikiOrtamSinifi.length
      )
    ) {
      errors.fizikiOrtamSinifi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.gurultuSinifi) && examProps.gurultuSinifi.length
      )
    ) {
      errors.gurultuSinifi = errorMessage;
      error = true;
    }
    if (!(Array.isArray(examProps.havaSinifi) && examProps.havaSinifi.length)) {
      errors.havaSinifi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.kimyasalMaddeSinifi) &&
        examProps.kimyasalMaddeSinifi.length
      )
    ) {
      errors.kimyasalMaddeSinifi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.tozYonetmeligiSinifi) &&
        examProps.tozYonetmeligiSinifi.length
      )
    ) {
      errors.tozYonetmeligiSinifi = errorMessage;
      error = true;
    }
    if (
      !(
        Array.isArray(examProps.isEkipmanlari) && examProps.isEkipmanlari.length
      )
    ) {
      errors.isEkipmanlari = errorMessage;
      error = true;
    }
    setErrors(errors);

    if (!error) {
      oldExamProps ? updateExam() : submitNewExam();
    }
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //DEPARTMENT
        id="combo-box"
        options={options.departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          examProps.department
            ? getFromId(options.departments, examProps.department)
            : null
        }
        onChange={async (event, value) => {
          setExamProps({ ...examProps, department: value?.id });
          let opts = { ...options };
          opts.users = await getUsers(value?.id);
          setOptions(opts);
          errors.department && setErrors({ ...errors, department: null });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.department}
            helperText={errors.department}
            label={language.auditTasks.department}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //PERSONNEL
        id="combo-box"
        options={options.users?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        disabled={!examProps.department}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          examProps.userId ? getFromId(options.users, examProps.userId) : null
        }
        onChange={async (event, value) => {
          setExamProps({ ...examProps, userId: value?.id });
          errors.userId && setErrors({ ...errors, userId: null });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={errors.userId}
            helperText={errors.userId}
            required
            label={language.tableDetails.personnel}
            className={classes.textField}
          />
        )}
      />
      <TextSelector
        label={language.auditTasks.inspectionType_}
        value={examProps.examinationType}
        error={errors.examinationType}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, examinationType: value.target.value });
          errors.examinationType &&
            setErrors({ ...errors, examinationType: null });
        }}
        style={classes.textField}
        options={jsonMap(false, examType)}
      />
      <DateTimePicker
        style={classes.dateField}
        error={errors.examinationTime}
        label={language.actions.inspectionDate_}
        value={
          examProps.examinationTime
            ? examProps.examinationTime.replaceAll("Z", "")
            : null
        }
        shrink={examProps.examinationTime}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            examinationTime: moment(value).toISOString(true),
          });
          errors.examinationTime &&
            setErrors({ ...errors, examinationTime: null });
        }}
      />
      <TextSelector
        label={language.ibysPages.aracKullanimi}
        required
        value={examProps.aracKullanimi}
        error={errors.aracKullanimi}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, aracKullanimi: value.target.value });
          errors.aracKullanimi && setErrors({ ...errors, aracKullanimi: null });
        }}
        style={classes.textField}
        options={jsonMap("aracKullanimi", options)}
      />
      <TextSelector
        label={language.ibysPages.calismaPozisyonu}
        required
        value={examProps.calismaPozisyonu}
        error={errors.calismaPozisyonu}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, calismaPozisyonu: value.target.value });
          errors.calismaPozisyonu &&
            setErrors({ ...errors, calismaPozisyonu: null });
        }}
        style={classes.textField}
        options={jsonMap("calismaPozisyonu", options)}
      />
      <TextSelector
        label={language.ibysPages.calismaZamani}
        required
        value={examProps.calismaZamani}
        error={errors.calismaZamani}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, calismaZamani: value.target.value });
          errors.calismaZamani && setErrors({ ...errors, calismaZamani: null });
        }}
        style={classes.textField}
        options={jsonMap("calismaZamani", options)}
      />
      <Autocomplete //çalışan mesleği
        id="combo-box"
        options={options.workerProfessions?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          examProps.calisanMeslegi
            ? getFromId(options.workerProfessions, examProps.calisanMeslegi)
            : null
        }
        onChange={(event, value) => {
          console.log(value);
          setExamProps({
            ...examProps,
            calisanMeslegi: value?.id,
          });
          errors.calisanMeslegi &&
            setErrors({ ...errors, calisanMeslegi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.calisanMeslegi}
            helperText={errors.calisanMeslegi}
            label={language.ibysPages.calisanMeslegi}
            className={classes.textField}
          />
        )}
      />
      {/* <TextArea
        label={language.ibysPages.calisanMeslegi}
        required
        value={examProps.calisanMeslegi}
        error={errors.calisanMeslegi}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, calisanMeslegi: value.target.value });
          errors.calisanMeslegi &&
            setErrors({ ...errors, calisanMeslegi: null });
        }}
        style={classes.textField}
      /> */}
      <DateTimePicker
        style={classes.dateField}
        error={errors.raporGecerlilikTarihi}
        label={language.ibysPages.raporGecerlilikTarihi}
        value={
          examProps.raporGecerlilikTarihi
            ? examProps.raporGecerlilikTarihi.replaceAll("Z", "")
            : null
        }
        shrink={examProps.raporGecerlilikTarihi}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            raporGecerlilikTarihi: moment(value).toISOString(true),
          });
          errors.raporGecerlilikTarihi &&
            setErrors({ ...errors, raporGecerlilikTarihi: null });
        }}
      />
      <TextSelector
        label={language.ibysPages.raporTuru}
        required
        value={examProps.raporTuru}
        error={errors.raporTuru}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, raporTuru: value.target.value });
          errors.raporTuru && setErrors({ ...errors, raporTuru: null });
        }}
        style={classes.textField}
        options={jsonMap("raporTuru", options)}
      />
      <TextSelector
        label={language.ibysPages.sonucVeKanaat}
        required
        value={examProps.sonucVeKanaat}
        error={errors.sonucVeKanaat}
        onChangeFunc={(value) => {
          setExamProps({ ...examProps, sonucVeKanaat: value.target.value });
          errors.sonucVeKanaat && setErrors({ ...errors, sonucVeKanaat: null });
        }}
        style={classes.textField}
        options={jsonMap("sonucVeKanaat", options)}
      />
      <TextSelector
        label={language.ibysPages.idrarTetkikiYapildiMi}
        required
        value={examProps.idrarTetkikiYapildiMi}
        error={errors.idrarTetkikiYapildiMi}
        shrink={examProps.idrarTetkikiYapildiMi != null}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            idrarTetkikiYapildiMi: value.target.value,
          });
          errors.idrarTetkikiYapildiMi &&
            setErrors({ ...errors, idrarTetkikiYapildiMi: null });
        }}
        style={classes.textField}
        options={jsonMap(false, trueFalse)}
      />
      <TextSelector
        label={language.ibysPages.rontgenYapildiMi}
        required
        value={examProps.rontgenYapildiMi}
        error={errors.rontgenYapildiMi}
        shrink={examProps.rontgenYapildiMi != null}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            rontgenYapildiMi: value.target.value,
          });
          errors.rontgenYapildiMi &&
            setErrors({ ...errors, rontgenYapildiMi: null });
        }}
        style={classes.textField}
        options={jsonMap(false, trueFalse)}
      />
      <TextSelector
        label={language.ibysPages.kanTetkikiYapildiMi}
        required
        value={examProps.kanTetkikiYapildiMi}
        error={errors.kanTetkikiYapildiMi}
        shrink={examProps.kanTetkikiYapildiMi != null}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            kanTetkikiYapildiMi: value.target.value,
          });
          errors.kanTetkikiYapildiMi &&
            setErrors({ ...errors, kanTetkikiYapildiMi: null });
        }}
        style={classes.textField}
        options={jsonMap(false, trueFalse)}
      />
      <TextSelector
        label={language.ibysPages.isitmeTestiYapildiMi}
        required
        value={examProps.isitmeTestiYapildiMi}
        error={errors.isitmeTestiYapildiMi}
        shrink={examProps.isitmeTestiYapildiMi != null}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            isitmeTestiYapildiMi: value.target.value,
          });
          errors.isitmeTestiYapildiMi &&
            setErrors({ ...errors, isitmeTestiYapildiMi: null });
        }}
        style={classes.textField}
        options={jsonMap(false, trueFalse)}
      />
      <TextSelector
        label={language.ibysPages.solunumFonkTestiYapildiMi}
        required
        value={examProps.solunumFonkTestiYapildiMi}
        error={errors.solunumFonkTestiYapildiMi}
        shrink={examProps.solunumFonkTestiYapildiMi != null}
        onChangeFunc={(value) => {
          setExamProps({
            ...examProps,
            solunumFonkTestiYapildiMi: value.target.value,
          });
          errors.solunumFonkTestiYapildiMi &&
            setErrors({ ...errors, solunumFonkTestiYapildiMi: null });
        }}
        style={classes.textField}
        options={jsonMap(false, trueFalse)}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.biyolojikEtkenlerSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.biyolojikEtkenlerSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            biyolojikEtkenlerSinifi: value,
          });
          errors.biyolojikEtkenlerSinifi &&
            setErrors({ ...errors, biyolojikEtkenlerSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.biyolojikEtkenlerSinifi}
            helperText={errors.biyolojikEtkenlerSinifi}
            label={language.ibysPages.biyolojikEtkenlerSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.elektrikSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.elektrikSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            elektrikSinifi: value,
          });
          errors.elektrikSinifi &&
            setErrors({ ...errors, elektrikSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.elektrikSinifi}
            helperText={errors.elektrikSinifi}
            label={language.ibysPages.elektrikSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.fizikiOrtamSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.fizikiOrtamSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            fizikiOrtamSinifi: value,
          });
          errors.fizikiOrtamSinifi &&
            setErrors({ ...errors, fizikiOrtamSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.fizikiOrtamSinifi}
            helperText={errors.fizikiOrtamSinifi}
            label={language.ibysPages.fizikiOrtamSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.gurultuSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.gurultuSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            gurultuSinifi: value,
          });
          errors.gurultuSinifi && setErrors({ ...errors, gurultuSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.gurultuSinifi}
            helperText={errors.gurultuSinifi}
            label={language.ibysPages.gurultuSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.havaSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.havaSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            havaSinifi: value,
          });
          errors.havaSinifi && setErrors({ ...errors, havaSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.havaSinifi}
            helperText={errors.havaSinifi}
            label={language.ibysPages.havaSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.kimyasalMaddeSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.kimyasalMaddeSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            kimyasalMaddeSinifi: value,
          });
          errors.kimyasalMaddeSinifi &&
            setErrors({ ...errors, kimyasalMaddeSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.kimyasalMaddeSinifi}
            helperText={errors.kimyasalMaddeSinifi}
            label={language.ibysPages.kimyasalMaddeSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.tozYonetmeligiSinifi?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.tozYonetmeligiSinifi}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            tozYonetmeligiSinifi: value,
          });
          errors.tozYonetmeligiSinifi &&
            setErrors({ ...errors, tozYonetmeligiSinifi: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.tozYonetmeligiSinifi}
            helperText={errors.tozYonetmeligiSinifi}
            label={language.ibysPages.tozYonetmeligiSinifi}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.isEkipmanlari?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={examProps?.isEkipmanlari}
        onChange={(event, value) => {
          setExamProps({
            ...examProps,
            isEkipmanlari: value,
          });
          errors.isEkipmanlari && setErrors({ ...errors, isEkipmanlari: null });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.isEkipmanlari}
            helperText={errors.isEkipmanlari}
            label={language.ibysPages.workEquipments}
            className={classes.textField}
          />
        )}
      />
      <CustomFileDropzone
        files={examinationFile ? [examinationFile] : []}
        setFiles={(value) => {
          if (value.length > 0) {
            setExaminationFile(value[0]);
          } else {
            setExaminationFile(null);
          }
        }}
        fileLimit={1}
        dropzoneText={language.tableDetails.dropZone5}
      />
      <CustomFileDropzone
        files={files}
        setFiles={(value) => {
          setFiles(value);
        }}
        fileLimit={200}
        dropzoneText={language.tableDetails.dropZone3}
      />
      <div className={classes.buttonContainer}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={onButtonClick}
            className={classes.submit}
          >
            {label}
          </Button>
        )}
      </div>
    </FormControl>
  );
}

/* <Autocomplete //COMPANY
  id="combo-box"
  options={options.companies?.sort(function(a, b) {
    return collator.compare(a.name, b.name)
  })}
  getOptionLabel={(option) => option.name}
  fullWidth={true}
  value={companyForDeptDropdown ? getFromId(options.companies, companyForDeptDropdown) : null}
  onChange={async (event, value) => {
    const temp = {...belongingDepartmentsLookup};
    const depts = await getDepartments(value?.id);
    setCompanyForDeptDropdown(value?.id);
    setDeptDropdown(depts);
    for(let index in depts) {
      temp[depts[index]?.id] = {name: depts[index]?.name, companyName: depts[index]?.companyName};
    }
    setBelongingDeparmentsLookup(temp);
  }} 
  renderInput={(params) => <TextField {...params} required label="Şirket" className={classes.textField}/>}
/> */
