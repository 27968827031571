import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl, TextField } from "@material-ui/core";
import { getDepartments } from './GetOptions';
import jsonMap from "../../components/Utils/jsonMap";
import { Autocomplete } from "@material-ui/lab";
import { TextArea, TextSelector } from '../../components/Fields/TextFields';
import { months } from '../../assets/constants';

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: 'pre-wrap'
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  buttonContainer: {
    textAlign: "center"
  }
}));

export default function NewTaskModal({
  oldHeadcounts,
  options,
  setOptions,
  headcounts,
  setHeadcounts,
  updateHeadcounts,
  submitNewHeadcounts,
  loading
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const [company, setCompany] = React.useState(headcounts?.companyId);

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //COMPANY
        options={options.companies?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={company ? getFromId(company, options.companies) : null}
        onChange={async (event, value) => {
          setCompany(value?.id);
          let newOpts = {...options};
          newOpts.departments = await getDepartments(value?.id, options.companies);
          setOptions(newOpts);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Şirket"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //DEPARTMENT
        options={options.departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        disabled={!company}
        fullWidth={true}
        value={headcounts.departmentId ? getFromId(headcounts.departmentId, options.departments) : null}
        onChange={async (event, value) => {
          setHeadcounts({ ...headcounts, departmentId: value?.id });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Bölüm"
            className={classes.textField}
          />
        )}
      />
      <TextArea
        label="Yıl"
        value={headcounts.year}
        shrink={headcounts.year !== null}
        style={classes.textField}
        onChangeFunc={(value) =>
          setHeadcounts({
            ...headcounts,
            year: value.target.value,
          })
        }
      />
      <TextSelector
        label="Ay"
        value={headcounts.month}
        shrink={headcounts.month !== null}
        style={classes.textField}
        options={jsonMap(false, months)}
        onChangeFunc={(value) =>
          setHeadcounts({
            ...headcounts,
            month: value.target.value,
          })
        }
      />
      <TextArea
        label="Mevcut Personel Sayısı"
        value={headcounts.existingPersonal}
        shrink={headcounts.existingPersonal !== null}
        style={classes.textField}
        onChangeFunc={(value) =>
          setHeadcounts({
            ...headcounts,
            existingPersonal: value.target.value,
          })
        }
      />
      <TextArea
        label="İşe Giren Personel Sayısı"
        value={headcounts.beginPersonal}
        shrink={headcounts.beginPersonal !== null}
        style={classes.textField}
        onChangeFunc={(value) =>
          setHeadcounts({
            ...headcounts,
            beginPersonal: value.target.value,
          })
        }
      />
      <TextArea
        label="İşten Çıkan Personel Sayısı"
        value={headcounts.quitPersonal}
        shrink={headcounts.quitPersonal !== null}
        style={classes.textField}
        onChangeFunc={(value) =>
          setHeadcounts({
            ...headcounts,
            quitPersonal: value.target.value,
          })
        }
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          id="task_submit_button"
          variant="contained"
          color="primary"
          onClick={() => oldHeadcounts ? updateHeadcounts() : submitNewHeadcounts()}
          className={classes.submit}
        >
          {oldHeadcounts ? "GÜNCELLE" : "OLUŞTUR"}
        </Button>}
      </div>
    </FormControl>
  );
}
