import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestVideoProps,
  videoProps,
  setRequestVideoProps,
  submitNewVideo,
  updateVideo,
  files,
  setFiles,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={language.visitorVideos.videoName}
        value={requestVideoProps.name}
        onChangeFunc={(value) =>
          setRequestVideoProps({
            ...requestVideoProps,
            name: value.target.value,
          })
        }
        style={classes.textField}
      />
      <CustomFileDropzone
        files={files}
        setFiles={(value) => {
          setFiles(value);
        }}
        fileLimit={1}
        dropzoneText={language.tableDetails.dropzone}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => (videoProps ? updateVideo() : submitNewVideo())}
            className={classes.submit}
          >
            {videoProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
