import Request from "../../helpers/Request";

export const getForms = async () => {
  const resp = await Request("get", "/api/form-templates/dropdown"); 
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let form = {};
    form["name"] = content[i].title;
    form["id"] = content[i].id;
    arr.push(form);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions() {
  const forms = await getForms();
  const options = {
    forms,
  };
  return options;
}
