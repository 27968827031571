import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px"
  },
  formControl: {
    width: 600
  },
  textField: {
    marginBottom: "20px"
  },
}));

/**
 * The form control component used in the user creation/editing modal.
 * @param {oldUserProps: obj, newUserProps: obj, setNewUserProps: func, options: obj[], updateUser: func, submitNewUser: func} props of the form control 
 */
export default function ModalForm({
  userToAdd,
  setUserToAdd,
  options,
  updateUser,
  oldUserProps,
  loading,
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  const getUserFromId = (id) => {
    for (let i = 0; i < options.users.length; i++) {
      if (options.users[i].id === id) {
        return options.users[i];
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //PERSONNEL
        id="combo-box"
        options={options.users?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={userToAdd ? getUserFromId(userToAdd) : null}
        onChange={async (event, value) => setUserToAdd(value?.id)}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Personel"
            className={classes.textField}
          />
        )}
      />
      <div style={{textAlign: "center"}}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={updateUser}
          className={classes.submit}
        >
          {"Komite Üyesi Yap"}
        </Button>}
      </div>
      
    </FormControl>
  );
}