import { makeStyles } from "@material-ui/core/styles";
import React, { useCallback } from "react";
import SessionHelper from "../../../../../helpers/SessionHelper";
import MaterialTable, { MTableToolbar } from "@material-table/core";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import LanguageHelper from "../../../../../helpers/LanguageHelper";
import { forwardRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import KKDModal from "./KKDModal";
import Request from "../../../../../helpers/Request";
import showSnackbar from "../../../../../components/Utils/showSnackbar";
import moment from "moment";
import AltTable from "../../../../../components/Table/AltTable";
import { getKKDTasks } from "../../../components/GetOptions";
import IconTooltipButton from "../../../../../components/Buttons/IconTooltipButton";
import { FormControl, TextField, Button, Divider } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import FileSaver from "file-saver";
import CustomFileDropzone from "../../../../../components/Files/CustomFileDropzone";
import DeleteModal from "./DeleteModal";
import { getKKDKinds } from "../../../../kkdPages/kkdDebt/GetOptions";
import CustomSnackbar from "../../../../../components/Snackbar/Snackbar";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: window.innerHeight > 900 ? "70vh" : "60vh",
    margin: "0 auto",
    marginTop: 20,
    marginBottom: 20,
  },
  missing: {
    width: "50%",
    overflow: "auto",
    maxHeight: window.innerHeight > 900 ? "70vh" : "60vh",
    margin: "0 auto",
    marginTop: 20,
    marginBottom: 20,
  },
  divider: {
    marginTop: 20,
    marginBottom: 10,
  },
  participants: {
    width: 250,
    height: 100,
    overflow: "auto",
  },
  dropzone: {
    width: "350px",
  },
  formControl: {
    width: "50%",
    margin: "0 auto",
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Transfer: forwardRef((props, ref) => <SyncAltIcon {...props} ref={ref} />),
};

export default function KKDTable({
  options,
  userProps,
  setUserProps,
  setChangeMade,
  errors,
  setErrors,
}) {
  const lightTheme = createTheme({ palette: { type: "light" } });
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const id = user?.company?.id;
  const columns = [
    { field: "id", title: "No" },
    { field: "kkdName", title: "KKD" },
    { field: "kkdSerialNo", title: "KKD Seri No" },
    { field: "givenDate", title: "Veriliş Tarihi" },
    { field: "productionDate", title: "Üretim Tarihi" },
    { field: "returnDate", title: "İade Tarihi" },
    { field: "returnReason", title: "İade Nedeni" },
  ];

  const [files, setFiles] = React.useState([]);
  const [dataFiles, setDataFiles] = React.useState([]);
  const [kkdKinds, setKKDKinds] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [data, setData] = React.useState({
    id: null,
    kkdTask: null,
    debitList: [],
    missingKKDs: [],
    unnecessaryKKDs: [],
  });
  const [debts, setDebts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [kkdModal, setKKDModal] = React.useState(null);
  const [deleteModal, setDeleteModal] = React.useState(null);
  const [taskOptions, setTaskOptions] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");

  const getKKD = useCallback(async () => {
    const resp = await Request(
      "get",
      "/api/kkd-users/details/" + userProps?.id
    );
    console.log(resp);
    // if (resp.status !== 200) {
    //   setSeverity("error");
    //   setSnackbarMessage(
    //     language.etc.fetchingError + resp?.data?.error?.message
    //   );
    //   setSnackbar(true);
    // }
    return resp.data;
  }, [language, setSeverity, setSnackbarMessage, setSnackbar]);

  const init = useCallback(async () => {
    let [data, opts, kkdKinds] = await Promise.all([
      getKKD(),
      getKKDTasks(),
      getKKDKinds(userProps?.id),
    ]);
    Array.isArray(opts) && setTaskOptions(opts);
    setData(data);
    setKKDKinds(kkdKinds);
    if (!data.message) {
      setDebts(formatDebts(data));
      if (data.debitForms) {
        let debtFiles = [];
        for (let index = 0; index < data.debitForms.length; index++) {
          if (data.debitForms[index]) {
            let fileKey = data.debitForms[index].fileKey
              ? data.debitForms[index].fileKey.replace(
                  "https://isgapi.gdzelektrik.com.tr:8443/open-file/",
                  ""
                )
              : data.debitForms[index].file
              ? data.debitForms[index].file.fileKey.replace(
                  "https://isgapi.gdzelektrik.com.tr:8443/open-file/",
                  ""
                )
              : "undefined";
            debtFiles.push(
              new File([fileKey], fileKey, { type: "application/pdf" })
            );
          }
        }
        // discard null files from debitforms
        let nullFiles = data.debitForms.filter(function (el) {
          return el !== null;
        });
        // dataFiles is necessary for comparison when adding or removing file
        setDataFiles(nullFiles);
        setFiles(debtFiles);
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, update]);
  React.useEffect(() => {
    init();
  }, [init]);

  const formatDebts = (data) => {
    let debts = [];
    const unnecessary = data.unnecessaryKKDDebits || [];
    const debitList = data.debitList || [];
    for (let i = 0; i < debitList.length; i++) {
      debts[i] = {
        id: debitList[i]?.id || "Yok",
        kkdId: debitList[i]?.kkd?.id || "Yok",
        kkdName: debitList[i]?.kkd?.kkdName || "Yok",
        kkdSerialNo: debitList[i]?.kkdSerialNo || "Yok",
        givenDate: debitList[i]?.givenDate
          ? moment(debitList[i]?.givenDate).format("DD-MM-YYYY")
          : "Yok",
        productionDate: debitList[i]?.productionDate
          ? moment(debitList[i]?.productionDate).format("DD-MM-YYYY")
          : "Yok",
        returnDate: debitList[i]?.returnDate
          ? moment(debitList[i]?.returnDate).format("DD-MM-YYYY")
          : "İade Edilmedi",
        returnReason: debitList[i]?.returnReason || "İade Edilmedi",
      };
      if (unnecessary && Array.isArray(unnecessary)) {
        for (let kkd of unnecessary) {
          if (kkd?.kkd?.id === debitList[i]?.kkd?.id) {
            debts[i].unnecessary = true;
          }
        }
      }
    }
    return debts;
  };

  const handleOpenModal = async (data) => {
    setKKDModal(data);
  };

  const handleOpenDeleteModal = async (data) => {
    setDeleteModal(data?.id);
  };

  const updateTable = () => {
    setUpdate(!update);
  };

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  const openGuide = async () => {
    const resp = await Request(
      "get",
      "/api/report/" + userProps?.id + "/base64/debit-form"
    );
    console.log(resp);
    if (resp.status !== 200) {
      if (resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      const source = "data:application/xlsx;base64, " + encodeURI(resp.data);
      FileSaver.saveAs(source, "Zimmet-Formu.xlsx");
    }
    return;
  };

  const RenderMissingKKDs = () => {
    let titles = ["KKD Tipi", ""];
    let rows = [];
    const arr = data.missingKKDs || [];
    for (let i = 0; i < arr.length; i++) {
      let cells = [];
      cells.push(arr[i].name || "Yok");
      cells.push(
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            handleOpenModal({
              kkdKind: arr[i],
              kkdId: null,
              kkdSerialNo: null,
              givenDate: null,
              productionDate: null,
            })
          }
        >
          ZİMMET EKLE
        </Button>
      );
      rows.push(cells);
    }
    return (
      <div className={classes.missing}>
        {rows.length > 0 ? (
          <AltTable titles={titles} rows={rows} />
        ) : (
          <p style={{ color: "red", fontSize: 18 }}>Veri Yok</p>
        )}
      </div>
    );
  };

  const RenderTaskField = useCallback(() => {
    const collator = new Intl.Collator("tr");
    return (
      <FormControl className={classes.formControl}>
        <Autocomplete
          id="combo-box"
          options={taskOptions?.sort(function (a, b) {
            return collator.compare(a.taskName, b.taskName);
          })}
          getOptionLabel={(option) => option.taskName}
          fullWidth={true}
          value={
            (userProps.kkdTaskId &&
              getFromId(taskOptions, userProps.kkdTaskId)) ||
            data.kkdTask ||
            null
          }
          onChange={(event, value) => {
            setUserProps({ ...userProps, kkdTaskId: value?.id });
            setChangeMade && setChangeMade(true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="KKD Görevi"
              className={classes.textField}
            />
          )}
        />
      </FormControl>
    );
  }, [classes, data, setChangeMade, userProps, setUserProps, taskOptions]);

  const RenderDropzone = () => {
    return (
      <div className={classes.missing}>
        <CustomFileDropzone
          files={files}
          setFiles={async (value) => {
            setFiles(value);
            await updateFiles(value);
          }}
          fileLimit={100}
          dropzoneText={
            "Zimmet formunu yüklemek için sürükleyin ya da buraya tıklayın."
          }
        />
      </div>
    );
  };

  const updateFiles = async (loadedFiles) => {
    // if new file is added
    if (loadedFiles.length > dataFiles.length) {
      let formData = new FormData();
      // take last file (added file)
      const file = loadedFiles.slice(-1)[0];
      formData.append("file", file);
      formData.append("user-id", userProps?.id);
      const resp = await Request("post", "/api/kkd-users/file", formData);
      console.log(resp);
      showSnackbar(
        resp,
        setSeverity,
        setSnackbarMessage,
        setSnackbar,
        language.etc.updated,
        null
      );
    } else {
      // or removed a file
      for (const file of dataFiles) {
        // compare with backend data (dataFiles)
        // if file from backend is not in the changed array (loadedFiles)
        var result = loadedFiles.find((obj) => {
          return obj.name === file.fileKey;
        });
        // if there is a file that is not in the loadedFiles
        // take id and send to backend for removing
        if (!result) {
          let params = {
            userId: userProps?.id,
            ids: file.id,
          };
          const resp = await Request(
            "patch",
            "/api/kkd-users/file",
            null,
            params
          );
          console.log(resp);
          showSnackbar(
            resp,
            setSeverity,
            setSnackbarMessage,
            setSnackbar,
            language.etc.updated,
            null
          );
        }
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <CustomSnackbar
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
          severity={severity}
        />
      </ThemeProvider>
      <DeleteModal
        options={options}
        modal={deleteModal}
        setModal={setDeleteModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        update={updateTable}
      />
      <KKDModal
        id={userProps?.id}
        kkdProps={kkdModal}
        kkdKinds={kkdKinds}
        setKKDModal={setKKDModal}
        setSeverity={setSeverity}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        update={updateTable}
      />
      <RenderTaskField />
      {data.kkdTask && (
        <div className={classes.container}>
          <MaterialTable
            title="Zimmetler"
            localization={language.tableLocalization}
            icons={tableIcons}
            columns={columns}
            isLoading={loading}
            data={debts}
            options={{
              minBodyHeight: window.innerHeight > 900 ? "40vh" : "35vh",
              maxBodyHeight: window.innerHeight > 900 ? "40vh" : "35vh",
              emptyRowsWhenPaging: false,
              rowStyle: (rowData) =>
                rowData.unnecessary && { backgroundColor: "#804f4f" },
            }}
            //handleOpenDeleteModal
            actions={[
              {
                icon: tableIcons.Add,
                tooltip: language.tableLocalization.body.addTooltip,
                isFreeAction: true,
                onClick: (event, rowData) => handleOpenModal(rowData),
              },
              (rowData) =>
                handleOpenDeleteModal && {
                  icon: tableIcons.Delete,
                  tooltip:
                    rowData.returnDate && rowData.returnDate !== "İade Edilmedi"
                      ? "İade Edilmiş"
                      : "İade Et",
                  disabled:
                    rowData.returnDate &&
                    rowData.returnDate !== "İade Edilmedi",
                  onClick: (event, rowData) => handleOpenDeleteModal(rowData),
                },
            ]}
            components={{
              Toolbar: (props) => (
                <>
                  <MTableToolbar {...props} />
                  <div style={{ marginLeft: 20, textAlign: "left" }}>
                    <IconTooltipButton
                      title="ZİMMET FORMU ŞABLONU"
                      label="ZİMMET FORMU ŞABLONU"
                      onClick={openGuide}
                    >
                      <MenuBookIcon />
                    </IconTooltipButton>
                  </div>
                </>
              ),
            }}
          />
        </div>
      )}
      {!data.message && (
        <div>
          <Divider className={classes.divider} />
          <h3>Eksik Donanımlar</h3>
          <RenderMissingKKDs />
          <Divider className={classes.divider} />
          <h3>Zimmet Formu</h3>
          <RenderDropzone />
        </div>
      )}
    </>
  );
}
