import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, FormControl, TextField } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import InjuredPartsList from "./InjuredPartsList";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import jsonMap from "../../../components/Utils/jsonMap";
import {
  CustomDatePicker,
  TimePicker,
} from "../../../components/Selectors/DatePickers";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function AccidentPersonnelForm({
  oldPersonnelProps,
  options,
  accidents,
  setAccidents,
  handleCloseModal,
  getPersonnelFromId,
  index,
}) {
  // const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [personnelProps, setPersonnelProps] = React.useState(
    (oldPersonnelProps && { ...oldPersonnelProps }) || {}
  );
  const [errors, setErrors] = React.useState({
    fallDistanceError: null,
    lostTimeError: null,
    noteError: null,
    summaryError: null,
    incapacitationEndDate: null,
    incapacitationStartDate: null,
  });

  const parseTime = (time) => {
    if (time) {
      let d = new Date();
      let [hours, minutes, seconds] = time.split(":");
      d.setHours(+hours);
      d.setMinutes(minutes);
      d.setSeconds(seconds);
      return d;
    }
  };

  const onButtonClick = () => {
    // let error = false;
    // let errorMessage = "Bu alan boş bırakılamaz.";
    // let errors = {
    //   incapacitationEndDate: null,
    //   incapacitationStartDate: null,
    // };
    // if (!personnelProps.incapacitationEndDate) {
    //   errors.incapacitationEndDate = errorMessage;
    //   error = true;
    // }
    // if (!personnelProps.incapacitationStartDate) {
    //   errors.incapacitationStartDate = errorMessage;
    //   error = true;
    // }
    // setErrors(errors);

    // if (!error) {
    if (oldPersonnelProps) {
      let temp = [...accidents];
      temp[index] = personnelProps;
      setAccidents(temp);
    } else {
      let temp = [...accidents];
      temp.push(personnelProps);
      setAccidents(temp);
    }
    handleCloseModal();
    // }
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <Autocomplete //PERSONNEL ID
        id="combo-box"
        options={options.users}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        defaultValue={
          oldPersonnelProps
            ? getPersonnelFromId(oldPersonnelProps.personnelId)
            : null
        }
        onChange={(event, value) =>
          setPersonnelProps({ ...personnelProps, personnelId: value?.id })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="Personel"
            className={classes.textField}
          />
        )}
      />
      <TextSelector //LOST LIMB
        required
        label={"Kayıp Uzuv"}
        shrink={personnelProps.lostLimbId !== (null || undefined)}
        value={personnelProps.lostLimbId}
        onChangeFunc={(value) =>
          setPersonnelProps({
            ...personnelProps,
            lostLimbId: parseInt(value.target.value),
          })
        }
        style={classes.textField}
        options={jsonMap("lostLimb", options)}
      />
      <InjuredPartsList //INJURED BODY PARTS
        personnelProps={personnelProps}
        setPersonnelProps={setPersonnelProps}
        options={options}
        jsonMap={jsonMap}
      />
      <TextArea //FALL DISTANCE
        label={"Düştüğü Mesafe (Metre)"}
        shrink={
          personnelProps.fallDistanceOnFallAccidentsInMeters !==
          (null || undefined)
        }
        value={personnelProps.fallDistanceOnFallAccidentsInMeters}
        onChangeFunc={(value) => {
          setPersonnelProps({
            ...personnelProps,
            fallDistanceOnFallAccidentsInMeters: value.target.value,
          });
          if (isNaN(value.target.value)) {
            setErrors({ ...errors, fallDistanceError: "Lütfen sayı giriniz." });
          } else {
            if (parseInt(value.target.value) < 0) {
              setErrors({
                ...errors,
                fallDistanceError: "Düştüğü mesafe 0'dan az olamaz.",
              });
            } else if (parseInt(value.target.value) > 400) {
              setErrors({
                ...errors,
                fallDistanceError: "Düştüğü mesafe 400'den fazla olamaz.",
              });
            } else {
              if (value.target.value) {
                errors.fallDistanceError &&
                  setErrors({ ...errors, fallDistanceError: null });
                setPersonnelProps({
                  ...personnelProps,
                  fallDistanceOnFallAccidentsInMeters: parseInt(
                    value.target.value
                  ),
                });
              }
            }
          }
        }}
        style={classes.textField}
      />
      <CustomDatePicker //INCAPACITATION START
        style={classes.dateField}
        label={"Rapor Başlangıç Tarihi"}
        value={
          personnelProps.incapacitationStartDate
            ? personnelProps.incapacitationStartDate
            : null
        }
        error={errors.incapacitationStartDate}
        required
        shrink={personnelProps.incapacitationStartDate !== (null || undefined)}
        onChangeFunc={(value) => {
          setPersonnelProps({
            ...personnelProps,
            incapacitationStartDate: new moment(value).toISOString(true),
          });
          errors.incapacitationStartDate &&
            setErrors({ ...errors, incapacitationStartDate: null });
        }}
      />
      <CustomDatePicker //INCAPACITATION END
        style={classes.dateField}
        label={"Rapor Bitiş Tarihi"}
        value={
          personnelProps.incapacitationEndDate
            ? personnelProps.incapacitationEndDate
            : null
        }
        error={errors.incapacitationEndDate}
        required
        shrink={personnelProps.incapacitationEndDate !== (null || undefined)}
        onChangeFunc={(value) => {
          setPersonnelProps({
            ...personnelProps,
            incapacitationEndDate: new moment(value).toISOString(true),
          });
          errors.incapacitationEndDate &&
            setErrors({ ...errors, incapacitationEndDate: null });
        }}
      />
      {/* <TextSelector //OUTCOME
        required
        label={"Kaza Sonucu"}
        shrink={personnelProps.accidentOutcomeId !== (null || undefined)}
        value={personnelProps.accidentOutcomeId}
        onChangeFunc={(value) =>
          setPersonnelProps({
            ...personnelProps,
            accidentOutcomeId: parseInt(value.target.value),
          })
        }
        style={classes.textField}
        options={jsonMap("accidentOutcomes", options)}
      /> */}
      <TextSelector //INJURY TYPE
        required
        label={"Yaralanma Tipi"}
        shrink={personnelProps.injuryTypeId !== (null || undefined)}
        value={personnelProps.injuryTypeId}
        onChangeFunc={(value) =>
          setPersonnelProps({
            ...personnelProps,
            injuryTypeId: parseInt(value.target.value),
          })
        }
        style={classes.textField}
        options={jsonMap("injuryTypes", options)}
      />
      <TextSelector //SECTIONS
        required
        label={"Seksiyonlar"}
        shrink={personnelProps.sectionId !== (null || undefined)}
        value={personnelProps.sectionId}
        onChangeFunc={(value) =>
          setPersonnelProps({
            ...personnelProps,
            sectionId: parseInt(value.target.value),
          })
        }
        style={classes.textField}
        options={jsonMap("sections", options)}
      />
      {/* <TextArea //LOST TIME
        required
        label={language.tableColumns.lostTime}
        shrink={personnelProps.lostTime !== (null || undefined)}
        value={personnelProps.lostTime}
        onChangeFunc={(value) => {
          setPersonnelProps({
            ...personnelProps,
            lostTime: value.target.value,
          });       
          if(isNaN(value.target.value)) {
            setErrors({...errors, lostTimeError: "Lütfen sayı giriniz."});
          } else {
            if(parseInt(value.target.value) < 0) {
              setErrors({...errors, lostTimeError: "Kayıp saat 0'dan az olamaz."});
            } else if(parseInt(value.target.value) > 500) {
              setErrors({...errors, lostTimeError: "Kayıp saat 500'den fazla olamaz."});
            } else {
              if(value.target.value) {
                errors.lostTimeError && setErrors({...errors, lostTimeError: "Kayıp saat 500'den fazla olamaz."});
                setPersonnelProps({
                  ...personnelProps,
                  lostTime: value.target.value,
                });
              }
            }
          }
        }}
        style={classes.textField}
      /> */}
      <CustomDatePicker //SGK NOTIFICATION DATE
        style={classes.dateField}
        label={"SGK Bildirim Tarihi"}
        value={
          personnelProps.sgkNotificationDate
            ? personnelProps.sgkNotificationDate
            : null
        }
        shrink={personnelProps.sgkNotificationDate !== (null || undefined)}
        onChangeFunc={(value) =>
          setPersonnelProps({
            ...personnelProps,
            sgkNotificationDate: new moment(value).toISOString(true),
          })
        }
      />
      {/* <TextArea //NOTE
        required
        label={"Açıklama"}
        shrink={personnelProps.note !== (null || undefined)}
        value={personnelProps.note}
        onChangeFunc={(value) => {
          if(value.target.value.length > 5000) {
            setErrors({...errors, noteError: "Açıklama 5000 karakterden uzun olamaz."});
          } else {
            errors.noteError && setErrors({...errors, noteError: null});
            setPersonnelProps({ ...personnelProps, note: value.target.value });
          }
        }}
        style={classes.textField}
      />
      <TextArea //SUMMARY
        required
        label={"Özet"}
        shrink={personnelProps.summary !== (null || undefined)}
        value={personnelProps.summary}
        onChangeFunc={(value) => {
          if(value.target.value.length > 5000) {
            setErrors({...errors, summaryError: "Özet 5000 karakterden uzun olamaz."});
          } else {
            errors.summaryError && setErrors({...errors, summaryError: "Özet 5000 karakterden uzun olamaz."});
            setPersonnelProps({
              ...personnelProps,
              summary: value.target.value,
            });
          }
        }}
        style={classes.textField}
      />       */}
      <TimePicker //WORK START TIME ACC DAY
        style={classes.dateField}
        label={"Kaza Günü İşe Başlama Saati"}
        shrink={
          personnelProps.workStartTimeAtAccidentDay !== (null || undefined)
        }
        value={
          personnelProps.workStartTimeAtAccidentDay
            ? parseTime(personnelProps.workStartTimeAtAccidentDay)
            : null
        }
        keyboardButtonProps={{ "aria-label": "change time" }}
        onChangeFunc={(date, value) => {
          setPersonnelProps({
            ...personnelProps,
            workStartTimeAtAccidentDay: value.substr(0, 5) + ":00",
          });
        }}
      />
      <div style={{ padding: 20 }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => onButtonClick()}
          className={classes.submit}
        >
          {oldPersonnelProps ? "Kazazedeyi Güncelle" : "Kazazede Ekle"}
        </Button>
      </div>
    </FormControl>
  );
}
