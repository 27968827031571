import {
  visitStatus,
  visitStatusForHost,
  visitStatusForSecurity,
} from "../../../assets/constants";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import SessionHelper from "../../../helpers/SessionHelper";

const language = LanguageHelper.getLanguage();

export const getDepartments = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/departments/belongs-to-company/" + companyId + "/basic-info"
  );
  const data = resp.data;
  // console.log(data);
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

export const getUsers = async (companyId) => {
  const resp = await Request(
    "get",
    "/api/users/belongs-to-company/" + companyId,
    null,
    { "self-included": false }
  );
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getVisitTypes = async () => {
  const resp = await Request("get", "/api/vm/drop-down/visit-types");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let type = {};
    type["name"] = data[i].name;
    type["id"] = data[i].id;
    arr.push(type);
  }
  return arr;
};

export const getEquipments = async (id) => {
  let idResp = [];
  if (id) {
    const resp = await Request("get", "/api/vm/drop-down/equipments/", null, {
      "visit-type-id": id,
    });
    idResp = resp.data;
  }
  const resp = await Request("get", "/api/vm/drop-down/equipments/");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let eq = {};
    if (idResp.find((x) => x.id === content[i].id)) {
      eq["name"] = content[i].name + language.visits.recommended;
    } else {
      eq["name"] = content[i].name;
    }
    eq["id"] = content[i].id;
    arr.push(eq);
  }
  return arr;
};

export const getDocuments = async (id) => {
  let idResp = [];
  if (id) {
    const resp = await Request("get", "/api/vm/drop-down/documents/", null, {
      "visit-type-id": id,
    });
    idResp = resp.data;
  }
  const resp = await Request("get", "/api/vm/drop-down/documents/");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let doc = {};
    if (idResp.find((x) => x.id === content[i].id)) {
      doc["name"] = content[i].name + language.visits.recommended;
    } else {
      doc["name"] = content[i].name;
    }
    doc["id"] = content[i].id;
    arr.push(doc);
  }
  return arr;
};

export const getVideos = async (id) => {
  let idResp = [];
  if (id) {
    const resp = await Request("get", "/api/vm/drop-down/videos/", null, {
      "visit-type-id": id,
    });
    idResp = resp.data;
  }
  const resp = await Request("get", "/api/vm/drop-down/videos/");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let video = {};
    if (idResp.find((x) => x.id === content[i].id)) {
      video["name"] = content[i].name + language.visits.recommended;
    } else {
      video["name"] = content[i].name;
    }
    video["id"] = content[i].id;
    arr.push(video);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/vm/drop-down/companies");
  const content = resp.data;
  let arr = [];
  for (let i = 0; i < content.length; i++) {
    let comp = {};
    comp["name"] = content[i].name;
    comp["id"] = content[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getVisitors = async (id) => {
  const resp = await Request("get", "/api/vm/drop-down/visitors", null, {
    visitorCompanyId: id,
  });
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let visitor = {};
    visitor["name"] = data[i].name + " " + data[i].surname;
    visitor["id"] = data[i].id;
    arr.push(visitor);
  }
  return arr;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export async function GetOptions(id) {
  const user = SessionHelper.getUser();
  const [
    departments,
    users,
    visitTypes,
    equipments,
    documents,
    videos,
    companies,
    visitors,
  ] = await Promise.all([
    getDepartments(user?.companyId),
    getUsers(user?.companyId),
    getVisitTypes(),
    getEquipments(id),
    getDocuments(id),
    getVideos(id),
    getCompanies(),
    getVisitors(id),
  ]);

  const status = { ...visitStatus };
  const statusForSecurity = { ...visitStatusForSecurity };
  const statusForHost = { ...visitStatusForHost };

  const options = {
    departments,
    users,
    visitTypes,
    equipments,
    documents,
    videos,
    companies,
    visitors,
    status,
    statusForSecurity,
    statusForHost,
  };
  return options;
}
