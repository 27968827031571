import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      companyFilter: filterProps.company?.id,
      cityFilter: filterProps.city,
      idFilter: filterProps.id,
      managerFilter: filterProps.manager?.id,
      nameFilter: filterProps.name,
      nonconfomityUserFilter: filterProps.unsuitUser?.id,
      SGKNoFilter: filterProps.sgk,
      townFilter: filterProps.town?.id
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "name") { params.sort = "name," + query.orderDirection; }
      else if (query.orderBy.field === "sgkno") { params.sort = "SGKNo," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "city") { params.sort = "city.name," + query.orderDirection; }
      else if (query.orderBy.field === "town") { params.sort = "town.name," + query.orderDirection; }
      else if (query.orderBy.field === "managerName") { params.sort = "manager.fName,manager.lName," + query.orderDirection; }
      else if (query.orderBy.field === "fieldOfActivity") { params.sort = "eDepartmentType," + query.orderDirection; }
      else if (query.orderBy.field === "fieldsOfActivity") { params.sort = "fieldsOfActivity," + query.orderDirection; }
      else if (query.orderBy.field === "nonconformityUserName") { params.sort = "nonconformityUser.fName,nonconformityUser.lName," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/departments", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          active: data[i].active ? data[i].active : null,
          fieldOfActivity: data[i].fieldOfActivity ? data[i].fieldOfActivity : null,
          fieldsOfActivity: data[i].fieldsOfActivity ? data[i].fieldsOfActivity : null,
          belongingDepartments: data[i].parentDepartments ? data[i].parentDepartments : null,
          name: data[i].name ? data[i].name : null,
          id: data[i].id ? data[i].id : null,
          sgkno: data[i].sgkno ? data[i].sgkno : null,
          company: data[i].company ? data[i].company?.id : null,
          companyName: data[i].company ? data[i].company?.name : null,
          companyShortName: data[i].company ? data[i].company?.nameShort : null,
          city: data[i].city ? data[i].city?.name : null,
          cityId: data[i].city ? data[i].city?.id : null,
          town: data[i].town ? data[i].town?.name : null,
          townId: data[i].town ? data[i].town?.id : null,
          riskClass: data[i].riskClass ? data[i].riskClass : null,
          nonConformityUsers: data[i].nonConformityUsers ? data[i].nonConformityUsers : null,
          unsuitUsersAndTowns: (data[i].nonConformityUsers && data[i].fieldsOfActivity) ? 
            mergeUnsuitUsersAndTowns(data[i].nonConformityUsers, data[i].fieldsOfActivity) : null,
          isgUsers: data[i].isgUsers ? data[i].isgUsers : null,
          healthUsers: data[i].healthUsers ? data[i].healthUsers : null,
          manager: data[i].manager ? data[i].manager?.id : null,
          managerName: data[i].manager ? `${data[i].manager?.fName} ${data[i].manager?.lName}` : null,
          managerData: data[i].manager ? data[i].manager : null 
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}

const mergeUnsuitUsersAndTowns = (unsuitUsers, towns) => {
  let mergedArray = [];
  for(let i in towns) {
    let user = null;
    for(let j in unsuitUsers) {
      if(towns[i].id === unsuitUsers[j].town?.id) {
        user = unsuitUsers[j].user;
      }
    }
    const str = towns[i].name + (user ? ` (${user.fName + " " + user.lName})` : "");
    mergedArray.push(str);
  }
  return mergedArray;
}