import React, { useCallback } from "react";
import moment from "moment";
import ModalForm from "./ModalForm";
import TableEntryModal from "../../../components/Modal/TableEntryModal";

export default function EmergencyPlanModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldReportProps,
  setOldReportProps,
  options,
  setOptions,
  modalLoading,
}) {
  const defaultProps = {
    regionId: null,
    departmentId: null,
    userId: null,
    startDate: null,
    endDate: null,
  };

  const [reportProps, setReportProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldReportProps) {
      setReportProps({
        id: oldReportProps.id || null,
        regionId: oldReportProps.regionId || null,
        departmentId: oldReportProps.departmentId || null,
        userId: oldReportProps.userId || null,
        companyName: oldReportProps.companyName || null,
        startDate: moment(oldReportProps.startDate).toISOString(false) || null,
        endDate: moment(oldReportProps.endDate).toISOString(false) || null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldReportProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setReportProps(defaultProps);
      setOldReportProps(null);
    }, 300);
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <ModalForm
          reportProps={reportProps}
          setReportProps={setReportProps}
          label={oldReportProps ? "GÜNCELLE" : "OLUŞTUR"}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
          handleCloseModal={handleCloseModal}
          oldReportProps={oldReportProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </>
  );
}
