import React from "react";
import YasamUserTable from './components/YasamUserTable';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { tableContainerStyles } from '../../assets/styles/tableContainer';

export default function TaskPage() {
  return (
    <Container maxWidth={false} style={tableContainerStyles}>
      <Grid>
        <YasamUserTable />
      </Grid>
    </Container>
  );
}
