import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Table from "../../components/Table/Table";
import TableBackdrop from "../../components/Table/TableBackdrop";
import GetFilterFields from "./LogsFilters";

export default function LogsTable() {
  const tableRef = React.useRef();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    time: null,
    date: null,
    level: null
  });

  const init = useCallback(async () => {
    setColumns([
      { title: "Tarih", field: "date" },
      { title: "Level", field: "level" },
      { title: "Mesaj", field: "message" }
    ]);
    setBackdropLoading(false);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          tableRef={tableRef}
          filterProps={filterProps} 
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}  
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      time: null,
      date: null,
      level: null
    });
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <RenderFilters />
      <Table
        noPaging
        noRowActions
        tableName={"Kayıtlar"}
        authName="logs"
        columns={columns}
        tableRef={tableRef}
        filters={filterProps}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        /* headerComponents={
          <FormControlLabel
            control={<Checkbox checked={futureTasks} onChange={() => {
              setFutureTasks(!futureTasks);
              tableRef.current && tableRef.current.onQueryChange();
            }} name="futureTasks" />}
            label="İleri Tarihli Görevleri Göster"
          />
        } */
      />
    </>
  );
}