import React, { useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { Bar, LabelList } from "recharts";
import PieChart from "../../../components/Chart/PieChart";
import BarChart from "../../../components/Chart/BarChart";
import SessionHelper from "../../../helpers/SessionHelper";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import {
  getPersonnelCountByIsgTraining,
  getCompletedTrainingHourByTrainingType,
  getCompletedTrainingHourByMonth,
  getCompletedTrainingManHourByTrainingType,
  getCompletedTrainingManHourByMonth,
  getCompletedTrainingPieceByTrainingType,
} from "./GetOptions";
import { Footer } from "./Settings";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "red"];
const fixedHeight = 650;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  section: {
    height: window.innerHeight > 900 ? "75vh" : "70vh",
  },
}));

const renderCustomizedLabel = (props) => {
  const { x, y, width, height, value, offset } = props;

  const xOffset = width / 2 + offset - 1;
  const yOffset = height / 2 + 5;

  return (
    <text x={x + xOffset} y={y + yOffset} fill={"black"} textAnchor="end">
      {value === 0 ? "" : value}
    </text>
  );
};

export function CompletedTrainingHourByMonthBarChart({
  title,
  data,
  Footer,
  filterProps,
}) {
  return (
    <BarChart
      dataSet={data}
      title={title}
      FooterComponent={() => Footer("bar", filterProps)}
    >
      <Bar
        isAnimationActive={false}
        dataKey="Aylık Tamamlanmış Eğitim Süresi"
        fill={COLORS[0]}
      >
        <LabelList
          dataKey="Aylık Tamamlanmış Eğitim Süresi"
          position="center"
          content={renderCustomizedLabel}
          style={{ fill: "white" }}
        />
      </Bar>
    </BarChart>
  );
}

export function CompletedTrainingManHourByMonthBarChart({
  title,
  data,
  Footer,
  filterProps,
}) {
  return (
    <BarChart
      dataSet={data}
      title={title}
      FooterComponent={() => Footer("bar", filterProps)}
    >
      <Bar
        isAnimationActive={false}
        dataKey="Tamamlanmış Eğitim Süresi"
        fill={COLORS[0]}
      >
        <LabelList
          dataKey="Tamamlanmış Eğitim Süresi"
          position="center"
          content={renderCustomizedLabel}
          style={{ fill: "white" }}
        />
      </Bar>
    </BarChart>
  );
}

export default function TrainingCharts({ setBackdropLoading, filterProps }) {
  const user = SessionHelper.getUser();
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "dashboard");
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const [personnelCountByIsgTraining, setPersonnelCountByIsgTraining] =
    React.useState(null);
  const [
    completedTrainingHourByTrainingType,
    setCompletedTrainingHourByTrainingType,
  ] = React.useState(null);
  const [completedTrainingHourByMonth, setCompletedTrainingHourByMonth] =
    React.useState(null);
  const [
    completedTrainingPieceByTrainingType,
    setCompletedTrainingPieceByTrainingType,
  ] = React.useState(null);
  const [completedTrainingManHourByMonth, setCompletedTrainingManHourByMonth] =
    React.useState(null);
  const [
    completedTrainingManHourByTrainingType,
    setCompletedTrainingManHourByTrainingType,
  ] = React.useState(null);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const [
      personnelCountByIsgTrainingData,
      completedTrainingHourByTrainingTypeData,
      completedTrainingHourByMonthData,
      completedTrainingPieceByTrainingTypeData,
      completedTrainingManHourByTrainingTypeData,
      completedTrainingManHourByMonthData,
    ] = await Promise.all([
      getPersonnelCountByIsgTraining(filterProps),
      getCompletedTrainingHourByTrainingType(filterProps),
      getCompletedTrainingHourByMonth(filterProps),
      getCompletedTrainingPieceByTrainingType(filterProps),
      getCompletedTrainingManHourByTrainingType(filterProps),
      getCompletedTrainingManHourByMonth(filterProps),
    ]);

    setPersonnelCountByIsgTraining(
      formatDataForPieChart(personnelCountByIsgTrainingData, true)
    );
    setCompletedTrainingHourByTrainingType(
      formatDataForPieChart(completedTrainingHourByTrainingTypeData, false)
    );
    setCompletedTrainingManHourByTrainingType(
      formatDataForPieChart(completedTrainingManHourByTrainingTypeData, false)
    );
    setCompletedTrainingPieceByTrainingType(
      formatDataForPieChart(completedTrainingPieceByTrainingTypeData, true)
    );
    setCompletedTrainingHourByMonth(
      formatDataForBarCharts(
        completedTrainingHourByMonthData,
        "Aylık Tamamlanmış Eğitim Süresi"
      )
    );
    setCompletedTrainingManHourByMonth(
      formatDataForBarCharts(
        completedTrainingManHourByMonthData,
        "Tamamlanmış Eğitim Süresi"
      )
    );
    setBackdropLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user,
    filterProps.startDate,
    filterProps.endDate,
    filterProps.companyIds,
    filterProps.regionIds,
    filterProps.townIds,
    filterProps.departmentIds,
    filterProps.departmentTypes,
  ]);
  React.useEffect(() => {
    init();
  }, [init]);

  const formatDataForBarCharts = (data, propName) => {
    let chartData = [];
    for (let p in data) {
      const row = {
        name: data[p].value,
        [propName]: data[p].count ?? 0,
      };
      chartData.push(row);
    }
    return chartData;
  };

  const formatDataForPieChart = (data, needPercent) => {
    let chartData = [];
    let totalCount = 0;
    if (needPercent) {
      for (let p in data) {
        totalCount += data[p].count ?? 0;
      }
    }
    for (let p in data) {
      if (needPercent) {
        const row = {
          name: data[p].value,
          value: data[p].count ?? 0,
          percent: ((data[p].count / totalCount) * 100).toFixed(2),
        };
        chartData.push(row);
      } else {
        const row = {
          name: data[p].value,
          value: data[p].count ?? 0,
        };
        chartData.push(row);
      }
    }
    return chartData;
  };

  return (
    <div className={classes.section}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={personnelCountByIsgTraining}
              title={"Temel İSG Eğitim Durumlarına Göre Personel Sayıları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={completedTrainingPieceByTrainingType}
              title={"Eğitim Tiplerine Göre Tamamlanmış Eğitim Sayıları"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={completedTrainingHourByTrainingType}
              title={"Eğitim Tiplerine Göre Tamamlanmış Eğitim Süreleri (Saat)"}
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <PieChart
              dataSet={completedTrainingManHourByTrainingType}
              title={
                "Eğitim Tiplerine Göre Tamamlanmış Eğitim Süreleri (Adam-Saat)"
              }
              FooterComponent={() => Footer("pie", filterProps)}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <CompletedTrainingHourByMonthBarChart
              data={completedTrainingHourByMonth}
              title={"Aylık Tamamlanmış Eğitim Süresi"}
              Footer={Footer}
              filterProps={filterProps}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Paper className={fixedHeightPaper}>
            <CompletedTrainingManHourByMonthBarChart
              data={completedTrainingManHourByMonth}
              title={"Tamamlanmış Eğitim Süresi"}
              Footer={Footer}
              filterProps={filterProps}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
