import Request from "../../helpers/Request";
import LanguageHelper from "../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      pageSize: query.pageSize,
      sort: "id",
      order: "DESC",
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "name") {
        params.sort = "name," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/inspection-type", null, params); 
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          name: data[i].name,
          forms: formatForms(data[i].formTemplates)
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      });
    }
  });
  return data;
}

const formatForms = (arr) =>{
  const formsArray = [];
  for(let f of arr){
    formsArray.push({
      name: f.title,
      id:f.id
    })
  }
  return formsArray
}