import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Button, CircularProgress, FormControl } from "@material-ui/core";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { TextArea } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function ModalForm({
  requestModuleProps,
  moduleProps,
  setRequestModuleProps,
  submitNewModule,
  updateModule,
  loading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        required
        label={language.tableColumns.name} //will be changed
        value={requestModuleProps.name}
        onChangeFunc={(value) =>
          setRequestModuleProps({
            ...requestModuleProps,
            name: value.target.value,
          })
        }
        style={classes.textField}
      />
      <div style={{ paddingBottom: 20, textAlign: "center" }}>
        {loading ? (
          <CircularProgress color="secondary" />
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => (moduleProps ? updateModule() : submitNewModule())}
            className={classes.submit}
          >
            {moduleProps ? language.modal.update : language.modal.submit}
          </Button>
        )}
      </div>
    </FormControl>
  );
}
