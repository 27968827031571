import React, { useCallback } from "react";
import { ResponsiveContainer } from "recharts";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  CircularProgress,
  Divider,
  makeStyles,
  Button,
} from "@material-ui/core";
import Request from "../../../helpers/Request";
import Table from "../../../components/Table/Table";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetOptions from "../heavyequipments/GetOptions";
import moment from "moment";
import showSnackbar from "../../../components/Utils/showSnackbar";
import CustomFileDropzone from "../../../components/Files/CustomFileDropzone";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MainMaintenanceModal from "./MainMaintenanceModal";
import VehicleInspectionModal from "./VehicleInspectionModal";
import ExhaustMeasurementModal from "./ExhaustMeasurementModal";
import CarInsuranceModal from "./CarInsuranceModal";
import TrafficInsuranceModal from "./TrafficInsuranceModal";
import Details from "./Details";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 20,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  divider: {
    margin: 10,
  },
  textField: {
    marginBottom: "20px",
    backgroundColor: "white",
    fontColor: "darkblue",
    width: 400,
    borderRadius: 3,
  },
  table: {
    fontSize: 15,
  },
  redText: {
    color: "red",
    fontSize: 16,
  },
  addButton: {
    paddingBottom: 20,
    textAlign: "center",
    width: "40%",
    marginLeft: "30%",
    marginRight: "30%",
  },
}));

export default function HeavyEquipmentDetails({ id, auth, rowData, tableRef }) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();
  const darkTheme = createTheme({ palette: { type: "dark" } });

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [data, setData] = React.useState(null);
  const [options, setOptions] = React.useState({});
  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mainMaintenanceModal, setMainMaintenanceModal] = React.useState(false);
  const [vehicleInspectionModal, setVehicleInspectionModal] =
    React.useState(false);
  const [exhaustMeasurementModal, setExhaustMeasurementModal] =
    React.useState(false);
  const [carInsuranceModal, setCarInsuranceModal] = React.useState(false);
  const [trafficInsuranceModal, setTrafficInsuranceModal] =
    React.useState(false);
  const [mainMaintenanceColumns, setMainMaintenanceColumns] = React.useState(
    []
  );
  const [exhaustMeasurementColumns, setExhaustMeasurementsColumns] =
    React.useState([]);
  const [vehicleInspectionColumns, setVehicleInspectionColumns] =
    React.useState([]);
  const [carInsuranceColumns, setCarInsuranceColumns] = React.useState([]);
  const [trafficInsuranceColumns, setTrafficInsuranceColumns] = React.useState(
    []
  );
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [registrationDocument, setRegistrationDocument] = React.useState(null);

  const getVehicle = useCallback(async (id) => {
    const resp = await Request("get", "/api/vehicles/passenger-vehicles/" + id);
    console.log(resp);
    return resp.data;
  }, []);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    const options = await GetOptions();
    setOptions(options);
    setCarInsuranceColumns([
      {
        title: "Kasko Tarihi",
        field: "lastInsuranceDate",
        render: (rowData) =>
          moment(rowData.lastInsuranceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Yenileme Tarihi",
        field: "renewDate",
        render: (rowData) =>
          moment(rowData.renewDate).format("DD-MM-YYYY HH:mm:ss"),
      },
    ]);
    setTrafficInsuranceColumns([
      {
        title: "Trafik Sigortası Tarihi",
        field: "lastInsuranceDate",
        render: (rowData) =>
          moment(rowData.lastInsuranceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Yenileme Tarihi",
        field: "renewDate",
        render: (rowData) =>
          moment(rowData.renewDate).format("DD-MM-YYYY HH:mm:ss"),
      },
    ]);
    setMainMaintenanceColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: "Bakım Tarihi",
        field: "maintenanceDate",
        render: (rowData) =>
          moment(rowData.maintenanceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Sonraki Bakım Tarihi",
        field: "nextMaintenanceDate",
        render: (rowData) =>
          moment(rowData.nextMaintenanceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Sonraki Bakım Km'si",
        field: "nextMaintenanceKm",
      },
      { title: "Açıklama", field: "explanation" },
    ]);
    setExhaustMeasurementsColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: "Egzoz Ölçüm Tarihi",
        field: "maintenanceDate",
        render: (rowData) =>
          moment(rowData.maintenanceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Sonraki Egzoz Ölçüm Tarihi",
        field: "nextMaintenanceDate",
        render: (rowData) =>
          moment(rowData.nextMaintenanceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Sonraki Egzoz Ölçüm Km'si",
        field: "nextMaintenanceKm",
      },
      { title: "Açıklama", field: "explanation" },
    ]);
    setVehicleInspectionColumns([
      { title: language.tableColumns.id, field: "id" },
      {
        title: "Muayene Tarihi",
        field: "maintenanceDate",
        render: (rowData) =>
          moment(rowData.maintenanceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Sonraki Muayene Tarihi",
        field: "nextMaintenanceDate",
        render: (rowData) =>
          moment(rowData.nextMaintenanceDate).format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        title: "Sonraki Muayene Km'si",
        field: "nextMaintenanceKm",
      },
      { title: "Açıklama", field: "explanation" },
    ]);
    const fetchData = await getVehicle(id);
    setData(fetchData);
    if (fetchData?.registrationDocument !== null) {
      const file = new File(
        [fetchData?.registrationDocument?.fileName],
        fetchData?.registrationDocument?.fileName,
        { type: "text/plain" }
      );
      setRegistrationDocument(file);
    }
    setBackdropLoading(false);
  }, [id, getVehicle, language.tableColumns.id]);
  React.useEffect(() => {
    init();
  }, [init]);

  const submitRegistrationDocument = async () => {
    setLoading(true);
    let formData = new FormData();
    if (registrationDocument) {
      formData.append("registrationDocumentFile", registrationDocument);
    }
    const resp = await Request(
      "post",
      "/api/vehicles/" + data?.id + "/registration-doc",
      formData
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    setLoading(false);
  };

  const handleOpenMainMaintenanceModal = async () => {
    setMainMaintenanceModal(true);
  };

  const handleOpenVehicleInspectionModal = async () => {
    setVehicleInspectionModal(true);
  };

  const handleOpenExhaustMeasurementModal = async () => {
    setExhaustMeasurementModal(true);
  };

  const handleOpenCarInsuranceModal = async () => {
    setCarInsuranceModal(true);
  };

  const handleOpenTrafficInsuranceModal = async () => {
    setTrafficInsuranceModal(true);
  };

  const RenderVehicleInspections = () => {
    return (
      <>
        <VehicleInspectionModal
          modal={vehicleInspectionModal}
          setModal={setVehicleInspectionModal}
          options={options}
          setOptions={setOptions}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          id={id}
        />
        <Table
          tableName={"Muayeneler"}
          authName="heavyEquipmentTable"
          columns={vehicleInspectionColumns}
          tableRef={tableRef}
          isEditHidden={true}
          handleOpenModal={handleOpenVehicleInspectionModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          data={data?.vehicleInspections}
          numOfEntries={numOfEntries}
          setNumOfEntries={setNumOfEntries}
          height={400}
          detailsPanel={(rowData) => (
            <div
              style={{
                backgroundColor: "#2D3446",
                color: "white",
                textAlign: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Details rowData={rowData} />
            </div>
          )}
        />
      </>
    );
  };

  const RenderExhaustMeasurements = () => {
    return (
      <>
        <ExhaustMeasurementModal
          modal={exhaustMeasurementModal}
          setModal={setExhaustMeasurementModal}
          options={options}
          setOptions={setOptions}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          id={id}
        />
        <Table
          tableName={"Egzoz Ölçüm"}
          authName="heavyEquipmentTable"
          columns={exhaustMeasurementColumns}
          tableRef={tableRef}
          isEditHidden={true}
          handleOpenModal={handleOpenExhaustMeasurementModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          data={data?.exhaustMeasurements}
          numOfEntries={numOfEntries}
          setNumOfEntries={setNumOfEntries}
          height={400}
          detailsPanel={(rowData) => (
            <div
              style={{
                backgroundColor: "#2D3446",
                color: "white",
                textAlign: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Details rowData={rowData} />
            </div>
          )}
        />
      </>
    );
  };

  const RenderMainMaintenances = () => {
    return (
      <>
        <MainMaintenanceModal
          modal={mainMaintenanceModal}
          setModal={setMainMaintenanceModal}
          options={options}
          setOptions={setOptions}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          id={id}
        />
        <Table
          tableName={"Bakımlar"}
          authName="heavyEquipmentTable"
          columns={mainMaintenanceColumns}
          tableRef={tableRef}
          isEditHidden={true}
          handleOpenModal={handleOpenMainMaintenanceModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          data={data?.mainMaintenances}
          numOfEntries={numOfEntries}
          setNumOfEntries={setNumOfEntries}
          height={400}
          detailsPanel={(rowData) => (
            <div
              style={{
                backgroundColor: "#2D3446",
                color: "white",
                textAlign: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Details rowData={rowData} />
            </div>
          )}
        />
      </>
    );
  };

  const RenderCarInsurances = () => {
    return (
      <>
        <CarInsuranceModal
          modal={carInsuranceModal}
          setModal={setCarInsuranceModal}
          options={options}
          setOptions={setOptions}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          id={id}
        />
        <Table
          tableName={"Kaskolar"}
          authName="heavyEquipmentTable"
          columns={carInsuranceColumns}
          tableRef={tableRef}
          isEditHidden={true}
          handleOpenModal={handleOpenCarInsuranceModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          data={data?.carInsurances}
          numOfEntries={numOfEntries}
          setNumOfEntries={setNumOfEntries}
          height={400}
          detailsPanel={(rowData) => (
            <div
              style={{
                backgroundColor: "#2D3446",
                color: "white",
                textAlign: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Details rowData={rowData} />
            </div>
          )}
        />
      </>
    );
  };

  const RenderTrafficInsurances = () => {
    return (
      <>
        <TrafficInsuranceModal
          modal={trafficInsuranceModal}
          setModal={setTrafficInsuranceModal}
          options={options}
          setOptions={setOptions}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
          id={id}
        />
        <Table
          tableName={"Trafik Sigortaları"}
          authName="heavyEquipmentTable"
          columns={trafficInsuranceColumns}
          tableRef={tableRef}
          isEditHidden={true}
          handleOpenModal={handleOpenTrafficInsuranceModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          data={data?.trafficInsurances}
          numOfEntries={numOfEntries}
          setNumOfEntries={setNumOfEntries}
          height={400}
          detailsPanel={(rowData) => (
            <div
              style={{
                backgroundColor: "#2D3446",
                color: "white",
                textAlign: "center",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Details rowData={rowData} />
            </div>
          )}
        />
      </>
    );
  };

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <ResponsiveContainer>
        {data ? (
          <>
            {data.status !== 404 ? (
              <div className={classes.details}>
                <ThemeProvider theme={darkTheme}>
                  <div className={classes.table}>
                    <RenderVehicleInspections />
                    <Divider className={classes.divider} />
                    <RenderMainMaintenances />
                    <Divider className={classes.divider} />
                    <RenderExhaustMeasurements />
                    <Divider className={classes.divider} />
                    <RenderCarInsurances />
                    <Divider className={classes.divider} />
                    <RenderTrafficInsurances />
                  </div>
                  <Divider className={classes.divider} />
                  <CustomFileDropzone
                    files={registrationDocument ? [registrationDocument] : []}
                    setFiles={(value) => {
                      value.length > 0
                        ? setRegistrationDocument(value[0])
                        : setRegistrationDocument(null);
                    }}
                    fileLimit={1}
                    dropzoneText={
                      "Ruhsat yüklemek için sürükleyin ya da buraya tıklayın."
                    }
                  />
                  <div className={classes.addButton}>
                    {loading ? (
                      <CircularProgress color="secondary" />
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={() => submitRegistrationDocument()}
                        className={classes.submit}
                      >
                        {"EKLE"}
                      </Button>
                    )}
                  </div>
                </ThemeProvider>
              </div>
            ) : (
              <>BİNEK ARAÇ BULUNAMADI</>
            )}
          </>
        ) : (
          <CircularProgress color="secondary" />
        )}
      </ResponsiveContainer>
    </>
  );
}
