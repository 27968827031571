import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../components/Table/TableFilters";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import { TextSelector, TextArea } from "../../../components/Fields/TextFields";
import jsonMap from "../../../components/Utils/jsonMap";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();

  const [backdropLoading, setBackdropLoading] = React.useState(false);
  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);

  return (
    <>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      <TableBackdrop backdropLoading={backdropLoading} />
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete //DEPARTMENT
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.department}
                onChange={async (event, value) => {
                  setBackdropLoading(true);
                  if (value === null) {
                    setTableFilters({ ...tableFilters, department: null });
                  } else {
                    setTableFilters({ ...tableFilters, department: value });
                  }
                  setBackdropLoading(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bölüm"
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Atık Tipi"
                value={tableFilters.wasteTypeFilter}
                shrink={tableFilters.wasteTypeFilter}
                onChangeFunc={(value) => {
                  setTableFilters({
                    ...tableFilters,
                    wasteTypeFilter: value.target.value,
                  });
                }}
                style={classes.textField}
                options={jsonMap("wasteTypes", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Atık Birimi"
                value={tableFilters.wasteUnitFilter}
                shrink={tableFilters.wasteUnitFilter}
                onChangeFunc={(value) => {
                  setTableFilters({
                    ...tableFilters,
                    wasteUnitFilter: value.target.value,
                  });
                }}
                style={classes.textField}
                options={jsonMap("wasteUnits", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label="Bertaraf Yöntemi"
                value={tableFilters.disposalMethodFilter}
                shrink={tableFilters.disposalMethodFilter}
                onChangeFunc={(value) => {
                  setTableFilters({
                    ...tableFilters,
                    disposalMethodFilter: value.target.value,
                  });
                }}
                style={classes.textField}
                options={jsonMap("wasteDisposalMethods", options)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextArea
                shrink={tableFilters.yearFilter !== null}
                label={"Yılı"}
                value={tableFilters.yearFilter}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    yearFilter: value.target.value,
                  })
                }
                style={classes.textField}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </>
  );
}
