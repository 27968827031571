import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import SessionHelper from "../../helpers/SessionHelper";
import Copyright from "../../components/Footer/Copyright";
import moment from "moment";
import SaveAlt from "@material-ui/icons/SaveAlt";
import IconTooltipButton from "../../components/Buttons/IconTooltipButton";
import "moment/locale/tr";
import Request from "../../helpers/Request";
import { CustomDatePicker } from "../../components/Selectors/DatePickers";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";

const fixedHeight = 500;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: "100vh",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  fixedHeight: {
    maxHeight: fixedHeight,
  },
  textField: {
    width: "90%",
    margin: 10,
  },
  subtitle: {
    margin: "0 auto",
    color: "#C3C3C3"
  }
}));

/**
 * A dashboard component that renders charts depending on the role of the user.
 */
export default function SharedSettings({
  getReport,
  setBackdropLoading,
  contractor,
  setContractor,
  edc,
  setEdc,
  region,
  setRegion,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  contractors, 
  setContractors,
  edcs, 
  setEdcs,
  regions, 
  setRegions,
  departmentRequired,
  isYgk,
  isOldFormat,
  setYgk,
  setOldFormat
}) {
  const collator = new Intl.Collator("tr");
  moment.locale("tr");
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const contractorRegionCheck = departmentRequired ? (contractor && contractor > -1) : (contractor ? (contractor > -1 || region > -1) : true);

  const getEdcs = useCallback(async () => {
    let resp;
    resp = await Request("get", "/api/companies/type-edc");
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let comp = {};
      comp["name"] = data[i].name;
      comp["id"] = data[i].id;
      arr.push(comp);
    }
    return arr;
  }, []);

  const getRegions = useCallback(async (id) => {
      let resp;
      if (user.roles.includes("ROLE_SİSTEM_ADMİNİ")) {
        resp = await Request("get", "/api/companies/" + id + "/get-cities");
      } else {
        resp = await Request(
          "get",
          "/api/departments/" + user.department?.id + "/get-cities"
        );
      }
      const data = resp.data;
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        let regions = data[i].regions;
        for (let j = 0; j < regions.length; j++) {
          let comp = {};
          comp["cityName"] = data[i].name;
          comp["name"] = regions[j].name;
          comp["id"] = regions[j].id;
          arr.push(comp);
        }
      }
      return arr;
    },
    [user]
  );

  const filterRegions = useCallback((towns) => {
    let temp = [];
    townLoop:
    for(let i in towns) {
      let new_region = {};
      const region = towns[i]?.belongingRegion;

      for(let j in temp) {
        if(temp[j]?.id === region?.id) {
          continue townLoop;
        }
      }

      new_region["cityName"] = region?.belongingCity?.name;
      new_region["name"] = region?.name;
      new_region["id"] = region?.id;
      temp.push(new_region);
    }
    return temp;
  }, []);

  const getContractors = useCallback(async (id, reg) => {
    setBackdropLoading(true);
    let resp;
    const param = {
      "region-id": reg > -1 ? reg : undefined
    }
    if (user.roles.includes("ROLE_SİSTEM_ADMİNİ")) {
      resp = await Request("get", "/api/departments/belongs-to-edc-company/" + id, null, param);
    } else {
      resp = await Request("get", "/api/departments/belongs-to-edc-company/" + user.companyId, null, param);
    }
    console.log(resp);
    const data = resp.data;
    let arr = [];
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      dept["fields"] = data[i].fieldOfActivities;
      arr.push(dept);
    }
    setBackdropLoading(false);
    return arr;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const init = useCallback(async () => {
    setBackdropLoading(true);
    if(user.roles.includes("ROLE_SİSTEM_ADMİNİ")) {
      setEdcs(await getEdcs());
    } else if(user.roles.includes("ROLE_BÖLGE_MÜDÜRÜ")) {
      setRegions(await getRegions());
      setContractors && setContractors(await getContractors(null, SessionHelper.getUser()?.department?.town?.belongingRegion?.id));
    } else {
      setRegions(await getRegions());
      setContractors && setContractors(await getContractors());
    }
    setBackdropLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getContractors, getRegions, getEdcs, user]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderContractorsAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={contractors?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        disabled={
          user.roles.includes("ROLE_SİSTEM_ADMİNİ") &&
          (edc === null || contractors.length < 1)
        }
        defaultValue={contractor ? getFromId(contractor, contractors) : null}
        onChange={async (event, value) => {
          value ? setRegions(filterRegions(value?.fields)) : setRegions(await getRegions(edc ? edc : null));
          setContractor(value ? value?.id : -1);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Yüklenici"
            className={classes.textField}
          />
        )}
      />
    );
  };

  const filterOptions = createFilterOptions({
    stringify: ({ name, cityName }) => `${name} ${cityName}`,
  });

  const RenderRegionAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={regions
          ?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })
          ?.sort(function (a, b) {
            return collator.compare(a.cityName, b.cityName);
          })}
        getOptionLabel={(option) => option.name}
        filterOptions={filterOptions}
        groupBy={(option) => option.cityName}
        fullWidth={true}
        disabled={
          (user.roles.includes("ROLE_SİSTEM_ADMİNİ") && (edc === null || regions.length < 1)) ||
          user.roles.includes("ROLE_BÖLGE_MÜDÜRÜ")
        }
        defaultValue={region ? getFromId(region, regions) : null}
        onChange={async (event, value) => {
          const reg = value ? value.id : -1;
          setContractors && setContractors(await getContractors(user.roles.includes("ROLE_SİSTEM_ADMİNİ") ? edc : null, reg));
          setRegion(reg);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Bölge"
            className={classes.textField}
          />
        )}
      />
    );
  };

  const RenderEDCAutocomplete = () => {
    return (
      <Autocomplete
        id="combo-box"
        options={edcs?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        defaultValue={edc ? getFromId(edc, edcs) : null}
        onChange={async (event, value) => {
          setEdc(value?.id);
          setContractors && setContractors(await getContractors(value?.id));
          setRegions(await getRegions(value?.id));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            label="EDC"
            className={classes.textField}
          />
        )}
      />
    );
  };

  const getFromId = (id, options) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} justifyContent="space-between">
            {/* SETTINGS */}
            <Grid item xs={12}>
              <Paper className={classes.paper} style={{ height: 100 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <CustomDatePicker
                      style={classes.textField}
                      label="Başlangıç Tarihi"
                      value={moment(startDate)}
                      shrink={startDate}
                      onChangeFunc={(value) => {
                        setStartDate(moment(value).set("hour", 0).set("minute", 0).toISOString(true));
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomDatePicker
                      style={classes.textField}
                      label="Bitiş Tarihi"
                      value={moment(endDate)}
                      shrink={endDate}
                      onChangeFunc={(value) => {
                        setEndDate(moment(value).set("hour", 23).set("minute", 59).toISOString(true));
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            
            {setYgk && 
            <Grid item xs={6} style={{margin: "0 auto", marginBottom: -20}}>
              <Paper>
                <FormControlLabel
                  control={<Checkbox checked={isYgk} onChange={() => {setYgk(!isYgk)}} name="isYgk" />}
                  label="Sadece Yaşam Güvenliği Komitesi Üyeleri Olsun"
                />
              </Paper>
            </Grid>}
            {setOldFormat && 
            <Grid item xs={6} style={{margin: "0 auto", marginBottom: -20}}>
              <Paper>
                <FormControlLabel
                  control={<Checkbox checked={isOldFormat} onChange={() => {setOldFormat(!isOldFormat)}} name="isOldFormat" />}
                  label="Eski Format"
                />
              </Paper>
            </Grid>}
            <Grid item xs={contractorRegionCheck ? 10 : 12}>
              <Paper
                className={classes.paper}
                style={{ height: 100, marginTop: 20 }}
              >
                <Grid container justifyContent="center">
                  {user.roles.includes("ROLE_SİSTEM_ADMİNİ") && (
                    <Grid item xs={4}>
                      <RenderEDCAutocomplete />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={user.roles.includes("ROLE_SİSTEM_ADMİNİ") ? 4 : 6}
                  >
                    <RenderRegionAutocomplete />
                  </Grid>
                  {contractor &&
                  <Grid
                    item
                    xs={user.roles.includes("ROLE_SİSTEM_ADMİNİ") ? 4 : 6}
                  >
                    <RenderContractorsAutocomplete />
                  </Grid>}
                </Grid>
              </Paper>
            </Grid>

            {/* EXPORT BUTTON */}
            {contractorRegionCheck && (
              <Grid item>
                <Paper
                  className={classes.paper}
                  style={{ height: 100, width: 100, marginTop: 20 }}
                >
                  <div style={{ margin: "auto", marginLeft: "32%" }}>
                    <IconTooltipButton title="Raporu İndir" onClick={getReport}>
                      <SaveAlt />
                    </IconTooltipButton>
                  </div>
                </Paper>
              </Grid>
            )}

            {departmentRequired ?
              <h4 className={classes.subtitle}>Yüklenici alanının doldurulması zorunludur.</h4>
              :
              contractor && 
              <h4 className={classes.subtitle}>Bölge ve Yüklenici alanlarından en az birinin doldurulması zorunludur.</h4>}
          </Grid>
          <Box pt={4} style={{marginTop: "40%"}}>
            <Copyright
              string={"DELTA Smart Technologies"}
              url={"https://deltasmart.tech/en/home/"}
              date={new Date().getFullYear()}
            />
          </Box>
        </Container>
      </main>
    </div>
  );
}
