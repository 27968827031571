import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import FetchData from "./FetchData";
import UserModal from "./UserModal";
import GetOptions from "./GetOptions";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import { Button, makeStyles } from "@material-ui/core";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { getAuthorizationForPage } from "../../../helpers/AuthorizationHelper";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

const useStyles = makeStyles((theme) => ({
  list: {
    height: 100,
    overflow: "auto",
  },
}));

export default function YasamUserTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const classes = useStyles();
  const localization = { ...language.tableLocalization };
  localization.body.deleteTooltip = "Komiteden Çıkar";
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "yasamUsersTable");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [oldUser, setOldUser] = React.useState(null);
  const [options, setOptions] = React.useState({});
  const [modal, setModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const init = useCallback(async () => {
    let options = await GetOptions();
    setColumns([
      { title: "No", field: "id", editable: "never" },
      { title: "Adı", field: "firstName", editable: "never" },
      { title: "Soyadı", field: "lastName", editable: "never" },
      { title: "Şirket Adı", field: "company", editable: "never" },
      { title: "Bölümü", field: "department", editable: "never" },
      {
        title: "Rolü",
        field: "roles",
        render: (rowData) => (
          <>
            <List className={classes.list}>
              {rowData.roles.map((data, index) => (
                <ListItem key={index}>
                  <ListItemText secondary={roles[`${data.name}`]} />
                </ListItem>
              ))}
            </List>
          </>
        ),
      },
      {
        title: "Aylık Denetim Görevi Hedefleri",
        field: "goal",
        sorting: false,
        render: (rowData) => (
          <Button variant="outlined" onClick={() => goToGoals(rowData.id)}>
            HEDEFLER
          </Button>
        ),
      },
    ]);
    setOptions(options);
  }, [classes]);
  React.useEffect(() => {
    init();
  }, [init]);

  const goToGoals = async (id) => {
    window.open("/goals/" + id);
  };

  const handleOpenModal = async (data) => {
    setOldUser(data);
    setModal(true);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedUser(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedUser(null);
    setDialog(false);
  };

  const removeFromCommittee = async () => {
    setRequestLoading(true);
    const userData = await Request("get", "/api/users/" + selectedUser?.id);
    let body = userData.data;
    let roles = [];
    for (let index in body.roles) {
      if (body.roles[index].name === "ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ") {
        body.roles.splice(index, 1);
      } else {
        roles.push(body.roles[index].name);
      }
    }
    let props = {
      roles: roles,
      id: body.id,
    };
    if (userData.status === 200) {
      const resp = await Request("patch", "/api/users", props);
      console.log(resp);
      showSnackbar(
        resp,
        setSeverity,
        setSnackbarMessage,
        setSnackbar,
        language.etc.deleted,
        tableRef
      );
    } else {
      setSeverity("error");
      setSnackbarMessage(language.login.unexpectedError);
      setSnackbar(true);
    }
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeFromCommittee}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <UserModal
        modal={modal}
        setModal={setModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldUserProps={oldUser}
        opts={options}
      />
      <Table
        tableName={language.sidebar.yasamUsers}
        authName="yasamUsersTable"
        columns={columns}
        tableRef={tableRef}
        localization={localization}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => authorization?.delete}
        //deleteEntry={removeFromCommittee}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
      />
    </>
  );
}
