import Request from '../../../../helpers/Request';
import moment from 'moment';
import SessionHelper from '../../../../helpers/SessionHelper';
import LanguageHelper from '../../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      filterTerminationStart: filterProps.filterTerminationStart,
      filterTerminationEnd: filterProps.filterTerminationEnd,
      filterApprovedStart: filterProps.filterApprovedStart,
      filterApprovedEnd: filterProps.filterApprovedEnd,
      filterDepartmentId: filterProps.department?.id,
      filterCompanyId: filterProps.company?.id,
      filtergivenBy: filterProps.givenBy,
      filterAssignedTo: filterProps.assignedTo,
      filterStatus: filterProps.status
    };
    if(filterProps.personal) {params.userId = user.id;}
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "customId") { params.sort = "customId," + query.orderDirection; }
      else if (query.orderBy.field === "approvedDate") { params.sort = "approvedDate," + query.orderDirection; }
      else if (query.orderBy.field === "givenBy") { params.sort = "givenBy.fName,givenBy.lName," + query.orderDirection; }
      else if (query.orderBy.field === "status") { params.sort = "status," + query.orderDirection; }
      else if (query.orderBy.field === "assignedTo") { params.sort = "assignedTo.fName,assignedTo.lName," + query.orderDirection; }
      else if (query.orderBy.field === "terminationDate") { params.sort = "terminationDate," + query.orderDirection; }
    }
    if(query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/emergency/practise-action", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData;
      let content = data.content;
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        let appr_date = "";
        let term_date = "";
        if (content[i].approvedDate) { appr_date = (new moment(content[i].approvedDate)).format('DD-MM-YYYY HH:mm:ss'); }
        if (content[i].terminationDate) { term_date = (new moment(content[i].terminationDate)).format('DD-MM-YYYY HH:mm:ss'); }
        const edit = {
          givenBy: content[i].givenBy ? content[i].givenBy?.fName + " " + content[i].givenBy?.lName : null,
          approvedDate: appr_date,
          assignedTo: content[i].assignedTo ? content[i].assignedTo?.fName + " " + content[i].assignedTo?.lName : language.etc.empty,
          company: content[i].company,
          id: content[i].id,
          customId: content[i].customId,
          department: content[i].department,
          terminationDate: term_date,
          status: content[i].status,
          note: content[i].messages[0].note
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      })
    }
  })
  return data;
}