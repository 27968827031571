import React, { useCallback } from "react";
import FetchData from "./FetchData";
import LanguageHelper from "../../../helpers/LanguageHelper";
import SessionHelper from "../../../helpers/SessionHelper";
import Request from "../../../helpers/Request";
import Details from "./Details";
import NewCityModal from './NewCityModal';
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import showSnackbar from "../../../components/Utils/showSnackbar";
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import DeleteDialog from "../../../components/Dialog/DeleteDialog";

export default function CitiesTownsTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const authorization = getAuthorizationForPage(roles, "citiesTownsTable");

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [modal, setModal] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [selectedCity, setSelectedCity] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);
  
  const init = useCallback(async () => {
    setColumns([
      { title: "No", field: "id", editable: "never" },
      { title: "Şehir", field: "name", editable: "never" },
    ]);
  }, []);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = () => {
    setModal(true);
  }

  const handleOpenDeleteDialog = (data) => {
    setSelectedCity(data);
    setDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedCity(null);
    setDialog(false);
  };

  const removeCity = async () => {
    setRequestLoading(true);
    const resp = await Request("delete", '/api/cities-towns/' + selectedCity?.id);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.deleted, tableRef);
    handleCloseDeleteDialog();
    setRequestLoading(false);
  }

  return (
    <>
       <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={removeCity}
        open={dialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <NewCityModal 
        modal={modal} 
        setModal={setModal} 
        setSnackbar={setSnackbar} 
        setSnackbarMessage={setSnackbarMessage} 
        setSeverity={setSeverity}
        tableRef={tableRef}
      />
      <Table
        tableName={language.sidebar.citiesTowns}
        authName="citiesTownsTable"
        columns={columns}
        tableRef={tableRef}
        handleOpenModal={handleOpenModal}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        isDeletable={(rowData) => authorization?.delete}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        setSeverity={setSeverity}
        fetchData={FetchData}
        //deleteEntry={removeCity}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        detailsPanel={rowData =>
          <Details 
            rowData={rowData} 
            tableRef={tableRef} 
            setSnackbar={setSnackbar} 
            setSnackbarMessage={setSnackbarMessage} 
            setSeverity={setSeverity}
          />
        }
      />
    </>
  );
}