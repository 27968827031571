import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from '@material-ui/icons/Delete';
import { Autocomplete, createFilterOptions } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  list: {
    maxHeight: 180,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  regions: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  textFieldRegions: {
    marginTop: "-20px",
    marginBottom: "40px",
    width: "50%"
  },
}));

export default function RegionList({newUserProps, setNewUserProps, options, setChangeMadeToProps}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [autocompleteValue, setAutocompleteValue] = React.useState("");

  const getFromId = (id, options) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  const filterOptions = createFilterOptions({
    stringify: ({ name, cityName }) => `${name} ${cityName}`
  });

  return (
    <div style={{textAlign: "left"}}>
      <h5 className={classes.regions}>Sorumluluk Bölgeleri</h5>
      {newUserProps.responsibilityRegions && newUserProps.responsibilityRegions.length > 0 
        ?
        <List className={classes.list}>
          {
            newUserProps.responsibilityRegions.map((data, index) => (
              <ListItem key={index}>
                <IconTooltipButton title={language.form.deleteQuestion} onClick={() => {
                  let regions = newUserProps.responsibilityRegions;
                  regions.splice(index, 1);
                  setNewUserProps({...newUserProps, responsibilityRegions: regions});
                  setChangeMadeToProps && setChangeMadeToProps(true);
                }}>
                  <DeleteIcon />
                </IconTooltipButton>
                <ListItemText secondary={data?.name ? data?.name : (options.regions ? getFromId(data, options.regions)?.name : null)}/>
              </ListItem>
            ))
          }
        </List>
        :
        <div className={classes.list} style={{textAlign: "center"}}>
          <p style={{color: "red", fontSize: 14, marginLeft: "auto"}}>Sorumluluk bölgesi yok.</p>
        </div>
      }
      <div style={{textAlign: "center"}}>
        <Autocomplete
          id="combo-box"
          options={options.regions?.sort(function(a, b) {
              return collator.compare(a.name, b.name);
            })?.sort(function(a, b) {
              return collator.compare(a.cityName, b.cityName);
          })}
          getOptionLabel={(option) => option.name}
          filterOptions={filterOptions}
          fullWidth={true}
          multiple={false}
          groupBy={(option) => option.cityName}
          disableCloseOnSelect={true}
          clearOnBlur={true}
          disableClearable={true}
          inputValue={autocompleteValue}
          onInputChange={(event, value, reason) => {
            if(reason === "reset") {
              setAutocompleteValue("");
            } else if(reason === "input") {
              setAutocompleteValue(value);
            }
          }}
          onChange={(event, value, reason) => {
            if(value?.id && value !== "") {
              let regions = newUserProps.responsibilityRegions;
              if(!regions.includes(parseInt(value?.id))) {
                regions.push(value?.id);
                setNewUserProps({...newUserProps, responsibilityRegions: regions});
                setChangeMadeToProps && setChangeMadeToProps(true);
              }
            }
          }}
          renderInput={(params) => <TextField {...params} required label="Bölge Ekle" className={classes.textFieldRegions}/>}
        />
      </div>
    </div>
  );
}