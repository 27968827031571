import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useNavigate, useLocation } from "react-router-dom";
import LanguageHelper from "../../helpers/LanguageHelper";
import logo from "../../assets/images/pincident.png";
import SessionHelper from "../../helpers/SessionHelper";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Request from "../../helpers/Request";
import Copyright from "../../components/Footer/Copyright";
import CustomSnackbar from "../../components/Snackbar/Snackbar";
import Settings from "../../components/Buttons/Settings";
import { CircularProgress } from "@material-ui/core";
import Timer from "./Timer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: 20,
    width: 300,
    height: "auto",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  buttonContainer: {
    marginTop: 10,
    textAlign: "center",
    paddingBottom: 20,
  },
}));

export default function SmsVerification({ update, setUpdate }) {
  const darkTheme = createTheme({ palette: { type: "dark" } });
  const lightTheme = createTheme({ palette: { type: "light" } });
  const navigate = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const language = LanguageHelper.getLanguage();
  const password = location.state.password;
  const key = location.state.key;
  const message = location.state.message;
  const regexRules =
    /(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*\d)(?=.*[!@#$%&*'(),\-+<=>:;?{}^._])[A-Za-z\d!@#$%&*'(),\-+<=>:;?{}^._]{8,32}$/;

  const [token, setToken] = useState("");
  const [snackbar, setSnackbar] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState(message);
  const [severity, setSeverity] = useState("success");
  const [darkMode, setDarkMode] = React.useState(
    localStorage.getItem("darkMode")
  );
  const [, setLanguage] = React.useState(language);
  const [loading, setLoading] = React.useState(false);

  async function handleLogin(token) {
    setLoading(true);
    const resp = await Request("post", "/api/auth/signin/tfa", {
      verificationCode: token,
      key: key,
    });
    console.log(resp);
    if (resp?.status === 200) {
      let data = resp.data.content;
      SessionHelper.setUser(data.jwt);
      setUpdate(!update);
      if (!regexRules.test(password)) {
        navigate("/dashboard", { state: { error: true } });
      } else {
        navigate("/dashboard");
      }
    } else {
      if (resp?.status === 429) {
        setSeverity("error");
        setSnackbarMessage(
          "Çok fazla istekte bulundunuz. Lütfen daha sonra tekrar deneyiniz."
        );
        setSnackbar(true);
      } else {
        if (resp?.data && resp?.data.messageResponse) {
          setSeverity("error");
          setSnackbarMessage(resp.data.messageResponse.message);
          setSnackbar(true);
        } else {
          setSeverity("error");
          setSnackbarMessage(language.login.unexpectedError);
          setSnackbar(true);
        }
      }
    }
    setLoading(false);
  }

  const handleSendAgain = async () => {
    setLoading(true);
    const resp = await Request("post", "/api/auth/tfa/send-code-again", {
      key: key,
    });
    console.log(resp);
    if (resp?.status === 200) {
      setSeverity("success");
      setSnackbarMessage(resp.data.messageResponse.message);
      setSnackbar(true);
      setLoading(false);
    } else {
      if (resp?.status === 429) {
        setSeverity("error");
        setSnackbarMessage(
          "Çok fazla istekte bulundunuz. Lütfen daha sonra tekrar deneyiniz."
        );
        setSnackbar(true);
      } else {
        if (resp?.data && resp?.data.messageResponse) {
          setSeverity("error");
          setSnackbarMessage(resp.data.messageResponse.message);
          setSnackbar(true);
        } else {
          setSeverity("error");
          setSnackbarMessage(language.login.unexpectedError);
          setSnackbar(true);
        }
      }
    }
    setLoading(false);
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (token === "") {
        setSnackbarMessage("SMS kodu alanı boş olamaz.");
        setSnackbar(true);
      } else {
        handleLogin(token);
      }
      e.preventDefault();
    }
  };

  return (
    <Container maxWidth={false}>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Settings
        darkMode={darkMode}
        setDarkMode={setDarkMode}
        setLanguage={setLanguage}
        isLoginPage
      />
      <Container component="main" maxWidth="xs">
        <ThemeProvider theme={darkMode === "true" ? darkTheme : lightTheme}>
          <CssBaseline />
          <div onKeyDown={_handleKeyDown} className={classes.paper}>
            <img className={classes.avatar} alt={logo} src={logo} />
            <Typography component="h1" variant="h5">
              {language.login.title}
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                error={snackbar}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Sms Kodu"
                autoFocus
                onChange={(token) => setToken(token.target.value.trim())}
              />
              <div className={classes.buttonContainer}>
                {loading ? (
                  <CircularProgress color="secondary" />
                ) : (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      if (token === "") {
                        setSnackbarMessage("SMS kodu alanı boş olamaz.");
                        setSnackbar(true);
                      } else {
                        handleLogin(token);
                      }
                    }}
                  >
                    {language.login.signin}
                  </Button>
                )}
              </div>
            </form>
          </div>
          <Timer handleSendAgain={handleSendAgain} loading={loading} />
          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                variant="body2"
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Giriş Ekranına Geri Dön
              </Link>
            </Grid>
          </Grid>
          <Box mt={8}>
            <Copyright
              string={"DELTA Smart Technologies"}
              date={new Date().getFullYear()}
              url={"https://deltasmart.tech/en/home/"}
            />
          </Box>
        </ThemeProvider>
      </Container>
    </Container>
  );
}
