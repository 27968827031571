import React, { useCallback } from "react";
import FetchData from "./FetchData";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import TableBackdrop from "../../../components/Table/TableBackdrop";
import GetFilterFields from "./ContractorToolboxReportFilters";
import LanguageHelper from "../../../helpers/LanguageHelper";
import GetOptions from "./GetOptions";
import ToolboxBarChart from "./ToolboxBarChart";
import ToolboxScoreScatterChart from "./ToolboxScoreScatterChart";

export default function ContractorToolboxReportTable() {
  const tableRef = React.useRef();
  const language = LanguageHelper.getLanguage();

  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [backdropLoading, setBackdropLoading] = React.useState(true);
  const [options, setOptions] = React.useState([]);

  const [filtersOpen, setFiltersOpen] = React.useState(true);
  const [filterProps, setFilterProps] = React.useState({
    companyIds: [],
    departmentIds: [],
    regionIds: [],
    months: [],
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: language.contractorToolbox.year, field: "year" },
      { title: language.contractorToolbox.month, field: "month" },
      {
        title: language.contractorToolbox.region,
        field: "region",
      },
      {
        title: language.contractorToolbox.department,
        field: "department",
      },
      {
        title: language.contractorToolbox.tbCount,
        field: "toolboxCount",
      },
      {
        title: language.contractorToolbox.teamCount,
        field: "crewCountForToolbox",
      },
      {
        title: language.contractorToolbox.tbScore,
        field: "toolboxScore",
      },
    ]);
    setBackdropLoading(false);
  }, [language]);
  React.useEffect(() => {
    init();
  }, [init]);

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      companyIds: [],
      departmentIds: [],
      regionIds: [],
      months: [],
    });
  };

  if (
    options.companies &&
    options.regions &&
    Array.isArray(options.companies) &&
    Array.isArray(options.regions)
  ) {
    return (
      <>
        <TableBackdrop backdropLoading={backdropLoading} />
        <CustomSnackbar
          snackbar={snackbar}
          setSnackbar={setSnackbar}
          snackbarMessage={snackbarMessage}
          severity={severity}
        />
        <RenderFilters />
        {filterProps.companyIds.length > 0 &&
          filterProps.regionIds.length > 0 &&
          filterProps.months.length > 0 &&
          filterProps.departmentIds.length > 0 && (
            <>
              <Table
                noRowActions
                tableName={language.sidebar.contractorToolbox}
                authName="contractorToolbox"
                columns={columns}
                tableRef={tableRef}
                filters={filterProps}
                setSnackbar={setSnackbar}
                setSnackbarMessage={setSnackbarMessage}
                setSeverity={setSeverity}
                fetchData={FetchData}
                numOfEntries={numOfEntries}
                setNumOfEntries={setNumOfEntries}
                noPaging
              />
              <ToolboxBarChart
                filters={filterProps}
                setBackdropLoading={setBackdropLoading}
              ></ToolboxBarChart>
              <ToolboxScoreScatterChart
                filters={filterProps}
                setBackdropLoading={setBackdropLoading}
                backdropLoading={backdropLoading}
              ></ToolboxScoreScatterChart>
            </>
          )}
      </>
    );
  } else {
    return <TableBackdrop backdropLoading={backdropLoading} />;
  }
}
