import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc" 
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "kkdName") { params.sort = "kkdName," + query.orderDirection; }
      else if (query.orderBy.field === "kkdKind") { params.sort = "kkdKind.name," + query.orderDirection; }
      else if (query.orderBy.field === "shelfLife") { params.sort = "shelfLifeTime," + query.orderDirection; }
      else if (query.orderBy.field === "lifetime") { params.sort = "lifeTime," + query.orderDirection; }
      else if (query.orderBy.field === "model") { params.sort = "model," + query.orderDirection; }
      else if (query.orderBy.field === "brand") { params.sort = "brand," + query.orderDirection; }
      else if (query.orderBy.field === "characteristic") { params.sort = "characteristic," + query.orderDirection; }
      else if (query.orderBy.field === "type") { params.sort = "type," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/kkds", null, params);
    console.log(resp)
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          kkdName: data[i].kkdName,
          kkdKind: data[i].kkdKind.name,
          type: data[i].type,
          brand: data[i].brand,
          model: data[i].model,
          characteristic: data[i].characteristic,
          shelfLife: data[i].shelfLife,
          lifetime: data[i].lifeTime,
          companyName: data[i].companyName,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}
