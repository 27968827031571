import Request from "../../../helpers/Request";

export const getUsers = async () => {
  const resp = await Request("get", "/api/quality-controls/users");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let user = {};
    user["name"] = data[i].fName + " " + data[i].lName;
    user["id"] = data[i].id;
    arr.push(user);
  }
  return arr;
};

export const getProductTypes = async () => {
  const resp = await Request("get", "/api/product-types");
  const data = resp.data.content;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let productType = {};
    productType["name"] = data[i].name;
    productType["id"] = data[i].id;
    arr.push(productType);
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [users, companies, productTypes] = await Promise.all([
    getUsers(),
    getCompanies(),
    getProductTypes(),
  ]);
  const options = { users, companies, productTypes };
  return options;
}
