import Request from "../../helpers/Request";
import SessionHelper from "../../helpers/SessionHelper";
import LanguageHelper from "../../helpers/LanguageHelper";

const language = LanguageHelper.getLanguage();

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/basic-info");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let comp = {};
    comp["name"] = data[i].name;
    comp["id"] = data[i].id;
    arr.push(comp);
  }
  return arr;
};

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getEducationStatuses = async () => {
  let fetch;
  const resp = await Request("get", "/api/users/selection/education");
  const json = resp.data;
  let new_json = {};
  for (let i = 0; i < json.length; i++) {
    if (language.title === "Türkçe") {
      new_json[json[i].nameTr] = json[i].nameTr;
    } else if (language.title === "English") {
      new_json[json[i].nameEng] = json[i].nameEng;
    }
  }
  new_json[null] = language.etc.empty;
  fetch = new_json;
  return fetch;
};

/**
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions(companyId) {
  const user = SessionHelper.getUser();
  let companies = [];
  let departments;
  let edu;
  [companies, departments, edu] = await Promise.all([
    getCompanies(),
    getDepartments(companyId || user?.companyId),
    getEducationStatuses(),
  ]);
  const options = {
    companies,
    departments,
    edu,
  };
  return options;
}
