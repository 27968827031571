import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Request from "../../../helpers/Request";
import ModalForm from './ModalForm';
import { modalStyles } from '../../../assets/styles/tableContainer';
import showSnackbar from "../../../components/Utils/showSnackbar";
import TaskModalForm from './TaskModalForm';

const useStyles = makeStyles((theme) => (modalStyles(theme)));

export default function KKDModal({modal, setModal, setSnackbar, setSnackbarMessage, setSeverity, tableRef, kkdProps, opts, task, reload}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestKKDProps, setRequestKKDProps] = React.useState({});
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  
  const init = useCallback(async () => {
    setRequestKKDProps({
      kkdName: kkdProps ? kkdProps.kkdName : null,
      type: kkdProps ? kkdProps.type : null,
      kkdKind: kkdProps ? kkdProps.kkdKind : null,
      brand: kkdProps ? kkdProps.brand : null,
      model: kkdProps ? kkdProps.model : null,
      characteristic: kkdProps ? kkdProps.characteristic : null,
      shelfLife: kkdProps ? kkdProps.shelfLife : null,
      lifetime: kkdProps ? kkdProps.lifetime : null,
    });
    setOptions(opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kkdProps, opts, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestKKDProps({
      kkdName: null,
      kkdKind: null,
      type: null,
      brand: null,
      model: null,
      characteristic: null,
      shelfLife: null,
      lifetime: null,
    });
  }

  const submitNewKKD = async () => {
    setLoading(true);
    requestKKDProps.kkdKind && 
    requestKKDProps.kkdKind.name && 
    (requestKKDProps.kkdKind = requestKKDProps.kkdKind.name);
    const resp = await Request("post", '/api/kkds', requestKKDProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }
  
  const updateKKD = async () => {
    setLoading(true);
    requestKKDProps.kkdKind && 
    requestKKDProps.kkdKind.name && 
    (requestKKDProps.kkdKind = requestKKDProps.kkdKind.name);
    const resp = await Request("patch", '/api/kkds/' + kkdProps.id, requestKKDProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  const addKKDKindsToTask = async (kkds) => {
    setLoading(true);
    let props = {...task};
    let temp = props?.kkdKindIds;
    for(let kkd of kkds) {
      temp.push(kkd);
    }
    props.kkdKindIds = temp;
    const resp = await Request("patch", '/api/kkd-tasks', props);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, null, reload());
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={classes.paper}>
            {task ?
            <TaskModalForm
              task={task} 
              addKKDKindsToTask={addKKDKindsToTask}
              options={options}
              loading={loading}
            />
            :
            <ModalForm 
              requestKKDProps={requestKKDProps} 
              kkdProps={kkdProps} 
              setRequestKKDProps={setRequestKKDProps} 
              submitNewKKD={submitNewKKD}
              updateKKD={updateKKD}
              options={options}
              loading={loading}
            />}
          </div>
        </Fade>
      </Modal>
    </>
  );
}