import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      offset: query.page,
      "page-size": query.pageSize,
      "sort-by": "id",

      userId: filterProps.userId,
      type: "RESET_PASSWORD",
      createdAtStart: filterProps.createdAtStart,
      createdAtEnd: filterProps.createdAtEnd,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params["sort-by"] = "id";
      } else if (query.orderBy.field === "userId") {
        params["sort-by"] = "user.id";
      } else if (query.orderBy.field === "fname") {
        params["sort-by"] = "user.fName";
      } else if (query.orderBy.field === "lName") {
        params["sort-by"] = "user.lname";
      } else if (query.orderBy.field === "email") {
        params["sort-by"] = "email";
      } else if (query.orderBy.field === "createdAt") {
        params["sort-by"] = "createdAt";
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    if (query.orderDirection !== "") {
      params.order = query.orderDirection.toUpperCase();
    }

    const resp = await Request(
      "get",
      "/api/history/activate_account-reset_password",
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let otherData = resp.data;
      let data = otherData.content;
      const userContent = data.content;
      let temp = [];
      for (let i = 0; i < userContent.length; i++) {
        const edit = {
          id: userContent[i]?.id ? userContent[i].id : language.etc.empty,
          email: userContent[i]?.email
            ? userContent[i].email
            : language.etc.empty,
          info: userContent[i]?.info ? userContent[i].info : language.etc.empty,
          userId: userContent[i]?.user.id
            ? userContent[i].user.id
            : language.etc.empty,
          fName: userContent[i]?.user?.fName
            ? userContent[i].user.fName
            : language.etc.empty,
          lName: userContent[i]?.user?.lName
            ? userContent[i].user.lName
            : language.etc.empty,
          companyName: userContent[i]?.user?.shortCompanyName
            ? userContent[i].user.shortCompanyName
            : language.etc.empty,
          createdAt: moment(userContent[i]?.createdAt).format("dddd, MMMM D YYYY, h:mm:ss a"),
          ipAddress: userContent[i]?.ipAddress,
          token: userContent[i]?.token,
        };
        temp.push(edit);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      });
    }
  });

  return data;
}
