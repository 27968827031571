import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import {
  Button,
  FormControl,
  TextField,
  Grid,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CustomSnackbar from "../../../components/Snackbar/Snackbar";
import { modalFormStyles } from "../../../assets/styles/tableContainer";
import { Autocomplete } from "@material-ui/lab";
import { getVisitors, getCompanies } from "./GetOptions";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CompanyModal from "../visitorCompany/CompanyModal";
import VisitorsModal from "../visitors/VisitorsModal";

const useStyles = makeStyles((theme) => modalFormStyles(theme));

export default function VisitorForm({
  oldPersonnelProps,
  options,
  visitors,
  setVisitors,
  handleCloseModal,
  index,
  getPersonnelFromId,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const [visitorOptions, setVisitorOptions] = React.useState(options.companies);
  const [modal, setModal] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [loading, setLoading] = React.useState(false);
  const [visitorsList, setVisitorsList] = React.useState([]);
  const [isVisitor, setIsVisitor] = React.useState(false);

  const [personnelProps, setPersonnelProps] = React.useState(
    (oldPersonnelProps && { ...oldPersonnelProps }) || {
      documentIds: options.documents.filter((option) =>
        option.name.includes("(Önerilen)")
      ),
      equipmentIds: options.equipments.filter((option) =>
        option.name.includes("(Önerilen)")
      ),

      videoIds: options.videos.filter((option) =>
        option.name.includes("(Önerilen)")
      ),
    }
  );

  const init = useCallback(async () => {
    const options = await getCompanies();
    setVisitorOptions(options);
  }, [modal]);

  React.useEffect(() => {
    async function fetchData() {
      const id = personnelProps?.visitorCompanyId;
      if (id) {
        const visitors = await getVisitors(id);
        setVisitorsList(visitors);
      } else {
        setVisitorsList([]);
      }
    }
    fetchData();
  }, [visitorOptions]);

  React.useEffect(() => {
    init();
  }, [init]);

  const handleOpenModal = async (data, index) => {
    setModal(true);
  };

  const getCompanyFromId = (id) => {
    for (let i = 0; i < options.companies.length; i++) {
      if (options.companies[i].id === id) {
        return options.companies[i];
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <Grid container direction="row">
        <Grid item xs={11}>
          <Autocomplete //Visitor ID
            id="combo-box"
            options={visitorOptions.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            defaultValue={
              oldPersonnelProps
                ? getCompanyFromId(oldPersonnelProps.visitorCompanyId)
                : null
            }
            onChange={async (event, value) => {
              setPersonnelProps({
                ...personnelProps,
                visitorCompanyId: value?.id,
              });
              setVisitorsList(await getVisitors(value?.id));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={language.visits.visitorCompanyName}
                className={classes.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "0.2rem" }}>
          <div style={{ marginLeft: "40%" }}>
            <IconTooltipButton
              title={language.visits.addVisitorCompany}
              onClick={() => {
                handleOpenModal(null);
                setIsVisitor(false);
              }}
            >
              <AddBoxIcon />
            </IconTooltipButton>
          </div>
        </Grid>
      </Grid>

      <Grid container direction="row">
        <Grid item xs={11}>
          <Autocomplete //Visitor ID
            id="combo-box"
            options={visitorsList?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            defaultValue={
              oldPersonnelProps
                ? getPersonnelFromId(oldPersonnelProps.visitorId)
                : null
            }
            onChange={(event, value) => {
              setPersonnelProps({ ...personnelProps, visitorId: value?.id });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={language.visits.visitor}
                className={classes.textField}
              />
            )}
          />
        </Grid>
        <Grid item xs={1} style={{ marginTop: "0.2rem" }}>
          <div style={{ marginLeft: "40%" }}>
            <IconTooltipButton
              title={language.visits.addVisitor}
              onClick={() => {
                handleOpenModal(null);
                setIsVisitor(true);
              }}
              disabled={
                personnelProps.visitorCompanyId === undefined ||
                personnelProps.visitorCompanyId === null
              }
            >
              <AddBoxIcon />
            </IconTooltipButton>
          </div>
        </Grid>
      </Grid>
      <Autocomplete
        multiple
        id="combo-box"
        options={options.equipments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={personnelProps?.equipmentIds}
        onChange={(event, value) => {
          setPersonnelProps({
            ...personnelProps,
            equipmentIds: value,
          });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitTypes.equipments}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.documents?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={personnelProps?.documentIds}
        onChange={(event, value) => {
          setPersonnelProps({
            ...personnelProps,
            documentIds: value,
          });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitTypes.documents}
            className={classes.textField}
          />
        )}
      />
      <Autocomplete
        multiple
        id="combo-box"
        options={options.videos?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={personnelProps?.videoIds}
        onChange={(event, value) => {
          setPersonnelProps({
            ...personnelProps,
            videoIds: value,
          });
        }}
        renderOption={(option) => <span>{option.name}</span>}
        renderInput={(params) => (
          <TextField
            {...params}
            label={language.visitTypes.videos}
            className={classes.textField}
          />
        )}
      />
      <Modal
        className={classes.modal}
        open={modal}
        onClose={() => {
          setModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : isVisitor ? (
              <VisitorsModal
                modal={modal}
                setModal={setModal}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbar={setSnackbar}
                setSeverity={setSeverity}
                companyId={personnelProps.visitorCompanyId}
                loading={loading}
                setLoading={setLoading}
              />
            ) : (
              <CompanyModal
                modal={modal}
                setModal={setModal}
                loading={loading}
                setLoading={setLoading}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbar={setSnackbar}
                setSeverity={setSeverity}
              />
            )}
          </div>
        </Fade>
      </Modal>
      <div style={{ padding: 20 }}>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            if (oldPersonnelProps) {
              let temp = [...visitors];
              temp[index] = personnelProps;
              setVisitors(temp);
            } else {
              let temp = [...visitors];
              temp.push(personnelProps);
              setVisitors(temp);
            }
            handleCloseModal();
          }}
          className={classes.submit}
        >
          {oldPersonnelProps
            ? language.visits.updateVisitor
            : language.visits.addVisitor}
        </Button>
      </div>
    </FormControl>
  );
}
