import React, { useCallback } from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import Request from "../../../helpers/Request";
import moment from 'moment';
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import showSnackbar from "../../../components/Utils/showSnackbar";
import SessionHelper from "../../../helpers/SessionHelper";
import { CustomDatePicker } from '../../../components/Selectors/DatePickers';
import { TextArea, TextSelector } from '../../../components/Fields/TextFields';
import CustomFileDropzone from '../../../components/Files/CustomFileDropzone';
import { Button, CircularProgress, FormControl, makeStyles, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { isEqual } from "lodash";
import { taskStatuses } from '../../../assets/constants';
import jsonMap from '../../../components/Utils/jsonMap';

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: 'pre-wrap'
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  buttonContainer: {
    paddingBottom: 20, 
    textAlign: "center"
  }
}));

export default function ActionModal({
  modalLoading,
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  riskProps,
  options,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const user = SessionHelper.getUser();
  const roles = user?.roles;
  const isNewAction = React.useRef(true);
  const initValues = React.useRef(null);
  const defaultProps = {
    riskId: riskProps?.id,
    givenBy: SessionHelper.getUser().id,
    assignedTo: null,
    terminationDate: null,
    completionDate: moment().toISOString(true),
    approvedDate: moment().toISOString(true),
    status: "TODO",
    message: null
  }

  const [files, setFiles] = React.useState([]);
  const [dropdown, setDropdown] = React.useState(options.statuses || taskStatuses);
  const [actionProps, setActionProps] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  const [errors, setErrors] = React.useState({
    assignedTo: null,
    terminationDate: null,
    completionDate: null,
    approvedDate: null,
    status: null,
    message: null
  });

  const init = useCallback(async () => {
    if(modal && Object.keys(modal).length > 0) {
      setActionProps(modal);
      isNewAction.current = isEqual({...modal, approvedDate: null, completionDate: null}, 
        {...defaultProps, approvedDate: null, completionDate: null});
      initValues.current = modal;
      setFiles(modal.files);
    } else {
      setActionProps(defaultProps);
    } 
    if(initValues.current) {
      if(initValues.current.status === "TODO") {
        if(user.id === initValues.current.assignedTo) {
          setDropdown({
            TODO: "Başlanmadı",
            DONE: "Onay Bekliyor"
          });
        } else {
          setDisabled(true);
        }
      } else if(initValues.current.status === "DONE") { 
        if(roles.includes("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_YÖNETİCİSİ") ||
          roles.includes("ROLE_ISG_UZMANI") ||
          roles.includes("ROLE_OSGB_ISG_UZMANI") ||
          user.id === initValues.current.givenBy) {
          setDropdown({
            DONE: "Onay Bekliyor",
            APPROVED: "Onaylandı",
            REJECTED: "Reddedildi"
          });
        } else {
          setDisabled(true);
        }
      } else if(initValues.current.status === "APPROVED") { 
        setDisabled(true);
      } else if(initValues.current.status === "REJECTED") { 
      if(user.id === initValues.current.assignedTo) {
        setDropdown({
          DONE: "Onay Bekliyor",
          REJECTED: "Reddedildi"
        });
      } else {
        setDisabled(true);
      }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskProps, options, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      assignedTo: null,
      terminationDate: null,
      completionDate: null,
      approvedDate: null,
      status: null,
      message: null
    };
    if(!actionProps.assignedTo) {
      errors.assignedTo = errorMessage;
      error = true;
    }
    if(!actionProps.terminationDate) {
      errors.terminationDate = errorMessage;
      error = true;
    }
    if((actionProps.status === "DONE" || actionProps.status === "APPROVED") && !actionProps.completionDate) {
      errors.completionDate = errorMessage;
      error = true;
    }
    if(actionProps.status === "APPROVED" && !actionProps.approvedDate) {
      errors.approvedDate = errorMessage;
      error = true;
    }
    if(!actionProps.status) {
      errors.status = errorMessage;
      error = true;
    }
    if(actionProps.status !== "APPROVED" && initValues.status !== actionProps.status && (!actionProps.message || actionProps.message === "")) {
      errors.message = errorMessage;
      error = true;
    }
    setErrors(errors);

    if(!error) {
      isNewAction.current ? submitAction() : updateAction();
    }
  }

  const handleCloseModal = () => {
    setModal(false);
    setFiles([]);
    setTimeout(() => {
      setActionProps(defaultProps);
    }, 500);
  };

  const submitAction = async () => {
    setLoading(true);
    let formData = new FormData();
    if(files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    formData.append("request", new Blob([JSON.stringify(actionProps)], { type: "application/json" }));
    const resp = await Request("post", "/api/riskAnalysis/action", formData);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, null);
    handleCloseModal();
    setLoading(false);
  };

  const updateAction = async () => {
    setLoading(true);
    let formData = new FormData();
    if(files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    const body = {...actionProps}
    body["actionId"] = actionProps.id;
    delete body.id
    console.log(body);
    formData.append("request", new Blob([JSON.stringify(body)], { type: "application/json" }));
    const resp = await Request("patch", "/api/riskAnalysis/action", formData);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, null);
    handleCloseModal();
    setLoading(false);
  };

  const getUserFromId = (id) => {
    for (let i = 0; i < options.users.length; i++) {
      if (options.users[i].id === id) {
        return options.users[i];
      }
    }
    return null;
  };

  return (
    <>
      <TableEntryModal
        modal={modal}
        handleCloseModal={handleCloseModal}
        modalLoading={modalLoading}
      >
        <FormControl
          noValidate
          autoComplete="off"
          className={classes.formControl}
        >
          <Autocomplete //PERSONNEL
            id="combo-box"
            options={options.users?.sort(function (a, b) {
              return collator.compare(a.name, b.name);
            })}
            getOptionLabel={(option) => option.name}
            fullWidth={true}
            value={actionProps.assignedTo ? getUserFromId(actionProps.assignedTo) : null}
            onChange={async (event, value) => {
              setActionProps({ ...actionProps, assignedTo: value?.id });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                error={errors.assignedTo}
                helperText={errors.assignedTo}
                required
                label="Personel"
                className={classes.textField}
              />
            )}
          />
          <CustomDatePicker //TERMINATION DATE
            style={classes.dateField}
            label="Termin Tarih"
            error={errors.terminationDate}
            value={actionProps.terminationDate ? moment(actionProps.terminationDate) : null}
            shrink={actionProps.terminationDate}
            onChangeFunc={(value) =>
              setActionProps({...actionProps,
                terminationDate: moment(value).set("hour", 23).set("minute", 59).toISOString(true),
              })
            }
          />
          {(actionProps?.status === "DONE" || actionProps?.status === "APPROVED") &&
          <CustomDatePicker //COMPLETION DATE
            style={classes.dateField}
            label="Tamamlanma Tarihi"
            error={errors.completionDate}
            value={actionProps.completionDate ? moment(actionProps.completionDate) : null}
            shrink={actionProps.completionDate}
            onChangeFunc={(value) =>
              setActionProps({...actionProps,
                completionDate: moment(value).toISOString(true),
              })
            }
          />}
          {actionProps?.status === "APPROVED" &&
          <CustomDatePicker //APPROVED DATE
            style={classes.dateField}
            label="Onaylanma Tarihi"
            error={errors.approvedDate}
            value={actionProps.approvedDate ? moment(actionProps.approvedDate) : null}
            shrink={actionProps.approvedDate}
            onChangeFunc={(value) =>
              setActionProps({...actionProps,
                approvedDate: moment(value).toISOString(true),
              })
            }
          />}
          <TextArea //DESCRIPTION
            label={"Açıklama"}
            value={actionProps.message}
            error={errors.message}
            required={actionProps?.status !== "APPROVED"}
            shrink={actionProps.message && actionProps.message !== ""}
            style={classes.textField}
            onChangeFunc={(value) => setActionProps({...actionProps, message: value.target.value})}
          />
          {!isNewAction.current &&
          <TextSelector //STATUS
            label={"Durumu"}
            required
            error={errors.status}
            disabled={disabled}
            value={actionProps?.status}
            shrink={actionProps?.status}
            style={classes.textField}
            options={jsonMap(false, dropdown)}
            onChangeFunc={(value) => {
              if(value.target.value === "DONE") {
                setActionProps({...actionProps,
                  completionDate: moment().toISOString(true),
                  approvedDate: null,
                  status: value.target.value
                });
              } else if(value.target.value === "APPROVED") {
                setActionProps({...actionProps,
                  completionDate: moment().toISOString(true),
                  approvedDate: moment().toISOString(true),
                  status: value.target.value
                });
              } else {
                setActionProps({ ...actionProps, 
                  completionDate: null,
                  approvedDate: null,
                  status: value.target.value 
                });
              }
            }}
          />}
          <CustomFileDropzone
            files={files}
            setFiles={(value) => {
              setFiles(value);
            }}
            fileLimit={200}
            dropzoneText={"Dosya yüklemek için sürükleyin ya da buraya tıklayın."}
          />
          <div className={classes.buttonContainer}>
            {loading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={onButtonClick}
                className={classes.submit}
              >
                {isNewAction.current ? "OLUŞTUR" : "GÜNCELLE"}
              </Button>
            )}
          </div>
        </FormControl>
      </TableEntryModal>
    </>
  );
}