import React, { useCallback } from "react";
import "../../../../App.css";
import SessionHelper from "../../../../helpers/SessionHelper";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import FetchData from "./FetchData";
import { getAuthorizationForPage } from "../../../../helpers/AuthorizationHelper";
import CustomSnackbar from "../../../../components/Snackbar/Snackbar";
import Table from "../../../../components/Table/Table";
import TableBackdrop from "../../../../components/Table/TableBackdrop";
import GetOptions from "./GetOptions";
import GetFilterFields from "./CrewNumbersFilters";
import CrewNumbersModal from "./CrewNumbersModal";
import moment from "moment";
import { getDepartments } from "./GetOptions";
import { days } from "../../../../assets/constants";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import showSnackbar from "../../../../components/Utils/showSnackbar";
import SaveDialog from "../../../../components/Dialog/SaveDialog";
import DeleteDialog from "../../../../components/Dialog/DeleteDialog";
import Request from "../../../../helpers/Request";

export default function CrewNumbersTable() {
  const language = LanguageHelper.getLanguage();
  const tableRef = React.useRef();
  const user = SessionHelper.getUser();
  const roles = user.roles;
  const authorization = getAuthorizationForPage(roles, "crewNumbers");

  const [columns, setColumns] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [modalLoading, setModalLoading] = React.useState(true);
  const [numOfEntries, setNumOfEntries] = React.useState(0);
  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [options, setOptions] = React.useState({});
  const [editModal, setEditModal] = React.useState(false);
  const [selectedCrewNumber, setSelectedCrewNumber] = React.useState(null);
  const [dialog, setDialog] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(false);
  const [requestLoading, setRequestLoading] = React.useState(false);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [filterProps, setFilterProps] = React.useState({
    filterCompany: null,
    filterDepartment: null,
    recordWeek: moment(new Date()).toISOString(),
  });

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setColumns([
      { title: "No", field: "id" },
      { title: "Şirket", field: "companyName" },
      { title: "Bölüm", field: "departmentName" },
      { title: "Toolbox Eğitim Günü", field: "toolboxDay", lookup: days },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(1)
          .format("DD-MM-YYYY")} Pazartesi`,
        field: "d1",
      },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(2)
          .format("DD-MM-YYYY")} Salı`,
        field: "d2",
      },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(3)
          .format("DD-MM-YYYY")} Çarşamba`,
        field: "d3",
      },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(4)
          .format("DD-MM-YYYY")} Perşembe`,
        field: "d4",
      },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(5)
          .format("DD-MM-YYYY")} Cuma`,
        field: "d5",
      },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(6)
          .format("DD-MM-YYYY")} Cumartesi`,
        field: "d6",
      },
      {
        title: `${new moment(filterProps.recordWeek)
          .isoWeekday(7)
          .format("DD-MM-YYYY")} Pazar`,
        field: "d7",
      },
    ]);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, roles, filterProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const getFromName = (options, name) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].name === name) {
        return options[i];
      }
    }
    return null;
  };

  const handleOpenSaveDialog = (data) => {
    setSelectedCrewNumber(data);
    setDialog(true);
  };

  const handleCloseSaveDialog = () => {
    setSelectedCrewNumber(null);
    setDialog(false);
  };

  const saveCrewNumber = async () => {
    setRequestLoading(true);
    selectedCrewNumber.saved = true;
    const resp = await Request(
      "patch",
      "/api/crew-numbers/" + selectedCrewNumber?.id,
      selectedCrewNumber
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.saved,
      tableRef
    );
    handleCloseSaveDialog();
    setRequestLoading(false);
  };

  const handleOpenModal = async (data) => {
    data && setSelectedCrewNumber(data);
    setModalLoading(true);
    setEditModal(true);
    let opts = { ...options };
    let companyId;
    if (data && data.companyName) {
      companyId = getFromName(opts.companies, data.companyName);
    }
    companyId
      ? (opts.departments = await getDepartments(companyId.id))
      : (opts.departments = await getDepartments(user.companyId));
    setOptions(opts);
    setModalLoading(false);
  };

  const handleOpenDeleteDialog = (data) => {
    setSelectedCrewNumber(data);
    setDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setSelectedCrewNumber(null);
    setDeleteDialog(false);
  };

  const deleteNumber = async () => {
    setRequestLoading(true);
    const resp = await Request(
      "delete",
      "/api/crew-numbers/" + selectedCrewNumber?.id
    );
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.deleted,
      tableRef
    );
    handleCloseDeleteDialog();
    setRequestLoading(false);
  };

  const RenderFilters = () => {
    return (
      <div style={{ marginBottom: 10 }}>
        <GetFilterFields
          options={options}
          tableRef={tableRef}
          setOptions={setOptions}
          filterProps={filterProps}
          filtersOpen={filtersOpen}
          clearFilters={clearFilters}
          setFilterProps={setFilterProps}
          setFiltersOpen={setFiltersOpen}
        />
      </div>
    );
  };

  const clearFilters = () => {
    setFilterProps({
      filterCompany: null,
      filterDepartment: null,
      recordWeek: moment(new Date()).toISOString(),
    });
  };

  return (
    <>
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <DeleteDialog
        remove={deleteNumber}
        open={deleteDialog}
        loading={requestLoading}
        close={handleCloseDeleteDialog}
      />
      <SaveDialog
        save={selectedCrewNumber && saveCrewNumber}
        open={dialog}
        loading={requestLoading}
        close={handleCloseSaveDialog}
      />
      <CrewNumbersModal
        modal={editModal}
        setModal={setEditModal}
        setSnackbarMessage={setSnackbarMessage}
        setSnackbar={setSnackbar}
        setSeverity={setSeverity}
        tableRef={tableRef}
        oldCrewNumberProps={selectedCrewNumber}
        setOldCrewNumberProps={setSelectedCrewNumber}
        options={options}
        setOptions={setOptions}
        modalLoading={modalLoading}
      />
      <TableBackdrop backdropLoading={loading} />
      <RenderFilters />
      <Button
        color="primary"
        size="large"
        startIcon={<ArrowBack />}
        onClick={() => {
          setFilterProps({
            ...filterProps,
            recordWeek: moment(filterProps.recordWeek)
              .subtract(7, "days")
              .toISOString(),
          });
          tableRef.current && tableRef.current.onQueryChange();
        }}
      >
        Önceki Hafta
      </Button>
      <Button
        color="primary"
        size="large"
        endIcon={<ArrowForward />}
        onClick={() => {
          setFilterProps({
            ...filterProps,
            recordWeek: moment(filterProps.recordWeek)
              .add(7, "days")
              .toISOString(),
          });
          tableRef.current && tableRef.current.onQueryChange();
        }}
      >
        Sonraki Hafta
      </Button>
      <Table
        tableName={"Ekip Sayısı"}
        authName={"crewNumbers"}
        columns={columns}
        tableRef={tableRef}
        setSnackbar={setSnackbar}
        setSnackbarMessage={setSnackbarMessage}
        isEditable={(user, rowData) =>
          (rowData.savedAt && roles.includes("ROLE_ISG_UZMANI")) ||
          (!rowData.savedAt && authorization.edit)
        }
        isEditHidden={!authorization.edit}
        isDeletable={(rowData) => authorization?.delete}
        handleOpenModal={handleOpenModal}
        handleOpenSaveModal={handleOpenSaveDialog}
        handleOpenDeleteModal={handleOpenDeleteDialog}
        disableCreate={!authorization.create}
        setSeverity={setSeverity}
        fetchData={FetchData}
        filters={filterProps}
        numOfEntries={numOfEntries}
        setNumOfEntries={setNumOfEntries}
        isSaveable={(user, rowData) => !rowData.savedAt && authorization.delete}
      />
    </>
  );
}
