import React, { useCallback } from 'react';
import { ResponsiveContainer } from 'recharts';
import SessionHelper from '../../../helpers/SessionHelper';
import LanguageHelper from '../../../helpers/LanguageHelper';
import { Button, CircularProgress, Divider, makeStyles } from '@material-ui/core';
import Request from '../../../helpers/Request';
import ListOfFiles from '../../../components/Files/ListOfFiles';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import ActionData from './ActionData';
import moment from 'moment';
import RejectionModal from './RejectionModal';
import TableBackdrop from '../../../components/Table/TableBackdrop';
import CustomSnackbar from '../../../components/Snackbar/Snackbar';

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: 'center',
    color: 'white',
    backgroundColor: '#2D3446',
  },
  approveReportButton: {
    cursor: "pointer", 
    margin: 20,
    marginTop: 30
  },
  approveReportLabel: {
    cursor: "pointer"
  },
  divider: {
    margin: 10
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3)
  },submit: {
    marginTop: "10px"
  },
  formControl: {
    width: 600
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px"
  },
  textField: {
    marginBottom: "20px"
  },
  redText: {
    color: "red", 
    fontSize: 16
  }
}));

export default function ActionDetails({id, auth, rowData, tableRef}) {
  const classes = useStyles();
  const user = SessionHelper.getUser();
  const language = LanguageHelper.getLanguage();
  const roles = user.roles;
  const authorization = id ? getAuthorizationForPage(roles, "actionTable") : auth;

  const [snackbar, setSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("info");
  const [data, setData] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [rejection, setRejection] = React.useState("");
  const [backdropLoading, setBackdropLoading] = React.useState(false);

  const getAction = useCallback(async (id) => {
    const resp = await Request("get", "/api/nearMiss/action/" + id);
    console.log(resp);
    return resp.data;
  }, [])

  const init = useCallback(async () => {
    setData(id ? await getAction(id) : rowData);
  }, [id, rowData, getAction]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  
  const approveReport = async (actionId) => {
    setBackdropLoading(true);
    const body = {
      "status": "APPROVED",
      "id": actionId
    };
    const resp = await Request('patch', '/api/nearMiss/action/', body);
    console.log(resp);
    if(resp.status !== 200) {
      if(resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      tableRef && tableRef.current && tableRef.current.onQueryChange();
      setSeverity("success");
      setSnackbarMessage(language.etc.approved);
      setSnackbar(true);
      !tableRef && window.location.reload();
    }
    setBackdropLoading(false);
  }

  const rejectReport = async (actionId) => {
    setBackdropLoading(true);
    const body = {
      "status": "REJECTED",
      "id": actionId,
      "message": rejection
    };
    const resp = await Request('patch', '/api/nearMiss/action/', body);
    console.log(resp);
    if(resp.status !== 200) {
      if(resp.data && resp.data.message) {
        setSeverity("error");
        setSnackbarMessage(resp.data.message);
        setSnackbar(true);
      } else {
        setSeverity("error");
        setSnackbarMessage(language.login.unexpectedError);
        setSnackbar(true);
      }
    } else {
      tableRef && tableRef.current && tableRef.current.onQueryChange();
      setSeverity("success");
      setSnackbarMessage(language.etc.rejected);
      setSnackbar(true);
      !tableRef && window.location.reload();
    }
    handleCloseModal();
    setBackdropLoading(false);
  }

  const RenderDescription = ({list}) => {
    let messageString = "Yok\n";
    if(list.length > 0) {
      const messages = list;
      if(messages.length > 0) {messageString = ""}
      for(let i = 0; i < messages.length; i++) {
        messages[i].noteTime ? 
        messageString += `${messages[i].user.fName} ${messages[i].user.lName}: ${messages[i].note} (${(new moment(messages[i].noteTime)).format("HH:mm DD-MM-YYYY")})\n`
        : 
        messageString += `${messages[i].user.fName} ${messages[i].user.lName}: ${messages[i].note} (${(new moment(data.completionDate)).format("HH:mm DD-MM-YYYY")})\n`;
      }
    }
    return(
      <>
        <label>{"Açıklama"}<br /></label>
        {data.messages ?
          <p style={{fontSize: 16, whiteSpace: "pre-wrap", overflow: "auto", maxHeight: 170}}>{messageString}</p>
          :
          <p className={classes.redText}>{language.tableDetails.noText}<br /></p>
        }
      </>
    )
  }
  
  const RenderAttachments = () => {
    return(
      <>
        <label>{"Dosyalar"}<br /></label>
        {data && data.attachments?.length > 0
          ? 
          <>
            <ListOfFiles fileKeys={data.attachments}/>
          </>
          : 
          <p className={classes.redText}>{"Dosya Yok"}<br /></p>}  
      </>
    )
  }

  const RenderButtons = () => {
    return(
      <>
        <Divider className={classes.divider}/>
        {data && 
        ((data.status === "Onay Bekliyor" || data.status === "DONE") || 
          (data.status === "Onaylandı" || data.status === "APPROVED"))
          ? <></>
          : <p className={classes.redText}>{"Tamamlanmamış aksiyonlar onaylanamaz."}<br /></p>}
        
        {(data && 
        (data.status === "Onaylandı" || data.status === "APPROVED"))
          ? <p className={classes.redText}>{"Onaylanmış aksiyonlar onaylanamaz/reddedilemez."}</p>
          : <></>}

        {data && 
        (data.status === "Onay Bekliyor" || data.status === "DONE") && 
        <>
          <Button variant="contained" onClick={() => approveReport(data.id)} className={classes.approveReportButton} >
            <label className={classes.approveReportLabel}>{"Aksiyonu Onayla"}</label>
          </Button>
          <Button variant="contained" onClick={() => handleClick()} className={classes.approveReportButton} >
            <label className={classes.approveReportLabel}>{"Aksiyonu Reddet"}</label>
          </Button>
        </>}
      </>
    )
  }

  return (
    <>
      <TableBackdrop backdropLoading={backdropLoading} />
      <CustomSnackbar
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        snackbarMessage={snackbarMessage}
        severity={severity}
      />
      <ResponsiveContainer>
        {data 
          ?
          <>
            {data.status !== 404 ?
              <div className={classes.details}>
                <RejectionModal
                  open={open}
                  handleCloseModal={handleCloseModal}
                  rejection={rejection}
                  setRejection={setRejection}
                  rejectReport={rejectReport}
                  data={data}
                />
                {id && <ActionData data={data} setSnackbar={setSnackbar} setSnackbarMessage={setSnackbarMessage} setSeverity={setSeverity}/>}
                <RenderDescription list={data.messages}/>
                <Divider className={classes.divider}/>
                <RenderAttachments/>
                {authorization.approve && <RenderButtons/>}
              </div>
              :
              <>AKSİYON BULUNAMADI</>
            }
          </>
          :
          <CircularProgress color="secondary"/>
        }
      </ResponsiveContainer>
    </>
  );
}