import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Request from "../../../helpers/Request";
import ModalForm from './ModalForm';
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: "none",
  },
  paper: {
    /* height: 500,
    overflow: "auto", */
    outline: "none",
    backgroundColor: theme.palette.background.paper,
    border: "none",
    borderRadius: "15px",
    padding: theme.spacing(2, 4, 3)
  },
  submit: {
    marginTop: "10px"
  },
  formControl: {
    width: 600
  },
  addButton: {
    fontSize: "20px",
    marginRight: "-10px"
  },
  textField: {
    marginBottom: "20px"
  },
  list: {
    maxHeight: 180,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  roles: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  addRoleButton: {
    paddingLeft: 20,
    marginTop: "0.5vw",
    marginBottom: "auto"
  },
  textFieldRoles: {
    marginTop: "-30px",
    marginBottom: "40px",
    marginLeft: "25%",
    marginRight: "auto",
    width: "50%"
  },
}));

/**
 * Modal component used in the user table for adding/editing users.
 * @param {modal: boolean, setModal: func, setSnackbarMessage: func, setSnackbar: func, tableRef: obj, oldUserProps: obj, opts: obj[]} props of the user modal 
 */
export default function UserModal({modal, setModal, setSnackbarMessage, setSnackbar, tableRef, oldUserProps, opts, setSeverity}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  
  const [userToAdd, setUserToAdd] = React.useState(null);
  const [options, setOptions] = React.useState(opts);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    setOptions(opts);
  }, [setOptions, opts]);
  React.useEffect(() => {
    init();
  }, [init, modal]);

  /**
   * Closes the modal and flushes the states.
   */
  const handleCloseModal = () => {
    setModal(false);
    setUserToAdd(null);
  }

  /**
    * Request to replace the old data with the new data.
    */
  const updateUser = async () => {
    setLoading(true);
    const userData = await Request("get", "/api/users/" + userToAdd);
    const body = userData.data;
    for(let index in body.roles) {
      body.roles[index] = body.roles[index].name;
    }
    body.roles.push("ROLE_YAŞAM_GÜVENLİĞİ_KOMİTESİ_UYESİ");
    let props = {
      roles: body.roles,
      id: body.id
    }
    if(userData.status === 200) {
      const resp = await Request("patch", "/api/users", props);
      console.log(resp);
      showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    } else {
      setSeverity("error");
      setSnackbarMessage(language.login.unexpectedError);
      setSnackbar(true);
    }
    handleCloseModal();
    setLoading(false);
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm 
              userToAdd={userToAdd}
              setUserToAdd={setUserToAdd}
              options={options}
              updateUser={updateUser}
              setOptions={setOptions}
              oldUserProps={oldUserProps}
              loading={loading}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}