import Request from "../../../../helpers/Request";
import SessionHelper from "../../../../helpers/SessionHelper";

export const getDepartments = async (companyId) => {
  let arr = [];
  if (companyId) {
    const resp = await Request(
      "get",
      "/api/departments/belongs-to-company/" + companyId + "/basic-info"
    );
    const data = resp.data;
    for (let i = 0; i < data.length; i++) {
      let dept = {};
      dept["name"] = data[i].name;
      dept["id"] = data[i].id;
      arr.push(dept);
    }
  }
  return arr;
};

export const getCompanies = async () => {
  const resp = await Request("get", "/api/companies/list/from-authority");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let dept = {};
    dept["name"] = data[i].name;
    dept["id"] = data[i].id;
    arr.push(dept);
  }
  return arr;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [companies, departments] = await Promise.all([
    getCompanies(),
    getDepartments(SessionHelper.getUser().companyId),
  ]);
  const options = { departments, companies };
  return options;
}
