import Request from "../../../../helpers/Request";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      user: filterProps.user,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      } else if (query.orderBy.field === "companyName") {
        params.sort = "user.company.name," + query.orderDirection;
      } else if (query.orderBy.field === "departmentName") {
        params.sort = "user.department.name," + query.orderDirection;
      } else if (query.orderBy.field === "personnelName") {
        params.sort = "user.fName," + query.orderDirection;
      } else if (query.orderBy.field === "personnelSurname") {
        params.sort = "user.lName," + query.orderDirection;
      } else if (query.orderBy.field === "examDate") {
        params.sort = "examinationTime," + query.orderDirection;
      } else if (query.orderBy.field === "examType") {
        params.sort = "examinationType," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/examination/isg-card",
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          companyName: data[i].companyName,
          departmentName: data[i].departmentName,
          examType: data[i].examinationType,
          examDate: data[i].examinationDate,
          workAtNight:
            data[i].workStatus?.workAtNight !== undefined
              ? data[i].workStatus?.workAtNight
              : false,
          workAtHeight:
            data[i].workStatus?.workAtHeight !== undefined
              ? data[i].workStatus?.workAtHeight
              : false,
          workInShift:
            data[i].workStatus?.workInShift !== undefined
              ? data[i].workStatus?.workInShift
              : false,
          workInDangerousWorkplace:
            data[i].workStatus?.workInDangerousWorkplace !== undefined
              ? data[i].workStatus?.workInDangerousWorkplace
              : false,
          workInVeryDangerousWorkplace:
            data[i].workStatus?.workInVeryDangerousWorkplace !== undefined
              ? data[i].workStatus?.workInVeryDangerousWorkplace
              : false,
          workWithScreenedVehicle:
            data[i].workStatus?.workWithScreenedVehicle !== undefined
              ? data[i].workStatus?.workWithScreenedVehicle
              : false,
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
