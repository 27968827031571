import React from "react";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Grid } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  leftHeaderContainer: {
    float: "left",
    marginLeft: "10px",
  },
  rightHeaderContainer: {
    marginRight: "10px",
  },
  buttonContainer: {
    marginLeft: "10px",
  },
}));

/**
 * Renders the saving part of the details panel.
 * Updated question is saved
 * @param { change: boolean,  putEditedTemplate: func} param props of the header component
 */
export default function Save({ change,putEditedTemplate }) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      {change && (
        <Grid
          container
          justifyContent="flex-end"
          className={classes.rightHeaderContainer}
        >
          <IconTooltipButton
            title={language.form.saveChanges}
            onClick={() => putEditedTemplate()}
          >
            {language.form.save}
          </IconTooltipButton>
        </Grid>
      )}
    </div>
  );
}
