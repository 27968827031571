import Request from '../../../helpers/Request';
import LanguageHelper from '../../../helpers/LanguageHelper';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "id,desc",

      //FILTERS
      filterCompanyId: filterProps.filterCompany?.id,
      filterDepartmentId: filterProps.filterDepartment?.id,
      filterStatus: filterProps.filterStatus,
      filterPlannedStart: filterProps.filterPlannedStart,
      filterPlannedEnd: filterProps.filterPlannedEnd,
      filterCompletionStart: filterProps.filterCompletionStart,
      filterCompletionEnd: filterProps.filterCompletionEnd
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") { params.sort = "id," + query.orderDirection; }
      else if (query.orderBy.field === "customId") { params.sort = "customId," + query.orderDirection; }
      else if (query.orderBy.field === "companyName") { params.sort = "company.name," + query.orderDirection; }
      else if (query.orderBy.field === "departmentName") { params.sort = "department.name," + query.orderDirection; }
      else if (query.orderBy.field === "cityName") { params.sort = "city.name," + query.orderDirection; }
      else if (query.orderBy.field === "townName") { params.sort = "town.name," + query.orderDirection; }
      else if (query.orderBy.field === "trainingType") { params.sort = "trainingType," + query.orderDirection; }
      else if (query.orderBy.field === "topics") { params.sort = "topics," + query.orderDirection; }
      else if (query.orderBy.field === "plannedDate") { params.sort = "plannedDate," + query.orderDirection; }
      else if (query.orderBy.field === "completionDate") { params.sort = "completionDate," + query.orderDirection; }
      else if (query.orderBy.field === "time") { params.sort = "time," + query.orderDirection; }
      else if (query.orderBy.field === "status") { params.sort = "status," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/isgCommittee", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {    
      let otherData = resp.data;
      let data = otherData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const props = {
          id: data[i]?.id,
          customId: data[i]?.customId,
          company: data[i]?.company,
          companyId: data[i]?.company?.id,
          companyName: data[i]?.company?.name,
          department: data[i]?.department,
          departmentId: data[i]?.department?.id,
          departmentName: data[i]?.department?.name,
          plannedDate: data[i]?.plannedDate,
          completionDate: data[i]?.completionDate,
          status: data[i]?.status,
          decisions: data[i]?.decisions,
          participants: formatLists(data[i]?.participants),
          notAttendedUsers: formatLists(data[i]?.notAttendedUsers),
          participantObjects: formatParticipants(data[i]?.participants),
        };
        props.files = [];
        for(let file of data[i]?.files) {
          props.files.push(new File([file?.fileName], file?.fileName, { type: "text/plain" }));
        }
        temp.push(props);
      }
      setNumOfEntries(otherData.totalElements);
      resolve({
        data: temp,
        page: otherData.pageable.pageNumber,
        totalCount: otherData.totalElements,
      })
    }
  })
  return data;
}

const formatLists = (lst) => {
  const array = [];
  for(let prt of lst) {
    array.push(prt.id);
  }
  return array;
}

const formatParticipants = (lst) => {
  const array = [];
  for(let prt of lst) {
    array.push({
      name: prt.fName + " " + prt.lName,
      id: prt.id
    });
  }
  return array;
}