import React, { useCallback } from "react";
import ModalForm from './ModalForm';
import TableEntryModal from "../../../../components/Modal/TableEntryModal";
import moment from "moment";

export default function JobRiskAnalysisModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldCrewNumberProps,
  setOldCrewNumberProps,
  options,
  setOptions,
  modalLoading
}) {
  const defaultProps = {
    weekStart: moment(new Date()).isoWeekday(1).toISOString(),
    departmentId: null,
    toolboxDay: 1,
    d1: "0",
    d2: "0",
    d3: "0",
    d4: "0",
    d5: "0",
    d6: "0",
    d7: "0",
  }

  const [crewNumberProps, setCrewNumberProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldCrewNumberProps) {
      setCrewNumberProps({
        weekStart: oldCrewNumberProps.weekStart || null,
        toolboxDay: oldCrewNumberProps.toolboxDay || null,
        departmentId: oldCrewNumberProps.departmentId || null,
        d1: oldCrewNumberProps.d1 || null,
        d2: oldCrewNumberProps.d2 || null,
        d3: oldCrewNumberProps.d3 || null,
        d4: oldCrewNumberProps.d4 || null,
        d5: oldCrewNumberProps.d5 || null,
        d6: oldCrewNumberProps.d6 || null,
        d7: oldCrewNumberProps.d7 || null,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldCrewNumberProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setCrewNumberProps(defaultProps);
      setOldCrewNumberProps(null);
    }, 300);
  };

  return (
    <>
      <TableEntryModal modal={modal} handleCloseModal={handleCloseModal} modalLoading={modalLoading}>
        <ModalForm
          crewNumberProps={crewNumberProps}
          setCrewNumberProps={setCrewNumberProps}
          label={oldCrewNumberProps ? "GÜNCELLE" : "OLUŞTUR"}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
          handleCloseModal={handleCloseModal}
          oldCrewNumberProps={oldCrewNumberProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </>
  );
}