import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { CircularProgress, Modal } from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Request from "../../../helpers/Request";
import ModalForm from './ModalForm';
import { modalStyles } from '../../../assets/styles/tableContainer';
import showSnackbar from "../../../components/Utils/showSnackbar";

const useStyles = makeStyles((theme) => (modalStyles(theme)));

export default function NewDepartmentModal({modal, setModal, setSnackbarMessage, setSnackbar, setSeverity, tableRef, oldDepartmentProps, opts, modalLoading}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  
  const [newDepartmentProps, setNewDepartmentProps] = React.useState({});
  const [options, setOptions] = React.useState(opts);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    let parentDepartmentIds = [] 
    if(oldDepartmentProps) {
      for(let prop in oldDepartmentProps.belongingDepartments) {
        parentDepartmentIds.push(oldDepartmentProps.belongingDepartments[prop].id);
      }
    }
    let fields = [] 
    if(oldDepartmentProps) {
      for(let prop in oldDepartmentProps.fieldsOfActivity) {
        fields.push(oldDepartmentProps.fieldsOfActivity[prop].id);
      }
    }
    let unsuitUsers = []
    if(oldDepartmentProps) {
      for(let index in oldDepartmentProps.nonConformityUsers) {
        unsuitUsers.push({
          townId: oldDepartmentProps.nonConformityUsers[index]?.town?.id, 
          userId: oldDepartmentProps.nonConformityUsers[index]?.user?.id
        });
      }
    }
    let isgUsers = [] 
    if(oldDepartmentProps) {
      for(let prop in oldDepartmentProps.isgUsers) {
        isgUsers.push(oldDepartmentProps.isgUsers[prop].id);
      }
    }
    setNewDepartmentProps({
      name: oldDepartmentProps ? oldDepartmentProps.name : null,
      sgkno: oldDepartmentProps ? oldDepartmentProps.sgkno : null,
      companyId: oldDepartmentProps ? oldDepartmentProps.company : null,
      cityId: oldDepartmentProps ? oldDepartmentProps.cityId : null,
      townId: oldDepartmentProps ? oldDepartmentProps.townId : null,
      managerId: oldDepartmentProps ? oldDepartmentProps.manager : null,
      isgUserIds: isgUsers,
      nonconformityUserRequest: unsuitUsers,
      fieldOfActivity: oldDepartmentProps ? oldDepartmentProps.fieldOfActivity : null,
      fieldsOfActivity: fields,
      parentDepartmentIds: parentDepartmentIds,
      riskClass: oldDepartmentProps ? oldDepartmentProps.riskClass : null
    });
    setOptions(opts);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOptions, opts, oldDepartmentProps, modal]);
  React.useEffect(() => {
    init();
  }, [init, modal]);

  const handleCloseModal = () => {
    setModal(false);
    setNewDepartmentProps({
      name: null,
      sgkno: null,
      companyId: null,
      cityId: null,
      townId: null,
      managerId: null,
      nonconformityUserRequest: null,
      fieldOfActivity: null,
      isgUserIds: [],
      fieldsOfActivity: [],
      parentDepartmentIds: [],
      riskClass:  null
    });
    let newOpts = opts;
    newOpts.towns = [];
    newOpts.departments = null;
    newOpts.managers = [];
    newOpts.isgUsers = [];
    newOpts.unsuitUser = [];
    setOptions(newOpts);
  }

  const submitNewDepartment = async () => {
    setLoading(true);
    const resp = await Request("post", '/api/departments', newDepartmentProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.added, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  const updateDepartment = async () => {
    setLoading(true);
    const resp = await Request("patch", '/api/departments/' + oldDepartmentProps.id, newDepartmentProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  }

  return (
    <>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={modal}>
          <div className={classes.paper}>
            {modalLoading 
              ?
              <CircularProgress color="secondary"/> 
              :
              <>
                <ModalForm 
                  oldDepartmentProps={oldDepartmentProps}
                  options={options}
                  newDepartmentProps={newDepartmentProps}
                  setNewDepartmentProps={setNewDepartmentProps}
                  updateDepartment={updateDepartment}
                  submitNewDepartment={submitNewDepartment}
                  setOptions={setOptions}
                  loading={loading}
                />
              </>
            }
          </div>
        </Fade>
      </Modal>
    </>
  );
}