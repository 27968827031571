import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core";
import SessionHelper from "../../../helpers/SessionHelper";
import TableEntryModal from "../../../components/Modal/TableEntryModal";
import Request from "../../../helpers/Request";
import showSnackbar from "../../../components/Utils/showSnackbar";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { TextArea } from "../../../components/Fields/TextFields";
import MembersList from "./MembersList";
import { getDepartments, getUsers } from "./GetOptions";
import { Autocomplete } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
  activateButton: {
    width: "40%",
    margin: "0 auto",
    marginBottom: 20,
  },
}));

const propsDefault = {
  crewName: null,
  companyId: SessionHelper.getUser()?.companyId,
  departmentId: SessionHelper.getUser()?.deparment?.id,
  userIds: [],
  kkdTaskId: null,
};

export default function CrewModal({
  crewProps,
  setCrewProps,
  options,
  setOptions,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef,
}) {
  const user = SessionHelper.getUser();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const isNewCrew = crewProps && Object.keys(crewProps).length === 0;
  const language = LanguageHelper.getLanguage();

  const [props, setProps] = React.useState(propsDefault);
  const [loading, setLoading] = React.useState(false);
  const [modalLoading, setModalLoading] = React.useState(false);

  const [errors, setErrors] = React.useState({
    crewName: null,
    companyId: null,
    departmentId: null,
  });

  const init = useCallback(async () => {
    setModalLoading(true);
    if (!isNewCrew && crewProps) {
      setProps({ ...crewProps });
    }
    setModalLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, crewProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      crewName: null,
      departmentId: null,
      companyName: null,
    };
    if (!props.crewName) {
      errors.crewName = errorMessage;
      error = true;
    }
    if (!props.companyName) {
      errors.companyName = errorMessage;
      error = true;
    }
    if (!props.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    setErrors(errors);
    if (!error) {
      isNewCrew ? submitNewCrew() : updateCrew();
    }
  };

  const submitNewCrew = async () => {
    setLoading(true);
    props.userIds = props?.memberObjects.map((mbr) => mbr.id);
    delete props.memberObjects;
    const resp = await Request("post", "/api/crews", props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const updateCrew = async () => {
    setLoading(true);
    props.userIds = props?.memberObjects.map((mbr) => mbr.id);
    delete props.memberObjects;
    const resp = await Request("patch", "/api/crews/" + props.id, props);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.updated,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  const handleCloseModal = () => {
    setProps(propsDefault);
    setCrewProps(null);
    setErrors({
      crewName: null,
      departmentId: null,
      companyName: null,
    });
  };

  const getFromName = (name, options) => {
    if (name && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].name === name) {
          return options[i];
        }
      }
    }
    return null;
  };

  const getFromId = (id, options) => {
    if (id && options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].id === id) {
          return options[i];
        }
      }
    }
    return null;
  };

  return (
    <TableEntryModal
      modal={crewProps}
      handleCloseModal={handleCloseModal}
      modalLoading={modalLoading}
    >
      <FormControl required autoComplete="off" className={classes.formControl}>
        <TextArea //NAME
          required
          error={errors.crewName}
          label={"Ekip İsmi"}
          value={props?.crewName}
          onChangeFunc={(value) => {
            setProps({ ...props, crewName: value.target.value });
            errors.crewName && setErrors({ ...errors, crewName: null });
          }}
          style={classes.textField}
        />
        <Autocomplete //COMPANY
          id="combo-box"
          options={options.companies?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          value={
            props.companyName
              ? getFromName(props.companyName, options.companies)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, companyName: null });
              setOptions({
                ...options,
                departments: [],
                users: [],
              });
            } else {
              setProps({ ...props, companyName: value?.name });
              const [depts, users] = await Promise.all([
                getDepartments(value?.id), getUsers(value?.id)
              ]);
              setOptions({
                ...options,
                departments: depts,
                users: users,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors.companyName}
              helperText={errors.companyName}
              label="Şirket"
              className={classes.textField}
            />
          )}
        />
        <Autocomplete //DEPARTMENT
          id="combo-box"
          options={options.departments?.sort(function (a, b) {
            return collator.compare(a.name, b.name);
          })}
          getOptionLabel={(option) => option.name}
          fullWidth={true}
          disabled={!props.companyName}
          value={
            props.departmentName
              ? getFromName(props.departmentName, options.departments)
              : null
          }
          onChange={async (event, value) => {
            if (value === null) {
              setProps({ ...props, departmentId: null, departmentName: null });
            } else {
              setProps({
                ...props,
                departmentId: value?.id,
                departmentName: value?.name,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors.departmentId}
              helperText={errors.departmentId}
              label="Bölüm"
              className={classes.textField}
            />
          )}
        />
        {options.users && (
          <MembersList props={props} setProps={setProps} options={options} />
        )}
        <Autocomplete
          id="combo-box"
          options={options.kkdTasks?.sort(function (a, b) {
            return collator.compare(a.taskName, b.taskName);
          })}
          getOptionLabel={(option) => option.taskName}
          fullWidth={true}
          value={
            props.kkdTaskId ? getFromId(props.kkdTaskId, options.kkdTasks) : null
          }
          onChange={(event, value) => {
            if (value === null) {
              setProps({ ...props, kkdTaskId: null });
            } else {
              setProps({
                ...props,
                kkdTaskId: value?.id,
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              label="KKD Görevi"
              className={classes.textField}
            />
          )}
        />
        <div style={{ paddingBottom: 20, textAlign: "center" }}>
          {loading ? (
            <CircularProgress color="secondary" />
          ) : (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => onButtonClick()}
              className={classes.submit}
            >
              {!isNewCrew ? "GÜNCELLE" : "OLUŞTUR"}
            </Button>
          )}
        </div>
      </FormControl>
    </TableEntryModal>
  );
}
