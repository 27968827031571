import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { getDepartments, getSGKofCompany, getUsers } from "./GetOptions";
import { FormControl, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import jsonMap from "../../../components/Utils/jsonMap";
import { CustomDatePicker } from "../../../components/Selectors/DatePickers";
import { TextArea, TextSelector } from "../../../components/Fields/TextFields";
import { getTowns } from "./GetOptions";
import { severities } from "../../../assets/constants";

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "90vw",
  },
  textField: {
    marginBottom: "2vw",
    whiteSpace: "pre-wrap",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0,
  },
}));

/**
 * The form control component used in the accident creation/editing modal.
 * @param {oldAccidentProps: obj, newAccidentProps: obj, setNewAccidentProps: func, options: obj[], updateAccident: func, submitNewAccident: func} props of the form control
 */
export default function WorkAccidentForm({
  oldAccidentProps,
  newAccidentProps,
  setNewAccidentProps,
  options,
  setOptions,
  errors,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();
  const collator = new Intl.Collator("tr");

  useEffect(() => {
    (async () => {
      const towns = await getTowns(oldAccidentProps?.city.id);
      setOptions({ ...options, towns: towns });
    })();
  }, []);

  const getFromId = (options, id) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].id === id) {
        return options[i];
      }
    }
    return null;
  };

  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextSelector //EDCCOMPANY
        required
        error={errors.edcCompanyId}
        helperText={errors.edcCompanyId}
        label={"Dağıtım Şirketi"}
        value={newAccidentProps.edcCompanyId}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            edcCompanyId: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap("edcCompanies", options)}
      />
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          newAccidentProps.companyId
            ? getFromId(options.companies, newAccidentProps.companyId)
            : null
        }
        onChange={async (event, value) => {
          setNewAccidentProps({ ...newAccidentProps, companyId: value?.id });
          let opts = { ...options };
          [opts.sgkNos, opts.users, opts.departments] = await Promise.all([
            getSGKofCompany(value?.id),
            getUsers(value?.id, false),
            getDepartments(value?.id),
          ]);
          setOptions(opts);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.companyId}
            helperText={errors.companyId}
            label="Şirket"
            className={classes.textField}
          />
        )}
      />
      {newAccidentProps.companyId && (
        <TextArea //COMPANY SGK
          required
          error={errors.companySGKSicilNo}
          helperText={errors.companySGKSicilNo}
          label={"Sorumlu Şirket SGK Sicil No"}
          value={newAccidentProps.companySGKSicilNo}
          onChangeFunc={(value) => {
            let val = value.target.value.replace(/[^0-9.]/g, "");
            setNewAccidentProps({
              ...newAccidentProps,
              companySGKSicilNo: val,
            });
          }}
          style={classes.textField}
        />
      )}
      <Autocomplete //DEPARTMENT
        id="combo-box"
        options={options.departments?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={
          newAccidentProps.departmentId
            ? getFromId(options.departments, newAccidentProps.departmentId)
            : null
        }
        onChange={async (event, value) => {
          setNewAccidentProps({ ...newAccidentProps, departmentId: value?.id });
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            required
            error={errors.departmentId}
            helperText={errors.departmentId}
            label="Bölüm"
            className={classes.textField}
          />
        )}
      />
      <Autocomplete //CITY
        id="combo-box"
        options={options.cities?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        defaultValue={
          oldAccidentProps
            ? getFromId(options.cities, newAccidentProps.cityId)
            : null
        }
        onChange={async (event, value) => {
          if (value === null) {
            setNewAccidentProps({ ...newAccidentProps, cityId: null });
          } else {
            setNewAccidentProps({
              ...newAccidentProps,
              cityId: value?.id,
              townId: null,
            });
            const towns = await getTowns(value?.id);
            setOptions({ ...options, towns: towns });
          }
        }}
        renderInput={(params) => (
          <TextField {...params} label="İl" className={classes.textField} />
        )}
      />
      <Autocomplete //TOWN
        id="combo-box"
        options={options.towns?.sort(function (a, b) {
          return collator.compare(a.name, b.name);
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        disabled={!newAccidentProps.cityId}
        defaultValue={() => {
          return oldAccidentProps && options.towns.length > 0
            ? getFromId(options.towns, newAccidentProps.townId)
            : oldAccidentProps?.town
            ? oldAccidentProps.town
            : null;
        }}
        onChange={(event, value) => {
          setNewAccidentProps({ ...newAccidentProps, townId: value?.id });
        }}
        renderInput={(params) => (
          <TextField {...params} label="İlçe" className={classes.textField} />
        )}
      />
      <TextSelector //ACCIDENT TYPE
        required
        error={errors.accidentTypeId}
        helperText={errors.accidentTypeId}
        label={"Kaza/Olay Şekli"}
        value={newAccidentProps.accidentTypeId}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentTypeId: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap("accidentTypes", options)}
      />
      <TextSelector //EPDK ACCIDENT TYPE
        required
        error={errors.accidentTypeEPDKId}
        helperText={errors.accidentTypeEPDKId}
        label={"EPDK İş Kazası Tipi"}
        value={newAccidentProps.accidentTypeEPDKId}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentTypeEPDKId: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap("accidentTypesEPDK", options)}
      />
      <TextSelector //SEVERITY
        label={"Ciddiyet"}
        value={newAccidentProps.severity}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            severity: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap(false, severities)}
      />
      <CustomDatePicker //ACCIDENT DATE
        style={classes.dateField}
        label={language.tableColumns.accidentDate}
        value={newAccidentProps.accidentDate}
        shrink={newAccidentProps.accidentDate}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentDate: moment(value)
              .set("hour", 18)
              .set("minute", 0)
              .toISOString(true),
          })
        }
      />
      <TextSelector //ACCIDENT DEFINITION -> ACCIDENT/EVENT TYPE
        error={errors.accidentDefinitionId}
        helperText={errors.accidentDefinitionId}
        label={"Kaza/Olay Türü"}
        value={newAccidentProps.accidentDefinitionId}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentDefinitionId: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap("accidentDefinitions", options)}
      />
      <TextSelector //ACCIDENT PLACE
        required
        error={errors.accidentPlaceId}
        helperText={errors.accidentPlaceId}
        label={"Kazanın Yeri"}
        value={newAccidentProps.accidentPlaceId}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            accidentPlaceId: value.target.value,
          })
        }
        style={classes.textField}
        options={jsonMap("places", options)}
      />
      <TextArea //SUMMARY
        label={"Kaza Özeti"}
        value={newAccidentProps.summary}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            summary: value.target.value,
          })
        }
        style={classes.textField}
      />
      {/* <TextArea //VISIBLE CAUSES
        label={"Görünen Sebepler"}
        value={newAccidentProps.visibleCauses}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            visibleCauses: value.target.value,
          })
        }
        style={classes.textField}
      /> */}
      <TextArea //INSTANT ACTIONS
        label={"Alınan Anlık Aksiyonlar"}
        value={newAccidentProps.instantAction}
        onChangeFunc={(value) =>
          setNewAccidentProps({
            ...newAccidentProps,
            instantAction: value.target.value,
          })
        }
        style={classes.textField}
      />
    </FormControl>
  );
}
