import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { List, ListItem, ListItemText } from "@material-ui/core";
import IconTooltipButton from "../../../components/Buttons/IconTooltipButton";
import DeleteIcon from '@material-ui/icons/Delete';
import jsonMap from "../../../components/Utils/jsonMap";
import { TextSelector } from "../../../components/Fields/TextFields";

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: 180,
    overflow: "auto",
    border: "1px solid gray",
    marginBottom: 25,
    marginTop: -5
  },
  injuredBodyParts: {
    marginTop: -5,
    color: "#B8B8B8"
  },
  textField: {
    marginTop: "-30px",
    marginBottom: "40px",
    marginLeft: "25%",
    marginRight: "auto",
    width: "50%"
  },
}));

export default function InjuredPartsList({personnelProps, setPersonnelProps, options}) {
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [update, setUpdate] = React.useState(false);

  const init = useCallback(async () => {
    if(!personnelProps.injuredBodyPartIds) {
      personnelProps.injuredBodyPartIds = [];
      setUpdate(!update);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personnelProps]);
  React.useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      {personnelProps.injuredBodyPartIds && 
      <>
        <h5 className={classes.injuredBodyParts}>Yaralı Vücut Parçaları</h5>
        <List className={classes.list}>
          {
            personnelProps.injuredBodyPartIds.map((data, index) => (
              <ListItem key={index}>
                <IconTooltipButton title={language.form.deleteQuestion} onClick={() => {
                  let injuredBodyPartIds = personnelProps.injuredBodyPartIds;
                  injuredBodyPartIds.splice(index, 1);
                  setPersonnelProps({...personnelProps, injuredBodyPartIds: injuredBodyPartIds});
                }}>
                  <DeleteIcon />
                </IconTooltipButton>
                <ListItemText secondary={options.bodyParts[`${data}`]}/>
              </ListItem>
            ))
          }
        </List>
        <TextSelector
          shrink={false}
          label={"Yaralı Vücut Parçası Ekle"}
          value={null}
          onChangeFunc={value => {
            let injuredBodyPartIds = personnelProps.injuredBodyPartIds;
            injuredBodyPartIds.push(parseInt(value.target.value));
            setPersonnelProps({...personnelProps, injuredBodyPartIds: injuredBodyPartIds});
          }}
          style={classes.textField}
          options={jsonMap("bodyParts", options)}
        />
      </>}
    </>
  );
}