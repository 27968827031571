import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, FormControl, TextField } from "@material-ui/core";
import moment from 'moment';
import { CustomDatePicker } from '../../../../components/Selectors/DatePickers';
import CustomFileDropzone from '../../../../components/Files/CustomFileDropzone';
import showSnackbar from '../../../../components/Utils/showSnackbar';
import { Autocomplete } from "@material-ui/lab";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import Request from '../../../../helpers/Request';
import { getDepartments } from './GetOptions';
import { TextArea } from '../../../../components/Fields/TextFields';

const useStyles = makeStyles(() => ({
  submit: {
    marginTop: "10px",
  },
  formControl: {
    width: "70vw",
    maxWidth: 600,
    maxHeight: "42vw",
  },
  dateField: {
    marginBottom: "2vw",
    marginTop: 0	
  },
  textField: {
    marginBottom: "2vw",
  },
  buttonContainer: {
    textAlign: "center",
    paddingBottom: 20
  }
}));

export default function ModalForm({
  jraProps,
  setJRAProps,
  label,
  loading,
  setLoading,
  options,
  setOptions,
  handleCloseModal,
  oldJRAProps,
  setSeverity,
  setSnackbarMessage,
  setSnackbar,
  tableRef
}) {
  const classes = useStyles();
  const collator = new Intl.Collator("tr");
  const language = LanguageHelper.getLanguage();

  const [files, setFiles] = React.useState(jraProps.files);
  const [errors, setErrors] = React.useState({
    companyId: null,
    departmentId: null,
    date: null,
    number: null
  });

  const getFromId = (options, id) => {
    for(let i = 0; i < options.length; i++) {
      if(options[i].id === id) {
        return options[i];
      }
    }
    return null;
  }

  /**
   * Request to replace the old data with the new data.
   */
  const updateJRA = async () => {
    setLoading(true);
    let formData = new FormData();
    if(files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    delete jraProps.files;
    const resp = await Request("patch", "/api/job-risk/" + oldJRAProps.id, formData, jraProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.updated, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  const submitNewJRA = async () => {
    setLoading(true);
    let formData = new FormData();
    if(files && Array.isArray(files)) {
      for (let file of files) {
        formData.append("files", file);
      }
    }
    delete jraProps.files;
    const resp = await Request("post", "/api/job-risk", formData, jraProps);
    console.log(resp);
    showSnackbar(resp, setSeverity, setSnackbarMessage, setSnackbar, language.etc.created, tableRef);
    handleCloseModal();
    setLoading(false);
  };

  const onButtonClick = () => {
    let error = false;
    let errorMessage = "Bu alan boş bırakılamaz.";
    let errors = {
      companyId: null,
      departmentId: null,
      date: null,
      number: null
    };

    if(!jraProps.companyId) {
      errors.companyId = errorMessage;
      error = true;
    }
    if(!jraProps.departmentId) {
      errors.departmentId = errorMessage;
      error = true;
    }
    if(!jraProps.frontDate) {
      errors.date = errorMessage;
      error = true;
    }
    if(!jraProps.number) {
      errors.number = errorMessage;
      error = true;
    }
    setErrors(errors);

    if(!error) {
      oldJRAProps ? updateJRA() : submitNewJRA();
    }
  }
  
  return (
    <FormControl noValidate autoComplete="off" className={classes.formControl}>
      <TextArea
        shrink={jraProps.number}
        label={"İş Tehlike Risk Analiz No"}
        required
        error={errors.number}
        value={jraProps.number}
        style={classes.textField}
        onChangeFunc={value => {
          let val = value.target.value;
          val = val.replace(/[^0-9._-]/g, "");
          if(val.length > 100) {
            setErrors({...errors, number: "İş Tehlike Risk Analiz No 100 karakterden uzun olamaz."});
          } else {
            setJRAProps({...jraProps, number: val});
            errors.number && setErrors({...errors, number: null});
          }
        }}
      />
      <Autocomplete //COMPANY
        id="combo-box"
        options={options.companies?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={jraProps.companyId ? getFromId(options.companies, jraProps.companyId) : null}
        onChange={async (event, value) => {
          setJRAProps({...jraProps, companyId: value?.id});
          let opts = {...options};
          opts.departments = await getDepartments(value?.id);
          setOptions(opts);
          errors.companyId && setErrors({...errors, companyId: null});
        }} 
        renderInput={(params) => 
          <TextField 
            {...params} 
            required 
            error={errors.department}
            helperText={errors.department}
            label="Şirket" 
            className={classes.textField}
          />
        }
      />
      <Autocomplete //DEPARTMENT
        id="combo-box"
        options={options.departments?.sort(function(a, b) {
          return collator.compare(a.name, b.name)
        })}
        getOptionLabel={(option) => option.name}
        fullWidth={true}
        value={jraProps.departmentId ? getFromId(options.departments, jraProps.departmentId) : null}
        onChange={async (event, value) => {
          setJRAProps({...jraProps, departmentId: value?.id});
          errors.departmentId && setErrors({...errors, departmentId: null});
        }} 
        renderInput={(params) => 
          <TextField 
            {...params} 
            required 
            error={errors.department}
            helperText={errors.department}
            label="Bölüm" 
            className={classes.textField}
          />
        }
      />
      <CustomDatePicker
        style={classes.dateField}
        error={errors.date}
        label="Tarih" 
        required
        value={jraProps.frontDate ? jraProps.frontDate.replaceAll("Z", "") : null} 
        shrink={jraProps.frontDate} 
        onChangeFunc={value => {
          setJRAProps({...jraProps, frontDate: moment(value).toISOString(true)});
          errors.date && setErrors({...errors, date: null});
        }}
      />
      <CustomFileDropzone
        files={files}
        setFiles={(value) => {
          setFiles(value);
        }}
        fileLimit={200}
        dropzoneText={"Dosyaları yüklemek için sürükleyin ya da buraya tıklayın."}
      />
      <div className={classes.buttonContainer}>
        {loading ?
        <CircularProgress color="secondary"/>
        :
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          className={classes.submit}
        >
          {label}
        </Button>}
      </div>
    </FormControl>
  );
}