import Request from "../../../helpers/Request";

export const getFunctions = async (moduleId) => {
  const resp = await Request(
    "get",
    "/api/authority/" + moduleId + "/module-functions"
  );
  const data = resp.data.content;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let func = {};
    func["name"] = data[i].name;
    func["id"] = data[i].id;
    arr.push(func);
  }
  return arr;
};

export const getModules = async () => {
  const resp = await Request("get", "/api/authority/modules-all");
  const data = resp.data.content;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let module = {};
    module["name"] = data[i].name;
    module["id"] = data[i].id;
    arr.push(module);
  }
  return arr;
};

export const getRolesId = async () => {
  const resp = await Request("get", "/api/roles/getAll");
  const data = resp.data;
  let arr = [];
  for (let i = 0; i < data.length; i++) {
    let role = {};
    role["name"] = data[i].name;
    role["nameTr"] = data[i].nameTr;
    role["id"] = data[i].id;
    arr.push(role);
  }
  return arr;
};

/*
 * Gets the options to use in the table fields and form dropdowns.
 * @returns {options: obj}
 */
export default async function GetOptions() {
  const [modules, rolesId] = await Promise.all([getModules(), getRolesId()]);
  const options = { modules, functions: [], rolesId };
  return options;
}
