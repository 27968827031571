import Request from '../../helpers/Request';
import LanguageHelper from '../../helpers/LanguageHelper';
import moment from 'moment';

export default function FetchData(query, setSnackbar, setSnackbarMessage, setSeverity, setNumOfEntries, type, filterProps) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "user.id,desc",

      //FILTERS
      filterCompany: filterProps.company?.id,
      filterDepartment: filterProps.department?.id,
      filterTrainingType: filterProps.trainingType,
      filterCity: filterProps.city?.id,
      filterCompletionDateEnd: filterProps.completionDateEnd,
      filterCompletionDateStart: filterProps.completionDateStart,
      filterAttendantName: filterProps.attendantName,
      filterAttendantSurname: filterProps.attendantSurname,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "date") { params.sort = "date," + query.orderDirection; }
      else if (query.orderBy.field === "level") { params.sort = "level," + query.orderDirection; }
      else if (query.orderBy.field === "message") { params.sort = "message," + query.orderDirection; }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request("get", "/api/training/attendants", null, params);
    console.log(resp);
    if(resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let data = resp.data;
      let content = data.content
      let temp = [];
      for (let i = 0; i < content.length; i++) {
        const edit = {
          userId: content[i]?.userId,
          region: content[i]?.region?.name,
          companyName: content[i]?.company?.name,
          departmentName: content[i]?.department?.name,
          city: content[i]?.city?.name,
          type: content[i]?.trainingType,
          trainingName: content[i]?.topics,
          name: content[i]?.name,
          surname: content[i]?.surname,
          completionDate: moment(content[i]?.completionDate).format("DD-MM-YYYY HH:mm"),
          time: content[i]?.time,
          instructors: formatInstructors(content[i]?.instructors, content[i]?.externalInstructors)
        };
        temp.push(edit);
      }
      setNumOfEntries(data.totalElements);
      resolve({
        data: temp,
        page: data.pageable.pageNumber,
        totalCount: data.totalElements,
      })
    }
  })
  return data;
}

const formatInstructors = (ins, extIns) => {
  const array = [];
  for(let prt of ins) {
    array.push(prt.fName + " " + prt.lName);
  }
  for(let prt of extIns) {
    array.push(prt.fullName + " (Dış)");
  }
  return array;
}