import Request from "../../../../helpers/Request";
import LanguageHelper from "../../../../helpers/LanguageHelper";

export default function FetchData(
  query,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  setNumOfEntries,
  type,
  filterProps
) {
  const language = LanguageHelper.getLanguage();
  const data = new Promise(async (resolve, reject) => {
    const params = {
      page: query.page,
      size: query.pageSize,
      sort: "incidentDate,desc",

      id: filterProps.user,
    };
    if (query.orderBy !== undefined) {
      if (query.orderBy.field === "id") {
        params.sort = "id," + query.orderDirection;
      }
      if (query.orderBy.field === "incidentType") {
        params.sort = "nearMissType," + query.orderDirection;
      }
      if (query.orderBy.field === "incidentDate") {
        params.sort = "incidentDate," + query.orderDirection;
      }
      if (query.orderBy.field === "company") {
        params.sort = "company.name," + query.orderDirection;
      }
      if (query.orderBy.field === "department") {
        params.sort = "department.name," + query.orderDirection;
      }
      if (query.orderBy.field === "description") {
        params.sort = "note," + query.orderDirection;
      }
    }
    if (query.search !== "") {
      params.search = query.search;
    }
    const resp = await Request(
      "get",
      "/api/nearMiss/isg-card/" + params.id,
      null,
      params
    );
    console.log(resp);
    if (resp.status !== 200) {
      setSeverity("error");
      setSnackbarMessage(language.etc.fetchingError + resp?.data?.message);
      setSnackbar(true);
    } else {
      let originalData = resp.data;
      let data = originalData.content;
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        const edit = {
          id: data[i].id,
          incidentType: data[i].type,
          incidentDate: data[i].incidentDate,
          company: data[i].company?.name,
          department: data[i].department?.name,
          description: data[i].note,
        };
        temp.push(edit);
      }
      setNumOfEntries(originalData.totalElements);
      resolve({
        data: temp,
        page: originalData.pageable.pageNumber,
        totalCount: originalData.totalElements,
      });
    }
  });
  return data;
}
