import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import Request from "../../../helpers/Request";
import LanguageHelper from "../../../helpers/LanguageHelper";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  details: {
    padding: 10,
    fontSize: 30,
    textAlign: "center",
    color: "white",
    backgroundColor: "#2D3446",
  },
  title: {
    float: "left",
    fontSize: "1vw",
    fontWeight: "bold",
  },
  error: {
    color: "red",
    fontSize: "2vw",
  },
}));

export default function VisitorVisitList({ rowData }) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [visit, setVisit] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getVisit = useCallback(async (id) => {
    const resp = await Request("get", "/api/vm/visitor/get-visits/" + id);
    
    return resp.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = useCallback(async () => {
    const temp = await getVisit(rowData.id);
    setVisit(temp);
    temp.length > 0 && setLoading(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [getVisit, rowData]);
  React.useEffect(() => {
    init();
  }, [init]);

  const PrintVisitors = () => {
    return visit?.map((data, index) => {
      return (
        <>
          <TableRow>
            <TableCell align="left">
            <p className={classes.text}>{data.entranceDate ? (new moment(data.entranceDate)).format('DD-MM-YYYY HH:mm:ss'):language.etc.empty}</p>
            </TableCell>
            <TableCell align="left">
            <p className={classes.text}>{data.exitDate ? (new moment(data.exitDate)).format('DD-MM-YYYY HH:mm:ss'):language.etc.empty}</p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>{data.hostName}</p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>{data.topic}</p>
            </TableCell>
            <TableCell align="left">
              <p className={classes.text}>{data.visitorCompanyName}</p>
            </TableCell>
          </TableRow>
        </>
      );
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <CircularProgress color="secondary" />
      ) : (visit.length > 0) ? (
        <div className={classes.details}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <p className={classes.title}>{language.visits.entranceDate}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>
                      {language.visits.exitDate}
                    </p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>
                      {language.visits.hostName}
                    </p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{language.visits.topic}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p className={classes.title}>{language.visits.visitorCompanyName}</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <PrintVisitors />
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <label className={classes.error}>{language.etc.not_found}</label>
      )}
    </React.Fragment>
  );
}