import React, { useCallback } from "react";
import ModalForm from './ModalForm';
import TableEntryModal from "../../../../components/Modal/TableEntryModal";

export default function JobRiskAnalysisModal({
  modal,
  setModal,
  setSnackbarMessage,
  setSnackbar,
  setSeverity,
  tableRef,
  oldJRAProps,
  setOldJRAProps,
  options,
  setOptions,
  modalLoading
}) {
  const defaultProps = {
    number: null,
    companyId: null,
    departmentId: null,
    frontDate: null,
    files: []
  }

  const [jraProps, setJRAProps] = React.useState(defaultProps);
  const [loading, setLoading] = React.useState(false);

  const init = useCallback(async () => {
    if (oldJRAProps) {
      setJRAProps({
        number: oldJRAProps.number || null,
        companyId: oldJRAProps.company || null,
        departmentId: oldJRAProps.department || null,
        frontDate: oldJRAProps.date || null,
        files: oldJRAProps.files || []
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldJRAProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setTimeout(() => {
      setJRAProps(defaultProps);
      setOldJRAProps(null);
    }, 300);
  };

  return (
    <>
      <TableEntryModal modal={modal} handleCloseModal={handleCloseModal} modalLoading={modalLoading}>
        <ModalForm
          jraProps={jraProps}
          setJRAProps={setJRAProps}
          label={oldJRAProps ? "GÜNCELLE" : "OLUŞTUR"}
          loading={loading}
          setLoading={setLoading}
          options={options}
          setOptions={setOptions}
          handleCloseModal={handleCloseModal}
          oldJRAProps={oldJRAProps}
          setSeverity={setSeverity}
          setSnackbarMessage={setSnackbarMessage}
          setSnackbar={setSnackbar}
          tableRef={tableRef}
        />
      </TableEntryModal>
    </>
  );
}