import React from "react";
import {
  Collapse,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import {
  TableFiltersButtons,
  TableFiltersOpenButton,
} from "../../../../components/Table/TableFilters";
import { CustomDatePicker } from "../../../../components/Selectors/DatePickers";
import LanguageHelper from "../../../../helpers/LanguageHelper";
import moment from "moment";
import { TextSelector } from "../../../../components/Fields/TextFields";
import jsonMap from "../../../../components/Utils/jsonMap";
import { examType } from "../../../../assets/constants";
import { getUsers } from "./GetOptions";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
  },
  textField: {
    width: "90%",
    margin: 10,
  },
}));

/**
 * A component to handle the filters used for the table.
 * @param {options: obj} param main parameters for the filters
 */
export default function GetFilterFields({
  options,
  tableRef,
  setOptions,
  filterProps,
  filtersOpen,
  clearFilters,
  setFilterProps,
  setFiltersOpen,
}) {
  const collator = new Intl.Collator("tr");
  const classes = useStyles();
  const language = LanguageHelper.getLanguage();

  const [tableFilters, setTableFilters] = React.useState(filterProps);
  const [collapse, setCollapse] = React.useState(filtersOpen);
  const [users, setUsers] = React.useState([]);

  return (
    <div>
      <TableFiltersOpenButton
        collapse={collapse}
        setCollapse={setCollapse}
        setFiltersOpen={setFiltersOpen}
      />
      {/* department: null,
    endTime: null,
    startTime: null,
    user: null,
    type: null, */}
      <Collapse in={collapse}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={options.departments?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                value={tableFilters.department}
                onChange={async (event, value) => {
                  setTableFilters({ ...tableFilters, department: value });
                  setUsers(await getUsers(value?.id));
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.auditTasks.department}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Autocomplete
                id="combo-box"
                options={users?.sort(function (a, b) {
                  return collator.compare(a.name, b.name);
                })}
                getOptionSelected={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                fullWidth={true}
                disabled={!tableFilters.department}
                value={tableFilters.user}
                onChange={(event, value) => {
                  setTableFilters({ ...tableFilters, user: value });
                }}
                renderOption={(option) => <span>{option.name}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={language.auditTasks.personel}
                    className={classes.textField}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.actions.inspectionDate}
                value={
                  tableFilters.startTime ? moment(tableFilters.startTime) : null
                }
                shrink={tableFilters.startTime}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    startTime: moment(value)
                      .set("hour", 0)
                      .set("minute", 0)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <CustomDatePicker
                style={classes.textField}
                label={language.actions.inspectionDateEnd}
                value={
                  tableFilters.endTime ? moment(tableFilters.endTime) : null
                }
                shrink={tableFilters.endTime}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    endTime: moment(value)
                      .set("hour", 23)
                      .set("minute", 59)
                      .toISOString(true),
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextSelector
                label={language.auditTasks.inspectionType_}
                value={tableFilters.type}
                shrink={tableFilters.type !== null}
                onChangeFunc={(value) =>
                  setTableFilters({
                    ...tableFilters,
                    type: value.target.value,
                  })
                }
                style={classes.textField}
                options={jsonMap(false, examType)}
              />
            </Grid>
          </Grid>

          <TableFiltersButtons
            tableRef={tableRef}
            newTableFilters={tableFilters}
            setFilterProps={setFilterProps}
            clearFilters={clearFilters}
          />
        </Paper>
      </Collapse>
    </div>
  );
}
