import React from 'react';
import SessionHelper from '../../../helpers/SessionHelper';
import { getAuthorizationForPage } from '../../../helpers/AuthorizationHelper';
import UnauthorizedPage from '../../UnauthorizedPage';
import { Container, Grid } from '@material-ui/core';
import { tableContainerStyles } from '../../../assets/styles/tableContainer';
import Actions from './Actions';

export default function RiskActionsPage() {
  const roles = SessionHelper.getUser().roles;
  const authorization = getAuthorizationForPage(roles, "riskActions");

  return (
    <Container maxWidth={false} disableGutters style={tableContainerStyles}>
      <Grid>
        {authorization?.view 
          ? 
          <Actions/>
          : 
          <UnauthorizedPage/>
        }
      </Grid>
    </Container>
  );
}