import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguageHelper from "../../../helpers/LanguageHelper";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Request from "../../../helpers/Request";
import ModalForm from "./ModalForm";
import { modalStyles } from "../../../assets/styles/tableContainer";
import showSnackbar from "../../../components/Utils/showSnackbar";
import GetOptions from "./GetOptions";

const useStyles = makeStyles((theme) => modalStyles(theme));

export default function ForwardingModal({
  modal,
  setModal,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
  tableRef,
  forwardProps,
  loading,
  setLoading,
}) {
  const language = LanguageHelper.getLanguage();
  const classes = useStyles();

  const [requestForwardProps, setRequestForwardProps] = React.useState({});
  const [options, setOptions] = React.useState({});

  const init = useCallback(async () => {
    const options = await GetOptions();
    setOptions(options);
    setRequestForwardProps({
      nearMissActionId: forwardProps?.id ? forwardProps?.id : null,
      to: [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forwardProps, modal]);
  React.useEffect(() => {
    init();
  }, [init]);

  const handleCloseModal = () => {
    setModal(false);
    setRequestForwardProps({
      to: [],
      nearMissActionId: null,
    });
  };

  const forwardUsers = async () => {
    setLoading(true);
    const ids = requestForwardProps?.to?.map((props) => props.id);
    let body = {
      nearMissActionId: requestForwardProps.nearMissActionId,
      to: ids,
    };
    const resp = await Request("post", "/api/nearMiss/forward", body);
    console.log(resp);
    showSnackbar(
      resp,
      setSeverity,
      setSnackbarMessage,
      setSnackbar,
      language.etc.added,
      tableRef
    );
    handleCloseModal();
    setLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        className={classes.modal}
        open={modal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modal}>
          <div className={classes.paper}>
            <ModalForm
              requestForwardProps={requestForwardProps}
              forwardProps={forwardProps}
              setRequestForwardProps={setRequestForwardProps}
              submitForwardProps={forwardUsers}
              loading={loading}
              options={options}
            />
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}
