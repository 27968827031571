import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {createTheme, Divider, ThemeProvider} from "@material-ui/core";
import NewTownModal from "./DetailsComponents/NewTownModal";
import RemoveDialog from "./DetailsComponents/RemoveDialog";
import NewRegionModal from "./DetailsComponents/NewRegionModal";
import RegionsArea from "./DetailsComponents/RegionsArea";
import NoRegionTownsArea from "./DetailsComponents/NoRegionTownsArea";

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: "#2D3446",
    color: "white",
    textAlign: "center",
    alignItems: "center"
  }
}));

export default function Details({
  rowData,
  tableRef,
  setSnackbar,
  setSnackbarMessage,
  setSeverity,
}) {
  const classes = useStyles();
  
  const [towns, setTowns] = React.useState(rowData?.townsNoRegion);
  const [regions, setRegions] = React.useState(rowData?.regions);
  const [townModal, setTownModal] = React.useState(false);
  const [regionModal, setRegionModal] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [deleteTownId, setDeleteTownId] = React.useState(null);
  const [deleteRegionId, setDeleteRegionId] = React.useState(null);
  const [deleteTownFromRegionIds, setDeleteTownFromRegionIds] = React.useState(null);

  return (
    <>
      <ThemeProvider theme={createTheme({ palette: { type: 'dark' } })}>
        <RemoveDialog 
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          deleteTownId={deleteTownId}
          deleteRegionId={deleteRegionId}
          deleteTownFromRegionIds={deleteTownFromRegionIds}
          towns={towns}
          setTowns={setTowns}
          regions={regions}
          setRegions={setRegions}
        />
        <NewTownModal
          rowData={rowData}
          tableRef={tableRef}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          townModal={townModal}
          setTownModal={setTownModal}
        />
        <NewRegionModal
          rowData={rowData}
          tableRef={tableRef}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setSeverity={setSeverity}
          regionModal={regionModal}
          setRegionModal={setRegionModal}
        />
        <div className={classes.content}>
          <RegionsArea
            setRegionModal={setRegionModal}
            setDeleteRegionId={setDeleteRegionId}
            setOpenDialog={setOpenDialog}
            setDeleteTownFromRegionIds={setDeleteTownFromRegionIds}
            regions={regions}
          />
          <Divider style={{margin: 10}} />
          <div style={{paddingBottom: 40}}>
            <NoRegionTownsArea 
              setTownModal={setTownModal}
              setDeleteTownId={setDeleteTownId}
              setOpenDialog={setOpenDialog}
              setSeverity={setSeverity}
              setSnackbarMessage={setSnackbarMessage}
              setSnackbar={setSnackbar}
              towns={towns}
              setTowns={setTowns}
              regions={regions}
              setRegions={setRegions}
            />
          </div>
        </div>
      </ThemeProvider>
    </>
  );
}
